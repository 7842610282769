import styled, { css } from 'styled-components/macro';

const commonRowStyles = css`
    display: flex;
    flex-direction: column;

    small {
        font-size: ${({ theme }) => theme.fontSize.small};
        color: ${({ theme }) => theme.color.textLight};
    }
`;

export const ResultsModalBodyWrapper = styled.div`
    width: 1100px;

    display: flex;

    @media (min-width: ${({ theme }) => `${theme.breakpoints.medium}px`}) {
        height: 80vh;
    }
    @media (max-width: ${({ theme }) => `${theme.breakpoints.medium}px`}) {
        width: calc(100vw - 24px);
        flex-direction: column;
        overflow-y: scroll;
    }
`;

export const ResultsSidebar = styled.div`
    width: 300px;
    padding: 24px;
    background-color: ${({ theme }) => theme.color.bgPrimarySuperLight};
    ${commonRowStyles};

    @media (max-width: ${({ theme }) => `${theme.breakpoints.medium}px`}) {
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
    }
`;

export const SidebarDataSourceHolder = styled.div`
    padding-bottom: 16px;
    ${commonRowStyles};

    span {
        color: ${({ theme }) => theme.color.textLight};
        margin-bottom: 16px;
    }

    @media (max-width: ${({ theme }) => `${theme.breakpoints.medium}px`}) {
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
    }
`;
export const SidebarDetailsHolder = styled.div`
    padding-top: 16px;
    border-top: ${({ theme }) => theme.border.base};
    ${commonRowStyles};
`;

export const SidebarDetailsRow = styled.div`
    margin: 16px 0;
    ${commonRowStyles};
`;

export const ResultsBody = styled.div`
    width: 100%;
`;

export const BodyHeader = styled.div`
    width: calc(100% - 48px);
    border-radius: ${({ theme }) => theme.border.radiusBase};
    background-color: ${({ theme }) => theme.color.bgSearch};
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 16px;
    margin: 24px 24px 0;

    .dropdownHolder {
        width: 100%;

        margin: 0 16px 0 0;
        display: flex;
        flex-direction: column;

        input {
            margin-top: 8px;
        }

        small {
            font-size: ${({ theme }) => theme.fontSize.small};
            color: ${({ theme }) => theme.color.textLight};
        }
    }

    @media (max-width: ${({ theme }) => `${theme.breakpoints.medium}px`}) {
        flex-direction: column;

        .dropdownHolder {
            margin: 0 0 16px 0;
        }
    }

    @media (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
        /* width: calc(100vw - 24px);
        flex-direction: column; */
    }
`;

export const BodyHolder = styled.div`
    height: auto;
    padding: 24px;

    ${({ customStyles }) =>
        customStyles &&
        css`
            ${customStyles}
        `}
`;
