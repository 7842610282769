import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import SmartTipStatus from 'components/SmartTipStatus';
import theme from 'assets/theme/mainTheme';
import {
    Container,
    Title,
    Row,
    StyledItem,
    ItemTitle,
    ItemValue,
} from './SmartTipSatisfactionSurvey.style';

const SmartTipSatisfactionSurvey = ({
    score,
    evaluationsCount,
    commentsCount,
    serveysEnabled,
    onShowResultsClick,
}) => (
  <Container>
    <Title>Satisfaction Survey</Title>
    <Row>
      <StyledItem>
        <ItemTitle>Average Score</ItemTitle>
        <ItemValue>{score || 'N/A'}</ItemValue>
      </StyledItem>
      <StyledItem>
        <ItemTitle>Number of Views</ItemTitle>
        <ItemValue>{evaluationsCount}</ItemValue>
      </StyledItem>
      <StyledItem>
        <ItemTitle>Satisfaction Survey Status</ItemTitle>
        {serveysEnabled ? (
          <SmartTipStatus
                        variant={theme.color.checkGreen}
                        text="On"
                    />
                ) : (
                  <SmartTipStatus variant={theme.color.red} text="Off" />
                )}
      </StyledItem>
      <StyledItem>
        <Button
                    primaryDark
                    onClick={onShowResultsClick}
                    disabled={commentsCount === 0}
                >
          {commentsCount === 0
                        ? 'No feedback yet'
                        : 'Display feedback'}
        </Button>
      </StyledItem>
    </Row>
  </Container>
);

SmartTipSatisfactionSurvey.propTypes = {
    onShowResultsClick: PropTypes.func.isRequired,
    score: PropTypes.string.isRequired,
    evaluationsCount: PropTypes.number.isRequired,
    commentsCount: PropTypes.number.isRequired,
    serveysEnabled: PropTypes.bool.isRequired,
};

export default SmartTipSatisfactionSurvey;
