import React from 'react';

export const generateNameDropdownOptions = (users, selectedNames) =>
    users.map((user) => ({
        id: user.id,
        label() {
            return (
              <span>
                {user.first_name} 
                {' '}
                {user.last_name}
              </span>
            );
        },
        selected: selectedNames.includes(user.id),
    }));
export const generateMetricsDropdownOptions = (metrics = [], selectedMetrics) =>
    metrics.map((metric) => ({
        id: metric.id,
        label() {
            return <span>{metric.name}</span>;
        },
        selected: selectedMetrics.includes(metric.id),
    }));
