import styled from 'styled-components/macro';

const AlertsDisplay = styled.div`
    display: flex;
    flex-flow: column nowrap;

    position: fixed;
    z-index: 9001;
    bottom: 32px;
    right: 32px;
`;

export default AlertsDisplay;
