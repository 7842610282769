import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SplashScreen from 'views/SplashScreen';
import { establishCurrentUserThunk } from 'redux/actions/thunks/user';
import DashboardWithSidebar from 'templates/DashboardWithSidebar';
import Routes from 'routing/Routes';
import { useAuth0 } from '@auth0/auth0-react';
import getTenantOrganizationThunk from 'redux/actions/thunks/tenantOrganization';
import useFetch from 'hooks/useFetch';
import apiRoutes from 'config/apiRoutes';

const Dashboard = () => {
    const dispatch = useDispatch();

    const { isUserEstablished, isUserError } = useSelector(
        (state) => state.currentUser
    );

    const {
        // getIdTokenClaims, // return more information like: email, username, etc.
        getAccessTokenSilently,
        isAuthenticated,
        isLoading,
        logout,
    } = useAuth0();

    const request = useFetch(getAccessTokenSilently, logout);

    if (!isAuthenticated && !isLoading) {
        document.location = '/login';
    }

    const { isEstablished } = useSelector((state) => state.tenantOrganization);

    useEffect(() => {
        request(
            apiRoutes.post.login,
            'POST',
            () => {
                dispatch(
                    establishCurrentUserThunk(getAccessTokenSilently, logout)
                );
                if (!isEstablished) {
                    dispatch(
                        getTenantOrganizationThunk(
                            getAccessTokenSilently,
                            logout
                        )
                    );
                }
            },
            () => {
                logout({ returnTo: window.location.origin });
            },
            {},
            {},
            true
        );
    }, []);

    if (isUserError) {
        logout({ returnTo: window.location.origin });
    }

    if (!isUserEstablished && !isUserError) {
        return <SplashScreen />;
    }

    return (
      <>
        <DashboardWithSidebar>
          <Routes />
        </DashboardWithSidebar>
      </>
    );
};

export default Dashboard;
