import styled from 'styled-components/macro';

const TeamWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
`;

export const LoadingWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;

export default TeamWrapper;
