import {
    ESTABLISH_TEAM,
    ESTABLISH_TEAM_SUCCESS,
    ESTABLISH_TEAM_FAILURE,
    JOIN_TEAM,
    JOIN_TEAM_SUCCESS,
    JOIN_TEAM_FAILURE,
    CREATE_TEAM_SUCCESS,
    UPDATE_TEAM,
    UPDATE_TEAM_SUCCESS,
    UPDATE_TEAM_FAILURE,
    ARCHIVE_TEAM,
    ARCHIVE_TEAM_SUCCESS,
    ARCHIVE_TEAM_FAILURE,
    RESET_TEAMS,
} from 'redux/actions/team';

export const initialState = {
    areTeamsEstablished: false,
    isTeamsError: false,
    areTeamsFetching: false,
    recentlyCreatedTeamId: undefined,
    teams: [],
    error: null,
};

const teams = (state = initialState, action) => {
    switch (action.type) {
        case ESTABLISH_TEAM:
            return {
                ...state,
                areTeamsFetching: true,
                isTeamsError: false,
            };

        case ESTABLISH_TEAM_SUCCESS:
            return {
                ...state,
                areTeamsFetching: false,
                areTeamsEstablished: true,
                teams: action.payload,
            };

        case ESTABLISH_TEAM_FAILURE:
            return {
                ...state,
                areTeamsFetching: false,
                isTeamsError: true,
                error: action.err,
            };

        case JOIN_TEAM:
            return {
                ...state,
            };

        case JOIN_TEAM_SUCCESS:
            return {
                ...state,
            };

        case JOIN_TEAM_FAILURE:
            return {
                ...state,
                error: action.err,
            };
        case CREATE_TEAM_SUCCESS:
            return {
                ...state,
                recentlyCreatedTeamId: action.payload.id || undefined,
            };

        case UPDATE_TEAM:
            return {
                ...state,
            };

        case UPDATE_TEAM_SUCCESS:
            return {
                ...state,
            };

        case UPDATE_TEAM_FAILURE:
            return {
                ...state,
                error: action.err,
            };

        case ARCHIVE_TEAM:
            return {
                ...state,
            };

        case ARCHIVE_TEAM_SUCCESS:
            return {
                ...state,
            };

        case ARCHIVE_TEAM_FAILURE:
            return {
                ...state,
                error: action.err,
            };

        case RESET_TEAMS:
            return initialState;

        default:
            return state;
    }
};

export default teams;
