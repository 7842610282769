import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import establishMetricSourcesThunk from 'redux/actions/thunks/metricSources';
import { useAuth0 } from '@auth0/auth0-react';
import Metrics from 'containers/Metrics';
import Spinner from 'components/Spinner';
import UniversalEmptyState from 'components/EmptyState/UniversalEmptyState';
import { AdminSection } from 'views/DataSourcesMeasures/DataSourcesMeasures.style';

const MetricSource = () => {
    const dispatch = useDispatch();
    const { getAccessTokenSilently, logout } = useAuth0();
    const {
        areMetricSourcesFetching,
        areMetricSourcesEstablished,
        metricSources,
    } = useSelector((state) => state.metricSources);

    useEffect(() => {
        dispatch(establishMetricSourcesThunk(getAccessTokenSilently, logout));
    }, []);

    if (!areMetricSourcesEstablished || areMetricSourcesFetching) {
        return <Spinner />;
    }

    if (metricSources !== undefined && metricSources.length === 0) {
        return (
          <UniversalEmptyState description="There are no Metrics or Data Sources available to your organization at the moment." />
        );
    }

    return (
      <div>
        {metricSources
                .sort((ms1, ms2) => {
                    if (ms1.name > ms2.name) {
                        return 1;
                    }

                    if (ms1.name < ms2.name) {
                        return -1;
                    }

                    return 0;
                })
                .map((metricSource) => (
                  <AdminSection withOverflowScroll>
                    <Metrics
                            key={metricSource.id}
                            areMetricSourcesFetching={areMetricSourcesFetching}
                            metricSourceId={metricSource.id}
                            metricName={metricSource.name}
                            metricSourceEnabled={metricSource.enabled}
                        />
                  </AdminSection>
                ))}
      </div>
    );
};

export default MetricSource;
