export const FETCH_REMINDERS = 'FETCH_REMINDERS';
export const FETCH_REMINDERS_SUCCESS = 'FETCH_REMINDERS_SUCCESS';
export const FETCH_REMINDERS_FAILURE = 'FETCH_REMINDERS_FAILURE';
export const SET_SELECTED_DATE = 'SET_SELECTED_DATE';
export const CLEAR_SELECTED_DATE = 'CLEAR_SELECTED_DATE';

export const DELETE_REMINDER = 'DELETE_REMINDER';
export const DELETE_REMINDER_SUCCESS = 'DELETE_REMINDER_SUCCESS';
export const DELETE_REMINDER_FAILURE = 'DELETE_REMINDER_FAILURE';

export const ADD_REMINDER = 'ADD_REMINDER';
export const ADD_REMINDER_SUCCESS = 'ADD_REMINDER_SUCCESS';

export const EDIT_REMINDER = 'EDIT_REMINDER';
export const EDIT_REMINDER_SUCCESS = 'EDIT_REMINDER_SUCCESS';

export const fetchReminders = () => ({
    type: FETCH_REMINDERS,
});

export const fetchRemindersSuccess = (payload) => ({
    type: FETCH_REMINDERS_SUCCESS,
    payload,
});

export const fetchRemindersFailure = (err) => ({
    type: FETCH_REMINDERS_FAILURE,
    err,
});

export const setSelectedDate = (payload) => ({
    type: SET_SELECTED_DATE,
    payload,
});

export const clearSelectedDate = () => ({
    type: CLEAR_SELECTED_DATE,
});

export const deleteReminder = (id) => ({
    type: DELETE_REMINDER,
    payload: id,
});

export const deleteReminderSuccess = (payload) => ({
    type: DELETE_REMINDER_SUCCESS,
    payload,
});

export const deleteReminderFailure = (err) => ({
    type: DELETE_REMINDER_FAILURE,
    err,
});

export const addReminder = () => ({
    type: ADD_REMINDER,
});

export const addReminderSuccess = (payload) => ({
    type: ADD_REMINDER_SUCCESS,
    payload,
});

export const editReminder = () => ({
    type: EDIT_REMINDER,
});

export const editReminderSuccess = (payload) => ({
    type: EDIT_REMINDER_SUCCESS,
    payload,
});
