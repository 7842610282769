import {
    ESTABLISH_USER_STATS,
    ESTABLISH_USER_STATS_SUCCESS,
    ESTABLISH_USER_STATS_FAILURE,
} from 'redux/actions/organizationUserStats';

export const initialState = {
    areUserStatsEstablished: false,
    isUserStatsError: false,
    areUserStatsFetching: true,
    organizationUserStats: {},
    error: null,
};

const organizationUserStats = (state = initialState, action) => {
    switch (action.type) {
        case ESTABLISH_USER_STATS:
            return {
                ...state,
                areUserStatsFetching: true,
                isUserStatsError: false,
            };

        case ESTABLISH_USER_STATS_SUCCESS:
            return {
                ...state,
                areUserStatsFetching: false,
                areUserStatsEstablished: true,
                organizationUserStats: action.payload,
            };

        case ESTABLISH_USER_STATS_FAILURE:
            return {
                ...state,
                areUserStatsFetching: false,
                isUserStatsError: true,
                error: action.err,
            };

        default:
            return state;
    }
};

export default organizationUserStats;
