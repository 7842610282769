import styled, { css } from 'styled-components/macro';

export const Body = styled.div`
    max-width: 440px;
    width: 100%;
    padding: 32px;
    text-align: center;
`;

export const Heading = styled.h3`
    font-weight: 20px;
`;

export const Paragraph = styled.p`
    margin: 24px 0;
    font-size: 12px;
    color: #718fbf;
    line-height: 18px;
`;

export const buttonStyles = css`
    min-width: 160px;
`;
