import React from 'react';
import PropTypes from 'prop-types';
import Input from 'components/Input';
import Modal from 'components/Modal';
import reactDatepickerWrapper from 'assets/styles/datepickerWrapper.style';
import TimePicker from 'components/TimePicker';
import DatePicker from 'components/DatePicker';
import Dropdown from 'components/Dropdown';
import MPASelectAdminsDropdown from 'containers/MPASelectAdminsDropdown';
import {
    ModalCustomBodyStyle,
    TwoColumnGrid,
    StyledButton,
    InputSection,
    Title,
    StyledMemoInfo,
    StyledMemoWarning,
    Message,
} from './MPAAddAdminMemoModal.style';

export const SEND_TO_OPTIONS = {
    send_to_all_admins_and_users: 'systemwide',
    send_to_all_users: 'all_users',
    send_to_all_hospital_admins: 'all_admins',
    send_to_selected_admins: 'admins',
};

export const TYPE_OPTIONS = {
    type_informational: 'type_informational',
    type_critical: 'type_critical',
};

export const sendToOptions = [
    {
        id: SEND_TO_OPTIONS.send_to_all_admins_and_users,
        label: 'All Admins and Users',
    },
    { id: SEND_TO_OPTIONS.send_to_all_users, label: 'All Users' },
    { id: SEND_TO_OPTIONS.send_to_all_hospital_admins, label: 'All Admins' },
    { id: SEND_TO_OPTIONS.send_to_selected_admins, label: 'Select Admins' },
];

export const typeOptions = [
    {
        id: 'informational_memo',
        label: 'Informational memo',
        svg: <StyledMemoInfo />,
    },
    { id: 'critical_memo', label: 'Critical memo', svg: <StyledMemoWarning /> },
];

const MPAAddAdminMemoModal = ({
    closeModal,
    sendTo,
    setSendTo,
    type,
    setType,
    hour,
    setHour,
    date,
    setDate,
    message,
    setMessage,
    setAdmins,
    createAdminMemo,
}) => (
  <Modal
        closeModal={closeModal}
        headerText="Create new Admin Memo"
        isCloseIcon
        closeByIconClick={closeModal}
        modalFooter={() => (
          <>
            <StyledButton primary onClick={closeModal}>
              Cancel
            </StyledButton>
            <StyledButton primaryDark onClick={createAdminMemo}>
              Send
            </StyledButton>
          </>
        )}
    >
    <ModalCustomBodyStyle>
      <InputSection shadow>
        <Title>Send to</Title>
        <Dropdown
                    fullWidth
                    withoutDivider
                    label={sendTo ? sendTo.label : sendToOptions[0].label}
                    options={sendToOptions}
                    onSelect={setSendTo}
                />
      </InputSection>

      {sendTo && sendTo.id === SEND_TO_OPTIONS.send_to_selected_admins && (
        <InputSection shadow noPaddingTop>
          <Title noCentered>Choose admins</Title>
          <div>
            <MPASelectAdminsDropdown onUpdate={setAdmins} />
          </div>
        </InputSection>
            )}

      <InputSection borderTop>
        <Title>Type</Title>
        <Dropdown
                    fullWidth
                    withoutDivider
                    label={type ? type.label : typeOptions[0].label}
                    labelSvg={type ? type.svg : typeOptions[0].svg}
                    options={typeOptions}
                    onSelect={setType}
                />
      </InputSection>

      <InputSection noPaddingTop>
        <Title>Date</Title>
        <TwoColumnGrid>
          <Input variant="group" noMargin>
            <TimePicker time={hour} setTime={setHour} />
          </Input>
          <Input
                        noMargin
                        variant="group"
                        customStyles={reactDatepickerWrapper}
                    >
            <DatePicker date={date} setNewDate={setDate} />
          </Input>
        </TwoColumnGrid>
      </InputSection>

      <InputSection noPaddingTop>
        <Title noCentered>Message</Title>
        <Message
                    onChange={(e) => setMessage(e.target.value)}
                    value={message}
                />
      </InputSection>
    </ModalCustomBodyStyle>
  </Modal>
);

MPAAddAdminMemoModal.propTypes = {
    closeModal: PropTypes.func.isRequired,
    sendTo: PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string,
    }).isRequired,
    setSendTo: PropTypes.func.isRequired,
    type: PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string,
        svg: PropTypes.node,
    }).isRequired,
    setType: PropTypes.func.isRequired,
    hour: PropTypes.string.isRequired,
    setHour: PropTypes.func.isRequired,
    date: PropTypes.instanceOf(Date).isRequired,
    setDate: PropTypes.func.isRequired,
    message: PropTypes.string.isRequired,
    setMessage: PropTypes.func.isRequired,
    setAdmins: PropTypes.func.isRequired,
    createAdminMemo: PropTypes.func.isRequired,
};

MPAAddAdminMemoModal.defaultProps = {};

export default MPAAddAdminMemoModal;
