import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Spinner from 'components/Spinner';
import SectionHeader from 'components/SectionHeader';
import TeamOverviewMessages from 'components/TeamOverviewMessages';
import TeamOverviewNavigation from 'containers/TeamOverviewNavigation';
import { ReactComponent as ArrowBackIcon } from 'assets/svg/arrow-back.svg';
import { ReactComponent as StarsIcon } from 'assets/svg/star-earned.svg';
import { ReactComponent as TipsIcon } from 'assets/svg/tips-watched.svg';
import { ReactComponent as BulbIcon } from 'assets/svg/light-bulb-shine.svg';
import useTeamName from 'hooks/useTeamName';
import theme from 'assets/theme/mainTheme';
import useAdmin from 'hooks/useAdmin';
import { ROUTES } from 'routing/constants';
import {
    TeamOverviewWrapper,
    TeamOverviewHolder,
    TeamOverviewSidebar,
    SidebarHeader,
    SidebarHeaderItem,
    SidebarHeaderItemTitle,
    RecentlyShared,
    RecentlySharedTitle,
    RecentlySharedItem,
    RecentlySharedIcon,
    RecentlySharedBody,
    RightColumn,
} from './TeamOverview.style';

const TeamOverviewView = ({
    isFetching,
    overview,
    teamId,
    fetchPreviousMessages,
    leaveMessageFeedback,
    handleSendMessage,
    currentUser,
    deleteMessage,
}) => {
    const teamName = useTeamName(teamId);
    const isAdmin = useAdmin();

    if (isFetching) {
        return <Spinner data-testid="spinner" />;
    }

    return (
      <TeamOverviewWrapper data-testid="team-overview-body">
        <SectionHeader
                title={`${teamName} team`}
                goBack={{
                    url: ROUTES.team,
                    label() {
                        return (
                          <>
                            <ArrowBackIcon stroke={theme.color.textLabel} />
                            {' '}
                            <span>Back</span>
                          </>
                        );
                    },
                }}
                cta={() => (
                  <TeamOverviewNavigation
                        teamId={teamId}
                        currentUser={currentUser}
                        isCreator={
                            overview.details.items.creator_id === currentUser.id
                        }
                    />
                )}
                collapseOnMobile
            />
        <TeamOverviewHolder>
          <TeamOverviewSidebar>
            <SidebarHeader>
              <SidebarHeaderItem>
                <SidebarHeaderItemTitle data-testid="stars-stat-holder">
                  <h3>{overview.stats.items.stars}</h3>
                  <span>Stars earned</span>
                </SidebarHeaderItemTitle>
                <StarsIcon />
              </SidebarHeaderItem>
              <SidebarHeaderItem>
                <SidebarHeaderItemTitle data-testid="smart-tips-stat-holder">
                  <h3>
                    {overview.stats.items.smart_tips_watched}
                  </h3>
                  <span>Smart Tips watched</span>
                </SidebarHeaderItemTitle>
                <TipsIcon />
              </SidebarHeaderItem>
            </SidebarHeader>
            <RecentlyShared>
              <RecentlySharedTitle>
                Recently shared
              </RecentlySharedTitle>
              <RecentlySharedBody>
                {overview.recentlyShared.items.map((item) => (
                  <Link to={`${ROUTES.smartTips}/${item.id}`}>
                    <RecentlySharedItem
                                        key={item.id}
                                        data-testid={`${item.id}-shared-item-holder`}
                                    >
                      <RecentlySharedIcon>
                        <BulbIcon
                                                fill={theme.color.primary}
                                            />
                      </RecentlySharedIcon>
                      <span>{item.name}</span>
                    </RecentlySharedItem>
                  </Link>
                            ))}
              </RecentlySharedBody>
            </RecentlyShared>
          </TeamOverviewSidebar>
          <RightColumn>
            <TeamOverviewMessages
                        currentUser={currentUser}
                        messages={overview.messages}
                        isAdmin={isAdmin}
                        deleteMessage={(messageId) => deleteMessage(messageId)}
                        leaveMessageFeedback={(messageId, liked) =>
                            leaveMessageFeedback(messageId, liked)}
                        fetchPreviousMessages={fetchPreviousMessages}
                        handleSendMessage={(messageContent) =>
                            handleSendMessage(messageContent)}
                    />
          </RightColumn>
        </TeamOverviewHolder>
      </TeamOverviewWrapper>
    );
};

TeamOverviewView.propTypes = {
    isFetching: PropTypes.bool,
    overview: PropTypes.shape({
        isTeamOverviewEstablished: PropTypes.bool,
        isTeamOverviewFetching: PropTypes.bool,
        isTeamOverviewError: PropTypes.bool,
        stats: PropTypes.shape({
            items: PropTypes.shape({
                stars: PropTypes.number,
                smart_tips_watched: PropTypes.number,
            }),
        }),
        details: PropTypes.shape({
            items: PropTypes.shape({
                creator_id: PropTypes.string,
            }),
        }),
        recentlyShared: PropTypes.shape({
            items: PropTypes.arrayOf(PropTypes.any),
        }),
        messages: PropTypes.shape({}),
    }).isRequired,
    currentUser: PropTypes.shape({
        id: PropTypes.string,
    }).isRequired,
    fetchPreviousMessages: PropTypes.func.isRequired,
    leaveMessageFeedback: PropTypes.func.isRequired,
    handleSendMessage: PropTypes.func.isRequired,
    deleteMessage: PropTypes.func.isRequired,
    teamId: PropTypes.string.isRequired,
};

TeamOverviewView.defaultProps = {
    isFetching: false,
};

export default TeamOverviewView;
