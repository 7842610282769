import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import theme from 'assets/theme/mainTheme';
import { ReactComponent as ArrowDown } from 'assets/svg/dropdown-down.svg';
import { ReactComponent as ArrowUp } from 'assets/svg/dropdown-up.svg';
import useDate from 'hooks/useDate';
import {
    SourceSelector,
    Dropdown,
    DropdownOptions,
    DropdownOption,
    DateWrapper,
} from './RelativePerformance.style';

const SourceChooser = ({
    selectedMetricSource,
    metricSources,
    setSelectedMetricSource,
}) => {
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const dropdownRef = useRef(null);

    const toggleDropdownVisible = () => {
        setIsDropdownVisible(!isDropdownVisible);
    };

    const RenderDate = (date) => {
        const timestamp = new Date(date);
        return useDate(timestamp);
    };

    const handleClickOutside = (e) => {
        if (dropdownRef.current && dropdownRef.current.contains(e.target)) {
            return;
        }
        setIsDropdownVisible(false);
    };

    useEffect(() => {
        if (isDropdownVisible) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isDropdownVisible]);

    return (
      <SourceSelector>
        <p>Data Source</p>
        <Dropdown onClick={toggleDropdownVisible} ref={dropdownRef}>
          {selectedMetricSource
                    ? selectedMetricSource.name
                    : 'Choose source'}
          {isDropdownVisible ? (
            <ArrowUp fill={theme.color.textBase} />
                ) : (
                  <ArrowDown fill={theme.color.textBase} />
                )}
          {isDropdownVisible && (
            <DropdownOptions>
              {metricSources.map((item) => (
                <DropdownOption
                                key={item.id}
                                onClick={() => setSelectedMetricSource(item)}
                            >
                  {item.name}
                </DropdownOption>
                        ))}
            </DropdownOptions>
                )}
        </Dropdown>
        {selectedMetricSource &&
                selectedMetricSource.last_results_imported_at && (
                <DateWrapper>
                  {`Last updated: ${RenderDate(
                            selectedMetricSource.last_results_imported_at
                        )}`}
                </DateWrapper>
                )}
      </SourceSelector>
    );
};

SourceChooser.propTypes = {
    selectedMetricSource: PropTypes.objectOf(PropTypes.any),
    metricSources: PropTypes.arrayOf(PropTypes.any),
    setSelectedMetricSource: PropTypes.func.isRequired,
};

SourceChooser.defaultProps = {
    selectedMetricSource: undefined,
    metricSources: [],
};

export default SourceChooser;
