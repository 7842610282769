import {
    establishUserDetails,
    establishUserDetailsFailure,
    establishUserDetailsSuccess,
} from 'redux/actions/organizationUserDetails';
import apiRoutes from 'config/apiRoutes';

const establishOrganizationUserDetailsThunk = (
    getAccessTokenSilently,
    logout,
    userId
) => (dispatch) => {
    dispatch(establishUserDetails());

    return getAccessTokenSilently()
        .then((token) => {
            fetch(apiRoutes.get.organizationUserDetails(userId), {
                method: 'GET',
                headers: {
                    organization_slug: window.localStorage.getItem(
                        'MP_ORGANIZATION'
                    ),
                    Authorization: `Token token="${token}"`,
                },
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.error) {
                        return dispatch(establishUserDetailsFailure(res.error));
                    }

                    return dispatch(establishUserDetailsSuccess(res));
                })
                .catch((err) =>
                    dispatch(
                        establishUserDetailsFailure({
                            err,
                        })
                    )
                );
        })
        .catch(() => logout({ returnTo: window.location.origin }));
};

export default establishOrganizationUserDetailsThunk;
