import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import establishOrganizationTeamsThunk from 'redux/actions/thunks/organizationTeams';
import UsersFiltersComponent from 'components/UsersFilters';
import { useAuth0 } from '@auth0/auth0-react';

const UsersFilters = ({ role, name, team, tags }) => {
    const { getAccessTokenSilently, logout } = useAuth0();
    const dispatch = useDispatch();
    const [teams, setTeams] = useState([]);
    const [teamsToSelect, setTeamsToSelect] = useState([]);

    const { organizationTeams, areOrganizationTeamsEstablished } = useSelector(
        (state) => state.organizationTeams
    );

    const clearFilters = () => {
        role.setFilter(undefined);
        team.setFilter('');
        name.setFilter('');
        tags.setFilter({ role_job: [], department: [] });
    };

    useEffect(() => {
        dispatch(
            establishOrganizationTeamsThunk(
                getAccessTokenSilently,
                logout,
                150,
                0
            )
        );
    }, []);

    useEffect(() => {
        setTeamsToSelect(
            teams.filter((item) =>
                item.title
                    .toString()
                    .toLowerCase()
                    .includes(team.value.toString().toLowerCase())
            )
        );
    }, [team.value]);

    useEffect(() => {
        setTeamsToSelect(teams);
    }, [teams]);

    useEffect(() => {
        if (areOrganizationTeamsEstablished) {
            setTeams(
                organizationTeams.map((organizationTeam) => ({
                    id: organizationTeam.id,
                    imgUrl: organizationTeam.picture_url,
                    title: organizationTeam.name,
                }))
            );
        }
    }, [areOrganizationTeamsEstablished, organizationTeams]);

    return (
        <UsersFiltersComponent
            clearFilters={clearFilters}
            filters={{
                role: {
                    value: role.value,
                    setFilter: role.setFilter,
                },
                name: {
                    value: name.value,
                    setFilter: name.setFilter,
                },
                team: {
                    value: team.value,
                    setFilter: team.setFilter,
                    options: teamsToSelect,
                },
                tags: {
                    value: tags.value,
                    setFilter: tags.setFilter,
                },
            }}
        />
    );
};

UsersFilters.propTypes = {
    role: PropTypes.shape({
        value: PropTypes.string,
        setFilter: PropTypes.func,
    }).isRequired,
    team: PropTypes.shape({
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.objectOf(PropTypes.any),
        ]),
        setFilter: PropTypes.func,
    }).isRequired,
    name: PropTypes.shape({
        value: PropTypes.string,
        setFilter: PropTypes.func,
    }).isRequired,
    tags: PropTypes.shape({
        value: PropTypes.string,
        setFilter: PropTypes.func,
    }).isRequired,
};

export default UsersFilters;
