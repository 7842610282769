export const ESTABLISH_METRIC_UPLOADS = 'ESTABLISH_METRIC_UPLOADS';
export const ESTABLISH_METRIC_UPLOADS_SUCCESS =
    'ESTABLISH_METRIC_UPLOADS_SUCCESS';
export const ESTABLISH_METRIC_UPLOADS_FAILURE =
    'ESTABLISH_METRIC_UPLOADS_FAILURE';

export const LOAD_MORE_METRIC_UPLOADS = 'LOAD_MORE_METRIC_UPLOADS';
export const MARK_ALL_METRIC_UPLOADS_LOADED = 'MARK_ALL_METRIC_UPLOADS_LOADED';
export const RESET_METRIC_UPLOADS = 'RESET_METRIC_UPLOADS';

export const DESTROY_METRIC_UPLOAD = 'DESTROY_METRIC_UPLOAD';

export const establishMetricUploads = () => ({
    type: ESTABLISH_METRIC_UPLOADS,
});

export const establishMetricUploadsSuccess = (payload) => ({
    type: ESTABLISH_METRIC_UPLOADS_SUCCESS,
    payload,
});

export const establishMetricUploadsFailure = (err) => ({
    type: ESTABLISH_METRIC_UPLOADS_FAILURE,
    err,
});

export const destroyMetricUpload = () => ({
    type: DESTROY_METRIC_UPLOAD,
});

export const loadMoreMetricUploads = (payload) => ({
    type: LOAD_MORE_METRIC_UPLOADS,
    payload,
});

export const allMetricUploadsLoaded = () => ({
    type: MARK_ALL_METRIC_UPLOADS_LOADED,
});

export const resetMetricUploads = () => ({
    type: RESET_METRIC_UPLOADS,
});
