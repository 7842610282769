import styled from 'styled-components/macro';

export const StyledSettingsWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

export const Grid = styled.div`
    padding: 0 16px;
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 16px;

    @media (max-width: ${({ theme }) => `${theme.breakpoints.medium}px`}) {
        grid-template-columns: 1fr;
    }
`;

export const StyledRemindersHolder = styled.div`
    width: 100%;
    margin: 16px;
    display: flex;
`;

export const StyledUpcomingRemindersHolder = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 3;

    @media (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
        max-width: calc(100vw - 48px);
    }
`;

export const StyledCalendarHolder = styled.div`
    flex-grow: 1;
`;

export const ClearRemindersHolder = styled.div`
    padding: 8px 16px 16px 16px;
    display: flex;
    flex-direction: row;
    align-items: center;

    span {
        color: ${({ theme }) => theme.color.textLight};
        display: inline-block;
        margin-right: 16px;
    }
`;
