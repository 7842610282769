export const ESTABLISH_SUPER_ADMIN_USERS = 'ESTABLISH_SUPER_ADMIN_USERS';
export const ESTABLISH_SUPER_ADMIN_USERS_SUCCESS =
    'ESTABLISH_SUPER_ADMIN_USERS_SUCCESS';
export const ESTABLISH_SUPER_ADMIN_USERS_FAILURE =
    'ESTABLISH_SUPER_ADMIN_USERS_FAILURE';
export const LOAD_MORE_SUPER_ADMIN_USERS = 'LOAD_MORE_SUPER_ADMIN_USERS';
export const MARK_ALL_SUPER_ADMIN_USERS_LOADED =
    'MARK_ALL_SUPER_ADMIN_USERS_LOADED';
export const RESET_SUPER_ADMIN_USERS = 'RESET_SUPER_ADMIN_USERS';

export const establishSuperAdminUsers = () => ({
    type: ESTABLISH_SUPER_ADMIN_USERS,
});

export const loadMoreSuperAdminUsers = (payload) => ({
    type: LOAD_MORE_SUPER_ADMIN_USERS,
    payload,
});

export const allSuperAdminUsersLoaded = () => ({
    type: MARK_ALL_SUPER_ADMIN_USERS_LOADED,
});

export const establishSuperAdminUsersSuccess = (payload) => ({
    type: ESTABLISH_SUPER_ADMIN_USERS_SUCCESS,
    payload,
});

export const establishSuperAdminUsersFailure = (err) => ({
    type: ESTABLISH_SUPER_ADMIN_USERS_FAILURE,
    err,
});

export const resetSuperAdminUsers = () => ({
    type: RESET_SUPER_ADMIN_USERS,
});
