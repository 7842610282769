import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import useAdmin from 'hooks/useAdmin';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import SplashScreen from 'views/SplashScreen';
import useMPA from 'hooks/useMPA';

// BASICS FOR PROTECTED ROUTING
const ProtectedRoute = ({ component, adminOnly, mpaOnly, ...args }) => {
    const isAdmin = useAdmin();
    const { isCurrentUserMPA } = useMPA();
    const { isAuthenticated, isLoading } = useAuth0();

    if (!isAuthenticated && !isLoading) {
        return <Redirect to="/login" />;
    }

    if (mpaOnly) {
        return (
          <Route
                {...args}
                component={
                    isCurrentUserMPA
                        ? withAuthenticationRequired(component, {
                              onRedirecting() {
                                  return <SplashScreen />;
                              },
                          })
                        : withAuthenticationRequired(() => <>Unauthorized</>)
                }
            />
        );
    }

    if (adminOnly) {
        return (
          <Route
                {...args}
                component={
                    isAdmin
                        ? withAuthenticationRequired(component, {
                              onRedirecting() {
                                  return <SplashScreen />;
                              },
                          })
                        : withAuthenticationRequired(() => <>Unauthorized</>)
                }
            />
        );
    }

    return (
      <Route
            {...args}
            component={withAuthenticationRequired(component, {
                onRedirecting() {
                    return <SplashScreen />;
                },
            })}
        />
    );
};

ProtectedRoute.propTypes = {
    component: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
        PropTypes.object,
    ]).isRequired,
    adminOnly: PropTypes.bool,
    mpaOnly: PropTypes.bool,
};

ProtectedRoute.defaultProps = {
    adminOnly: false,
    mpaOnly: false,
};

export default ProtectedRoute;
