import React from 'react';
import PropTypes from 'prop-types';
import Badge from 'components/Badge';
import { ReactComponent as PersonIcon } from 'assets/svg/person.svg';
import { css } from 'styled-components/macro';
import useAdmin from 'hooks/useAdmin';
import Button from 'components/Button';
import AvatarPlaceholder from 'components/AvatarPlaceholder';
import {
    StyledUserHeader,
    StyledAvatarPlaceholder,
    StyledLabel,
    StyledUserTeams,
} from './UserDetailsHeader.style';

const assignButtonStyles = css`
    display: flex;
    align-items: center;

    svg {
        margin-left: 8px;
    }
`;

const UserDetailsHeader = ({
    firstName,
    lastName,
    email,
    avatarUrl,
    backgroundColor,
    teams,
    setIsAssignModalVisible,
}) => {
    const isAdmin = useAdmin();
    return (
      <StyledUserHeader>
        {avatarUrl ? (
          <AvatarPlaceholder
                    image={avatarUrl}
                    width="80px"
                    height="80px"
                />
            ) : (
              <StyledAvatarPlaceholder color={backgroundColor}>
                {firstName && typeof firstName === 'string' && firstName[0]}
                {lastName && typeof lastName === 'string' && lastName[0]}
              </StyledAvatarPlaceholder>
            )}
        <StyledLabel>
          <strong>
            {firstName} 
            {' '}
            {lastName}
          </strong>
          <small>{email}</small>
        </StyledLabel>
        <StyledLabel>
          <small>In teams:</small>
          <StyledUserTeams>
            {teams &&
                        teams.slice(0, 3).map((team) => (
                          <Badge
                                key={team.name}
                                withMargin
                                customStyles={css`
                                    color: ${team.color};
                                    background-color: ${team.background_color};
                                `}
                            >
                            {team.name}
                          </Badge>
                        ))}
            {' '}
            <small>
              {teams &&
                            teams.length > 3 &&
                            `and ${teams.length - 3} more`}
            </small>
          </StyledUserTeams>
        </StyledLabel>
        <StyledLabel pullRight>
          {isAdmin && (
            <Button
                        customStyles={assignButtonStyles}
                        onClick={() => setIsAssignModalVisible(true)}
                        primary
                        svg
                    >
              Assign 
              {' '}
              <PersonIcon />
            </Button>
                )}
        </StyledLabel>
      </StyledUserHeader>
    );
};

UserDetailsHeader.propTypes = {
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    avatarUrl: PropTypes.string,
    backgroundColor: PropTypes.string,
    setIsAssignModalVisible: PropTypes.func.isRequired,
    teams: PropTypes.arrayOf(PropTypes.any),
};

UserDetailsHeader.defaultProps = {
    firstName: '',
    lastName: '',
    email: '',
    teams: [],
    avatarUrl: '',
    backgroundColor: 'white',
};

export default UserDetailsHeader;
