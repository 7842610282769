import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as MemoInfo } from 'assets/svg/notifications/memo-info.svg';
import { ReactComponent as MemoWarning } from 'assets/svg/notifications/memo-warning.svg';
import useDate, { DATE_TYPES } from 'hooks/useDate';
import Button from 'components/Button';
import {
    SingleAdminMemoHolder,
    IconHolder,
    MiddleSection,
    Name,
    DateHolder,
    ActionsHolder,
} from './SingleAdminMemo.style';

const SingleAdminMemo = ({
    adminMemoId,
    name,
    type,
    sendDate,
    discardedAt,
    revertAdminMemo,
}) => {
    const getTime = (creationDate) =>
        creationDate.toLocaleString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
        });

    const GetDate = (date) => useDate(date, DATE_TYPES.long);

    const renderDate = (date) => {
        const timestamp = new Date(date);
        if (Date.now() - timestamp.getTime() < 0) {
            return `To be send on: ${GetDate(timestamp)}`;
        }

        const days = Math.floor(
            (Date.now() - new Date(date).getTime()) / 1000 / 86400
        );

        if (days === 0) {
            return `Today, ${getTime(timestamp)}`;
        }

        if (days === 1) {
            return `Yesterday, ${getTime(timestamp)}`;
        }

        return GetDate(timestamp);
    };

    const renderIcon = (value) => {
        switch (value) {
            case 'informational_memo':
                return <MemoInfo type={value} />;
            case 'critical_memo':
                return <MemoWarning type={value} />;
            default:
                return null;
        }
    };

    const renderActions = (date, id) => {
        if (discardedAt) {
            return (
              <ActionsHolder>
                <p>Discarded</p>
              </ActionsHolder>
            );
        }

        if (Date.now() - new Date(date).getTime() < 0) {
            return (
              <ActionsHolder>
                <Button primary onClick={() => revertAdminMemo(id)}>
                  Revert
                </Button>
              </ActionsHolder>
            );
        }

        return (
          <ActionsHolder>
            <p>Already sent</p>
          </ActionsHolder>
        );
    };

    return (
      <SingleAdminMemoHolder>
        <IconHolder type={type}>{renderIcon(type)}</IconHolder>
        <MiddleSection>
          <Name>{name}</Name>
          <DateHolder>{renderDate(sendDate)}</DateHolder>
        </MiddleSection>
        {renderActions(sendDate, adminMemoId)}
      </SingleAdminMemoHolder>
    );
};

SingleAdminMemo.propTypes = {
    adminMemoId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    sendDate: PropTypes.string.isRequired,
    discardedAt: PropTypes.string,
    revertAdminMemo: PropTypes.func,
};

SingleAdminMemo.defaultProps = {
    discardedAt: undefined,
    revertAdminMemo: undefined,
};

export default SingleAdminMemo;
