import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as TeamIcon } from 'assets/svg/sidebarIcons/team.svg';
import Checkbox from 'components/Checkbox';

import {
    DropdownOption,
    WrapperInfoOption,
    DropdownOptionTexts,
    EmptyAvatar,
    DropdownAvatar,
} from './DropdownItem.style';

const DropdownItem = ({ type, data, select, isSelected }) => {
    switch (type) {
        case 'user':
            return (
                <DropdownOption>
                    <WrapperInfoOption>
                        {data.avatar_url ? (
                            <DropdownAvatar>
                                <img
                                    src={data.avatar_url}
                                    alt={data.first_name}
                                />
                            </DropdownAvatar>
                        ) : (
                            <EmptyAvatar
                                color={data.color}
                                backgroundColor={data.background_color}
                            >
                                {`${data.first_name && data.first_name[0]}${
                                    data.last_name && data.last_name[0]
                                }`}
                            </EmptyAvatar>
                        )}

                        <DropdownOptionTexts>
                            <span>
                                {data.first_name} {data.last_name}
                            </span>
                            {data.email && <small>{data.email}</small>}
                        </DropdownOptionTexts>
                    </WrapperInfoOption>
                    <Checkbox toggleSelected={select} isSelected={isSelected} />
                </DropdownOption>
            );

        case 'team':
            return (
                <DropdownOption onClick={select} type={type}>
                    {data.picture_url ? (
                        <DropdownAvatar>
                            <img src={data.picture_url} alt={data.name} />
                        </DropdownAvatar>
                    ) : (
                        <EmptyAvatar backgroundColor={data.background_color}>
                            <TeamIcon
                                fill={data.color}
                                style={{ maxWidth: '50%' }}
                            />
                        </EmptyAvatar>
                    )}

                    <DropdownOptionTexts>
                        <span>{data.name}</span>
                        {data.recent_users && (
                            <small>
                                {data.recent_users.length === 1
                                    ? `${data.recent_users.length} user`
                                    : `${data.recent_users.length} users`}
                            </small>
                        )}
                    </DropdownOptionTexts>
                </DropdownOption>
            );

        default:
            return <DropdownOption />;
    }
};

DropdownItem.propTypes = {
    type: PropTypes.string.isRequired,
    data: PropTypes.objectOf(PropTypes.any).isRequired,
    select: PropTypes.func.isRequired,
    isSelected: PropTypes.func.isRequired,
};

export default DropdownItem;
