import styled, { css } from 'styled-components/macro';

export const ModalBody = styled.div`
    display: flex;
    flex-flow: column nowrap;
    max-height: 80vh;
`;

export const SectionButtons = styled.span`
    display: flex;
    flex-flow: row;
    padding: 16px;
`;

export const Row = styled.span`
    display: grid;
    grid-template-columns: 1fr 5fr;
    grid-gap: 16px;
`;

export const InputSection = styled.div`
    width: 100%;
    height: 100%;
    padding: 24px;
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-gap: 16px;
    background-color: ${({ theme, shadow }) =>
        shadow ? theme.color.bgBase : theme.color.white};

    ${({ fixedHeight }) =>
        fixedHeight &&
        css`
            height: 95px;
            display: flex;
            align-items: center;
        `}

    ${({ borderTop }) =>
        borderTop &&
        css`
            border-top: ${({ theme }) => theme.border.base};
        `}

    ${({ borderBottom }) =>
        borderBottom &&
        css`
            border-bottom: ${({ theme }) => theme.border.base};
        `}


    ${({ noPaddingTop }) =>
        noPaddingTop &&
        css`
            padding-top: 0px;
        `}

    ${({ noPaddingBottom }) =>
        noPaddingBottom &&
        css`
            padding-bottom: 0px;
        `}

    ${({ borderRadius }) =>
        borderRadius &&
        css`
            border-radius: ${({ theme }) => theme.border.radiusBase};
        `}

    ${({ bottomBorderRadius }) =>
        bottomBorderRadius &&
        css`
            border-radius: ${({ theme }) => theme.border.bottomBorderRadius};
        `}
`;

export const Title = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    color: ${({ theme }) => theme.color.textLight};

    ${({ dark }) =>
        dark &&
        css`
            color: ${({ theme }) => theme.color.textBase};
        `}

    ${({ noCentered }) =>
        noCentered &&
        css`
            padding-top: 16px;
            align-items: flex-start;
        `}
`;
