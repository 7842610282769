import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import PerformanceDashboard from 'containers/PerformanceDashboard/PerformanceDashboard';
import SectionHeader from 'components/SectionHeader';
import StatisticBox from 'components/StatisticBox';
import PerformanceMetrics from 'containers/PerformanceMetrics/PerformanceMetrics';
import useAdmin from 'hooks/useAdmin';
import { ReactComponent as PerformanceIcon } from 'assets/svg/sidebarIcons/performance.svg';

import PerformanceSectionWrapper, {
    StatisticsWrapper,
    ModalLayout,
    ModalIconHolder,
    ModalContent,
    ModalParagraph,
    StyledH3,
    ModalAnchor,
    StyledCloseIcon,
    StyledCounter,
} from './Performance.style';

const PerformanceCoachingDisabledModal = (props) => {
    const { dismiss } = props;

    const { performanceCoachingRequestsCount } = useSelector(
        (state) => state.tenantOrganization
    );

    return (
      <ModalLayout>
        <div>
          <ModalIconHolder>
            <PerformanceIcon fill="white" />
          </ModalIconHolder>
        </div>
        <ModalContent>
          <StyledH3>Performance Coaching is not enabled.</StyledH3>
          <ModalParagraph>
            MedPower Performance Coaching is the perfect way to share
            personalized performance data and targeted training tips
            with providers, nurses and staff. You can configure this
            feature. To enable it for users, please contact MedPower.
          </ModalParagraph>
          {Number.isInteger(performanceCoachingRequestsCount) && (
            <ModalParagraph>
              <StyledCounter>
                {performanceCoachingRequestsCount}
                {' '}
                {performanceCoachingRequestsCount === 1
                                ? 'person'
                                : 'people'}
              </StyledCounter>
              {' '}
              <span>
                {' '}
                in your organization
                {' '}
                {performanceCoachingRequestsCount === 1
                                ? 'is'
                                : 'are'}
                {' '}
                interested in this feature
              </span>
            </ModalParagraph>
                )}
          <div style={{ marginTop: '8px' }}>
            <ModalAnchor
                        href="https://medpower.com/performance-coaching"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
              Learn More
            </ModalAnchor>
            <ModalAnchor href="mailto:info@medpower.com?subject=Performance%20Coaching">
              Contact MedPower
            </ModalAnchor>
            {' '}
          </div>
        </ModalContent>
        <div onClick={() => dismiss()}>
          <StyledCloseIcon fill="#718FBF" />
        </div>
      </ModalLayout>
    );
};

PerformanceCoachingDisabledModal.propTypes = {
    dismiss: PropTypes.func.isRequired,
};

const PerformanceView = ({
    achievementsSection,
    handleMetricLoaded,
    userLevel,
    userScore,
    pointsToNextLevel,
}) => {
    const isAdmin = useAdmin();
    const [isModalDismissed, setIsModalDismissed] = useState(false);

    useEffect(() => {
        if (localStorage.getItem('PERFORMANCE_COACHING_MODAL_DISMISSED')) {
            setIsModalDismissed(true);
        } else {
            setIsModalDismissed(false);
        }
    }, []);

    const { performanceCoaching, isEstablished } = useSelector(
        (state) => state.tenantOrganization
    );

    const canRenderPCModal = () =>
        isAdmin &&
        isEstablished &&
        !isModalDismissed &&
        performanceCoaching === false;

    const dismissModal = () => {
        localStorage.setItem('PERFORMANCE_COACHING_MODAL_DISMISSED', true);
        setIsModalDismissed(true);
    };

    return (
      <>
        <SectionHeader title="Performance" withPadding />
        {canRenderPCModal() && (
        <PerformanceSectionWrapper withPadding>
          <PerformanceCoachingDisabledModal dismiss={dismissModal} />
        </PerformanceSectionWrapper>
            )}

        <PerformanceSectionWrapper withPadding>
          <PerformanceDashboard />
        </PerformanceSectionWrapper>
        <PerformanceSectionWrapper withPadding>
          <PerformanceMetrics handleMetricLoaded={handleMetricLoaded} />
        </PerformanceSectionWrapper>
        <SectionHeader title="Achievements" withPadding />
        <StatisticsWrapper withPadding ref={achievementsSection}>
          <StatisticBox
                    metric={userScore || 0}
                    label="Total earned Stars"
                    type="stars-earned"
                />
          <StatisticBox
                    metric={userLevel ? `${userLevel} level` : '0 level'}
                    label="Your current Level"
                    type="level"
                />
          <StatisticBox
                    metric={pointsToNextLevel || 0}
                    label="Stars to next Level"
                    type="stars-to-next-level"
                />
        </StatisticsWrapper>
      </>
    );
};

PerformanceView.propTypes = {
    achievementsSection: PropTypes.objectOf(PropTypes.any).isRequired,
    handleMetricLoaded: PropTypes.func.isRequired,
    userLevel: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    userScore: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    pointsToNextLevel: PropTypes.string,
};

PerformanceView.defaultProps = {
    userLevel: undefined,
    userScore: undefined,
    pointsToNextLevel: undefined,
};

export default PerformanceView;
