import styled, { css } from 'styled-components/macro';
import { ReactComponent as CloseIcon } from 'assets/svg/close.svg';
// import recommendationBg from 'assets/svg/robot.svg';
// import tipBg from 'assets/svg/tip-woman.svg';
// import tipOfTheWeekBg from 'assets/svg/yellow-jacket-woman.svg';
import Button from 'components/Button';

export const StyledSmartTip = styled.div`
    width: 450px;
    max-width: 450px;
    min-width: 450px;
    height: 262px;
    padding: 24px;
    margin: 16px;
    position: relative;
    border: ${({ theme }) => theme.border.base};
    border-radius: ${({ theme }) => theme.border.radiusBase};
    background-color: ${({ theme }) => theme.color.white};
    background-repeat: no-repeat;
    background-position-x: right;
    background-size: contain;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: all 0.3s ease;
    overflow: hidden;

    @media screen and (max-width: ${({ theme }) =>
            `${theme.breakpoints.small}px`}) {
        min-width: calc(100vw - 48px);
        max-width: calc(100vw - 48px);
    }

    small {
        color: ${({ theme }) => theme.color.textNavigationSuperLight};
        font-size: ${({ theme }) => theme.fontSize.base};
    }

    strong {
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        margin: 16px 0;
    }

    small,
    strong,
    span {
        user-select: none;
    }

    ${({ type }) => {
        if (type === 'recommendation') {
            return css`
                border: none;
                background-color: ${({ theme }) => theme.color.primary};
                strong,
                span {
                    color: ${({ theme }) => theme.color.white};
                }
            `;
        }

        if (type === 'tip_of_the_week') {
            return css`
                border: none;
                background-color: ${({ theme }) => theme.color.violet};
                strong,
                span {
                    color: ${({ theme }) => theme.color.white};
                }
            `;
        }
        if (type === 'tip') {
            return css`
                background-size: 190px auto;
                background-position-y: center;
            `;
        }

        return null;
    }}

    ${({ size, type }) =>
        size === 'compact' &&
        css`
            width: 326px;
            height: 256px;
            background-size: 60%;
            background-position-y: bottom;

            ${type === 'tip' &&
            css`
                background-position-x: calc(100% + 16px);
                background-size: 46%;
            `};
        `}
    
     ${({ size }) =>
        size !== 'compact' &&
        css`
            @media screen and (max-width: ${({ theme }) =>
                    `${theme.breakpoints.mobile}px`}) {
                min-width: calc(100vw - 80px);
                max-width: calc(100vw - 80px);
            }
        `}
`;

export const StyledButtonGroup = styled.div`
    width: 330px;
    margin-top: auto;
    display: flex;
    align-items: center;
    position: relative;

    svg {
        margin-right: 8px;
    }

    button {
        margin-right: 16px;
    }

    z-index: 2;
`;

export const StyledTitle = styled.div`
    display: flex;
    flex-direction: column;
    width: 230px;

    ${({ isSmartTip, isRecommendation, isTipOfTheWeek, theme }) => {
        if (isSmartTip) {
            return css`
                small {
                    color: ${theme.color.textBase};
                }
            `;
        }
        if (isRecommendation) {
            return css`
                small {
                    color: ${theme.color.white};
                }
            `;
        }
        if (isTipOfTheWeek) {
            return css`
                small {
                    color: ${theme.color.white};
                }
            `;
        }
        return css``;
    }}

    z-index: 2;
`;

export const StyledCloseIcon = styled(CloseIcon)`
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
    z-index: 2;

    fill: ${({ theme, type }) => {
        switch (type) {
            case 'tip':
                return theme.color.borderColorBase;
            case 'recommendation':
                return theme.color.darkBlue;
            case 'tip_of_the_week':
                return theme.color.violetVeryDark;
            default:
                return theme.color.textBase;
        }
    }};
`;

export const StyledButton = styled(Button)`
    padding: 0px;
    height: 49px;
    width: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const StyledCompactButton = styled(StyledButton)`
    width: 48px;

    svg {
        margin: auto;
        pointer-events: none;
    }

    path {
        pointer-events: auto;
    }
`;

export const StyledNewTipMsg = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;

    position: absolute;
    width: 50px;
    height: 31px;
    right: 16px;
    top: 16px;

    border-radius: ${({ theme }) => theme.border.radiusBase};

    background: ${({ theme }) => theme.color.bgQuaternary};
    font-size: ${({ theme }) => theme.fontSize.small};
    color: ${({ theme }) => theme.color.pinkLight};
`;
