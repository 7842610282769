import React from 'react';
import PropTypes from 'prop-types';
import SectionHeader from 'components/SectionHeader';
import Spinner from 'components/Spinner';
import InfiniteScroll from 'react-infinite-scroller';
import SingleCustomer from 'containers/SingleCustomer';
import { useHistory } from 'react-router-dom';
import Button from 'components/Button';
import { MPA_ROUTES } from 'routing/constants';
import { Wrapper, ListWrapper } from './MPACustomers.style';

const MPACustomers = ({ customers, hasMore, loadMore }) => {
    const history = useHistory();

    return (
      <Wrapper>
        <SectionHeader
                title="Organizations"
                cta={() => (
                  <Button
                        primaryDark
                        onClick={() => history.push(MPA_ROUTES.addCustomer)}
                    >
                    New Organization
                  </Button>
                )}
            />
        {!customers ? (
          <Spinner />
            ) : (
              <ListWrapper>
                <InfiniteScroll
                        initialLoad={false}
                        loadMore={loadMore}
                        loader={<Spinner key="spinner" />}
                        hasMore={hasMore}
                    >
                  {customers.map((customer) => (
                    <SingleCustomer
                                customer={customer}
                                key={customer.id}
                            />
                        ))}
                </InfiniteScroll>
              </ListWrapper>
            )}
      </Wrapper>
    );
};

MPACustomers.propTypes = {
    customers: PropTypes.arrayOf(PropTypes.any).isRequired,
    hasMore: PropTypes.bool.isRequired,
    loadMore: PropTypes.func.isRequired,
};

export default MPACustomers;
