import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { getHoursFromTime, validateTime } from 'redux/helpers/reminders';
import MPAAddAdminMemoModalComponent from 'components/MPAAddAdminMemoModal';
import {
    SEND_TO_OPTIONS,
    sendToOptions,
    typeOptions,
} from 'components/MPAAddAdminMemoModal/MPAAddAdminMemoModal';
import { createMPAAdminMemoThunk } from 'redux/actions/thunks/mpaAdminMemos';
import { useAuth0 } from '@auth0/auth0-react';

const MPAAddAdminMemoModal = ({ closeModal, reloadAdminMemos }) => {
    const { getAccessTokenSilently, logout } = useAuth0();
    const [dateTime, setDateTime] = useState(null);
    const [hour, setHour] = useState('12:30 PM');
    const [date, setDate] = useState(new Date());
    const [message, setMessage] = useState('');
    const [admins, setAdmins] = useState([]);
    const [sendTo, setSendTo] = useState(sendToOptions[0]);
    const [type, setType] = useState(typeOptions[0]);
    const dispatch = useDispatch();

    const getIds = () => {
        switch (sendTo.id) {
            case SEND_TO_OPTIONS.send_to_selected_admins:
                return admins;
            default:
                return [];
        }
    };

    const createAdminMemo = () => {
        dispatch(
            createMPAAdminMemoThunk(
                getAccessTokenSilently,
                logout,
                {
                    admin_memo: {
                        memo_type: type.id,
                        send_to: sendTo.id,
                        send_at: dateTime.toJSON(),
                        ids: getIds(),
                        message,
                    },
                },
                reloadAdminMemos
            )
        );
        closeModal();
    };

    const setTime = (val) => {
        if (validateTime(val)) {
            setHour(val);
        }
    };

    useEffect(() => {
        const newDate = date;
        newDate.setHours(getHoursFromTime(hour));
        newDate.setMinutes(
            hour.slice(hour.indexOf(':') + 1, hour.indexOf(':') + 3)
        );
        setDateTime(newDate);
    }, [hour, date]);

    return (
      <MPAAddAdminMemoModalComponent
            closeModal={closeModal}
            sendTo={sendTo}
            setSendTo={setSendTo}
            type={type}
            setType={setType}
            hour={hour}
            setHour={setTime}
            date={date}
            setDate={setDate}
            message={message}
            setMessage={setMessage}
            setAdmins={setAdmins}
            createAdminMemo={createAdminMemo}
        />
    );
};

MPAAddAdminMemoModal.propTypes = {
    closeModal: PropTypes.func.isRequired,
    reloadAdminMemos: PropTypes.func,
};

MPAAddAdminMemoModal.defaultProps = {
    reloadAdminMemos: () => {},
};

export default MPAAddAdminMemoModal;
