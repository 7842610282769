import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, useFormikContext } from 'formik';
import Input from 'components/Input';
import { ReactComponent as UploadArrow } from 'assets/svg/upload-onboarding.svg';
import { ReactComponent as UploadSuccess } from 'assets/svg/check-circle.svg';
import theme from 'assets/theme/mainTheme';
import {
    AvatarContainer,
    ProfileImgHolder,
    UploadSuccessIcon,
    UploadMiniSpinner,
    HoverableOverlay,
} from './UploadAvatarOnboarding.style';

const UploadAvatarOnboarding = ({
    avatarPreview,
    setAvatarPreview,
    userDataLoading,
    wasFormSubmitted,
    resetFormSubmitted,
    ...rest
}) => {
    const avatarRef = React.createRef();
    const { values, submitForm } = useFormikContext();

    const { errors, setFieldValue } = rest;

    useEffect(() => {
        if (!errors.avatar && values.avatar) {
            submitForm();
        }
    }, [values, errors, submitForm]);

    return (
      <>
        <AvatarContainer
                title="Click to change the avatar"
                onClick={() => {
                    resetFormSubmitted();
                    return avatarRef.current.click();
                }}
            >
          <ProfileImgHolder alt="Upload profile picture">
            {avatarPreview ? (
              <>
                <HoverableOverlay>
                  <UploadArrow fill={theme.color.white} />
                  Upload new
                </HoverableOverlay>
                <img src={avatarPreview} alt="Upload profile pic" />
              </>
                    ) : (
                      <UploadArrow fill={theme.color.white} />
                    )}
          </ProfileImgHolder>
          {wasFormSubmitted && (
            <UploadSuccessIcon>
              <UploadSuccess fill={theme.color.checkGreen} />
            </UploadSuccessIcon>
                )}
          {userDataLoading && (
            <UploadSuccessIcon darkBackground>
              <UploadMiniSpinner />
            </UploadSuccessIcon>
                )}
        </AvatarContainer>
        <Form>
          <Input
                    type="file"
                    ref={avatarRef}
                    name="avatar"
                    accept="image/png, image/jpeg"
                    style={{ display: 'none' }}
                    onChange={(e) => {
                        if (!e.target.files || !e.target.files[0]) return;
                        setAvatarPreview(
                            URL.createObjectURL(e.target.files[0])
                        );
                        setFieldValue('avatar', e.target.files[0]);
                    }}
                    error={errors.avatar}
                />
        </Form>
      </>
    );
};

UploadAvatarOnboarding.propTypes = {
    avatarPreview: PropTypes.string.isRequired,
    setAvatarPreview: PropTypes.func.isRequired,
    userDataLoading: PropTypes.bool.isRequired,
    wasFormSubmitted: PropTypes.bool.isRequired,
    resetFormSubmitted: PropTypes.func.isRequired,
};

export default UploadAvatarOnboarding;
