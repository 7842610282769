import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/Modal';
import Button from 'components/Button';
import { ReactComponent as CloseModalIcon } from 'assets/svg/close-modal.svg';
import {
    StyledModalBody,
    StyledModalButtonGroup,
    ModalCustomStyle,
} from './ConfirmActionModal.style';

const ConfirmActionModal = ({
    isVisible,
    onToggle,
    onConfirm,
    title,
    description,
    confirmLabel,
    irreversible,
    large,
}) => {
    if (!isVisible) {
        return null;
    }

    return (
      <Modal absolute customStyles={ModalCustomStyle} closeModal={onToggle}>
        <StyledModalBody data-testid="confirm-action-modal" large={large}>
          <CloseModalIcon onClick={onToggle} />
          <h2>{title}</h2>
          <small>{description}</small>
          {irreversible && <small>This action is irreversible!</small>}
          <StyledModalButtonGroup>
            <Button primary onClick={onToggle}>
              Cancel
            </Button>
            <Button
                        data-testid="confirm-action-button"
                        quaternary
                        onClick={() => {
                            onConfirm();
                            onToggle();
                        }}
                    >
              {confirmLabel}
            </Button>
          </StyledModalButtonGroup>
        </StyledModalBody>
      </Modal>
    );
};

ConfirmActionModal.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    onToggle: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    title: PropTypes.string,
    description: PropTypes.string.isRequired,
    confirmLabel: PropTypes.string,
    irreversible: PropTypes.bool,
    large: PropTypes.bool,
};

ConfirmActionModal.defaultProps = {
    title: 'Are you sure?',
    confirmLabel: 'Confirm',
    irreversible: false,
    large: false,
};

export default ConfirmActionModal;
