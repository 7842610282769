import styled, { css } from 'styled-components/macro';

const Button = styled.button`
    padding: 16px;
    border-radius: ${({ theme }) => theme.border.radiusBase};
    font-size: ${({ theme }) => theme.fontSize.base};
    font-weight: 500;
    border: none;
    margin: 0;
    user-select: none;
    white-space: nowrap;
    justify-content: center;

    ${({ primary }) =>
        primary &&
        css`
            background-color: ${({ theme }) => theme.color.bgPrimaryLight};
            color: ${({ theme }) => theme.color.primary};
        `};

    ${({ primaryDark }) =>
        primaryDark &&
        css`
            background-color: ${({ theme }) => theme.color.primaryDark};
            color: ${({ theme }) => theme.color.white};
        `};

    ${({ secondary }) =>
        secondary &&
        css`
            background-color: ${({ theme }) => theme.color.bgSecondaryLight};
            color: ${({ theme }) => theme.color.secondaryDark};
        `};

    ${({ tertiary }) =>
        tertiary &&
        css`
            background-color: ${({ theme }) => theme.color.bgTertiaryLight};
            color: ${({ theme }) => theme.color.tertiary};
        `};

    ${({ textLight }) =>
        textLight &&
        css`
            background-color: transparent;
            color: ${({ theme }) => theme.color.textLight};
        `};

    ${({ tertiaryDark }) =>
        tertiaryDark &&
        css`
            background-color: ${({ theme }) => theme.color.tertiary};
            color: ${({ theme }) => theme.color.white};
        `};

    ${({ quaternary }) =>
        quaternary &&
        css`
            background-color: ${({ theme }) => theme.color.bgQuaternaryLight};
            color: ${({ theme }) => theme.color.quaternary};
        `};

    ${({ white }) =>
        white &&
        css`
            background-color: ${({ theme }) => theme.color.white};
            color: ${({ theme }) => theme.color.base};
        `};

    ${({ transparent }) =>
        transparent &&
        css`
            background-color: transparent;
            color: ${({ theme }) => theme.color.base};
            border: ${({ theme }) => theme.border.base};
        `};

    ${({ borderless }) =>
        borderless &&
        css`
            border: none;
        `};

    ${({ violetDark }) =>
        violetDark &&
        css`
            background-color: ${({ theme }) => theme.color.violetDark};
            color: ${({ theme }) => theme.color.base};
        `};

    ${({ s }) =>
        s &&
        css`
            padding: 8px 13px;
        `};

    ${({ xs }) =>
        xs &&
        css`
            padding: 5px 10px;
        `};

    ${({ fullWidth }) =>
        fullWidth &&
        css`
            width: 100%;
        `};

    ${({ inline }) =>
        inline &&
        css`
            width: auto;
            &:not(:last-child) {
                margin-right: 16px;
            }
        `};

    ${({ svg }) =>
        svg &&
        css`
            svg {
                margin-left: 8px !important;
            }
        `};

    &:disabled {
        cursor: not-allowed;
        opacity: 0.7;
    }

    ${({ small }) =>
        small &&
        css`
            height: 37px;
            padding: 10px 16px;
        `};

    ${({ customStyles }) =>
        css`
            ${customStyles}
        `}
`;

export default Button;
