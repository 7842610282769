import styled, { css } from 'styled-components/macro';
import Button from 'components/Button';

export const SingleTeamHolder = styled.div`
    ${({ theme }) => css`
        background-color: ${theme.color.white};
        border: ${theme.border.base};
        border-radius: ${theme.border.radiusBase};
    `}

    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 102px;
    margin: 8px 16px 16px;
    padding: 32px;

    a {
        text-decoration: none;
    }

    @media (max-width: ${({ theme }) => `${theme.breakpoints.l}px`}) {
        height: auto;
    }

    @media (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
        flex-wrap: wrap;
    }
`;

export const TitleHolder = styled.div`
    display: flex;
    align-items: center;
    padding: 16px 16px 16px 0;
`;

export const StarsHolder = styled.div`
    display: flex;
    flex-flow: row no-wrap;
    align-items: center;

    p {
        margin-right: 16px;
        color: ${({ theme }) => theme.color.secondary};
    }
`;

export const IconHolder = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.color.bgPrimaryLight};
    margin-right: 32px;
    width: 40px;
    height: 40px;
    border-radius: 50%;

    @media (max-width: ${({ theme }) => `${theme.breakpoints.s}px`}) {
        display: none;
    }

    ${({ currentTeamIndicator }) =>
        currentTeamIndicator &&
        css`
            width: 32px;
            height: 32px;
            position: absolute;
            top: -16px;
            right: -48px;

            ${({ theme }) => css`
                background-color: ${theme.color.white};
                border: ${theme.border.base};
                box-shadow: ${theme.boxShadow.base};
            `}
        `}

    ${({ ranking }) =>
        ranking &&
        css`
            color: ${({ theme }) => theme.color.primary};
        `}

    ${({ gold }) =>
        gold &&
        css`
            background-color: ${({ theme }) => theme.color.gold};
            color: ${({ theme }) => theme.color.white};
        `}

    ${({ silver }) =>
        silver &&
        css`
            background-color: ${({ theme }) => theme.color.silver};
            color: ${({ theme }) => theme.color.white};
        `}


    ${({ bronze }) =>
        bronze &&
        css`
            background-color: ${({ theme }) => theme.color.bronze};
            color: ${({ theme }) => theme.color.white};
        `}

    ${({ starsHolder }) =>
        starsHolder &&
        css`
            width: 24px;
            height: 24px;
            background-color: ${({ theme }) => theme.color.secondaryLight};

            svg {
                width: 12px;
                height: 12px;
            }
        `}
`;

export const Picture = styled.img`
    margin-right: 32px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;

    @media (max-width: ${({ theme }) => `${theme.breakpoints.s}px`}) {
        display: none;
    }
`;

export const Title = styled.div`
    display: flex;
    flex-direction: column;
    width: 200px;

    h3 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: ${({ maxWidth }) => maxWidth || 'auto'};
        margin: 0 0 8px 0;
        padding: 0;
        font-size: ${({ theme }) => theme.fontSize.normal};
        font-weight: 500;
    }

    span {
        color: ${({ theme }) => theme.color.textLight};
        line-height: 1;
    }

    @media (max-width: ${({ theme }) => `${theme.breakpoints.s}px`}) {
        width: auto;
    }
`;

export const UsersHolder = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 32px;
    margin-left: auto;
    width: 320px;

    span {
        color: ${({ theme }) => theme.color.textLight};
        line-height: 1;
        display: block;
    }

    @media (max-width: ${({ theme }) => `${theme.breakpoints.m}px`}) {
        display: none;
    }
`;

export const UserAvatarsHolder = styled.div`
    display: flex;
    height: 24px;
    margin-bottom: 8px;

    img {
        margin-right: 8px;
        height: 24px;
        width: 24px;
        border-radius: 50%;
    }
`;

export const TeamButton = styled(Button)`
    margin-left: 32px;
    padding-left: 24px;
    padding-right: 24px;

    ${({ disabled }) =>
        disabled &&
        css`
            &:hover:before {
                display: block;
            }

            &:before {
                content: attr(data-text);
                position: absolute;
                display: none;

                top: 0px;
                left: 82%;

                padding: 10px;
                text-align: center;

                ${({ theme }) => css`
                    background-color: ${theme.color.black};
                    color: ${theme.color.white};
                    border-radius: ${theme.border.radiusBase};
                    box-shadow: ${theme.boxShadow.base};
                `}
            }
        `}
`;

export const MiniTeamHolder = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 100%;
    height: 40px;

    a {
        text-decoration: none;
    }

    * {
        padding: 0;
    }

    strong {
        font-size: 14px;
    }
`;

export const MiniTitleHolder = styled(TitleHolder)`
    padding: 8px 8px 8px 0;
`;

export const MiniPicture = styled(Picture)`
    margin-right: 16px;
`;

export const MiniIconHolder = styled(IconHolder)`
    margin-right: 16px;
`;

export const MiniTitle = styled(Title)`
    width: auto;

    h3 {
        font-size: ${({ theme }) => theme.fontSize.medium};
    }

    span {
        font-size: ${({ theme }) => theme.fontSize.small};
    }
`;
