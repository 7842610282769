import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import { ReactComponent as SettingsIcon } from 'assets/svg/settings.svg';
import Switch from 'components/Switch';
import {
    NotificationOptionsDropdownHolder,
    Label,
    Divider,
    SwitchWrapper,
    OptionsWrapper,
} from './NotificationsOptionsDropdown.style';

const NotificationDropdown = ({
    isScoreNotificationsEnabled,
    isMessageNotificationsEnabled,
    isEmailNotificationsEnabled,
    isVisible,
    toggleVisible,
    toggleScoreNotificationsOptions,
    toggleMessageNotificationsOptions,
    toggleEmailNotificationsOptions,
}) => (
  <NotificationOptionsDropdownHolder>
    <Button primary onClick={toggleVisible}>
      <SettingsIcon />
    </Button>
    {isVisible && (
    <OptionsWrapper>
      <Label>Team chat notifications</Label>
      <SwitchWrapper>
        <Switch
                        switchId="team-chat-notifications-options"
                        defaultStatus={isMessageNotificationsEnabled}
                        switchOn={toggleMessageNotificationsOptions}
                        switchOff={toggleMessageNotificationsOptions}
                    />
      </SwitchWrapper>
      <Divider />
      <Label>Stars/Points notifications</Label>
      <SwitchWrapper>
        <Switch
                        switchId="message-notifications-options"
                        defaultStatus={isScoreNotificationsEnabled}
                        switchOn={toggleScoreNotificationsOptions}
                        switchOff={toggleScoreNotificationsOptions}
                    />
      </SwitchWrapper>
      <Divider />
      <Label>Email notifications</Label>
      <SwitchWrapper>
        <Switch
                        switchId="email-notifications-options"
                        defaultStatus={isEmailNotificationsEnabled}
                        switchOn={toggleEmailNotificationsOptions}
                        switchOff={toggleEmailNotificationsOptions}
                    />
      </SwitchWrapper>
    </OptionsWrapper>
        )}
  </NotificationOptionsDropdownHolder>
);

NotificationDropdown.propTypes = {
    isScoreNotificationsEnabled: PropTypes.bool,
    isMessageNotificationsEnabled: PropTypes.bool,
    isEmailNotificationsEnabled: PropTypes.bool,
    isVisible: PropTypes.bool.isRequired,
    toggleVisible: PropTypes.func.isRequired,
    toggleScoreNotificationsOptions: PropTypes.func.isRequired,
    toggleMessageNotificationsOptions: PropTypes.func.isRequired,
    toggleEmailNotificationsOptions: PropTypes.func.isRequired,
};

NotificationDropdown.defaultProps = {
    isScoreNotificationsEnabled: undefined,
    isMessageNotificationsEnabled: undefined,
    isEmailNotificationsEnabled: undefined,
};

export default NotificationDropdown;
