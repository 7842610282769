import {
    establishCourses,
    establishCoursesSuccess,
    establishCoursesFailure,
} from 'redux/actions/courses';
import apiRoutes from 'config/apiRoutes';
import parseCourses from 'redux/helpers/courses';
import { dispatchErrorAlertThunk } from './systemAlerts';

const establishCoursesThunk = (getAccessTokenSilently, logout) => (
    dispatch
) => {
    dispatch(establishCourses());

    getAccessTokenSilently()
        .then((token) => {
            fetch(apiRoutes.get.courses(), {
                method: 'GET',
                headers: {
                    organization_slug: window.localStorage.getItem(
                        'MP_ORGANIZATION'
                    ),
                    Authorization: `Token token="${token}"`,
                },
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.error) {
                        dispatch(
                            dispatchErrorAlertThunk(
                                res.error,
                                'There is something wrong with Courses. Please contact MedPower Support.'
                            )
                        );
                        return dispatch(establishCoursesFailure(res.error));
                    }

                    return dispatch(
                        establishCoursesSuccess(parseCourses(res.courses))
                    );
                })
                .catch((err) => {
                    dispatch(
                        dispatchErrorAlertThunk(
                            err.error,
                            'There is something wrong with Courses. Please contact MedPower Support.'
                        )
                    );
                    dispatch(
                        establishCoursesFailure({
                            err,
                        })
                    );
                });
        })
        .catch(() => logout({ returnTo: window.location.origin }));
};

export default establishCoursesThunk;
