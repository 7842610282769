import React from 'react';
import PropTypes from 'prop-types';
import Spinner from 'components/Spinner';
import SectionHeader from 'components/SectionHeader';
import AvatarPlaceholder from 'components/AvatarPlaceholder';
import {
    EditProfileWrapper,
    StyledColumn,
    AvatarContainer,
    ProfileImgHolder,
    UploadIcon,
    FormContainer,
} from './EditUserProfile.style';
import EditUserProfileForm from './EditUserProfileForm';

const EditUserProfile = ({
    userName,
    userEmail,
    isUserFetching,
    avatarPreview,
    setAvatarPreview,
    ...rest
}) => {
    // Ref needed to trigger upload when clicked on the avatar
    const avatarRef = React.createRef();

    if (isUserFetching) {
        return <Spinner />;
    }

    return (
      <>
        <SectionHeader title="Edit profile" withMargin />
        <EditProfileWrapper>
          <StyledColumn>
            <span>Profile picture</span>
            <AvatarContainer
                        title="Click to change the avatar"
                        onClick={() => avatarRef.current.click()}
                    >
              <ProfileImgHolder alt={userName}>
                {avatarPreview ? (
                  <AvatarPlaceholder
                                    image={avatarPreview}
                                    width="100%"
                                    height="100%"
                                />
                            ) : (
                              <AvatarPlaceholder>
                                {userName && userName[0]}
                              </AvatarPlaceholder>
                            )}
              </ProfileImgHolder>
              <UploadIcon />
            </AvatarContainer>
          </StyledColumn>
          <StyledColumn>
            <span>Complete your profile data</span>
            <FormContainer>
              <EditUserProfileForm
                            setAvatarPreview={setAvatarPreview}
                            ref={avatarRef}
                            {...rest}
                        />
            </FormContainer>
          </StyledColumn>
        </EditProfileWrapper>
      </>
    );
};

EditUserProfile.propTypes = {
    userName: PropTypes.string.isRequired,
    userEmail: PropTypes.string.isRequired,
    isUserFetching: PropTypes.bool.isRequired,
    avatarPreview: PropTypes.string.isRequired,
    setAvatarPreview: PropTypes.func.isRequired,
};

export default EditUserProfile;
