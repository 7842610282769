import styled, { css } from 'styled-components/macro';

export const AdminWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    ${({ fullHeight }) =>
        fullHeight &&
        css`
            flex-direction: column;
            min-height: calc(100vh - 250px);
        `}
`;

export const AdminSection = styled.div`
    width: 100%;

    ${({ withPadding }) =>
        withPadding &&
        css`
            padding: 0 16px;
        `}

    ${({ withSpacing }) =>
        withSpacing &&
        css`
            margin: 16px 0;
        `}
`;

export const Container = styled.div`
    background-color: ${({ theme }) => theme.color.white};
    border: ${({ theme }) => theme.border.base};
    border-radius: ${({ theme }) => theme.border.radiusBase};
    display: flex;
    flex-direction: column;
`;

export const Title = styled.div`
    padding: 24px;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    color: ${({ theme }) => theme.color.textBase};
    border-bottom: 1px solid;
    border-color: ${({ theme }) => theme.color.borderColorBase};
`;

export const Content = styled.div`
    padding: 24px;
    border-bottom: 1px solid;
    border-color: ${({ theme }) => theme.color.borderColorBase};
`;

export const STTitle = styled.div`
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    color: ${({ theme }) => theme.color.textLight};
`;

export const ButtonSection = styled.div`
    padding: 24px;
`;

export const DropdownOption = styled.div`
    display: flex;
    align-items: center;

    img,
    svg {
        margin-right: 16px;
        width: 32px;
        height: 32px;
        border-radius: 50%;
    }
`;

export const DropdownOptionTexts = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    span {
        overflow: hidden;
        max-width: 200px;
        text-overflow: ellipsis;
    }

    small {
        overflow: hidden;
        max-width: 200px;
        text-overflow: ellipsis;
        color: ${({ theme }) => theme.color.textLight};
    }
`;

export const InputWrapper = styled.div`
    width: 40%;

    @media (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
        width: auto;
    }
`;
