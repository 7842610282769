import styled, { css } from 'styled-components/macro';

export const SingleSuperAdminUserWrapper = styled.div`
    padding: 27px 32px;
    border: ${({ theme }) => theme.border.base};
    border-radius: ${({ theme }) => theme.border.radiusBase};
    margin-bottom: 24px;
`;

export const Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr 10fr 2fr;
    grid-gap: 8px;
    align-items: center;
`;

export const Avatar = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    cursor: pointer;
`;

export const EmptyAvatar = styled.p`
    cursor: pointer;
    margin: 0px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    text-align: center;
    vertical-align: middle;
    line-height: 40px;
    font-size: 12px;
    font-weight: 600;
    color: ${({ theme }) => theme.color.primary};
    background-color: ${({ theme }) => theme.color.bgPrimaryLight};

    ${({ color }) =>
        color &&
        css`
            color: ${color};
        `}

    ${({ backgroundColor }) =>
        backgroundColor &&
        css`
            background-color: ${backgroundColor};
        `}
`;

export const Name = styled.div`
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: ${({ theme }) => theme.color.textBase};
    padding-bottom: 4px;
    cursor: pointer;
`;

export const Email = styled.div`
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: ${({ theme }) => theme.color.textLight};
    cursor: pointer;
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
`;

export const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 49px;
    height: 49px;
    border-radius: ${({ theme }) => theme.border.radiusBase};
    cursor: pointer;

    &:hover {
        background-color: ${({ theme }) => theme.color.bgPrimaryLight};
    }
`;

export const Divider = styled.div`
    width: 1px;
    height: 45px;
    border-right: 1px solid;
    border-color: ${({ theme }) => theme.color.bgSearch};
    padding-left: 16px;
    margin-right: 16px;
`;

export const DropdownWrapper = styled.div`
    position: relative;
`;

export const Dropdown = styled.div`
    z-index: 1;
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-gap: 8px;
    padding: 16px;
    width: 160px;
    position: absolute;
    border-radius: ${({ theme }) => theme.border.radiusBase};
    background-color: ${({ theme }) => theme.color.white};
    box-shadow: ${({ theme }) => theme.boxShadow.base};
    top: 49px;
    right: 0px;
`;

export const DropdownItem = styled.div`
    padding: 16px;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    color: ${({ theme }) => theme.color.primary};
    border-radius: ${({ theme }) => theme.border.radiusSmall};
    z-index: 1;

    ${({ theme, secondary }) =>
        secondary &&
        css`
            color: ${theme.color.red};
        `}

    &:hover {
        cursor: pointer;
        background-color: ${({ theme }) => theme.color.bgPrimaryLight};

        ${({ theme, secondary }) =>
            secondary &&
            css`
                background-color: ${theme.color.pinkLight};
            `}
    }
`;
