import {
    establishDataSourcesMeasuresUploads,
    establishDataSourcesMeasuresUploadsSuccess,
    establishDataSourcesMeasuresUploadsFailure,
    destroyMetricUpload,
    loadMoreDataSourcesMeasuresUploads,
    allDataSourcesMeasuresUploadsLoaded,
    resetDataSourcesMeasuresUploads,
} from 'redux/actions/mpaDataSourcesMeasuresUploads';
import {
    dispatchSuccessAlertThunk,
    dispatchErrorAlertThunk,
} from 'redux/actions/thunks/systemAlerts';
import apiRoutes from 'config/apiRoutes';

const establishDataSourcesMeasuresUploadsThunk = (
    getAccessTokenSilently,
    logout,
    limit,
    offset
) => (dispatch) => {
    dispatch(establishDataSourcesMeasuresUploads());

    return getAccessTokenSilently()
        .then((token) => {
            fetch(apiRoutes.get.dataSourcesMeasuresUploads(limit, offset), {
                method: 'GET',
                headers: {
                    organization_slug: window.localStorage.getItem(
                        'MP_ORGANIZATION'
                    ),
                    Authorization: `Token token="${token}"`,
                },
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.error) {
                        return dispatch(
                            establishDataSourcesMeasuresUploadsFailure(
                                res.error
                            )
                        );
                    }

                    return dispatch(
                        establishDataSourcesMeasuresUploadsSuccess(
                            res.metric_sources_uploads
                        )
                    );
                })
                .catch((err) =>
                    dispatch(
                        establishDataSourcesMeasuresUploadsFailure({
                            err,
                        })
                    )
                );
        })
        .catch(() => logout({ returnTo: window.location.origin }));
};

export const destroyMetricUploadThunk = (
    getAccessTokenSilently,
    logout,
    metricUploadId
) => (dispatch) => {
    dispatch(destroyMetricUpload());

    return getAccessTokenSilently()
        .then((token) => {
            fetch(apiRoutes.delete.destroyMetricUpload(metricUploadId), {
                method: 'DELETE',
                headers: {
                    organization_slug: window.localStorage.getItem(
                        'MP_ORGANIZATION'
                    ),
                    Authorization: `Token token="${token}"`,
                },
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.error) {
                        dispatch(
                            dispatchErrorAlertThunk(metricUploadId, res.error)
                        );
                        return dispatch(
                            establishDataSourcesMeasuresUploadsFailure(
                                res.error
                            )
                        );
                    }

                    dispatch(
                        dispatchSuccessAlertThunk(
                            metricUploadId,
                            'Record has been deleted'
                        )
                    );
                    return dispatch(
                        establishDataSourcesMeasuresUploadsThunk(
                            getAccessTokenSilently,
                            logout
                        )
                    );
                })
                .catch((err) => {
                    dispatch(
                        dispatchErrorAlertThunk(metricUploadId, err.error)
                    );
                    dispatch(
                        establishDataSourcesMeasuresUploadsFailure({
                            err,
                        })
                    );
                });
        })
        .catch(() => logout({ returnTo: window.location.origin }));
};

export const loadMoreDataSourcesMeasuresUploadsThunk = (
    getAccessTokenSilently,
    logout,
    limit,
    offset
) => (dispatch) => {
    dispatch(establishDataSourcesMeasuresUploads());

    const url = apiRoutes.get.dataSourcesMeasuresUploads(limit, offset);

    return getAccessTokenSilently()
        .then((token) => {
            fetch(url, {
                method: 'GET',
                headers: {
                    organization_slug: window.localStorage.getItem(
                        'MP_ORGANIZATION'
                    ),
                    Authorization: `Token token="${token}"`,
                },
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.error) {
                        return dispatch(
                            establishDataSourcesMeasuresUploadsFailure(
                                res.error
                            )
                        );
                    }

                    if (res.metric_sources_uploads.length === 0) {
                        return dispatch(allDataSourcesMeasuresUploadsLoaded());
                    }

                    return dispatch(
                        loadMoreDataSourcesMeasuresUploads(
                            res.metric_sources_uploads
                        )
                    );
                })
                .catch((err) =>
                    dispatch(
                        establishDataSourcesMeasuresUploadsFailure({
                            err,
                        })
                    )
                );
        })
        .catch(() => logout({ returnTo: window.location.origin }));
};

export const resetDataSourcesMeasuresUploadsThunk = () => (dispatch) => {
    dispatch(resetDataSourcesMeasuresUploads());
};

export default establishDataSourcesMeasuresUploadsThunk;
