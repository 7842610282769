import parseReminders from 'redux/helpers/reminders';

import {
    SET_SELECTED_DATE,
    CLEAR_SELECTED_DATE,
    FETCH_REMINDERS,
    FETCH_REMINDERS_SUCCESS,
    FETCH_REMINDERS_FAILURE,
    DELETE_REMINDER,
    ADD_REMINDER,
} from 'redux/actions/remindersAll';

export const initialState = {
    areRemindersFetching: false,
    areRemindersEstablished: false,
    isRemindersError: false,
    selectedDate: new Date(),
    reminders: [],
    error: null,
};

const reminders = (state = initialState, action) => {
    switch (action.type) {
        case SET_SELECTED_DATE:
            return {
                ...state,
                selectedDate: action.payload,
            };

        case CLEAR_SELECTED_DATE:
            return {
                ...state,
                selectedDate: new Date(),
            };

        case FETCH_REMINDERS:
            return {
                ...state,
                areRemindersFetching: true,
                areRemindersEstablished: false,
            };

        case FETCH_REMINDERS_SUCCESS:
            return {
                ...state,
                areRemindersFetching: false,
                areRemindersEstablished: true,
                reminders: parseReminders(action.payload),
            };

        case FETCH_REMINDERS_FAILURE:
            return {
                ...state,
                areRemindersFetching: false,
                areRemindersEstablished: false,
                isRemindersError: true,
                error: action.err,
            };

        case DELETE_REMINDER:
            return {
                ...state,
                reminders: state.reminders.filter(
                    (reminder) => reminder.id !== action.payload
                ),
            };

        case ADD_REMINDER:
            return {
                ...state,
                reminders: state.reminders.filter(
                    (reminder) => reminder.id !== action.payload
                ),
            };

        default:
            return state;
    }
};

export default reminders;
