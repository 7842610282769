import {
    establishSmartTipDetails,
    establishSmartTipDetailsSuccess,
    establishSmartTipDetailsFailure,
    updateSmartTipDetails,
    updateSmartTipDetailsSuccess,
    updateSmartTipDetailsFailure,
    sendSmartTipFeedback,
    sendSmartTipFeedbackSuccess,
    sendSmartTipFeedbackFailure,
} from 'redux/actions/smartTipDetails';
import apiRoutes from 'config/apiRoutes';
import {
    dispatchErrorAlertThunk,
    dispatchSuccessAlertThunk,
} from './systemAlerts';

const establishSmartTipDetailsThunk = (
    getAccessTokenSilently,
    logout,
    smartTipId
) => (dispatch) => {
    dispatch(establishSmartTipDetails());

    return getAccessTokenSilently()
        .then((token) => {
            fetch(apiRoutes.get.smartTipDetails(smartTipId), {
                method: 'GET',
                headers: {
                    organization_slug: window.localStorage.getItem(
                        'MP_ORGANIZATION'
                    ),
                    Authorization: `Token token="${token}"`,
                },
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.error) {
                        return dispatch(
                            establishSmartTipDetailsFailure(res.error)
                        );
                    }

                    return dispatch(establishSmartTipDetailsSuccess(res));
                })
                .catch((err) =>
                    dispatch(
                        establishSmartTipDetailsFailure({
                            err,
                        })
                    )
                );
        })
        .catch(() => logout({ returnTo: window.location.origin }));
};

export const updateSmartTipDetailsThunk = (
    getAccessTokenSilently,
    logout,
    smartTipId,
    values = {}
) => (dispatch) => {
    dispatch(updateSmartTipDetails());

    return getAccessTokenSilently()
        .then((token) => {
            fetch(apiRoutes.put.updateSmartTip(smartTipId), {
                method: 'PUT',
                headers: {
                    organization_slug: window.localStorage.getItem(
                        'MP_ORGANIZATION'
                    ),
                    Authorization: `Token token="${token}"`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ smart_tip: { ...values } }),
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.error) {
                        return dispatch(
                            updateSmartTipDetailsFailure(res.error)
                        );
                    }

                    return dispatch(updateSmartTipDetailsSuccess(values));
                })
                .catch((err) =>
                    dispatch(
                        updateSmartTipDetailsFailure({
                            err,
                        })
                    )
                );
        })
        .catch(() => logout({ returnTo: window.location.origin }));
};

export const sendSmartTipFeedbackThunk = (
    getAccessTokenSilently,
    logout,
    smartTipId,
    values = {}
) => (dispatch) => {
    dispatch(sendSmartTipFeedback());

    return getAccessTokenSilently()
        .then((token) => {
            fetch(apiRoutes.post.sendSmartTipFeedback(smartTipId), {
                method: 'POST',
                headers: {
                    organization_slug: window.localStorage.getItem(
                        'MP_ORGANIZATION'
                    ),
                    Authorization: `Token token="${token}"`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ evaluation: { ...values } }),
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.error) {
                        dispatch(
                            dispatchErrorAlertThunk(
                                'feedbackSubmitted',
                                res.error
                            )
                        );
                        return dispatch(sendSmartTipFeedbackFailure(res.error));
                    }

                    dispatch(
                        dispatchSuccessAlertThunk(
                            'feedbackSubmitted',
                            'Feedback submitted'
                        )
                    );
                    return dispatch(
                        sendSmartTipFeedbackSuccess({ evaluation: res.value })
                    );
                })
                .catch((err) => {
                    dispatch(dispatchErrorAlertThunk(err, err.error));
                    dispatch(
                        sendSmartTipFeedbackFailure({
                            err,
                        })
                    );
                });
        })
        .catch(() => logout({ returnTo: window.location.origin }));
};

export default establishSmartTipDetailsThunk;
