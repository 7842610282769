import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import useFetch from 'hooks/useFetch';

import MPAAddSuperAdminUserView from 'views/MPAAddSuperAdminUser';
import {
    dispatchErrorAlertThunk,
    dispatchSuccessAlertThunk,
} from 'redux/actions/thunks/systemAlerts';
import apiRoutes from 'config/apiRoutes';
import { useAuth0 } from '@auth0/auth0-react';

const formSchema = Yup.object().shape({
    firstName: Yup.string()
        .min(2, 'Too short')
        .max(50, 'Too long value')
        .required('Required'),
    lastName: Yup.string()
        .min(2, 'Too short')
        .max(50, 'Too long value')
        .required('Required'),
    emailAddress: Yup.string()
        .email('Invalid email format')
        .required('Required'),
});

const initialDate = {
    firstName: undefined,
    lastName: undefined,
    emailAddress: undefined,
};

const MPAAddSuperAdminUser = () => {
    const { getAccessTokenSilently, logout } = useAuth0();
    const dispatch = useDispatch();
    const request = useFetch(getAccessTokenSilently, logout);

    const handleSubmitSuccess = (res) => {
        if (!res.error) {
            dispatch(
                dispatchSuccessAlertThunk(
                    'createSuperAdminUser',
                    'Super Admin User created'
                )
            );
        }
    };

    const handleSubmitError = (err) => {
        dispatch(dispatchErrorAlertThunk(err, err?.error));
    };

    const handleSubmit = (values) => {
        const fd = new FormData();
        if (values.firstName) fd.append('user[first_name]', values.firstName);
        if (values.lastName) fd.append('user[last_name]', values.lastName);
        if (values.emailAddress) fd.append('user[email]', values.emailAddress);

        request(
            apiRoutes.post.createSuperAdminUser(),
            'POST',
            handleSubmitSuccess,
            handleSubmitError,
            {
                body: fd,
            }
        );
    };

    return (
      <Formik
            initialValues={initialDate}
            validationSchema={formSchema}
            onSubmit={handleSubmit}
            enableReinitialize
        >
        {(formikProps) => <MPAAddSuperAdminUserView {...formikProps} />}
      </Formik>
    );
};

export default MPAAddSuperAdminUser;
