import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from 'components/Checkbox';
import { ReactComponent as CommentIcon } from 'assets/svg/comment-icon.svg';
import { ReactComponent as StatusDotIcon } from 'assets/svg/status-dot.svg';
import useDate from 'hooks/useDate';
import theme from 'assets/theme/mainTheme';
import {
    SmartTipUploadItemHolder,
    SurveysEnabledHolder,
    CommentHolder,
    TableHeaderHolder,
    Score,
    LastUpdate,
    StyledCheckbox,
} from './SmartTipUploadItem.style';

export const SmartTipUploadsTableHeader = ({
    areAllSelected,
    handleSelectAllSmartTips,
}) => (
  <TableHeaderHolder>
    <Checkbox
            isSelected={areAllSelected}
            toggleSelected={handleSelectAllSmartTips}
        />
    <span>Name</span>
    <span>Last Updated</span>
    <span>Satisfaction Survey Status</span>
    <span>Satisfaction Score</span>
  </TableHeaderHolder>
);

SmartTipUploadsTableHeader.propTypes = {
    areAllSelected: PropTypes.bool.isRequired,
    handleSelectAllSmartTips: PropTypes.func.isRequired,
};

const SmartTipUploadItem = ({
    smartTip,
    isSelected,
    handleSelectSmartTip,
    setSelectedSmartTip,
}) => {
    const lastEvaluatedDate = useDate(
        new Date(smartTip.last_evaluated_at * 1000)
    );

    return (
      <SmartTipUploadItemHolder id={smartTip.id}>
        <Checkbox
                isSelected={isSelected}
                toggleSelected={() => handleSelectSmartTip(smartTip.id)}
                customStyles={StyledCheckbox}
            />
        <strong>{smartTip.name}</strong>
        {smartTip.last_evaluated_at ? (
          <LastUpdate>{lastEvaluatedDate}</LastUpdate>
            ) : (
              <LastUpdate>n/a</LastUpdate>
            )}
        {smartTip.surveys_enabled ? (
          <SurveysEnabledHolder color={theme.color.checkGreen}>
            <StatusDotIcon />
            <p>On</p>
          </SurveysEnabledHolder>
            ) : (
              <SurveysEnabledHolder color={theme.color.textLight}>
                <StatusDotIcon />
                <p>Off</p>
              </SurveysEnabledHolder>
            )}
        {smartTip.score ? (
          <Score>{smartTip.score}</Score>
            ) : (
              <Score>n/a</Score>
            )}
        <CommentHolder
                disabled={smartTip.comments_count === 0}
                onClick={() => {
                    if (smartTip.comments_count !== 0) {
                        setSelectedSmartTip(smartTip);
                    }
                }}
            >
          <CommentIcon />
          <p>{smartTip.comments_count}</p>
        </CommentHolder>
      </SmartTipUploadItemHolder>
    );
};

SmartTipUploadItem.propTypes = {
    smartTip: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        last_evaluated_at: PropTypes.number,
        surveys_enabled: PropTypes.bool,
        score: PropTypes.string,
        comments_count: PropTypes.number,
    }).isRequired,
    isSelected: PropTypes.bool.isRequired,
    handleSelectSmartTip: PropTypes.func.isRequired,
    setSelectedSmartTip: PropTypes.func.isRequired,
};

SmartTipUploadItem.defaultProps = {};

export default SmartTipUploadItem;
