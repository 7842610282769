import { useSelector } from 'react-redux';
import { MPA_ROUTES } from 'routing/constants';

const useMPA = () => {
    const isCurrentUserMPA = useSelector((state) => state.currentUser.isMPA);
    let isMPAPath = false;

    Object.values(MPA_ROUTES).forEach((item) => {
        if (window.location.pathname.includes(item)) {
            isMPAPath = true;
        }
    });

    return {
        isCurrentUserMPA,
        isMPAPath,
        displayAsMPA: isCurrentUserMPA && isMPAPath,
    };
};

export default useMPA;
