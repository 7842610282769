import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ArrowLeftIcon } from 'assets/svg/pagination/arrow-left.svg';
import { ReactComponent as ArrowRightIcon } from 'assets/svg/pagination/arrow-right.svg';
import {
    Wrapper,
    PaginationWrapper,
    ButtonWrapper,
    PageNumber,
    Counter,
} from './Pagination.style';

const Pagination = ({
    pages,
    currentPage,
    handleNextPage,
    handlePrevPage,
    setCurrentPage,
    isPrevEnabled,
    isNextEnabled,
}) => (
  <Wrapper>
    <Counter>{`Page ${currentPage + 1} of ${pages.length}`}</Counter>
    <PaginationWrapper>
      <ButtonWrapper
                onClick={isPrevEnabled() ? handlePrevPage : undefined}
                disabled={!isPrevEnabled()}
            >
        <ArrowLeftIcon />
      </ButtonWrapper>
      {pages.map((page) =>
                Math.abs(currentPage - page.index) < 3 ? (
                  <PageNumber
                        key={`page_${page.index}`}
                        selected={page.index === currentPage}
                        onClick={() => setCurrentPage(page.index)}
                    >
                    {page.pageNumber}
                  </PageNumber>
                ) : null
            )}
      <ButtonWrapper
                onClick={isNextEnabled() ? handleNextPage : undefined}
                disabled={!isNextEnabled()}
            >
        <ArrowRightIcon />
      </ButtonWrapper>
    </PaginationWrapper>
  </Wrapper>
);

Pagination.propTypes = {
    pages: PropTypes.arrayOf(PropTypes.any).isRequired,
    currentPage: PropTypes.number.isRequired,
    handleNextPage: PropTypes.func.isRequired,
    handlePrevPage: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired,
    isPrevEnabled: PropTypes.func.isRequired,
    isNextEnabled: PropTypes.func.isRequired,
};

Pagination.defaultProps = {};

export default Pagination;
