export const ESTABLISH_ORGANIZATION_TEAMS = 'ESTABLISH_ORGANIZATION_TEAMS';
export const ESTABLISH_ORGANIZATION_TEAMS_SUCCESS =
    'ESTABLISH_ORGANIZATION_TEAMS_SUCCESS';
export const ESTABLISH_ORGANIZATION_TEAMS_FAILURE =
    'ESTABLISH_ORGANIZATION_TEAMS_FAILURE';
export const LOAD_MORE_ORGANIZATION_TEAMS = 'LOAD_MORE_ORGANIZATION_TEAMS';
export const MARK_ALL_ORGANIZATION_TEAMS_LOADED =
    'MARK_ALL_ORGANIZATION_TEAMS_LOADED';
export const RESET_ORGANIZATION_TEAMS = 'RESET_ORGANIZATION_TEAMS';

export const establishOrganizationTeams = () => ({
    type: ESTABLISH_ORGANIZATION_TEAMS,
});

export const establishOrganizationTeamsSuccess = (payload) => ({
    type: ESTABLISH_ORGANIZATION_TEAMS_SUCCESS,
    payload,
});

export const establishOrganizationTeamsFailure = (err) => ({
    type: ESTABLISH_ORGANIZATION_TEAMS_FAILURE,
    err,
});

export const loadMoreOrganizationTeams = (payload) => ({
    type: LOAD_MORE_ORGANIZATION_TEAMS,
    payload,
});

export const allTeamsLoaded = () => ({
    type: MARK_ALL_ORGANIZATION_TEAMS_LOADED,
});

export const resetOrganizationTeams = () => ({
    type: RESET_ORGANIZATION_TEAMS,
});
