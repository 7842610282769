import styled, { css } from 'styled-components/macro';

const StyledStatus = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${(props) => props.variant};
    height: 20px;

    svg {
        margin-right: 11px;
        fill: ${(props) => props.variant};
    }

    ${({ customStyles }) =>
        customStyles &&
        css`
            ${customStyles}
        `}
`;

export default StyledStatus;
