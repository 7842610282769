import styled from 'styled-components/macro';

export const Container = styled.div`
    display: flex;
    flex-flow: column;
`;

export const Title = styled.p`
    margin: 0;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
`;

export const Description = styled.p`
    margin: 0;
    margin-top: 18px;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
`;
