export const GET_USER_PROFILE = 'GET_USER_PROFILE';
export const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS';
export const GET_USER_PROFILE_FAILURE = 'GET_USER_PROFILE_FAILURE';

export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';
export const UPDATE_USER_PROFILE_SUCCESS = 'UPDATE_USER_PROFILE_SUCCESS';
export const UPDATE_USER_PROFILE_FAILURE = 'UPDATE_USER_PROFILE_FAILURE';

export const getUserProfile = () => ({
    type: GET_USER_PROFILE,
});

export const getUserProfileSuccess = (payload) => ({
    type: GET_USER_PROFILE_SUCCESS,
    payload,
});

export const getUserProfileFailure = (err) => ({
    type: GET_USER_PROFILE_FAILURE,
    err,
});

export const updateUserProfile = () => ({
    type: UPDATE_USER_PROFILE,
});

export const updateUserProfileSuccess = (payload) => ({
    type: UPDATE_USER_PROFILE_SUCCESS,
    payload,
});

export const updateUserProfileFailure = (err) => ({
    type: UPDATE_USER_PROFILE_FAILURE,
    err,
});
