import {
    establishMPAAdminMemos,
    establishMPAAdminMemosSuccess,
    establishMPAAdminMemosFailure,
    resetMPAAdminMemos,
    allMPAAdminMemosLoaded,
    loadMoreMPAAdminMemos,
} from 'redux/actions/mpaAdminMemos';
import apiRoutes from 'config/apiRoutes';

const establishMPAAdminMemosThunk = (
    getAccessTokenSilently,
    logout,
    limit,
    offset
) => (dispatch) => {
    dispatch(establishMPAAdminMemos());

    return getAccessTokenSilently()
        .then((token) => {
            fetch(apiRoutes.get.mpaAdminMemos(limit, offset), {
                method: 'GET',
                headers: {
                    organization_slug: window.localStorage.getItem(
                        'MP_ORGANIZATION'
                    ),
                    Authorization: `Token token="${token}"`,
                },
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.error) {
                        return dispatch(
                            establishMPAAdminMemosFailure(res.error)
                        );
                    }

                    if (res.admin_memos.length < limit) {
                        dispatch(allMPAAdminMemosLoaded());
                    }

                    return dispatch(
                        establishMPAAdminMemosSuccess(res.admin_memos)
                    );
                })
                .catch((err) => {
                    dispatch(
                        establishMPAAdminMemosFailure({
                            err,
                        })
                    );
                })
                .finally(
                    () =>
                        !limit && !offset && dispatch(allMPAAdminMemosLoaded())
                );
        })
        .catch(() => logout({ returnTo: window.location.origin }));
};

export const loadMoreMPAAdminMemosThunk = (
    getAccessTokenSilently,
    logout,
    limit,
    offset
) => (dispatch) => {
    dispatch(establishMPAAdminMemos());

    return getAccessTokenSilently()
        .then((token) => {
            fetch(apiRoutes.get.mpaAdminMemos(limit, offset), {
                method: 'GET',
                headers: {
                    organization_slug: window.localStorage.getItem(
                        'MP_ORGANIZATION'
                    ),
                    Authorization: `Token token="${token}"`,
                },
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.error) {
                        return dispatch(
                            establishMPAAdminMemosFailure(res.error)
                        );
                    }

                    if (res.admin_memos.length < limit) {
                        dispatch(allMPAAdminMemosLoaded());
                    }

                    if (res.admin_memos.length === 0) {
                        return dispatch(allMPAAdminMemosLoaded());
                    }

                    return dispatch(loadMoreMPAAdminMemos(res.admin_memos));
                })
                .catch((err) => {
                    dispatch(
                        establishMPAAdminMemosFailure({
                            err,
                        })
                    );
                });
        })
        .catch(() => logout({ returnTo: window.location.origin }));
};

export const resetMPAAdminMemosThunk = () => (dispatch) => {
    dispatch(resetMPAAdminMemos());
};

export const revertMPAAdminMemoThunk = (
    getAccessTokenSilently,
    logout,
    adminMemoId,
    reloadMPAAdminMemos
) => (dispatch) => {
    dispatch(resetMPAAdminMemosThunk());

    return getAccessTokenSilently()
        .then((token) => {
            fetch(apiRoutes.delete.revertMPAAdminMemo(adminMemoId), {
                method: 'DELETE',
                headers: {
                    organization_slug: window.localStorage.getItem(
                        'MP_ORGANIZATION'
                    ),
                    Authorization: `Token token="${token}"`,
                },
            })
                .then(() => {
                    reloadMPAAdminMemos();
                })
                .catch((err) =>
                    dispatch(
                        establishMPAAdminMemosFailure({
                            err,
                        })
                    )
                );
        })
        .catch(() => logout({ returnTo: window.location.origin }));
};

export const createMPAAdminMemoThunk = (
    getAccessTokenSilently,
    logout,
    adminMemo,
    reloadMPAAdminMemos
) => (dispatch) =>
    getAccessTokenSilently()
        .then((token) => {
            fetch(apiRoutes.post.createMPAAdminMemo(), {
                method: 'POST',
                headers: {
                    organization_slug: window.localStorage.getItem(
                        'MP_ORGANIZATION'
                    ),
                    Authorization: `Token token="${token}"`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(adminMemo),
            })
                .then(() => {
                    reloadMPAAdminMemos();
                })
                .catch((err) =>
                    dispatch(
                        establishMPAAdminMemosFailure({
                            err,
                        })
                    )
                );
        })
        .catch(() => logout({ returnTo: window.location.origin }));

export default establishMPAAdminMemosThunk;
