import styled, { css } from 'styled-components/macro';
import Logo from 'components/Logo';

export const StyledSidebarHeader = styled.div`
    width: 100px;
    height: 90px;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
`;

export const StyledSidebarLogo = styled(Logo)`
    margin: 30px 15px 30px 15px;
    z-index: 1000;
`;

export const StyledSidebarHamburger = styled.div`
    padding: 30px 15px 30px 15px;
    z-index: 1000;
    display: flex;
    justify-content: center;
`;

export const StyledSidebarLogotype = styled.div`
    padding-left: 15px;
    height: 28px;
    display: flex;
    align-items: center;
    border-left: ${({ theme }) => theme.border.base};
    opacity: 1;
    position: absolute;
    margin-left: 54px;
    transition: all 0.2s ease;

    ${({ isMPA, theme }) =>
        isMPA &&
        css`
            color: ${theme.color.white};
        `}
`;

export const StyledSidebarHolder = styled.aside`
    height: 100vh;
    width: 320px;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: ${({ theme }) => theme.color.white};
    box-shadow: ${({ theme }) => theme.boxShadow.base};
    z-index: 999;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    * {
        user-select: none;
    }

    ${({ isMPA }) =>
        isMPA &&
        css`
            background-color: ${({ theme }) => theme.color.mpaSidebarBg};
        `}
`;

export const StyledMobileSidebarHolder = styled.div`
    position: fixed;
    width: ${({ isMobileExpanded }) => (isMobileExpanded ? 'auto' : '70px')};
    height: ${({ isMobileExpanded }) => (isMobileExpanded ? '100%' : 'auto')};
    z-index: 1000;
    top: 0;
    background-color: ${({ isMPA, isMobileExpanded, theme }) => {
        if (isMPA && isMobileExpanded) {
            return theme.color.mpaSidebarBg;
        }
        if (isMobileExpanded) {
            return theme.color.white;
        }
        return 'transparent';
    }};

    box-shadow: ${({ theme, isMobileExpanded }) =>
        isMobileExpanded && theme.boxShadow.base};
`;

export const StyledMobileSidebarMenuHolder = styled.div`
    min-width: 318px;

    position: relative;
    transition: all 0.2s ease;
    height: ${({ isMobileExpanded }) => (isMobileExpanded ? 'auto' : '0px')};
    overflow: hidden;
`;
