import styled, { css } from 'styled-components/macro';
import Button from 'components/Button';

export const TeamOverviewNavigationWrapper = styled.div`
    display: flex;
    align-items: center;

    svg {
        cursor: pointer;
    }

    button {
        justify-content: center;
    }

    @media (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
        flex-direction: column-reverse;
        align-items: flex-end;
    }
`;

export const TeamOverviewNavigationItems = styled.div`
    display: flex;
    align-items: center;

    padding-right: 16px;
    margin-right: 32px;
    border-right: ${({ theme }) => theme.border.base};
`;

export const TeamOverviewItem = styled.span`
    padding: 10px 16px;
    border-radius: ${({ theme }) => theme.border.radiusBase};
    color: ${({ theme }) => theme.color.textLight};
    cursor: pointer;

    ${({ isActive }) =>
        isActive &&
        css`
            color: ${({ theme }) => theme.color.primary};
            background-color: ${({ theme }) => theme.color.bgPrimaryLight};
        `};
`;

export const DropdownWrapper = styled.div`
    position: relative;
`;

export const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 49px;
    height: 49px;
    border-radius: ${({ theme }) => theme.border.radiusBase};
    cursor: pointer;
    background-color: ${({ theme }) => theme.color.white};

    &:hover {
        background-color: ${({ theme }) => theme.color.bgPrimaryLight};
    }

    ${({ withBackground }) =>
        withBackground &&
        css`
            background-color: ${({ theme }) => theme.color.bgPrimaryLight};
        `}
`;

export const SettingsDropdown = styled.div`
    position: absolute;
    height: 81px;
    right: 0px;
    top: 0px;
    z-index: 1000;
    margin-top: 49px;

    display: flex;
    align-items: center;
    padding: 16px;

    ${({ theme }) =>
        css`
            background: ${theme.color.white};
            border: ${theme.border.base};
            box-shadow: ${theme.boxShadow.base};
            border-radius: ${theme.border.radiusBase};
        `};
`;

export const StyledSettingsButton = styled(Button)`
    ${({ disabled }) =>
        disabled &&
        css`
            &:hover:before {
                display: block;
            }

            &:before {
                content: attr(data-text);
                position: absolute;
                display: none;

                left: -150%;

                padding: 10px;
                text-align: center;

                ${({ theme }) => css`
                    background-color: ${theme.color.black};
                    color: ${theme.color.white};
                    border-radius: ${theme.border.radiusBase};
                    box-shadow: ${theme.boxShadow.base};
                `}
            }
        `}
`;
