import styled from 'styled-components/macro';

export const FAQContainer = styled.div`
    width: 100%;
`;

export const FAQItems = styled.div`
    padding: 16px;
    display: flex;
    flex-flow: column nowrap;
`;

export const SectionSeparator = styled.span`
    margin-top: 16px;
    margin-bottom: 32px;

    color: ${({ theme }) => `${theme.color.textLight}`};
`;

export const FaqItemInternalText = styled.div`
    padding: 48px 32px;
    height: auto;
    overflow: hidden;

    color: ${({ theme }) => `${theme.color.textLight}`};

    li {
        color: inherit;
    }
`;

export const HeaderButtons = styled.span`
    display: flex;
    flex-flow: row nowrap;

    margin-right: 32px;

    button {
        margin-left: 16px;
        padding: 16px 24px;
    }

    a {
        margin-left: 16px;
        padding: 16px 24px;
        border-radius: ${({ theme }) => theme.border.radiusBase};

        font-size: ${({ theme }) => theme.fontSize.base};
        background-color: ${({ theme }) => theme.color.primaryDark};
        color: ${({ theme }) => theme.color.white};
        font-weight: 500;

        display: flex;
        align-items: center;

        svg {
            margin-left: 8px;
        }
    }
`;
