import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useOffset from 'hooks/useOffset';
import establishOrganizationSmartTipsThunk, {
    resetOrganizationSmartTipsThunk,
} from 'redux/actions/thunks/organizationSmartTips';
import SmartTipsHAView from 'views/SmartTipsHA';
import { useAuth0 } from '@auth0/auth0-react';
import { idize } from '../CreateNewUserHA/CreateNewUserHA';

export const parseTips = (tips) =>
    tips
        .map((tip) => ({
            id: tip.id,
            name: tip.name,
            lastUpdate: tip.updated_at,
            status: tip.status,
            score: tip.score,
            assignedUsersCount: tip.assigned_users_count,
            assignedTeamsCount: tip.assigned_teams_count,
            isSelected: false,
            assignedToAll: tip.assigned_to_all,
        }))
        .sort((a, b) => new Date(b.lastUpdate) - new Date(a.lastUpdate));

const INFINITE_SCROLL_THRESHOLD = 20;

const SmartTipsHA = () => {
    const { getAccessTokenSilently, logout } = useAuth0();
    const [offset, incrementOffset, resetOffset] = useOffset(
        INFINITE_SCROLL_THRESHOLD
    );
    const [smartTips, setSmartTips] = useState([]);
    const {
        organizationSmartTips,
        hasMoreOrganizationSmartTips,
        areOrganizationSmartTipsFetching,
    } = useSelector((state) => state.organizationSmartTips);
    const dispatch = useDispatch();
    const filterParam = new URLSearchParams(window.location.search).get(
        'filter'
    );
    const [filter, setFilter] = useState(filterParam || 'all');
    const [search, setSearch] = useState('');
    const [tagsFilter, setTagsFilter] = useState({
        role_job: [],
        department: [],
    });

    const tagsFilterPrimitive = JSON.stringify(tagsFilter);

    useEffect(() => {
        if (filter === 'all') {
            dispatch(
                establishOrganizationSmartTipsThunk(
                    getAccessTokenSilently,
                    logout,
                    INFINITE_SCROLL_THRESHOLD,
                    0,
                    {
                        search,
                        classification_tag_ids: [
                            ...tagsFilter.role_job.map(idize),
                            ...tagsFilter.department.map(idize),
                        ],
                    }
                )
            );
        } else {
            dispatch(
                establishOrganizationSmartTipsThunk(
                    getAccessTokenSilently,
                    logout,
                    INFINITE_SCROLL_THRESHOLD,
                    0,
                    {
                        search,
                        status: filter,
                        classification_tag_ids: [
                            ...tagsFilter.role_job.map(idize),
                            ...tagsFilter.department.map(idize),
                        ],
                    }
                )
            );
        }
    }, [filter, search, tagsFilterPrimitive]);

    useEffect(() => {
        setSmartTips(parseTips(organizationSmartTips));
    }, [organizationSmartTips]);

    const handleFilter = (status) => {
        setFilter(status);
    };

    const handleSearch = (e) => {
        setSearch(e.target.value);
    };

    const fetchSmartTips = (reset = false) => {
        if (reset) resetOffset();

        if (filter === 'all') {
            dispatch(
                establishOrganizationSmartTipsThunk(
                    getAccessTokenSilently,
                    logout,
                    INFINITE_SCROLL_THRESHOLD,
                    reset ? 0 : offset,
                    {
                        search,
                        classification_tag_ids: [
                            ...tagsFilter.role_job.map(idize),
                            ...tagsFilter.department.map(idize),
                        ],
                    },
                    true
                )
            );
        } else {
            dispatch(
                establishOrganizationSmartTipsThunk(
                    getAccessTokenSilently,
                    logout,
                    INFINITE_SCROLL_THRESHOLD,
                    reset ? 0 : offset,
                    {
                        search,
                        status: filter,
                        classification_tag_ids: [
                            ...tagsFilter.role_job.map(idize),
                            ...tagsFilter.department.map(idize),
                        ],
                    },
                    true
                )
            );
        }
        incrementOffset();
    };

    const loadMore = () => {
        fetchSmartTips();
    };

    const resetSmartTips = () => {
        fetchSmartTips(true);
    };

    const hasMore =
        hasMoreOrganizationSmartTips && !areOrganizationSmartTipsFetching;

    useEffect(() => {
        fetchSmartTips(true);

        return () => {
            resetOffset();
            dispatch(resetOrganizationSmartTipsThunk());
        };
    }, []);

    return (
        <SmartTipsHAView
            smartTips={smartTips}
            handleFilter={handleFilter}
            search={search}
            handleSearch={handleSearch}
            filterParam={filterParam}
            loadMore={loadMore}
            hasMore={hasMore}
            isFetching={areOrganizationSmartTipsFetching}
            resetSmartTips={resetSmartTips}
            tagsFilter={tagsFilter}
            setTagsFilter={setTagsFilter}
        />
    );
};

export default SmartTipsHA;
