import styled from 'styled-components/macro';

export const NestTagListContainer = styled.div`
    max-height: 300px;
    overflow: auto;
`;

export const ChipContainer = styled.span`
    padding: 10px;
    border-radius: 8px;
    background: #e4ecfd;
    display: inline-block;
    margin: 0 5px;
    margin-bottom: 10px;
    cursor: pointer;
`;

export const ChipRemoveIconContainer = styled.span`
    height: 12px;
    width: 12px;
    padding-left: 10px;
    margin-left: 10px;
    border-left: 1px solid #e4ecfd;
`;

export const WrapperTags = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export const SeparatorTags = styled.div`
    width: 20px;
    height: 40px;
    position: relative;
    &::before,
    &::after {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 2px;
        height: 30px;
        background-color: #ebf0fa;
    }
    &::before {
        left: 30%;
    }
    &::after {
        right: 30%;
    }
`;
