export const ESTABLISH_USERS_UPLOADS = 'ESTABLISH_USERS_UPLOADS';
export const ESTABLISH_USERS_UPLOADS_SUCCESS =
    'ESTABLISH_USERS_UPLOADS_SUCCESS';
export const ESTABLISH_USERS_UPLOADS_FAILURE =
    'ESTABLISH_USERS_UPLOADS_FAILURE';

export const LOAD_MORE_USERS_UPLOADS = 'LOAD_MORE_USERS_UPLOADS';
export const MARK_ALL_USERS_UPLOADS_LOADED = 'MARK_ALL_USERS_UPLOADS_LOADED';
export const RESET_USERS_UPLOADS = 'RESET_USERS_UPLOADS';

export const UPLOAD_USERS = 'UPLOAD_USERS';
export const UPLOAD_USERS_SUCCESS = 'UPLOAD_USERS_SUCCESS';
export const UPLOAD_USERS_FAILURE = 'UPLOAD_USERS_FAILURE';

export const establishUsersUploads = () => ({
    type: ESTABLISH_USERS_UPLOADS,
});

export const establishUsersUploadsSuccess = (payload) => ({
    type: ESTABLISH_USERS_UPLOADS_SUCCESS,
    payload,
});

export const establishUsersUploadsFailure = (err) => ({
    type: ESTABLISH_USERS_UPLOADS_FAILURE,
    err,
});

export const loadMoreUsersUploads = (payload) => ({
    type: LOAD_MORE_USERS_UPLOADS,
    payload,
});

export const allUsersUploadsLoaded = () => ({
    type: MARK_ALL_USERS_UPLOADS_LOADED,
});

export const resetUsersUploads = () => ({
    type: RESET_USERS_UPLOADS,
});

export const uploadUsers = () => ({
    type: UPLOAD_USERS,
});

export const uploadUsersSuccess = (payload) => ({
    type: UPLOAD_USERS_SUCCESS,
    payload,
});

export const uploadUsersFailure = (err) => ({
    type: UPLOAD_USERS_FAILURE,
    err,
});
