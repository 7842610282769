import React from 'react';
import SectionHeader from 'components/SectionHeader';
import { MPA_ROUTES } from 'routing/constants';
import theme from 'assets/theme/mainTheme';
import { useHistory } from 'react-router-dom';
import { ReactComponent as ArrowBackIcon } from 'assets/svg/arrow-back.svg';

import Button from 'components/Button';
import Input from 'components/Input';

import {
    Wrapper,
    Title,
    InputWrapper,
    FormWrapper,
    ButtonsWrapper,
} from './MPAAddSuperAdminUser.style';

const MPAAddSuperAdminUser = ({ ...rest }) => {
    const history = useHistory();
    const {
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
    } = rest;

    const isButtonDisabled = () => {
        let result = false;

        Object.values(values).forEach((value) => {
            if (value === undefined) {
                result = true;
            }
        });

        if (Object.values(errors).length !== 0) {
            result = true;
        }

        return result;
    };

    return (
      <Wrapper>
        <SectionHeader
                title="New Super Admin User"
                goBack={{
                    url: MPA_ROUTES.superAdminUsers,
                    label() {
                        return (
                          <>
                            <ArrowBackIcon stroke={theme.color.textLabel} />
                            {' '}
                            <span>Back</span>
                          </>
                        );
                    },
                }}
            />
        <FormWrapper>
          <Title>Complete new User data</Title>
          <InputWrapper>
            <Input
                        name="firstName"
                        placeholder="First name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={values.firstName}
                        error={
                            errors.firstName && touched.firstName
                                ? errors.firstName
                                : null
                        }
                    />
            <Input
                        name="lastName"
                        placeholder="Last name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={values.lastName}
                        error={
                            errors.lastName && touched.lastName
                                ? errors.lastName
                                : null
                        }
                    />
            <Input
                        name="emailAddress"
                        placeholder="E-mail address"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={values.emailAddress}
                        error={
                            errors.emailAddress && touched.emailAddress
                                ? errors.emailAddress
                                : null
                        }
                    />
          </InputWrapper>
          <ButtonsWrapper>
            <Button
                        primaryDark
                        type="submit"
                        onClick={handleSubmit}
                        disabled={isButtonDisabled()}
                    >
              Send invitation
            </Button>
            <Button
                        primary
                        onClick={() => history.push(MPA_ROUTES.superAdminUsers)}
                    >
              Cancel
            </Button>
          </ButtonsWrapper>
        </FormWrapper>
      </Wrapper>
    );
};

MPAAddSuperAdminUser.propTypes = {};

MPAAddSuperAdminUser.defaultProps = {};

export default MPAAddSuperAdminUser;
