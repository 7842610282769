import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useFetch from 'hooks/useFetch';
import useAdmin from 'hooks/useAdmin';
import apiRoutes from 'config/apiRoutes';
import RelativePerformanceView from 'components/charts/RelativePerformance';
import { useAuth0 } from '@auth0/auth0-react';

const initialCurrentScores = {
    score: undefined,
    providerScore: undefined,
    date: undefined,
};

const getAdjustedData = (data) => {
    const resultArray = [];
    if (data && data.length > 0) {
        if (
            data[data.length - 1].provider_score !== undefined &&
            data[data.length - 1].provider_score !== null
        ) {
            let prevValue;
            for (let index = data.length - 1; index >= 0; index -= 1) {
                if (
                    data[index].provider_score !== undefined &&
                    data[index].provider_score !== null
                ) {
                    prevValue = data[index].provider_score;
                    resultArray.push({
                        score: data[index].score,
                        providerScore: data[index].provider_score,
                        providerScoreLabel: `${data[index].provider_score}%`,
                        date: new Date(data[index].measured_at).getTime(),
                    });
                } else {
                    resultArray.push({
                        score: data[index].score,
                        providerScore: prevValue,
                        providerScoreLabel: 'n/a',
                        date: new Date(data[index].measured_at).getTime(),
                    });
                }
            }
            return resultArray;
        }

        for (let index = data.length - 1; index >= 0; index -= 1) {
            resultArray.push({
                score: data[index].score,
                date: new Date(data[index].measured_at).getTime(),
            });
        }
        return resultArray;
    }
    return resultArray;
};

const RelativePerformance = ({ data, getData }) => {
    const { getAccessTokenSilently, logout } = useAuth0();
    const request = useFetch(getAccessTokenSilently, logout);
    const isAdmin = useAdmin();
    const [loading, setLoading] = useState(false);
    const [metricSources, setMetricSources] = useState([]);
    const [selectedMetricSource, setSelectedMetricSource] = useState(undefined);
    const [adjustedData, setAdjustedData] = useState(undefined);
    const [currentScores, setCurrentScores] = useState();

    useEffect(() => {
        if (data) {
            setAdjustedData(getAdjustedData(data));
        }
    }, [data]);

    useEffect(() => {
        if (adjustedData && adjustedData.length !== 0) {
            setCurrentScores({
                ...currentScores,
                score: adjustedData[0].score,
                providerScore: adjustedData[0].providerScore,
                date: adjustedData[0].date,
                providerScoreLabel: adjustedData[0].providerScoreLabel,
            });
        }
    }, [adjustedData]);

    const handleSetCurrentScores = (os, pos, date, providerScoreLabel) => {
        setCurrentScores({
            ...currentScores,
            score: os,
            providerScore: pos,
            date,
            providerScoreLabel,
        });
    };

    const handleMetricSourcesSuccess = (res) => {
        setMetricSources(res.metric_sources);
        if (res.metric_sources.length !== 0) {
            setSelectedMetricSource(res.metric_sources[0]);
        }
        setLoading(false);
    };

    const handleMetricSourcesError = (err) => {
        setLoading(false);
        return err;
    };

    useEffect(() => {
        setLoading(true);
        setCurrentScores(initialCurrentScores);
        const reqUrl = isAdmin
            ? apiRoutes.get.organizationMetricSources
            : apiRoutes.get.providerMetricSources;
        request(
            reqUrl,
            'GET',
            handleMetricSourcesSuccess,
            handleMetricSourcesError,
            {},
            { 'Content-Type': 'application/json' }
        );
    }, []);

    useEffect(() => {
        if (selectedMetricSource) {
            getData(selectedMetricSource.id);
        }
    }, [selectedMetricSource]);

    return (
      <RelativePerformanceView
            selectedMetricSource={selectedMetricSource}
            metricSources={metricSources}
            setSelectedMetricSource={setSelectedMetricSource}
            currentScores={currentScores}
            loading={loading}
            data={adjustedData}
            handleSetCurrentScores={handleSetCurrentScores}
        />
    );
};

RelativePerformance.propTypes = {
    data: PropTypes.arrayOf(PropTypes.any).isRequired,
    getData: PropTypes.func,
};

RelativePerformance.defaultProps = {
    getData: () => {},
};

export default RelativePerformance;
