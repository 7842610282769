export const ESTABLISH_TEAM_OVERVIEW = 'ESTABLISH_TEAM_OVERVIEW';
export const ESTABLISH_TEAM_OVERVIEW_SUCCESS =
    'ESTABLISH_TEAM_OVERVIEW_SUCCESS';
export const ESTABLISH_TEAM_OVERVIEW_FAILURE =
    'ESTABLISH_TEAM_OVERVIEW_FAILURE';

export const ESTABLISH_TEAM_RECENTLY_SHARED = 'ESTABLISH_TEAM_RECENTLY_SHARED';
export const ESTABLISH_TEAM_RECENTLY_SHARED_SUCCESS =
    'ESTABLISH_TEAM_RECENTLY_SHARED_SUCCESS';
export const ESTABLISH_TEAM_RECENTLY_SHARED_FAILURE =
    'ESTABLISH_TEAM_RECENTLY_SHARED_FAILURE';

export const ESTABLISH_TEAM_STATS = 'ESTABLISH_TEAM_STATS';
export const ESTABLISH_TEAM_STATS_SUCCESS = 'ESTABLISH_TEAM_STATS_SUCCESS';
export const ESTABLISH_TEAM_STATS_FAILURE = 'ESTABLISH_TEAM_STATS_FAILURE';

export const ESTABLISH_TEAM_DETAILS = 'ESTABLISH_TEAM_DETAILS';
export const ESTABLISH_TEAM_DETAILS_SUCCESS = 'ESTABLISH_TEAM_DETAILS_SUCCESS';
export const ESTABLISH_TEAM_DETAILS_FAILURE = 'ESTABLISH_TEAM_DETAILS_FAILURE';

export const FETCH_MESSAGES = 'FETCH_MESSAGES';
export const FETCH_MESSAGES_SUCCESS = 'FETCH_MESSAGES_SUCCESS';
export const FETCH_MESSAGES_FAILURE = 'FETCH_MESSAGES_FAILURE';

export const DELETE_MESSAGE = 'DELETE_MESSAGE';
export const DELETE_MESSAGE_SUCCESS = 'DELETE_MESSAGE_SUCCESS';
export const DELETE_MESSAGE_FAILURE = 'DELETE_MESSAGE_FAILURE';

export const FETCH_PREVIOUS_MESSAGES = 'FETCH_PREVIOUS_MESSAGES';
export const FETCH_PREVIOUS_MESSAGES_SUCCESS =
    'FETCH_PREVIOUS_MESSAGES_SUCCESS';
export const FETCH_PREVIOUS_MESSAGES_FAILURE =
    'FETCH_PREVIOUS_MESSAGES_FAILURE';
export const LIKE_MESSAGE = 'LIKE_MESSAGE';
export const UNLIKE_MESSAGE = 'UNLIKE_MESSAGE';
export const UPDATE_SINGLE_MESSAGE = 'UPDATE_SINGLE_MESSAGE';

export const establishOverview = () => ({
    type: ESTABLISH_TEAM_OVERVIEW,
});

export const establishOverviewSuccess = (payload) => ({
    type: ESTABLISH_TEAM_OVERVIEW_SUCCESS,
    payload,
});

export const establishOverviewFailure = (err) => ({
    type: ESTABLISH_TEAM_OVERVIEW_FAILURE,
    err,
});

export const establishRecentlyShared = () => ({
    type: ESTABLISH_TEAM_RECENTLY_SHARED,
});

export const establishRecentlySharedSuccess = (payload) => ({
    type: ESTABLISH_TEAM_RECENTLY_SHARED_SUCCESS,
    payload,
});

export const establishRecentlySharedFailure = (err) => ({
    type: ESTABLISH_TEAM_RECENTLY_SHARED_FAILURE,
    err,
});

export const establishStats = () => ({
    type: ESTABLISH_TEAM_STATS,
});

export const establishStatsSuccess = (payload) => ({
    type: ESTABLISH_TEAM_STATS_SUCCESS,
    payload,
});

export const establishStatsFailure = (err) => ({
    type: ESTABLISH_TEAM_STATS_FAILURE,
    err,
});

export const fetchMessages = () => ({
    type: FETCH_MESSAGES,
});

export const fetchMessagesSuccess = (payload) => ({
    type: FETCH_MESSAGES_SUCCESS,
    payload,
});

export const fetchMessagesFailure = (err) => ({
    type: FETCH_MESSAGES_FAILURE,
    err,
});

export const deleteMessage = () => ({
    type: DELETE_MESSAGE,
});

export const deleteMessageSuccess = (payload) => ({
    type: DELETE_MESSAGE_SUCCESS,
    payload,
});

export const deleteMessageFailure = (err) => ({
    type: DELETE_MESSAGE_FAILURE,
    err,
});

export const fetchPreviousMessages = () => ({
    type: FETCH_PREVIOUS_MESSAGES,
});

export const fetchPreviousMessagesSuccess = (payload) => ({
    type: FETCH_PREVIOUS_MESSAGES_SUCCESS,
    payload,
});

export const fetchPreviousMessagesFailure = (err) => ({
    type: FETCH_PREVIOUS_MESSAGES_FAILURE,
    err,
});
export const likeMessage = (payload) => ({
    type: LIKE_MESSAGE,
    payload,
});

export const unlikeMessage = (payload) => ({
    type: UNLIKE_MESSAGE,
    payload,
});

export const updateSingleMessage = (payload) => ({
    type: UPDATE_SINGLE_MESSAGE,
    payload,
});

export const establishTeamDetails = () => ({
    type: ESTABLISH_TEAM_DETAILS,
});

export const establishTeamDetailsSuccess = (payload) => ({
    type: ESTABLISH_TEAM_DETAILS_SUCCESS,
    payload,
});

export const establishTeamDetailsFailure = (err) => ({
    type: ESTABLISH_TEAM_DETAILS_FAILURE,
    err,
});
