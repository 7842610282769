import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import MetricsTable from 'components/MetricsTable';
import Spinner from 'components/Spinner';
import { useAuth0 } from '@auth0/auth0-react';
import useFetch from 'hooks/useFetch';
import apiRoutes from 'config/apiRoutes';

const Metrics = ({ metricSourceId, metricName, metricSourceEnabled }) => {
    const { getAccessTokenSilently, logout } = useAuth0();
    const request = useFetch(getAccessTokenSilently, logout);
    const [isMetricSourceEnabled, setIsMetricSourceEnabled] = useState(
        metricSourceEnabled
    );
    const [isAccordionOpen, setIsAccordionOpen] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [metrics, setMetrics] = useState([]);

    const handleSuccessMetrics = (res) => {
        setMetrics(res.metrics);
        setIsLoading(false);
    };

    const handleFailureMetrics = (err) => {
        setIsLoading(false);
        return err;
    };

    const fetchMetrics = () => {
        setIsLoading(true);
        request(
            apiRoutes.get.organizationMetricsBySourceId(metricSourceId),
            'GET',
            handleSuccessMetrics,
            handleFailureMetrics,
            {},
            { 'Content-Type': 'application/json' }
        );
    };

    const reloadList = () => {
        setIsAccordionOpen(true);
        fetchMetrics();
    };

    const handleSuccessChangeSourceStatus = (res) => {
        if (!res.error) {
            setIsMetricSourceEnabled(!isMetricSourceEnabled);
        }
        fetchMetrics();
    };

    const handleFailureChangeSourceStatus = (err) => {
        fetchMetrics();
        return err;
    };

    const fetchChangeSourceStatus = (status) => {
        request(
            apiRoutes.put.updateMetricSource(metricSourceId),
            'PUT',
            handleSuccessChangeSourceStatus,
            handleFailureChangeSourceStatus,
            {
                body: JSON.stringify({
                    metric_source: {
                        enabled: status,
                    },
                }),
            },
            { 'Content-Type': 'application/json' }
        );
    };

    useEffect(() => {
        fetchMetrics();
    }, [metricSourceId]);

    if (isLoading) {
        return <Spinner />;
    }

    return (
      <MetricsTable
            label={metricName}
            metrics={metrics}
            metricSourceId={metricSourceId}
            isMetricSourceEnabled={isMetricSourceEnabled}
            fetchChangeSourceStatus={fetchChangeSourceStatus}
            reloadList={reloadList}
            isAccordionOpen={isAccordionOpen}
        />
    );
};

Metrics.propTypes = {
    metricSourceId: PropTypes.string.isRequired,
    metricName: PropTypes.string.isRequired,
    metricSourceEnabled: PropTypes.bool.isRequired,
};

export default Metrics;
