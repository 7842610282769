import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ProcessedIcon } from 'assets/svg/upload-processed.svg';
import { ReactComponent as ProcessingIcon } from 'assets/svg/upload-processing.svg';
import { ReactComponent as ErrorIcon } from 'assets/svg/upload-error.svg';
import { ReactComponent as DownloadIcon } from 'assets/svg/download-thick-bottom.svg';
import useDate, { DATE_TYPES } from 'hooks/useDate';
import Button from 'components/Button';
import {
    UsersUploadItemHolder,
    DateHolder,
    StatusHolder,
    TableHeaderHolder,
    ActionColumnWrapper,
    DownloadIconWrapper,
} from './UsersUploadItem.style';

const getUploadStatus = (status) => {
    if (status === 'error') {
        return <ErrorIcon />;
    }

    if (status === 'processing') {
        return <ProcessingIcon />;
    }

    return <ProcessedIcon />;
};

export const UsersUploadsTableHeader = () => (
  <TableHeaderHolder>
    <span>Filename</span>
    <span>Upload Date</span>
    <span>Status</span>
  </TableHeaderHolder>
);

const renderStatus = (status) => (
  <>{status.charAt(0).toUpperCase() + status.slice(1)}</>
);
const renderErrorMessage = (errorMessage) => (
  <small className="errMessage">{errorMessage}</small>
);

const UsersUploadItem = ({
    id,
    createdAt,
    fileName,
    status,
    errorsFileUrl,
    handleDownloadFile,
    tryAgain,
    handleTryAgain,
    errorMessage,
}) => {
    const creationDate = new Date(createdAt);

    return (
      <UsersUploadItemHolder id={id}>
        <strong>{fileName}</strong>
        <DateHolder>{useDate(creationDate, DATE_TYPES.long)}</DateHolder>
        <StatusHolder>
          {getUploadStatus(status)}
          {status !== 'error' && renderStatus(status)}

          {status === 'error' &&
                    errorMessage &&
                    renderErrorMessage(errorMessage)}

          {status === 'error' && !errorMessage && renderStatus(status)}
        </StatusHolder>
        <ActionColumnWrapper>
          {status === 'error' && tryAgain && (
            <Button primary onClick={() => handleTryAgain(id)}>
              Try again
            </Button>
                )}
          {status === 'error' && errorsFileUrl && (
            <DownloadIconWrapper>
              <DownloadIcon
                            onClick={() =>
                                handleDownloadFile(errorsFileUrl, fileName)}
                        />
            </DownloadIconWrapper>
                )}
        </ActionColumnWrapper>
      </UsersUploadItemHolder>
    );
};

UsersUploadItem.propTypes = {
    id: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    fileName: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    errorsFileUrl: PropTypes.string,
    handleDownloadFile: PropTypes.func.isRequired,
    tryAgain: PropTypes.bool,
    handleTryAgain: PropTypes.func.isRequired,
    errorMessage: PropTypes.oneOf([PropTypes.string, null]).isRequired,
};

UsersUploadItem.defaultProps = {
    errorsFileUrl: undefined,
    tryAgain: false,
};

export default UsersUploadItem;
