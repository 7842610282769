import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';
import { SmartTipListItem } from '../../components/SmartTipListItem/SmartTipListItem';
import {
    FilterLabel,
    SeparatorLine,
    SmartFilterClearBtnContainer,
    SmartTipFiltersContainer,
    SmartTipsItemsContainer,
} from './styled';
import Button from '../../components/Button';
import Input from '../../components/Input';
import { idize } from '../CreateNewUserHA/CreateNewUserHA';
import apiRoutes from '../../config/apiRoutes';
import { ClassificationTagDropdown } from '../../components/ClassificationTagDropdown/ClassificationTagDropdown';
import Pagination from '../../components/Pagination';
import { DEFAULT_LIMIT, generatePages, TAGS_INIT_VALS } from './helpers';

export const BrowseAvailableSmartTipsForProvider = () => {
    const { getAccessTokenSilently } = useAuth0();
    const [smartTips, setSmartTips] = useState([]);
    const [nameFilter, setNameFilter] = useState('');
    const [totalCount, setTotalCount] = useState(null);
    const [page, setPage] = useState(0);

    const [tagsFilter, setTagsFilter] = useState(TAGS_INIT_VALS);

    const tagsFilterPrimitive = JSON.stringify(tagsFilter);

    const history = useHistory();

    const totalPages = Math.ceil(totalCount / DEFAULT_LIMIT);

    useEffect(() => {
        const params = new URLSearchParams(document.location.search);
        const initNameParam = params.get('name');
        if (initNameParam) {
            // setNameFilter(initNameParam);
        }
    }, []);

    useEffect(() => {
        getAccessTokenSilently().then((token) => {
            const queryParams = {
                search: nameFilter,
                type: 'published',
                limit: DEFAULT_LIMIT,
                offset: page * DEFAULT_LIMIT,
                classification_tag_ids: [
                    ...tagsFilter.role_job,
                    ...tagsFilter.department,
                ].map(idize),
            };

            fetch(apiRoutes.get.browseSmartTips(queryParams), {
                method: 'GET',
                headers: {
                    organization_slug:
                        window.localStorage.getItem('MP_ORGANIZATION'),
                    Authorization: `Token token="${token}"`,
                },
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.error) {
                        return;
                    }

                    setTotalCount(res.total_count);
                    setSmartTips(res.smart_tips);
                });
        });
    }, [nameFilter, tagsFilterPrimitive, page]);

    return (
        <div style={{ margin: 20 }}>
            <SmartTipFiltersContainer>
                <FilterLabel>Filters</FilterLabel>
                <div className="filter-inputs">
                    <Input
                        variant="base"
                        value={nameFilter}
                        placeholder="Smart Tip Name"
                        onChange={(e) => {
                            setPage(0);
                            setNameFilter(e.target.value);
                        }}
                    />

                    <ClassificationTagDropdown
                        currentlySavedTags={tagsFilter}
                        setClassificationTags={(newTags) => {
                            setPage(0);
                            setTagsFilter(newTags);
                        }}
                        layout="horizontal"
                        localMode
                    />
                </div>

                <SeparatorLine />

                <SmartFilterClearBtnContainer>
                    <Button
                        primary
                        onClick={() => {
                            setNameFilter('');
                            setTagsFilter(TAGS_INIT_VALS);
                        }}
                    >
                        Clear filters
                    </Button>
                </SmartFilterClearBtnContainer>
            </SmartTipFiltersContainer>

            <SmartTipsItemsContainer>
                {smartTips.map((st) => (
                    <SmartTipListItem
                        key={st.id}
                        name={st.name}
                        desc={st.description}
                        onClick={() => {
                            history.push(`/smart-tips/${st.id}`);
                        }}
                    />
                ))}

                <Pagination
                    pages={generatePages(totalCount)}
                    currentPage={page}
                    handleNextPage={() => {
                        setPage(page + 1);
                    }}
                    handlePrevPage={() => {
                        setPage(page - 1);
                    }}
                    setCurrentPage={setPage}
                    isPrevEnabled={() => page > 0}
                    isNextEnabled={() => page + 1 < totalPages}
                />
            </SmartTipsItemsContainer>
        </div>
    );
};
