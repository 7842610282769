import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SectionHeader from 'components/SectionHeader';
import SmartTipCTA from 'components/SmartTipCTA';
import Spinner from 'components/Spinner';
import { ReactComponent as ArrowBackIcon } from 'assets/svg/arrow-back.svg';
import theme from 'assets/theme/mainTheme';
import SmartTipSatisfactionSurvey from 'components/SmartTipSatisfactionSurvey';
import SmartTipDescription from 'components/SmartTipDescription';
import SmartTipStatus from 'components/SmartTipStatus';
import SurveyFeedbackModal from 'containers/SurveyFeedbackModal';
import NoResults from 'components/EmptyState/NoResults';
import {
    StyledSmartTipView,
    StyledPlayer,
    StyledSmartTipStatus,
    Divider,
    NoResultsWrapper,
} from './SmartTipsDetailsView.style';

const OrganizationSmartTipDetailsView = ({
    isFetching,
    smartTipId,
    isAssignedToAll,
    name,
    status,
    description,
    score,
    evaluationsCount,
    commentsCount,
    serveysEnabled,
    source,
    handleVideoError,
    isVideoError,
    reload,
}) => {
    const [
        isSurveyResultsModalVisible,
        setIsSurveyResultsModalVisible,
    ] = useState(false);

    const toggleSurveyResultsModal = () =>
        setIsSurveyResultsModalVisible(!isSurveyResultsModalVisible);

    const renderStatus = () => {
        switch (status) {
            case 'published':
                return (
                  <SmartTipStatus
                        customStyles={StyledSmartTipStatus}
                        variant={theme.color.checkGreen}
                        text="Published"
                    />
                );
            case 'review':
                return (
                  <SmartTipStatus
                        customStyles={StyledSmartTipStatus}
                        variant={theme.color.gold}
                        text="Review"
                    />
                );
            default:
                return (
                  <SmartTipStatus
                        customStyles={StyledSmartTipStatus}
                        variant={theme.color.textLight}
                        text="Unpublished"
                    />
                );
        }
    };

    if (isFetching) {
        return <Spinner />;
    }

    return (
      <StyledSmartTipView>
        {isSurveyResultsModalVisible && (
        <SurveyFeedbackModal
                    selectedSmartTip={{ id: smartTipId }}
                    closeModal={toggleSurveyResultsModal}
                />
            )}
        <SectionHeader
                title={name}
                goBack={{
                    label() {
                        return (
                          <>
                            <ArrowBackIcon stroke={theme.color.textLabel} />
                            {' '}
                            <span>Back</span>
                          </>
                        );
                    },
                }}
                cta={() => (
                  <SmartTipCTA
                        status={status}
                        smartTipId={smartTipId}
                        isAssignedToAll={isAssignedToAll}
                        reload={reload}
                    />
                )}
            >
          {renderStatus()}
        </SectionHeader>

        {(!source.id || source.id.length === 0) && isVideoError ? (
          <NoResultsWrapper>
            <NoResults
                        reload
                        type="oops"
                        message="There is something wrong with video player. Please come back later or contact the Administrator."
                    />
          </NoResultsWrapper>
            ) : (
              <StyledPlayer
                    responsive
                    video={source.id}
                    showPortrait={false}
                    showTitle={false}
                    showByline={false}
                    onError={handleVideoError}
                />
            )}

        <SmartTipSatisfactionSurvey
                onShowResultsClick={toggleSurveyResultsModal}
                score={score}
                evaluationsCount={evaluationsCount}
                commentsCount={commentsCount}
                serveysEnabled={serveysEnabled}
            />

        {description && <Divider />}

        <SmartTipDescription description={description} />
      </StyledSmartTipView>
    );
};

OrganizationSmartTipDetailsView.propTypes = {
    isFetching: PropTypes.bool.isRequired,
    smartTipId: PropTypes.string,
    isAssignedToAll: PropTypes.bool,
    name: PropTypes.string,
    status: PropTypes.string,
    score: PropTypes.string,
    description: PropTypes.string,
    evaluationsCount: PropTypes.number,
    commentsCount: PropTypes.number,
    serveysEnabled: PropTypes.bool,
    source: PropTypes.shape({
        type: PropTypes.string,
        id: PropTypes.string,
    }).isRequired,
    handleVideoError: PropTypes.func.isRequired,
    isVideoError: PropTypes.bool.isRequired,
    reload: PropTypes.func,
};

OrganizationSmartTipDetailsView.defaultProps = {
    smartTipId: '',
    name: '',
    status: '',
    score: '0',
    evaluationsCount: 0,
    commentsCount: 0,
    serveysEnabled: false,
    reload: () => {},
    isAssignedToAll: false,
    description: '',
};

export default OrganizationSmartTipDetailsView;
