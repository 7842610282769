import React from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from 'routing/constants';
import { ReactComponent as CongratsIcon } from 'assets/svg/congrats.svg';
import { StyledCongratsWrapper, StyledCongrats } from './Congratulations.style';

const Congratulations = () => (
  <StyledCongratsWrapper>
    <StyledCongrats>
      <CongratsIcon />
      <strong>Congratulations!</strong>
      <span>
        You&apos;re all caught-up.
        {' '}
        <Link to={ROUTES.smartTips}>Click here</Link>
        {' '}
        to browse all
        <br className="hiddenSmall" />
        {' '}
        assigned and completed Smart
        Tips.
      </span>
    </StyledCongrats>
  </StyledCongratsWrapper>
);

export default Congratulations;
