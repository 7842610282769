import styled, { css } from 'styled-components/macro';

export const StyledUserDetailsWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
`;

export const StyledUserStatistics = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px;

    @media (max-width: ${({ theme }) => `${theme.breakpoints.mobile}px`}) {
        grid-template-columns: 1fr;
    }
`;

export const StyledUserDetailsSections = styled.div`
    display: flex;
    flex-flow: column wrap;
    width: 100%;
    padding: 0 16px;

    ${({ marginTop }) =>
        marginTop &&
        css`
            margin-top: 32px;
        `}
`;

export const CtaWrapper = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
`;

export const DropdownWrapper = styled.div`
    position: relative;
`;

export const Dropdown = styled.div`
    z-index: 1;
    display: flex;
    flex-direction: column;
    width: 180px;
    position: absolute;
    border-radius: ${({ theme }) => theme.border.radiusBase};
    background-color: ${({ theme }) => theme.color.white};
    box-shadow: ${({ theme }) => theme.boxShadow.base};
    top: 25px;
    right: 0px;
`;

export const Divider = styled.div`
    width: 100%;
    border-bottom: 1px solid;
    border-color: ${({ theme }) => theme.color.borderColorBase};
`;

export const DropdownItem = styled.div`
    margin: 16px;
    padding: 16px;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    color: ${({ theme }) => theme.color.primary};
    border-radius: ${({ theme }) => theme.border.radiusSmall};
    z-index: 1;

    ${({ theme, secondary }) =>
        secondary &&
        css`
            color: ${theme.color.red};
        `}

    &:hover {
        cursor: pointer;
        background-color: ${({ theme }) => theme.color.bgPrimaryLight};

        ${({ theme, secondary }) =>
            secondary &&
            css`
                background-color: ${theme.color.pinkLight};
            `}
    }
`;
