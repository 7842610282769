import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Button from 'components/Button';
import { ReactSVG } from 'react-svg';
import { ReactComponent as ClockIcon } from 'assets/svg/alarm-clock.svg';
import { ReactComponent as PlayIcon } from 'assets/svg/play.svg';
import theme from 'assets/theme/mainTheme';
import { ROUTES } from 'routing/constants';
import Spinner from 'components/Spinner';
import {
    StyledButtonGroup,
    StyledSmartTip,
    StyledTitle,
    StyledCompactButton,
    StyledNewTipMsg,
    StyledCloseIcon,
} from './SmartTip.style';

const SmartTip = ({
    title,
    size = 'regular',
    isNew = false,
    buttonLabel,
    type,
    typeLabel,
    tipId,
    openModalById,
    clickable,
    markAsOpened,
    ...rest
}) => {
    // Style flags
    const isSmartTip = type === 'tip';
    const isRecommendation = type === 'recommendation';
    const isTipOfTheWeek = type === 'tip_of_the_week';
    const isButtonCompact = size === 'compact';

    const getImageId = () => {
        if (isRecommendation) {
            return rest.performanceImage;
        }
        if (isTipOfTheWeek) {
            return rest.totwImage;
        }
        return rest.imageUrl;
    };

    return (
        <StyledSmartTip type={type} size={size}>
            {isNew && (
                <StyledNewTipMsg data-testid="tip-new-msgbox">
                    New!
                </StyledNewTipMsg>
            )}
            <StyledTitle
                isSmartTip={isSmartTip}
                isRecommendation={isRecommendation}
                isTipOfTheWeek={isTipOfTheWeek}
            >
                <small data-testid="tip-type-label">{typeLabel}</small>{' '}
                <strong data-testid="tip-title">{title}</strong>
            </StyledTitle>
            <StyledButtonGroup>
                {isSmartTip && (
                    <Link to={clickable && `${ROUTES.smartTips}/${tipId}`}>
                        {isButtonCompact ? (
                            <StyledCompactButton primary>
                                <PlayIcon fill={theme.color.primary} />
                            </StyledCompactButton>
                        ) : (
                            <Button
                                primary
                                data-testid="tip-play-button"
                                onClick={() => markAsOpened(tipId, 'opened')}
                            >
                                {buttonLabel}
                            </Button>
                        )}
                    </Link>
                )}
                {(isRecommendation || isTipOfTheWeek) && (
                    <Link to={clickable && `${ROUTES.smartTips}/${tipId}`}>
                        {isButtonCompact ? (
                            <StyledCompactButton>
                                <PlayIcon
                                    data-testid="tip-play-icon"
                                    fill={
                                        isRecommendation
                                            ? theme.color.primary
                                            : theme.color.violet
                                    }
                                />
                            </StyledCompactButton>
                        ) : (
                            <Button
                                white
                                data-testid="tip-play-button"
                                onClick={() => markAsOpened(tipId, 'opened')}
                            >
                                {buttonLabel}
                            </Button>
                        )}
                    </Link>
                )}
                {openModalById && (
                    <StyledCompactButton
                        primary
                        primaryDark={isRecommendation}
                        violetDark={isTipOfTheWeek}
                        id="setReminderButton"
                        onClick={(e) => {
                            if (
                                e.target.id === 'setReminderButton' ||
                                (e.target.tagName &&
                                    e.target.tagName === 'path')
                            ) {
                                openModalById(tipId);
                            }
                        }}
                        data-testid="tip-reminder-button"
                    >
                        <ClockIcon
                            fill={
                                isSmartTip
                                    ? theme.color.primary
                                    : theme.color.white
                            }
                        />
                    </StyledCompactButton>
                )}
            </StyledButtonGroup>
            {markAsOpened && (
                <StyledCloseIcon
                    onClick={() => markAsOpened(tipId, 'opened')}
                    type={type}
                />
            )}
            <ReactSVG
                afterInjection={() => {}}
                beforeInjection={(svg) => {
                    svg.classList.add('svg-class-name');
                    svg.setAttribute(
                        'style',
                        'position: absolute; top: 16px; right: 48px; bottom: 32px; transform: scale(1.5);'
                    );
                }}
                className="wrapper-class-name"
                evalScripts="always"
                fallback={() => <></>}
                loading={() => <Spinner />}
                onClick={() => {}}
                renumerateIRIElements={false}
                src={getImageId()}
            />
        </StyledSmartTip>
    );
};

SmartTip.propTypes = {
    title: PropTypes.string.isRequired,
    size: PropTypes.string,
    isNew: PropTypes.bool,
    tipId: PropTypes.string.isRequired,
    buttonLabel: PropTypes.string,
    type: PropTypes.string.isRequired,
    typeLabel: PropTypes.string.isRequired,
    openModalById: PropTypes.func,
    clickable: PropTypes.bool,
    markAsOpened: PropTypes.func,
};

SmartTip.defaultProps = {
    isNew: false,
    size: 'regular',
    buttonLabel: 'Play video',
    openModalById: undefined,
    clickable: true,
    markAsOpened: undefined,
};

export default SmartTip;
