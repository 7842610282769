import React from 'react';
import PropTypes from 'prop-types';
import SectionHeader from 'components/SectionHeader';
import SingleCourse from 'components/SingleCourse';
import SmartTip from 'components/SmartTip';
import RelativePerformanceChart from 'containers/RelativePerformance';
import Button from 'components/Button';
import Accordion from 'components/Accordion';
import MetricModal from 'components/MetricModal';
import { ReactComponent as RightArrowIcon } from 'assets/svg/arrow-right.svg';
import {
    StyledPerformanceDashboard,
    StyledColumn,
    StyledChartWrapper,
} from 'components/PerformanceDashboard/PerformanceDashboard.style';
import {
    StyledDataSection,
    StyledDataBox,
} from 'components/PerformanceDashboard/Stats/Stats.style';
import StatisticBox from 'components/StatisticBox';
import {
    StyledSmartTips,
    StyledTipsHolder,
    StyledTipsWrapper,
    StyledButton,
} from 'containers/SmartTips/SmartTips.style';
import {
    accordionCustomStyles,
    MetricAccordionBody,
} from 'containers/PerformanceMetrics/PerformanceMetrics.style';
import PerformanceSectionWrapper, {
    StatisticsWrapper,
} from 'views/Performance/Performance.style';
import {
    StyledDashboardWrapper,
    HighlightableSection,
    DashboardItemWrapper,
} from './DashboardMain.style';

const metricSourceMock = {
    id: 'PE Dashboard',
    name: 'PE Dashboard',
};

const metricsMock = [
    {
        name: 'Activity within scheduled hours',
        description: 'Time spent in EMR system on diagnostic tab',
        result: 10,
        goal: 60,
        efficient: false,
    },
    {
        name: "Metric's performance score",
        description: 'Time spent in EMR system on diagnostic tab',
        result: 75,
        goal: 50,
        efficient: true,
    },
    {
        name: "Metric's performance score",
        description: 'Time spent in EMR system on diagnostic tab',
        result: 40,
        goal: 50,
        efficient: false,
    },
];

const smartTipsMock = [
    {
        id: 'mockTip1',
        title: 'Here is tip that could boost your performance',
        type: 'recommendation',
        typeLabel: 'Mentor recommendation',
        isVisible: true,
        buttonLabel: 'Play video',
        duration: 15,
    },
    {
        id: 'mockTip2',
        title: 'How about learning a new thing today?',
        type: 'tip',
        typeLabel: 'Smart tip',
        isVisible: true,
        buttonLabel: 'Play video',
        duration: 15,
    },
];

const coursesMock = [
    {
        id: 'mockCourse1',
        title: 'Acute EHR - Cardiology',
        subtitle: 'Complete by: 03 Aug 2021',
        buttonLabel: 'Continue',
        isNew: false,
    },
    {
        id: 'mockCourse2',
        title: 'Acute EHR - Cardiology',
        subtitle: 'Complete by: 12 Nov 2021',
        buttonLabel: 'Continue',
        isNew: false,
    },
    {
        id: 'mockCourse3',
        title: 'Acute EHR - Cardiology',
        subtitle: 'Complete by: 03 May 2021',
        buttonLabel: 'Continue',
        isNew: true,
    },
];

const DashboardOnboardingMock = ({
    displayPerformance,
    highlightChart,
    highlightMetrics,
}) => {
    if (displayPerformance) {
        return (
            <>
                <SectionHeader title="Performance" withPadding />
                <PerformanceSectionWrapper withPadding>
                    <StyledPerformanceDashboard>
                        <HighlightableSection highlighted={highlightChart}>
                            <StyledChartWrapper>
                                <RelativePerformanceChart
                                    data={[
                                        {
                                            score: 50,
                                            provider_score: 13,
                                            measured_at: '12/01',
                                        },
                                        {
                                            score: 12,
                                            provider_score: 50,
                                            measured_at: '12/02',
                                        },
                                        {
                                            score: 30,
                                            provider_score: 70,
                                            measured_at: '12/03',
                                        },
                                        {
                                            score: 90,
                                            provider_score: 100,
                                            measured_at: '12/04',
                                        },
                                        {
                                            score: 100,
                                            provider_score: 10,
                                            measured_at: '12/05',
                                        },
                                        {
                                            score: 12,
                                            provider_score: 30,
                                            measured_at: '12/06',
                                        },
                                    ]}
                                />
                            </StyledChartWrapper>
                        </HighlightableSection>
                        <StyledDataSection>
                            <StyledDataBox>
                                <small>Completed Smart Tips monthly</small>
                                <span>
                                    <strong>{15}</strong>
                                    {/* TODO: fix it */}
                                </span>
                            </StyledDataBox>
                            <StyledDataBox>
                                <small>Active Smart Tips monthly</small>
                                <span>
                                    <strong>{25}</strong>
                                </span>
                            </StyledDataBox>
                        </StyledDataSection>
                    </StyledPerformanceDashboard>
                </PerformanceSectionWrapper>

                <PerformanceSectionWrapper withPadding>
                    <HighlightableSection highlighted={highlightMetrics}>
                        <SectionHeader title="Performance metrics" />
                        <Accordion
                            label={metricSourceMock.name}
                            customStyles={accordionCustomStyles}
                            customOnClick={() => {}}
                            rightSideCta={() => {}}
                            initiallyOpen
                        >
                            <MetricAccordionBody>
                                {metricsMock.map((singleMetric) => (
                                    <MetricModal
                                        key={`${singleMetric.name}${singleMetric.result}`}
                                        title={singleMetric.name}
                                        subtitle={singleMetric.description}
                                        scoring={singleMetric.result}
                                        goal={singleMetric.goal}
                                        isEfficient={singleMetric.efiicient}
                                        customCta={() => (
                                            <Button primary fullWidth>
                                                Go to Smart Tip{' '}
                                            </Button>
                                        )}
                                    />
                                ))}
                            </MetricAccordionBody>
                        </Accordion>
                    </HighlightableSection>
                    <HighlightableSection highlighted={false}>
                        <Accordion
                            label="Quality Vantage"
                            customStyles={accordionCustomStyles}
                            customOnClick={() => {}}
                            rightSideCta={() => {}}
                        >
                            <div />
                        </Accordion>
                    </HighlightableSection>
                </PerformanceSectionWrapper>

                <SectionHeader title="Achievements" withPadding />
                <StatisticsWrapper withPadding>
                    <StatisticBox
                        metric={64}
                        label="Stars earned in total"
                        type="stars-earned"
                    />
                    <StatisticBox
                        metric="5 level"
                        label="Your current level"
                        type="level"
                    />
                    <StatisticBox
                        metric={12}
                        label="Stars to next level"
                        type="stars-to-next-level"
                    />
                </StatisticsWrapper>
            </>
        );
    }

    return (
        <StyledDashboardWrapper>
            <DashboardItemWrapper>
                <SectionHeader
                    title="New Smart Tips"
                    subtitle="All Smart Tips"
                />
                <StyledTipsWrapper>
                    <StyledSmartTips>
                        <StyledTipsHolder propWidth={2}>
                            {smartTipsMock.map((tip) => (
                                <SmartTip
                                    id={`${tip.id}-singleTip`}
                                    tipId={tip.id}
                                    key={tip.title}
                                    {...tip}
                                />
                            ))}
                        </StyledTipsHolder>
                    </StyledSmartTips>
                    <StyledButton type="button" isRightSide onClick={() => {}}>
                        <RightArrowIcon />
                    </StyledButton>
                </StyledTipsWrapper>

                <SectionHeader
                    title="Your performance"
                    subtitle="See performance"
                />
                <StyledPerformanceDashboard>
                    <StyledColumn>
                        <RelativePerformanceChart
                            data={[
                                {
                                    overallScore: 50,
                                    providerOverallScore: 13,
                                    date: '12/01',
                                },
                                {
                                    overallScore: 12,
                                    providerOverallScore: 50,
                                    date: '12/02',
                                },
                                {
                                    overallScore: 30,
                                    providerOverallScore: 70,
                                    date: '12/03',
                                },
                                {
                                    overallScore: 90,
                                    providerOverallScore: 100,
                                    date: '12/04',
                                },
                                {
                                    overallScore: 100,
                                    providerOverallScore: 10,
                                    date: '12/05',
                                },
                                {
                                    overallScore: 12,
                                    providerOverallScore: 30,
                                    date: '12/06',
                                },
                            ]}
                        />
                    </StyledColumn>
                    <StyledColumn>
                        <strong>
                            You can learn more about your performance!
                        </strong>
                        <span>
                            Points you earned, time you spent in the platform...
                            all that and much more you can find in performance!
                        </span>
                        <Button primary>Go to Performance</Button>
                    </StyledColumn>
                </StyledPerformanceDashboard>
            </DashboardItemWrapper>

            <DashboardItemWrapper>
                <SectionHeader title="Your courses" subtitle="All courses" />
                {coursesMock.map((course) => (
                    <SingleCourse key={course.id} {...course} />
                ))}
            </DashboardItemWrapper>
        </StyledDashboardWrapper>
    );
};

DashboardOnboardingMock.propTypes = {
    displayPerformance: PropTypes.bool.isRequired,
    highlightChart: PropTypes.bool.isRequired,
    highlightMetrics: PropTypes.bool.isRequired,
};

export default DashboardOnboardingMock;
