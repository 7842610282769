import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ReactComponent as Lupe } from 'assets/svg/lupe.svg';
import {
    StyledSearch,
    StyledSearchHolder,
    StyledMobileSearch,
    StyledMobileSearchHolder,
    SearchResultsDropdown,
    SearchResultsSection,
    MobileSearchResultsDropdown,
} from './Search.style';
import Button from '../Button';

const Search = ({
    searchState,
    onSearch,
    isMobile,
    searchResults,
    clearResults,
}) => {
    const history = useHistory();
    const resultsDropdownRef = useRef(null);
    const searchInputRef = useRef(null);

    const [isSearchVisible, setIsSearchVisible] = useState(false);
    const [isResultsDropdownVisible, setIsResultsDropdownVisible] = useState(
        false
    );

    const { smartTips, courses } = searchResults;

    const clearSearch = () => {
        onSearch('');
        clearResults();
    };

    const showDropdown = () => {
        clearSearch();
        setIsResultsDropdownVisible(true);
    };

    const hideDropdown = () => {
        clearSearch();
        setIsResultsDropdownVisible(false);
        setIsSearchVisible(false);
    };

    const handleSearch = (e) => {
        onSearch(e.target.value);
    };

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (
                resultsDropdownRef.current &&
                !resultsDropdownRef.current.contains(e.target) &&
                searchInputRef.current &&
                !searchInputRef.current.contains(e.target)
            ) {
                hideDropdown();
            }
        };

        document.addEventListener('mousedown', checkIfClickedOutside);

        return () =>
            document.removeEventListener('mousedown', checkIfClickedOutside);
    }, [resultsDropdownRef, searchInputRef, hideDropdown]);

    if (isMobile) {
        return (
          <StyledMobileSearchHolder
                isSearchVisible={isSearchVisible}
                onClick={() =>
                    !isSearchVisible && setIsSearchVisible(!isSearchVisible)}
            >
            <StyledMobileSearch
                    value={searchState || ''}
                    isSearchVisible={isSearchVisible}
                    onChange={(e) => handleSearch(e)}
                    onFocus={showDropdown}
                    placeholder="Search"
                    id="globalSearch"
                />
            <Lupe onClick={() => setIsSearchVisible(!isSearchVisible)} />
            {isResultsDropdownVisible &&
                    (smartTips.length > 0 || courses.length > 0) && (
                    <MobileSearchResultsDropdown ref={resultsDropdownRef}>
                      {smartTips.length > 0 && (
                      <SearchResultsSection>
                        <strong>Smart Tips</strong>
                        {smartTips.map((smartTip) => (
                          <span
                                            key={smartTip.id}
                                            onClick={() => {
                                                history.push(
                                                    `/smart-tips/${smartTip.id}`
                                                );
                                                hideDropdown();
                                            }}
                                        >
                            {smartTip.name}
                          </span>
                                    ))}
                      </SearchResultsSection>
                            )}
                      {courses.length > 0 && (
                      <SearchResultsSection>
                        <strong style={{ marginBottom: '16px' }}>
                          Courses
                        </strong>
                        {courses.map((course) => (
                          <>
                            <a
                                                href={course.url}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                style={{ width: '100%' }}
                                            >
                              <span key={course.id}>
                                {course.name}
                              </span>
                            </a>
                          </>
                                    ))}
                      </SearchResultsSection>
                            )}
                      <Button
                                primary
                                white
                                onClick={() => {
                                    hideDropdown();
                                    history.push(
                                        `/browse-smart-tips?name=${searchState}`
                                    );
                                }}
                            >
                        View All
                      </Button>
                    </MobileSearchResultsDropdown>
                    )}
          </StyledMobileSearchHolder>
        );
    }

    return (
      <StyledSearchHolder>
        <StyledSearch
                value={searchState || ''}
                onChange={(e) => handleSearch(e)}
                onFocus={showDropdown}
                placeholder="Search"
                id="globalSearch"
                ref={searchInputRef}
            />
        <Lupe />
        {isResultsDropdownVisible &&
                (smartTips.length > 0 || courses.length > 0) && (
                <SearchResultsDropdown ref={resultsDropdownRef}>
                  {smartTips.length > 0 && (
                  <SearchResultsSection>
                    <strong>Smart Tips</strong>
                    {smartTips.map((smartTip) => (
                      <span
                                        key={smartTip.id}
                                        onClick={() => {
                                            history.push(
                                                `/smart-tips/${smartTip.id}`
                                            );
                                            hideDropdown();
                                        }}
                                    >
                        {smartTip.name}
                      </span>
                                ))}
                  </SearchResultsSection>
                        )}
                  {courses.length > 0 && (
                  <SearchResultsSection>
                    <strong style={{ marginBottom: '16px' }}>
                      Courses
                    </strong>
                    {courses.map((course) => (
                      <>
                        <a
                                            href={course.url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{ width: '100%' }}
                                        >
                          <span key={course.id}>
                            {course.name}
                          </span>
                        </a>
                      </>
                                ))}
                  </SearchResultsSection>
                        )}
                  <Button
                            primary
                            white
                            onClick={() => {
                                hideDropdown();
                                history.push(
                                    `/browse-smart-tips?name=${searchState}`
                                );
                            }}
                        >
                    View All
                  </Button>
                </SearchResultsDropdown>
                )}
      </StyledSearchHolder>
    );
};

Search.propTypes = {
    searchState: PropTypes.string.isRequired,
    searchResults: PropTypes.shape({
        smartTips: PropTypes.arrayOf(PropTypes.any),
        courses: PropTypes.arrayOf(PropTypes.any),
    }).isRequired,
    isMobile: PropTypes.bool.isRequired,
    onSearch: PropTypes.func.isRequired,
    clearResults: PropTypes.func.isRequired,
};

export default Search;
