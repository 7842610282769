import React from 'react';
import PropTypes from 'prop-types';
import 'react-datepicker/dist/react-datepicker.css';
import Calendar from './Calendar';

import {
    RemindersCalendarHolder,
    CalendarHeader,
    StyledButton,
    CalendarBody,
} from './RemindersCalendar.style';

const RemindersCalendar = ({
    selectedDate,
    setSelectedDate,
    remindersByDate,
    setIsDaySelected,
    setNewReminderModalOpened,
}) => (
  <RemindersCalendarHolder>
    <CalendarHeader>
      <StyledButton primary onClick={() => setNewReminderModalOpened()}>
        Add new reminder
      </StyledButton>
    </CalendarHeader>
    <CalendarBody>
      <Calendar
                selectedDate={selectedDate}
                selectDate={setSelectedDate}
                remindersByDate={remindersByDate}
                setIsDaySelected={setIsDaySelected}
            />
    </CalendarBody>
  </RemindersCalendarHolder>
);

RemindersCalendar.propTypes = {
    selectedDate: PropTypes.objectOf(Date).isRequired,
    setSelectedDate: PropTypes.func.isRequired,
    setIsDaySelected: PropTypes.func.isRequired,
    setNewReminderModalOpened: PropTypes.func.isRequired,
    remindersByDate: PropTypes.arrayOf(
        PropTypes.shape({
            dateTimeObject: PropTypes.instanceOf(Date),
        })
    ).isRequired,
};

export default RemindersCalendar;
