import styled from 'styled-components/macro';

export const OptionsWrapper = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 24px 0;
    top: 60px;
    right: 0px;
    width: 220px;
    height: 250px;
    border: none;
    background-color: ${({ theme }) => theme.color.white};
    border-radius: ${({ theme }) => theme.border.radiusSmall};
    box-shadow: ${({ theme }) => theme.boxShadow.base};

    @media (max-width: ${({ theme }) => `${theme.breakpoints.medium}px`}) {
        z-index: 2;
    }
`;

export const NotificationOptionsDropdownHolder = styled.div`
    position: relative;
`;

export const Divider = styled.div`
    width: 100%;
    border-bottom: 1px solid;
    border-color: ${({ theme }) => theme.color.borderColorBase};
`;

export const Label = styled.div`
    padding: 0 24px;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    color: ${({ theme }) => theme.color.textLight};
`;

export const SwitchWrapper = styled.div`
    padding: 0 24px;
`;
