import React from 'react';
import PropTypes from 'prop-types';
import SectionHeader from 'components/SectionHeader';
import { ReactComponent as ArrowBackIcon } from 'assets/svg/arrow-back.svg';
import CreateTeamForm from 'components/CreateTeamForm/CreateTeamForm';
import { ROUTES } from 'routing/constants';
import theme from 'assets/theme/mainTheme';
import CreateTeamWrapper from './CreateTeam.style';

const CreateTeamView = ({
    users,
    createTeam,
    isFetching,
    loadMoreUsers,
    hasMoreUsers,
    filterUsers,
    filterValue,
    resetUsersList,
    customCreator,
}) => (
  <CreateTeamWrapper data-testid="createTeamWrapper">
    <SectionHeader
            title="Create new team"
            withMargin
            goBack={{
                url: ROUTES.team,
                label() {
                    return (
                      <>
                        <ArrowBackIcon stroke={theme.color.textLabel} />
                        {' '}
                        <span>Back</span>
                      </>
                    );
                },
            }}
        />
    <CreateTeamForm
            users={users}
            createTeam={createTeam}
            isFetching={isFetching}
            loadMoreUsers={loadMoreUsers}
            hasMoreUsers={hasMoreUsers}
            filterUsers={filterUsers}
            filterValue={filterValue}
            resetUsersList={resetUsersList}
            customCreator={customCreator}
        />
  </CreateTeamWrapper>
);

CreateTeamView.propTypes = {
    users: PropTypes.arrayOf(PropTypes.any),
    createTeam: PropTypes.func.isRequired,
    isFetching: PropTypes.bool.isRequired,
    loadMoreUsers: PropTypes.func.isRequired,
    hasMoreUsers: PropTypes.bool.isRequired,
    filterUsers: PropTypes.func.isRequired,
    filterValue: PropTypes.string.isRequired,
    resetUsersList: PropTypes.func.isRequired,
    customCreator: PropTypes.shape({
        selected: PropTypes.string,
        onSelect: PropTypes.func,
    }),
};

CreateTeamView.defaultProps = {
    users: [],
    customCreator: {},
};

export default CreateTeamView;
