import {
    ESTABLISH_USERS_UPLOADS,
    ESTABLISH_USERS_UPLOADS_SUCCESS,
    ESTABLISH_USERS_UPLOADS_FAILURE,
    LOAD_MORE_USERS_UPLOADS,
    MARK_ALL_USERS_UPLOADS_LOADED,
    RESET_USERS_UPLOADS,
    UPLOAD_USERS,
    UPLOAD_USERS_SUCCESS,
    UPLOAD_USERS_FAILURE,
} from 'redux/actions/usersUploads';

export const initialState = {
    areUsersUploadsEstablished: false,
    isUsersUploadsError: false,
    areUsersUploadsFetching: false,
    error: null,
    usersUploads: [],
    hasMoreUsersUploads: true,
    isUsersFileUploaded: false,
    isUsersFileUploading: false,
    isUsersFileError: false,
};

const usersUploads = (state = initialState, action) => {
    switch (action.type) {
        case ESTABLISH_USERS_UPLOADS:
            return {
                ...state,
                areUsersUploadsFetching: true,
                isUsersUploadsError: false,
            };

        case ESTABLISH_USERS_UPLOADS_SUCCESS:
            return {
                ...state,
                areUsersUploadsFetching: false,
                areUsersUploadsEstablished: true,
                usersUploads: action.payload,
            };

        case ESTABLISH_USERS_UPLOADS_FAILURE:
            return {
                ...state,
                areUsersUploadsFetching: false,
                isUsersUploadsError: true,
                usersUploads: [],
                error: action.err,
            };

        case LOAD_MORE_USERS_UPLOADS:
            return {
                ...state,
                areUsersUploadsFetching: false,
                areUsersUploadsEstablished: true,
                usersUploads: [...state.usersUploads, ...action.payload],
            };

        case MARK_ALL_USERS_UPLOADS_LOADED:
            return {
                ...state,
                hasMoreUsersUploads: false,
                areUsersUploadsFetching: false,
                areUsersUploadsEstablished: true,
            };

        case RESET_USERS_UPLOADS:
            return initialState;

        case UPLOAD_USERS:
            return {
                ...state,
                isUsersFileUploaded: false,
                isUsersFileUploading: true,
                isUsersFileError: false,
            };

        case UPLOAD_USERS_SUCCESS:
            return {
                ...state,
                isUsersFileUploaded: true,
                isUsersFileUploading: false,
                isUsersFileError: false,
            };

        case UPLOAD_USERS_FAILURE:
            return {
                ...state,
                isUsersFileUploaded: false,
                isUsersFileUploading: false,
                isUsersFileError: true,
                error: action.err,
            };

        default:
            return state;
    }
};

export default usersUploads;
