import {
    ESTABLISH_METRIC_DETAILS,
    ESTABLISH_METRIC_DETAILS_SUCCESS,
    ESTABLISH_METRIC_DETAILS_FAILURE,
    RESET_METRIC_DETAILS,
} from 'redux/actions/currentMetricDetails';

export const initialState = {
    areMetricDetailsEstablished: false,
    isMetricDetailsError: false,
    areMetricDetailsFetching: false,
    error: null,
    metricDetails: null,
};

const metricDetails = (state = initialState, action) => {
    switch (action.type) {
        case ESTABLISH_METRIC_DETAILS:
            return {
                ...state,
                areMetricDetailsFetching: true,
                isMetricDetailsError: false,
            };

        case ESTABLISH_METRIC_DETAILS_SUCCESS:
            return {
                ...state,
                areMetricDetailsFetching: false,
                areMetricDetailsEstablished: true,
                metricDetails: action.payload,
            };

        case ESTABLISH_METRIC_DETAILS_FAILURE:
            return {
                ...state,
                areMetricDetailsFetching: false,
                isMetricDetailsError: true,
                error: action.err,
            };

        case RESET_METRIC_DETAILS:
            return initialState;

        default:
            return state;
    }
};

export default metricDetails;
