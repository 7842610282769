import {
    establishSmartTipEvaluationList,
    establishSmartTipEvaluationListFailure,
    establishSmartTipEvaluationListSuccess,
} from 'redux/actions/organizationSmartTipEvaluationList';
import apiRoutes from 'config/apiRoutes';

const establishSmartTipEvaluationListThunk = (
    getAccessTokenSilently,
    logout,
    smartTipId
) => (dispatch) => {
    dispatch(establishSmartTipEvaluationList());

    return getAccessTokenSilently()
        .then((token) => {
            fetch(
                apiRoutes.get.organizationSmartTipEvaluationList(smartTipId),
                {
                    method: 'GET',
                    headers: {
                        organization_slug: window.localStorage.getItem(
                            'MP_ORGANIZATION'
                        ),
                        Authorization: `Token token="${token}"`,
                    },
                }
            )
                .then((res) => res.json())
                .then((res) => {
                    if (res.error) {
                        return dispatch(
                            establishSmartTipEvaluationListFailure(res.error)
                        );
                    }

                    return dispatch(
                        establishSmartTipEvaluationListSuccess(res.evaluations)
                    );
                })
                .catch((err) =>
                    dispatch(
                        establishSmartTipEvaluationListFailure({
                            err,
                        })
                    )
                );
        })
        .catch(() => logout({ returnTo: window.location.origin }));
};

export default establishSmartTipEvaluationListThunk;
