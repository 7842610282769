const parseCourses = (courses) =>
    courses.map((course) => ({
        id: course.id,
        title: `${course.name} - ${course.catalog_category}`,
        categories: course.categories,
        subtitle: course.description || 'Missing course description',
        buttonLabel: course.status === 'enrolled' ? 'Continue' : 'Start course',
        status: course.status,
        url: course.url,
        isNew: false,
    }));

export default parseCourses;
