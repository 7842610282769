import {
    establishNotifications,
    establishNotificationsSuccess,
    establishNotificationsFailure,
    resetNotifications,
    allNotificationsLoaded,
    loadMoreNotifications,
    markNotificationAsRead,
    setUnseenNotificationsCount,
    decreaseUnseenNotificationsCount,
} from 'redux/actions/notifications';
import apiRoutes from 'config/apiRoutes';
import { getUserProfileThunk } from './profile';

const establishNotificationsThunk = (
    getAccessTokenSilently,
    logout,
    limit,
    offset
) => (dispatch) => {
    dispatch(establishNotifications());

    return getAccessTokenSilently()
        .then((token) => {
            fetch(apiRoutes.get.notifications(limit, offset), {
                method: 'GET',
                headers: {
                    organization_slug: window.localStorage.getItem(
                        'MP_ORGANIZATION'
                    ),
                    Authorization: `Token token="${token}"`,
                },
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.error) {
                        return dispatch(
                            establishNotificationsFailure(res.error)
                        );
                    }

                    return dispatch(establishNotificationsSuccess(res));
                })
                .catch((err) =>
                    dispatch(
                        establishNotificationsFailure({
                            err,
                        })
                    )
                )
                .finally(
                    () =>
                        !limit && !offset && dispatch(allNotificationsLoaded())
                );
        })
        .catch(() => logout({ returnTo: window.location.origin }));
};

export const loadMoreNotificationsThunk = (
    getAccessTokenSilently,
    logout,
    limit,
    offset
) => (dispatch) => {
    dispatch(establishNotifications());

    return getAccessTokenSilently()
        .then((token) => {
            fetch(apiRoutes.get.notifications(limit, offset), {
                method: 'GET',
                headers: {
                    organization_slug: window.localStorage.getItem(
                        'MP_ORGANIZATION'
                    ),
                    Authorization: `Token token="${token}"`,
                },
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.error) {
                        return dispatch(
                            establishNotificationsFailure(res.error)
                        );
                    }

                    if (res.notifications.length === 0) {
                        return dispatch(allNotificationsLoaded());
                    }

                    return dispatch(loadMoreNotifications(res.notifications));
                })
                .catch((err) =>
                    dispatch(
                        establishNotificationsFailure({
                            err,
                        })
                    )
                );
        })
        .catch(() => logout({ returnTo: window.location.origin }));
};

export const resetNotificationsThunk = () => (dispatch) => {
    dispatch(resetNotifications());
};

export const markAllNotificationsAsReadThunk = (
    getAccessTokenSilently,
    logout
) => (dispatch) => {
    getAccessTokenSilently()
        .then((token) => {
            fetch(apiRoutes.post.markAllNotificationsAsRead(), {
                method: 'POST',
                headers: {
                    organization_slug: window.localStorage.getItem(
                        'MP_ORGANIZATION'
                    ),
                    Authorization: `Token token="${token}"`,
                },
            })
                .catch((err) =>
                    dispatch(
                        establishNotificationsFailure({
                            err,
                        })
                    )
                )
                .finally(() => {
                    dispatch(resetNotifications());
                });
        })
        .catch(() => logout({ returnTo: window.location.origin }));
};

export const markNotificationAsReadThunk = (
    getAccessTokenSilently,
    logout,
    notificationId
) => (dispatch) => {
    dispatch(markNotificationAsRead(notificationId));

    getAccessTokenSilently()
        .then((token) => {
            fetch(apiRoutes.put.markNotificationAsRead(notificationId), {
                method: 'PUT',
                headers: {
                    organization_slug: window.localStorage.getItem(
                        'MP_ORGANIZATION'
                    ),
                    Authorization: `Token token="${token}"`,
                },
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.error) {
                        return dispatch(
                            establishNotificationsFailure(res.error)
                        );
                    }
                    return dispatch(decreaseUnseenNotificationsCount());
                })
                .catch((err) =>
                    dispatch(
                        establishNotificationsFailure({
                            err,
                        })
                    )
                );
        })
        .catch(() => logout({ returnTo: window.location.origin }));
};

export const getUnseenNotificationsCountThunk = (
    getAccessTokenSilently,
    logout
) => (dispatch) => {
    getAccessTokenSilently()
        .then((token) => {
            fetch(apiRoutes.get.getUnseenNotificationCount(), {
                method: 'GET',
                headers: {
                    organization_slug: window.localStorage.getItem(
                        'MP_ORGANIZATION'
                    ),
                    Authorization: `Token token="${token}"`,
                },
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.error) {
                        return dispatch(
                            establishNotificationsFailure(res.error)
                        );
                    }
                    return dispatch(
                        setUnseenNotificationsCount(res.unseen_count)
                    );
                })
                .catch((err) =>
                    dispatch(
                        establishNotificationsFailure({
                            err,
                        })
                    )
                );
        })
        .catch(() => logout({ returnTo: window.location.origin }));
};

export const toggleNotificationsOptionsThunk = (
    getAccessTokenSilently,
    logout,
    isScoreNotificationsEnabled,
    isMessageNotificationsEnabled,
    isEmailNotificationsEnabled
) => (dispatch) => {
    getAccessTokenSilently()
        .then((token) => {
            fetch(apiRoutes.put.toggleNotificationsOptions(), {
                method: 'PUT',
                headers: {
                    organization_slug: window.localStorage.getItem(
                        'MP_ORGANIZATION'
                    ),
                    Authorization: `Token token="${token}"`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    user: {
                        score_notifications_enabled: isScoreNotificationsEnabled,
                        message_notifications_enabled: isMessageNotificationsEnabled,
                        email_notifications_enabled: isEmailNotificationsEnabled,
                    },
                }),
            })
                .then(() => {
                    setTimeout(
                        () =>
                            dispatch(
                                getUserProfileThunk(
                                    getAccessTokenSilently,
                                    logout
                                )
                            ),
                        200
                    );
                })
                .catch((err) =>
                    dispatch(
                        establishNotificationsFailure({
                            err,
                        })
                    )
                );
        })
        .catch(() => logout({ returnTo: window.location.origin }));
};

export default establishNotificationsThunk;
