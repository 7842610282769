import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { leaveTeamThunk } from 'redux/actions/thunks/teamMembers';
import {
    archiveTeamThunk,
    archiveOrganizationTeamThunk,
} from 'redux/actions/thunks/team';
import TeamOverviewNavigationComponent from 'components/TeamOverviewNavigation';
import { useAuth0 } from '@auth0/auth0-react';

const TeamOverviewNavigation = ({ teamId, activeSection, isCreator }) => {
    const { getAccessTokenSilently, logout } = useAuth0();
    const dispatch = useDispatch();
    const history = useHistory();

    const onLeave = () => {
        dispatch(leaveTeamThunk(getAccessTokenSilently, logout, teamId));
    };

    const onTeamDelete = () => {
        dispatch(
            archiveOrganizationTeamThunk(
                getAccessTokenSilently,
                logout,
                teamId,
                history
            )
        );
    };

    const onCreatorLeave = () => {
        dispatch(archiveTeamThunk(getAccessTokenSilently, logout, teamId));
    };

    return (
      <TeamOverviewNavigationComponent
            teamId={teamId}
            activeSection={activeSection}
            onLeave={onLeave}
            onTeamDelete={onTeamDelete}
            onCreatorLeave={onCreatorLeave}
            confirmLabel="Leave team"
            isCreator={isCreator}
        />
    );
};

TeamOverviewNavigation.propTypes = {
    isCreator: PropTypes.bool.isRequired,
    teamId: PropTypes.string.isRequired,
    activeSection: PropTypes.string,
};
TeamOverviewNavigation.defaultProps = {
    activeSection: 'overview',
};

export default TeamOverviewNavigation;
