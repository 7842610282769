import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ReactComponent as PlusIcon } from 'assets/svg/plus.svg';
import SingleTeam from 'components/SingleTeam';
import Spinner from 'components/Spinner';
import SectionHeader from 'components/SectionHeader';
import Button from 'components/Button';
import InfiniteScroll from 'react-infinite-scroller';
import { ROUTES } from 'routing/constants';
import TeamWrapper, { LoadingWrapper } from './Team.style';

const TeamView = ({
    hasEstablished,
    isFetching,
    teams,
    loadFunc,
    hasMore,
    joinTeam,
    leaveTeam,
    archiveTeam,
    currentUser,
}) => {
    if (!hasEstablished) {
        return <Spinner />;
    }

    return (
      <TeamWrapper>
        <SectionHeader
                title="Teams"
                withMargin
                cta={() => (
                  <Link to={ROUTES.createTeam}>
                    <Button primaryDark svg>
                      Create new team
                      <PlusIcon />
                    </Button>
                  </Link>
                )}
            />
        <InfiniteScroll
                loadMore={loadFunc}
                loader={<Spinner key="spinner" />}
                hasMore={hasMore && !isFetching}
                style={{ width: `calc(100% - 32px)` }}
            >
          {teams.map((team) => (
            <SingleTeam
                        key={team.id}
                        creatorId={team.creator_id}
                        teamId={team.id}
                        currentUser={currentUser}
                        title={team.name}
                        joinTeam={() => joinTeam(team.id)}
                        leaveTeam={() => leaveTeam(team.id)}
                        archiveTeam={() => archiveTeam(team.id)}
                        users={team.recent_users}
                        pictureUrl={team.picture_url}
                        color={team.color}
                        bgColor={team.background_color}
                        usersCountProvided={team.users_count}
                        {...team}
                    />
                ))}
        </InfiniteScroll>
        {isFetching && (
        <LoadingWrapper>
          <Spinner />
        </LoadingWrapper>
            )}
      </TeamWrapper>
    );
};

TeamView.propTypes = {
    hasEstablished: PropTypes.bool,
    isFetching: PropTypes.bool,
    teams: PropTypes.arrayOf(PropTypes.any),
    loadFunc: PropTypes.func,
    joinTeam: PropTypes.func,
    leaveTeam: PropTypes.func,
    archiveTeam: PropTypes.func,
    hasMore: PropTypes.bool,
    currentUser: PropTypes.shape({
        id: PropTypes.string,
        userTeams: PropTypes.arrayOf(PropTypes.any),
    }).isRequired,
};

TeamView.defaultProps = {
    hasEstablished: false,
    isFetching: false,
    teams: [],
    loadFunc: () => {},
    hasMore: false,
    joinTeam: () => {},
    leaveTeam: () => {},
    archiveTeam: () => {},
};

export default TeamView;
