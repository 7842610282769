import {
    establishMetricSources,
    establishMetricSourcesSuccess,
    establishMetricSourcesFailure,
    updateMetricSource,
    updateMetricSourceSuccess,
    updateMetricSourceFailure,
} from 'redux/actions/metricSources';
import {
    dispatchSuccessAlertThunk,
    dispatchErrorAlertThunk,
} from 'redux/actions/thunks/systemAlerts';
import apiRoutes from 'config/apiRoutes';

const establishMetricSourcesThunk = (getAccessTokenSilently, logout) => (
    dispatch
) => {
    dispatch(establishMetricSources());

    return getAccessTokenSilently()
        .then((token) => {
            fetch(apiRoutes.get.organizationMetricSources, {
                method: 'GET',
                headers: {
                    organization_slug: window.localStorage.getItem(
                        'MP_ORGANIZATION'
                    ),
                    Authorization: `Token token="${token}"`,
                },
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.error) {
                        return dispatch(
                            establishMetricSourcesFailure(res.error)
                        );
                    }

                    return dispatch(
                        establishMetricSourcesSuccess(res.metric_sources)
                    );
                })
                .catch((err) =>
                    dispatch(
                        establishMetricSourcesFailure({
                            err,
                        })
                    )
                );
        })
        .catch(() => logout({ returnTo: window.location.origin }));
};

export const updateMetricSourceThunk = (
    getAccessTokenSilently,
    logout,
    metricSourceId,
    values,
    handleSuccess
) => (dispatch) => {
    dispatch(updateMetricSource());

    const formData = new FormData();
    formData.append('metric_source[enabled]', values.enabled);

    return getAccessTokenSilently()
        .then((token) => {
            fetch(apiRoutes.put.updateMetricSource(metricSourceId), {
                method: 'PUT',
                headers: {
                    organization_slug: window.localStorage.getItem(
                        'MP_ORGANIZATION'
                    ),
                    Authorization: `Token token="${token}"`,
                },
                body: formData,
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.error) {
                        dispatch(
                            dispatchErrorAlertThunk(metricSourceId, res.error)
                        );
                        dispatch(
                            establishMetricSourcesThunk(
                                getAccessTokenSilently,
                                logout
                            )
                        );
                        return dispatch(updateMetricSourceFailure(res.error));
                    }

                    if (handleSuccess) handleSuccess();

                    dispatch(
                        dispatchSuccessAlertThunk(
                            metricSourceId,
                            'Metric source status has been updated'
                        )
                    );
                    dispatch(
                        establishMetricSourcesThunk(
                            getAccessTokenSilently,
                            logout
                        )
                    );
                    return dispatch(updateMetricSourceSuccess(res));
                })
                .catch((err) => {
                    dispatch(
                        dispatchErrorAlertThunk(metricSourceId, err.error)
                    );
                    dispatch(
                        updateMetricSourceFailure({
                            err,
                        })
                    );
                    dispatch(
                        establishMetricSourcesThunk(
                            getAccessTokenSilently,
                            logout
                        )
                    );
                });
        })
        .catch(() => logout({ returnTo: window.location.origin }));
};

export default establishMetricSourcesThunk;
