import styled, { css } from 'styled-components/macro';
import Button from 'components/Button';

export const ModalCustomBodyStyle = styled.div`
    padding: 15px 23px;
    display: flex;
    flex-flow: column;
`;

const StyledButton = styled(Button)`
    margin-left: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    ${({ width }) => css`
        width: ${width};
    `}

    ${({ disabled }) =>
        disabled &&
        css`
            opacity: 0.5;
            cursor: inherit;
        `}
`;

export default StyledButton;
