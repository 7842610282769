import styled, { css } from 'styled-components/macro';

export const CreateTeamFormWrapper = styled.div`
    width: 100%;
    display: flex;
    min-height: calc(100vh - 195px);
    overflow-y: auto;

    ${({ theme }) => css`
        background-color: ${theme.color.white};
        border-radius: ${theme.border.radiusBase};
    `}

    @media (max-width: ${({ theme }) => `${theme.breakpoints.s}px`}) {
        flex-direction: column;
    }
`;

export const FormTitle = styled.span`
    color: ${({ theme }) => theme.color.textLight};
    display: block;
    margin-bottom: 24px;
`;

export const TeamPictureHolder = styled.div`
    flex-basis: 265px;
    padding: 32px 16px;
    border-right: ${({ theme }) => theme.border.base};
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const CreateTeamFormHolder = styled.div`
    width: 100%;
    padding: 32px;
    display: flex;
    flex-flow: column;
`;

export const InputWrapper = styled.div`
    max-width: 395px;
    margin-bottom: 16px;
`;

export const ButtonGroup = styled.div`
    padding-top: 16px;
    border-top: ${({ theme }) => theme.border.base};
    display: flex;
    align-items: center;
    margin-top: 16px;

    button {
        margin-right: 16px;
        padding: 16px 24px;
    }
`;

export const DropdownOption = styled.div`
    display: flex;
    align-items: center;

    img,
    svg {
        margin-right: 16px;
        width: 32px;
        height: 32px;
        border-radius: 50%;
    }
`;

export const DropdownOptionTexts = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    span {
        overflow: hidden;
        max-width: 200px;
        text-overflow: ellipsis;
    }

    small {
        overflow: hidden;
        max-width: 200px;
        text-overflow: ellipsis;
        color: ${({ theme }) => theme.color.textLight};
    }
`;
