import styled, { css } from 'styled-components/macro';
import Button from 'components/Button';

export const SingleNotificationHolder = styled.div`
    width: 100%;
    padding: 32px;
    min-height: 110px;
    background-color: ${({ theme }) => theme.color.white};
    border: ${({ theme }) => theme.border.base};
    border-radius: ${({ theme }) => theme.border.radiusBase};
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;

    @media (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
        flex-direction: column;

        * {
            margin: 8px 0;
        }
    }
`;

export const CompactSingleNotificationHolder = styled(SingleNotificationHolder)`
    border: none;
    padding: 0px 32px;
    min-height: 0px;
    margin: 24px 0px;
    flex-direction: column;
    align-items: flex-start;

    @media (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
        flex-direction: column;

        * {
            margin: 8px 0;
        }
    }
`;

export const CompactRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    @media (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
        flex-direction: column;
    }
`;

export const IconHolder = styled.div`
    position: relative;
    width: 48px;
    height: 48px;
    margin-right: 32px;

    circle {
        fill: ${({ type, theme, getCircleColor }) =>
            getCircleColor(type, theme)};
    }

    path {
        fill: ${({ type, theme, getPathColor }) => getPathColor(type, theme)};
    }

    .dot {
        position: absolute;
        right: -2px;
        top: 2px;

        circle {
            fill: ${({ theme }) => theme.color.quaternary};
        }
    }

    ${({ critical }) =>
        critical &&
        css`
            circle {
                fill: ${({ theme }) => theme.color.quaternary};
            }
        `}

    @media (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
        margin-right: 0;
    }
`;

export const MiddleSection = styled.div`
    margin-right: 32px;

    @media (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
        margin-right: 0;
    }
`;

export const Description = styled.div`
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: ${({ theme }) => theme.color.textBase};
    padding-bottom: 8px;

    ${({ isRead }) =>
        isRead &&
        css`
            font-weight: 500;
            color: ${({ theme }) => theme.color.textLight};
        `}
`;

export const DateHolder = styled.div`
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    color: ${({ theme }) => theme.color.textLight};
`;

export const ActionsHolder = styled.div`
    margin-left: auto;

    ${({ compact }) =>
        compact &&
        css`
            padding: 10px 0;
            margin-left: 0px;
            padding-left: 80px;
            width: 100%;

            @media (max-width: ${({ theme }) =>
                    `${theme.breakpoints.small}px`}) {
                padding-left: 0;
            }
        `}
`;

export const StyledButton = styled(Button)`
    ${({ compact }) =>
        compact &&
        css`
            width: 100%;
        `}
`;
