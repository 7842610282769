import styled, { css } from 'styled-components/macro';
import Button from 'components/Button';

export const StyledDropdown = styled.div`
    position: relative;
    display: inline-block;

    &:hover {
        button {
            background-color: ${({ theme }) => theme.color.white};
            z-index: 2;
        }
    }

    &:not(:last-child) {
        margin-right: 16px;
    }

    svg {
        margin-right: 8px;
    }
`;

export const StyledButton = styled(Button)`
    min-width: 170px;
    height: 54px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;

    ${({ fullWidth }) =>
        fullWidth &&
        css`
            width: 100%;
        `}
`;

export const ButtonContent = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const StyledDropdownContent = styled.div`
    position: absolute;
    min-width: 170px;
    background-color: ${({ theme }) => theme.color.white};
    border-radius: ${({ theme }) => theme.border.radiusSmall};
    border: ${({ theme }) => theme.border.base};
    box-shadow: ${({ theme }) => theme.boxShadow.base};
    z-index: 10;

    ${({ fullWidth }) =>
        fullWidth &&
        css`
            width: 100%;
        `}
`;

export const SelectOption = styled.div`
    border-radius: ${({ theme }) => theme.border.radiusBase};
    padding: 12px 16px;
    text-decoration: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    user-select: none;

    ${({ withoutDivider }) =>
        !withoutDivider &&
        css`
            &:not(:last-child) {
                border-bottom: ${({ theme }) => theme.border.base};
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }
        `}

    &:hover {
        background-color: ${({ theme }) => theme.color.white};
        color: ${({ theme }) => theme.color.primary};
    }
`;
