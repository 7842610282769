import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useOffset from 'hooks/useOffset';
import PropTypes from 'prop-types';
import Modal from 'components/Modal';
import Button from 'components/Button';
import ManageUsersIdsModal from 'components/ManageUsersIdsModal';
import { KEYBOARD_KEYS } from 'constants.js';
import {
    establishOrganizationUsersModalThunk,
    loadMoreOrganizationUsersModalThunk,
} from 'redux/actions/thunks/organizationUsersModal';
import { resetOrganizationUsersModal } from 'redux/actions/organizationUsersModal';
import { useAuth0 } from '@auth0/auth0-react';

const INFINITE_SCROLL_THRESHOLD = 20;

const ManageUsersIdsModalComponent = ({ toggleVisibility }) => {
    const { getAccessTokenSilently, logout } = useAuth0();
    const dispatch = useDispatch();
    const [filter, setFilter] = useState('');
    const [offset, incrementOffset, resetOffset] = useOffset(
        INFINITE_SCROLL_THRESHOLD
    );

    const {
        amountOfAllUsers,
        organizationUsers,
        areOrganizationUsersFetching,
        hasMoreOrganizationUsers,
    } = useSelector((state) => state.organizationUsersModal);

    const search = () => {
        resetOffset();
        dispatch(
            establishOrganizationUsersModalThunk(
                getAccessTokenSilently,
                logout,
                INFINITE_SCROLL_THRESHOLD,
                0,
                {
                    search: filter,
                }
            )
        );
        incrementOffset();
    };

    const handleClick = (e) => {
        if (e.keyCode === KEYBOARD_KEYS.enter) {
            search();
        }
    };

    const loadMore = () => {
        dispatch(
            loadMoreOrganizationUsersModalThunk(
                getAccessTokenSilently,
                logout,
                INFINITE_SCROLL_THRESHOLD,
                offset,
                {
                    search: filter,
                }
            )
        );
        incrementOffset();
    };

    const toggleModal = () => {
        dispatch(resetOrganizationUsersModal());
        toggleVisibility();
    };

    useEffect(() => {
        search();
        return () => {
            dispatch(resetOrganizationUsersModal());
        };
    }, []);

    return (
        <Modal
            closeModal={toggleModal}
            headerText="Manage User IDs"
            modalFooter={() => (
                <Button
                    primary
                    onClick={toggleModal}
                    filter={filter}
                    setFilter={setFilter}
                >
                    Close
                </Button>
            )}
        >
            <ManageUsersIdsModal
                users={organizationUsers}
                totalCount={amountOfAllUsers}
                filter={filter}
                setFilter={setFilter}
                search={search}
                handleClick={handleClick}
                loadMore={loadMore}
                hasMore={
                    hasMoreOrganizationUsers && !areOrganizationUsersFetching
                }
            />
        </Modal>
    );
};

ManageUsersIdsModalComponent.propTypes = {
    toggleVisibility: PropTypes.func.isRequired,
};

export default ManageUsersIdsModalComponent;
