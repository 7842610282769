import {
    establishMetricDetails,
    establishMetricDetailsSuccess,
    establishMetricDetailsFailure,
    resetMetricDetails,
} from 'redux/actions/currentMetricDetails';
import apiRoutes from 'config/apiRoutes';

const establishMetricDetailsThunk = (
    getAccessTokenSilently,
    logout,
    metricSourceId,
    metricId
) => (dispatch) => {
    dispatch(establishMetricDetails());

    return getAccessTokenSilently()
        .then((token) => {
            fetch(apiRoutes.get.metricDetails(metricSourceId, metricId), {
                method: 'GET',
                headers: {
                    organization_slug: window.localStorage.getItem(
                        'MP_ORGANIZATION'
                    ),
                    Authorization: `Token token="${token}"`,
                },
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.error) {
                        return dispatch(
                            establishMetricDetailsFailure(res.error)
                        );
                    }

                    return dispatch(establishMetricDetailsSuccess(res));
                })
                .catch((err) =>
                    dispatch(
                        establishMetricDetailsFailure({
                            err,
                        })
                    )
                );
        })
        .catch(() => logout({ returnTo: window.location.origin }));
};

export const resetMetricDetailsThunk = () => (dispatch) => {
    dispatch(resetMetricDetails());
};

export default establishMetricDetailsThunk;
