import {
    ESTABLISH_NOTIFICATIONS,
    ESTABLISH_NOTIFICATIONS_SUCCESS,
    ESTABLISH_NOTIFICATIONS_FAILURE,
    LOAD_MORE_NOTIFICATIONS,
    ALL_NOTIFICATIONS_LOADED,
    RESET_NOTIFICATIONS,
    MARK_NOTIFICATION_AS_READ,
    SET_UNSEEN_NOTIFICATIONS_COUNT,
    DECREASE_UNSEEN_NOTIFICATIONS_COUNT,
} from 'redux/actions/notifications';

export const initialState = {
    areNotificationsFetching: false,
    hasMoreNotifications: true,
    notifications: [],
    unseenCount: 0,
    isNotificationsError: false,
    error: null,
};

const notifications = (state = initialState, action) => {
    switch (action.type) {
        case ESTABLISH_NOTIFICATIONS:
            return {
                ...state,
                areNotificationsFetching: true,
                isNotificationsError: false,
            };

        case ESTABLISH_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                areNotificationsFetching: false,
                notifications: action.payload.notifications,
                unseenCount: action.payload.unseen_count,
            };

        case ESTABLISH_NOTIFICATIONS_FAILURE:
            return {
                ...state,
                areNotificationsFetching: false,
                isNotificationsError: true,
                error: action.err,
            };

        case LOAD_MORE_NOTIFICATIONS:
            return {
                ...state,
                areNotificationsFetching: false,
                notifications: [...state.notifications, ...action.payload],
            };

        case ALL_NOTIFICATIONS_LOADED:
            return {
                ...state,
                hasMoreNotifications: false,
                areNotificationsFetching: false,
            };

        case RESET_NOTIFICATIONS:
            return initialState;

        case MARK_NOTIFICATION_AS_READ:
            return {
                ...state,
                notifications: state.notifications.map((notification) => {
                    if (notification.id === action.payload) {
                        const updateNotification = notification;
                        updateNotification.seen_at = `${new Date()}`;
                        return updateNotification;
                    }
                    return notification;
                }),
            };

        case SET_UNSEEN_NOTIFICATIONS_COUNT:
            return {
                ...state,
                unseenCount: action.payload,
            };

        case DECREASE_UNSEEN_NOTIFICATIONS_COUNT:
            return {
                ...state,
                unseenCount: state.unseenCount > 0 ? state.unseenCount - 1 : 0,
            };

        default:
            return state;
    }
};

export default notifications;
