import styled, { css } from 'styled-components/macro';

export const StyledModalBody = styled.div`
    width: 377px;
    height: 209px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-evenly;
    align-items: center;
    padding: 24px;

    svg {
        align-self: flex-end;
        margin-bottom: -14px;
        cursor: pointer;
    }

    small {
        color: ${({ theme }) => theme.color.textLight};
        text-align: center;
        width: auto !important;
    }

    ${({ large }) =>
        large &&
        css`
            max-width: 440px;
            width: 100%;
            padding: 32px;
            text-align: center;
            width: auto;
            height: auto;

            h2 {
                font-size: 16px;
            }

            small {
                margin: 16px 0;
                font-size: 12px;
                color: #718fbf;
                line-height: 18px;
            }

            svg {
                display: none;
            }

            button {
                min-width: 160px;
            }
        `}
`;

export const StyledModalButtonGroup = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 24px;

    button {
        width: 157px;
        height: 47px;
    }
`;

export const ModalCustomStyle = css`
    min-width: 377px;
    min-height: 209px;
    display: flex;
    justify-content: center;
    align-items: center;
`;
