import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'styled-components/macro';
import { Link } from 'react-router-dom';
import Search from 'containers/Search';
import theme from 'assets/theme/mainTheme';
import { ReactComponent as FaqIcon } from 'assets/svg/faq.svg';
import NotificationsDropdown from 'containers/NotificationsDropdown';
import { MPA_ROUTES, ROUTES } from 'routing/constants';
import AvatarPlaceholder from 'components/AvatarPlaceholder';
import useMPA from 'hooks/useMPA';
import {
    StyledNavbarResponsive,
    StyledUserData,
    IconWrapper,
} from './NavbarResponsive.style';

const NavbarResponsive = ({ currentUser, redirectToFAQ }) => {
    const { isCurrentUserMPA } = useMPA();

    return (
      <StyledNavbarResponsive>
        {!isCurrentUserMPA && <Search isMobile />}

        <StyledUserData>
          <Link
                    to={
                        isCurrentUserMPA
                            ? MPA_ROUTES.userSettings
                            : ROUTES.userSettings
                    }
                >
            <span className="currentUserName">
              {currentUser.userName}
            </span>
            {currentUser.userAvatarUrl ? (
              <AvatarPlaceholder
                            image={currentUser.userAvatarUrl}
                            customStyles={css`
                                margin-left: 16px;
                            `}
                            width="30px"
                            height="30px"
                        />
                    ) : (
                      <AvatarPlaceholder
                            customStyles={css`
                                margin-left: 16px;
                            `}
                            width="30px"
                            height="30px"
                        >
                        {currentUser.userFirstName &&
                                currentUser.userFirstName[0]}
                        {currentUser.userLastName &&
                                currentUser.userLastName[0]}
                      </AvatarPlaceholder>
                    )}
          </Link>
        </StyledUserData>
        {!isCurrentUserMPA && (
        <IconWrapper onClick={redirectToFAQ} faq>
          <FaqIcon fill={theme.color.textNavigation} />
        </IconWrapper>
            )}
        {!isCurrentUserMPA && (
        <IconWrapper>
          <NotificationsDropdown />
        </IconWrapper>
            )}
      </StyledNavbarResponsive>
    );
};

NavbarResponsive.propTypes = {
    currentUser: PropTypes.shape({
        userFirstName: PropTypes.string,
        userName: PropTypes.string,
        userLastName: PropTypes.string,
        userAvatarUrl: PropTypes.string,
        userLevel: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        userScore: PropTypes.number,
    }).isRequired,
    redirectToFAQ: PropTypes.func.isRequired,
};

export default NavbarResponsive;
