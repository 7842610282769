import styled from 'styled-components/macro';
import Button from 'components/Button';

const StyledSmartTipCTA = styled.div`
    display: flex;
    flex-flow: row nowrap;

    button {
        margin-left: 16px;
    }

    svg {
        margin: unset;
    }
`;

export const StyledIconButton = styled(Button)`
    width: 121px;
    justify-content: center;
    padding: 0px 15px;

    svg {
        margin-left: 0 !important;
        margin-right: 5px;
    }
`;

export default StyledSmartTipCTA;
