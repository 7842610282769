import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/Modal';
import Button from 'components/Button';
import { ReactComponent as CloseModalIcon } from 'assets/svg/close-modal.svg';
import {
    StyledModalBody,
    StyledModalButtonGroup,
} from './IncognitoModeError.style';

const IncognitoModeError = ({ isVisible, onToggle, title, description }) => {
    if (!isVisible) {
        return null;
    }

    return (
        <Modal absolute closeModal={onToggle}>
            <StyledModalBody>
                <CloseModalIcon onClick={onToggle} />
                <h2>{title}</h2>
                <small>{description}</small>
                <StyledModalButtonGroup>
                    <Button primary onClick={onToggle}>
                        Acknowledge
                    </Button>
                </StyledModalButtonGroup>
            </StyledModalBody>
        </Modal>
    );
};

IncognitoModeError.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    onToggle: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
};

export default IncognitoModeError;
