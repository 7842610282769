import {
    ESTABLISH_ORGANIZATION_SMART_TIPS,
    ESTABLISH_ORGANIZATION_SMART_TIPS_SUCCESS,
    ESTABLISH_ORGANIZATION_SMART_TIPS_SEARCH_SUCCESS,
    ESTABLISH_ORGANIZATION_SMART_TIPS_FAILURE,
    LOAD_MORE_ORGANIZATION_SMART_TIPS,
    MARK_ALL_ORGANIZATION_SMART_TIPS_LOADED,
    RESET_ORGANIZATION_SMART_TIPS,
    UPDATE_ORGANIZATION_SMART_TIP_SUCCESS,
    UPDATE_ORGANIZATION_SMART_TIP_FAILURE,
    SEARCH_USER_SMART_TIPS,
    SEARCH_USER_SMART_TIPS_SUCCESS,
    SEARCH_USER_SMART_TIPS_FAILURE,
    ASSIGN_ORGANIZATION_SMART_TIP,
    ASSIGN_ORGANIZATION_SMART_TIP_SUCCESS,
    ASSIGN_ORGANIZATION_SMART_TIP_FAILURE,
} from 'redux/actions/organizationSmartTips';

export const initialState = {
    areOrganizationSmartTipsEstablished: false,
    isOrganizationSmartTipsError: false,
    areOrganizationSmartTipsFetching: false,
    organizationSmartTips: [],
    error: null,
    hasMoreOrganizationSmartTips: true,

    areUserSmartTipsFetching: false,
    areUserSmartTipsEstablished: false,
    isUserSmartTipsAssignmentPending: false,
    userSmartTipsSearchList: [],
};

const organizationSmartTips = (state = initialState, action) => {
    switch (action.type) {
        case ESTABLISH_ORGANIZATION_SMART_TIPS:
            return {
                ...state,
                areOrganizationSmartTipsFetching: true,
                isOrganizationSmartTipsError: false,
            };

        case ESTABLISH_ORGANIZATION_SMART_TIPS_SEARCH_SUCCESS:
            return {
                ...state,
                areOrganizationSmartTipsFetching: false,
                areOrganizationSmartTipsEstablished: true,
                organizationSmartTips:
                    action.payload === undefined
                        ? undefined
                        : [...action.payload],
            };
        case ESTABLISH_ORGANIZATION_SMART_TIPS_SUCCESS:
            if (action.payload && action.payload.length === 0) {
                return {
                    ...state,
                    hasMoreOrganizationSmartTips: false,
                    areOrganizationSmartTipsFetching: false,
                    areOrganizationSmartTipsEstablished: true,
                };
            }

            return {
                ...state,
                areOrganizationSmartTipsFetching: false,
                areOrganizationSmartTipsEstablished: true,
                hasMoreOrganizationSmartTips: true,
                organizationSmartTips:
                    action.payload === undefined
                        ? undefined
                        : [
                              ...state.organizationSmartTips,
                              ...action.payload.filter(
                                  (st) =>
                                      !state.organizationSmartTips.find(
                                          (s) => s.id === st.id
                                      )
                              ),
                          ],
            };

        case UPDATE_ORGANIZATION_SMART_TIP_FAILURE:
        case ESTABLISH_ORGANIZATION_SMART_TIPS_FAILURE:
            return {
                ...state,
                areOrganizationSmartTipsFetching: false,
                isOrganizationSmartTipsError: true,
                error: action.err,
            };

        case LOAD_MORE_ORGANIZATION_SMART_TIPS:
            return {
                ...state,
                areOrganizationSmartTipsFetching: false,
                areOrganizationSmartTipsEstablished: true,
                organizationSmartTips: [
                    ...state.organizationSmartTips,
                    ...action.payload,
                ],
            };

        case MARK_ALL_ORGANIZATION_SMART_TIPS_LOADED:
            return {
                ...state,
                hasMoreOrganizationSmartTips: false,
                areOrganizationSmartTipsFetching: false,
                areOrganizationSmartTipsEstablished: true,
            };

        case UPDATE_ORGANIZATION_SMART_TIP_SUCCESS:
            return {
                ...state,
                organizationSmartTips: state.organizationSmartTips.map(
                    (smartTip) => {
                        if (smartTip.id === action.payload.id) {
                            return action.payload;
                        }

                        return smartTip;
                    }
                ),
            };

        case RESET_ORGANIZATION_SMART_TIPS:
            return initialState;

        case SEARCH_USER_SMART_TIPS:
            return {
                ...state,
                areUserSmartTipsFetching: true,
                isUserSmartTipsError: false,
            };

        case SEARCH_USER_SMART_TIPS_SUCCESS:
            return {
                ...state,
                areUserSmartTipsFetching: false,
                areUserSmartTipsEstablished: true,
                userSmartTipsSearchList: action.payload,
            };

        case SEARCH_USER_SMART_TIPS_FAILURE:
            return {
                ...state,
                areUserSmartTipsFetching: false,
                isUserSmartTipsError: true,
                error: action.err,
            };

        case ASSIGN_ORGANIZATION_SMART_TIP:
            return {
                ...state,
                isUserSmartTipsAssignmentPending: true,
            };

        case ASSIGN_ORGANIZATION_SMART_TIP_SUCCESS:
            return {
                ...state,
                isUserSmartTipsAssignmentPending: false,
            };

        case ASSIGN_ORGANIZATION_SMART_TIP_FAILURE:
            return {
                ...state,
                error: action.err,
            };

        default:
            return state;
    }
};

export default organizationSmartTips;
