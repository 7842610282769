import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Input from 'components/Input';
import {
    DropdownOption,
    DropdownOptionTexts,
} from 'components/CreateTeamForm/CreateTeamForm.style';
import theme from 'assets/theme/mainTheme';
import { ReactComponent as LightBulbIcon } from 'assets/svg/light-bulb-shine.svg';
import { ReactComponent as CloseIcon } from 'assets/svg/close.svg';
import { UnlinkSmartTipButton } from './EditMetricModal.style';

const generateSmartTipsSelectOptions = (smartTips) =>
    smartTips.map((smartTip) => ({
        id: smartTip.id,
        data: { ...smartTip },
        label() {
            return (
              <DropdownOption>
                <LightBulbIcon fill={theme.color.primary} />
                <DropdownOptionTexts>
                  <span>{smartTip.name}</span>
                </DropdownOptionTexts>
              </DropdownOption>
            );
        },
    }));

const LinkSmartTipInput = ({
    list,
    setSubmitted,
    unlink,
    isAnyLinked,
    isFetching,
    loadMore,
    hasMore,
    filterFunc,
    searchFilterValue,
    resetList,
}) => {
    // Smart Tips related state items
    const [showSmartTipDropdown, setShowSmartTipDropdown] = useState(false);
    const [smartTipsToSelect, setSmartTipsToSelect] = useState([]);

    const handleSelect = (option) => {
        filterFunc(option.data.name);
        setSubmitted(option.id);
        setShowSmartTipDropdown(false);
        resetList();
    };

    useEffect(() => {
        if (list) {
            setSmartTipsToSelect(generateSmartTipsSelectOptions(list));
        }
    }, [list]);

    return (
      <>
        <Input
                variant="searchDropdownApi"
                placeholder="Search Smart Tip"
                selectButtonLabel="Link"
                onClick={() => {
                    setShowSmartTipDropdown(!showSmartTipDropdown);
                }}
                onChange={(e) => {
                    filterFunc(e.target.value);
                    if (e.target.value.length > 0) {
                        setShowSmartTipDropdown(true);
                    }
                }}
                value={searchFilterValue}
                dropdownVisible={showSmartTipDropdown}
                options={smartTipsToSelect}
                handleSelect={handleSelect}
                loadMore={loadMore}
                hasMore={hasMore}
                isLoadingOptions={isFetching}
            />
        {isAnyLinked && (
        <UnlinkSmartTipButton>
          <CloseIcon fill={theme.color.textBase} onClick={unlink} />
        </UnlinkSmartTipButton>
            )}
      </>
    );
};

LinkSmartTipInput.propTypes = {
    list: PropTypes.arrayOf(PropTypes.any).isRequired,
    setSubmitted: PropTypes.func.isRequired,
    unlink: PropTypes.func.isRequired,
    isAnyLinked: PropTypes.bool.isRequired,
    isFetching: PropTypes.bool.isRequired,
    loadMore: PropTypes.func.isRequired,
    hasMore: PropTypes.bool.isRequired,
    filterFunc: PropTypes.func.isRequired,
    searchFilterValue: PropTypes.string.isRequired,
    resetList: PropTypes.func.isRequired,
};

export default LinkSmartTipInput;
