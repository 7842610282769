import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as HeartIcon } from 'assets/svg/heart.svg';
import UploadDropzone from 'components/UploadDropzone';
import {
    DropzoneArea,
    StyledUploadIcon,
    UploadImageArea,
    PictureArea,
} from './UploadImage.style';

const UploadImage = ({ handleUpload, defaultFile }) => {
    const [uploadedFile, setUploadedFile] = useState(null);
    const [uploadedFileUrl, setUploadedFileUrl] = useState(defaultFile);

    useEffect(() => {
        const reader = new FileReader();
        reader.onload = () => {
            setUploadedFileUrl(reader.result);
        };
        if (uploadedFile instanceof Blob) {
            reader.readAsDataURL(uploadedFile);
        }
    }, [uploadedFile]);

    return (
      <UploadImageArea data-testid="UploadImage">
        {uploadedFileUrl ? (
          <PictureArea src={uploadedFileUrl} alt="Uploaded file" />
            ) : (
              <>
                <DropzoneArea>
                  <HeartIcon width="37px" height="33px" />
                </DropzoneArea>
                <StyledUploadIcon />
              </>
            )}

        <UploadDropzone
                handleUpload={handleUpload}
                setUploadedFile={setUploadedFile}
                accept="image/png, image/jpeg"
            />
      </UploadImageArea>
    );
};

UploadImage.propTypes = {
    handleUpload: PropTypes.func.isRequired,
    defaultFile: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.objectOf(PropTypes.any),
    ]),
};

UploadImage.defaultProps = { defaultFile: null };

export default UploadImage;
