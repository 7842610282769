import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ArrowDown } from 'assets/svg/dropdown-down.svg';
import { ReactComponent as ArrowUp } from 'assets/svg/dropdown-up.svg';
import theme from 'assets/theme/mainTheme';
import {
    AccordionContainer,
    AccordionLabel,
    AccordionLabelContent,
    AccordionStatusIconHolder,
    AccordionContent,
    SecondLabel,
} from './Accordion.style';

const Accordion = ({
    label,
    secondLabel,
    labelCta,
    rightSideCta,
    customStyles,
    customOnClick,
    children,
    initiallyOpen,
    expandOnlyOnArrowClick,
    disabledSource,
}) => {
    const [isDisabled, setIsDisabled] = useState(false);
    const [isAccordionOpen, toggleAccordion] = useState(initiallyOpen);

    useEffect(() => {
        if (disabledSource) {
            setIsDisabled(true);
            toggleAccordion(false);
        }
    }, [disabledSource]);

    return (
      <AccordionContainer
            customStyles={customStyles}
            isAccordionOpen={isAccordionOpen}
        >
        <AccordionLabel
                expandOnlyOnArrowClick={expandOnlyOnArrowClick}
                expanded={isAccordionOpen}
                className="accordionButton"
                onClick={(customProps) => {
                    if (isDisabled) {
                        return;
                    }
                    if (!expandOnlyOnArrowClick) {
                        toggleAccordion(!isAccordionOpen);
                        customOnClick(customProps);
                    }
                }}
            >
          <AccordionLabelContent
                    className="accordionLabelContent"
                    threeColumns={secondLabel !== undefined}
                >
            <strong>{label}</strong>
            {secondLabel && <SecondLabel>{secondLabel()}</SecondLabel>}
            {labelCta && labelCta()}
          </AccordionLabelContent>
          {!isDisabled ? (
            <AccordionStatusIconHolder
                        className="accordionIconHolder"
                        onClick={(customProps) => {
                            if (isDisabled) {
                                return;
                            }
                            if (expandOnlyOnArrowClick) {
                                toggleAccordion(!isAccordionOpen);
                                customOnClick(customProps);
                            }
                        }}
                    >
              {isAccordionOpen ? (
                <ArrowUp fill={theme.color.primary} />
                        ) : (
                          <ArrowDown fill={theme.color.primary} />
                        )}
            </AccordionStatusIconHolder>
                ) : (
                  <div style={{ width: '33px', height: '33px' }} />
                )}

          {rightSideCta && rightSideCta()}
        </AccordionLabel>
        <AccordionContent expanded={isAccordionOpen}>
          {children}
        </AccordionContent>
      </AccordionContainer>
    );
};

Accordion.propTypes = {
    label: PropTypes.string.isRequired,
    secondLabel: PropTypes.func,
    labelCta: PropTypes.func,
    rightSideCta: PropTypes.func,
    customOnClick: PropTypes.func,
    customStyles: PropTypes.oneOfType([PropTypes.func, PropTypes.any]),
    children: PropTypes.node.isRequired,
    initiallyOpen: PropTypes.bool,
    disabledSource: PropTypes.bool,
    expandOnlyOnArrowClick: PropTypes.bool,
};

Accordion.defaultProps = {
    secondLabel: undefined,
    labelCta: () => {},
    rightSideCta: () => {},
    customOnClick: () => {},
    customStyles: undefined,
    initiallyOpen: false,
    disabledSource: false,
    expandOnlyOnArrowClick: false,
};

export default Accordion;
