import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import useOffset from 'hooks/useOffset';
import {
    establishUsersUploadsThunk,
    loadMoreUsersUploadsThunk,
    resetUsersUploadsThunk,
} from 'redux/actions/thunks/usersUploads';
import UsersUploadItem, {
    UsersUploadsTableHeader,
} from 'components/UsersUploadItem/UsersUploadItem';
import Spinner from 'components/Spinner';
import InfiniteScroll from 'react-infinite-scroller';
import SectionHeader from 'components/SectionHeader';
import Button from 'components/Button';
import downloadBlob from 'redux/helpers/downloadBlob';
import { useAuth0 } from '@auth0/auth0-react';
import useFetch from 'hooks/useFetch';
import apiRoutes from 'config/apiRoutes';
import { dispatchErrorAlertThunk } from 'redux/actions/thunks/systemAlerts';
import { UsersWrapper } from 'views/UsersHA/UsersHA.style';

const INFINITE_SCROLL_THRESHOLD = 20;

const CSVUsersImport = ({ isImportModalVisible }) => {
    const { getAccessTokenSilently, logout } = useAuth0();
    const request = useFetch(getAccessTokenSilently, logout);
    const [offset, incrementOffset, resetOffset] = useOffset(
        INFINITE_SCROLL_THRESHOLD
    );
    const [sortedUsersUploads, setSortedUsersUploads] = useState([]);
    const wasFileUrl = useRef(undefined);
    const wasTryAgain = useRef(undefined);

    const dispatch = useDispatch();
    const {
        areUsersUploadsEstablished,
        areUsersUploadsFetching,
        hasMoreUsersUploads,
        usersUploads,
    } = useSelector((state) => state.usersUploads);

    const loadMore = () => {
        dispatch(
            loadMoreUsersUploadsThunk(
                getAccessTokenSilently,
                logout,
                INFINITE_SCROLL_THRESHOLD,
                offset
            )
        );
        incrementOffset();
    };

    const reloadList = () => {
        setSortedUsersUploads([]);
        dispatch(
            establishUsersUploadsThunk(
                getAccessTokenSilently,
                logout,
                INFINITE_SCROLL_THRESHOLD,
                0
            )
        );
        resetOffset(INFINITE_SCROLL_THRESHOLD);
    };

    const downloadFile = (errorFileUrl, fileName) => {
        getAccessTokenSilently()
            .then((token) => {
                fetch(errorFileUrl, {
                    method: 'GET',
                    headers: {
                        organization_slug: window.localStorage.getItem(
                            'MP_ORGANIZATION'
                        ),
                        Authorization: `Token token="${token}"`,
                    },
                })
                    .then((res) => res.blob())
                    .then((res) => downloadBlob(res, `error-${fileName}`));
            })
            .catch(() => logout({ returnTo: window.location.origin }));
    };

    const handleSuccess = () => {
        reloadList();
    };

    const handleFailure = (err) => {
        dispatch(dispatchErrorAlertThunk('errorTryAgain', err.error));
    };

    const tryAgain = (id) => {
        request(
            apiRoutes.put.retryUsersUpload(id),
            'PUT',
            handleSuccess,
            handleFailure,
            {},
            { 'Content-Type': 'application/json' },
            false
        );
    };

    useEffect(() => {
        dispatch(
            establishUsersUploadsThunk(
                getAccessTokenSilently,
                logout,
                INFINITE_SCROLL_THRESHOLD,
                0
            )
        );
        resetOffset(INFINITE_SCROLL_THRESHOLD);

        return dispatch(resetUsersUploadsThunk());
    }, []);

    useEffect(() => {
        if (!isImportModalVisible) {
            dispatch(
                establishUsersUploadsThunk(
                    getAccessTokenSilently,
                    logout,
                    INFINITE_SCROLL_THRESHOLD,
                    0
                )
            );
            resetOffset(INFINITE_SCROLL_THRESHOLD);
        }
    }, [isImportModalVisible]);

    useEffect(() => {
        setSortedUsersUploads(
            usersUploads.sort(
                (a, b) =>
                    new Date(b.created_at).getTime() -
                    new Date(a.created_at).getTime()
            )
        );
    }, [usersUploads]);

    if (!areUsersUploadsEstablished) {
        return <Spinner />;
    }

    return (
      <UsersWrapper>
        <SectionHeader title="Processed CSV User Files">
          <Button primary small onClick={reloadList}>
            Refresh
          </Button>
          {areUsersUploadsFetching && <Spinner small />}
        </SectionHeader>
        <UsersUploadsTableHeader />
        <InfiniteScroll
                initialLoad={false}
                loadMore={loadMore}
                loader={<Spinner key="spinner" />}
                hasMore={hasMoreUsersUploads && !areUsersUploadsFetching}
            >
          {sortedUsersUploads.map((item) => {
                    if (
                        item.errors_file_url &&
                        wasFileUrl.current === undefined
                    ) {
                        wasFileUrl.current = item.errors_file_url;
                    }

                    if (item.try_again && wasTryAgain.current === undefined) {
                        wasTryAgain.current = item.try_again;
                    }

                    return (
                      <UsersUploadItem
                            key={item.id}
                            id={item.id}
                            createdAt={item.created_at}
                            fileName={item.file_name}
                            status={item.status}
                            errorsFileUrl={
                                item.errors_file_url && item.errors_file_url
                            }
                            handleDownloadFile={downloadFile}
                            errorMessage={item.error_message}
                            tryAgain={
                                item.try_again &&
                                item.try_again === wasTryAgain.current
                                    ? wasTryAgain.current
                                    : undefined
                            }
                            handleTryAgain={tryAgain}
                        />
                    );
                })}
          {areUsersUploadsFetching && <Spinner />}
        </InfiniteScroll>
      </UsersWrapper>
    );
};

CSVUsersImport.propTypes = {
    isImportModalVisible: PropTypes.bool.isRequired,
};

export default CSVUsersImport;
