import React from 'react';
import PropTypes from 'prop-types';
import {
    InputWrapper,
    StyledInput,
    CounterWrapper,
} from './InputWithCounter.style';

const InputWithCounterView = ({
    placeholder,
    value,
    onChange,
    invalid,
    characterLimit,
    fixedWidth,
    withMargin,
    onTouch,
}) => (
  <InputWrapper
        onBlur={onTouch}
        invalid={invalid}
        fixedWidth={fixedWidth}
        withMargin={withMargin}
    >
    <StyledInput
            placeholder={placeholder}
            value={value}
            onChange={(e) => {
                if (e.target.value.length <= characterLimit) onChange(e);
            }}
        />
    <CounterWrapper>{`${value.length}/${characterLimit}`}</CounterWrapper>
  </InputWrapper>
);

InputWithCounterView.propTypes = {
    placeholder: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    invalid: PropTypes.bool,
    characterLimit: PropTypes.number.isRequired,
    fixedWidth: PropTypes.bool,
    withMargin: PropTypes.bool,
    onTouch: PropTypes.func,
};

InputWithCounterView.defaultProps = {
    placeholder: '',
    value: undefined,
    invalid: false,
    fixedWidth: false,
    withMargin: false,
    onTouch: () => {},
};

export default InputWithCounterView;
