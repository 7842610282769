import styled from 'styled-components/macro';

const MetricModalHolder = styled.div`
    width: 325px;
    height: 190px;
    margin: 0 32px 32px 0;
    padding: 24px;
    background-color: ${({ theme }) => theme.color.white};
    border-radius: ${({ theme }) => theme.border.radiusBase};
    border: ${({ theme }) => theme.border.base};
`;

export default MetricModalHolder;
