import styled from 'styled-components/macro';
import InputBase from 'components/Input';

export const SuperAdminUsersWrapper = styled.div`
    min-height: calc(100vh - 150px);
`;

export const FiltersWrapper = styled.div`
    padding: 0 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const Input = styled(InputBase)`
    max-width: 180px;
    margin-left: 16px;
`;

export const Text = styled.div`
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    color: ${({ theme }) => theme.color.textLight};
`;

export const ListWrapper = styled.div`
    padding: 32px 16px 0px;
`;

export const SpinnerWrapper = styled.div`
    width: 100%;
    height: 784px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const PaginationWrapper = styled.div`
    padding: 0 16px;
`;
