import React from 'react';
import { ReactComponent as CustomersIcon } from 'assets/svg/sidebarIcons/customers.svg';
import { ReactComponent as DataSourcesMeasuresIcon } from 'assets/svg/sidebarIcons/dataSourcesMeasures.svg';
import { ReactComponent as SuperAdminUsersIcon } from 'assets/svg/sidebarIcons/superAdminUsers.svg';
import { ReactComponent as AdminMemosIcon } from 'assets/svg/sidebarIcons/adminMemos.svg';

import theme from 'assets/theme/mainTheme';
import { MPA_ROUTES } from 'routing/constants';

const sidebarMPAMenu = [
    {
        id: 0,
        title: 'Organizations',
        url: MPA_ROUTES.customers,
        highlight: [
            MPA_ROUTES.customers,
            MPA_ROUTES.editCustomer,
            MPA_ROUTES.addCustomer,
        ],
        icon(isIconActive) {
            return (
              <CustomersIcon
                    fill={
                        isIconActive
                            ? theme.color.mpaItemText
                            : theme.color.white
                    }
                />
            );
        },
    },
    {
        id: 1,
        title: 'Data Sources / Metrics',
        url: MPA_ROUTES.dataSourcesMeasures,
        highlight: [MPA_ROUTES.dataSourcesMeasures],
        icon(isIconActive) {
            return (
              <DataSourcesMeasuresIcon
                    fill={
                        isIconActive
                            ? theme.color.mpaItemText
                            : theme.color.white
                    }
                />
            );
        },
    },
    {
        id: 2,
        title: 'Super Admin Users',
        url: MPA_ROUTES.superAdminUsers,
        highlight: [
            MPA_ROUTES.superAdminUsers,
            MPA_ROUTES.editSuperAdminUser,
            MPA_ROUTES.addSuperAdminUser,
        ],
        icon(isIconActive) {
            return (
              <SuperAdminUsersIcon
                    fill={
                        isIconActive
                            ? theme.color.mpaItemText
                            : theme.color.white
                    }
                />
            );
        },
    },
    {
        id: 3,
        title: 'Admin Memos',
        url: MPA_ROUTES.adminMemos,
        highlight: [MPA_ROUTES.adminMemos],
        icon(isIconActive) {
            return (
              <AdminMemosIcon
                    fill={
                        isIconActive
                            ? theme.color.mpaItemText
                            : theme.color.white
                    }
                />
            );
        },
    },
];

export default sidebarMPAMenu;
