import React from 'react';
import PropTypes from 'prop-types';
import { SwitchHolder, SwitchBox, SwitchCase } from './SwitchButtons.style';

const SwitchButtons = ({
    confirmLabel,
    rejectLabel,
    isConfirmed,
    handleUpdate,
}) => (
  <SwitchHolder>
    <SwitchCase
            selected={isConfirmed}
            onClick={() => handleUpdate('confirm')}
        >
      <SwitchBox /> 
      {' '}
      {confirmLabel}
    </SwitchCase>
    <SwitchCase
            selected={!isConfirmed}
            onClick={() => handleUpdate('reject')}
        >
      <SwitchBox /> 
      {' '}
      {rejectLabel}
    </SwitchCase>
  </SwitchHolder>
);

SwitchButtons.propTypes = {
    confirmLabel: PropTypes.string,
    rejectLabel: PropTypes.string,
    isConfirmed: PropTypes.bool.isRequired,
    handleUpdate: PropTypes.func.isRequired,
};

SwitchButtons.defaultProps = {
    confirmLabel: 'On',
    rejectLabel: 'Off',
};

export default SwitchButtons;
