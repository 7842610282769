import styled, { css } from 'styled-components/macro';

export const DropdownOption = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 8px;
    min-height: 48px;
    position: relative;
`;

export const DropdownAvatar = styled.div`
    img,
    svg {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        margin-right: 8px;
        object-fit: cover;
    }
`;

export const DropdownOptionTexts = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    span {
        overflow: hidden;
        max-width: 200px;
        text-overflow: ellipsis;
    }

    small {
        overflow: hidden;
        max-width: 200px;
        text-overflow: ellipsis;
        color: ${({ theme }) => theme.color.textLight};
    }
`;

export const EmptyAvatar = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px;
    margin-right: 8px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    text-align: center;
    vertical-align: middle;
    line-height: 32px;
    font-size: 12px;
    font-weight: 600;
    color: ${({ theme }) => theme.color.primary};
    background-color: ${({ theme }) => theme.color.bgPrimaryLight};

    ${({ color }) =>
        color &&
        css`
            color: ${color};
        `}

    ${({ backgroundColor }) =>
        backgroundColor &&
        css`
            background-color: ${backgroundColor};
        `}
`;
