import styled, { css } from 'styled-components/macro';
import { ReactComponent as Icon } from './placeholder-icon.svg';

export const StyledPerformanceDashboard = styled.div`
    min-height: 400px;
    width: 100%;
    position: relative;
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 30px;

    border: none;
    border-radius: 0;
    background-color: initial;
    padding: 0;

    @media (max-width: ${({ theme }) => `${theme.breakpoints.web}px`}) {
        grid-template-columns: 2fr 1fr;
    }

    ${({ twoThird }) =>
        twoThird &&
        css`
            grid-template-columns: 2fr 1fr;
        `}

    @media (max-width: ${({ theme }) =>
        `${theme.breakpoints.mobile + 300}px`}) {
        grid-template-columns: 1fr;
    }
`;

export const StyledChartWrapper = styled.div`
    width: 100%;
    height: auto;
    border-radius: ${({ theme }) => theme.border.radiusBase};
    border: ${({ theme }) => theme.border.base};
    background-color: ${({ theme }) => theme.color.white};

    position: relative;
    flex-flow: column nowrap;
    display: flex;

    @media (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
        width: calc(100vw - 48px);
        max-width: calc(100vw - 48px);
        height: auto;
    }
`;

export const StyledColumn = styled.div`
    padding: 16px 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: ${({ theme }) => theme.border.radiusBase};
    border: ${({ theme }) => theme.border.base};
    background-color: ${({ theme }) => theme.color.white};

    & + & {
        border-left: ${({ theme }) => theme.border.base};
    }

    & > strong,
    & > span {
        margin: 0 20% 24px;
    }

    strong {
        font-weight: 600;
    }

    span {
        font-weight: 400;
        font-size: ${({ theme }) => theme.fontSize.small};
        color: ${({ theme }) => theme.color.textLight};
    }

    @media (max-width: ${({ theme }) => `${theme.breakpoints.mobile}px`}) {
        width: 100%;
        margin-bottom: 30px;
    }
`;

export const Placeholder = styled.div`
    background: rgb(47, 155, 255);
    background: radial-gradient(
        circle,
        rgba(47, 155, 255, 1) 0%,
        rgba(55, 127, 255, 1) 75%
    );
    background-position-x: 20%;
    border-radius: 16px;
    position: relative;
    padding: 24px;
`;

export const PlaceholderText = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 50%;
    z-index: 2;

    h3 {
        font-style: normal;
        font-weight: 600;
        font-size: 23px;
        line-height: 28px;
        color: #ffffff;
        margin-bottom: 24px;
    }

    P {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #fbfcfe;
        opacity: 0.7;
        margin-bottom: 16px;
    }

    button {
        margin-top: auto;
        padding: 16px 24px;
    }

    @media (max-width: ${({ theme }) => `${theme.breakpoints.medium}px`}) {
        max-width: 350px;
    }

    @media (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
        max-width: 100%;

        button {
            width: 100%;
        }
    }
`;

export const StyledIcon = styled(Icon)`
    position: absolute;
    right: 24px;
    bottom: 24px;
    z-index: 1;

    @media (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
        display: none;
    }
`;
