import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { css } from 'styled-components/macro';
import { Link } from 'react-router-dom';
import Search from 'containers/Search';
import theme from 'assets/theme/mainTheme';
import useAdmin from 'hooks/useAdmin';
import { ReactComponent as StarIcon } from 'assets/svg/star.svg';
import { ReactComponent as FaqIcon } from 'assets/svg/faq.svg';
import Badge from 'components/Badge';
import AuthButton from 'components/AuthButton';
import NotificationsDropdown from 'containers/NotificationsDropdown';
import { MPA_ROUTES, ROUTES } from 'routing/constants';
import AvatarPlaceholder from 'components/AvatarPlaceholder';
import { useSelector } from 'react-redux';
import useMPA from 'hooks/useMPA';
import {
    StyledNavbar,
    StyledLevelBadge,
    StyledScoreBadge,
    StyledUserData,
    IconWrapper,
} from './Navbar.style';

const Navbar = ({ currentUser, redirectToFAQ }) => {
    const [avatarUrl, setAvatarUrl] = useState(null);
    const isAdmin = useAdmin();
    const { isCurrentUserMPA } = useMPA();
    const { userFirstName, userLastName, userAvatarUrl } = useSelector(
        (state) => state.currentUser
    );

    useEffect(() => {
        setAvatarUrl(userAvatarUrl);
    }, [userAvatarUrl]);

    return (
      <StyledNavbar>
        {!isCurrentUserMPA && <Search />}

        {isAdmin ? (
          <Badge quaternary>Admin</Badge>
            ) : (
              <>
                <StyledScoreBadge>
                  {currentUser.userScore}
                  <StarIcon fill={theme.color.secondary} />
                </StyledScoreBadge>
                <StyledLevelBadge>{currentUser.userLevel}</StyledLevelBadge>
              </>
            )}

        <StyledUserData>
          <Link
                    to={
                        isCurrentUserMPA
                            ? MPA_ROUTES.userSettings
                            : ROUTES.userSettings
                    }
                >
            {currentUser.userName}
            {avatarUrl ? (
              <AvatarPlaceholder
                            image={avatarUrl}
                            customStyles={css`
                                margin-left: 16px;
                            `}
                            width="30px"
                            height="30px"
                        />
                    ) : (
                      <AvatarPlaceholder
                            customStyles={css`
                                margin-left: 16px;
                            `}
                            width="30px"
                            height="30px"
                        >
                        {userFirstName && userFirstName[0]}
                        {userLastName && userLastName[0]}
                      </AvatarPlaceholder>
                    )}
          </Link>
        </StyledUserData>
        {!isCurrentUserMPA && (
        <IconWrapper>
          <NotificationsDropdown />
        </IconWrapper>
            )}

        {!isCurrentUserMPA && (
        <IconWrapper onClick={redirectToFAQ} faq>
          <FaqIcon fill={theme.color.textNavigation} />
        </IconWrapper>
            )}

        <div style={{ marginLeft: '20px' }}>
          <AuthButton inline />
        </div>
      </StyledNavbar>
    );
};

Navbar.propTypes = {
    currentUser: PropTypes.shape({
        userFirstName: PropTypes.string,
        userName: PropTypes.string,
        userLastName: PropTypes.string,
        userAvatarUrl: PropTypes.string,
        userLevel: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        userScore: PropTypes.number,
    }).isRequired,
    redirectToFAQ: PropTypes.func.isRequired,
};

export default Navbar;
