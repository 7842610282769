import styled, { css } from 'styled-components/macro';

export const CTAWrapper = styled.div`
    display: flex;
    flex-direction: row;

    button {
        margin-left: 8px;
    }
`;

export const DropdownWrapper = styled.div`
    position: relative;
`;

export const Dropdown = styled.div`
    z-index: 1;
    display: grid;
    grid-template-rows: 1fr;
    grid-gap: 8px;
    padding: 16px;
    width: 180px;
    position: absolute;
    border-radius: ${({ theme }) => theme.border.radiusBase};
    background-color: ${({ theme }) => theme.color.white};
    box-shadow: ${({ theme }) => theme.boxShadow.base};
    top: 49px;
    right: 0px;
`;

export const DropdownItem = styled.div`
    padding: 16px;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    color: ${({ theme }) => theme.color.primary};
    border-radius: ${({ theme }) => theme.border.radiusSmall};
    z-index: 1;

    ${({ theme, secondary }) =>
        secondary &&
        css`
            color: ${theme.color.red};
        `}

    &:hover {
        cursor: pointer;
        background-color: ${({ theme }) => theme.color.bgPrimaryLight};

        ${({ theme, secondary }) =>
            secondary &&
            css`
                background-color: ${theme.color.pinkLight};
            `}
    }
`;
