import {
    ESTABLISH_ADMIN_MEMOS,
    ESTABLISH_ADMIN_MEMOS_SUCCESS,
    ESTABLISH_ADMIN_MEMOS_FAILURE,
    LOAD_MORE_ADMIN_MEMOS,
    ALL_ADMIN_MEMOS_LOADED,
    RESET_ADMIN_MEMOS,
} from 'redux/actions/adminMemos';

export const initialState = {
    areAdminMemosFetching: false,
    hasMoreAdminMemos: true,
    adminMemos: [],
    isAdminMemosError: false,
    error: null,
};

const adminMemos = (state = initialState, action) => {
    switch (action.type) {
        case ESTABLISH_ADMIN_MEMOS:
            return {
                ...state,
                areAdminMemosFetching: true,
                isAdminMemosError: false,
            };

        case ESTABLISH_ADMIN_MEMOS_SUCCESS:
            return {
                ...state,
                areAdminMemosFetching: false,
                adminMemos: action.payload,
            };

        case ESTABLISH_ADMIN_MEMOS_FAILURE:
            return {
                ...state,
                areAdminMemosFetching: false,
                isAdminMemosError: true,
                error: action.err,
            };

        case LOAD_MORE_ADMIN_MEMOS:
            return {
                ...state,
                areAdminMemosFetching: false,
                adminMemos: [...state.adminMemos, ...action.payload],
            };

        case ALL_ADMIN_MEMOS_LOADED:
            return {
                ...state,
                hasMoreAdminMemos: false,
                areAdminMemosFetching: false,
            };

        case RESET_ADMIN_MEMOS:
            return initialState;

        default:
            return state;
    }
};

export default adminMemos;
