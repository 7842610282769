import React from 'react';
import { ReactComponent as RecentSTIcon } from 'assets/svg/no-results/recent-st.svg';

import {
    EmptyStateWrapper,
    Title,
    Text,
} from './RecentlyVisitedSTEmptyState.style';

const RecentlyVisitedSTEmptyState = () => (
  <EmptyStateWrapper>
    <RecentSTIcon />
    <Title>Nothing in here</Title>
    <Text>You have not displayed any Smart Tips yet.</Text>
  </EmptyStateWrapper>
);

export default RecentlyVisitedSTEmptyState;
