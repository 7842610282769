export const ESTABLISH_SMART_TIPS = 'ESTABLISH_SMART_TIPS';
export const ESTABLISH_SMART_TIPS_SUCCESS = 'ESTABLISH_SMART_TIPS_SUCCESS';
export const ESTABLISH_SMART_TIPS_ESSENTIAL_SUCCESS =
    'ESTABLISH_SMART_TIPS_ESSENTIAL_SUCCESS';
export const ESTABLISH_SMART_TIPS_TOTW_SUCCESS =
    'ESTABLISH_SMART_TIPS_TOTW_SUCCESS';
export const ESTABLISH_SMART_TIPS_ARCHIVED_SUCCESS =
    'ESTABLISH_SMART_TIPS_ARCHIVED_SUCCESS';
export const ESTABLISH_SMART_TIPS_ARCHIVED_ESSENTIAL_SUCCESS =
    'ESTABLISH_SMART_TIPS_ARCHIVED_ESSENTIAL_SUCCESS';
export const ESTABLISH_SMART_TIPS_FAILURE = 'ESTABLISH_SMART_TIPS_FAILURE';

export const FETCH_RECENT_SMART_TIPS = 'FETCH_RECENT_SMART_TIPS';
export const FETCH_RECENT_SMART_TIPS_SUCCESS =
    'FETCH_RECENT_SMART_TIPS_SUCCESS';
export const FETCH_RECENT_SMART_TIPS_FAILURE =
    'FETCH_RECENT_SMART_TIPS_FAILURE';

export const RESET_SMART_TIPS = 'RESET_SMART_TIPS';

export const establishSmartTips = () => ({
    type: ESTABLISH_SMART_TIPS,
});

export const establishSmartTipsSuccess = (payload) => ({
    type: ESTABLISH_SMART_TIPS_SUCCESS,
    payload,
});

export const establishSmartTipsFailure = (err) => ({
    type: ESTABLISH_SMART_TIPS_FAILURE,
    err,
});

export const fetchRecentSmartTips = () => ({
    type: FETCH_RECENT_SMART_TIPS,
});

export const fetchRecentSmartTipsSuccess = (payload) => ({
    type: FETCH_RECENT_SMART_TIPS_SUCCESS,
    payload,
});

export const establishSmartTipsEssentialSuccess = (payload) => ({
    type: ESTABLISH_SMART_TIPS_ESSENTIAL_SUCCESS,
    payload,
});

export const establishSmartTipsToTWSuccess = (payload) => ({
    type: ESTABLISH_SMART_TIPS_TOTW_SUCCESS,
    payload,
});

export const establishSmartTipsArchivedSuccess = (payload) => ({
    type: ESTABLISH_SMART_TIPS_ARCHIVED_SUCCESS,
    payload,
});

export const establishSmartTipsArchivedEssentialSuccess = (payload) => ({
    type: ESTABLISH_SMART_TIPS_ARCHIVED_ESSENTIAL_SUCCESS,
    payload,
});

export const fetchRecentSmartTipsFailure = (err) => ({
    type: FETCH_RECENT_SMART_TIPS_FAILURE,
    err,
});

export const resetSmartTips = () => ({
    type: RESET_SMART_TIPS,
});
