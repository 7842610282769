import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { updateMetricThunk } from 'redux/actions/thunks/metrics';
import SingleMetricComponent from 'components/SingleMetric';
import { useAuth0 } from '@auth0/auth0-react';

const SingleMetric = ({
    id,
    name,
    enabled,
    goal,
    result,
    metricSourceId,
    withoutBorder,
    reloadList,
}) => {
    const dispatch = useDispatch();
    const { getAccessTokenSilently, logout } = useAuth0();

    const updateMetric = (values) => {
        dispatch(
            updateMetricThunk(
                getAccessTokenSilently,
                logout,
                metricSourceId,
                id,
                values,
                reloadList
            )
        );
    };

    return (
      <SingleMetricComponent
            {...{ id, name, goal, result, enabled }}
            onUpdate={updateMetric}
            metricSourceId={metricSourceId}
            withoutBorder={withoutBorder}
        />
    );
};

SingleMetric.propTypes = {
    id: PropTypes.string.isRequired,
    metricSourceId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    enabled: PropTypes.bool.isRequired,
    goal: PropTypes.number.isRequired,
    result: PropTypes.number,
    withoutBorder: PropTypes.bool,
    reloadList: PropTypes.func.isRequired,
};

SingleMetric.defaultProps = { result: 0, withoutBorder: false };

export default SingleMetric;
