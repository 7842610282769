import {
    ESTABLISH_TEAM_MEMBERS,
    ESTABLISH_TEAM_MEMBERS_SUCCESS,
    ESTABLISH_TEAM_MEMBERS_FAILURE,
    RESET_TEAM_MEMBERS,
    REMOVE_TEAM_MEMBER,
    REMOVE_TEAM_MEMBER_SUCCESS,
    REMOVE_TEAM_MEMBER_FAILURE,
    LEAVE_TEAM,
    LEAVE_TEAM_SUCCESS,
    LEAVE_TEAM_FAILURE,
} from 'redux/actions/teamMembers';

export const initialState = {
    areTeamMembersEstablished: false,
    isTeamMembersError: false,
    areTeamMembersFetching: false,
    teamMembers: [],
    error: null,
};

const teamMembers = (state = initialState, action) => {
    switch (action.type) {
        case ESTABLISH_TEAM_MEMBERS:
            return {
                ...state,
                areTeamMembersFetching: true,
                isTeamMembersError: false,
            };

        case ESTABLISH_TEAM_MEMBERS_SUCCESS:
            return {
                ...state,
                areTeamMembersFetching: false,
                areTeamMembersEstablished: true,
                teamMembers: action.payload,
            };

        case ESTABLISH_TEAM_MEMBERS_FAILURE:
            return {
                ...state,
                areTeamMembersFetching: false,
                isTeamMembersError: true,
                error: action.err,
            };

        case REMOVE_TEAM_MEMBER:
            return state;

        case REMOVE_TEAM_MEMBER_SUCCESS:
            return state;

        case REMOVE_TEAM_MEMBER_FAILURE:
            return {
                ...state,
                error: action.err,
            };

        case LEAVE_TEAM:
            return state;

        case LEAVE_TEAM_SUCCESS:
            return state;

        case LEAVE_TEAM_FAILURE:
            return {
                ...state,
                error: action.err,
            };

        case RESET_TEAM_MEMBERS:
            return initialState;
        default:
            return state;
    }
};

export default teamMembers;
