import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import apiRoutes from 'config/apiRoutes';
import useFetch from 'hooks/useFetch';
import CreateSmartTipView from 'views/CreateSmartTip';
import { ROUTES } from 'routing/constants';
import { useAuth0 } from '@auth0/auth0-react';
import {
    dispatchErrorAlertThunk,
    dispatchSuccessAlertThunk,
} from 'redux/actions/thunks/systemAlerts';
import { useDispatch } from 'react-redux';
import { idize } from '../CreateNewUserHA/CreateNewUserHA';

const statuses = [
    {
        id: 'published',
        title: 'Published',
    },
    {
        id: 'unpublished',
        title: 'Unpublished',
    },
    {
        id: 'review',
        title: 'Review',
    },
];

const CreateSmartTip = () => {
    const { getAccessTokenSilently, logout } = useAuth0();
    const dispatch = useDispatch();
    const [isValid, setIsValid] = useState(false);
    const [status, setStatus] = useState(null); // published / unpublished
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [vimeoId, setVimeoId] = useState('');
    const [assets, setAssets] = useState([]);
    const [asset, setAsset] = useState(null);
    const [classificationTags, setClassificationTags] = useState({
        role_job: [],
        department: [],
    });
    const [searchRestricted, setSearchRestricted] = useState(false);
    const [nameValid, setNameValid] = useState(false);
    const [descriptionValid, setDescriptionValid] = useState(false);
    const [vimeoIdValid, setVimeoIdValid] = useState(false);
    const [assetValid, setAssetValid] = useState(false);
    const [applayAllTags, setApplayAllTags] = useState(false);

    const request = useFetch(getAccessTokenSilently, logout);
    const history = useHistory();

    useEffect(() => {
        setStatus(statuses[1]);
        request(
            apiRoutes.get.organizationAssets,
            'GET',
            (res) => setAssets(res.assets),
            () => {},
            {},
            { 'Content-Type': 'application/json' }
        );
    }, []);

    useEffect(() => {
        if (description && description.length > 245) {
            return setDescriptionValid(false);
        }
        return setDescriptionValid(true);
    }, [description]);

    useEffect(() => {
        if (!name || name.length < 3 || name.length > 70) {
            return setNameValid(false);
        }
        return setNameValid(true);
    }, [name]);

    useEffect(() => {
        if (!vimeoId || vimeoId.toString().length < 6) {
            // basic validation, possibly use regex in future
            return setVimeoIdValid(false);
        }
        return setVimeoIdValid(true);
    }, [vimeoId]);

    useEffect(() => {
        if (!asset) {
            return setAssetValid(false);
        }
        return setAssetValid(true);
    }, [asset]);

    useEffect(() => {
        if (nameValid && vimeoIdValid && assetValid) {
            return setIsValid(true);
        }
        return setIsValid(false);
    }, [nameValid, vimeoIdValid, assetValid]);

    const updateField = (type, value) => {
        switch (type) {
            case 'status':
                setStatus(value);
                break;
            case 'name':
                setName(value);
                break;
            case 'description':
                setDescription(value);
                break;
            case 'vimeoId':
                setVimeoId(value);
                break;
            case 'asset':
                setAsset(value);
                break;
            default:
                break;
        }
    };

    const createOrganizationSmartTip = () => {
        request(
            apiRoutes.post.createOrganizationSmartTip,
            'POST',
            (res) => {
                if (!res.error) {
                    dispatch(
                        dispatchSuccessAlertThunk(
                            'smartTipCreated',
                            'Smart Tip created'
                        )
                    );
                    history.push(ROUTES.smartTips);
                }
            },
            (err) => {
                dispatch(dispatchErrorAlertThunk(err, err.error));
            },
            {
                body: JSON.stringify({
                    smart_tip: {
                        name,
                        description,
                        source_id: vimeoId,
                        status: status.id,
                        asset_id: asset.id,
                        assigned_to_all: applayAllTags,
                        search_restricted: searchRestricted,
                        classification_tag_ids: [
                            ...classificationTags.role_job.map(idize),
                            ...classificationTags.department.map(idize),
                        ],
                    },
                }),
            },
            { 'Content-Type': 'application/json' }
        );
    };

    const handleCancel = () => history.push(ROUTES.smartTips);
    const handleSubmit = () => {
        createOrganizationSmartTip();
    };

    return (
        <CreateSmartTipView
            updateField={updateField}
            handleCancel={handleCancel}
            handleSubmit={handleSubmit}
            formSettings={{
                status: status || '',
                name,
                description,
                vimeoId,
                asset,
                isValid,
            }}
            formValid={{
                name: nameValid,
                description: descriptionValid,
                vimeoId: vimeoIdValid,
                asset: assetValid,
            }}
            formValidationActions={{
                setNameValid,
                setVimeoIdValid,
                setAssetValid,
            }}
            statuses={statuses}
            assets={assets}
            classificationTags={classificationTags}
            setClassificationTags={setClassificationTags}
            classificationTagLocalMode
            setSearchRestricted={setSearchRestricted}
            searchRestricted={searchRestricted}
            applayAllTags={applayAllTags}
            setApplayAllTags={setApplayAllTags}
        />
    );
};

export default CreateSmartTip;
