import styled, { css } from 'styled-components/macro';
import Spinner from 'components/Spinner';

export const AvatarContainer = styled.div`
    position: relative;

    &:hover {
        cursor: pointer;
    }
`;

export const ProfileImgHolder = styled.div`
    width: 134px;
    height: 134px;
    border-radius: 50%;
    margin: 8px;
    box-shadow: 0px 8px 40px rgba(0, 48, 135, 0.12);
    background-color: ${({ theme }) => theme.color.primaryDark};
    overflow: hidden;
    cursor: pointer;

    img {
        width: 134px;
        height: 134px;
    }

    svg {
        width: 32px;
        height: 32px;
        margin-left: calc(50% - 16px);
        margin-top: calc(50% - 16px);
    }
`;

export const HoverableOverlay = styled.div`
    border-radius: 50%;
    position: absolute;
    width: 134px;
    height: 134px;
    background-color: ${({ theme }) => theme.color.primaryDark};
    font-size: ${({ theme }) => theme.fontSize.small};
    transition-duration: 200ms;
    opacity: 0;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;

    svg {
        width: 12px;
        height: 12px;
        margin: 0;
        margin-right: 6px;
    }

    &:hover {
        opacity: 0.8;
    }
`;

export const UploadSuccessIcon = styled.div`
    position: absolute;
    bottom: 7px;
    right: 14px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.color.white};
    background-position: center;
    background-repeat: no-repeat;

    svg {
        width: 32px;
        height: 32px;
    }

    ${({ darkBackground }) =>
        darkBackground &&
        css`
            background-color: ${({ theme }) => theme.color.primaryDark};
        `};
`;

export const UploadMiniSpinner = styled(Spinner)`
    width: 24px;
    height: 24px;
    display: block;
    margin: 0;
    margin-left: calc(50% - 12px);
    margin-top: calc(50% - 12px);
`;
