import styled, { css } from 'styled-components/macro';

export const CustomerWrapper = styled.div`
    margin-bottom: 16px;
    padding: 27px 24px;
    display: grid;
    grid-template-columns: 1fr 9fr 4fr;
    grid-gap: 8px;
    align-items: center;
    border: ${({ theme }) => theme.border.base};
    border-radius: ${({ theme }) => theme.border.radiusBase};

    .show-on-hover {
        display: none;
    }

    &:hover {
        .show-on-hover {
            display: flex;
        }
    }
`;

export const HeartIconWrapper = styled.div`
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.color.bgPrimaryLight};
    border-radius: 50%;

    svg {
        width: 12px;
        position: relative;
        top: 1px;
    }
`;

export const MiddleSectionWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Name = styled.div`
    margin-bottom: 4px;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    color: ${({ theme }) => theme.color.textBase};
    cursor: pointer;
`;

export const Count = styled.div`
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    min-height: 18px;
    color: ${({ theme }) => theme.color.textLight};
    cursor: pointer;

    span {
        color: ${({ theme }) => theme.color.textLight};
        line-height: 17px;
        font-size: 14px;
        min-height: 18px;
        display: inline-block;
    }

    &:hover {
        color: ${({ theme }) => theme.color.textBase};

        .loginLink {
            color: ${({ theme }) => theme.color.textBase};
        }

        svg {
            opacity: 1 !important;
        }
    }

    &:active {
        .loginLink {
            color: ${({ theme }) => theme.color.secondary};
        }
    }
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

export const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 49px;
    height: 49px;
    border-radius: ${({ theme }) => theme.border.radiusBase};
    cursor: pointer;

    &:hover {
        background-color: ${({ theme }) => theme.color.bgPrimaryLight};
    }
`;

export const Divider = styled.div`
    width: 1px;
    height: 45px;
    border-right: 1px solid;
    border-color: ${({ theme }) => theme.color.bgSearch};
    padding-left: 16px;
    margin-right: 16px;
`;

export const StyledAvatarPlaceholder = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    ${({ color }) =>
        color &&
        css`
            background-color: ${color};
        `}
`;

export const Avatar = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    cursor: pointer;
`;
