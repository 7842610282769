import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import apiRoutes from 'config/apiRoutes';
import Auth0ProviderWithHistory from 'auth/auth0ProviderWithHistory';
import AuthButton from 'components/AuthButton';
import Input from 'components/Input';
import Logo from 'components/Logo';
import { KEYBOARD_KEYS } from 'constants.js';
import Spinner from 'components/Spinner';
import Button from 'components/Button';
import IncognitoModeError from 'components/IncognitoModeError';
import { detectIncognito } from 'detectincognitojs';
import {
    AuthViewWrapper,
    LogoHolder,
    FormHolder,
    DropdownWrapper,
    Dropdown,
    DropdownItem,
    DropdownTitle,
    Grid,
    TermsAndPrivacyWrapper,
    TermsAndPrivacyItem,
    Leaf,
    Person,
} from './AuthView.style';

const renderAuthButton = (canLogIn) => (
    <Auth0ProviderWithHistory>
        <AuthButton canLogIn={canLogIn} id="authButton" />
    </Auth0ProviderWithHistory>
);

const AuthView = () => {
    const [value, setValue] = useState('');
    const [canLogIn, setCanLogIn] = useState(false);
    const [urlLogIn, setUrlLogIn] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(undefined);
    const [isItemSelected, setIsItemSelected] = useState(false);
    const [isIncognitoMode, setIsIncognitoMode] = useState(false);

    useEffect(() => {
        detectIncognito().then((result) => {
            setIsIncognitoMode(result.isPrivate);
        });
    }, []);

    const searchForTenants = () => {
        setLoading(true);
        fetch(apiRoutes.get.tenantSearch(value))
            .then((res) => res.json())
            .then((res) => {
                setLoading(false);
                if (res.tenants) {
                    setOptions(
                        res.tenants.map((tenant) => ({
                            title: tenant.name,
                            id: tenant.slug,
                        }))
                    );
                }
            });
    };

    useEffect(() => {
        const parsedSearch = queryString.parse(document.location.search);

        if (parsedSearch.organization && value === '') {
            const slug = parsedSearch.organization;

            fetch(apiRoutes.get.tenant, {
                method: 'GET',
                headers: {
                    organization_slug: slug,
                },
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.auth0_organization) {
                        window.localStorage.setItem(
                            'MP_AUTH0_ORGANIZATION',
                            res.auth0_organization
                        );

                        window.localStorage.removeItem('MP_AUTH0_CONNECTION');
                    } else if (res.auth0_connection) {
                        window.localStorage.setItem(
                            'MP_AUTH0_CONNECTION',
                            res.auth0_connection
                        );

                        window.localStorage.removeItem('MP_AUTH0_ORGANIZATION');
                    }

                    window.localStorage.setItem('MP_ORGANIZATION', slug);
                    return res;
                })
                .then(() => {
                    setUrlLogIn(true);
                    setCanLogIn(true);
                })
                .catch(() => {});
        }
    }, []);

    useEffect(() => {
        if (document.getElementById('authButton') && urlLogIn) {
            setTimeout(() => {
                // Wait for init
                document.getElementById('authButton').click();
            }, 500);
        }
    }, [canLogIn]);

    useEffect(() => {
        window.localStorage.removeItem('MP_ORGANIZATION');
        window.localStorage.removeItem('MP_AUTH0_CONNECTION');
    }, []);

    useEffect(() => {
        setCanLogIn(false);
        if (value === '') {
            setOptions([]);
            setShowDropdown(false);
        } else if (!isItemSelected) {
            setShowDropdown(true);
            searchForTenants();
        }
        setIsItemSelected(false);
    }, [value]);

    useEffect(() => {
        if (options.length > 0) {
            setSelectedIndex(0);
        } else {
            setSelectedIndex(undefined);
        }
    }, [options]);

    const handleOnChange = (val) => {
        setValue(val.title);

        window.localStorage.setItem('MP_ORGANIZATION', val.id);

        fetch(apiRoutes.get.tenant, {
            method: 'GET',
            headers: {
                organization_slug: val.id,
            },
        })
            .then((res) => {
                if (res.status === 404) {
                    setCanLogIn(false);
                } else {
                    return res.json();
                }
                return 0;
            })
            .then((res) => {
                if (res !== 0) {
                    if (res.auth0_organization) {
                        window.localStorage.setItem(
                            'MP_AUTH0_ORGANIZATION',
                            res.auth0_organization
                        );

                        window.localStorage.removeItem('MP_AUTH0_CONNECTION');
                    } else if (res.auth0_connection) {
                        window.localStorage.setItem(
                            'MP_AUTH0_CONNECTION',
                            res.auth0_connection
                        );

                        window.localStorage.removeItem('MP_AUTH0_ORGANIZATION');
                    }
                }
            })
            .then(() => {
                setTimeout(() => {
                    setCanLogIn(true);
                }, 150);
            });
    };

    const handleClick = (e) => {
        if (value !== '') {
            switch (e.keyCode) {
                // enter
                case KEYBOARD_KEYS.enter:
                    if (selectedIndex !== undefined) {
                        handleOnChange(options[selectedIndex]);
                        setShowDropdown(false);
                        setIsItemSelected(true);
                    }
                    break;

                // down
                case KEYBOARD_KEYS.arrowLeft:
                case KEYBOARD_KEYS.arrowUp:
                    if (selectedIndex > 0) {
                        setSelectedIndex(selectedIndex - 1);
                    }
                    break;

                // up
                case KEYBOARD_KEYS.arrowRight:
                case KEYBOARD_KEYS.arrowDown:
                    if (selectedIndex < options.length - 1) {
                        setSelectedIndex(selectedIndex + 1);
                    }
                    break;

                default:
                    break;
            }
        }
    };

    return (
        <>
            <AuthViewWrapper>
                <FormHolder urlLogIn={urlLogIn}>
                    <Leaf />
                    <Person />
                    <LogoHolder>
                        <Logo /> MedPower
                    </LogoHolder>
                    <Grid>
                        <h3>Enter Organization Name</h3>

                        <DropdownWrapper>
                            <Input
                                placeholder="Search for your organization"
                                onFocus={() => {
                                    setShowDropdown(true);
                                }}
                                value={value}
                                onChange={(e) => setValue(e.target.value)}
                                handleSelect={(val) => {
                                    handleOnChange(val);
                                    setShowDropdown(false);
                                }}
                                onKeyDown={handleClick}
                                customStyles={{ maxWidth: '100%' }}
                            />

                            {showDropdown && value !== '' && (
                                <Dropdown>
                                    {options.length === 0 && !loading ? (
                                        <DropdownTitle>
                                            Organization not found
                                        </DropdownTitle>
                                    ) : (
                                        <DropdownTitle>
                                            Organizations
                                        </DropdownTitle>
                                    )}

                                    {loading ? (
                                        <Spinner small />
                                    ) : (
                                        options.map((option, index) => (
                                            <DropdownItem
                                                key={option.id}
                                                selected={
                                                    index === selectedIndex
                                                }
                                                onClick={() => {
                                                    setSelectedIndex(index);
                                                    setShowDropdown(false);
                                                    handleOnChange(option);
                                                    setIsItemSelected(true);
                                                }}
                                            >
                                                {option.title}
                                            </DropdownItem>
                                        ))
                                    )}
                                </Dropdown>
                            )}
                        </DropdownWrapper>

                        {canLogIn ? (
                            renderAuthButton(canLogIn)
                        ) : (
                            <Button disabled primaryDark>
                                Sign In
                            </Button>
                        )}
                    </Grid>
                    <TermsAndPrivacyWrapper>
                        <TermsAndPrivacyItem
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.medpower.org/privacy-policy"
                        >
                            Privacy policy
                        </TermsAndPrivacyItem>
                        <TermsAndPrivacyItem
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.medpower.org/termsofuse"
                        >
                            Terms of use
                        </TermsAndPrivacyItem>
                    </TermsAndPrivacyWrapper>
                    <IncognitoModeError
                        title="You use incognitio mode"
                        description="Please allow cookies in your browser settings or switch to normal mode to log in."
                        onToggle={() => setIsIncognitoMode(false)}
                        isVisible={isIncognitoMode}
                    />
                </FormHolder>
            </AuthViewWrapper>
        </>
    );
};

export default AuthView;
