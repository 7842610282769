import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import useOffset from 'hooks/useOffset';
import {
    establishOrganizationUsersThunk,
    loadMoreOrganizationUsersThunk,
    resetOrganizationUsersThunk,
} from 'redux/actions/thunks/organizationUsers';
import DropdownSelect from 'components/DropdownSelect/DropdownSelect';
import { useAuth0 } from '@auth0/auth0-react';

const INFINITE_SCROLL_THRESHOLD = 20;

const SelectUsersDropdown = ({ onUpdate }) => {
    const { getAccessTokenSilently, logout } = useAuth0();
    const dispatch = useDispatch();
    const [searchFilter, setSearchFilter] = useState('');
    const [offset, incrementOffset, resetOffset] = useOffset(
        INFINITE_SCROLL_THRESHOLD
    );

    const { organizationUsers, hasMoreOrganizationUsers } = useSelector(
        (state) => state.organizationUsers
    );

    useEffect(() => {
        resetOffset();
        dispatch(
            establishOrganizationUsersThunk(
                getAccessTokenSilently,
                logout,
                INFINITE_SCROLL_THRESHOLD,
                0,
                {
                    search: searchFilter || '',
                }
            )
        );
        incrementOffset();
    }, [searchFilter]);

    return (
        <DropdownSelect
            type="user"
            items={organizationUsers}
            loadMore={() => {
                dispatch(
                    loadMoreOrganizationUsersThunk(
                        getAccessTokenSilently,
                        logout,
                        INFINITE_SCROLL_THRESHOLD,
                        offset,
                        {
                            search: searchFilter || '',
                        }
                    )
                );
                incrementOffset();
            }}
            hasMore={hasMoreOrganizationUsers}
            onSearch={setSearchFilter}
            resetItemsList={() => {
                resetOffset();
                dispatch(resetOrganizationUsersThunk());
            }}
            handleSetSelectedItems={onUpdate}
            placeholder="Assign to Users"
        />
    );
};

SelectUsersDropdown.propTypes = {
    onUpdate: PropTypes.func.isRequired,
};

export default SelectUsersDropdown;
