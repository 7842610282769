import styled, { css } from 'styled-components/macro';

export const accordionCustomStyles = css`
    width: 100%;
    background: transparent;
    border: none;
    position: relative;

    .accordionButton {
        background: transparent;
        border: none;
        padding-left: 0;
        justify-content: flex-start;
        padding-top: 0;
        padding-bottom: 16px;

        & > div {
            width: auto;
            min-width: 33px;
        }
    }

    .accordionIconHolder {
        width: 33px;
        height: 33px;
        border-radius: 17px;
        margin: 16px;
    }
`;

export const LastUpdate = styled.div`
    margin-left: 16px;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: ${({ theme }) => theme.color.textLight};
    border-left: ${({ theme }) => theme.border.base};
    padding-left: 16px;
`;

export const MetricAccordionBody = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
`;
