import React from 'react';
import PropTypes from 'prop-types';
import { StyledDatePicker, StyledCalendarContainer } from './DatePicker.style';

const MyContainer = ({ children }) => (
  <StyledCalendarContainer>{children}</StyledCalendarContainer>
);

const DatePicker = ({ date, setNewDate }) => {
    const handleChangeDate = (value) => {
        const now = new Date();
        const nowDate = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate()
        );
        const newDate = value;
        newDate.setDate(value.getDate());

        if (nowDate.getTime() <= newDate.getTime()) {
            setNewDate(newDate);
        }
    };

    return (
      <StyledDatePicker
            selected={date}
            onChange={handleChangeDate}
            calendarContainer={MyContainer}
            dateFormat="MMMM dd, yyyy"
        />
    );
};

DatePicker.propTypes = {
    date: PropTypes.instanceOf(Date).isRequired,
    setNewDate: PropTypes.func.isRequired,
};

MyContainer.propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
        .isRequired,
};

export default DatePicker;
