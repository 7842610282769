import styled, { css } from 'styled-components/macro';

const CheckboxHolder = styled.span`
    cursor: pointer;

    ${({ disabled }) =>
        disabled &&
        css`
            opacity: 0.5;
            cursor: not-allowed;
        `}

    ${({ customStyles }) =>
        customStyles &&
        css`
            ${customStyles}
        `}
`;

export default CheckboxHolder;
