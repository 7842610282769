import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as CloseIcon } from 'assets/svg/close.svg';
import { ReactComponent as CloseModalIcon } from 'assets/svg/close-modal-2.svg';
import { ChipContainer, ChipRemoveIconContainer } from './styled';
import './styles.css';

export const ClassificationTagChip = ({ tag, onClick }) => (
  <ChipContainer className="user-classification-chip">
    {tag.name}
    <ChipRemoveIconContainer onClick={onClick}>
      <CloseIcon className="close-icon-active" height={14} width={14} />
      <CloseModalIcon
                className="close-icon-inactive"
                height={14}
                width={14}
            />
    </ChipRemoveIconContainer>
  </ChipContainer>
);

ClassificationTagChip.propTypes = {
    tag: PropTypes.node.isRequired,
    onClick: PropTypes.func.isRequired,
};
