import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useOffset from 'hooks/useOffset';
import UsersSatisfactionView from 'views/UsersSatisfaction';
import establishOrganizationSmartTipsThunk, {
    loadMoreOrganizationSmartTipsThunk,
    resetOrganizationSmartTipsThunk,
} from 'redux/actions/thunks/organizationSmartTips';
import { useAuth0 } from '@auth0/auth0-react';

const INFINITE_SCROLL_THRESHOLD = 20;

const UsersSatisfaction = () => {
    const { getAccessTokenSilently, logout } = useAuth0();
    const dispatch = useDispatch();
    const [offset, incrementOffset, resetOffset] = useOffset(
        INFINITE_SCROLL_THRESHOLD
    );
    const [scrollDisabled, setScrollDisabled] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [selectedSmartTips, setSelectedSmartTips] = useState([]);
    const [selectedSmartTip, setSelectedSmartTip] = useState(undefined);

    const {
        areOrganizationSmartTipsEstablished,
        areOrganizationSmartTipsFetching,
        hasMoreOrganizationSmartTips,
        organizationSmartTips,
    } = useSelector((state) => state.organizationSmartTips);

    const isSurveyFeedbackModalVisible = selectedSmartTip !== undefined;

    useEffect(() => {
        dispatch(
            establishOrganizationSmartTipsThunk(
                getAccessTokenSilently,
                logout,
                INFINITE_SCROLL_THRESHOLD,
                0
            )
        );
        resetOffset(INFINITE_SCROLL_THRESHOLD);

        return dispatch(resetOrganizationSmartTipsThunk());
    }, []);

    useEffect(() => {
        if (searchValue && searchValue.length > 0) {
            setScrollDisabled(true);
        } else {
            setScrollDisabled(false);
        }
    }, [searchValue]);

    const handleSearch = (search) => {
        dispatch(
            establishOrganizationSmartTipsThunk(
                getAccessTokenSilently,
                logout,
                50,
                0,
                {
                    search,
                }
            )
        );
    };

    const handleSearchInput = (searchString) => {
        setSearchValue(searchString);
        handleSearch(searchString);
    };

    const handleSelectSmartTip = (id) => {
        if (selectedSmartTips.includes(id)) {
            setSelectedSmartTips(
                selectedSmartTips.filter((item) => item !== id)
            );
        } else {
            setSelectedSmartTips([...selectedSmartTips, id]);
        }
    };

    const handleSelectAllSmartTips = () => {
        if (organizationSmartTips.length === selectedSmartTips.length) {
            setSelectedSmartTips([]);
        } else {
            setSelectedSmartTips(organizationSmartTips.map((item) => item.id));
        }
    };

    const loadMore = () => {
        if (scrollDisabled) {
            return;
        }
        dispatch(
            loadMoreOrganizationSmartTipsThunk(
                getAccessTokenSilently,
                logout,
                INFINITE_SCROLL_THRESHOLD,
                offset
            )
        );
        incrementOffset();
    };

    const hasMore =
        hasMoreOrganizationSmartTips && !areOrganizationSmartTipsFetching;

    return (
      <UsersSatisfactionView
            areOrganizationSmartTipsEstablished={
                areOrganizationSmartTipsEstablished
            }
            searchValue={searchValue}
            handleSearchInput={handleSearchInput}
            loadMore={loadMore}
            hasMore={hasMore}
            organizationSmartTips={organizationSmartTips}
            areOrganizationSmartTipsFetching={areOrganizationSmartTipsFetching}
            selectedSmartTips={selectedSmartTips}
            handleSelectSmartTip={handleSelectSmartTip}
            handleSelectAllSmartTips={handleSelectAllSmartTips}
            isSurveyFeedbackModalVisible={isSurveyFeedbackModalVisible}
            selectedSmartTip={selectedSmartTip}
            setSelectedSmartTip={setSelectedSmartTip}
            setSelectedSmartTips={setSelectedSmartTips}
            reload={() =>
                dispatch(
                    establishOrganizationSmartTipsThunk(
                        getAccessTokenSilently,
                        logout,
                        INFINITE_SCROLL_THRESHOLD,
                        0
                    )
                )}
        />
    );
};

export default UsersSatisfaction;
