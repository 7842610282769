import styled, { css } from 'styled-components/macro';

export const SingleTip = styled.div`
    display: grid;
    grid-template-columns: 4fr 3fr 2fr 2fr 3fr 1fr 1fr;
    grid-gap: 16px;
    width: 100%;
    height: auto;
    background-color: ${({ theme }) => theme.color.white};
    border: ${({ theme }) => theme.border.base};
    border-radius: ${({ theme }) => theme.border.radiusBase};
    margin-bottom: 16px;
    padding: 16px 32px;
    justify-content: space-between;
    align-items: center;
`;

export const BoldText = styled.div`
    display: flex;
    flex: row;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    text-align: left;
    color: ${({ theme }) => theme.color.textBase};

    ${({ clickable }) =>
        clickable &&
        css`
            cursor: pointer;
        `}
`;

export const Row = styled.div`
    display: flex;
    flex: row;
`;

export const TightText = styled(BoldText)`
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: left;
    color: ${({ theme }) => theme.color.textLight};
`;

export const ColumnTitle = styled.span`
    display: block;
`;

export const StatusIndicator = styled.div`
    display: block;
    width: 14px;
    height: 14px;
    border-radius: 50px;
    background-color: ${({ theme }) => theme.color.textLight};
    margin-right: 10px;

    ${({ published }) =>
        published &&
        css`
            background-color: ${({ theme }) => theme.color.secondary};
        `}

    ${({ review }) =>
        review &&
        css`
            background-color: ${({ theme }) => theme.color.tertiary};
        `}
`;

export const StatusLabel = styled.div`
    color: ${({ theme }) => theme.color.textLight};

    ${({ published }) =>
        published &&
        css`
            color: ${({ theme }) => theme.color.secondary};
        `}

    ${({ review }) =>
        review &&
        css`
            color: ${({ theme }) => theme.color.tertiary};
        `}
`;

export const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 49px;
    height: 49px;
    border-radius: ${({ theme }) => theme.border.radiusBase};
    cursor: pointer;

    &:hover {
        background-color: ${({ theme }) => theme.color.bgPrimaryLight};
    }

    svg {
        width: 24px;
        height: 24px;
    }
`;
