import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as StarIcon } from 'assets/svg/star.svg';
import { ReactComponent as StarsToNextLevelIcon } from 'assets/svg/single-neutral-actions-star.svg';
import { ReactComponent as LevelIcon } from 'assets/svg/read-glasses.svg';
import theme from 'assets/theme/mainTheme';
import {
    StyledStatisticWrapper,
    StyledStatisticLabel,
    StyledMetric,
} from './StatisticBox.style';

const StatisticBox = ({ metric, label, type }) => (
  <StyledStatisticWrapper>
    <StyledStatisticLabel
            starsEarned={type === 'stars-earned'}
            level={type === 'level'}
            starsToNextLevel={type === 'stars-to-next-level'}
        >
      {type === 'stars-earned' && <StarIcon fill={theme.color.white} />}
      {type === 'level' && <LevelIcon fill={theme.color.white} />}
      {type === 'stars-to-next-level' && (
        <StarsToNextLevelIcon fill={theme.color.white} />
            )}
    </StyledStatisticLabel>
    <StyledMetric>
      <strong>{metric}</strong>
      <small>{label}</small>
    </StyledMetric>
  </StyledStatisticWrapper>
);

StatisticBox.propTypes = {
    metric: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    label: PropTypes.string.isRequired,
    type: PropTypes.string,
};

StatisticBox.defaultProps = {
    type: 'stars-earned',
};

export default StatisticBox;
