import {
    ESTABLISH_DATA_SOURCES_MEASURES_UPLOADS,
    ESTABLISH_DATA_SOURCES_MEASURES_UPLOADS_SUCCESS,
    ESTABLISH_DATA_SOURCES_MEASURES_UPLOADS_FAILURE,
    DESTROY_METRIC_UPLOAD,
    LOAD_MORE_DATA_SOURCES_MEASURES_UPLOADS,
    MARK_ALL_DATA_SOURCES_MEASURES_UPLOADS_LOADED,
    RESET_DATA_SOURCES_MEASURES_UPLOADS,
} from 'redux/actions/mpaDataSourcesMeasuresUploads';

export const initialState = {
    areDataSourcesMeasuresUploadsEstablished: false,
    isDataSourcesMeasuresUploadsError: false,
    areDataSourcesMeasuresUploadsFetching: false,
    error: null,
    dataSourcesMeasuresUploads: [],
    hasMoreDataSourcesMeasuresUploads: true,
};

const dataSourcesMeasuresUploads = (state = initialState, action) => {
    switch (action.type) {
        case DESTROY_METRIC_UPLOAD:
        case ESTABLISH_DATA_SOURCES_MEASURES_UPLOADS:
            return {
                ...state,
                areDataSourcesMeasuresUploadsFetching: true,
                isDataSourcesMeasuresUploadsError: false,
            };

        case ESTABLISH_DATA_SOURCES_MEASURES_UPLOADS_SUCCESS:
            return {
                ...state,
                areDataSourcesMeasuresUploadsFetching: false,
                areDataSourcesMeasuresUploadsEstablished: true,
                dataSourcesMeasuresUploads: action.payload,
            };

        case ESTABLISH_DATA_SOURCES_MEASURES_UPLOADS_FAILURE:
            return {
                ...state,
                areDataSourcesMeasuresUploadsFetching: false,
                isDataSourcesMeasuresUploadsError: true,
                error: action.err,
            };

        case LOAD_MORE_DATA_SOURCES_MEASURES_UPLOADS:
            return {
                ...state,
                areDataSourcesMeasuresUploadsFetching: false,
                areDataSourcesMeasuresUploadsEstablished: true,
                dataSourcesMeasuresUploads: [
                    ...state.dataSourcesMeasuresUploads,
                    ...action.payload,
                ],
            };
        case MARK_ALL_DATA_SOURCES_MEASURES_UPLOADS_LOADED:
            return {
                ...state,
                hasMoreDataSourcesMeasuresUploads: false,
                areDataSourcesMeasuresUploadsFetching: false,
                areDataSourcesMeasuresUploadsEstablished: true,
            };

        case RESET_DATA_SOURCES_MEASURES_UPLOADS:
            return initialState;

        default:
            return state;
    }
};

export default dataSourcesMeasuresUploads;
