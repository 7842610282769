import Button from 'components/Button';
import styled, { css } from 'styled-components/macro';

const StyledButton = styled(Button)`
    margin-right: 5px;
    ${({ transparent }) =>
        transparent &&
        css`
            background-color: transparent;
            color: ${({ theme }) => theme.color.textLight};
            border: none;
        `};
`;

export default StyledButton;
