import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useFetch from 'hooks/useFetch';
import useAdmin from 'hooks/useAdmin';
import apiRoutes from 'config/apiRoutes';
import PerformanceDashboardComponent from 'components/PerformanceDashboard/PerformanceDashboard';
import { useAuth0 } from '@auth0/auth0-react';

const PerformanceDashboard = ({ userId, twoThird, goToPerformance }) => {
    const { getAccessTokenSilently, logout } = useAuth0();
    const [userStats, setUserStats] = useState(undefined);
    const [
        performanceCoachingRequestSent,
        setPerformanceCoachingRequestSent,
    ] = useState(false);
    const [btnLoadingState, setBtnLoadingState] = useState(false);
    const [metricSourceOverallScores, setMetricSourceOverallScores] = useState(
        []
    );
    const request = useFetch(getAccessTokenSilently, logout);

    const handleUserStatsSuccess = (stats) => setUserStats(stats);

    const handleUserStatsFailure = () => setUserStats(undefined);

    const handleMetricSourcesOverallScoresSuccess = (
        newMetricSourceOverallScores
    ) =>
        setMetricSourceOverallScores(
            newMetricSourceOverallScores.overall_scores
        );

    const handleMetricSourcesOverallScoresFailure = () =>
        setMetricSourceOverallScores(undefined);

    const isAdmin = useAdmin();

    const handleSendNotification = () => {
        setBtnLoadingState(true);
        request(
            apiRoutes.post.performanceCoachingRequest,
            'POST',
            () => {
                setBtnLoadingState(false);
                setPerformanceCoachingRequestSent(true);
            },
            () => {
                setBtnLoadingState(false);
            },
            {},
            {},
            true
        );
    };

    useEffect(() => {
        let reqUrl;

        if (isAdmin) {
            if (userId) {
                reqUrl = apiRoutes.get.organizationUserStats(userId);
            } else {
                reqUrl = apiRoutes.get.organizationStats;
            }
        } else {
            reqUrl = apiRoutes.get.userProfileStats;
        }

        request(
            reqUrl,
            'GET',
            handleUserStatsSuccess,
            handleUserStatsFailure,
            {},
            { 'Content-Type': 'application/json' }
        );

        if (!isAdmin) {
            request(
                apiRoutes.get.performanceCoachingRequest,
                'GET',
                (res) => {
                    setPerformanceCoachingRequestSent(res.sent);
                },
                () => {},
                {}
            );
        }
    }, []);

    const getMetricSourcesOverallScores = (metricSourceId) => {
        let reqUrl = '';

        if (userId) {
            reqUrl = apiRoutes.get.organizationUserMetricSourcesOverallScores(
                metricSourceId,
                userId
            );
        } else {
            reqUrl = isAdmin
                ? apiRoutes.get.organizationMetricSourcesOverallScores(
                      metricSourceId
                  )
                : apiRoutes.get.metricSourcesOverallScores(metricSourceId);
        }
        request(
            reqUrl,
            'GET',
            handleMetricSourcesOverallScoresSuccess,
            handleMetricSourcesOverallScoresFailure,
            {},
            { 'Content-Type': 'application/json' }
        );
    };

    return (
      <PerformanceDashboardComponent
            twoThird={twoThird}
            goToPerformance={goToPerformance}
            handleSendNotification={handleSendNotification}
            performanceCoachingRequestSent={performanceCoachingRequestSent}
            userStats={userStats}
            metricSourceOverallScores={metricSourceOverallScores}
            getMetricSourcesOverallScores={getMetricSourcesOverallScores}
            isAdmin={isAdmin && !userId}
            btnLoadingState={btnLoadingState}
        />
    );
};

PerformanceDashboard.propTypes = {
    userId: PropTypes.string,
    twoThird: PropTypes.bool,
    goToPerformance: PropTypes.bool,
};

PerformanceDashboard.defaultProps = {
    userId: undefined,
    twoThird: false,
    goToPerformance: false,
};

export default PerformanceDashboard;
