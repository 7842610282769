import React from 'react';
import PropTypes from 'prop-types';
import useTeamName from 'hooks/useTeamName';
import useTeamCreator from 'hooks/useTeamCreator';
import AddTeamMembers from 'containers/AddTeamMembers';
import Spinner from 'components/Spinner';
import SectionHeader from 'components/SectionHeader';
import TeamMember from 'components/TeamMember';
import InfiniteScroll from 'react-infinite-scroller';
import TeamOverviewNavigation from 'containers/TeamOverviewNavigation';
import useAdmin from 'hooks/useAdmin';
import { ReactComponent as ArrowBackIcon } from 'assets/svg/arrow-back.svg';
import theme from 'assets/theme/mainTheme';
import { ROUTES } from 'routing/constants';
import {
    StyledTeamMembersView,
    StyledTeamMembersHeader,
    TeamMemberWrapper,
} from './TeamMembersView.style';

const TeamMembersView = ({
    isFetching,
    teamMembers,
    teamId,
    removeUserFromTeam,
    leaveTeam,
    loadMore,
    hasMore,
    membersCount,
}) => {
    const teamName = useTeamName(teamId);
    const { isTeamCreator, userId } = useTeamCreator();

    const isAdmin = useAdmin();

    if (isFetching) {
        return <Spinner data-testid="spinner" />;
    }

    return (
        <StyledTeamMembersView data-testid="team-members-body">
            <SectionHeader
                title={`${teamName} team`}
                withPadding
                goBack={{
                    url: ROUTES.team,
                    label() {
                        return (
                            <>
                                <ArrowBackIcon stroke={theme.color.textLabel} />{' '}
                                <span>Back</span>
                            </>
                        );
                    },
                }}
                cta={() => (
                    <TeamOverviewNavigation
                        teamId={teamId}
                        activeSection="members"
                    />
                )}
                collapseOnMobile
            />

            <StyledTeamMembersHeader data-testid="team-members-counter">
                <span>{membersCount} users in team</span>
                {(isTeamCreator || isAdmin) && (
                    <AddTeamMembers teamMembers={teamMembers} teamId={teamId} />
                )}
            </StyledTeamMembersHeader>
            {/* <InfiniteScroll
                loadMore={loadMore}
                hasMore={hasMore}
                style={{ width: '100%' }}
            > */}
            <TeamMemberWrapper>
                {teamMembers.map((member) => (
                    <TeamMember
                        key={member.id}
                        userId={member.id}
                        firstName={member.first_name}
                        lastName={member.last_name}
                        avatarUrl={member.avatar_url}
                        backgroundColor={member.background_color}
                        creator={member.creator}
                        email={member.email}
                        isRemovable={
                            isTeamCreator || member.id === userId || isAdmin
                        }
                        onRemove={() => {
                            if (member.id === userId) {
                                leaveTeam();
                            } else {
                                removeUserFromTeam(member.id);
                            }
                        }}
                    />
                ))}
            </TeamMemberWrapper>
            {/* </InfiniteScroll> */}
        </StyledTeamMembersView>
    );
};

TeamMembersView.propTypes = {
    isFetching: PropTypes.bool.isRequired,
    teamMembers: PropTypes.arrayOf(PropTypes.any).isRequired,
    teamId: PropTypes.string.isRequired,
    removeUserFromTeam: PropTypes.func.isRequired,
    leaveTeam: PropTypes.func.isRequired,
    loadMore: PropTypes.func.isRequired,
    hasMore: PropTypes.bool.isRequired,
    membersCount: PropTypes.number.isRequired,
};

export default TeamMembersView;
