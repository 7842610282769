import styled from 'styled-components/macro';

export const ModalContent = styled.div`
    padding: 24px 18px;
    height: 300px;
    overflow-y: auto;
`;

export const Loading = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Comment = styled.div`
    display: flex;
    flex-flow: row;
    border: 1px solid;
    border-color: ${({ theme }) => theme.color.borderColorBase};
    border-radius: 16px;
    padding: 24px;
    margin-bottom: 16px;
`;

export const Content = styled.div`
    width: 100%;
`;

export const Avatar = styled.img`
    position: relative;
    top: 3px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
`;

export const EmptyAvatar = styled.p`
    margin: 0px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    text-align: center;
    vertical-align: middle;
    line-height: 40px;
    font-size: 12px;
    font-weight: 600;
    color: ${({ color }) => color};
    background-color: ${({ backgroundColor }) => backgroundColor};
`;

export const PictureWrapper = styled.div`
    min-width: 45px;
    height: 40px;
    margin-right: 24px;
`;

export const FeedbackIcon = styled.div`
    position: relative;
    width: 20px;
    height: 20px;
    top: -20px;
    left: 25px;
    border-radius: 50%;
    background-color: ${({ theme, variant }) => {
        switch (variant) {
            case 1:
                return theme.color.quaternary;
            case 2:
                return theme.color.quaternaryAlt;
            case 3:
                return theme.color.tertiaryAlt;
            case 4:
                return theme.color.paleGreen;
            case 5:
                return theme.color.primary;
            default:
                return null;
        }
    }};
    fill: ${({ theme }) => theme.color.white};

    svg {
        position: relative;
        top: 1px;
        left: 5px;
    }
`;

export const TopSection = styled.div`
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    margin-bottom: 11px;

    p {
        margin: 0;
        font-size: 14px;
        font-weight: 600;
        color: ${({ theme }) => theme.color.textBase};
    }

    small {
        font-size: 12px;
        font-weight: 500;
        color: ${({ theme }) => theme.color.textLight};
    }
`;

export const BottomSection = styled.p`
    margin: 0px;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    color: ${({ theme }) => theme.color.textNavigationCounter};
`;
