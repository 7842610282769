import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Sidebar from 'containers/Sidebar';
import Navbar from 'containers/Navbar';
import SystemAlerts from 'containers/SystemAlerts';
import theme from 'assets/theme/mainTheme';
import 'smartbanner.js/dist/smartbanner.min.css';
import 'smartbanner.js/dist/smartbanner.min';
import {
    StyledDashboardWrapper,
    StyledContentHolder,
} from './DashboardWithSidebar.style';

const DashboardWithSidebar = ({ children }) => {
    const [isMobile, setIsMobile] = useState(false);
    const [isMobileExpanded, setIsMobileExpanded] = useState(false);

    useEffect(() => {
        if (window) {
            if (window.document.body.offsetWidth <= theme.breakpoints.mobile) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }

            // TEMPORARY
            if (!isMobileExpanded) {
                setIsMobileExpanded(isMobileExpanded);
            }
        }
    }, []);

    const toggleMobileSidebar = () => {
        setIsMobileExpanded(!isMobileExpanded);
    };

    return (
      <StyledDashboardWrapper isMobile={isMobile}>
        <Sidebar
                isMobile={isMobile}
                isMobileExpanded={isMobileExpanded}
                toggleMobileSidebar={toggleMobileSidebar}
            />
        <StyledContentHolder isMobile={isMobile}>
          <Navbar isMobile={isMobile} />
          <SystemAlerts />
          {children}
        </StyledContentHolder>
      </StyledDashboardWrapper>
    );
};

DashboardWithSidebar.propTypes = {
    children: PropTypes.element,
};

DashboardWithSidebar.defaultProps = {
    children: <></>,
};

export default DashboardWithSidebar;
