import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Accordion from 'components/Accordion';
import { useDispatch } from 'react-redux';
import MPASingleMetric from 'containers/MPASingleMetric';
import Switch from 'components/Switch';
import Modal from 'components/Modal';
import Button from 'components/Button';
import Spinner from 'components/Spinner';
import ConfirmActionModal from 'components/ConfirmActionModal';
import { useAuth0 } from '@auth0/auth0-react';
import useFetch from 'hooks/useFetch';
import apiRoutes from 'config/apiRoutes';
import {
    dispatchErrorAlertThunk,
    dispatchSuccessAlertThunk,
} from 'redux/actions/thunks/systemAlerts';
import establishMetricSourcesMpaThunk from 'redux/actions/thunks/mpaMetricSources';
import {
    AccordionWrapper,
    TableHeaderMPA,
    LabelCta,
    CustomersListWrapper,
    SingleCustomerWrapper,
    Avatar,
    EmptyAvatar,
} from './MetricsTable.style';

const MetricsTableMPA = ({
    metrics,
    label,
    metricSourceId,
    isMetricSourceEnabled,
    isModalVisible,
    toggleModalVisible,
    usedByCustomersList,
    reloadMetrics,
    isAccordionOpen,
    fetchChangeSourceStatus,
    refresh,
}) => {
    const { getAccessTokenSilently, logout } = useAuth0();
    const request = useFetch(getAccessTokenSilently, logout);
    const dispatch = useDispatch();
    const [sortedMetrics, setSortedMetrics] = useState([]);
    const [idToDelete, setIdToDelete] = useState(undefined);

    const handleSuccess = (res) => {
        if (!res.error) {
            dispatch(
                dispatchSuccessAlertThunk('metricDeleted', 'Metric deleted')
            );

            dispatch(
                establishMetricSourcesMpaThunk(getAccessTokenSilently, logout)
            );
            refresh();
        }
    };

    const handleFailure = (err) => {
        dispatch(dispatchErrorAlertThunk(err, err.error));

        dispatch(
            establishMetricSourcesMpaThunk(getAccessTokenSilently, logout)
        );
    };

    const deleteMetric = () => {
        if (idToDelete) {
            request(
                apiRoutes.delete.mpaDeleteMetric(metricSourceId, idToDelete),
                'DELETE',
                handleSuccess,
                handleFailure
            );
        }
    };

    useEffect(() => {
        setSortedMetrics(
            metrics.sort((m1, m2) => {
                if (m1.name > m2.name) {
                    return 1;
                }

                if (m1.name < m2.name) {
                    return -1;
                }

                return 0;
            })
        );
    }, [metrics]);

    return (
      <AccordionWrapper>
        <Accordion
                initiallyOpen={isAccordionOpen}
                expandOnlyOnArrowClick
                label={label}
                secondLabel={() =>
                    usedByCustomersList && (
                    <div onClick={toggleModalVisible}>
                      {`${usedByCustomersList.length} customers`}
                    </div>
                    )}
                labelCta={() => (
                  <LabelCta>
                    <small style={{ marginRight: '16px' }}>
                      Global source status
                    </small>
                    <Switch
                            switchId={`${metricSourceId}-switch`}
                            switchOn={() => {
                                fetchChangeSourceStatus(true);
                            }}
                            switchOff={() => {
                                fetchChangeSourceStatus(false);
                            }}
                            defaultStatus={isMetricSourceEnabled}
                            withConfirmationModal
                            externalStatus={isMetricSourceEnabled}
                            onConfirmModalDescription="Warning: Changing Metric Status triggers a score recalculation for all affected Organizations. It can take several minutes."
                            offConfirmModalDescription="Warning: Changing Metric Status triggers a score recalculation for all affected Organizations. It can take several minutes."
                        />
                  </LabelCta>
                )}
            >
          <TableHeaderMPA>
            <span>Metrics name</span>
            <span>Description</span>
            <span>Goal</span>
            <span>Metric status</span>
          </TableHeaderMPA>
          {sortedMetrics.map((metric, index) => (
            <MPASingleMetric
                        key={metric.id}
                        {...metric}
                        metricSourceId={metricSourceId}
                        reloadMetrics={reloadMetrics}
                        withoutBorder={index === metrics.length - 1}
                        handleDelete={setIdToDelete}
                    />
                ))}
        </Accordion>

        {isModalVisible && (
        <Modal
                    closeModal={toggleModalVisible}
                    headerText="Source used by"
                    closeByIconClick={toggleModalVisible}
                    isCloseIcon
                    modalFooter={() => (
                      <Button primary onClick={toggleModalVisible}>
                        Cancel
                      </Button>
                    )}
                >
          {usedByCustomersList.length === 0 && <Spinner />}
          <CustomersListWrapper>
            {usedByCustomersList.map((customer) => (
              <SingleCustomerWrapper key={customer.id}>
                {customer.avatar_url ? (
                  <Avatar
                                        src={customer.avatar_url}
                                        alt={customer.name}
                                    />
                                ) : (
                                  <EmptyAvatar>{`${customer.name[0]}`}</EmptyAvatar>
                                )}
                <span>{customer.name}</span>
              </SingleCustomerWrapper>
                        ))}
          </CustomersListWrapper>
        </Modal>
            )}

        <ConfirmActionModal
                isVisible={idToDelete !== undefined}
                onToggle={() => setIdToDelete(undefined)}
                onConfirm={deleteMetric}
                title={
                    sortedMetrics.length > 1
                        ? 'Confirm Metric deletion'
                        : 'Confirm Data Source deletion'
                }
                description="Are you sure you want to delete this Metric? This will affect every organization using this Data Source."
                confirmLabel="Confirm"
            />
      </AccordionWrapper>
    );
};

MetricsTableMPA.propTypes = {
    metrics: PropTypes.arrayOf(PropTypes.any),
    label: PropTypes.string.isRequired,
    metricSourceId: PropTypes.string.isRequired,
    isMetricSourceEnabled: PropTypes.bool.isRequired,
    isModalVisible: PropTypes.bool.isRequired,
    toggleModalVisible: PropTypes.func.isRequired,
    usedByCustomersList: PropTypes.arrayOf(PropTypes.any),
    reloadMetrics: PropTypes.func.isRequired,
    isAccordionOpen: PropTypes.bool.isRequired,
    fetchChangeSourceStatus: PropTypes.func.isRequired,
    refresh: PropTypes.func.isRequired,
};

MetricsTableMPA.defaultProps = {
    metrics: [],
    usedByCustomersList: [],
};

export default MetricsTableMPA;
