import styled, { css } from 'styled-components/macro';

const StyledFeedbackWrapper = styled.div`
    width: 100%;
    height: 79px;
    margin-top: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
    ${({ theme }) =>
        css`
            background: ${theme.color.bgSearch};
            border-radius: ${theme.border.radiusBase};
        `};

    @media (max-width: ${({ theme }) => `${theme.breakpoints.medium}px`}) {
        padding: 16px;
        height: auto;
        flex-direction: column;
        flex-wrap: wrap;
    }
`;

export const StyledFeedbackSelection = styled.div`
    width: 45%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    strong {
        width: 100%;
        padding-right: 16px;
        text-align: right;
        transition-duration: 200ms;

        color: ${({ theme, isCompleted, buttonNumber }) => {
            if (!isCompleted) return theme.color.textBase;
            switch (buttonNumber) {
                case 1:
                    return theme.color.quaternary;
                case 2:
                    return theme.color.quaternaryAlt;
                case 3:
                    return theme.color.tertiaryAlt;
                case 4:
                    return theme.color.paleGreen;
                case 5:
                    return theme.color.primary;
                default:
                    return theme.color.textBase;
            }
        }};
    }

    @media (max-width: ${({ theme }) => `${theme.breakpoints.medium}px`}) {
        flex-direction: column;
        width: 100%;
        margin-top: 16px;
    }
`;

export const StyledFeedbackGroup = styled.span`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const StyledFeedbackButton = styled.div`
    width: 48px;
    height: 48px;
    border-radius: 50px;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition-duration: 200ms;
    background-color: ${({ theme }) => theme.color.white};
    cursor: not-allowed;

    ${({ theme, isSelected, bgColor }) =>
        isSelected &&
        bgColor &&
        css`
            background-color: ${bgColor};

            svg {
                fill: ${theme.color.white};
            }
        `}

    ${({ theme, isCompleted, bgColor }) =>
        isCompleted &&
        bgColor &&
        css`
            cursor: pointer;

            &:hover {
                background-color: ${bgColor};

                svg {
                    fill: ${theme.color.white};
                }
            }
        `}
        @media (max-width: ${({ theme }) =>
        `${theme.breakpoints.small}px`}) {
        width: 36px;
        height: 36px;
    }
`;

export default StyledFeedbackWrapper;
