import React from 'react';
import PropTypes from 'prop-types';
import SectionHeader from 'components/SectionHeader';
import AdminHANavigation from 'components/AdminHANavigation';
import Spinner from 'components/Spinner';
import Button from 'components/Button';
import { ReactComponent as LightBulbIcon } from 'assets/svg/light-bulb-shine.svg';
import Input from 'components/Input';
import theme from 'assets/theme/mainTheme';

import {
    AdminWrapper,
    AdminSection,
    Container,
    Title,
    Content,
    STTitle,
    ButtonSection,
    DropdownOption,
    DropdownOptionTexts,
    InputWrapper,
} from './TipOfTheWeek.style';

const generateSmartTipsSelectOptions = (smartTips) =>
    smartTips
        .filter((smartTip) => !smartTip.search_restricted)
        .map((smartTip) => ({
            id: smartTip.id,
            data: { ...smartTip },
            label() {
                return (
                    <DropdownOption>
                        <LightBulbIcon fill={theme.color.primary} />
                        <DropdownOptionTexts>
                            <span>{smartTip.name}</span>
                        </DropdownOptionTexts>
                    </DropdownOption>
                );
            },
        }));

const TipOfTheWeek = ({
    handleSelect,
    filter,
    setFilter,
    organizationSmartTips,
    isButtonDisabled,
    isFetching,
    hasMore,
    loadMore,
    handleSave,
    showSmartTipDropdown,
    setShowSmartTipDropdown,
}) => (
    <>
        <AdminWrapper fullHeight data-testid="AdminHAWrapper">
            <SectionHeader title="Tip of the Week" withPadding cta={() => {}} />
            <AdminSection withPadding>
                <AdminHANavigation variant="tip-of-the-week" />
            </AdminSection>
            {isFetching ? (
                <Spinner />
            ) : (
                <AdminSection withPadding withSpacing>
                    <Container>
                        <Title>Tip of the Week</Title>
                        <Content>
                            <STTitle>Current Tip of the Week</STTitle>
                            <InputWrapper>
                                <Input
                                    variant="searchDropdownApi"
                                    placeholder="Choose Smart Tip"
                                    selectButtonLabel="Link"
                                    onChange={(e) => setFilter(e.target.value)}
                                    value={filter}
                                    dropdownVisible={showSmartTipDropdown}
                                    options={generateSmartTipsSelectOptions(
                                        organizationSmartTips
                                    )}
                                    handleSelect={handleSelect}
                                    loadMore={loadMore}
                                    hasMore={hasMore}
                                    isLoadingOptions={isFetching}
                                    onClick={() =>
                                        setShowSmartTipDropdown(
                                            !showSmartTipDropdown
                                        )
                                    }
                                />
                            </InputWrapper>
                        </Content>
                        <ButtonSection>
                            <Button
                                customStyles={{ width: '123px' }}
                                primaryDark
                                disabled={isButtonDisabled}
                                onClick={handleSave}
                            >
                                Save
                            </Button>
                        </ButtonSection>
                    </Container>
                </AdminSection>
            )}
        </AdminWrapper>
    </>
);

TipOfTheWeek.propTypes = {
    handleSelect: PropTypes.func.isRequired,
    filter: PropTypes.string,
    setFilter: PropTypes.func.isRequired,
    organizationSmartTips: PropTypes.arrayOf(PropTypes.any),
    isButtonDisabled: PropTypes.bool,
    isFetching: PropTypes.bool,
    hasMore: PropTypes.bool.isRequired,
    loadMore: PropTypes.func.isRequired,
    handleSave: PropTypes.func.isRequired,
    showSmartTipDropdown: PropTypes.bool.isRequired,
    setShowSmartTipDropdown: PropTypes.func.isRequired,
};

TipOfTheWeek.defaultProps = {
    filter: '',
    organizationSmartTips: [],
    isButtonDisabled: true,
    isFetching: true,
};

export default TipOfTheWeek;
