import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as StatusDot } from 'assets/svg/status-dot.svg';
import StyledStatus from './SmartTipStatus.style';

const SmartTipStatus = ({ text, variant, customStyles }) => (
  <StyledStatus variant={variant} customStyles={customStyles}>
    <StatusDot />
    <p>{text}</p>
  </StyledStatus>
);

SmartTipStatus.propTypes = {
    customStyles: PropTypes.any || null,
    text: PropTypes.string.isRequired,
    variant: PropTypes.string.isRequired,
};

SmartTipStatus.defaultProps = {
    customStyles: '',
};

export default SmartTipStatus;
