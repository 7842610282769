import { css } from 'styled-components/macro';
import InterLight from 'assets/fonts/Inter/static/inter-light.ttf';
import InterRegular from 'assets/fonts/Inter/static/inter-regular.ttf';
import InterMedium from 'assets/fonts/Inter/static/inter-medium.ttf';
import InterSemibold from 'assets/fonts/Inter/static/inter-semibold.ttf';
import InterBold from 'assets/fonts/Inter/static/inter-bold.ttf';
import InterBlack from 'assets/fonts/Inter/static/inter-black.ttf';

const globalFonts = css`
    @font-face {
        font-family: 'Inter';
        src: url(${InterLight}) format('truetype');
        font-weight: 300;
        font-style: normal;
    }

    @font-face {
        font-family: 'Inter';
        src: url(${InterRegular}) format('truetype');
        font-weight: 400;
        font-style: normal;
    }

    @font-face {
        font-family: 'Inter';
        src: url(${InterMedium}) format('truetype');
        font-weight: 500;
        font-style: normal;
    }

    @font-face {
        font-family: 'Inter';
        src: url(${InterSemibold}) format('truetype');
        font-weight: 600;
        font-style: normal;
    }

    @font-face {
        font-family: 'Inter';
        src: url(${InterBold}) format('truetype');
        font-weight: 700;
        font-style: normal;
    }

    @font-face {
        font-family: 'Inter';
        src: url(${InterBlack}) format('truetype');
        font-weight: 900;
        font-style: normal;
    }
`;

export default globalFonts;
