import {
    FETCH_RECENT_SMART_TIPS,
    FETCH_RECENT_SMART_TIPS_SUCCESS,
    ESTABLISH_SMART_TIPS_ESSENTIAL_SUCCESS,
    ESTABLISH_SMART_TIPS_TOTW_SUCCESS,
    FETCH_RECENT_SMART_TIPS_FAILURE,
    ESTABLISH_SMART_TIPS_ARCHIVED_SUCCESS,
    ESTABLISH_SMART_TIPS_ARCHIVED_ESSENTIAL_SUCCESS,
    ESTABLISH_SMART_TIPS,
    ESTABLISH_SMART_TIPS_SUCCESS,
    ESTABLISH_SMART_TIPS_FAILURE,
    RESET_SMART_TIPS,
} from 'redux/actions/smartTips';

export const initialState = {
    areSmartTipsEstablished: false,
    isSmartTipsError: false,
    areSmartTipsFetching: false,
    areRecentSmartTipsEstablished: false,
    isRecentSmartTipsError: false,
    areRecentSmartTipsFetching: false,
    smartTips: [],
    smartTipsEssential: [],
    smartTipsToTW: [],
    smartTipsArchived: [],
    smartTipsEssentialArchived: [],
    recentSmartTips: [],
    error: null,
};

const smartTips = (state = initialState, action) => {
    switch (action.type) {
        case ESTABLISH_SMART_TIPS:
            return {
                ...initialState,
                areSmartTipsFetching: true,
            };

        case ESTABLISH_SMART_TIPS_SUCCESS:
            return {
                ...state,
                areSmartTipsFetching: false,
                areSmartTipsEstablished: true,
                smartTips: action.payload,
            };

        case ESTABLISH_SMART_TIPS_FAILURE:
            return {
                ...state,
                areSmartTipsFetching: false,
                isSmartTipsError: true,
                error: action.err,
            };

        case FETCH_RECENT_SMART_TIPS:
            return {
                ...state,
                areRecentSmartTipsFetching: true,
                isRecentSmartTipsError: false,
            };

        case FETCH_RECENT_SMART_TIPS_SUCCESS:
            return {
                ...state,
                areRecentSmartTipsFetching: false,
                areRecentSmartTipsEstablished: true,
                recentSmartTips: action.payload,
            };

        case ESTABLISH_SMART_TIPS_ESSENTIAL_SUCCESS:
            return {
                ...state,
                smartTipsEssential: action.payload,
            };

        case ESTABLISH_SMART_TIPS_TOTW_SUCCESS:
            return {
                ...state,
                smartTipsToTW: action.payload,
            };

        case ESTABLISH_SMART_TIPS_ARCHIVED_SUCCESS:
            return {
                ...state,
                smartTipsArchived: action.payload,
            };

        case ESTABLISH_SMART_TIPS_ARCHIVED_ESSENTIAL_SUCCESS:
            return {
                ...state,
                smartTipsEssentialArchived: action.payload,
            };

        case FETCH_RECENT_SMART_TIPS_FAILURE:
            return {
                ...state,
                areRecentSmartTipsFetching: false,
                isRecentSmartTipsError: true,
                error: action.err,
            };

        case RESET_SMART_TIPS:
            return initialState;

        default:
            return state;
    }
};

export default smartTips;
