const getType = (type) => {
    switch (type) {
        case 'recommendation':
            return 'Performance Tip';
        case 'tip_of_the_week':
            return 'Tip of the Week';
        default:
            return 'Smart Tip';
    }
};

const parseSmartTips = (smartTips, type = 'tip') =>
    smartTips.map((tip) => ({
        id: tip.id,
        title: tip.name,
        buttonLabel: 'Play video',
        typeLabel: getType(type),
        isVisible: true,
        status: tip.status,
        performanceImage: tip.performance_tip_image_url,
        totwImage: tip.tip_of_the_week_image_url,
        imageUrl: tip.image_url,
        type,
    }));

export default parseSmartTips;
