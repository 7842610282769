import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import DashboardMainView from 'views/DashboardMain';
import establishCoursesThunk from 'redux/actions/thunks/courses';
import { useAuth0 } from '@auth0/auth0-react';

const DashboardMain = () => {
    const [onboardingAvailable, setOnboardingAvailable] = useState(false);
    const { getAccessTokenSilently, logout } = useAuth0();
    const dispatch = useDispatch();
    const { id, userScore } = useSelector((state) => state.currentUser);
    const { areCoursesEstablished, areCoursesFetching, courses } = useSelector(
        (state) => state.courses
    );
    const { isLMSEnabled } = useSelector((state) => state.currentUser);

    useEffect(() => {
        if (!areCoursesEstablished && !areCoursesFetching) {
            if (isLMSEnabled) {
                dispatch(establishCoursesThunk(getAccessTokenSilently, logout));
            }
        }
    }, []);

    useEffect(() => {
        if (localStorage.getItem(`onboarding-${id}`) || userScore > 5) {
            setOnboardingAvailable(false);
        } else {
            localStorage.setItem(`onboarding-${id}`, true);
            setOnboardingAvailable(true);
        }
    }, []);

    useEffect(() => {
        if (onboardingAvailable) {
            setOnboardingAvailable(false);
            // TEMPORARY DISABLED UNTIL ITS UPDATED
            // history.push('/onboarding');
        }
    }, [onboardingAvailable]);

    return (
      <DashboardMainView
            coursesAreFetching={areCoursesFetching}
            courses={courses}
        />
    );
};

DashboardMain.propTypes = {
    courses: PropTypes.shape({
        areCoursesEstablished: PropTypes.bool,
        isCoursesError: PropTypes.bool,
        areCoursesFetching: PropTypes.bool,
        courses: PropTypes.arrayOf(PropTypes.any),
    }).isRequired,
};

export default DashboardMain;
