import React, { useState, useEffect } from 'react';
import useFetch from 'hooks/useFetch';
import TipOfTheWeekView from 'views/TipOfTheWeek';
import establishOrganizationSmartTipsThunk, {
    loadMoreOrganizationSmartTipsThunk,
    resetOrganizationSmartTipsThunk,
} from 'redux/actions/thunks/organizationSmartTips';
import { useDispatch, useSelector } from 'react-redux';
import useOffset from 'hooks/useOffset';
import apiRoutes from 'config/apiRoutes';
import {
    dispatchErrorAlertThunk,
    dispatchSuccessAlertThunk,
} from 'redux/actions/thunks/systemAlerts';
import { useAuth0 } from '@auth0/auth0-react';

const INFINITE_SCROLL_THRESHOLD = 20;

const TipOfTheWeek = () => {
    const { getAccessTokenSilently, logout } = useAuth0();
    const request = useFetch(getAccessTokenSilently, logout);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [tipOfTheWeek, setTipOfTheWeek] = useState(undefined);
    const [initialFilter, setInitialFilter] = useState('');
    const [filter, setFilter] = useState('');
    const [showSmartTipDropdown, setShowSmartTipDropdown] = useState(false);
    const [offset, incrementOffset, resetOffset] = useOffset(
        INFINITE_SCROLL_THRESHOLD
    );

    const {
        areOrganizationSmartTipsFetching,
        hasMoreOrganizationSmartTips,
        organizationSmartTips,
    } = useSelector((state) => state.organizationSmartTips);

    const handleSelect = (smartTip) => {
        setFilter(smartTip.data.name);
        setShowSmartTipDropdown(false);
        setTipOfTheWeek(smartTip.data);
    };

    const hasMore =
        hasMoreOrganizationSmartTips && !areOrganizationSmartTipsFetching;

    const loadMore = () => {
        dispatch(
            loadMoreOrganizationSmartTipsThunk(
                getAccessTokenSilently,
                logout,
                INFINITE_SCROLL_THRESHOLD,
                offset,
                { search: filter }
            )
        );
        incrementOffset();
    };

    const handleSave = () => {
        if (tipOfTheWeek) {
            request(
                apiRoutes.put.updateOrganizationSmartTip(tipOfTheWeek.id),
                'PUT',
                (res) => {
                    if (!res.error) {
                        dispatch(
                            dispatchSuccessAlertThunk(
                                'tipOfTheWeekChanged',
                                'Tip of the Week changed successfully'
                            )
                        );
                    }
                },
                (err) => {
                    dispatch(dispatchErrorAlertThunk(err, err.error));
                },
                {
                    body: JSON.stringify({
                        smart_tip: {
                            mentor_recommendation: !!filter,
                        },
                    }),
                },
                { 'Content-Type': 'application/json' }
            );
        }
    };

    useEffect(() => {
        dispatch(
            establishOrganizationSmartTipsThunk(
                getAccessTokenSilently,
                logout,
                INFINITE_SCROLL_THRESHOLD,
                0,
                {
                    search: filter,
                }
            )
        );
        resetOffset(INFINITE_SCROLL_THRESHOLD);

        return dispatch(resetOrganizationSmartTipsThunk());
    }, [filter]);

    useEffect(() => {
        if (filter.length > 0 && tipOfTheWeek && tipOfTheWeek.name !== filter) {
            setShowSmartTipDropdown(true);
        }
    }, [filter]);

    useEffect(() => {
        if (filter === initialFilter) {
            setShowSmartTipDropdown(false);
        }
    }, [filter, initialFilter]);

    useEffect(() => {
        setLoading(true);
        request(
            apiRoutes.get.smartTipsToTW,
            'GET',
            (res) => {
                setLoading(false);
                if (res.smart_tips.length !== 0) {
                    setTipOfTheWeek(res.smart_tips[0]);
                    setFilter(res.smart_tips[0].name);
                    setInitialFilter(res.smart_tips[0].name);
                }
            },
            (err) => {
                setLoading(false);
                return err;
            },
            {},
            { 'Content-Type': 'application/json' }
        );
    }, []);

    const isButtonDisabled = tipOfTheWeek === undefined;

    return (
        <TipOfTheWeekView
            handleSelect={handleSelect}
            filter={filter}
            setFilter={setFilter}
            tipOfTheWeek={tipOfTheWeek}
            organizationSmartTips={organizationSmartTips}
            isButtonDisabled={isButtonDisabled}
            isFetching={loading}
            hasMore={hasMore}
            loadMore={loadMore}
            handleSave={handleSave}
            showSmartTipDropdown={showSmartTipDropdown}
            setShowSmartTipDropdown={setShowSmartTipDropdown}
        />
    );
};

export default TipOfTheWeek;
