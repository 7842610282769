import styled, { css } from 'styled-components/macro';

const AvatarPlaceholder = styled.span`
    width: 100%;
    height: 100%;
    min-height: 24px;
    min-width: 24px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.color.bgPrimaryLight};
    display: flex !important;
    align-items: center;
    justify-content: center;

    ${({ image }) =>
        image &&
        css`
            background-image: url(${image});
            background-size: cover;
            background-position: center center;
        `}

    ${({ width }) =>
        width &&
        css`
            width: ${width};
        `};
    ${({ height }) =>
        height &&
        css`
            height: ${height};
        `};

    ${({ customStyles }) =>
        css`
            ${customStyles}
        `};
`;

export default AvatarPlaceholder;
