import styled, { css } from 'styled-components/macro';

export const AdminSection = styled.div`
    width: 100%;

    ${({ withPadding }) =>
        withPadding &&
        css`
            padding: 0 16px;
        `}

    ${({ withSpacing }) =>
        withSpacing &&
        css`
            margin: 16px 0;
        `}
`;

export const SmartTipsWrapper = styled.section`
    width: 100%;
    flex-direction: column;
    padding: 0px 16px;
    display: flex;
    justify-content: space-between;

    @media (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
        padding: 0 16px 0 0;
        overflow-x: scroll;

        > div,
        > span {
            width: fit-content;
            min-width: 700px;
        }
    }
`;

export const SectionHeaderCta = styled.div`
    display: flex;

    button {
        margin-left: 16px;
    }
`;

export const SmartTipsTableHeader = styled.span`
    display: grid;
    grid-template-columns: 4fr 3fr 2fr 2fr 3fr 1fr 1fr;
    grid-gap: 16px;
    align-items: center;
    width: 100%;
    padding: 16px 32px;
    color: ${({ theme }) => theme.color.textLight};
`;

export const Filters = styled.div`
    padding: 16px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    > * {
        width: auto;
        max-width: 210px;
        margin-right: 16px;
    }

    @media (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
        flex-direction: column;
        align-items: flex-start;
    }
`;

export const HeaderColumn = styled.div`
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    color: ${({ theme }) => theme.color.textLight};
`;
