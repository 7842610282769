import {
    ESTABLISH_ORGANIZATION_SMART_TIP_DETAILS,
    ESTABLISH_ORGANIZATION_SMART_TIP_DETAILS_SUCCESS,
    ESTABLISH_ORGANIZATION_SMART_TIP_DETAILS_FAILURE,
    ESTABLISH_ORGANIZATION_SMART_TIP_ASSIGNED_USERS,
    ESTABLISH_ORGANIZATION_SMART_TIP_ASSIGNED_USERS_SUCCESS,
    ESTABLISH_ORGANIZATION_SMART_TIP_ASSIGNED_USERS_FAILURE,
    ESTABLISH_ORGANIZATION_SMART_TIP_ASSIGNED_TEAMS,
    ESTABLISH_ORGANIZATION_SMART_TIP_ASSIGNED_TEAMS_SUCCESS,
    ESTABLISH_ORGANIZATION_SMART_TIP_ASSIGNED_TEAMS_FAILURE,
    RESET_ORGANIZATION_SMART_TIP_DETAILS,
} from 'redux/actions/organizationSmartTipDetails';

export const initialState = {
    areOrganizationSmartTipDetailsEstablished: false,
    isOrganizationSmartTipDetailsError: false,
    areOrganizationSmartTipDetailsFetching: false,
    error: null,
    details: {},
    assignedUsers: [],
    assignedTeams: [],
};

const organizationSmartTipDetails = (state = initialState, action) => {
    switch (action.type) {
        case ESTABLISH_ORGANIZATION_SMART_TIP_DETAILS:
            return {
                ...state,
                areOrganizationSmartTipDetailsFetching: true,
                isOrganizationSmartTipDetailsError: false,
            };

        case ESTABLISH_ORGANIZATION_SMART_TIP_DETAILS_SUCCESS:
            return {
                ...state,
                areOrganizationSmartTipDetailsFetching: false,
                areOrganizationSmartTipDetailsEstablished: true,
                details: action.payload,
            };

        case ESTABLISH_ORGANIZATION_SMART_TIP_DETAILS_FAILURE:
            return {
                ...state,
                areOrganizationSmartTipDetailsFetching: false,
                isOrganizationSmartTipDetailsError: true,
                error: action.err,
            };
        case ESTABLISH_ORGANIZATION_SMART_TIP_ASSIGNED_USERS:
        case ESTABLISH_ORGANIZATION_SMART_TIP_ASSIGNED_TEAMS:
            return {
                ...state,
                areOrganizationSmartTipDetailsFetching: true,
                isOrganizationSmartTipDetailsError: false,
            };

        case ESTABLISH_ORGANIZATION_SMART_TIP_ASSIGNED_USERS_SUCCESS:
            return {
                ...state,
                areOrganizationSmartTipDetailsFetching: false,
                areOrganizationSmartTipDetailsEstablished: true,
                assignedUsers: action.payload,
            };

        case ESTABLISH_ORGANIZATION_SMART_TIP_ASSIGNED_TEAMS_SUCCESS:
            return {
                ...state,
                areOrganizationSmartTipDetailsFetching: false,
                areOrganizationSmartTipDetailsEstablished: true,
                assignedTeams: action.payload,
            };

        case ESTABLISH_ORGANIZATION_SMART_TIP_ASSIGNED_USERS_FAILURE:
        case ESTABLISH_ORGANIZATION_SMART_TIP_ASSIGNED_TEAMS_FAILURE:
            return {
                ...state,
                areOrganizationSmartTipDetailsFetching: false,
                isOrganizationSmartTipDetailsError: true,
                error: action.err,
            };

        case RESET_ORGANIZATION_SMART_TIP_DETAILS:
            return { ...initialState, details: { ...state.details } };

        default:
            return state;
    }
};

export default organizationSmartTipDetails;
