import styled from 'styled-components/macro';

export const Wrapper = styled.div`
    padding: 0 16px;
`;

export const ListWrapper = styled.div`
    overflow-y: auto;
    height: 100%;
`;
