import { combineReducers } from 'redux';
import currentUser from './currentUser';
import courses from './courses';
import smartTips from './smartTips';
import smartTipDetails from './smartTipDetails';
import reminders from './reminders';
import team from './team';
import users from './users';
import teamMembers from './teamMembers';
import teamOverview from './teamOverview';
import teamRanking from './teamRanking';
import metricSources from './metricSources';
import metricUploads from './metricUploads';
import metrics from './metrics';
import currentMetricDetails from './currentMetricDetails';
import systemAlerts from './systemAlerts';
import notifications from './notifications';
import adminMemos from './adminMemos';
import usersUploads from './usersUploads';
import customers from './customers';
import tenantOrganization from './tenantOrganization';

import organizationSmartTipDetails from './organizationSmartTipDetails';
import organizationSmartTipEvaluationList from './organizationSmartTipEvaluationList';
import organizationUsers from './organizationUsers';
import organizationUserDetails from './organizationUserDetails';
import organizationUserStats from './organizationUserStats';
import organizationTeams from './organizationTeams';
import organizationSmartTips from './organizationSmartTips';
import organizationUsersModal from './organizationUsersModal';
import organizationAssignSmartTip from './organizationAssignSmartTip';

import mpaSuperAdminUsers from './mpaSuperAdminUsers';
import mpaAdminMemos from './mpaAdminMemos';
import mpaMetricSources from './mpaMetricSources';
import mpaDataSourcesMeasuresUploads from './mpaDataSourcesMeasuresUploads';

const rootReducer = combineReducers({
    currentUser,
    courses,
    smartTips,
    smartTipDetails,
    reminders,
    team,
    users,
    teamMembers,
    teamOverview,
    teamRanking,
    metricSources,
    metrics,
    metricUploads,
    currentMetricDetails,
    systemAlerts,
    notifications,
    adminMemos,
    usersUploads,
    customers,
    tenantOrganization,

    organizationSmartTips,
    organizationSmartTipDetails,
    organizationSmartTipEvaluationList,
    organizationUsers,
    organizationUserDetails,
    organizationUserStats,
    organizationTeams,
    organizationUsersModal,
    organizationAssignSmartTip,

    mpaSuperAdminUsers,
    mpaAdminMemos,
    mpaMetricSources,
    mpaDataSourcesMeasuresUploads,
});

export default rootReducer;
