import styled, { css } from 'styled-components/macro';

export const SmartTipsSectionWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

export const EmptySmartTipsSectionWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    svg {
        margin: 16px;
    }
`;

export const EmptyPageTitle = styled.h3`
    font-size: ${({ theme }) => theme.fontSize.sectionHeading};
    color: ${({ theme }) => theme.color.base};
    text-align: center;
    margin: 16px;
    font-weight: 500;
`;

export const EmptyPageSubtitle = styled.span`
    display: inline-block;
    max-width: 470px;
    font-size: ${({ theme }) => theme.fontSize.small};
    color: ${({ theme }) => theme.color.textLight};
    text-align: center;
    margin: 16px;
`;

export const EmptyButtonGroup = styled.div`
    margin: 16px 0;

    button {
        margin: 8px;
    }
`;

export const NoResultsWrapper = styled.div`
    margin: 16px;
    padding: 16px;
    display: flex;
    align-items: center;
    height: 256px;
    background-color: ${({ theme }) => theme.color.bgGrey};
    border-radius: ${({ theme }) => theme.border.radiusBase};

    ${({ fullHeight }) =>
        fullHeight &&
        css`
            height: calc(100vh - 300px);
        `}
`;

export const ArchivedSTWrapper = styled.div`
    display: flex;
    flex-flow: row wrap;

    @media (max-width: ${({ theme }) => `${theme.breakpoints.mobile}px`}) {
        flex-flow: column;
    }
`;
