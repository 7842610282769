export const ESTABLISH_CURRENT_USER = 'ESTABLISH_CURRENT_USER';
export const ESTABLISH_CURRENT_USER_SUCCESS = 'ESTABLISH_CURRENT_USER_SUCCESS';
export const ESTABLISH_CURRENT_USER_FAILURE = 'ESTABLISH_CURRENT_USER_FAILURE';
export const ESTABLISH_USERS = 'ESTABLISH_USERS';
export const ESTABLISH_USERS_SUCCESS = 'ESTABLISH_USERS_SUCCESS';
export const ESTABLISH_USERS_FAILURE = 'ESTABLISH_USERS_FAILURE';
export const FETCH_RECENT_USERS = 'FETCH_RECENT_USERS';
export const FETCH_RECENT_USERS_SUCCESS = 'FETCH_RECENT_USERS_SUCCESS';
export const FETCH_RECENT_USERS_FAILURE = 'FETCH_RECENT_USERS_FAILURE';
export const LOAD_MORE_USERS = 'LOAD_MORE_USERS';
export const MARK_ALL_USERS_LOADED = 'MARK_ALL_USERS_LOADED';
export const RESET_USERS = 'RESET_USERS';

export const establishCurrentUser = () => ({
    type: ESTABLISH_CURRENT_USER,
});

export const establishCurrentUserSuccess = (payload) => ({
    type: ESTABLISH_CURRENT_USER_SUCCESS,
    payload,
});

export const establishCurrentUserFailure = (err) => ({
    type: ESTABLISH_CURRENT_USER_FAILURE,
    err,
});

export const establishUsers = () => ({
    type: ESTABLISH_USERS,
});

export const establishUsersSuccess = (payload) => ({
    type: ESTABLISH_USERS_SUCCESS,
    payload,
});

export const establishUsersFailure = (err) => ({
    type: ESTABLISH_USERS_FAILURE,
    err,
});

export const fetchRecentUsers = () => ({
    type: FETCH_RECENT_USERS,
});

export const fetchRecentUsersSuccess = (payload) => ({
    type: FETCH_RECENT_USERS_SUCCESS,
    payload,
});

export const fetchRecentUsersFailure = (err) => ({
    type: FETCH_RECENT_USERS_FAILURE,
    err,
});

export const loadMoreUsers = (payload) => ({
    type: LOAD_MORE_USERS,
    payload,
});

export const allUsersLoaded = () => ({
    type: MARK_ALL_USERS_LOADED,
});

export const resetUsers = () => ({
    type: RESET_USERS,
});
