import React from 'react';
import { ReactComponent as HourGlass } from 'assets/svg/no-results/hour-glass.svg';
import {
    ChartWrapper,
    EmptyStateWrapper,
    TextWrapper,
    Title,
    Paragraph,
    Grid,
} from './ChartEmptyState.style';

const ChartEmptyState = () => (
  <ChartWrapper>
    <EmptyStateWrapper>
      <Grid>
        <HourGlass />
        <TextWrapper>
          <Title>No performance data reported</Title>
          <Paragraph>
            Your organization can use this application to share
            individual performance metrics.
          </Paragraph>
        </TextWrapper>
      </Grid>
    </EmptyStateWrapper>
  </ChartWrapper>
);

export default ChartEmptyState;
