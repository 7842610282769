import React from 'react';
import PropTypes from 'prop-types';
import {
    AreaChart,
    Area,
    XAxis,
    YAxis,
    ResponsiveContainer,
    Tooltip,
    ReferenceDot,
    ReferenceLine,
} from 'recharts';
import useDate from 'hooks/useDate';
import theme from 'assets/theme/mainTheme';
import Spinner from 'components/Spinner';
import useAdmin from 'hooks/useAdmin';
import ChartEmptyState from 'components/EmptyState/ChartEmptyState/ChartEmptyState';
import SourceChooser from './SourceChooser';
import Legend from './Legend';
import { ChartWrapper } from './RelativePerformance.style';

const CustomTooltip = () => null;

const RelativePerformance = ({
    selectedMetricSource,
    metricSources,
    setSelectedMetricSource,
    currentScores,
    loading,
    data,
    handleSetCurrentScores,
}) => {
    const isAdmin = useAdmin();

    const isDoubleChart =
        currentScores !== undefined &&
        currentScores.score !== undefined &&
        currentScores.providerScore !== undefined;

    const DateFormatter = (date) => useDate(new Date(date));

    if (metricSources.length === 0) {
        return (
          <ChartWrapper>
            <ChartEmptyState />
          </ChartWrapper>
        );
    }

    return (
      <ChartWrapper>
        <SourceChooser
                selectedMetricSource={selectedMetricSource}
                metricSources={metricSources}
                setSelectedMetricSource={setSelectedMetricSource}
            />

        {data.length !== 0 && (
        <Legend
                    isSingleResult={data.length === 1}
                    isAdmin={isAdmin}
                    isDoubleChart={isDoubleChart}
                    currentScores={currentScores}
                />
            )}
        {loading && <Spinner />}
        {data.length === 0 && <ChartEmptyState />}
        {data.length > 1 && (
        <ResponsiveContainer height={200}>
          <AreaChart
                        data={data}
                        onMouseMove={(e) => {
                            if (e.isTooltipActive && e.activePayload) {
                                handleSetCurrentScores(
                                    e.activePayload[0].payload.score,
                                    e.activePayload[0].payload.providerScore,
                                    e.activePayload[0].payload.date,
                                    e.activePayload[0].payload
                                        .providerScoreLabel
                                );
                            }
                        }}
                    >
            <defs>
              <linearGradient
                                id="areaColor"
                                x1="0"
                                y1="0"
                                x2="0"
                                y2="1"
                            >
                <stop
                                    offset="20%"
                                    stopColor={theme.color.primaryLight}
                                    stopOpacity={1}
                                />
                <stop
                                    offset="100%"
                                    stopColor={theme.color.white}
                                    stopOpacity={1}
                                />
              </linearGradient>
              <linearGradient
                                id="areaColor2"
                                x1="0"
                                y1="0"
                                x2="0"
                                y2="1"
                            >
                <stop
                                    offset="20%"
                                    stopColor={theme.color.goldLight}
                                    stopOpacity={1}
                                />
                <stop
                                    offset="100%"
                                    stopColor={theme.color.white}
                                    stopOpacity={1}
                                />
              </linearGradient>
            </defs>
            <YAxis domain={[-10, 110]} hide />
            <XAxis
                            interval="preserveStartEnd"
                            dataKey="date"
                            axisLine={false}
                            tickLine={false}
                            scale="time"
                            type="number"
                            tickFormatter={DateFormatter}
                            domain={[data[0].date, data[data.length - 1].date]}
                            ticks={data.map((item) => item.date)}
                            tick={{
                                fontSize: '11px',
                                fontWeight: 500,
                                lineHeight: '10px',
                            }}
                        />

            <Area
                            type="monotone"
                            dataKey="providerScore"
                            stroke={theme.color.bgPrimary}
                            strokeWidth={3}
                            fill="url(#areaColor)"
                            dot={false}
                            activeDot={false}
                        />

            {isDoubleChart ? (
              <Area
                                type="monotone"
                                dataKey="score"
                                stroke={theme.color.gold}
                                strokeWidth={3}
                                fill="none"
                                dot={false}
                                activeDot={false}
                            />
                        ) : (
                          <Area
                                type="monotone"
                                dataKey="score"
                                stroke={theme.color.gold}
                                strokeWidth={3}
                                fill="url(#areaColor2)"
                                dot={false}
                                activeDot={false}
                            />
                        )}

            <Tooltip content={<CustomTooltip />} />

            <ReferenceLine
                            x={currentScores.date}
                            stroke={theme.color.grey}
                        />

            {currentScores.providerScore ? (
              <ReferenceDot
                                x={currentScores.date}
                                y={currentScores.providerScore}
                                r={7}
                                stroke={theme.color.textBase}
                                fill={theme.color.white}
                                strokeWidth={5}
                            />
                        ) : (
                          <ReferenceDot
                                x={currentScores.date}
                                y={currentScores.score}
                                r={7}
                                stroke={theme.color.textBase}
                                fill={theme.color.white}
                                strokeWidth={5}
                            />
                        )}
          </AreaChart>
        </ResponsiveContainer>
            )}
      </ChartWrapper>
    );
};

RelativePerformance.propTypes = {
    selectedMetricSource: PropTypes.objectOf(PropTypes.any),
    metricSources: PropTypes.arrayOf(PropTypes.any),
    setSelectedMetricSource: PropTypes.func.isRequired,
    currentScores: PropTypes.objectOf(PropTypes.any),
    loading: PropTypes.bool.isRequired,
    data: PropTypes.arrayOf(PropTypes.any),
    handleSetCurrentScores: PropTypes.func.isRequired,
};

RelativePerformance.defaultProps = {
    selectedMetricSource: undefined,
    metricSources: [],
    currentScores: undefined,
    data: [],
};

export default RelativePerformance;
