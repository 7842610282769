import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import useTeamCreator from 'hooks/useTeamCreator';
import ConfirmActionModal from 'components/ConfirmActionModal';
import { ReactComponent as SettingsIcon } from 'assets/svg/settings.svg';
import { ROUTES } from 'routing/constants';
import useAdmin from 'hooks/useAdmin';
import {
    TeamOverviewNavigationWrapper,
    TeamOverviewNavigationItems,
    TeamOverviewItem,
    SettingsDropdown,
    StyledSettingsButton,
    DropdownWrapper,
    IconWrapper,
} from './TeamOverviewNavigation.style';

const TeamOverviewNavigation = ({
    teamId,
    activeSection,
    onLeave,
    onCreatorLeave,
    onTeamDelete,
    isCreator,
}) => {
    const [isSettingsOpen, setIsSettingsOpen] = useState(false);
    const [
        isConfirmLeaveModalVisible,
        setIsConfirmLeaveModalVisible,
    ] = useState(false);
    const [
        isConfirmDeleteModalVisible,
        setIsConfirmDeleteModalVisible,
    ] = useState(false);
    const dropdownRef = useRef(null);

    const isAdmin = useAdmin();

    const { isTeamCreator, usersCount } = useTeamCreator(teamId);

    const handleClickOutside = (e) => {
        if (dropdownRef.current && dropdownRef.current.contains(e.target)) {
            return;
        }
        setIsSettingsOpen(false);
    };

    useEffect(() => {
        if (isSettingsOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isSettingsOpen]);

    return (
      <TeamOverviewNavigationWrapper>
        {isConfirmLeaveModalVisible && (
        <ConfirmActionModal
                    isVisible={isConfirmLeaveModalVisible}
                    onToggle={() => {
                        setIsConfirmLeaveModalVisible(
                            !isConfirmLeaveModalVisible
                        );
                    }}
                    onConfirm={() => {
                        if (isTeamCreator && usersCount === 1) {
                            onCreatorLeave();
                        } else if (!isTeamCreator) {
                            onLeave();
                        }
                        setIsSettingsOpen(false);
                    }}
                    description="Are you sure you want to leave this team?"
                    confirmLabel="Leave team"
                />
            )}
        {isConfirmDeleteModalVisible && (
        <ConfirmActionModal
                    isVisible={isConfirmDeleteModalVisible}
                    onToggle={() => {
                        setIsConfirmDeleteModalVisible(
                            !isConfirmDeleteModalVisible
                        );
                    }}
                    onConfirm={() => {
                        if (isAdmin) {
                            onTeamDelete(teamId);
                        }
                        setIsSettingsOpen(false);
                    }}
                    description="Are you sure you want to delete this team?"
                    confirmLabel="Delete team"
                />
            )}
        <TeamOverviewNavigationItems>
          <Link to={`${ROUTES.teamOverview}/${teamId}`}>
            <TeamOverviewItem isActive={activeSection === 'overview'}>
              Overview
            </TeamOverviewItem>
          </Link>
          <Link to={`${ROUTES.teamMembers}/${teamId}`}>
            <TeamOverviewItem isActive={activeSection === 'members'}>
              Members
            </TeamOverviewItem>
          </Link>
          <Link to={`${ROUTES.teamRanking}/${teamId}`}>
            <TeamOverviewItem isActive={activeSection === 'ranking'}>
              Ranking
            </TeamOverviewItem>
          </Link>
        </TeamOverviewNavigationItems>

        {!isCreator && !isAdmin && (
        <DropdownWrapper ref={dropdownRef}>
          <IconWrapper
                        withBackground={isSettingsOpen}
                        onClick={() => {
                            setIsSettingsOpen(!isSettingsOpen);
                        }}
                    >
            <SettingsIcon />
          </IconWrapper>
          {isSettingsOpen && (
          <SettingsDropdown>
            <StyledSettingsButton
                                quaternary
                                transparent
                                borderless
                                data-text="You can't leave the team as its creator."
                                disabled={isTeamCreator && usersCount !== 1}
                                onClick={() => {
                                    setIsConfirmLeaveModalVisible(true);
                                }}
                            >
              Leave team
            </StyledSettingsButton>
          </SettingsDropdown>
                    )}
        </DropdownWrapper>
            )}
        {isAdmin && (
        <DropdownWrapper ref={dropdownRef}>
          <IconWrapper
                        withBackground={isSettingsOpen}
                        onClick={() => {
                            setIsSettingsOpen(!isSettingsOpen);
                        }}
                    >
            <SettingsIcon />
          </IconWrapper>
          {isSettingsOpen && (
          <SettingsDropdown>
            <StyledSettingsButton
                                quaternary
                                transparent
                                borderless
                                data-text=""
                                onClick={() => {
                                    setIsConfirmDeleteModalVisible(true);
                                }}
                            >
              Delete team
            </StyledSettingsButton>
          </SettingsDropdown>
                    )}
        </DropdownWrapper>
            )}
      </TeamOverviewNavigationWrapper>
    );
};

TeamOverviewNavigation.propTypes = {
    teamId: PropTypes.string.isRequired,
    isCreator: PropTypes.bool.isRequired,
    activeSection: PropTypes.string.isRequired,
    onLeave: PropTypes.func.isRequired,
    onTeamDelete: PropTypes.func.isRequired,
    onCreatorLeave: PropTypes.func.isRequired,
};

export default TeamOverviewNavigation;
