import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components/macro';
import scrollToTop from 'utilites/scrollToTop';
import { ReactComponent as ArrowTopIcon } from 'assets/svg/arrow-top.svg';

const Root = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
`;

const Button = styled.div`
    ${({ theme }) => css`
        position: relative;
        background-color: ${theme.color.primary};
        width: 48px;
        height: 48px;
        border-radius: 50%;
        cursor: pointer;
    `}
`;
const StyledArrowTopIcon = styled(ArrowTopIcon)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

const BackToTopButton = () => {
    const [isVisible, setIsVisible] = useState(false);

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;

        if (scrolled > 300) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisible);
        return () => window.removeEventListener('scroll', toggleVisible);
    }, []);

    return (
      <Root>
        {isVisible && (
        <Button onClick={scrollToTop}>
          <StyledArrowTopIcon />
        </Button>
            )}
      </Root>
    );
};

export default BackToTopButton;
