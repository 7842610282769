import styled, { css } from 'styled-components/macro';

export const InputWrapper = styled.div`
    display: grid;
    grid-template-columns: 12fr 1fr;
    grid-gap: 8px;
    border: ${({ theme }) => theme.border.base};
    border-radius: ${({ theme }) => theme.border.radiusSmall};

    overflow: hidden;
    align-items: center;

    ${({ invalid }) =>
        invalid &&
        css`
            border-color: ${({ theme }) => theme.color.quaternary};
        `}

    ${({ fixedWidth }) =>
        fixedWidth &&
        css`
            max-width: 380px;
        `};

    ${({ withMargin }) =>
        withMargin &&
        css`
            margin-bottom: 16px;
        `};
`;

export const StyledInput = styled.input`
    padding: 16px 0 16px 16px;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: ${({ theme }) => theme.color.textBase};
    border: none;
`;

export const CounterWrapper = styled.div`
    padding-right: 8px;
    text-align: right;
    font-size: 12px;
    font-weight: 400;
    line-height: 21px;
    color: ${({ theme }) => theme.color.textLight};
`;
