import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import MPACustomerDetailsView from 'views/MPACustomerDetails';
import apiRoutes from 'config/apiRoutes';
import MPA_ORGANIZATION_SLUG from 'config/mpaAccessSlug';
import useFetch from 'hooks/useFetch';
import {
    dispatchErrorAlertThunk,
    dispatchSuccessAlertThunk,
} from 'redux/actions/thunks/systemAlerts';
import { MPA_ROUTES } from 'routing/constants';
import { useAuth0 } from '@auth0/auth0-react';

const MPACustomerDetails = () => {
    const { getAccessTokenSilently, logout } = useAuth0();
    const history = useHistory();
    const dispatch = useDispatch();
    const request = useFetch(getAccessTokenSilently, logout);
    const { customerId } = useParams();
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [
        isConfirmationModalVisible,
        setIsConfirmationModalVisible,
    ] = useState(false);
    const [customerData, setCustomerData] = useState({
        error: '',
        dataProvided: false,
        name: '',
        avatar_url: null,
        background_color: '',
        api_url: '',
        api_key: '',
        country: null,
        zipcode: null,
        city: null,
        state: null,
        street: null,
        contact_fullname: null,
        contact_mobile: null,
        contact_email: null,
    });

    const toggleConfirmationModalVisible = () => {
        setIsConfirmationModalVisible(!isConfirmationModalVisible);
    };

    const toggleDropdownVisible = () => {
        setIsDropdownVisible(!isDropdownVisible);
    };

    const handleConfirmSuccess = (res) => {
        if (!res.error) {
            dispatch(
                dispatchSuccessAlertThunk(
                    'archiveCustomer',
                    customerData.name
                        ? `You successfully archived client ${customerData.name}`
                        : 'You successfully archived client'
                )
            );
            history.push(MPA_ROUTES.customers);
        }
    };

    const handleConfirmError = (err) => {
        dispatch(dispatchErrorAlertThunk(err, err.error));
    };

    const handleConfirm = () => {
        request(
            apiRoutes.put.editCustomerDetails(customerId),
            'PUT',
            handleConfirmSuccess,
            handleConfirmError,
            {
                body: JSON.stringify({
                    organization: {
                        enabled: false,
                    },
                }),
            },
            { 'Content-Type': 'application/json' }
        );
    };

    useEffect(() => {
        getAccessTokenSilently()
            .then((token) => {
                fetch(apiRoutes.get.customerDetails(customerId), {
                    method: 'GET',
                    headers: {
                        organization_slug: MPA_ORGANIZATION_SLUG,
                        Authorization: `Token token="${token}"`,
                        'Content-Type': 'application/json',
                    },
                })
                    .then((res) => res.json())
                    .then((res) => {
                        if (!res.error) {
                            setCustomerData({
                                ...res,
                                avatarUrl: res.avatar_url,
                                backgroundColor: res.background_color,
                                apiKey: res.api_key,
                                apiUrl: res.api_url,
                                contactEmail: res.contact_email,
                                contactFullname: res.contact_fullname,
                                contactMobile: res.contact_mobile,
                                dataProvided: true,
                            });
                        }

                        return null;
                    })
                    .catch((err) =>
                        setCustomerData({ ...customerData, error: err })
                    );
            })
            .catch(() => logout({ returnTo: window.location.origin }));
    }, [customerId]);

    return (
      <MPACustomerDetailsView
            isDropdownVisible={isDropdownVisible}
            toggleDropdownVisible={toggleDropdownVisible}
            isConfirmationModalVisible={isConfirmationModalVisible}
            toggleConfirmationModalVisible={toggleConfirmationModalVisible}
            handleConfirm={handleConfirm}
            {...customerData}
        />
    );
};

export default MPACustomerDetails;
