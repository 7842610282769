import React from 'react';
import PropTypes from 'prop-types';
import { useAuth0 } from '@auth0/auth0-react';
import Button from 'components/Button';

const AuthButton = ({ canLogIn, ...rest }) => {
    const {
        loginWithRedirect,
        isAuthenticated,
        isLoading,
        logout,
    } = useAuth0();

    const isDisabled = () => {
        if (isLoading) {
            return true;
        }

        if (canLogIn === false && canLogIn !== undefined) {
            return true;
        }

        return false;
    };

    if (!isAuthenticated) {
        return (
          <Button
                primaryDark
                onClick={loginWithRedirect}
                disabled={isDisabled()}
                {...rest}
            >
            Sign in
          </Button>
        );
    }

    if (isAuthenticated && document.location.href.includes('/login')) {
        window.location = '/dashboard';
    }

    return (
      <Button
            quaternary
            onClick={() => logout({ returnTo: window.location.origin })}
            disabled={isDisabled()}
            {...rest}
        >
        Log Out
      </Button>
    );
};

AuthButton.propTypes = {
    canLogIn: PropTypes.bool,
};

AuthButton.defaultProps = {
    canLogIn: undefined,
};

export default AuthButton;
