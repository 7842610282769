import styled, { css } from 'styled-components/macro';

export const TimePickerWrapper = styled.div`
    position: relative;
    width: 100%;
`;

export const ContentWrapper = styled.div`
    cursor: pointer;
    height: 54px;
    width: 100%;
    padding: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: ${({ theme }) => theme.color.white};
    border: ${({ theme }) => theme.border.base};
    border-radius: ${({ theme }) => theme.border.radiusSmall};

    svg {
        fill: ${({ theme }) => theme.color.textBase};
        margin-right: 10px;
    }
`;

export const Time = styled.div`
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
`;

export const Dropdown = styled.div`
    position: absolute;
    width: auto;
    height: auto;
    z-index: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px;
    top: 63px;
    left: 0;
    background-color: ${({ theme }) => theme.color.white};
    border: ${({ theme }) => theme.border.base};
    border-radius: ${({ theme }) => theme.border.radiusSmall};
`;

export const TimeColumn = styled.div`
    height: 200px;
    width: 50px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    &::-webkit-scrollbar {
        display: none;
    }
`;

export const SingleValue = styled.div`
    max-width: 45px;
    min-width: 45px;
    max-height: 45px;
    min-height: 45px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    cursor: pointer;

    &:hover {
        background-color: ${({ theme }) => theme.color.bgPrimaryLight};
        color: ${({ theme }) => theme.color.primary};
    }

    ${({ isSelected }) =>
        isSelected &&
        css`
            background-color: ${({ theme }) => theme.color.primary};
            color: ${({ theme }) => theme.color.white};

            &:hover {
                background-color: ${({ theme }) => theme.color.primary};
                color: ${({ theme }) => theme.color.white};
            }
        `}
`;
