import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ArrowBackIcon } from 'assets/svg/arrow-back.svg';
import { ReactComponent as InfoIcon } from 'views/MPAEditCustomer/info-icon.svg';
import { useHistory } from 'react-router-dom';
import { css } from 'styled-components/macro';

import { MPA_ROUTES } from 'routing/constants';
import theme from 'assets/theme/mainTheme';
import SectionHeader from 'components/SectionHeader';
import Button from 'components/Button';
import Input from 'components/Input';
import Checkbox from 'components/Checkbox';
import { DatabaseInfo } from 'views/MPAEditCustomer/MPAEditCustomer.style';

import {
    ComponentWrapper,
    AddCustomerWrapper,
    Title,
    SectionItem,
    InputGroupWrapper,
    InputWrapper,
    BottomSectionWrapper,
} from './MPAAddNewCustomer.style';

const MPAAddNewCustomer = ({
    countries,
    isDropdownVisible,
    setIsDropdownVisible,
    ...rest
}) => {
    const history = useHistory();
    const {
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
    } = rest;

    const isButtonDisabled = () => {
        let result = false;

        if (Object.values(errors).length !== 0) {
            result = true;
        }

        return result;
    };

    return (
      <ComponentWrapper>
        <SectionHeader
                goBack={{
                    url: MPA_ROUTES.customers,
                    label() {
                        return (
                          <>
                            <ArrowBackIcon stroke={theme.color.textLabel} />
                            <div style={{ color: theme.color.primary }}>
                              Back
                            </div>
                          </>
                        );
                    },
                }}
                title="Add New Organization"
            />
        <AddCustomerWrapper>
          <SectionItem>
            <Title>Organization data</Title>
            <InputGroupWrapper gridRows="1fr 1fr 1fr">
              <Input
                            name="organizationName"
                            placeholder="Organization name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            defaultValue={values.organizationName}
                            error={
                                errors.organizationName &&
                                touched.organizationName
                                    ? errors.organizationName
                                    : null
                            }
                        />
              <Input
                            name="LMSApiKey"
                            placeholder="LMS API Key"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            defaultValue={values.LMSApiKey}
                            error={
                                errors.LMSApiKey && touched.LMSApiKey
                                    ? errors.LMSApiKey
                                    : null
                            }
                        />
              <Input
                            name="LMSAddress"
                            placeholder="LMS Address"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            defaultValue={values.LMSAddress}
                            error={
                                errors.LMSAddress && touched.LMSAddress
                                    ? errors.LMSAddress
                                    : null
                            }
                        />
              <Input
                            name="lmsOrgId"
                            placeholder="LMS Organization ID"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            defaultValue={values.lmsOrgId}
                            error={
                                errors.lmsOrgId && touched.lmsOrgId
                                    ? errors.lmsOrgId
                                    : null
                            }
                        />
            </InputGroupWrapper>
          </SectionItem>
          <SectionItem borderTop>
            <Title>Auth0 configuration</Title>
            <InputGroupWrapper>
              <div
                            onClick={() => {
                                if (values.use_auth0_connection) {
                                    setFieldValue('connectionName', undefined);
                                } else {
                                    setFieldValue(
                                        'auth0OrganizationName',
                                        undefined
                                    );
                                }

                                setFieldValue(
                                    'use_auth0_connection',
                                    !values.use_auth0_connection
                                );
                            }}
                            style={{
                                cursor: 'pointer',
                                marginBottom: '16px',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                <Checkbox
                                name="use_auth0_connection"
                                isSelected={values.use_auth0_connection}
                                toggleSelected={() =>
                                    setFieldValue(
                                        'use_auth0_connection',
                                        !values.use_auth0_connection
                                    )}
                                customStyles={css`
                                    margin-right: 8px;
                                `}
                            />
                {' '}
                <span>
                  Use a single Database or Enterprise connection
                  instead of an Organization.
                </span>
              </div>
              {values.use_auth0_connection ? (
                <>
                  <Input
                                    disabled={!values.use_auth0_connection}
                                    name="connectionName"
                                    placeholder="Enter name of existing Auth0 Database or Enterprise connection."
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.connectionName || ''}
                                    error={
                                        errors.connectionName &&
                                        touched.connectionName
                                            ? errors.connectionName
                                            : null
                                    }
                                />
                  <DatabaseInfo>
                    <InfoIcon />
                    {' '}
                    <span>
                      Leave blank to create Database
                      connection automatically from name.
                    </span>
                  </DatabaseInfo>
                </>
                        ) : (
                          <>
                            <Input
                                    name="auth0OrganizationName"
                                    placeholder="If not using default, enter name of existing Auth0 Organization."
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.auth0OrganizationName || ''}
                                    error={
                                        errors.auth0OrganizationName &&
                                        touched.auth0OrganizationName
                                            ? errors.auth0OrganizationName
                                            : null
                                    }
                                />
                            <DatabaseInfo>
                              <InfoIcon />
                              {' '}
                              <span>
                                Leave blank to create Organization and
                                Database automatically from name.
                              </span>
                            </DatabaseInfo>
                          </>
                        )}
            </InputGroupWrapper>
          </SectionItem>
          <SectionItem borderTop borderBottom>
            <Title>Address</Title>
            <InputGroupWrapper gridRows="1fr 1fr 1fr 1fr">
              <Input
                            name="country"
                            onBlur={handleBlur}
                            defaultValue={values.country}
                            dropdownTitle="Countries"
                            variant="searchDropdown"
                            placeholder="Country"
                            onClick={() =>
                                setIsDropdownVisible(!isDropdownVisible)}
                            dropdownVisible={isDropdownVisible}
                            options={countries}
                            handleSelect={(val) => {
                                setFieldValue(
                                    'country',
                                    countries.find(
                                        (country) => country.id === val
                                    ).title
                                );
                                setIsDropdownVisible(false);
                            }}
                            error={
                                errors.country && touched.country
                                    ? errors.country
                                    : null
                            }
                            readOnly
                        />

              <InputWrapper>
                <Input
                                name="zipCode"
                                placeholder="Zip Code"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                defaultValue={values.zipCode}
                                error={
                                    errors.zipCode && touched.zipCode
                                        ? errors.zipCode
                                        : null
                                }
                            />
              </InputWrapper>

              <InputWrapper>
                <Input
                                name="city"
                                placeholder="City"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                defaultValue={values.city}
                                error={
                                    errors.city && touched.city
                                        ? errors.city
                                        : null
                                }
                            />
                <Input
                                name="state"
                                placeholder="State"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                defaultValue={values.state}
                                error={
                                    errors.state && touched.state
                                        ? errors.state
                                        : null
                                }
                            />
              </InputWrapper>

              <Input
                            name="street"
                            placeholder="Street"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            defaultValue={values.street}
                            error={
                                errors.street && touched.street
                                    ? errors.street
                                    : null
                            }
                        />
            </InputGroupWrapper>
          </SectionItem>
          <SectionItem>
            <Title>Contact</Title>
            <InputGroupWrapper gridRows="1fr 1fr 1fr">
              <Input
                            name="fullName"
                            placeholder="Full Name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            defaultValue={values.fullName}
                            error={
                                errors.fullName && touched.fullName
                                    ? errors.fullName
                                    : null
                            }
                        />
              <Input
                            name="mobile"
                            placeholder="Mobile"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            defaultValue={values.mobile}
                            error={
                                errors.mobile && touched.mobile
                                    ? errors.mobile
                                    : null
                            }
                        />
              <Input
                            name="emailAddress"
                            placeholder="Email address"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            defaultValue={values.emailAddress}
                            error={
                                errors.emailAddress && touched.emailAddress
                                    ? errors.emailAddress
                                    : null
                            }
                        />
            </InputGroupWrapper>
            <BottomSectionWrapper>
              <Button
                            primaryDark
                            type="submit"
                            onClick={handleSubmit}
                            disabled={isButtonDisabled()}
                        >
                Save
              </Button>
              <Button
                            primary
                            onClick={() => history.push(MPA_ROUTES.customers)}
                        >
                Cancel
              </Button>
            </BottomSectionWrapper>
          </SectionItem>
        </AddCustomerWrapper>
      </ComponentWrapper>
    );
};

MPAAddNewCustomer.propTypes = {
    countries: PropTypes.arrayOf(PropTypes.any),
    isDropdownVisible: PropTypes.bool.isRequired,
    setIsDropdownVisible: PropTypes.func.isRequired,
};

MPAAddNewCustomer.defaultProps = { countries: [] };

export default MPAAddNewCustomer;
