const useFetch = (getAccessTokenSilently, logout) => (
    route,
    method,
    successHandler,
    errorHandler,
    settings,
    headers,
    noJson,
    noAuth
) =>
    getAccessTokenSilently()
        .then((token) => {
            const allHeaders = {
                organization_slug: window.localStorage.getItem(
                    'MP_ORGANIZATION'
                ),
                ...headers,
            };

            if (!noAuth) {
                allHeaders.Authorization = `Token token="${token}"`;
            }

            fetch(route, {
                method,
                headers: allHeaders,
                ...settings,
            })
                .then((res) => (noJson ? res : res.json()))
                .then((res) => {
                    if (noJson) {
                        if (res.status !== 200 && res.status !== 201) {
                            errorHandler(res);
                        } else {
                            successHandler(res);
                        }
                    } else {
                        if (res.error) {
                            errorHandler(res);
                        }

                        successHandler(res);
                    }
                })
                .catch((err) => errorHandler(err));
        })
        .catch(() => logout({ returnTo: window.location.origin }));

export default useFetch;
