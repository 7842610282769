import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/Modal';
import Input from 'components/Input';
import Switch from 'components/Switch';
import Button from 'components/Button';
import Spinner from 'components/Spinner';
import { css } from 'styled-components/macro';
import { ReactComponent as ExclamationMark } from 'assets/svg/exclamation-mark.svg';
import DisableForUsersInput from './DisableForUsersInput';
import {
    InputSection,
    InputHolder,
    SwitchStatusSection,
    Footer,
    WarningSection,
    ModalWrapper,
} from './EditMetricModal.style';
import LinkSmartTipInput from './LinkSmartTipInput';

const EditMetricModal = ({
    hasEstablishedData,
    toggleVisible,
    metricName,
    metricSourceName,
    name,
    goal,
    description,
    status,
    users,
    smartTips,
    onGeneralSubmit,
}) => {
    if (!hasEstablishedData) {
        return (
          <Modal
                absolute
                fixed
                closeModal={toggleVisible}
                customStyles={css`
                    width: 650px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                `}
            >
            <Spinner />
          </Modal>
        );
    }
    return (
      <Modal
            absolute
            fixed
            headerText={`Edit [${metricSourceName} / ${metricName}]`}
            closeModal={toggleVisible}
            customStyles={css`
                width: 650px;
            `}
            modalFooter={() => (
              <Footer>
                <Button primary onClick={toggleVisible}>
                  Cancel
                </Button>
                <Button
                        primaryDark
                        onClick={() => {
                            onGeneralSubmit();
                            toggleVisible();
                        }}
                    >
                  Save
                </Button>
              </Footer>
            )}
        >
        <ModalWrapper>
          <WarningSection>
            <ExclamationMark />
            <span>
              <strong>Warning:</strong>
              {' '}
              Changing Metric Status
              triggers a score recalculation for all affected
              Organizations. It can take several minutes.
            </span>
          </WarningSection>
          <InputSection>
            Metric name
            <InputHolder>
              <Input
                            disabled
                            case="base"
                            value={name.value}
                            onChange={({ target }) =>
                                name.setFunc(target.value)}
                        />
            </InputHolder>
          </InputSection>

          <InputSection>
            Metric description
            <InputHolder>
              <Input
                            disabled
                            case="base"
                            value={description.value}
                            onChange={({ target }) =>
                                description.setFunc(target.value)}
                        />
            </InputHolder>
          </InputSection>

          <InputSection>
            Goal
            <InputHolder>
              <Input
                            case="base"
                            type="number"
                            value={goal.value}
                            onChange={({ target }) => {
                                if (target.value >= 0 && target.value <= 100) {
                                    goal.setFunc(target.value);
                                }
                            }}
                        />
            </InputHolder>
          </InputSection>

          <InputSection>
            Linked Smart Tip
            <InputHolder>
              <LinkSmartTipInput {...smartTips} />
            </InputHolder>
          </InputSection>

          <SwitchStatusSection>
            Status
            <Switch
                        defaultStatus={status.value}
                        switchOn={() => status.setFunc(true)}
                        switchOff={() => status.setFunc(false)}
                        switchId={`${metricName}-edit-modal-status-switch`}
                    />
          </SwitchStatusSection>

          <DisableForUsersInput {...users} />
        </ModalWrapper>
      </Modal>
    );
};

EditMetricModal.propTypes = {
    hasEstablishedData: PropTypes.bool.isRequired,
    toggleVisible: PropTypes.func.isRequired,
    metricName: PropTypes.string.isRequired,
    metricSourceName: PropTypes.string.isRequired,
    onGeneralSubmit: PropTypes.func.isRequired,

    name: PropTypes.shape({
        value: PropTypes.string,
        setFunc: PropTypes.func,
    }).isRequired,

    description: PropTypes.shape({
        value: PropTypes.string,
        setFunc: PropTypes.func,
    }).isRequired,

    goal: PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        setFunc: PropTypes.func,
    }).isRequired,

    status: PropTypes.shape({
        value: PropTypes.bool,
        setFunc: PropTypes.func,
    }).isRequired,

    users: PropTypes.shape({
        list: PropTypes.arrayOf(PropTypes.any).isRequired,
        setSubmitted: PropTypes.func.isRequired,
        defaultValue: PropTypes.arrayOf(PropTypes.any).isRequired,
        isFetching: PropTypes.bool.isRequired,
        loadMore: PropTypes.func.isRequired,
        hasMore: PropTypes.bool.isRequired,
        filterFunc: PropTypes.func.isRequired,
        searchFilterValue: PropTypes.string.isRequired,
        resetList: PropTypes.func.isRequired,
    }).isRequired,

    smartTips: PropTypes.shape({
        list: PropTypes.arrayOf(PropTypes.any).isRequired,
        setSubmitted: PropTypes.func.isRequired,
        unlink: PropTypes.func.isRequired,
        isAnyLinked: PropTypes.bool.isRequired,
        isFetching: PropTypes.bool.isRequired,
        loadMore: PropTypes.func.isRequired,
        hasMore: PropTypes.bool.isRequired,
        filterFunc: PropTypes.func.isRequired,
        searchFilterValue: PropTypes.string.isRequired,
        resetList: PropTypes.func.isRequired,
    }).isRequired,
};

export default EditMetricModal;
