import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Input from 'components/Input';
import Modal from 'components/Modal';
import { ReactComponent as CloseIcon } from 'assets/svg/close-modal-2.svg';
import reactDatepickerWrapper from 'assets/styles/datepickerWrapper.style';
import TimePicker from 'components/TimePicker';
import DatePicker from 'components/DatePicker';
import { getHoursFromTime, validateTime } from 'redux/helpers/reminders';
import {
    StyledButton,
    customModalStyles,
    ModalContentWrapper,
} from './EditReminderModal.style';

const createEditReminderBody = (relatableId, actionDatetime) => ({
    reminder: {
        relatable_id: relatableId,
        relatable_type: 'smart_tip',
        action_datetime: actionDatetime,
    },
});

const getHourFromParsedDate = (parsedDate) => {
    if (parsedDate) {
        const splitDate = parsedDate.split(',');
        if (splitDate.length === 3) return splitDate[2];
        if (splitDate.length === 2) return splitDate[1];
    }
    return '12:00 PM';
};

const EditReminderModal = ({
    reminderId,
    title,
    parsedDate,
    dateTimeObject,
    smartTips,
    editReminder,
    closeModal,
}) => {
    const [relatableId, setRelatableId] = useState(reminderId);

    const [searchDropdownValue, setSearchDropdownValue] = useState(title);
    const [searchDropdownVisible, setSearchDropdownVisible] = useState(false);

    const [actionDatetime, setActionDatetime] = useState(null);
    const [actionTime, setActionTime] = useState(
        getHourFromParsedDate(parsedDate)
    );
    const [actionDate, setActionDate] = useState(dateTimeObject);

    const [relatableType, setRelatableType] = useState(null);
    const [editReminderBody, setEditReminderBody] = useState(
        createEditReminderBody(relatableId, actionDatetime)
    );

    useEffect(() => {
        if (searchDropdownValue.length > 0 && !searchDropdownVisible) {
            setSearchDropdownVisible(true);
        } else if (!searchDropdownValue.length && searchDropdownVisible) {
            setSearchDropdownVisible(false);
        }
    }, [searchDropdownValue]);

    useEffect(() => {
        if (relatableId) {
            setSearchDropdownVisible(false);
        }
    }, [relatableId]);

    useEffect(() => {
        const dateTime = actionDate;

        dateTime.setHours(getHoursFromTime(actionTime));
        dateTime.setMinutes(
            actionTime.slice(
                actionTime.indexOf(':') + 1,
                actionTime.indexOf(':') + 3
            )
        );

        setActionDatetime(dateTime);
    }, [actionTime, actionDate]);

    useEffect(() => {
        if (relatableId && relatableType && actionDatetime) {
            setEditReminderBody(
                createEditReminderBody(relatableId, actionDatetime)
            );
        }
    }, [relatableId, relatableType, actionDatetime]);

    const handleSmartTipSelect = (id) => {
        let dropdownValue = '';
        const foundTip = smartTips.find((tip) => tip.id === id);
        if (foundTip) {
            dropdownValue = foundTip.title;
        }
        setSearchDropdownValue(dropdownValue);
        setRelatableId(id);
        setRelatableType('smart_tip');
    };

    const filterDropdownOptions = () =>
        smartTips.filter((tip) =>
            tip.title.toLowerCase().includes(searchDropdownValue.toLowerCase())
        );

    const handleTime = (val) => {
        if (validateTime(val)) {
            setActionTime(val);
        }
    };

    return (
      <Modal
            absolute
            noBg
            closeModal={closeModal}
            customStyles={customModalStyles}
            headerText="Edit reminder details"
        >
        <ModalContentWrapper>
          <Input
                    value={searchDropdownValue}
                    onChange={(e) => setSearchDropdownValue(e.target.value)}
                    variant="searchDropdown"
                    placeholder="Search Smart Tips"
                    dropdownTitle="Smart Tips"
                    dropdownVisible={searchDropdownVisible}
                    handleSelect={handleSmartTipSelect}
                    options={filterDropdownOptions()}
                />

          <Input variant="group">
            <TimePicker time={actionTime} setTime={handleTime} />
          </Input>

          <Input
                    variant="group"
                    noflex
                    customStyles={reactDatepickerWrapper}
                >
            <DatePicker date={actionDate} setNewDate={setActionDate} />
          </Input>

          <Input variant="group" pullright>
            <StyledButton
                        primary
                        onClick={(e) => closeModal(e)}
                        id="closeReminderModalButton"
                    >
              <CloseIcon />
            </StyledButton>
            <StyledButton
                        disabled={editReminderBody === null}
                        primary
                        width="110px"
                        onClick={() =>
                            editReminderBody
                                ? editReminder(
                                      reminderId,
                                      createEditReminderBody(
                                          relatableId,
                                          actionDatetime
                                      )
                                  )
                                : {}}
                    >
              Save
            </StyledButton>
          </Input>
        </ModalContentWrapper>
      </Modal>
    );
};

EditReminderModal.propTypes = {
    reminderId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    parsedDate: PropTypes.string.isRequired,
    dateTimeObject: PropTypes.objectOf(PropTypes.any).isRequired,
    closeModal: PropTypes.func.isRequired,
    editReminder: PropTypes.func.isRequired,
    smartTips: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default EditReminderModal;
