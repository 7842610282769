import {
    ESTABLISH_SMART_TIP_DETAILS,
    ESTABLISH_SMART_TIP_DETAILS_SUCCESS,
    ESTABLISH_SMART_TIP_DETAILS_FAILURE,
    UPDATE_SMART_TIP_DETAILS,
    UPDATE_SMART_TIP_DETAILS_SUCCESS,
    UPDATE_SMART_TIP_DETAILS_FAILURE,
    SEND_SMART_TIP_FEEDBACK,
    SEND_SMART_TIP_FEEDBACK_SUCCESS,
    SEND_SMART_TIP_FEEDBACK_FAILURE,
    ASSIGN_TO_ALL_USERS,
} from 'redux/actions/smartTipDetails';

export const initialState = {
    areSmartTipDetailsEstablished: false,
    isSmartTipDetailsError: false,
    areSmartTipDetailsFetching: false,
    error: null,
    details: {},
    assignToAllUsers: false,
};

const smartTipDetails = (state = initialState, action) => {
    switch (action.type) {
        case ESTABLISH_SMART_TIP_DETAILS:
            return {
                ...state,
                areSmartTipDetailsFetching: true,
                isSmartTipDetailsError: false,
            };

        case ESTABLISH_SMART_TIP_DETAILS_SUCCESS:
            return {
                ...state,
                areSmartTipDetailsFetching: false,
                areSmartTipDetailsEstablished: true,
                details: action.payload,
            };

        case ESTABLISH_SMART_TIP_DETAILS_FAILURE:
        case UPDATE_SMART_TIP_DETAILS_FAILURE:
        case SEND_SMART_TIP_FEEDBACK_FAILURE:
            return {
                ...state,
                areSmartTipDetailsFetching: false,
                isSmartTipDetailsError: true,
                error: action.err,
            };

        case UPDATE_SMART_TIP_DETAILS_SUCCESS:
        case SEND_SMART_TIP_FEEDBACK_SUCCESS:
            return {
                ...state,
                areSmartTipDetailsFetching: false,
                areSmartTipDetailsEstablished: true,
                details: {
                    ...state.details,
                    ...action.payload,
                },
            };
        case ASSIGN_TO_ALL_USERS:
            return {
                ...state,
                assignToAllUsers: action.payload,
            };
        case UPDATE_SMART_TIP_DETAILS:
        case SEND_SMART_TIP_FEEDBACK:
        default:
            return state;
    }
};

export default smartTipDetails;
