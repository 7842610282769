import React from 'react';
import PropTypes from 'prop-types';
import useDate from 'hooks/useDate';
import {
    ChartOverlay,
    Label,
    LegendWrapper,
    Info,
    SingleResultDateWrapper,
    ChartOverlayWrapper,
} from './RelativePerformance.style';

const Legend = ({ isSingleResult, isAdmin, isDoubleChart, currentScores }) => {
    const isScoreEmpty =
        currentScores.score !== undefined && currentScores.score !== null;

    const parsedDate = useDate(new Date(currentScores.date));

    if (
        currentScores !== undefined &&
        (currentScores.score === undefined ||
            (isDoubleChart && currentScores.providerScore === undefined))
    ) {
        return <LegendWrapper empty />;
    }

    if (isAdmin) {
        if (isDoubleChart) {
            return (
              <LegendWrapper>
                <Info>User performance compared to all providers</Info>
                <ChartOverlayWrapper isSingleResult={isSingleResult}>
                  <ChartOverlay row isSingleResult={isSingleResult}>
                    <Label>User</Label>
                    <strong>{currentScores.providerScoreLabel}</strong>
                    <Label gold>Average</Label>
                    <strong>
                      {isScoreEmpty
                                    ? `${currentScores.score || 0}%`
                                    : 'n/a'}
                    </strong>
                  </ChartOverlay>
                  {isSingleResult && (
                    <SingleResultDateWrapper>
                      {parsedDate}
                    </SingleResultDateWrapper>
                        )}
                </ChartOverlayWrapper>
              </LegendWrapper>
            );
        }

        return (
          <LegendWrapper>
            <Info>Average for all Users</Info>
            <ChartOverlayWrapper isSingleResult={isSingleResult}>
              <ChartOverlay row isSingleResult={isSingleResult}>
                <Label gold>Average</Label>
                <strong>
                  {isScoreEmpty ? `${currentScores.score}%` : 'n/a'}
                </strong>
              </ChartOverlay>
              {isSingleResult && (
                <SingleResultDateWrapper>
                  {parsedDate}
                </SingleResultDateWrapper>
                    )}
            </ChartOverlayWrapper>
          </LegendWrapper>
        );
    }

    if (isDoubleChart) {
        return (
          <LegendWrapper>
            <Info>Your avg. performance versus all users</Info>
            <ChartOverlayWrapper isSingleResult={isSingleResult}>
              <ChartOverlay row isSingleResult={isSingleResult}>
                <Label>You</Label>
                <strong>
                  {currentScores.providerScoreLabel}
                  {' '}
                </strong>
                <Label gold>Average</Label>
                <strong>
                  {isScoreEmpty ? `${currentScores.score}%` : 'n/a'}
                </strong>
              </ChartOverlay>
              {isSingleResult && (
                <SingleResultDateWrapper>
                  {parsedDate}
                </SingleResultDateWrapper>
                    )}
            </ChartOverlayWrapper>
          </LegendWrapper>
        );
    }

    return (
      <LegendWrapper>
        <ChartOverlayWrapper isSingleResult={isSingleResult}>
          <ChartOverlay row isSingleResult={isSingleResult}>
            <Label gold>Average</Label>
            <strong>
              {isScoreEmpty ? `${currentScores.score}%` : 'n/a'}
            </strong>
          </ChartOverlay>
          {isSingleResult && (
            <SingleResultDateWrapper>
              {parsedDate}
            </SingleResultDateWrapper>
                )}
        </ChartOverlayWrapper>
      </LegendWrapper>
    );
};

Legend.propTypes = {
    isSingleResult: PropTypes.bool,
    isAdmin: PropTypes.bool,
    isDoubleChart: PropTypes.bool,
    currentScores: PropTypes.objectOf(PropTypes.any),
};

Legend.defaultProps = {
    isSingleResult: false,
    isAdmin: false,
    isDoubleChart: false,
    currentScores: undefined,
};

export default Legend;
