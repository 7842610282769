import {
    ESTABLISH_ORGANIZATION_SMART_TIP_EVALUATION_LIST,
    ESTABLISH_ORGANIZATION_SMART_TIP_EVALUATION_LIST_SUCCESS,
    ESTABLISH_ORGANIZATION_SMART_TIP_EVALUATION_LIST_FAILURE,
} from 'redux/actions/organizationSmartTipEvaluationList';

export const initialState = {
    organizationSmartTipEvaluationList: [],
    isOrganizationSmartTipEvaluationListFetching: false,
    isOrganizationSmartTipEvaluationListEstablished: false,
    isOrganizationSmartTipEvaluationListError: false,
    error: null,
};

const userDetails = (state = initialState, action) => {
    switch (action.type) {
        case ESTABLISH_ORGANIZATION_SMART_TIP_EVALUATION_LIST:
            return {
                ...state,
                isOrganizationSmartTipEvaluationListFetching: true,
                isOrganizationSmartTipEvaluationListError: false,
            };

        case ESTABLISH_ORGANIZATION_SMART_TIP_EVALUATION_LIST_SUCCESS:
            return {
                ...state,
                isOrganizationSmartTipEvaluationListFetching: false,
                isOrganizationSmartTipEvaluationListEstablished: true,
                organizationSmartTipEvaluationList: action.payload,
            };

        case ESTABLISH_ORGANIZATION_SMART_TIP_EVALUATION_LIST_FAILURE:
            return {
                ...state,
                isOrganizationSmartTipEvaluationListFetching: false,
                isOrganizationSmartTipEvaluationListError: true,
                error: action.err,
            };

        default:
            return state;
    }
};

export default userDetails;
