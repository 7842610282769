import styled, { css } from 'styled-components/macro';

export const NoResultsWrapper = styled.div`
    max-width: 475px;
    height: 327px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    margin: 72px auto;
    text-align: center;

    h2 {
        font-size: ${({ theme }) => theme.fontSize.sectionHeading};
        font-weight: 600;
        margin: 0;
    }

    p {
        font-size: ${({ theme }) => theme.fontSize.small};
        color: ${({ theme }) => theme.color.textLight};
        line-height: 18px;
        margin: 16px 0;
    }

    button {
        width: 170px;
    }

    ${({ type }) =>
        type &&
        css`
            height: 200px;
        `}
`;

export const FullHeightWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 250px);
    width: 100%;
`;

export const ButtonsWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
    align-items: center;
`;
