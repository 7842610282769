import React, { useState, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
    establishUsersThunk,
    loadMoreUsersThunk,
    resetUsersThunk,
} from 'redux/actions/thunks/user';
import establishOrganizationSmartTipsThunk, {
    loadMoreOrganizationSmartTipsThunk,
    resetOrganizationSmartTipsThunk,
} from 'redux/actions/thunks/organizationSmartTips';
import establishMetricDetailsThunk, {
    resetMetricDetailsThunk,
} from 'redux/actions/thunks/currentMetricDetails';
import EditMetricModalComponent from 'components/EditMetricModal';
import { useAuth0 } from '@auth0/auth0-react';

const INFINITE_SCROLL_THRESHOLD = 20;
const OFFSET_RESET = 'OFFSET_RESET';
const OFFSET_INCREMENT = 'OFFSET_INCREMENT';

const offsetReducer = (state, action) => {
    if (action === OFFSET_INCREMENT) {
        return state + INFINITE_SCROLL_THRESHOLD;
    }

    if (action === OFFSET_RESET) {
        return 0;
    }

    return state;
};

const EditMetricModal = ({
    name,
    metricId,
    metricSourceId,
    goal,
    enabled,
    onUpdate,
    toggleVisible,
}) => {
    const dispatch = useDispatch();
    const { getAccessTokenSilently, logout } = useAuth0();

    const metricSourceName = useSelector(
        ({ metricSources }) =>
            metricSources.metricSources.find((m) => m.id === metricSourceId)
                .name
    );
    const { metricDetails, areMetricDetailsEstablished } = useSelector(
        ({ currentMetricDetails }) => currentMetricDetails
    );
    const {
        users,
        areUsersEstablished,
        areUsersFetching,
        hasMoreUsers,
    } = useSelector((state) => state.users);

    const {
        organizationSmartTips,
        areOrganizationSmartTipsEstablished,
        areOrganizationSmartTipsFetching,
        hasMoreOrganizationSmartTips,
    } = useSelector((state) => state.organizationSmartTips);

    const [initialFetch, setInitialFetch] = useState(false);

    // Setup search filters
    const [
        organizationSmartTipsNameFilter,
        setOrganizationSmartTipsNameFilter,
    ] = useState('');
    const [usersNameFilter, setUsersNameFilter] = useState('');

    // Setup infinite scrolling offsets
    const [
        organizationSmartTipsOffset,
        dispatchOrganizationSmartTipsOffset,
    ] = useReducer(offsetReducer, 0);
    const [usersOffset, dispatchUsersOffset] = useReducer(offsetReducer, 0);

    // Setup inputs states
    const [newMetricGoal, setNewMetricGoal] = useState(goal);
    const [newMetricStatus, setNewMetricStatus] = useState(enabled);
    const [newLinkedSmartTipId, setNewLinkedSmartTipId] = useState('');
    const [isAnySmartTipLinked, setIsAnySmartTipLinked] = useState(false);
    const [newDisabledUsersIds, setNewDisabledUsersIds] = useState([]);
    const [defaultUsers, setDefaultUsers] = useState([]);

    const updateMetric = () =>
        onUpdate({
            goal: newMetricGoal,
            enabled: newMetricStatus,
            smartTipId: newLinkedSmartTipId,
            disabledUserIds: newDisabledUsersIds,
        });

    useEffect(() => {
        if (!initialFetch) {
            setInitialFetch(
                areMetricDetailsEstablished &&
                    areOrganizationSmartTipsEstablished &&
                    areUsersEstablished
            );
        }
    }, [
        areMetricDetailsEstablished,
        areOrganizationSmartTipsEstablished,
        areUsersEstablished,
        initialFetch,
        setInitialFetch,
    ]);

    // Fetch Metric details data
    useEffect(() => {
        dispatch(
            establishMetricDetailsThunk(
                getAccessTokenSilently,
                logout,
                metricSourceId,
                metricId
            )
        );

        return () => dispatch(resetMetricDetailsThunk());
    }, []);

    // Populate default metric data (if there is any)
    useEffect(() => {
        if (metricDetails) {
            setDefaultUsers(metricDetails.disabled_users);

            if (metricDetails.smart_tip) {
                setIsAnySmartTipLinked(true);
                setNewLinkedSmartTipId(metricDetails.smart_tip.id);
                setOrganizationSmartTipsNameFilter(
                    metricDetails.smart_tip.name
                );
            }
        }
    }, [metricDetails]);

    // Apply search filters
    useEffect(() => {
        dispatch(resetUsersThunk());

        if (usersNameFilter.length > 2) {
            dispatch(
                establishUsersThunk(
                    getAccessTokenSilently,
                    logout,
                    INFINITE_SCROLL_THRESHOLD,
                    0,
                    {
                        search: usersNameFilter.toLowerCase(),
                    }
                )
            );
            dispatchUsersOffset(OFFSET_RESET);
        } else if (usersNameFilter.length === 0) {
            dispatch(
                establishUsersThunk(
                    getAccessTokenSilently,
                    logout,
                    INFINITE_SCROLL_THRESHOLD,
                    0
                )
            );
            dispatchUsersOffset(OFFSET_INCREMENT);
        }
    }, [usersNameFilter]);

    useEffect(() => {
        dispatch(resetOrganizationSmartTipsThunk());

        if (organizationSmartTipsNameFilter.length > 2) {
            dispatch(
                establishOrganizationSmartTipsThunk(
                    getAccessTokenSilently,
                    logout,
                    INFINITE_SCROLL_THRESHOLD,
                    0,
                    {
                        search: organizationSmartTipsNameFilter.toLowerCase(),
                    }
                )
            );
            dispatchOrganizationSmartTipsOffset(OFFSET_RESET);
        } else if (organizationSmartTipsNameFilter.length === 0) {
            dispatch(
                establishOrganizationSmartTipsThunk(
                    getAccessTokenSilently,
                    logout,
                    INFINITE_SCROLL_THRESHOLD,
                    0
                )
            );
            dispatchOrganizationSmartTipsOffset(OFFSET_INCREMENT);
        }
    }, [organizationSmartTipsNameFilter]);

    return (
      <EditMetricModalComponent
            hasEstablishedData={initialFetch}
            toggleVisible={toggleVisible}
            metricName={name}
            metricSourceName={metricSourceName}
            onGeneralSubmit={updateMetric}
            goal={{
                value: newMetricGoal,
                setFunc: setNewMetricGoal,
            }}
            name={{
                value: name,
            }}
            description={{
                value: metricDetails ? metricDetails.description : '',
            }}
            status={{
                value: newMetricStatus,
                setFunc: setNewMetricStatus,
            }}
            users={{
                list: users,
                setSubmitted: setNewDisabledUsersIds,
                defaultValue: defaultUsers,
                isFetching: areUsersFetching,
                hasMore: hasMoreUsers && !areUsersFetching,
                searchFilterValue: usersNameFilter,
                filterFunc: setUsersNameFilter,
                resetList: () => {
                    dispatch(resetUsersThunk());

                    dispatchUsersOffset(OFFSET_RESET);
                },
                loadMore: () => {
                    if (usersNameFilter.length === 0) {
                        dispatch(
                            loadMoreUsersThunk(
                                getAccessTokenSilently,
                                logout,
                                INFINITE_SCROLL_THRESHOLD,
                                usersOffset
                            )
                        );
                        dispatchUsersOffset(OFFSET_INCREMENT);
                    }
                },
            }}
            smartTips={{
                list: organizationSmartTips,
                setSubmitted: (stId) => {
                    setNewLinkedSmartTipId(stId);
                    setIsAnySmartTipLinked(true);
                },
                unlink: () => {
                    setOrganizationSmartTipsNameFilter('');
                    setNewLinkedSmartTipId('');
                    setIsAnySmartTipLinked(false);
                },
                isAnyLinked: isAnySmartTipLinked,
                isFetching: areOrganizationSmartTipsFetching,
                hasMore: hasMoreOrganizationSmartTips,
                searchFilterValue: organizationSmartTipsNameFilter,
                filterFunc: setOrganizationSmartTipsNameFilter,
                resetList: () => {
                    dispatch(resetOrganizationSmartTipsThunk());

                    dispatchOrganizationSmartTipsOffset(OFFSET_RESET);
                },
                loadMore: () => {
                    if (organizationSmartTipsNameFilter.length === 0) {
                        dispatch(
                            loadMoreOrganizationSmartTipsThunk(
                                getAccessTokenSilently,
                                logout,
                                INFINITE_SCROLL_THRESHOLD,
                                organizationSmartTipsOffset
                            )
                        );
                        dispatchOrganizationSmartTipsOffset(OFFSET_INCREMENT);
                    }
                },
            }}
        />
    );
};

EditMetricModal.propTypes = {
    name: PropTypes.string.isRequired,
    goal: PropTypes.number.isRequired,
    enabled: PropTypes.bool.isRequired,
    metricId: PropTypes.string.isRequired,
    metricSourceId: PropTypes.string.isRequired,
    toggleVisible: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
};

export default EditMetricModal;
