import React from 'react';
import PropTypes from 'prop-types';
import SingleMetricComponent from 'components/SingleMetric/MPASingleMetric';

const MPASingleMetric = ({
    id,
    name,
    enabled,
    description,
    goal,
    metricSourceId,
    reloadMetrics,
    withoutBorder,
    handleDelete,
}) => (
  <SingleMetricComponent
        metricSourceId={metricSourceId}
        metricId={id}
        metricName={name}
        metricDescription={description}
        metricGoal={goal}
        metricEnabled={enabled}
        reloadMetrics={reloadMetrics}
        withoutBorder={withoutBorder}
        handleDelete={handleDelete}
    />
);

MPASingleMetric.propTypes = {
    id: PropTypes.string.isRequired,
    metricSourceId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    goal: PropTypes.number.isRequired,
    enabled: PropTypes.bool.isRequired,
    reloadMetrics: PropTypes.func.isRequired,
    withoutBorder: PropTypes.bool,
    handleDelete: PropTypes.func.isRequired,
};

MPASingleMetric.defaultProps = { withoutBorder: false };

export default MPASingleMetric;
