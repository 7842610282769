import styled, { css } from 'styled-components/macro';

export const StyledStatisticWrapper = styled.div`
    position: relative;

    display: flex;
    align-items: center;
    width: 100%;
    height: 199px;

    border-radius: ${({ theme }) => theme.border.radiusBase};
    border: ${({ theme }) => theme.border.base};
    background-color: ${({ theme }) => theme.color.white};
`;

export const StyledStatisticLabel = styled.div`
    width: 48px;
    height: 69px;

    position: absolute;
    top: 0;
    left: calc(50% - 24px);

    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 16px;

    border-radius: 0 0 50px 50px;

    ${({ starsEarned }) =>
        starsEarned &&
        css`
            background-color: ${({ theme }) => theme.color.paleGreen};
        `};

    ${({ level }) =>
        level &&
        css`
            background-color: ${({ theme }) => theme.color.gold};
        `};

    ${({ starsToNextLevel }) =>
        starsToNextLevel &&
        css`
            background-color: ${({ theme }) =>
                theme.color.textNavigationCounter};
        `};
`;

export const StyledMetric = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    padding-top: 70px;

    strong {
        font-size: ${({ theme }) => theme.fontSize.huge};
    }

    small {
        margin-top: 7px;
        font-size: ${({ theme }) => theme.fontSize.medium};
        color: ${({ theme }) => theme.color.textLight};
    }
`;
