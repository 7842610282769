import {
    establishOrganizationSmartTips,
    establishOrganizationSmartTipsFailure,
    establishOrganizationSmartTipsSuccess,
    loadMoreOrganizationSmartTips,
    allSmartTipsLoaded,
    resetOrganizationSmartTips,
    updateOrganizationSmartTip,
    updateOrganizationSmartTipSuccess,
    updateOrganizationSmartTipSearchSuccess,
    updateOrganizationSmartTipFailure,
    searchUserSmartTips,
    searchUserSmartTipsFailure,
    searchUserSmartTipsSuccess,
    assignOrganizationSmartTip,
    assignOrganizationSmartTipFailure,
    assignOrganizationSmartTipSuccess,
} from 'redux/actions/organizationSmartTips';
import {
    dispatchSuccessAlertThunk,
    dispatchErrorAlertThunk,
} from 'redux/actions/thunks/systemAlerts';
import apiRoutes from 'config/apiRoutes';
import paramsToString from 'redux/helpers/paramsToString';

const establishOrganizationSmartTipsThunk =
    (getAccessTokenSilently, logout, limit, offset, params = {}, noReplace) =>
    (dispatch, getState) => {
        const { organizationSmartTips } = getState();

        if (organizationSmartTips.areOrganizationSmartTipsFetching) {
            return () => {};
        }

        dispatch(establishOrganizationSmartTips());

        const url =
            apiRoutes.get.organizationSmartTips(limit, offset) +
            paramsToString(params);

        return getAccessTokenSilently()
            .then((token) => {
                fetch(url, {
                    method: 'GET',
                    headers: {
                        organization_slug:
                            window.localStorage.getItem('MP_ORGANIZATION'),
                        Authorization: `Token token="${token}"`,
                    },
                })
                    .then((res) => res.json())
                    .then((res) => {
                        if (res.error) {
                            return dispatch(
                                establishOrganizationSmartTipsFailure(res.error)
                            );
                        }
                        if (!noReplace) {
                            return dispatch(
                                updateOrganizationSmartTipSearchSuccess(
                                    res.smart_tips
                                )
                            );
                        }

                        return dispatch(
                            establishOrganizationSmartTipsSuccess(
                                res.smart_tips
                            )
                        );
                    })
                    .catch((err) =>
                        dispatch(
                            establishOrganizationSmartTipsFailure({
                                err,
                            })
                        )
                    )
                    .finally(
                        () =>
                            !limit && !offset && dispatch(allSmartTipsLoaded())
                    );
            })
            .catch(() => logout({ returnTo: window.location.origin }));
    };

export const loadMoreOrganizationSmartTipsThunk =
    (getAccessTokenSilently, logout, limit, offset, params = {}) =>
    (dispatch, getState) => {
        const { organizationSmartTips } = getState();

        if (organizationSmartTips.areOrganizationSmartTipsFetching) {
            return () => {};
        }
        dispatch(establishOrganizationSmartTips());

        const url =
            apiRoutes.get.organizationSmartTips(limit, offset) +
            paramsToString(params);

        return getAccessTokenSilently()
            .then((token) => {
                fetch(url, {
                    method: 'GET',
                    headers: {
                        organization_slug:
                            window.localStorage.getItem('MP_ORGANIZATION'),
                        Authorization: `Token token="${token}"`,
                    },
                })
                    .then((res) => res.json())
                    .then((res) => {
                        if (res.error) {
                            return dispatch(
                                establishOrganizationSmartTipsFailure(res.error)
                            );
                        }

                        if (res.smart_tips.length === 0) {
                            return dispatch(allSmartTipsLoaded());
                        }

                        return dispatch(
                            loadMoreOrganizationSmartTips(res.smart_tips)
                        );
                    })
                    .catch((err) =>
                        dispatch(
                            establishOrganizationSmartTipsFailure({
                                err,
                            })
                        )
                    );
            })
            .catch(() => logout({ returnTo: window.location.origin }));
    };

export const assignOrganizationSmartTipToAllUsersThunk =
    (getAccessTokenSilently, logout, smartTipId, resetSmartTips, values = {}) =>
    (dispatch) => {
        dispatch(updateOrganizationSmartTip());
        return getAccessTokenSilently()
            .then((token) => {
                fetch(apiRoutes.put.updateOrganizationSmartTip(smartTipId), {
                    method: 'PUT',
                    headers: {
                        organization_slug:
                            window.localStorage.getItem('MP_ORGANIZATION'),
                        Authorization: `Token token="${token}"`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        assigned_user_ids: values.assigned_user_ids,
                    }),
                })
                    .then((res) => res.json())
                    .then((res) => {
                        if (res.error) {
                            dispatch(
                                dispatchErrorAlertThunk(smartTipId, res.error)
                            );
                            return dispatch(
                                updateOrganizationSmartTipFailure(res.error)
                            );
                        }
                        dispatch(
                            dispatchSuccessAlertThunk(
                                smartTipId,
                                'Assignment of Smart Tip to all users has been removed'
                            )
                        );

                        resetSmartTips();
                        return dispatch(updateOrganizationSmartTipSuccess(res));
                    })
                    .catch((err) => {
                        dispatch(
                            dispatchErrorAlertThunk(smartTipId, err.error)
                        );
                        dispatch(
                            updateOrganizationSmartTipFailure({
                                err,
                            })
                        );
                    });
            })
            .catch(() => logout({ returnTo: window.location.origin }));
    };

export const updateOrganizationSmartTipThunk =
    (getAccessTokenSilently, logout, smartTipId, values = {}, resetSmartTips) =>
    (dispatch) => {
        dispatch(updateOrganizationSmartTip());

        return getAccessTokenSilently()
            .then((token) => {
                fetch(apiRoutes.put.updateOrganizationSmartTip(smartTipId), {
                    method: 'PUT',
                    headers: {
                        organization_slug:
                            window.localStorage.getItem('MP_ORGANIZATION'),
                        Authorization: `Token token="${token}"`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ smart_tip: { ...values } }),
                })
                    .then((res) => res.json())
                    .then((res) => {
                        if (res.error) {
                            dispatch(
                                dispatchErrorAlertThunk(smartTipId, res.error)
                            );
                            return dispatch(
                                updateOrganizationSmartTipFailure(res.error)
                            );
                        }

                        dispatch(
                            dispatchSuccessAlertThunk(
                                smartTipId,
                                'Smart tip has been assigned'
                            )
                        );
                        resetSmartTips();
                        return dispatch(updateOrganizationSmartTipSuccess(res));
                    })
                    .catch((err) => {
                        dispatch(
                            dispatchErrorAlertThunk(smartTipId, err.error)
                        );
                        dispatch(
                            updateOrganizationSmartTipFailure({
                                err,
                            })
                        );
                    });
            })
            .catch(() => logout({ returnTo: window.location.origin }));
    };

export const resetOrganizationSmartTipsThunk = () => (dispatch) => {
    dispatch(resetOrganizationSmartTips());
};

export const searchOrganizationSmartTipsThunk =
    (getAccessTokenSilently, logout, searchValue, userId) => (dispatch) => {
        dispatch(searchUserSmartTips());

        return getAccessTokenSilently()
            .then((token) => {
                fetch(
                    apiRoutes.get.organizationUserSmartTipsList(
                        userId,
                        searchValue
                    ),
                    {
                        method: 'GET',
                        headers: {
                            organization_slug:
                                window.localStorage.getItem('MP_ORGANIZATION'),
                            Authorization: `Token token="${token}"`,
                        },
                    }
                )
                    .then((res) => res.json())
                    .then((res) => {
                        if (res.error) {
                            return dispatch(
                                searchUserSmartTipsFailure(res.error)
                            );
                        }

                        return dispatch(
                            searchUserSmartTipsSuccess(res.smart_tips)
                        );
                    });
            })
            .catch(() => logout({ returnTo: window.location.origin }));
    };

export const assignOrganizationSmartTipThunk =
    (getAccessTokenSilently, logout, userId, smartTipId) => (dispatch) => {
        dispatch(assignOrganizationSmartTip());

        return getAccessTokenSilently()
            .then((token) => {
                fetch(
                    apiRoutes.post.assignSmartTipToOrganizationUser(
                        userId,
                        smartTipId
                    ),
                    {
                        method: 'POST',
                        headers: {
                            organization_slug:
                                window.localStorage.getItem('MP_ORGANIZATION'),
                            Authorization: `Token token="${token}"`,
                        },
                    }
                )
                    .then((res) => res.json())
                    .then((res) => {
                        if (res.error) {
                            return dispatch(
                                assignOrganizationSmartTipFailure(res.error)
                            );
                        }

                        return dispatch(assignOrganizationSmartTipSuccess());
                    });
            })
            .catch(() => logout({ returnTo: window.location.origin }));
    };

export default establishOrganizationSmartTipsThunk;
