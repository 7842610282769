import { createGlobalStyle } from 'styled-components/macro';
import globalFonts from './GlobalFonts';
import theme from './mainTheme';

const GlobalStyle = createGlobalStyle`
    ${globalFonts}
    *, *::before, *::after {
        box-sizing: border-box;
    }

    body {
        background-color: ${theme.color.bgBase};
        padding: 0;
        margin: 0;
        font-family: 'Inter', sans-serif;
        font-size: ${theme.fontSize.base};
        color: ${theme.color.textBase};
        font-weight: 400;
    }

    li, span, small, a, i, b, strong, button, textarea, input  {
        font-family: 'Inter', sans-serif;
        color: ${theme.color.textBase};
    }

    button {
        cursor: pointer;
    }

    textarea:focus, input:focus, button:focus {
        outline: none;
    }

    a {
        text-decoration: none;
    }
`;

export default GlobalStyle;
