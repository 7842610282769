const useLocalStorage = (key) => {
    const setItem = (value) => localStorage.setItem(key, value);

    const getItem = () => localStorage.getItem(key);

    const clearItem = () => localStorage.removeItem(key);

    return { setItem, getItem, clearItem };
};

export default useLocalStorage;
