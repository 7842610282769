import React, { useState, useReducer, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import useAdmin from 'hooks/useAdmin';
import SearchComponent from 'components/Search';
import apiRoutes from 'config/apiRoutes';
import paramsToString from 'redux/helpers/paramsToString';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';
import { dispatchErrorAlertThunk } from 'redux/actions/thunks/systemAlerts';

const initialState = {
    smartTips: [],
    courses: [],
};

const SET_SMART_TIPS = 'SET_SMART_TIPS';
const SET_COURSES = 'SET_COURSES';
const RESET = 'RESET';

const resultsReducer = (state, action) => {
    if (action.type === SET_SMART_TIPS) {
        return {
            ...state,
            smartTips: action.payload,
        };
    }
    if (action.type === SET_COURSES) {
        return {
            ...state,
            courses: action.payload,
        };
    }
    if (action.type === RESET) {
        return initialState;
    }

    return state;
};

const Search = ({ isMobile }) => {
    const { getAccessTokenSilently, logout } = useAuth0();
    const dispatch = useDispatch();
    const { isLMSEnabled } = useSelector((state) => state.currentUser);
    const [searchResults, dispatchSearchResults] = useReducer(
        resultsReducer,
        initialState
    );
    const [searchState, setSearchState] = useState('');
    const doCoursesRequests = useRef(isLMSEnabled);
    const isAdmin = useAdmin();

    const smartTipsUrl = isAdmin
        ? apiRoutes.get.organizationSmartTips(3, 0)
        : `${apiRoutes.get.allSmartTips(3, 0)}&type=published`;

    const coursesUrl = apiRoutes.get.courses(3, 0);

    useEffect(() => {
        if (searchState.length >= 3) {
            getAccessTokenSilently()
                .then((token) => {
                    fetch(
                        smartTipsUrl + paramsToString({ search: searchState }),
                        {
                            method: 'GET',
                            headers: {
                                organization_slug: window.localStorage.getItem(
                                    'MP_ORGANIZATION'
                                ),
                                Authorization: `Token token="${token}"`,
                            },
                        }
                    )
                        .then((res) => res.json())
                        .then((res) => {
                            if (res.error) {
                                dispatchSearchResults({ type: RESET });
                            } else {
                                dispatchSearchResults({
                                    type: SET_SMART_TIPS,
                                    payload: res.smart_tips,
                                });
                            }
                        })
                        .catch((err) => {
                            dispatch(dispatchErrorAlertThunk(err, err.error));
                        });
                })
                .catch(() => logout({ returnTo: window.location.origin }));

            if (doCoursesRequests.current) {
                getAccessTokenSilently()
                    .then((token) => {
                        fetch(
                            coursesUrl +
                                paramsToString({ search: searchState }),
                            {
                                method: 'GET',
                                headers: {
                                    organization_slug: window.localStorage.getItem(
                                        'MP_ORGANIZATION'
                                    ),
                                    Authorization: `Token token="${token}"`,
                                },
                            }
                        )
                            .then((res) => res.json())
                            .then((res) => {
                                if (res.error) {
                                    if (doCoursesRequests.current) {
                                        dispatch(
                                            dispatchErrorAlertThunk(
                                                res.error,
                                                'There is something wrong with Courses'
                                            )
                                        );
                                    }
                                    doCoursesRequests.current = false;
                                } else {
                                    dispatchSearchResults({
                                        type: SET_COURSES,
                                        payload: res.courses,
                                    });
                                }
                            })
                            .catch((err) => {
                                if (doCoursesRequests.current) {
                                    dispatch(
                                        dispatchErrorAlertThunk(
                                            err.error,
                                            'There is something wrong with Courses'
                                        )
                                    );
                                    doCoursesRequests.current = false;
                                }
                            });
                    })
                    .catch(() => logout({ returnTo: window.location.origin }));
            }
        } else {
            dispatchSearchResults({ type: RESET });
        }
    }, [searchState]);

    return (
      <SearchComponent
            isMobile={isMobile}
            searchState={searchState}
            onSearch={setSearchState}
            searchResults={searchResults}
            clearResults={() => dispatchSearchResults({ type: RESET })}
        />
    );
};

Search.propTypes = {
    isMobile: PropTypes.bool.isRequired,
};

export default Search;
