import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ClockIcon } from 'assets/svg/alarm-clock.svg';
import {
    TimePickerWrapper,
    Time,
    Dropdown,
    TimeColumn,
    SingleValue,
    ContentWrapper,
} from './TimePicker.style';

const getPartFromTime = (time) => {
    if (time.includes('a') || time.includes('A')) {
        return 'AM';
    }
    return 'PM';
};

const TimePicker = ({ time, setTime }) => {
    const dropdownRef = useRef(null);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [hour, setHour] = useState(
        time ? time.replace(/\s+/g, '').split(':')[0] : '10'
    );
    const [minutes, setMinutes] = useState(
        time ? time.split(':')[1].replace(/\s+|AM|PM|am|pm/g, '') : '00'
    );
    const [part, setPart] = useState(getPartFromTime(time));

    useEffect(() => {
        setTime(`${hour}:${minutes} ${part}`);
    }, [hour, minutes, part]);

    const handleClickOutside = (e) => {
        if (dropdownRef.current && dropdownRef.current.contains(e.target)) {
            return;
        }
        setIsDropdownVisible(false);
    };

    useEffect(() => {
        if (isDropdownVisible) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isDropdownVisible]);

    const renderHours = () => {
        const hours = [];
        for (let index = 1; index < 13; index += 1) {
            hours.push(
              <SingleValue
                    key={`hours_${index}`}
                    onClick={() => setHour(`${index}`)}
                    isSelected={hour === `${index}`}
                >
                {index}
              </SingleValue>
            );
        }
        return hours;
    };

    const renderMinutes = () => {
        const hours = [];
        for (let index = 0; index < 60; index += 1) {
            if (index < 10) {
                hours.push(
                  <SingleValue
                        key={`minutes_${index}`}
                        onClick={() => setMinutes(`0${index}`)}
                        isSelected={minutes === `0${index}`}
                    >
                    {`0${index}`}
                  </SingleValue>
                );
            } else {
                hours.push(
                  <SingleValue
                        key={`minutes_${index}`}
                        onClick={() => setMinutes(`${index}`)}
                        isSelected={minutes === `${index}`}
                    >
                    {index}
                  </SingleValue>
                );
            }
        }
        return hours;
    };

    return (
      <TimePickerWrapper ref={dropdownRef}>
        <ContentWrapper
                onClick={() => setIsDropdownVisible(!isDropdownVisible)}
            >
          <ClockIcon />
          <Time>{time}</Time>
        </ContentWrapper>
        {isDropdownVisible && (
        <Dropdown>
          <TimeColumn>{renderHours()}</TimeColumn>
          <TimeColumn>{renderMinutes()}</TimeColumn>
          <TimeColumn>
            <SingleValue
                            onClick={() => setPart('AM')}
                            isSelected={part === 'AM'}
                        >
              AM
            </SingleValue>
            <SingleValue
                            onClick={() => setPart('PM')}
                            isSelected={part === 'PM'}
                        >
              PM
            </SingleValue>
          </TimeColumn>
        </Dropdown>
            )}
      </TimePickerWrapper>
    );
};

TimePicker.propTypes = {
    time: PropTypes.string,
    setTime: PropTypes.func.isRequired,
};

TimePicker.defaultProps = {
    time: '10:00 AM',
};

export default TimePicker;
