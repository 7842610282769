import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import Spinner from 'components/Spinner';
import { ReactComponent as DropdownArrow } from 'assets/svg/dropdown-down.svg';
import theme from 'assets/theme/mainTheme';
import { DropdownArea, DropdownButtonHolder } from './DropdownButton.style';

const DropdownButton = ({
    arrowColor,
    label,
    dropdownVisible,
    setDropdownVisible,
    children,
    ...rest
}) => {
    const dropdownRef = useRef(null);

    const handleClickOutside = (e) => {
        if (dropdownRef.current && dropdownRef.current.contains(e.target)) {
            return;
        }
        setDropdownVisible(false);
    };

    useEffect(() => {
        if (dropdownVisible) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownVisible]);

    return (
      <DropdownButtonHolder ref={dropdownRef}>
        <Button
                {...rest}
                onClick={() => setDropdownVisible(!dropdownVisible)}
                svg
            >
          {label} 
          {' '}
          <DropdownArrow fill={arrowColor} />
        </Button>
        {dropdownVisible && <DropdownArea>{children}</DropdownArea>}
      </DropdownButtonHolder>
    );
};

DropdownButton.propTypes = {
    arrowColor: PropTypes.string,
    label: PropTypes.string,
    dropdownVisible: PropTypes.bool,
    setDropdownVisible: PropTypes.func,
    children: PropTypes.any || PropTypes.func,
};

DropdownButton.defaultProps = {
    arrowColor: theme.color.primary,
    children: <Spinner />,
    label: 'Import',
    // Dropdown options are not required because there may be a need to use this button without dropdown
    dropdownVisible: false,
    setDropdownVisible: () => {},
};

export default DropdownButton;
