export const ESTABLISH_MPA_ADMIN_MEMOS = 'ESTABLISH_MPA_ADMIN_MEMOS';
export const ESTABLISH_MPA_ADMIN_MEMOS_SUCCESS =
    'ESTABLISH_MPA_ADMIN_MEMOS_SUCCESS';
export const ESTABLISH_MPA_ADMIN_MEMOS_FAILURE =
    'ESTABLISH_MPA_ADMIN_MEMOS_FAILURE';
export const LOAD_MORE_MPA_ADMIN_MEMOS = 'LOAD_MORE_MPA_ADMIN_MEMOS';
export const ALL_MPA_ADMIN_MEMOS_LOADED = 'ALL_MPA_ADMIN_MEMOS_LOADED';
export const RESET_MPA_ADMIN_MEMOS = 'RESET_MPA_ADMIN_MEMOS';

export const establishMPAAdminMemos = () => ({
    type: ESTABLISH_MPA_ADMIN_MEMOS,
});

export const establishMPAAdminMemosSuccess = (payload) => ({
    type: ESTABLISH_MPA_ADMIN_MEMOS_SUCCESS,
    payload,
});

export const establishMPAAdminMemosFailure = (err) => ({
    type: ESTABLISH_MPA_ADMIN_MEMOS_FAILURE,
    err,
});

export const loadMoreMPAAdminMemos = (payload) => ({
    type: LOAD_MORE_MPA_ADMIN_MEMOS,
    payload,
});

export const allMPAAdminMemosLoaded = () => ({
    type: ALL_MPA_ADMIN_MEMOS_LOADED,
});

export const resetMPAAdminMemos = () => ({
    type: RESET_MPA_ADMIN_MEMOS,
});
