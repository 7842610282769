import styled, { css } from 'styled-components/macro';
import { ReactComponent as LeafBase } from 'assets/svg/auth-page/leaf.svg';
import { ReactComponent as PersonBase } from 'assets/svg/auth-page/person.svg';

export const Leaf = styled(LeafBase)`
    position: absolute;
    bottom: 10px;
    right: 215px;
    z-index: -1;
`;

export const Person = styled(PersonBase)`
    position: absolute;
    top: 25px;
    left: 215px;
    z-index: -1;
`;

export const AuthViewWrapper = styled.section`
    background-color: ${({ theme }) => theme.color.bgBase};
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999999;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const FormHolder = styled.div`
    height: 390px;
    width: 416px;
    background-color: ${({ theme }) => theme.color.white};
    box-shadow: ${({ theme }) => theme.boxShadow.base};
    border-radius: ${({ theme }) => theme.border.radiusBase};
    position: relative;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    max-width: calc(100% - 32px);

    h3 {
        text-align: center;
        font-weight: 500;
        font-size: 20px;
        margin: 0px;
    }

    & > * {
        flex-basis: auto;
        width: 100%;
    }

    @keyframes blink {
        0% {
            opacity: 1;
        }
        50% {
            opacity: 0.6;
        }
        100% {
            opacity: 1;
        }
    }

    ${({ urlLogIn }) =>
        urlLogIn &&
        css`
            animation-name: blink;
            animation-duration: 1s;
            animation-iteration-count: infinite;
        `}
`;

export const LogoHolder = styled.div`
    width: auto;
    position: absolute;
    bottom: calc(100% + 32px);
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;

    svg {
        margin-right: 12px;
    }
`;

export const DropdownWrapper = styled.div`
    position: relative;
`;

export const Dropdown = styled.div`
    position: absolute;
    z-index: 1;
    top: 53px;
    width: 352px;
    max-width: 100%;
    height: auto;
    max-height: 300px;
    overflow-y: auto;
    padding: 10px 8px;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
    border: 1px solid;
    background-color: ${({ theme }) => theme.color.white};
    border-color: ${({ theme }) => theme.color.borderColorBase};
    border-radius: ${({ theme }) => theme.border.radiusSmall};
`;

export const DropdownItem = styled.div`
    width: 100%;
    padding: 8px;
    border-radius: ${({ theme }) => theme.border.radiusSmall};
    color: ${({ theme }) => theme.color.textLight};
    cursor: pointer;

    ${({ selected }) =>
        selected &&
        css`
            color: ${({ theme }) => theme.color.white};
            background-color: ${({ theme }) => theme.color.bgPrimary};
        `}

    &:hover {
        color: ${({ theme }) => theme.color.textLight};
        background-color: ${({ theme }) => theme.color.bgPrimaryLight};

        ${({ selected }) =>
            selected &&
            css`
                color: ${({ theme }) => theme.color.white};
                background-color: ${({ theme }) => theme.color.bgPrimary};
            `}
    }
`;

export const DropdownTitle = styled.div`
    width: 100%;
    padding: 8px;
    border-radius: ${({ theme }) => theme.border.radiusSmall};
    color: ${({ theme }) => theme.color.textLight};
`;

export const Grid = styled.div`
    height: 75%;
    padding: 32px;
    display: grid;
    grid-template-rows: 3fr 3fr 3fr 1fr;
    grid-gap: 16px;
    align-items: center;
    border-bottom: 1px solid;
    border-color: ${({ theme }) => theme.color.bgSearch};
`;

export const TermsAndPrivacyWrapper = styled.div`
    height: 25%;
    padding: 32px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
    align-items: center;
`;

export const TermsAndPrivacyItem = styled.a`
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    text-align: center;
    cursor: pointer;
    color: ${({ theme }) => theme.color.textLight};
`;
