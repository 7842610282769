import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    establishSuperAdminUsersThunk,
    resetSuperAdminUsersThunk,
} from 'redux/actions/thunks/mpaSuperAdminUsers';
import MPASuperAdminUsersView from 'views/MPASuperAdminUsers';

const PAGINATION_THRESHOLD = 10;

const MPASuperAdminUsers = () => {
    const dispatch = useDispatch();
    const { getAccessTokenSilently, logout } = useAuth0();
    const [filter, setFilter] = useState('');
    const [pages, setPages] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);

    const reloadList = () => {
        dispatch(
            establishSuperAdminUsersThunk(
                getAccessTokenSilently,
                logout,
                PAGINATION_THRESHOLD,
                0,
                {
                    role: 'medpower_admin',
                    search: filter,
                }
            )
        );
        setCurrentPage(0);
    };

    const handleChangeFilter = (e) => {
        setFilter(e.target.value);
    };

    const {
        superAdminUsers,
        areSuperAdminUsersFetching,
        amountOfAllSuperAdminUsers,
    } = useSelector((state) => state.mpaSuperAdminUsers);

    useEffect(() => {
        dispatch(
            establishSuperAdminUsersThunk(
                getAccessTokenSilently,
                logout,
                PAGINATION_THRESHOLD,
                0,
                {
                    role: 'medpower_admin',
                    search: filter,
                }
            )
        );

        return dispatch(resetSuperAdminUsersThunk());
    }, []);

    useEffect(() => {
        dispatch(
            establishSuperAdminUsersThunk(
                getAccessTokenSilently,
                logout,
                PAGINATION_THRESHOLD,
                0,
                {
                    role: 'medpower_admin',
                    search: filter,
                }
            )
        );
    }, [filter]);

    const nextPage = () => {
        if (
            isNextEnabled() &&
            currentPage < amountOfAllSuperAdminUsers / PAGINATION_THRESHOLD
        ) {
            setCurrentPage(currentPage + 1);
        }
    };

    const prevPage = () => {
        if (isPrevEnabled() && currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };

    const isNextEnabled = () => {
        if (
            currentPage ===
            Math.floor(amountOfAllSuperAdminUsers / PAGINATION_THRESHOLD)
        ) {
            return false;
        }
        return true;
    };

    const isPrevEnabled = () => {
        if (currentPage === 0) {
            return false;
        }
        return true;
    };

    useEffect(() => {
        if (amountOfAllSuperAdminUsers) {
            const newPages = [];
            for (
                let index = 0;
                index < amountOfAllSuperAdminUsers / PAGINATION_THRESHOLD;
                index += 1
            ) {
                newPages.push({
                    index,
                    pageNumber: index + 1,
                });
            }
            setPages(newPages);
        }
    }, [amountOfAllSuperAdminUsers]);

    useEffect(() => {
        dispatch(
            establishSuperAdminUsersThunk(
                getAccessTokenSilently,
                logout,
                PAGINATION_THRESHOLD,
                currentPage * PAGINATION_THRESHOLD,
                {
                    role: 'medpower_admin',
                    search: filter,
                }
            )
        );
    }, [currentPage]);

    return (
      <MPASuperAdminUsersView
            pages={pages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            nextPage={nextPage}
            prevPage={prevPage}
            isNextEnabled={isNextEnabled}
            isPrevEnabled={isPrevEnabled}
            isFetching={areSuperAdminUsersFetching}
            superAdminUsers={superAdminUsers}
            filter={filter}
            handleChangeFilter={handleChangeFilter}
            reloadList={reloadList}
        />
    );
};

export default MPASuperAdminUsers;
