import styled, { css } from 'styled-components/macro';

const keyframes = css`
    @keyframes spin {
        to {
            transform: rotate(360deg);
        }
    }
    @-webkit-keyframes spin {
        to {
            transform: rotate(360deg);
        }
    }
`;

const StyledSpinner = styled.div`
    ${keyframes}
    display: inline-block;
    width: 50px;
    height: 50px;
    border: 3px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: #eee;
    animation: spin 1s ease-in-out infinite;
    margin: 32px;

    ${({ small }) =>
        small &&
        css`
            margin: 8px;
            width: 20px;
            height: 20px;
        `}
`;

export default StyledSpinner;
