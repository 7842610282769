import styled from 'styled-components/macro';

export const NotificationsWrapper = styled.div``;

export const NotificationsListWrapper = styled.div`
    padding: 0 16px;
`;

export const Divider = styled.div`
    margin-top: 24px;
    margin-bottom: 16px;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    color: ${({ theme }) => theme.color.textLight};
`;

export const VerticalDivider = styled.div`
    height: 33px;
    margin: 0 24px;
    border-right: 1px solid;
    border-color: ${({ theme }) => theme.color.bgSearch};
`;

export const CTAHolder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;
