import React from 'react';
import { AnimatedLogo, StyledSplashScreen } from './SplashScreen.style';

const SplashScreen = () => (
  <StyledSplashScreen>
    <AnimatedLogo size={72} data-testid="logo" />
  </StyledSplashScreen>
);

export default SplashScreen;
