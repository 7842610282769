import React from 'react';
import PropTypes from 'prop-types';

import UploadImage from 'components/UploadImage/UploadImage';
import { FormTitle } from './CreateTeamForm.style';

const TeamPictureUpload = ({ handleUpload }) => (
  <>
    <FormTitle>Team picture</FormTitle>
    <UploadImage handleUpload={handleUpload} />
  </>
);

TeamPictureUpload.propTypes = {
    handleUpload: PropTypes.func.isRequired,
};

export default TeamPictureUpload;
