import React from 'react';
import PropTypes from 'prop-types';
import useTeamName from 'hooks/useTeamName';
import SingleRankedTeam from 'components/SingleTeam/SingleRankedTeam';
import Spinner from 'components/Spinner';
import SectionHeader from 'components/SectionHeader';
import TeamOverviewNavigation from 'containers/TeamOverviewNavigation';
import { ReactComponent as ArrowBackIcon } from 'assets/svg/arrow-back.svg';
import InfiniteScroll from 'react-infinite-scroller';
import { ROUTES } from 'routing/constants';
import theme from 'assets/theme/mainTheme';
import StyledTeamRankingView from './TeamRankingView.style';

const TeamRankingView = ({
    teamId,
    isFetching,
    teams,
    loadFunc,
    hasMore,
    currentUser,
}) => {
    const teamName = useTeamName(teamId);

    if (isFetching) {
        return <Spinner data-testid="spinner" />;
    }

    return (
        <StyledTeamRankingView data-testid="team-ranking-body">
            <SectionHeader
                title={`${teamName} team`}
                withMargin
                goBack={{
                    url: ROUTES.team,
                    label() {
                        return (
                            <>
                                <ArrowBackIcon stroke={theme.color.textLabel} />{' '}
                                <span>Back</span>
                            </>
                        );
                    },
                }}
                cta={() => (
                    <TeamOverviewNavigation
                        teamId={teamId}
                        activeSection="ranking"
                    />
                )}
                collapseOnMobile
            />
            {/* <InfiniteScroll
                loadMore={loadFunc}
                loader={<Spinner />}
                hasMore={hasMore}
                style={{ width: `calc(100% - 32px)` }}
            > */}
            {teams.map((team, index) => (
                <SingleRankedTeam
                    key={team.id}
                    isCurrentUserTeamMember={
                        currentUser &&
                        Boolean(
                            currentUser.userTeams.find((t) => t.id === teamId)
                        )
                    }
                    order={index + 1}
                    title={team.name}
                    usersCount={team.users_count}
                    starsCount={team.stars_count}
                />
            ))}
            {/* </InfiniteScroll> */}
        </StyledTeamRankingView>
    );
};

TeamRankingView.propTypes = {
    teamId: PropTypes.string.isRequired,
    isFetching: PropTypes.bool,
    teams: PropTypes.arrayOf(PropTypes.any),
    loadFunc: PropTypes.func,
    hasMore: PropTypes.bool,
    currentUser: PropTypes.shape({
        id: PropTypes.string,
        userTeams: PropTypes.arrayOf(PropTypes.any),
    }).isRequired,
};

TeamRankingView.defaultProps = {
    isFetching: false,
    teams: [],
    loadFunc: () => {},
    hasMore: false,
};

export default TeamRankingView;
