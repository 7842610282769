import React from 'react';
import { ReactComponent as EmptyStats } from 'assets/svg/no-results/stats.svg';
import { StatsEmptyStateWrapper, Title, Info } from './StatsEmptyState.style';

const StatsEmptyState = () => (
  <StatsEmptyStateWrapper>
    <EmptyStats />
    <Title>Oops!</Title>
    <Info>We are still analyzing your data. Please come back later.</Info>
  </StatsEmptyStateWrapper>
);

export default StatsEmptyState;
