import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import AssignSmartTipModal from 'containers/AssignSmartTipModal';
import { ReactComponent as PencilIcon } from 'assets/svg/pencil-2.svg';
import { ReactComponent as AssignIcon } from 'assets/svg/assign.svg';
import theme from 'assets/theme/mainTheme';
import useDate from 'hooks/useDate';
import { HA_ROUTES, ROUTES } from 'routing/constants';
import {
    SingleTip,
    StatusIndicator,
    StatusLabel,
    IconWrapper,
    BoldText,
    TightText,
    Row,
} from './SmartTipHA.style';

const SmartTipHa = ({
    id,
    name,
    lastUpdate,
    status,
    score,
    assignedUsersCount,
    assignedTeamsCount,
    assignedToAll,
    resetSmartTips,
}) => {
    const history = useHistory();
    const [isAssignModalVisible, setIsAssignModalVisible] = useState(false);

    return (
      <>
        {isAssignModalVisible && (
        <AssignSmartTipModal
                    smartTipId={id}
                    toggleModal={() =>
                        setIsAssignModalVisible(!isAssignModalVisible)}
                    isAssignedToAll={assignedToAll}
                    resetSmartTips={resetSmartTips}
                />
            )}
        <SingleTip>
          <BoldText
                    onClick={() => history.push(`${ROUTES.smartTips}/${id}`)}
                    clickable
                >
            {name}
          </BoldText>
          <TightText>{useDate(new Date(lastUpdate))}</TightText>
          <Row>
            <StatusIndicator
                        published={status === 'published'}
                        review={status === 'review'}
                    />
            <StatusLabel
                        published={status === 'published'}
                        review={status === 'review'}
                    >
              {status.charAt(0).toUpperCase() + status.slice(1)}
            </StatusLabel>
          </Row>
          <TightText>{score || '-'}</TightText>
          <TightText>
            {/* {assignedToAll ? (
                        'All users'
                    ) : ( */}
            <>
              {assignedUsersCount}
              {' '}
              {assignedUsersCount === 1 ? 'user' : 'users'}
              {' '}
              /
              {' '}
              {assignedTeamsCount}
              {' '}
              {assignedTeamsCount === 1 ? 'team' : 'teams'}
            </>
            {/* )} */}
          </TightText>
          <Link
                    to={`${HA_ROUTES.editSmartTip}/${id}`}
                    style={{ maxWidth: '49px' }}
                >
            <IconWrapper>
              <PencilIcon fill={theme.color.textLight} />
            </IconWrapper>
          </Link>
          <IconWrapper
                    onClick={() =>
                        setIsAssignModalVisible(!isAssignModalVisible)}
                >
            <AssignIcon
                        fill={theme.color.textLight}
                        onClick={() =>
                            setIsAssignModalVisible(!isAssignModalVisible)}
                    />
          </IconWrapper>
        </SingleTip>
      </>
    );
};

SmartTipHa.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    lastUpdate: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    score: PropTypes.string,
    assignedUsersCount: PropTypes.number.isRequired,
    assignedTeamsCount: PropTypes.number.isRequired,
    assignedToAll: PropTypes.bool.isRequired,
    resetSmartTips: PropTypes.func.isRequired,
};

SmartTipHa.defaultProps = {
    score: null,
};

export default SmartTipHa;
