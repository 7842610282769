import styled, { css } from 'styled-components/macro';

const disabledStyled = css`
    &:disabled {
        background-color: #f1f6fa;
    }
`;

export const InputBase = styled.input`
    background-color: ${({ theme }) => theme.color.white};
    border: ${({ theme }) => theme.border.base};
    border-radius: ${({ theme }) => theme.border.radiusSmall};
    height: 54px;
    padding: 16px;
    width: 100%;
    transition: padding 100ms;

    &:disabled {
        background-color: ${({ theme }) => theme.color.borderColorBase};
    }

    ${({ label }) =>
        label &&
        css`
            &:focus,
            &:disabled:hover {
                border-color: ${({ theme }) => theme.color.focus};
                padding-top: 24px;
                padding-bottom: 8px;
                transition: padding 100ms;
            }
        `};

    ${({ inline }) =>
        inline &&
        css`
            width: auto;
            &:not(:last-child) {
                margin-right: 16px;
            }
        `};

    ${({ invalid, theme }) =>
        invalid &&
        css`
            border: 1px solid ${theme.color.quaternary};
        `};

    ${({ grow }) =>
        grow &&
        css`
            flex-grow: ${grow};
        `};

    ${({ width }) =>
        width &&
        css`
            width: ${width};
        `};

    &::placeholder {
        color: ${({ theme }) => theme.color.textBase};
        opacity: 60%;
    }

    ${({ error }) =>
        error &&
        css`
            && {
                border-color: ${({ theme }) => theme.color.quaternary};
            }

            && + span {
                color: ${({ theme }) => theme.color.quaternary};
            }
        `};

    ${({ customStyles }) =>
        customStyles &&
        css`
            ${customStyles};
        `};

    ${disabledStyled};
`;

export const InputWithIconHolder = styled.div`
    position: relative;
    cursor: pointer;

    svg {
        position: absolute;
        top: 50%;
        left: 16px;
        transform: translateY(-50%);
    }

    input {
        padding-left: 44px;
        ${disabledStyled};
    }

    ${({ dropdown }) =>
        dropdown &&
        css`
            svg {
                right: 16px;
                left: auto;
            }

            input {
                padding-left: 16px;
                padding-right: 44px;
            }
        `};

    ${({ customStyles }) =>
        customStyles &&
        css`
            ${customStyles};
        `};
`;

export const InputWithLabelHolder = styled.div`
    display: flex;
    align-items: center;

    input {
        margin-left: 16px;
        ${disabledStyled};
        max-width: 200px;
    }

    ${({ customStyles }) =>
        customStyles &&
        css`
            ${customStyles};
        `};
`;

export const InputOuterLabel = styled.span`
    color: ${({ theme }) => theme.color.textLight};
    padding: 0 16px;
`;

export const InputGroup = styled.div`
    width: 100%;
    margin: 8px 0;
    display: flex;
    flex-basis: 100%;

    ${({ noflex }) =>
        noflex &&
        css`
            display: block;
        `};

    ${({ pullright }) =>
        pullright &&
        css`
            justify-content: flex-end;
        `};

    ${({ customStyles }) =>
        customStyles &&
        css`
            ${customStyles};
        `};

    ${({ dropdown }) =>
        dropdown &&
        css`
            position: relative;

            input {
                cursor: pointer;
            }
        `};

    ${({ noMargin }) =>
        noMargin &&
        css`
            margin: 0;
        `};
`;

export const InputDropdownBody = styled.div`
    width: 100%;
    position: absolute;
    padding: 16px;
    border-radius: ${({ theme }) => theme.border.radiusSmall};
    border: ${({ theme }) => theme.border.base};
    background-color: ${({ theme }) => theme.color.white};
    box-shadow: ${({ theme }) => theme.boxShadow.base};
    top: calc(100% - 1px);
    z-index: 1010;
    max-height: 320px;
    /* TODO: check it (replace hidden with auto) */
    /* overflow-y: hidden; */
    overflow-y: auto;
    display: none;
    flex-direction: column;

    ${({ smallerDropdown }) =>
        smallerDropdown &&
        css`
            max-height: 150px;
        `}

    ${({ isVisible }) =>
        isVisible &&
        css`
            display: flex;
        `}

    ${disabledStyled};
`;

export const InputApiDropdownBody = styled(InputDropdownBody)`
    height: 264px;
    overflow-y: scroll;
`;

export const InputDropdownTitle = styled.span`
    color: ${({ theme }) => theme.color.textNavigationCounter};
    display: block;
    margin: 8px 8px 16px;
`;

export const InputDropdownOption = styled.span`
    color: ${({ theme }) => theme.color.base};
    display: block;
    cursor: pointer;
    padding: 8px;
    border-radius: 8px;

    &:hover {
        color: ${({ theme }) => theme.color.primary};
        background-color: ${({ theme }) => theme.color.bgPrimaryLight};
    }

    ${({ withImg }) =>
        withImg &&
        css`
            display: flex;
            align-items: center;

            img {
                width: 32px;
                height: 32px;
                border-radius: 16px;
                margin-right: 16px;
            }

            span {
                white-space: nowrap;
            }

            small {
                color: ${({ theme }) => theme.color.textLight};
                line-height: 1.6;
            }
        `}
`;

export const InputDropdownSelectBody = styled.div`
    width: 100%;
    position: absolute;
    border-radius: ${({ theme }) => theme.border.radiusSmall};
    background-color: ${({ theme }) => theme.color.white};
    box-shadow: ${({ theme }) => theme.boxShadow.base};
    top: 100%;
    z-index: 1010;
    max-height: 320px;
    overflow-y: hidden;
    display: none;
    flex-direction: column;
    cursor: pointer;
    padding: 16px 0 0;

    ${({ isVisible }) =>
        isVisible &&
        css`
            display: flex;
        `}

    ${({ width }) =>
        width &&
        css`
            width: ${width};
        `}

    ${({ verticalPadding }) =>
        verticalPadding &&
        css`
            padding: 16px 0;
        `}

    ${disabledStyled};
`;

export const InputDropdownSelectOption = styled.span`
    color: ${({ theme }) => theme.color.base};
    cursor: pointer;
    padding: 16px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 2px 16px;

    &:hover {
        color: ${({ theme }) => theme.color.primary};
        background-color: ${({ theme }) => theme.color.bgPrimaryLight};
    }
`;

export const OptionsGroup = styled.div`
    height: 240px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;

    ${({ height }) =>
        height &&
        css`
            height: ${height};
        `}
`;

export const CtaGroup = styled.div`
    border-top: ${({ theme }) => theme.border.base};
    padding: 16px;
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;

    button {
        margin-left: 16px;
    }
`;

export const InputError = styled.div`
    color: ${({ theme }) => theme.color.quaternary};
    margin-top: 6px;
    padding-left: 18px;
`;

export const InputLabel = styled.span`
    && {
        position: absolute;
        left: 16px;
        top: 8px;
        color: ${({ theme }) => theme.color.textLabel};
        font-size: ${({ theme }) => theme.fontSize.small};
        opacity: 0;
        height: 0;
        pointer-events: none;
    }
`;

export const InputContainer = styled.span`
    position: relative;
    width: 100%;

    input:focus + span,
    input:disabled:hover + span {
        opacity: 1;
        height: auto;
        transition: opacity 100ms;
    }

    ${({ customStyles }) =>
        customStyles &&
        css`
            ${customStyles};
        `};
`;

export const EmojiIcon = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    width: 54px;
    height: 54px;
    border-radius: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
`;
