import {
    establishOrganizationUsers,
    establishOrganizationUsersFailure,
    establishOrganizationUsersSuccess,
    loadMoreOrganizationUsers,
    toggleUserStatus,
    toggleUserStatusSuccess,
    toggleUserStatusFailure,
    deleteOrganizationUser,
    deleteOrganizationUserSuccess,
    deleteOrganizationUserFailure,
    allUsersLoaded,
    resetOrganizationUsers,
    isLoadingOrganizationUsers,
} from 'redux/actions/organizationUsers';
import apiRoutes from 'config/apiRoutes';
import paramsToString from 'redux/helpers/paramsToString';
import downloadBlob from 'redux/helpers/downloadBlob';
import {
    dispatchErrorAlertThunk,
    dispatchSuccessAlertThunk,
} from './systemAlerts';

export const establishOrganizationUsersThunk =
    (getAccessTokenSilently, logout, limit = 20, offset = 0, params = {}) =>
    (dispatch) => {
        dispatch(establishOrganizationUsers());

        const url =
            apiRoutes.get.organizationUsers(limit, offset) +
            paramsToString(params);

        return getAccessTokenSilently()
            .then((token) => {
                fetch(url, {
                    method: 'GET',
                    headers: {
                        organization_slug:
                            window.localStorage.getItem('MP_ORGANIZATION'),
                        Authorization: `Token token="${token}"`,
                    },
                })
                    .then((res) => res.json())
                    .then((res) => {
                        dispatch(isLoadingOrganizationUsers(true));
                        if (res.error) {
                            return dispatch(
                                establishOrganizationUsersFailure(res.error)
                            );
                        }

                        return dispatch(establishOrganizationUsersSuccess(res));
                    })
                    .catch((err) =>
                        dispatch(
                            establishOrganizationUsersFailure({
                                err,
                            })
                        )
                    )
                    .finally(() => {
                        dispatch(isLoadingOrganizationUsers(false));
                    });
            })
            .catch(() => logout({ returnTo: window.location.origin }));
    };

export const loadMoreOrganizationUsersThunk =
    (getAccessTokenSilently, logout, limit, offset, params = {}) =>
    (dispatch) => {
        dispatch(establishOrganizationUsers());
        const url =
            apiRoutes.get.organizationUsers(limit, offset) +
            paramsToString(params);

        return getAccessTokenSilently()
            .then((token) => {
                dispatch(isLoadingOrganizationUsers(true));
                fetch(url, {
                    method: 'GET',
                    headers: {
                        organization_slug:
                            window.localStorage.getItem('MP_ORGANIZATION'),
                        Authorization: `Token token="${token}"`,
                    },
                })
                    .then((res) => res.json())
                    .then((res) => {
                        if (res.error) {
                            return dispatch(
                                establishOrganizationUsersFailure(res.error)
                            );
                        }

                        if (res.users.length === 0) {
                            return dispatch(allUsersLoaded());
                        }

                        return dispatch(loadMoreOrganizationUsers(res));
                    })
                    .catch((err) =>
                        dispatch(
                            establishOrganizationUsersFailure({
                                err,
                            })
                        )
                    )
                    .finally(() => dispatch(isLoadingOrganizationUsers(false)));
            })
            .catch(() => logout({ returnTo: window.location.origin }));
    };

export const toggleUserStatusThunk =
    (getAccessTokenSilently, logout, userId) => (dispatch) => {
        dispatch(toggleUserStatus());

        return getAccessTokenSilently()
            .then((token) => {
                fetch(apiRoutes.put.toggleUserStatus(userId), {
                    method: 'PUT',
                    headers: {
                        organization_slug:
                            window.localStorage.getItem('MP_ORGANIZATION'),
                        Authorization: `Token token="${token}"`,
                    },
                })
                    .then((res) => res.json())
                    .then((res) => {
                        if (res.error) {
                            return dispatch(toggleUserStatusFailure(res.error));
                        }

                        return dispatch(toggleUserStatusSuccess());
                    })
                    .catch((err) =>
                        dispatch(
                            toggleUserStatusFailure({
                                err,
                            })
                        )
                    );
            })
            .catch(() => logout({ returnTo: window.location.origin }));
    };

export const deleteOrganizationUserThunk =
    (getAccessTokenSilently, logout, userId, resetUsersList) => (dispatch) => {
        dispatch(deleteOrganizationUser());

        return getAccessTokenSilently()
            .then((token) => {
                fetch(apiRoutes.delete.deleteOrganizationUser(userId), {
                    method: 'DELETE',
                    headers: {
                        organization_slug:
                            window.localStorage.getItem('MP_ORGANIZATION'),
                        Authorization: `Token token="${token}"`,
                    },
                })
                    .then((res) => {
                        if (res.error || +res.status > 201) {
                            return Promise.reject(
                                new Error({
                                    res,
                                    error: res.statusText,
                                })
                            );
                        }
                        dispatch(
                            dispatchSuccessAlertThunk(
                                'deleteUser',
                                'User deleted'
                            )
                        );
                        if (resetUsersList) resetUsersList();
                        return dispatch(deleteOrganizationUserSuccess(userId));
                    })
                    .catch((err) => {
                        dispatch(dispatchErrorAlertThunk(err, err.error));
                        dispatch(
                            deleteOrganizationUserFailure({
                                err,
                            })
                        );
                    });
            })
            .catch(() => logout({ returnTo: window.location.origin }));
    };

export const resetOrganizationUsersThunk = () => (dispatch) => {
    dispatch(resetOrganizationUsers());
};

export const downloadUsersTemplateThunk =
    (getAccessTokenSilently, logout) => () =>
        getAccessTokenSilently()
            .then((token) => {
                fetch(apiRoutes.get.downloadUsersTemplate(), {
                    method: 'GET',
                    headers: {
                        organization_slug:
                            window.localStorage.getItem('MP_ORGANIZATION'),
                        Authorization: `Token token="${token}"`,
                    },
                })
                    .then((res) => res.blob())
                    .then((res) => downloadBlob(res, 'users-template.csv'));
            })
            .catch(() => logout({ returnTo: window.location.origin }));

export const downloadUsersResultsThunk =
    (getAccessTokenSilently, logout, params = {}) =>
    (dispatch) => {
        const url = apiRoutes.get.organizationUsersCSVExport(
            paramsToString(params)
        );

        return getAccessTokenSilently()
            .then((token) => {
                fetch(url, {
                    method: 'GET',
                    headers: {
                        organization_slug:
                            window.localStorage.getItem('MP_ORGANIZATION'),
                        Authorization: `Token token="${token}"`,
                        'Content-Type': 'text/csv',
                    },
                })
                    .then((res) => res.blob())
                    .then((res) => downloadBlob(res, 'users-export.csv'))
                    .catch((err) =>
                        dispatch(
                            establishOrganizationUsersFailure({
                                err,
                            })
                        )
                    );
            })
            .catch(() => logout({ returnTo: window.location.origin }));
    };
