const theme = {
    color: {
        primary: '#377FFF', // blue
        primaryLight: '#c9dbfb',
        primaryDark: '#2666F6', // blue
        secondaryLight: '#e4f9f8', // light green
        secondary: '#31D4C8', // green
        secondaryDark: '#0DA89C', // green
        tertiary: '#FFBB38', // yellow
        tertiaryAlt: '#FDCB6E',
        quaternary: '#FC83A2', // red
        quaternaryAlt: '#FCA783',
        white: '#ffffff',
        black: '#000000',
        focus: 'rgba(85, 127, 255, 0.5)',
        violet: '#766BFE',
        violetLight: '#F3F2FF',
        violetDark: '#6256F1',
        violetVeryDark: '#524bb8',
        pinkLight: '#FFEEF3',
        gold: '#FCB839',
        goldLight: '#FFF8EC',
        silver: '#C8C8C8',
        bronze: '#937676',
        paleGreen: '#70E1E1',
        checkGreen: '#2DCBCB',
        red: '#FB4C79',
        grey: '#C6C6C6',

        textBase: '#343C6A',
        textLight: '#718FBF',
        textNavigation: '#505887',
        textNavigationCounter: '#6F769E',
        textNavigationLight: 'rgba(113, 143, 191, 0.5)',
        textNavigationSuperLight: '#8AA9F0',
        textLabel: '#6CA1FF',

        bgBase: '#fbfcfe',
        bgGrey: '#F4F7FD',
        bgPrimary: '#557FFF',
        bgPrimaryLight: '#EEF5FF',
        bgPrimarySuperLight: '#FBFCFE',
        bgSecondary: '#2FCDC1',
        bgSecondaryLight: 'rgba(49, 212, 200, 0.2)',
        bgTertiary: '#EFA923',
        bgTertiaryLight: 'rgba(255, 187, 56, 0.2);',
        bgQuaternary: '#FC83A2',
        bgQuaternaryLight: '#FFF2F8',
        bgSearch: '#EBF0FA',
        bgModal: 'rgba(34, 41, 81, 0.6)',

        blue: '#356CED',
        darkBlue: '#1953d5',

        borderColorBase: '#E7F0F7',

        lightHoverBg: '#F8F9FD',

        shadowColorBase: 'rgba(0, 48, 135, 0.12)',

        mpaSidebarBg: '#1B273F',
        mpaItemText: '#8D93B1',
        mpaActiveItem: '#101A2F',
    },
    border: {
        base: `1px solid #E7F0F7`,
        bold: `3px solid #E7F0F7`,
        radiusBase: '16px',
        radiusSmall: '10px',
        radiusX2: '32px',
        bottomBorderRadius: '0px 0px 20px 20px',
    },
    boxShadow: {
        base: `0px 8px 60px rgba(0, 48, 135, 0.12)`,
    },
    fontSize: {
        base: '14px',
        pageHeading: '20px',
        sectionHeading: '18px',
        big: '16px',
        medium: '14px',
        small: '12px',
        smaller: '10px',
        huge: '32px',
    },
    breakpoints: {
        xs: 560,
        s: 768,
        m: 810,
        l: 1140,
        xl: 1200,
        mobile: 1024,
        web: 1536,

        large: 1680,
        medium: 1025,
        small: 540,
    },
};

export default theme;
