import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import establishCoursesThunk from 'redux/actions/thunks/courses';
import CoursesView from 'views/Courses';
import { useAuth0 } from '@auth0/auth0-react';

const Courses = () => {
    const { getAccessTokenSilently, logout } = useAuth0();
    const dispatch = useDispatch();
    const { areCoursesEstablished, areCoursesFetching, courses } = useSelector(
        (state) => state.courses
    );

    useEffect(() => {
        if (!areCoursesEstablished && !areCoursesFetching) {
            dispatch(establishCoursesThunk(getAccessTokenSilently, logout));
        }
    }, []);

    return <CoursesView isFetching={areCoursesFetching} courses={courses} />;
};

export default Courses;
