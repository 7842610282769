import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Input from 'components/Input';
import { ROLES_TO_SELECT } from 'constants.js';
import { ReactComponent as DropdownIcon } from 'assets/svg/dropdown-down.svg';
import theme from 'assets/theme/mainTheme';
import {
    StyledFilterSet,
    StyledFiltersContainer,
    RoleDropdownWrapper,
    Dropdown,
    DropdownItem,
    Role,
} from './UsersFilters.style';
import { ClassificationTagDropdown } from '../ClassificationTagDropdown/ClassificationTagDropdown';
import {
    FilterLabel,
    SeparatorLine,
    SmartFilterClearBtnContainer,
    SmartTipFiltersContainer,
} from '../../containers/BrowseAvailableSmartTipsForProvider/styled';
import Button from '../Button';

const UsersFilters = ({ clearFilters, filters }) => {
    const { role, name, team, tags } = filters;
    const roleDropdownRef = useRef(null);

    const [isTeamSelectDropdownVisible, setIsTeamSelectDropdownVisible] =
        useState(false);

    const [isRoleSelectDropdownVisible, setIsRoleSelectDropdownVisible] =
        useState(false);

    const [queryUserName, setQueryUserName] = useState('');

    useEffect(() => {
        const timeoutId = setTimeout(() => name.setFilter(queryUserName), 500);
        return () => clearTimeout(timeoutId);
    }, [queryUserName]);

    useEffect(() => {
        if (isTeamSelectDropdownVisible) {
            setIsRoleSelectDropdownVisible(false);
        }
    }, [isTeamSelectDropdownVisible]);

    useEffect(() => {
        if (isRoleSelectDropdownVisible) {
            setIsTeamSelectDropdownVisible(false);
        }
    }, [isRoleSelectDropdownVisible]);

    useEffect(() => {
        if (team.value && team.value.length > 0) {
            setIsTeamSelectDropdownVisible(true);
        }
    }, [team]);

    const handleClickOutside = (e) => {
        if (
            roleDropdownRef.current &&
            roleDropdownRef.current.contains(e.target)
        ) {
            return;
        }
        setIsRoleSelectDropdownVisible(false);
    };

    useEffect(() => {
        if (isRoleSelectDropdownVisible) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isRoleSelectDropdownVisible]);

    const calculateValue = () => {
        if (!team.value) {
            return '';
        }

        return team.value.title;
    };

    return (
        <SmartTipFiltersContainer>
            <StyledFilterSet>
                <FilterLabel>Filters</FilterLabel>
            </StyledFilterSet>
            <StyledFiltersContainer style={{ paddingBottom: 0 }}>
                <StyledFilterSet>
                    <Input
                        data-testid="input-name-search"
                        variant="base"
                        placeholder="User Name"
                        value={queryUserName}
                        onChange={({ target }) =>
                            setQueryUserName(target.value)
                        }
                    />
                </StyledFilterSet>

                <StyledFilterSet>
                    <RoleDropdownWrapper ref={roleDropdownRef}>
                        <Role
                            onClick={() =>
                                setIsRoleSelectDropdownVisible(
                                    !isRoleSelectDropdownVisible
                                )
                            }
                        >
                            {ROLES_TO_SELECT[role.value]
                                ? ROLES_TO_SELECT[role.value]
                                : 'User Type'}
                            <DropdownIcon fill={theme.color.textBase} />
                        </Role>
                        {isRoleSelectDropdownVisible && (
                            <Dropdown>
                                {Object.keys(ROLES_TO_SELECT).map((option) => (
                                    <DropdownItem
                                        key={option}
                                        isSelected={role.value === option}
                                        onClick={() => {
                                            setIsRoleSelectDropdownVisible(
                                                false
                                            );
                                            if (role.value === option) {
                                                role.setFilter(undefined);
                                            } else {
                                                role.setFilter(option);
                                            }
                                        }}
                                    >
                                        {ROLES_TO_SELECT[option]}
                                    </DropdownItem>
                                ))}
                            </Dropdown>
                        )}
                    </RoleDropdownWrapper>
                </StyledFilterSet>

                <StyledFilterSet>
                    <Input
                        data-testid="input-team-select"
                        variant="searchDropdown"
                        placeholder={
                            team.value ? team.value.title : 'Team Name'
                        }
                        options={team.options}
                        onClick={() => {
                            setIsTeamSelectDropdownVisible(
                                !isTeamSelectDropdownVisible
                            );
                        }}
                        value={calculateValue(team)}
                        onChange={({ target }) => {
                            team.setFilter(target.value);
                        }}
                        handleSelect={(id) => {
                            team.setFilter(
                                team.options.find((item) => item.id === id)
                            );
                            setIsTeamSelectDropdownVisible(false);
                        }}
                        dropdownVisible={isTeamSelectDropdownVisible}
                        displaySelectButtons={false}
                        customDropdownSelectBodyWidth="270px"
                    />
                </StyledFilterSet>
            </StyledFiltersContainer>

            <div style={{ marginLeft: 12 }}>
                <ClassificationTagDropdown
                    currentlySavedTags={tags.value}
                    setClassificationTags={tags.setFilter}
                    layout="horizontal"
                    localMode
                />
            </div>

            <SeparatorLine />

            <SmartFilterClearBtnContainer>
                <Button primary onClick={clearFilters}>
                    Clear filters
                </Button>
            </SmartFilterClearBtnContainer>
        </SmartTipFiltersContainer>
    );
};

UsersFilters.propTypes = {
    clearFilters: PropTypes.func.isRequired,
    filters: PropTypes.shape({
        role: PropTypes.shape({
            value: PropTypes.string,
            options: PropTypes.arrayOf(PropTypes.any),
            setFilter: PropTypes.func,
        }),
        name: PropTypes.shape({
            value: PropTypes.string,
            setFilter: PropTypes.func,
        }),
        tags: PropTypes.shape({
            value: PropTypes.string,
            setFilter: PropTypes.func,
        }),
        team: PropTypes.shape({
            value: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.shape({
                    id: PropTypes.string,
                    title: PropTypes.string,
                }),
            ]),
            setFilter: PropTypes.func,
            options: PropTypes.arrayOf(PropTypes.any),
        }),
    }).isRequired,
};

export default UsersFilters;
