export const ESTABLISH_USER_DETAILS = 'ESTABLISH_USER_DETAILS';
export const ESTABLISH_USER_DETAILS_SUCCESS = 'ESTABLISH_USER_DETAILS_SUCCESS';
export const ESTABLISH_USER_DETAILS_FAILURE = 'ESTABLISH_USER_DETAILS_FAILURE';

export const establishUserDetails = () => ({
    type: ESTABLISH_USER_DETAILS,
});

export const establishUserDetailsSuccess = (payload) => ({
    type: ESTABLISH_USER_DETAILS_SUCCESS,
    payload,
});

export const establishUserDetailsFailure = (err) => ({
    type: ESTABLISH_USER_DETAILS_FAILURE,
    err,
});
