import {
    establishOrganizationAssignSmartTipAssignedUsers,
    loadMoreEstablishOrganizationAssignSmartTipAssignedUsersSuccess,
    establishOrganizationAssignSmartTipAssignedUsersSuccess,
    establishOrganizationAssignSmartTipAssignedUsersFailure,
    establishOrganizationAssignSmartTipAssignedTeams,
    establishOrganizationAssignSmartTipAssignedTeamsSuccess,
    establishOrganizationAssignSmartTipAssignedTeamsFailure,
    resetUsersAndTeamsLists,
    allUsersLoaded,
    stopLoadingAssignSmartTipUser,
    isLoading,
} from 'redux/actions/organizationAssignSmartTip';
import apiRoutes from 'config/apiRoutes';

export const establishOrganizationAssignSmartTipAssignedUsersThunk =
    (getAccessTokenSilently, logout, smartTipId, limit, offset) =>
    (dispatch) => {
        dispatch(isLoading(true));
        dispatch(establishOrganizationAssignSmartTipAssignedUsers());

        return getAccessTokenSilently()
            .then((token) => {
                fetch(
                    apiRoutes.get.usersAssignedToSmartTip(
                        smartTipId,
                        limit,
                        offset
                    ),
                    {
                        method: 'GET',
                        headers: {
                            organization_slug:
                                window.localStorage.getItem('MP_ORGANIZATION'),
                            Authorization: `Token token="${token}"`,
                        },
                    }
                )
                    .then((res) => res.json())
                    .then((res) => {
                        if (res.error) {
                            return dispatch(
                                establishOrganizationAssignSmartTipAssignedUsersFailure(
                                    res.error
                                )
                            );
                        }

                        if (res.users.length === 0) {
                            return dispatch(allUsersLoaded());
                        }

                        return dispatch(
                            establishOrganizationAssignSmartTipAssignedUsersSuccess(
                                res.users
                            )
                        );
                    })
                    .catch((err) => {
                        dispatch(
                            establishOrganizationAssignSmartTipAssignedUsersFailure(
                                {
                                    err,
                                }
                            )
                        );
                    })
                    .finally(() => dispatch(isLoading(false)));
            })
            .catch(() => logout({ returnTo: window.location.origin }));
    };

export const loadMoreOrganizationAssignSmartTipAssignedUsersThunk =
    (getAccessTokenSilently, logout, smartTipId, limit, offset) =>
    (dispatch) => {
        dispatch(establishOrganizationAssignSmartTipAssignedUsers());

        return getAccessTokenSilently()
            .then((token) => {
                dispatch(isLoading(true));
                fetch(
                    apiRoutes.get.usersAssignedToSmartTip(
                        smartTipId,
                        limit,
                        offset
                    ),
                    {
                        method: 'GET',
                        headers: {
                            organization_slug:
                                window.localStorage.getItem('MP_ORGANIZATION'),
                            Authorization: `Token token="${token}"`,
                        },
                    }
                )
                    .then((res) => res.json())
                    .then((res) => {
                        if (res.error) {
                            return dispatch(
                                establishOrganizationAssignSmartTipAssignedUsersFailure(
                                    res.error
                                )
                            );
                        }

                        if (res.users.length === 0) {
                            return dispatch(allUsersLoaded());
                        }

                        return dispatch(
                            loadMoreEstablishOrganizationAssignSmartTipAssignedUsersSuccess(
                                res.users
                            )
                        );
                    })
                    .catch((err) =>
                        dispatch(
                            establishOrganizationAssignSmartTipAssignedUsersFailure(
                                {
                                    err,
                                }
                            )
                        )
                    )
                    .finally(() => dispatch(isLoading(false)));
            })
            .catch(() => logout({ returnTo: window.location.origin }));
    };

export const establishOrganizationAssignSmartTipAssignedTeamsThunk =
    (getAccessTokenSilently, logout, smartTipId) => (dispatch) => {
        dispatch(establishOrganizationAssignSmartTipAssignedTeams());

        return getAccessTokenSilently()
            .then((token) => {
                fetch(apiRoutes.get.teamsAssignedToSmartTip(smartTipId), {
                    method: 'GET',
                    headers: {
                        organization_slug:
                            window.localStorage.getItem('MP_ORGANIZATION'),
                        Authorization: `Token token="${token}"`,
                    },
                })
                    .then((res) => res.json())
                    .then((res) => {
                        if (res.error) {
                            return dispatch(
                                establishOrganizationAssignSmartTipAssignedTeamsFailure(
                                    res.error
                                )
                            );
                        }

                        return dispatch(
                            establishOrganizationAssignSmartTipAssignedTeamsSuccess(
                                res.teams
                            )
                        );
                    })
                    .catch((err) =>
                        dispatch(
                            establishOrganizationAssignSmartTipAssignedTeamsFailure(
                                {
                                    err,
                                }
                            )
                        )
                    );
            })
            .catch(() => logout({ returnTo: window.location.origin }));
    };

export const resetUsersAndTeamsListsThunk = () => (dispatch) => {
    dispatch(resetUsersAndTeamsLists());
};
