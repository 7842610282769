import styled from 'styled-components/macro';

const getCircleColor = (type, theme) => {
    switch (type) {
        case 'informational_memo':
            return theme.color.textLight;
        case 'critical_memo':
            return theme.color.quaternary;
        default:
            return theme.color.violet;
    }
};

const getPathColor = (type, theme) => {
    switch (type) {
        case 'informational_memo':
            return theme.color.bgPrimaryLight;
        case 'critical_memo':
            return theme.color.pinkLight;
        default:
            return theme.color.violetLight;
    }
};

export const SingleAdminMemoHolder = styled.div`
    width: 100%;
    padding: 32px;
    min-height: 110px;
    background-color: ${({ theme }) => theme.color.white};
    border: ${({ theme }) => theme.border.base};
    border-radius: ${({ theme }) => theme.border.radiusBase};
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const IconHolder = styled.div`
    position: relative;
    width: 48px;
    height: 48px;
    margin-right: 32px;

    circle {
        fill: ${({ type, theme }) => getCircleColor(type, theme)};
    }

    path {
        fill: ${({ type, theme }) => getPathColor(type, theme)};
    }

    .dot {
        position: absolute;
        right: -2px;
        top: 2px;

        circle {
            fill: ${({ theme }) => theme.color.quaternary};
        }
    }
`;

export const MiddleSection = styled.div`
    margin-right: 32px;
`;

export const Name = styled.div`
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: ${({ theme }) => theme.color.textBase};
    padding-bottom: 8px;
`;

export const DateHolder = styled.div`
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    color: ${({ theme }) => theme.color.textLight};
`;

export const ActionsHolder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: auto;

    svg {
        cursor: pointer;
    }

    p {
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        color: ${({ theme }) => theme.color.textLight};
    }
`;
