import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector, useDispatch } from 'react-redux';
import { toggleNotificationsOptionsThunk } from 'redux/actions/thunks/notifications';
import NotificationsOptionsDropdownView from 'views/NotificationsOptionsDropdown';

const NotificationsOptionsDropdown = () => {
    const dispatch = useDispatch();
    const { getAccessTokenSilently, logout } = useAuth0();
    const [isVisible, setIsVisible] = useState(false);

    const {
        isScoreNotificationsEnabled,
        isMessageNotificationsEnabled,
        isEmailNotificationsEnabled,
    } = useSelector((state) => state.currentUser);

    const toggleVisible = () => {
        setIsVisible(!isVisible);
    };

    const toggleScoreNotificationsOptions = () => {
        dispatch(
            toggleNotificationsOptionsThunk(
                getAccessTokenSilently,
                logout,
                !isScoreNotificationsEnabled,
                isMessageNotificationsEnabled,
                isEmailNotificationsEnabled
            )
        );
    };

    const toggleMessageNotificationsOptions = () => {
        dispatch(
            toggleNotificationsOptionsThunk(
                getAccessTokenSilently,
                logout,
                isScoreNotificationsEnabled,
                !isMessageNotificationsEnabled,
                isEmailNotificationsEnabled
            )
        );
    };

    const toggleEmailNotificationsOptions = () => {
        dispatch(
            toggleNotificationsOptionsThunk(
                getAccessTokenSilently,
                logout,
                isScoreNotificationsEnabled,
                isMessageNotificationsEnabled,
                !isEmailNotificationsEnabled
            )
        );
    };

    return (
      <NotificationsOptionsDropdownView
            isScoreNotificationsEnabled={isScoreNotificationsEnabled}
            isMessageNotificationsEnabled={isMessageNotificationsEnabled}
            isEmailNotificationsEnabled={isEmailNotificationsEnabled}
            isVisible={isVisible}
            toggleVisible={toggleVisible}
            toggleEmailNotificationsOptions={toggleEmailNotificationsOptions}
            toggleScoreNotificationsOptions={toggleScoreNotificationsOptions}
            toggleMessageNotificationsOptions={
                toggleMessageNotificationsOptions
            }
        />
    );
};

export default NotificationsOptionsDropdown;
