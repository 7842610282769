import styled, { css } from 'styled-components/macro';

export const StyledSmartTips = styled.div`
    width: 1068px;
    height: 294px;
    position: relative;
    overflow: hidden;
`;

export const StyledButton = styled.button`
    width: 55px;
    height: 55px;
    border-radius: 50%;
    border: ${({ theme }) => theme.border.base};
    background-color: ${({ theme }) => theme.color.white};
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    top: 50%;
    left: 16px;
    transform: translate(-50%, -50%);
    z-index: 150;

    ${({ isRightSide }) =>
        isRightSide &&
        css`
            left: auto;
            right: 16px;
            transform: translate(50%, -50%);
        `}
`;

export const StyledTipsHolder = styled.div`
    width: ${({ propWidth }) => `${propWidth * 534}px`};
    content: ${({ propWidth }) => `${propWidth * 534}px`};
    height: 294px;
    display: flex;
    flex-wrap: nowrap;
`;
export const StyledTipsWrapper = styled.div`
    width: 1068px;
    height: 294px;
    position: relative;
`;

export const StyledCongratulationsModal = styled.div`
    width: 350px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 16px 32px;

    svg,
    h3,
    span,
    button {
        margin: 12px 0;
    }

    h3 {
        font-size: ${({ theme }) => theme.fontSize.sectionHeading};
        font-weight: 500;
    }
    span {
        font-size: ${({ theme }) => theme.fontSize.small};
        color: ${({ theme }) => theme.color.textLight};

        a {
            color: ${({ theme }) => theme.color.primary};
        }
    }

    button {
        width: 100%;
    }
`;
