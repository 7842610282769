import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { css } from 'styled-components/macro';
import Button from 'components/Button';
import DropdownButton from 'components/DropdownButton';
import theme from 'assets/theme/mainTheme';
import DataSourcesMeasuresCTAHolder from './DataSourcesMeasuresCTA.style';

const btnStyles = css`
    color: ${theme.color.primary};
    margin-left: 0 !important;
`;

const DataSourcesMeasuresCTA = ({
    handleMetricClick,
    handleResultsClick,
    downloadTemplate,
}) => {
    const [dropdownVisible, setDropdownVisible] = useState(false);

    return (
      <DataSourcesMeasuresCTAHolder>
        <Button primary onClick={() => downloadTemplate()}>
          Download CSV template
        </Button>
        <DropdownButton
                primary
                label="Import"
                dropdownVisible={dropdownVisible}
                setDropdownVisible={setDropdownVisible}
            >
          <Button
                    white
                    customStyles={btnStyles}
                    onClick={() => {
                        handleMetricClick();
                        setDropdownVisible(false);
                    }}
                >
            Import results
          </Button>
        </DropdownButton>
        <Button primaryDark onClick={() => handleResultsClick()}>
          View Results
        </Button>
      </DataSourcesMeasuresCTAHolder>
    );
};

DataSourcesMeasuresCTA.propTypes = {
    handleMetricClick: PropTypes.func.isRequired,
    handleResultsClick: PropTypes.func.isRequired,
    downloadTemplate: PropTypes.func.isRequired,
};

export default DataSourcesMeasuresCTA;
