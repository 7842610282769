import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import establishMetricSourcesMpaThunk from 'redux/actions/thunks/mpaMetricSources';
import downloadBlob from 'redux/helpers/downloadBlob';
import MetricsMPA from 'containers/Metrics/MPAMetrics';
import theme from 'assets/theme/mainTheme';
import SectionHeader from 'components/SectionHeader';
import ProcessedResultsMPA from 'containers/ProcessedResults/ProcessedResultsMPA';
import Button from 'components/Button';
import Spinner from 'components/Spinner';
import apiRoutes from 'config/apiRoutes';
import ImportModal from 'components/ImportModal/ImportModal';
import { ReactComponent as ArrowDown } from 'assets/svg/dropdown-down.svg';
import { ReactComponent as ArrowUp } from 'assets/svg/dropdown-up.svg';
import { useAuth0 } from '@auth0/auth0-react';
import {
    CTAWrapper,
    DropdownWrapper,
    Dropdown,
    DropdownItem,
} from './MPADataSourcesMeasures.style';

const DataSourcesMeasuresMPA = () => {
    const { getAccessTokenSilently, logout } = useAuth0();
    const [isUploading, setIsUploading] = useState(false);
    const [isReadyToClose, setIsReadyToClose] = useState(false);
    const [isImportModalVisible, setIsImportModalVisible] = useState(
        document.location.hash.includes('importDataSources')
    );
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [downloadInProgress, setDownloadInProgress] = useState(false);
    const dropdownRef = useRef(null);
    const dispatch = useDispatch();

    const { areMetricSourcesEstablished, metricSources } = useSelector(
        (state) => state.mpaMetricSources
    );

    useEffect(() => {
        dispatch(
            establishMetricSourcesMpaThunk(getAccessTokenSilently, logout)
        );
    }, []);

    const downloadCSVTemplate = () => {
        setDownloadInProgress(true);
        getAccessTokenSilently()
            .then((token) => {
                fetch(apiRoutes.get.downloadTemplateAdmin, {
                    method: 'GET',
                    headers: {
                        organization_slug: window.localStorage.getItem(
                            'MP_ORGANIZATION'
                        ),
                        Authorization: `Token token="${token}"`,
                    },
                })
                    .then((res) => res.blob())
                    .then((res) => {
                        setDownloadInProgress(false);
                        downloadBlob(res, 'csv-template.csv');
                    });
            })
            .catch(() => logout({ returnTo: window.location.origin }));
    };

    const handleUsersUpload = (file) => {
        if (!file) {
            return;
        }

        const formData = new FormData();
        formData.append('file', file);

        setIsUploading(true);

        getAccessTokenSilently()
            .then((token) => {
                fetch(apiRoutes.post.mpaMetricSourcesUpload, {
                    method: 'POST',
                    headers: {
                        organization_slug: window.localStorage.getItem(
                            'MP_ORGANIZATION'
                        ),
                        Authorization: `Token token="${token}"`,
                    },
                    body: formData,
                }).then((res) => {
                    if (res.error || +res.status >= 300) {
                        return;
                    }
                    dispatch(
                        establishMetricSourcesMpaThunk(
                            getAccessTokenSilently,
                            logout
                        )
                    );

                    setIsUploading(false);
                    setIsReadyToClose(true);
                });
            })
            .catch(() => logout({ returnTo: window.location.origin }));
    };

    const handleClickOutside = (e) => {
        if (dropdownRef.current && dropdownRef.current.contains(e.target)) {
            return;
        }
        setIsDropdownVisible(false);
    };

    useEffect(() => {
        if (isDropdownVisible) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isDropdownVisible]);

    if (!areMetricSourcesEstablished) {
        return <Spinner />;
    }

    return (
      <>
        <div>
          <SectionHeader
                    withPadding
                    title="Data Sources / Metrics"
                    cta={() => (
                      <CTAWrapper>
                        <Button
                                primary
                                onClick={() => downloadCSVTemplate()}
                            >
                          {downloadInProgress
                                    ? 'Preparing file...'
                                    : 'Download CSV template'}
                        </Button>
                        <DropdownWrapper ref={dropdownRef}>
                          <Button
                                    svg
                                    primary
                                    onClick={() =>
                                        setIsDropdownVisible(!isDropdownVisible)}
                                >
                            Import
                            {isDropdownVisible ? (
                              <ArrowUp fill={theme.color.primary} />
                                    ) : (
                                      <ArrowDown fill={theme.color.primary} />
                                    )}
                          </Button>
                          {isDropdownVisible && (
                            <Dropdown>
                              <DropdownItem
                                            onClick={() => {
                                                setIsImportModalVisible(true);
                                                setIsDropdownVisible(false);
                                            }}
                                        >
                                Data Source / Metric Structure
                              </DropdownItem>
                            </Dropdown>
                                )}
                        </DropdownWrapper>
                      </CTAWrapper>
                    )}
                />

          {metricSources
                    .sort((ms1, ms2) => {
                        if (ms1.name > ms2.name) {
                            return 1;
                        }

                        if (ms1.name < ms2.name) {
                            return -1;
                        }

                        return 0;
                    })
                    .map((metricSource) => (
                      <MetricsMPA
                            key={metricSource.id}
                            metricSourceId={metricSource.id}
                            metricName={metricSource.name}
                            metricSourceEnabled={metricSource.enabled}
                        />
                    ))}

          <div>
            <ProcessedResultsMPA
                        isImportModalVisible={isImportModalVisible}
                    />
          </div>
        </div>
        {isImportModalVisible && (
        <ImportModal
                    headerText="Import metric structure"
                    handleUpload={handleUsersUpload}
                    setIsVisible={setIsImportModalVisible}
                    type="metrics"
                    isUploading={isUploading}
                    setIsUploading={setIsUploading}
                    isReadyToClose={isReadyToClose}
                    setIsReadyToClose={setIsReadyToClose}
                    reset={() => {
                        setIsImportModalVisible(false);
                        setIsUploading(false);
                        setIsReadyToClose(false);
                        setDownloadInProgress(false);
                        document.location.hash = '';
                    }}
                />
            )}
      </>
    );
};

export default DataSourcesMeasuresMPA;
