import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useOffset from 'hooks/useOffset';
import { useDispatch, useSelector } from 'react-redux';
import {
    establishOrganizationUsersThunk,
    loadMoreOrganizationUsersThunk,
    resetOrganizationUsersThunk,
} from 'redux/actions/thunks/organizationUsers';
import DropdownSelect from 'components/DropdownSelect/DropdownSelect';
import { useAuth0 } from '@auth0/auth0-react';
import {
    establishOrganizationAssignSmartTipAssignedUsersThunk,
    loadMoreOrganizationAssignSmartTipAssignedUsersThunk,
    resetUsersAndTeamsListsThunk,
} from 'redux/actions/thunks/organizationAssignSmartTip';
import { idize } from '../CreateNewUserHA/CreateNewUserHA';

const INFINITE_SCROLL_THRESHOLD = 50;

export const UsersSection = ({
    onUpdate,
    smartTipId,
    disabled,
    classificationTags,
    assignToAllUsers,
    setAssignToAll,
    setSelectedUser,
    unassignedUserIds,
    setUnassignedUserIds,
}) => {
    const { getAccessTokenSilently, logout } = useAuth0();
    const [searchFilter, setSearchFilter] = useState('');
    const dispatch = useDispatch();

    const [offset, incrementOffset, resetOffset] = useOffset(
        INFINITE_SCROLL_THRESHOLD
    );

    const [offset1, incrementOffset1, resetOffset1] = useOffset(
        INFINITE_SCROLL_THRESHOLD
    );

    const {
        organizationUsers,
        hasMoreOrganizationUsers,
        isLoadingOrganizationUsers,
    } = useSelector((state) => state.organizationUsers);

    const uncheckAssignToAllUsers = () => {
        setAssignToAll(false);
    };

    const resetItemsList = () => {
        resetOffset();
        dispatch(resetOrganizationUsersThunk());
    };

    const loadMore = () => {
        if (!hasMoreOrganizationUsers) return;
        if (isLoadingOrganizationUsers) return;
        dispatch(
            loadMoreOrganizationUsersThunk(
                getAccessTokenSilently,
                logout,
                INFINITE_SCROLL_THRESHOLD,
                offset,
                {
                    search: searchFilter,
                    classification_tag_ids: [
                        ...classificationTags.role_job.map(idize),
                        ...classificationTags.department.map(idize),
                    ],
                }
            )
        );
        incrementOffset();
    };

    const ctFilterPrimitive = JSON.stringify(classificationTags);

    useEffect(() => {
        dispatch(
            establishOrganizationUsersThunk(
                getAccessTokenSilently,
                logout,
                INFINITE_SCROLL_THRESHOLD,
                0,
                {
                    search: searchFilter,
                    classification_tag_ids: [
                        ...classificationTags.role_job.map(idize),
                        ...classificationTags.department.map(idize),
                    ],
                }
            )
        );
        incrementOffset();

        return () => resetItemsList();
    }, [searchFilter, ctFilterPrimitive]);

    const {
        assignedUsers,
        allUsersLoaded,
        isLoadingUser,
        areOrganizationAssignedUsersFetching,
    } = useSelector((state) => state.organizationAssignSmartTip);

    const loadMoreUsers = () => {
        if (!allUsersLoaded) return;
        if (isLoadingUser) return;
        dispatch(
            loadMoreOrganizationAssignSmartTipAssignedUsersThunk(
                getAccessTokenSilently,
                logout,
                smartTipId,
                INFINITE_SCROLL_THRESHOLD,
                offset1
            )
        );
        incrementOffset1();
    };

    const resetItem = () => {
        resetOffset1();
        dispatch(resetUsersAndTeamsListsThunk());
    };

    // FETCH ASSIGNED USERS
    useEffect(() => {
        dispatch(
            establishOrganizationAssignSmartTipAssignedUsersThunk(
                getAccessTokenSilently,
                logout,
                smartTipId,
                INFINITE_SCROLL_THRESHOLD,
                0
            )
        );

        incrementOffset1();

        return () => resetItem();
    }, []);

    return (
        <>
            <DropdownSelect
                type="user"
                smartTipId={smartTipId}
                items={organizationUsers}
                loadMore={loadMore}
                hasMore={hasMoreOrganizationUsers}
                onSearch={setSearchFilter}
                resetItemsList={resetItemsList}
                preselectedItems={assignedUsers}
                handleSetSelectedItems={(values) =>
                    onUpdate({ user_ids: [...values] })
                }
                placeholder="Select Users"
                disabled={disabled || assignToAllUsers}
                assignToAllUsers={assignToAllUsers}
                uncheckAssignToAllUsers={uncheckAssignToAllUsers}
                isLoadingOrganizationUsers={isLoadingOrganizationUsers}
                setSelectedUser={setSelectedUser}
                loadMoreUsers={loadMoreUsers}
                allUsersLoaded={allUsersLoaded}
                isLoadingUser={areOrganizationAssignedUsersFetching}
                unassignedUserIds={unassignedUserIds}
                setUnassignedUserIds={setUnassignedUserIds}
            />
        </>
    );
};

UsersSection.propTypes = {
    smartTipId: PropTypes.string.isRequired,
    onUpdate: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    // eslint-disable-next-line react/forbid-prop-types
    classificationTags: PropTypes.array.isRequired,
    assignToAllUsers: PropTypes.bool,
    setAssignToAll: PropTypes.func,
    setSelectedUser: PropTypes.func,
    unassignedUserIds: PropTypes.array,
    setUnassignedUserIds: PropTypes.func,
};

UsersSection.defaultProps = {
    disabled: false,
    assignToAllUsers: false,
    setAssignToAll: undefined,
    setSelectedUser: undefined,
    unassignedUserIds: undefined,
    setUnassignedUserIds: undefined,
};
