import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import theme from 'assets/theme/mainTheme';
import { ReactComponent as DropdownArrow } from 'assets/svg/dropdown-down.svg';
import {
    StyledDropdown,
    ButtonContent,
    StyledButton,
    SelectOption,
    StyledDropdownContent,
} from './Dropdown.style';

const Dropdown = ({
    label,
    labelSvg,
    options,
    onSelect,
    transparent,
    withoutDivider,
    fullWidth,
    ...rest
}) => {
    const [optionsVisible, setOptionsVisible] = useState(false);
    const dropdownRef = useRef(null);

    const onOptionClick = (option) => {
        onSelect(option);
        setOptionsVisible(false);
    };

    const handleClickOutside = (e) => {
        if (dropdownRef.current && dropdownRef.current.contains(e.target)) {
            return;
        }
        setOptionsVisible(false);
    };

    useEffect(() => {
        if (optionsVisible) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [optionsVisible]);

    return (
      <StyledDropdown ref={dropdownRef}>
        <StyledButton
                transparent={transparent}
                fullWidth={fullWidth}
                onClick={() => setOptionsVisible(!optionsVisible)}
                {...rest}
            >
          <ButtonContent>
            {labelSvg && labelSvg}
            {label}
          </ButtonContent>
          <DropdownArrow fill={theme.color.textBase} />
        </StyledButton>
        {optionsVisible && (
        <StyledDropdownContent
                    fullWidth={fullWidth}
                    isVisible={optionsVisible}
                >
          {options.map((option) => {
                        if (option.svg) {
                            return (
                              <SelectOption
                                    key={option.id}
                                    onClick={() => onOptionClick(option)}
                                    withoutDivider={withoutDivider}
                                >
                                {option.svg}
                                {option.label}
                              </SelectOption>
                            );
                        }
                        return (
                          <SelectOption
                                key={option.id}
                                onClick={() => onOptionClick(option)}
                                withoutDivider={withoutDivider}
                            >
                            {option.label}
                          </SelectOption>
                        );
                    })}
        </StyledDropdownContent>
            )}
      </StyledDropdown>
    );
};

Dropdown.propTypes = {
    label: PropTypes.string.isRequired,
    labelSvg: PropTypes.node,
    options: PropTypes.arrayOf(PropTypes.any).isRequired,
    onSelect: PropTypes.func.isRequired,
    transparent: PropTypes.bool,
    withoutDivider: PropTypes.bool,
    fullWidth: PropTypes.bool,
};

Dropdown.defaultProps = {
    transparent: true,
    withoutDivider: false,
    fullWidth: false,
    labelSvg: undefined,
};

export default Dropdown;
