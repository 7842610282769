export const GET_TENANT_ORGANIZATION = 'GET_TENANT_ORGANIZATION';
export const GET_TENANT_ORGANIZATION_SUCCESS =
    'GET_TENANT_ORGANIZATION_SUCCESS';
export const GET_TENANT_ORGANIZATION_FAILURE =
    'GET_TENANT_ORGANIZATION_FAILURE';

export const getTenantOrganization = () => ({
    type: GET_TENANT_ORGANIZATION,
});

export const getTenantOrganizationSuccess = (payload) => ({
    type: GET_TENANT_ORGANIZATION_SUCCESS,
    payload,
});

export const getTenantOrganizationFailure = (err) => ({
    type: GET_TENANT_ORGANIZATION_FAILURE,
    err,
});
