import React, { useEffect, useState } from 'react';
import useFetch from 'hooks/useFetch';
import { useHistory, useParams } from 'react-router-dom';
import apiRoutes from 'config/apiRoutes';

import Spinner from 'components/Spinner';
import MPASuperAdminUserDetailsView from 'views/MPASuperAdminUserDetails';
import {
    dispatchErrorAlertThunk,
    dispatchSuccessAlertThunk,
} from 'redux/actions/thunks/systemAlerts';
import { useDispatch } from 'react-redux';
import { MPA_ROUTES } from 'routing/constants';
import { useAuth0 } from '@auth0/auth0-react';

const MPASuperAdminUserDetails = () => {
    const { getAccessTokenSilently, logout } = useAuth0();
    const request = useFetch(getAccessTokenSilently, logout);
    const dispatch = useDispatch();
    const history = useHistory();
    const { userId } = useParams();
    const [dropdownVisible, setSettingsVisible] = useState(false);
    const [superAdminUserDetails, setSuperAdminUserDetails] = useState(
        undefined
    );
    const [
        deleteConfirmationModalVisible,
        setDeleteConfirmationModalVisible,
    ] = useState(false);

    const toggleDeleteConfirmationModalVisible = () => {
        setDeleteConfirmationModalVisible(!deleteConfirmationModalVisible);
    };

    const handleMetricSourcesSuccess = (res) => {
        if (!res.error) {
            dispatch(
                dispatchSuccessAlertThunk(
                    'deleteSuperAdminUser',
                    'Super Admin User deleted'
                )
            );
            toggleDeleteConfirmationModalVisible();
            history.push(MPA_ROUTES.superAdminUsers);
        }
    };

    const handleMetricSourcesError = (err) => {
        dispatch(dispatchErrorAlertThunk(err, err.error));
    };

    const handleDeleteUser = () => {
        request(
            apiRoutes.delete.deleteSuperAdminUser(userId),
            'DELETE',
            handleMetricSourcesSuccess,
            handleMetricSourcesError,
            {},
            { 'Content-Type': 'application/json' }
        );
    };

    const handleSuperAdminUserDetailsSuccess = (res) => {
        setSuperAdminUserDetails(res);
    };

    const handleSuperAdminUserDetailsError = (err) => err;

    const fetchSuperAdminUserDetails = () => {
        request(
            apiRoutes.get.superAdminUser(userId),
            'GET',
            handleSuperAdminUserDetailsSuccess,
            handleSuperAdminUserDetailsError,
            {},
            { 'Content-Type': 'application/json' }
        );
    };

    useEffect(() => {
        fetchSuperAdminUserDetails();
    }, []);

    if (!superAdminUserDetails) {
        return <Spinner />;
    }

    return (
      <MPASuperAdminUserDetailsView
            id={userId}
            fullName={superAdminUserDetails.fullname}
            firstName={superAdminUserDetails.first_name}
            lastName={superAdminUserDetails.last_name}
            email={superAdminUserDetails.email}
            avatar={superAdminUserDetails.avatar_url}
            color={superAdminUserDetails.color}
            backgroundColor={superAdminUserDetails.background_color}
            dropdownVisible={dropdownVisible}
            setSettingsVisible={setSettingsVisible}
            deleteConfirmationModalVisible={deleteConfirmationModalVisible}
            toggleDeleteConfirmationModalVisible={
                toggleDeleteConfirmationModalVisible
            }
            handleDeleteUser={handleDeleteUser}
        />
    );
};

export default MPASuperAdminUserDetails;
