import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/Modal';
import Button from 'components/Button';

import {
    Body,
    Heading,
    Paragraph,
    buttonStyles,
} from './TogglePerformanceCoachingModal.style';

const TogglePerformanceCoachingModal = ({
    perfomranceModalVisible,
    setPerformanceModalVisible,
    setFieldValue,
}) => (
  <Modal
        closeModal={(e) => {
            if (e.target.id === 'globalModalBg') {
                setPerformanceModalVisible(false);
            }
        }}
    >
    {' '}
    {}
    <Body>
      {perfomranceModalVisible === 'disable' && (
        <>
          <Heading>Disable Performance Coaching?</Heading>
          <Paragraph>
            Performance Coaching enables users to view personalized
            performance data and assigns performance-related Smart
            Tips to each user based on their individual results to
            help them reach their goals.
          </Paragraph>

          <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-around',
                        }}
                    >
            <Button
                            primary
                            onClick={() => {
                                setPerformanceModalVisible(undefined);
                            }}
                            customStyles={buttonStyles}
                        >
              Cancel
            </Button>
            <Button
                            quaternary
                            onClick={() => {
                                setFieldValue(
                                    'performanceCoachingEnabled',
                                    false
                                );
                                setPerformanceModalVisible(undefined);
                            }}
                            customStyles={buttonStyles}
                        >
              Disable
            </Button>
          </div>
        </>
            )}

      {perfomranceModalVisible === 'enable' && (
        <>
          <Heading>Enable Performance Coaching?</Heading>
          <Paragraph>
            Performance Coaching enables users to view personalized
            performance data and assigns performance-related Smart
            Tips to each user based on their individual results to
            help them reach their goals.
          </Paragraph>

          <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-around',
                        }}
                    >
            <Button
                            primary
                            onClick={() => {
                                setPerformanceModalVisible(undefined);
                            }}
                            customStyles={buttonStyles}
                        >
              Cancel
            </Button>
            <Button
                            primary
                            onClick={() => {
                                setFieldValue(
                                    'performanceCoachingEnabled',
                                    true
                                );
                                setPerformanceModalVisible(undefined);
                            }}
                            customStyles={buttonStyles}
                        >
              Enable
            </Button>
          </div>
        </>
            )}
    </Body>
  </Modal>
);

TogglePerformanceCoachingModal.propTypes = {
    perfomranceModalVisible: PropTypes.string,
    setPerformanceModalVisible: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
};

TogglePerformanceCoachingModal.defaultProps = {
    perfomranceModalVisible: undefined,
};

export default TogglePerformanceCoachingModal;
