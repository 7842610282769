import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    downloadUsersTemplateThunk,
    establishOrganizationUsersThunk,
    resetOrganizationUsersThunk,
} from 'redux/actions/thunks/organizationUsers';
import UsersHAView from 'views/UsersHA';
import { idize } from '../CreateNewUserHA/CreateNewUserHA';
import { downloadUsersResultsThunk } from '../../redux/actions/thunks/organizationUsers';

const PAGINATION_THRESHOLD = 8;

const UsersHA = () => {
    // Filters
    const { getAccessTokenSilently, logout } = useAuth0();
    const [roleFilter, setRoleFilter] = useState(undefined);
    const [teamFilter, setTeamFilter] = useState(undefined);
    const [nameFilter, setNameFilter] = useState('');
    const [tagsFilter, setTagsFilter] = useState({
        role_job: [],
        department: [],
    });
    const tagsFilterPrimitive = JSON.stringify(tagsFilter);
    const [isManageIdsModalVisible, setIsManageIdsModalVisible] =
        useState(false);
    const [isImportModalVisible, setIsImportModalVisible] = useState(false);
    const [importStrategyUpload, setImportStrategyUpload] = useState(null);
    const [isAddNewUserDropdownVisible, setIsAddNewUserDropdownVisible] =
        useState(false);
    const [isResourcesDropdownVisible, setResourcesDropdownVisible] =
        useState(false);

    const [pages, setPages] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);

    const dispatch = useDispatch();
    const {
        organizationUsers,
        areOrganizationUsersEstablished,
        areOrganizationUsersFetching,
        amountOfAllUsers,
    } = useSelector((state) => state.organizationUsers);

    const nextPage = () => {
        if (
            isNextEnabled() &&
            currentPage < amountOfAllUsers / PAGINATION_THRESHOLD
        ) {
            setCurrentPage(currentPage + 1);
        }
    };

    const prevPage = () => {
        if (isPrevEnabled() && currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };

    const isNextEnabled = () => {
        if (
            currentPage === Math.floor(amountOfAllUsers / PAGINATION_THRESHOLD)
        ) {
            return false;
        }
        return true;
    };

    const isPrevEnabled = () => {
        if (currentPage === 0) {
            return false;
        }
        return true;
    };

    const downloadTemplate = () => {
        dispatch(downloadUsersTemplateThunk(getAccessTokenSilently, logout));
    };

    const downloadUsersResultsCSV = () => {
        dispatch(
            downloadUsersResultsThunk(getAccessTokenSilently, logout, {
                role: roleFilter,
                team_id: teamFilter && teamFilter.id,
                search: nameFilter,
                classification_tag_ids: [
                    ...tagsFilter.role_job.map(idize),
                    ...tagsFilter.department.map(idize),
                ],
            })
        );
    };

    const toggleManageIdsModalVisible = () => {
        setIsManageIdsModalVisible(!isManageIdsModalVisible);
    };

    useEffect(() => {
        if (!isManageIdsModalVisible) {
            dispatch(
                establishOrganizationUsersThunk(
                    getAccessTokenSilently,
                    logout,
                    PAGINATION_THRESHOLD,
                    0,
                    {
                        role: roleFilter,
                        teamId: teamFilter && teamFilter.id,
                        search: nameFilter,
                    }
                )
            );
            setCurrentPage(0);
        }
    }, [isManageIdsModalVisible]);

    useEffect(() => {
        if (amountOfAllUsers !== undefined) {
            const newPages = [];
            for (
                let index = 0;
                index < amountOfAllUsers / PAGINATION_THRESHOLD;
                index += 1
            ) {
                newPages.push({
                    index,
                    pageNumber: index + 1,
                });
            }
            setPages(newPages);
        }
    }, [amountOfAllUsers]);

    useEffect(() => {
        dispatch(
            establishOrganizationUsersThunk(
                getAccessTokenSilently,
                logout,
                PAGINATION_THRESHOLD,
                currentPage * PAGINATION_THRESHOLD,
                {
                    role: roleFilter,
                    team_id: teamFilter && teamFilter.id,
                    search: nameFilter,
                    classification_tag_ids: [
                        ...tagsFilter.role_job.map(idize),
                        ...tagsFilter.department.map(idize),
                    ],
                }
            )
        );
    }, [currentPage]);

    useEffect(() => {
        dispatch(
            establishOrganizationUsersThunk(
                getAccessTokenSilently,
                logout,
                PAGINATION_THRESHOLD,
                0,
                {
                    role: roleFilter,
                    team_id: teamFilter && teamFilter.id,
                    search: nameFilter,
                    classification_tag_ids: [
                        ...tagsFilter.role_job.map(idize),
                        ...tagsFilter.department.map(idize),
                    ],
                }
            )
        );
        setCurrentPage(0);
    }, [roleFilter, tagsFilter, teamFilter, nameFilter, tagsFilterPrimitive]);

    const resetUsersList = () => {
        dispatch(resetOrganizationUsersThunk);
        setCurrentPage(0);
        setPages([]);
    };

    return (
        <UsersHAView
            pages={pages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            isAddNewUserDropdownVisible={isAddNewUserDropdownVisible}
            setIsAddNewUserDropdownVisible={setIsAddNewUserDropdownVisible}
            users={organizationUsers}
            hasLoaded={areOrganizationUsersEstablished}
            isFetching={areOrganizationUsersFetching}
            nextPage={nextPage}
            prevPage={prevPage}
            isNextEnabled={isNextEnabled}
            isPrevEnabled={isPrevEnabled}
            downloadTemplate={downloadTemplate}
            downloadUsersResultsCSV={downloadUsersResultsCSV}
            isManageIdsModalVisible={isManageIdsModalVisible}
            toggleManageIdsModalVisible={toggleManageIdsModalVisible}
            filters={{
                role: {
                    value: roleFilter,
                    setFilter: setRoleFilter,
                },
                team: {
                    value: teamFilter,
                    setFilter: setTeamFilter,
                },
                name: {
                    value: nameFilter,
                    setFilter: setNameFilter,
                },
                tags: {
                    value: tagsFilter,
                    setFilter: setTagsFilter,
                },
            }}
            isImportModalVisible={isImportModalVisible}
            setIsImportModalVisible={setIsImportModalVisible}
            importStrategyUpload={importStrategyUpload}
            setImportStrategyUpload={setImportStrategyUpload}
            resetUsersList={resetUsersList}
            isResourcesDropdownVisible={isResourcesDropdownVisible}
            setResourcesDropdownVisible={setResourcesDropdownVisible}
        />
    );
};

export default UsersHA;
