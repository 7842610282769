import styled, { css } from 'styled-components/macro';

export const TableHeaderHolder = styled.div`
    width: 100%;
    padding: 0 32px;
    margin: 16px 0px;

    display: grid;
    grid-gap: 8px;
    grid-template-columns: 5fr 4fr 4fr 2fr;

    span {
        color: ${({ theme }) => theme.color.textLight};
    }
`;

export const UsersUploadItemHolder = styled.div`
    width: 100%;
    align-items: center;
    padding: 32px;
    min-height: 115px;

    display: grid;
    grid-gap: 8px;
    grid-template-columns: 5fr 4fr 5fr 1fr;
    margin-bottom: 16px;

    ${({ theme }) => css`
        background-color: ${theme.color.white};
        border: ${theme.border.base};
        border-radius: ${theme.border.radiusBase};
    `}

    strong {
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

export const DateHolder = styled.p`
    margin: 0;
    color: ${({ theme }) => theme.color.textLight};
    font-size: 14px;
    font-weight: 400;
`;

export const StatusHolder = styled.span`
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.color.textLight};

    svg {
        margin-right: 16px;
    }

    .errMessage {
        margin-left: 8px;
        color: ${({ theme }) => theme.color.textLight};
    }
`;

export const ActionColumnWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

export const DownloadIconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 49px;
    height: 49px;
    border-radius: ${({ theme }) => theme.border.radiusBase};
    cursor: pointer;

    &:hover {
        background-color: ${({ theme }) => theme.color.bgPrimaryLight};
    }
`;
