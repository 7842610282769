import styled, { css } from 'styled-components/macro';

export const StyledDashboardWrapper = styled.div`
    max-width: 100%;
    /* display: grid; */
    /* grid-template-columns: 3fr 1fr; */

    ${({ oneColumn }) =>
        oneColumn &&
        css`
            grid-template-columns: 1fr;
        `}

    @media (max-width: ${({ theme }) => `${theme.breakpoints.web}px`}) {
        grid-template-columns: 1fr;
    }
`;

export const DashboardItemWrapper = styled.div`
    width: 100%;
    padding: 0 16px;
    overflow: hidden;

    ${({ courses }) =>
        courses &&
        css`
            display: flex;
            flex-flow: wrap;
            align-items: flex-start;
            flex-direction: column;

            @media (max-width: ${({ theme }) =>
                    `${theme.breakpoints.large}px`}) {
                flex-direction: row;
                flex-wrap: wrap;
            }
        `}
`;
export const WrapperCourses = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
`;
export const HACTAWrapper = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px;

    @media (max-width: ${({ theme }) => `${theme.breakpoints.mobile}px`}) {
        grid-template-columns: 1fr;
    }
`;

export const HighlightableSection = styled.div`
    padding: 0 16px;

    ${({ highlighted }) =>
        highlighted &&
        css`
            position: relative;
            border-radius: ${({ theme }) => theme.border.radiusBase};
            background-color: ${({ theme }) => theme.color.white};
            z-index: 1001;
        `};
`;
