import React from 'react';
import PropTypes from 'prop-types';
import Spinner from 'components/Spinner';
import { Link } from 'react-router-dom';
import { ReactComponent as FeedbackIcon5 } from 'assets/svg/survey/smiley-5.svg';
import { ReactComponent as FeedbackIcon4 } from 'assets/svg/survey/smiley-4.svg';
import { ReactComponent as FeedbackIcon3 } from 'assets/svg/survey/smiley-3.svg';
import { ReactComponent as FeedbackIcon2 } from 'assets/svg/survey/smiley-2.svg';
import { ReactComponent as FeedbackIcon1 } from 'assets/svg/survey/smiley-1.svg';
import {
    ModalContent,
    EmptyAvatar,
    Avatar,
    Comment,
    Content,
    FeedbackIcon,
    PictureWrapper,
    TopSection,
    Loading,
    BottomSection,
} from './SurveyFeedbackModal.style';

const renderFeedbackIcon = (value) => {
    const size = 10;
    switch (value) {
        case 1:
            return <FeedbackIcon1 width={size} height={size} />;
        case 2:
            return <FeedbackIcon2 width={size} height={size} />;
        case 3:
            return <FeedbackIcon3 width={size} height={size} />;
        case 4:
            return <FeedbackIcon4 width={size} height={size} />;
        case 5:
            return <FeedbackIcon5 width={size} height={size} />;
        default:
            return null;
    }
};

const renderDate = (date) => {
    const seconds = (Date.now() - new Date(date).getTime()) / 1000;
    if (Math.floor(seconds / 86400) !== 0) {
        return `${Math.floor(seconds / 86400)} days ago`;
    }
    return `${Math.floor(seconds / 3600)} hours ago`;
};

const SurveyFeedbackModal = ({ isFetching, evaluations }) => {
    const comments = !isFetching
        ? evaluations.filter((item) => item.comment !== '')
        : null;

    if (isFetching) {
        return (
          <Loading>
            <Spinner data-testid="spinner" />
          </Loading>
        );
    }

    return (
      <div>
        <ModalContent data-testid="modal-body">
          {comments.map((comment) => (
            <Comment key={comment.id}>
              <Link to={`/ha-users/${comment.user_id}`}>
                {' '}
                <PictureWrapper>
                  {comment.avatar_url ? (
                    <Avatar
                                        src={comment.avatar_url}
                                        alt={comment.first_name}
                                    />
                                ) : (
                                  <EmptyAvatar
                                        color={comment.color}
                                        backgroundColor={
                                            comment.background_color
                                        }
                                    >
                                    {`${
                                            comment.first_name &&
                                            comment.first_name[0]
                                        }${
                                            comment.last_name &&
                                            comment.last_name[0]
                                        }`}
                                  </EmptyAvatar>
                                )}
                  <FeedbackIcon variant={comment.value}>
                    {renderFeedbackIcon(comment.value)}
                  </FeedbackIcon>
                </PictureWrapper>
              </Link>
              <Content>
                <Link to={`/ha-users/${comment.user_id}`}>
                  <TopSection>
                    <p>{comment.full_name}</p>
                    <small>
                      {renderDate(comment.created_at)}
                    </small>
                  </TopSection>
                </Link>
                <BottomSection>{comment.comment}</BottomSection>
              </Content>
            </Comment>
                ))}
        </ModalContent>
      </div>
    );
};

SurveyFeedbackModal.propTypes = {
    isFetching: PropTypes.bool,
    selectedSmartTip: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        full_name: PropTypes.string,
        first_name: PropTypes.string,
        last_name: PropTypes.string,
        avatar_url: PropTypes.string,
        value: PropTypes.number,
        created_at: PropTypes.string,
    }),
    evaluations: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
            full_name: PropTypes.string,
            avatar_url: PropTypes.string,
            value: PropTypes.number,
            created_at: PropTypes.string,
        })
    ).isRequired,
};

SurveyFeedbackModal.defaultProps = {
    isFetching: true,
    selectedSmartTip: undefined,
};

export default SurveyFeedbackModal;
