import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { css } from 'styled-components/macro';

import Metrics from 'containers/MetricSource';
import ProcessedResults from 'containers/ProcessedResults';
import SectionHeader from 'components/SectionHeader';
import DataSourcesMeasuresCTA from 'components/DataSourcesMeasuresCTA';
import Modal from 'components/Modal';
import Button from 'components/Button';
import ResultsModalBody from 'components/ResultsModalBody';
import theme from 'assets/theme/mainTheme';
import ImportModal from 'components/ImportModal/ImportModal';
import { uploadedMetricReset } from 'redux/actions/metrics';
import { resetMetricUploadsThunk } from 'redux/actions/thunks/metricUploads';
import { useDispatch } from 'react-redux';
import { AdminSection, AdminWrapper } from './DataSourcesMeasures.style';

const DataSourcesMeasures = ({
    uploadMetricResults,
    metricsState,
    metricsState: {
        isResultsFileUploaded,
        isResultsFileUploading,
        isResultsFileError,
        areUsersFetched,
        areUsersFetching,
        isUsersError,
        foundUsers,
        foundMetrics,
        foundMetricResults,
        areSearchMetricsFetching,
    },
    searchOrganizationUsers,
    searchMetrics,
    downloadTemplate,
    metricSources,
    establishMetricResults,
}) => {
    const dispatch = useDispatch();

    const [isError, setIsError] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const [isReadyToClose, setIsReadyToClose] = useState(false);
    const [resultsModalVisible, setResultsModalVisible] = useState(false);
    const [metricUploadModalVisible, setMetricUploadModalVisible] = useState(
        false
    );

    useEffect(() => {
        setIsUploading(isResultsFileUploading);
        setIsReadyToClose(isResultsFileUploaded);
    }, [isResultsFileUploading, isResultsFileUploaded]);

    useEffect(() => {
        setIsError(false);
    }, [isResultsFileError]);

    const handleCloseResultsModal = (e) => {
        if (e.target.id === 'globalModalBg') {
            setResultsModalVisible(false);
        }
    };

    const handleMetricUpload = (file) => {
        if (file) {
            uploadMetricResults(file);
        }
    };

    return (
      <>
        <AdminWrapper data-testid="AdminHAWrapper">
          <SectionHeader
                    title="Data Sources / Metrics"
                    withPadding
                    cta={() => (
                      <DataSourcesMeasuresCTA
                            downloadTemplate={downloadTemplate}
                            handleMetricClick={() =>
                                setMetricUploadModalVisible(
                                    !metricUploadModalVisible
                                )}
                            handleResultsClick={() =>
                                setResultsModalVisible(!resultsModalVisible)}
                        />
                    )}
                />
          <AdminSection withPadding>
            <Metrics />
          </AdminSection>
          <AdminSection withPadding>
            <ProcessedResults
                        isImportModalVisible={metricUploadModalVisible}
                    />
          </AdminSection>
        </AdminWrapper>

        {metricUploadModalVisible && (
        <ImportModal
                    headerText="Import results"
                    handleUpload={handleMetricUpload}
                    setIsVisible={setMetricUploadModalVisible}
                    type="metrics"
                    isUploading={isUploading}
                    setIsUploading={setIsUploading}
                    isReadyToClose={isReadyToClose}
                    setIsReadyToClose={setIsReadyToClose}
                    reset={() => dispatch(resetMetricUploadsThunk())}
                    uploadedMetricReset={() => dispatch(uploadedMetricReset())}
                />
            )}

        {resultsModalVisible && (
        <Modal
                    closeModal={handleCloseResultsModal}
                    headerText="View Results"
                    modalFooter={() => (
                      <Button
                            primary
                            onClick={() => setResultsModalVisible(false)}
                        >
                        Close
                      </Button>
                    )}
                    customStyles={css`
                        min-width: 0;

                        @media (max-width: ${theme.breakpoints.s}) {
                            width: 95%;
                        }
                    `}
                    noPadding
                >
          {isError ? (
            <p>An error occurred</p>
                    ) : (
                      <ResultsModalBody
                            areUsersFetched={areUsersFetched}
                            areUsersFetching={areUsersFetching}
                            isUsersError={isUsersError}
                            searchOrganizationUsers={searchOrganizationUsers}
                            searchMetrics={searchMetrics}
                            foundUsers={foundUsers}
                            foundMetrics={foundMetrics}
                            metricSources={metricSources}
                            areSearchMetricsFetching={areSearchMetricsFetching}
                            metricsState={metricsState}
                            establishMetricResults={establishMetricResults}
                            foundMetricResults={foundMetricResults}
                        />
                    )}
        </Modal>
            )}
      </>
    );
};

DataSourcesMeasures.propTypes = {
    metricsState: PropTypes.shape({
        isResultsFileUploaded: PropTypes.bool,
        isResultsFileUploading: PropTypes.bool,
        isResultsFileError: PropTypes.bool,
        areUsersFetched: PropTypes.bool,
        areUsersFetching: PropTypes.bool,
        isUsersError: PropTypes.bool,
        foundUsers: PropTypes.arrayOf(PropTypes.any),
        areSearchMetricsFetching: PropTypes.bool,
        foundMetrics: PropTypes.arrayOf(PropTypes.any),
        foundMetricResults: PropTypes.arrayOf(PropTypes.any),
    }).isRequired,
    uploadMetricResults: PropTypes.func.isRequired,
    downloadTemplate: PropTypes.func.isRequired,
    searchOrganizationUsers: PropTypes.func.isRequired,
    metricSources: PropTypes.arrayOf(PropTypes.any).isRequired,
    searchMetrics: PropTypes.func.isRequired,
    establishMetricResults: PropTypes.func.isRequired,
};

export default DataSourcesMeasures;
