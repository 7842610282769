import React from 'react';
import PropTypes from 'prop-types';
import theme from 'assets/theme/mainTheme';
import { ReactComponent as CheckboxActiveIcon } from 'assets/svg/checkbox-active.svg';
import { ReactComponent as CheckboxInactiveIcon } from 'assets/svg/checkbox-inactive.svg';
import CheckboxHolder from './Checkbox.style';

const Checkbox = ({ toggleSelected, isSelected, customStyles, disabled }) => (
  <CheckboxHolder
        onClick={disabled ? () => {} : toggleSelected}
        customStyles={customStyles}
        disabled={disabled}
    >
    {isSelected ? (
      <CheckboxActiveIcon fill={theme.color.primary} />
        ) : (
          <CheckboxInactiveIcon />
        )}
  </CheckboxHolder>
);

Checkbox.propTypes = {
    customStyles: PropTypes.any || null,
    toggleSelected: PropTypes.func.isRequired,
    isSelected: PropTypes.bool.isRequired,
    disabled: PropTypes.bool,
};

Checkbox.defaultProps = {
    customStyles: '',
    disabled: false,
};

export default Checkbox;
