import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    establishUsersThunk,
    loadMoreUsersThunk,
    resetUsersThunk,
} from 'redux/actions/thunks/user';
import useOffset from 'hooks/useOffset';
import { useHistory } from 'react-router-dom';
import { createTeamThunk } from 'redux/actions/thunks/team';
import CreateTeamView from 'views/CreateTeam/CreateTeam';
import useAdmin from 'hooks/useAdmin';
import { useAuth0 } from '@auth0/auth0-react';

const INFINITE_SCROLL_THRESHOLD = 20;

const CreateTeamForm = () => {
    const { getAccessTokenSilently, logout } = useAuth0();
    const history = useHistory();
    const dispatch = useDispatch();
    const [offset, incrementOffset, resetOffset] = useOffset(
        INFINITE_SCROLL_THRESHOLD
    );
    const [nameFilter, setNameFilter] = useState('');
    const [creatorId, setCreatorId] = useState('');
    const { areUsersFetching, users, hasMoreUsers } = useSelector(
        (state) => state.users
    );

    useEffect(() => {
        dispatch(resetUsersThunk());

        if (nameFilter.length > 2) {
            dispatch(
                establishUsersThunk(
                    getAccessTokenSilently,
                    logout,
                    INFINITE_SCROLL_THRESHOLD,
                    0,
                    {
                        search: nameFilter.toLowerCase(),
                    }
                )
            );
            resetOffset(INFINITE_SCROLL_THRESHOLD);
        } else {
            dispatch(
                establishUsersThunk(
                    getAccessTokenSilently,
                    logout,
                    INFINITE_SCROLL_THRESHOLD,
                    0
                )
            );
            resetOffset(INFINITE_SCROLL_THRESHOLD);
        }
    }, [nameFilter]);

    const isAdmin = useAdmin();

    return (
      <CreateTeamView
            isFetching={areUsersFetching}
            users={users}
            createTeam={(teamData) =>
                dispatch(
                    createTeamThunk(
                        getAccessTokenSilently,
                        logout,
                        teamData,
                        isAdmin,
                        creatorId,
                        history
                    )
                )}
            customCreator={{
                selected: creatorId,
                onSelect: setCreatorId,
            }}
            hasMoreUsers={hasMoreUsers}
            filterValue={nameFilter}
            filterUsers={setNameFilter}
            resetUsersList={() => {
                dispatch(resetUsersThunk());
                resetOffset();
            }}
            loadMoreUsers={() => {
                dispatch(
                    loadMoreUsersThunk(
                        getAccessTokenSilently,
                        logout,
                        INFINITE_SCROLL_THRESHOLD,
                        offset,
                        {
                            search: nameFilter.length > 2 ? nameFilter : '',
                        }
                    )
                );
                incrementOffset();
            }}
        />
    );
};

export default CreateTeamForm;
