import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/Modal';
import Button from 'components/Button';
import establishSmartTipEvaluationListThunk from 'redux/actions/thunks/organizationSmartTipEvaluationList';
import { useSelector, useDispatch } from 'react-redux';
import SurveyFeedbackModalView from 'views/SurveyFeedbackModal';
import { useAuth0 } from '@auth0/auth0-react';

const SurveyFeedbackModal = ({ selectedSmartTip, closeModal }) => {
    const { getAccessTokenSilently, logout } = useAuth0();
    const dispatch = useDispatch();

    const {
        organizationSmartTipEvaluationList,
        isOrganizationSmartTipEvaluationListFetching,
    } = useSelector((state) => state.organizationSmartTipEvaluationList);

    useEffect(() => {
        if (selectedSmartTip) {
            dispatch(
                establishSmartTipEvaluationListThunk(
                    getAccessTokenSilently,
                    logout,
                    selectedSmartTip.id
                )
            );
        }
    }, [selectedSmartTip]);

    return (
      <Modal
            customStyles={{ width: '670px' }}
            closeModal={closeModal}
            isCloseIcon
            closeByIconClick={closeModal}
            headerText="Satisfaction Survey Feedback"
            modalFooter={() => (
              <Button primaryDark onClick={closeModal}>
                Close
              </Button>
            )}
        >
        <SurveyFeedbackModalView
                isFetching={isOrganizationSmartTipEvaluationListFetching}
                evaluations={organizationSmartTipEvaluationList}
            />
      </Modal>
    );
};

SurveyFeedbackModal.propTypes = {
    selectedSmartTip: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        full_name: PropTypes.string,
        avatar_url: PropTypes.string,
        value: PropTypes.number,
        created_at: PropTypes.string,
    }).isRequired,
    closeModal: PropTypes.func.isRequired,
};

export default SurveyFeedbackModal;
