import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { ReactComponent as ArrowBackIcon } from 'assets/svg/arrow-back.svg';
import { ReactComponent as InfoIcon } from 'assets/svg/info-circle.svg';
import SectionHeader from 'components/SectionHeader';
import Button from 'components/Button';
import theme from 'assets/theme/mainTheme';
import Input from 'components/Input';
import { HA_ROUTES } from 'routing/constants';
import { ROLES_TO_SELECT } from 'constants.js';
import { FormWrapper, Section, Title, Role } from './HAEditUser.style';
import { ClassificationTagDropdown } from '../../components/ClassificationTagDropdown/ClassificationTagDropdown';
import apiRoutes from '../../config/apiRoutes';

const HAEditUserView = ({
    firstName,
    setFirstName,
    isFirstNameValid,
    lastName,
    setLastName,
    isLastNameValid,
    emailAddress,
    setEmailAddress,
    isEmailAddressValid,
    role,
    mnemonic,
    setMnemonic,
    isMnemonicValid,
    editUser,
    isButtonDisabled,
    userId,
    setClassificationTags,
    classificationTags,
    setClassificationTagIds,
}) => {
    const history = useHistory();

    return (
        <>
            <SectionHeader
                goBack={{
                    url: HA_ROUTES.users,
                    label() {
                        return (
                            <>
                                <ArrowBackIcon stroke={theme.color.textLabel} />
                                <div style={{ color: theme.color.primary }}>
                                    Back
                                </div>
                            </>
                        );
                    },
                }}
                title="Edit User"
                customStyles={{ marginBottom: '16px' }}
            />
            <FormWrapper>
                <Section grid>
                    <Title>Modify existing User data</Title>
                    <Input
                        placeholder="First name"
                        defaultValue={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        invalid={!isFirstNameValid()}
                    />
                    <Input
                        placeholder="Last name"
                        defaultValue={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        invalid={!isLastNameValid()}
                    />
                    <Input
                        placeholder="E-mail address"
                        defaultValue={emailAddress}
                        onChange={(e) => setEmailAddress(e.target.value)}
                        invalid={!isEmailAddressValid()}
                    />
                    <Role>
                        {ROLES_TO_SELECT[role]
                            ? ROLES_TO_SELECT[role]
                            : 'User Type'}
                    </Role>
                </Section>

                <Section grid borderTop>
                    <div>
                        <ClassificationTagDropdown
                            localMode
                            updateUrl={apiRoutes.post.assignClassificationTagsToUser(
                                userId
                            )}
                            currentlySavedTags={classificationTags}
                            setClassificationTags={setClassificationTags}
                            setClassificationTagIds={setClassificationTagIds}
                        />
                    </div>
                </Section>

                <Section grid borderTop>
                    <Title>
                        <InfoIcon fill={theme.color.textLight} /> Unique ID used
                        to connect User to imported performance data.
                    </Title>
                    <Input
                        placeholder="User ID or Mnemonic"
                        defaultValue={mnemonic}
                        onChange={(e) => setMnemonic(e.target.value)}
                        invalid={!isMnemonicValid()}
                    />
                </Section>
                <Section borderTop>
                    <Button
                        primaryDark
                        customStyles={{ marginRight: '16px' }}
                        onClick={editUser}
                        disabled={isButtonDisabled}
                    >
                        Save
                    </Button>
                    <Button
                        primary
                        onClick={() => history.push(HA_ROUTES.users)}
                    >
                        Cancel
                    </Button>
                </Section>
            </FormWrapper>
        </>
    );
};

HAEditUserView.propTypes = {
    firstName: PropTypes.string,
    setFirstName: PropTypes.func.isRequired,
    isFirstNameValid: PropTypes.func.isRequired,
    lastName: PropTypes.string,
    setLastName: PropTypes.func.isRequired,
    isLastNameValid: PropTypes.func.isRequired,
    emailAddress: PropTypes.string,
    setEmailAddress: PropTypes.func.isRequired,
    isEmailAddressValid: PropTypes.func.isRequired,
    role: PropTypes.string,
    mnemonic: PropTypes.string,
    setMnemonic: PropTypes.func.isRequired,
    isMnemonicValid: PropTypes.func.isRequired,
    editUser: PropTypes.func.isRequired,
    isButtonDisabled: PropTypes.bool.isRequired,
    userId: PropTypes.string.isRequired,
    setClassificationTags: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    classificationTags: PropTypes.array.isRequired,
    setClassificationTagIds: PropTypes.func,
};

HAEditUserView.defaultProps = {
    role: undefined,
    firstName: undefined,
    lastName: undefined,
    emailAddress: undefined,
    mnemonic: undefined,
    setClassificationTagIds: undefined,
};

export default HAEditUserView;
