import React from 'react';
import PropTypes from 'prop-types';
import SingleNotification from 'components/SingleNotification';
import NoResults from 'components/EmptyState/NoResults';
import Button from 'components/Button';
import theme from 'assets/theme/mainTheme';
import {
    NotificationDropdownHolder,
    NotificationDropdownWrapper,
    NotificationListWrapper,
    BellIcon,
    Square,
    Counter,
    TopSection,
    BottomSection,
} from './NotificationsDropdown.style';

const NotificationDropdown = ({
    unseenCount,
    notifications,
    isVisible,
    toggleVisible,
    markAsRead,
    seeAllNotifications,
    markAllNotificationsAsRead,
}) => (
  <NotificationDropdownHolder
        id="notificationHolder"
        unseenCount={!!unseenCount}
        onClick={(e) => {
            if (e.target.id === 'notificationHolder') {
                toggleVisible();
            }
        }}
    >
    <BellIcon onClick={toggleVisible} />
    {unseenCount !== 0 && <Counter>{unseenCount}</Counter>}
    {isVisible && (
    <NotificationDropdownWrapper>
      <Square />
      <TopSection>
        <p>Notifications</p>
        {notifications.length !== 0 && (
        <Button
                            borderless
                            transparent
                            customStyles={{
                                color: theme.color.textNavigationCounter,
                            }}
                            onClick={markAllNotificationsAsRead}
                        >
          Mark all read
        </Button>
                    )}
      </TopSection>
      <NotificationListWrapper>
        {notifications.length === 0 ? (
          <NoResults
                            title="There aren't any notifications yet!"
                            message=""
                        />
                    ) : (
                        notifications.map((notification) => (
                          <SingleNotification
                                key={notification.id}
                                markAsRead={() => markAsRead(notification.id)}
                                name={notification.event_name}
                                createdAt={notification.created_at}
                                isRead={notification.seen_at !== null}
                                context={notification.event_context}
                                compact
                                critical={
                                    notification.event_type === 'critical_memo'
                                }
                            />
                        ))
                    )}
      </NotificationListWrapper>
      <BottomSection>
        <Button
                        primary
                        borderless
                        customStyles={{
                            width: '100%',
                        }}
                        onClick={seeAllNotifications}
                    >
          See all notifications
        </Button>
      </BottomSection>
    </NotificationDropdownWrapper>
        )}
  </NotificationDropdownHolder>
);

NotificationDropdown.propTypes = {
    unseenCount: PropTypes.number,
    notifications: PropTypes.arrayOf(PropTypes.any),
    isVisible: PropTypes.bool.isRequired,
    toggleVisible: PropTypes.func.isRequired,
    markAsRead: PropTypes.func.isRequired,
    markAllNotificationsAsRead: PropTypes.func.isRequired,
    seeAllNotifications: PropTypes.func.isRequired,
};

NotificationDropdown.defaultProps = {
    unseenCount: 0,
    notifications: [],
};

export default NotificationDropdown;
