import styled from 'styled-components/macro';

export const StyledSingleCourse = styled.div`
    width: 324px;
    height: 220px;
    margin: 16px;
    border: ${({ theme }) => theme.border.base};
    border-radius: ${({ theme }) => theme.border.radiusBase};
    background-color: ${({ theme }) => theme.color.white};
    position: relative;

    @media (max-width: ${({ theme }) => `${theme.breakpoints.web}px`}) {
        margin: 16px 16px 16px 0;
        width: auto;
        min-width: 300px;
    }

    @media (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
        width: 100%;
        min-width: 200px;
    }
`;

export const StyledCourseTitleHolder = styled.div`
    padding: 16px;
    width: 100%;
    height: 130px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
`;

export const StyledCourseIconHolder = styled.div`
    margin-right: 8px;
    width: 66px;
    height: 66px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: ${({ theme }) => theme.border.bold};
`;

export const StyledCourseTitle = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 168px;

    strong,
    span {
        margin: 0;
    }

    strong {
        font-weight: 600;
        margin-bottom: 8px;
    }

    span {
        font-weight: 400;
        font-size: ${({ theme }) => theme.fontSize.small};
        color: ${({ theme }) => theme.color.textLight};
    }
`;

export const StyledButtonHolder = styled.div`
    width: 100%;
    height: 90px;
    border-top: ${({ theme }) => theme.border.base};
    margin-top: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 24px;

    button,
    a {
        width: 100%;
    }
`;

export const StyledNewBadge = styled.span`
    color: ${({ theme }) => theme.color.white};
    background-color: ${({ theme }) => theme.color.quaternary};
    border-radius: ${({ theme }) => theme.border.radiusBase};
    font-size: ${({ theme }) => theme.fontSize.small};
    font-weight: 600;
    width: 44px;
    height: 24px;
    position: absolute;
    top: 0;
    right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(-50%);
    z-index: 2;
`;
