import {
    UPLOAD_METRIC_RESULTS,
    UPLOAD_METRIC_RESULTS_SUCCESS,
    UPLOAD_METRIC_RESULTS_FAILURE,
    SEARCH_ORGANIZATION_USERS,
    SEARCH_ORGANIZATION_USERS_SUCCESS,
    SEARCH_ORGANIZATION_USERS_FAILURE,
    ESTABLISH_METRIC_RESULTS,
    UPLOADED_METRIC_RESET,
    ESTABLISH_METRIC_RESULTS_SUCCESS,
    ESTABLISH_METRIC_RESULTS_FAILURE,
    SEARCH_METRICS,
    SEARCH_METRICS_SUCCESS,
    SEARCH_METRICS_FAILURE,
    UPDATE_METRIC,
    UPDATE_METRIC_SUCCESS,
    UPDATE_METRIC_FAILURE,
} from 'redux/actions/metrics';

export const initialState = {
    areMetricsEstablished: false,
    isMetricsError: false,
    areMetricsFetching: false,
    isResultsFileUploaded: false,
    isResultsFileUploading: false,
    isResultsFileError: false,
    areUsersFetched: false,
    areUsersFetching: false,
    isUsersError: false,
    areSearchMetricsFetched: false,
    areSearchMetricsFetching: false,
    isSearchMetricsError: false,
    error: null,
    foundUsers: [],
    foundMetrics: [],
};

const metrics = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_METRIC:
        case UPDATE_METRIC_FAILURE:
            return {
                ...state,
                areMetricsFetching: true,
                isMetricsError: false,
            };

        case UPDATE_METRIC_SUCCESS:
            return {
                ...state,
                areMetricsFetching: false,
                areMetricsEstablished: true,
            };

        case UPLOAD_METRIC_RESULTS:
            return {
                ...state,
                isResultsFileUploaded: false,
                isResultsFileUploading: true,
                isResultsFileError: false,
            };

        case UPLOAD_METRIC_RESULTS_SUCCESS:
            return {
                ...state,
                isResultsFileUploaded: true,
                isResultsFileUploading: false,
                isResultsFileError: false,
            };

        case UPLOADED_METRIC_RESET:
            return {
                ...state,
                isResultsFileUploaded: false,
            };

        case UPLOAD_METRIC_RESULTS_FAILURE:
            return {
                ...state,
                isResultsFileUploaded: false,
                isResultsFileUploading: false,
                isResultsFileError: true,
                error: action.err,
            };

        case SEARCH_ORGANIZATION_USERS:
            return {
                ...state,
                areUsersFetched: false,
                areUsersFetching: true,
                isUsersError: false,
            };

        case SEARCH_ORGANIZATION_USERS_SUCCESS:
            return {
                ...state,
                areUsersFetched: true,
                areUsersFetching: false,
                isUsersError: false,
                foundUsers: action.payload,
            };

        case SEARCH_ORGANIZATION_USERS_FAILURE:
            return {
                ...state,
                areUsersFetched: false,
                areUsersFetching: false,
                isUsersError: true,
                error: action.err,
            };

        case SEARCH_METRICS:
            return {
                ...state,
                areSearchMetricsFetched: false,
                areSearchMetricsFetching: true,
                isSearchMetricsError: false,
            };

        case SEARCH_METRICS_SUCCESS:
            return {
                ...state,
                areSearchMetricsFetched: true,
                areSearchMetricsFetching: false,
                isSearchMetricsError: false,
                foundMetrics: action.payload,
            };

        case SEARCH_METRICS_FAILURE:
            return {
                ...state,
                areSearchMetricsFetched: false,
                areSearchMetricsFetching: false,
                isSearchMetricsError: true,
                error: action.err,
            };

        case ESTABLISH_METRIC_RESULTS:
            return {
                ...state,
            };

        case ESTABLISH_METRIC_RESULTS_SUCCESS:
            return {
                ...state,
                foundMetricResults: {
                    ...state.foundMetricResults,
                    [action.payload.metric]: action.payload.results,
                },
            };

        case ESTABLISH_METRIC_RESULTS_FAILURE:
            return {
                ...state,
            };

        default:
            return state;
    }
};

export default metrics;
