const timeSince = (date) => {
    const seconds = Math.floor((new Date() - date) / 1000);
    let interval = seconds / 31536000;

    const getPlural = (number) => {
        if (+number > 1) {
            return 's';
        }

        return '';
    };

    if (interval > 1) {
        return `${Math.floor(interval)} year${getPlural(Math.floor(interval))}`;
    }

    interval = seconds / 2592000;
    if (interval > 1) {
        return `${Math.floor(interval)} month${getPlural(
            Math.floor(interval)
        )}`;
    }

    interval = seconds / 86400;
    if (interval > 1) {
        return `${Math.floor(interval)} day${getPlural(Math.floor(interval))}`;
    }

    interval = seconds / 3600;
    if (interval > 1) {
        return `${Math.floor(interval)} hour${getPlural(Math.floor(interval))}`;
    }

    interval = seconds / 60;
    if (interval > 1) {
        return `${Math.floor(interval)} minute${getPlural(
            Math.floor(interval)
        )}`;
    }

    return `${Math.floor(seconds)} second${getPlural(Math.floor(seconds))}`;
};

const parseDate = (dateString) => {
    const date = new Date(dateString);

    return `${timeSince(date)} ago`;
};

export default parseDate;
