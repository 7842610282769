import styled from 'styled-components/macro';

export const IconsHolder = styled.div`
    margin-left: auto;

    svg {
        margin: 8px;
        opacity: 0.2;
        transition: opacity 0.2s ease;
        cursor: pointer;
    }
`;

export const SingleReminderHolder = styled.div`
    width: 100%;
    padding: 24px;
    min-height: 110px;
    background-color: ${({ theme }) => theme.color.white};
    border: ${({ theme }) => theme.border.base};
    border-radius: ${({ theme }) => theme.border.radiusBase};
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    position: relative;

    * {
        user-select: none;
    }

    &:hover {
        svg {
            opacity: 1;
        }
    }

    @media (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
        position: initial;
    }
`;

export const BellIconHolder = styled.div`
    width: 65px;
    height: 65px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.color.bgPrimaryLight};
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
        width: 32px;
        height: 32px;
        background-color: transparent;
    }
`;

export const TitleHolder = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 290px;
    margin: 0 24px;
`;

export const Title = styled.span`
    font-weight: 500;
    display: inline-block;
    margin: 4px 0;
`;
export const DateTime = styled.span`
    color: ${({ theme }) => theme.color.textLight};
    display: inline-block;
    margin: 4px 0;
`;
