import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import theme from 'assets/theme/mainTheme';
import { ReactComponent as PreviewIcon } from 'assets/svg/image-placeholder.svg';
import Button from 'components/Button';
import { ReactSVG } from 'react-svg';
import Modal from 'components/Modal';
import Spinner from 'components/Spinner';
import {
    GalleryHolder,
    PreviewHolder,
    PreviewArea,
    SettingsArea,
    ModalGalleryItem,
    ModalGallery,
} from './GalleryPicker.style';

const GalleryPicker = ({
    buttonLabelEmpty,
    buttonLabelSelected,
    selectedAsset,
    setSelectedAsset,
    assets,
    invalid,
}) => {
    const [galleryItems, setGalleryItems] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);

    useEffect(() => {
        if (assets !== galleryItems) {
            setGalleryItems(assets);
        }
    }, [assets]);

    const handleChooseAsset = (item) => {
        setSelectedAsset(item);
        setModalVisible(false);
    };

    return (
      <>
        <GalleryHolder invalid={invalid}>
          <PreviewHolder>
            <PreviewArea onClick={() => setModalVisible(true)}>
              {selectedAsset ? (
                <ReactSVG
                                afterInjection={() => {}}
                                beforeInjection={(svg) => {
                                    svg.classList.add('svg-class-name');

                                    svg.setAttribute('style', 'height: 120px;');
                                }}
                                className="wrapper-class-name"
                                evalScripts="always"
                                fallback={() => (
                                  <PreviewIcon fill={theme.color.bgSearch} />
                                )}
                                loading={() => <Spinner />}
                                onClick={() => {}}
                                renumerateIRIElements={false}
                                src={selectedAsset.url}
                            />
                        ) : (
                          <PreviewIcon fill={theme.color.bgSearch} />
                        )}
            </PreviewArea>
          </PreviewHolder>
          <SettingsArea>
            <Button
                        primary
                        fullWidth
                        onClick={() => setModalVisible(true)}
                    >
              {selectedAsset ? buttonLabelSelected : buttonLabelEmpty}
            </Button>
          </SettingsArea>
        </GalleryHolder>
        {modalVisible && (
        <Modal
                    absolute
                    closeModal={() => setModalVisible(false)}
                    headerText="Choose graphic asset"
                >
          <ModalGallery>
            {galleryItems.map((item) => (
              <ModalGalleryItem
                                key={item.id}
                                onClick={() => handleChooseAsset(item)}
                                onKeyDown={() => handleChooseAsset(item)}
                            >
                <ReactSVG
                                    afterInjection={() => {}}
                                    beforeInjection={(svg) => {
                                        svg.classList.add('svg-class-name');

                                        svg.setAttribute(
                                            'style',
                                            'height: 120px;'
                                        );
                                    }}
                                    className="wrapper-class-name"
                                    evalScripts="always"
                                    fallback={() => (
                                      <PreviewIcon
                                            fill={theme.color.bgSearch}
                                        />
                                    )}
                                    loading={() => <Spinner />}
                                    onClick={() => {}}
                                    renumerateIRIElements={false}
                                    src={item.url}
                                />
              </ModalGalleryItem>
                        ))}
          </ModalGallery>
        </Modal>
            )}
      </>
    );
};

GalleryPicker.propTypes = {
    buttonLabelEmpty: PropTypes.string,
    buttonLabelSelected: PropTypes.string,
    selectedAsset: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    assets: PropTypes.arrayOf(PropTypes.any).isRequired,
    setSelectedAsset: PropTypes.func.isRequired,
    invalid: PropTypes.bool,
};

GalleryPicker.defaultProps = {
    buttonLabelEmpty: 'Choose asset',
    buttonLabelSelected: 'Change illustration',
    selectedAsset: null,
    invalid: false,
};

export default GalleryPicker;
