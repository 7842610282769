import styled from 'styled-components/macro';

export const ChartWrapper = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
`;

export const Info = styled.div`
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    color: ${({ theme }) => theme.color.textLight};
    padding: 20px 35px 5px;
`;

export const EmptyStateWrapper = styled.div`
    height: 350px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const Title = styled.div`
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    color: ${({ theme }) => theme.color.textBase};
    margin-bottom: 24px;
`;

export const Paragraph = styled.div`
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    color: ${({ theme }) => theme.color.textLight};
    max-width: 300px;
`;

export const Grid = styled.div`
    width: 405px;
    display: grid;
    grid-template-columns: 1fr 5fr;
    grid-gap: 16px;
`;
