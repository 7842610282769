import React from 'react';
import PropTypes from 'prop-types';
import SectionHeader from 'components/SectionHeader';
import SmartTip from 'components/SmartTip';
import NoResults from 'components/EmptyState/NoResults';
import Spinner from 'components/Spinner';
import { ReactComponent as OkIcon } from 'assets/svg/ok-tick.svg';
import AddReminderModal from 'components/AddReminderModal';
import { StyledCongratulationsModal } from 'containers/SmartTipsPage/SmartTips.style';
import Button from 'components/Button';
import { Link } from 'react-router-dom';
import { ROUTES } from 'routing/constants';

import SmartTipsEmptyState from './SmartTipsEmpty';
import {
    ArchivedSTWrapper,
    SmartTipsSectionWrapper,
    NoResultsWrapper,
} from './SmartTips.style';

const SmartTipsView = ({
    isFetching,
    smartTips,
    smartTipsEssential,
    smartTipsToTW,
    smartTipsArchived,
    smartTipsEssentialArchived,
    handleModalClose,
    addReminderModalOpenedById,
    setAddReminderModalOpenedById,
    addReminder,
}) => (
    <div>
        <SmartTipsSectionWrapper data-testid="smarttips-wrapper-with-content">
            <SectionHeader
                title="Your Smart Tips"
                counter={
                    smartTipsToTW.length +
                    smartTipsEssential.length +
                    smartTips.length
                }
                withPadding
            />

            {isFetching && <Spinner />}

            {!isFetching &&
            smartTipsToTW.length === 0 &&
            smartTipsEssential.length === 0 &&
            smartTips.length === 0 ? (
                <SmartTipsEmptyState />
            ) : (
                <>
                    {smartTipsToTW.map((tip) => (
                        <SmartTip
                            {...tip}
                            tipId={tip.id}
                            key={`${tip.id}-${tip.type}`}
                            isNew={tip.status === 'unviewed'}
                            size="compact"
                            openModalById={setAddReminderModalOpenedById}
                        />
                    ))}
                    {smartTipsEssential.map((tip) => (
                        <SmartTip
                            {...tip}
                            tipId={tip.id}
                            key={`${tip.id}-${tip.type}`}
                            isNew={tip.status === 'unviewed'}
                            size="compact"
                            openModalById={setAddReminderModalOpenedById}
                        />
                    ))}
                    {smartTips.map((tip) => (
                        <SmartTip
                            {...tip}
                            tipId={tip.id}
                            key={`${tip.id}-${tip.type}`}
                            isNew={tip.status === 'unviewed'}
                            size="compact"
                            openModalById={setAddReminderModalOpenedById}
                        />
                    ))}
                </>
            )}
        </SmartTipsSectionWrapper>

        <SmartTipsSectionWrapper>
            <SectionHeader
                title="Completed Smart Tips"
                withMargin
                counter={
                    smartTipsArchived.length + smartTipsEssentialArchived.length
                }
            />
            {isFetching && <Spinner />}

            {!isFetching &&
            smartTipsArchived.length === 0 &&
            smartTipsEssentialArchived.length === 0 ? (
                <NoResultsWrapper>
                    <NoResults
                        type="box"
                        title="Nothing in the box!"
                        message="You haven't completed any Smart Tips yet."
                    />
                </NoResultsWrapper>
            ) : (
                <ArchivedSTWrapper>
                    {smartTipsEssentialArchived.map((tip) => (
                        <SmartTip
                            {...tip}
                            tipId={tip.id}
                            key={`${tip.id}-${tip.type}-completed`}
                            isNew={tip.status === 'unviewed'}
                            size="compact"
                            openModalById={setAddReminderModalOpenedById}
                        />
                    ))}
                    {smartTipsArchived.map((tip) => (
                        <SmartTip
                            {...tip}
                            tipId={tip.id}
                            key={`${tip.id}-${tip.type}-completed`}
                            isNew={tip.status === 'unviewed'}
                            size="compact"
                            openModalById={setAddReminderModalOpenedById}
                        />
                    ))}
                </ArchivedSTWrapper>
            )}
        </SmartTipsSectionWrapper>

        {addReminderModalOpenedById && (
            <AddReminderModal
                id="editReminderModal"
                reminderId={addReminderModalOpenedById}
                title="title"
                smartTips={[
                    ...smartTipsToTW,
                    ...smartTipsEssential,
                    ...smartTips,
                    ...smartTipsEssentialArchived,
                    ...smartTipsArchived,
                ]}
                addReminder={(data) => addReminder(data)}
                closeModal={(e) => handleModalClose(e)}
                congratulationsView={() => (
                    <StyledCongratulationsModal>
                        <OkIcon />
                        <h3>Congratulations!</h3>
                        <span>
                            Your mobile reminder has been set. You can change
                            its details in{' '}
                            <Link to={ROUTES.userSettings}>user profile</Link>.
                        </span>
                        <Button
                            primary
                            onClick={() => setAddReminderModalOpenedById(null)}
                        >
                            Confirm
                        </Button>
                    </StyledCongratulationsModal>
                )}
            />
        )}
    </div>
);

SmartTipsView.propTypes = {
    isFetching: PropTypes.bool.isRequired,
    smartTips: PropTypes.arrayOf(PropTypes.any),
    smartTipsEssential: PropTypes.arrayOf(PropTypes.any),
    smartTipsToTW: PropTypes.arrayOf(PropTypes.any),
    smartTipsArchived: PropTypes.arrayOf(PropTypes.any),
    smartTipsEssentialArchived: PropTypes.arrayOf(PropTypes.any),
    handleModalClose: PropTypes.func.isRequired,
    addReminderModalOpenedById: PropTypes.string,
    setAddReminderModalOpenedById: PropTypes.func.isRequired,
    addReminder: PropTypes.func.isRequired,
};
SmartTipsView.defaultProps = {
    smartTips: [],
    smartTipsEssential: [],
    smartTipsToTW: [],
    smartTipsArchived: [],
    smartTipsEssentialArchived: [],
    addReminderModalOpenedById: null,
};

export default SmartTipsView;
