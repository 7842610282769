import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import SectionHeader from 'components/SectionHeader';
import SingleCourse from 'components/SingleCourse';
import SmartTips from 'containers/SmartTips';
import PerformanceDashboard from 'containers/PerformanceDashboard/PerformanceDashboard';
import NoResults from 'components/EmptyState/NoResults';
import {
    StyledDashboardWrapper,
    DashboardItemWrapper,
} from './DashboardMain.style';

const DashboardMainView = ({ courses }) => {
    const [coursesDisabled, setCoursesDisabled] = useState(false);
    const { performanceCoaching, isEstablished } = useSelector(
        (state) => state.tenantOrganization
    );

    useEffect(() => {
        if (!Array.isArray(courses) || courses.length === 0) {
            setCoursesDisabled(true);
        } else {
            setCoursesDisabled(false);
        }
    }, [courses]);

    return (
        <StyledDashboardWrapper oneColumn={coursesDisabled}>
            <DashboardItemWrapper>
                <SectionHeader title="New Smart Tips" />
                <SmartTips />

                {isEstablished && performanceCoaching && (
                    <>
                        <SectionHeader title="Your Performance" />
                        <PerformanceDashboard goToPerformance />
                    </>
                )}
            </DashboardItemWrapper>

            {!coursesDisabled && (
                <DashboardItemWrapper courses>
                    <SectionHeader title="Your Courses" />
                    {courses.length === 0 ? (
                        <NoResults
                            title="Currently you don't have any active courses."
                            message="New MedPower courses will appear here once you are enrolled. If you want to find courses that you previously completed, visit the Courses page."
                            isButtonVisible
                            linkedCourses
                        />
                    ) : (
                        <div>
                            {courses
                                .filter(
                                    (course) => course.status === 'enrolled'
                                )
                                .map((course) => (
                                    <SingleCourse key={course.id} {...course} />
                                ))}
                        </div>
                    )}
                </DashboardItemWrapper>
            )}
        </StyledDashboardWrapper>
    );
};

DashboardMainView.propTypes = {
    courses: PropTypes.arrayOf(PropTypes.any),
};

DashboardMainView.defaultProps = {
    courses: [],
};

export default DashboardMainView;
