/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import useAdmin from 'hooks/useAdmin';
import SectionHeader from 'components/SectionHeader';
import Spinner from 'components/Spinner';
import InfiniteScroll from 'react-infinite-scroller';
import SingleNotification from 'components/SingleNotification';
import Button from 'components/Button';
import NotificationsOptionsDropdown from 'containers/NotificationsOptionsDropdown';
import {
    NotificationsWrapper,
    NotificationsListWrapper,
    Divider,
    VerticalDivider,
    CTAHolder,
} from './Notifications.style';

const Notifications = ({
    isFetching,
    notifications,
    loadMore,
    hasMore,
    unseenCount,
    markAllAsRead,
    markAsRead,
}) => {
    const isAdmin = useAdmin();
    let wasLast7Days = false;
    let wasLast30Days = false;
    let wasOlder = false;

    const getDaysAgo = (date) => {
        const days = Math.floor(
            (Date.now() - new Date(date).getTime()) / 1000 / 86400
        );
        return days;
    };

    return (
      <NotificationsWrapper>
        <SectionHeader
                title="Notifications"
                withPadding
                counter={unseenCount}
                cta={() =>
                    isAdmin ? (
                      <CTAHolder>
                        <Button primary onClick={markAllAsRead}>
                          Mark all read
                        </Button>
                      </CTAHolder>
                    ) : (
                      <CTAHolder>
                        <Button primary onClick={markAllAsRead}>
                          Mark all read
                        </Button>
                        <VerticalDivider />
                        <NotificationsOptionsDropdown />
                      </CTAHolder>
                    )}
            />
        <InfiniteScroll
                loadMore={loadMore}
                loader={<Spinner key="spinner" />}
                hasMore={hasMore}
            >
          <NotificationsListWrapper>
            {notifications.map((item) => {
                        if (getDaysAgo(item.created_at) < 8) {
                            if (!wasLast7Days) {
                                wasLast7Days = true;
                                return (
                                  <div key={item.id}>
                                    <Divider>Last 7 days</Divider>
                                    <SingleNotification
                                            markAsRead={() =>
                                                markAsRead(item.id)}
                                            name={item.event_name}
                                            createdAt={item.created_at}
                                            isRead={item.seen_at !== null}
                                            context={item.event_context}
                                            critical={
                                                item.event_type ===
                                                'critical_memo'
                                            }
                                        />
                                  </div>
                                );
                            }
                        } else if (getDaysAgo(item.created_at) < 31) {
                            if (!wasLast30Days) {
                                wasLast30Days = true;
                                return (
                                  <div key={item.id}>
                                    <Divider>Last 30 days</Divider>
                                    <SingleNotification
                                            markAsRead={() =>
                                                markAsRead(item.id)}
                                            name={item.event_name}
                                            createdAt={item.created_at}
                                            isRead={item.seen_at !== null}
                                            context={item.event_context}
                                            critical={
                                                item.event_type ===
                                                'critical_memo'
                                            }
                                        />
                                  </div>
                                );
                            }
                        } else if (!wasOlder) {
                            wasOlder = true;
                            return (
                              <div key={item.id}>
                                <Divider>Older</Divider>
                                <SingleNotification
                                        markAsRead={() => markAsRead(item.id)}
                                        name={item.event_name}
                                        createdAt={item.created_at}
                                        isRead={item.seen_at !== null}
                                        context={item.event_context}
                                        critical={
                                            item.event_type === 'critical_memo'
                                        }
                                    />
                              </div>
                            );
                        }

                        return (
                          <SingleNotification
                                key={item.id}
                                markAsRead={() => markAsRead(item.id)}
                                name={item.event_name}
                                createdAt={item.created_at}
                                isRead={item.seen_at !== null}
                                context={item.event_context}
                                critical={item.event_type === 'critical_memo'}
                            />
                        );
                    })}
          </NotificationsListWrapper>
          {isFetching && <Spinner />}
        </InfiniteScroll>
      </NotificationsWrapper>
    );
};

Notifications.propTypes = {
    isFetching: PropTypes.bool.isRequired,
    notifications: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            event_name: PropTypes.string,
            seen_at: PropTypes.string,
            created_at: PropTypes.string,
            event_context: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.object,
            ]),
        })
    ).isRequired,
    unseenCount: PropTypes.number.isRequired,
    loadMore: PropTypes.func.isRequired,
    hasMore: PropTypes.bool.isRequired,
    markAllAsRead: PropTypes.func.isRequired,
    markAsRead: PropTypes.func.isRequired,
};

export default Notifications;
/* eslint-enable camelcase */
