import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Navbar from 'components/Navbar';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'routing/constants';
import NavbarResponsive from 'components/NavbarResponsive';

const NavbarContainer = ({ currentUser }) => {
    const history = useHistory();

    const redirectToFAQ = () => history.push(ROUTES.faq);

    return (
      <>
        <Navbar currentUser={currentUser} redirectToFAQ={redirectToFAQ} />
        <NavbarResponsive
                currentUser={currentUser}
                redirectToFAQ={redirectToFAQ}
            />
      </>
    );
};

NavbarContainer.propTypes = {
    currentUser: PropTypes.shape({
        userName: PropTypes.string,
    }).isRequired,
    actions: PropTypes.shape({}).isRequired,
};

NavbarContainer.defaultProps = {};

const mapStateToProps = (state) => ({
    currentUser: state.currentUser,
});

const mapDispatchToProps = () => ({
    actions: {},
});

export default connect(mapStateToProps, mapDispatchToProps)(NavbarContainer);
