import styled, { css } from 'styled-components/macro';
import { ReactComponent as UploadIcon } from 'assets/svg/upload-arrow-top.svg';

const uploadAreaCommon = css`
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const UploadImageArea = styled.div`
    ${uploadAreaCommon};
    width: 134px;
    height: 134px;
    background-color: ${({ theme }) => theme.color.white};
    box-shadow: ${({ theme }) => theme.boxShadow.base};
    position: relative;
`;

export const StyledUploadIcon = styled(UploadIcon)`
    position: absolute;
    right: 3px;
    bottom: 3px;
    cursor: pointer;
`;

export const DropzoneArea = styled.div`
    ${uploadAreaCommon};
    width: 120px;
    height: 120px;
    background-color: ${({ theme }) => theme.color.bgPrimaryLight};
    cursor: pointer;
`;
export const PictureArea = styled.img`
    width: 120px;
    height: 120px;
    cursor: pointer;
    border-radius: 50%;
`;
