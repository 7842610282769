import {
    DISPATCH_SUCCESS_ALERT,
    DISPATCH_ERROR_ALERT,
    REMOVE_ALERT,
} from 'redux/actions/systemAlerts';

const initialState = {
    list: [],
};

const systemAlerts = (state = initialState, action) => {
    switch (action.type) {
        case DISPATCH_SUCCESS_ALERT:
        case DISPATCH_ERROR_ALERT:
            return {
                ...state,
                list: [...state.list, action.payload],
            };

        case REMOVE_ALERT:
            return {
                ...state,
                list: state.list.filter(
                    (alert) => alert.id !== action.payload.id
                ),
            };

        default:
            return state;
    }
};

export default systemAlerts;
