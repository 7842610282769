import styled from 'styled-components/macro';
import Button from 'components/Button';
import { Link } from 'react-router-dom';

export const ProfileHolder = styled.div`
    background-color: ${({ theme }) => theme.color.white};
    border: ${({ theme }) => theme.border.base};
    border-radius: ${({ theme }) => theme.border.radiusBase};
    padding: 16px;

    margin: 16px;
    display: flex;
    align-items: center;

    @media (max-width: ${({ theme }) => `${theme.breakpoints.xl}px`}) {
        flex-wrap: wrap;
    }

    @media (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
        justify-content: center;
        max-width: calc(100vw - 48px);
    }
`;

export const ProfileImgHolder = styled.img`
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin: 8px;
    object-fit: cover;
`;
export const ProfileImgEmpty = styled.div`
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin: 8px;
    object-fit: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.color.bgPrimaryLight};
`;

export const ProfileDetails = styled.div`
    display: flex;
    flex-direction: column;
    margin: 16px;

    h3 {
        font-weight: 500;
        font-size: ${({ theme }) => theme.fontSize.big};
        line-height: 22px;
        margin: 0 0 8px;
    }

    span {
        color: ${({ theme }) => theme.color.textLight};
    }

    @media (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
        overflow-x: auto;
    }
`;

export const TeamsHolder = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 40px;

    @media (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
        margin: 16px;
        width: 100%;
    }
`;

export const TeamsTitle = styled.span`
    color: ${({ theme }) => theme.color.textLight};
`;

export const BadgeHolder = styled.div`
    display: flex;
    margin: 4px 0 0;

    span {
        margin: 4px 4px 0 0;
    }

    @media (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
        flex-wrap: wrap;
    }
`;

export const EditButton = styled(Button)`
    margin: 16px 16px 16px auto;
`;

export const StyledLink = styled(Link)`
    margin-left: auto;
`;
