import styled, { css } from 'styled-components/macro';

export const SwitchDisplay = styled.span`
    width: 175px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
`;

export const SwitchLabel = styled.label`
    display: flex;
    align-items: center;
    min-width: 57px;
    height: 32px;
    padding: 4px;
    cursor: pointer;

    border-radius: ${({ theme }) => theme.border.radiusBase};
    border: ${({ theme }) => theme.border.base};
    background-color: ${({ theme }) => theme.color.bgPrimarySuperLight};
`;

export const SwitchInnerLabel = styled.span`
    display: block;
    width: 24px;
    height: 24px;
    border-radius: 50px;
    background-color: ${({ theme }) => theme.color.bgSearch};
    transition-duration: 200ms;

    ${({ switchedOn }) =>
        switchedOn &&
        css`
            transform: translateX(100%);
            background-color: ${({ theme }) => theme.color.secondary};
        `}

    ${({ partiallyEnabled }) =>
        partiallyEnabled &&
        css`
            background-color: ${({ theme }) => theme.color.tertiary};
        `}
`;

export const SwitchTextLabel = styled.span`
    color: ${({ theme }) => theme.color.bgModal};
    text-align: left;
    padding-left: 16px;
    width: 100%;

    ${({ switchedOn }) =>
        switchedOn &&
        css`
            color: ${({ theme }) => theme.color.secondary};
        `}

    ${({ partiallyEnabled }) =>
        partiallyEnabled &&
        css`
            color: ${({ theme }) => theme.color.tertiary};
        `}
`;
