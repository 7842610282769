import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CircleChart from 'components/charts/CircleChart';
import MetricModalHolder, {
    Stats,
    TitleHolder,
    Indicator,
    IndicatorProgress,
    IndicatorGoal,
} from './MetricModal.style';

const MetricModal = ({
    title,
    subtitle,
    scoring,
    goal,
    withChart,
    customCta,
}) => {
    const [showCta, setShowCta] = useState(false);
    const [parsedScoring, setParsedScoring] = useState(0);
    const [parsedGoal, setParsedGoal] = useState(0);
    const [efficient, setEfficient] = useState(false);

    useEffect(() => {
        setParsedScoring(Number.isInteger(scoring) ? scoring : 0);
        setParsedGoal(Number.isInteger(goal) ? goal : 0);
    }, [scoring, goal]);

    useEffect(() => {
        setEfficient(parsedScoring >= parsedGoal);
    }, [parsedScoring, parsedGoal]);

    if (withChart) {
        return (
          <MetricModalHolder withChart>
            <TitleHolder>
              <strong>{title}</strong>
              <span>{subtitle}</span>
            </TitleHolder>
            <CircleChart scoringPercent={scoring} />
          </MetricModalHolder>
        );
    }

    return (
      <MetricModalHolder
            onFocus={() => setShowCta(true)}
            onMouseOver={() => setShowCta(true)}
            onBlur={() => setShowCta(false)}
            onMouseLeave={() => setShowCta(false)}
        >
        <strong>{title}</strong>
        <span>{subtitle}</span>

        <Stats>
          {customCta && showCta ? (
                    customCta(setShowCta)
                ) : (
                  <Indicator>
                    <IndicatorProgress
                            scoring={parsedScoring}
                            efficient={efficient}
                        >
                      <span>{`${parsedScoring}%`}</span>
                    </IndicatorProgress>
                    <IndicatorGoal goal={parsedGoal} />
                  </Indicator>
                )}
        </Stats>
      </MetricModalHolder>
    );
};

MetricModal.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    goal: PropTypes.number,
    scoring: PropTypes.number,
    withChart: PropTypes.bool,
    customCta: PropTypes.oneOfType([PropTypes.func, PropTypes.any]),
};

MetricModal.defaultProps = {
    withChart: false,
    goal: 0,
    customCta: undefined,
    scoring: 0,
};

export default MetricModal;
