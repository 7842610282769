import styled, { keyframes } from 'styled-components/macro';
import Logo from 'components/Logo';

export const StyledSplashScreen = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 9999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${({ theme }) => theme.color.bgBase};
`;

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
`;

export const AnimatedLogo = styled(Logo)`
    animation: ${rotate} infinite;
    animation-duration: 2s;
    animation-delay: -1s;
`;
