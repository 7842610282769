import styled, { css } from 'styled-components/macro';

export const Wrapper = styled.div`
    position: relative;
    height: auto;

    ${({ withButtons }) =>
        withButtons &&
        css`
            margin-bottom: 0px;
            padding-bottom: 0px;
            height: auto;
        `}

    ${({ disabled }) =>
        disabled &&
        css`
            height: auto;
        `}
`;

export const Dropdown = styled.div`
    width: 100%;
    top: 54px;
    z-index: 5;
    position: absolute;
    padding: 8px;
    max-height: 220px;
    overflow-y: auto;

    ${({ theme }) => css`
        box-shadow: ${theme.boxShadow.base};
        background-color: ${theme.color.white};
        border-radius: ${theme.border.radiusSmall};
    `}
`;

export const SelectedItemsWrapper = styled.div`
    position: relative;
    z-index: 1;
    width: 100%;
    height: 210px;
    overflow-y: scroll;

    ::-webkit-scrollbar {
        width: 0px;
        background: transparent;
    }

    /* ${({ disabled }) =>
        disabled &&
        css`
            display: none;
        `} */
`;

export const SpinnerWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`;
