import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useOffset from 'hooks/useOffset';
import establishNotificationsThunk, {
    loadMoreNotificationsThunk,
    resetNotificationsThunk,
    markAllNotificationsAsReadThunk,
    markNotificationAsReadThunk,
} from 'redux/actions/thunks/notifications';
import NotificationsView from 'views/Notifications';
import { useAuth0 } from '@auth0/auth0-react';

const INFINITE_SCROLL_THRESHOLD = 20;

const Notifications = () => {
    const dispatch = useDispatch();
    const { getAccessTokenSilently, logout } = useAuth0();
    const [offset, incrementOffset, resetOffset] = useOffset(
        INFINITE_SCROLL_THRESHOLD
    );

    const {
        areNotificationsFetching,
        hasMoreNotifications,
        notifications,
        unseenCount,
    } = useSelector((state) => state.notifications);

    const hasMore = hasMoreNotifications && !areNotificationsFetching;

    const loadMore = () => {
        dispatch(
            loadMoreNotificationsThunk(
                getAccessTokenSilently,
                logout,
                INFINITE_SCROLL_THRESHOLD,
                offset
            )
        );
        incrementOffset();
    };

    const markAllAsRead = () => {
        dispatch(
            markAllNotificationsAsReadThunk(getAccessTokenSilently, logout)
        );
        dispatch(
            establishNotificationsThunk(
                getAccessTokenSilently,
                logout,
                INFINITE_SCROLL_THRESHOLD,
                0
            )
        );
        resetOffset(INFINITE_SCROLL_THRESHOLD);
    };

    const markAsRead = (notificationId) => {
        dispatch(
            markNotificationAsReadThunk(
                getAccessTokenSilently,
                logout,
                notificationId
            )
        );
    };

    useEffect(() => {
        dispatch(
            establishNotificationsThunk(
                getAccessTokenSilently,
                logout,
                INFINITE_SCROLL_THRESHOLD,
                0
            )
        );
        resetOffset(INFINITE_SCROLL_THRESHOLD);
        return dispatch(resetNotificationsThunk());
    }, []);

    return (
      <NotificationsView
            isFetching={areNotificationsFetching}
            notifications={notifications}
            unseenCount={unseenCount}
            loadMore={loadMore}
            hasMore={hasMore}
            markAllAsRead={markAllAsRead}
            markAsRead={markAsRead}
        />
    );
};

export default Notifications;
