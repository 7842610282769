import {
    createTeam,
    createTeamSuccess,
    createTeamFailure,
    joinTeam,
    joinTeamSuccess,
    joinTeamFailure,
    establishTeams,
    establishTeamsSuccess,
    establishTeamsFailure,
    updateTeam,
    updateTeamSuccess,
    updateTeamFailure,
    archiveTeam,
    archiveTeamSuccess,
    archiveTeamFailure,
} from 'redux/actions/team';
import {
    dispatchSuccessAlertThunk,
    dispatchErrorAlertThunk,
} from 'redux/actions/thunks/systemAlerts';
import { establishTeamMembersThunk } from 'redux/actions/thunks/teamMembers';
import apiRoutes from 'config/apiRoutes';
import { ROUTES } from 'routing/constants';

const establishTeamsThunk = (getAccessTokenSilently, logout, limit, offset) => (
    dispatch
) => {
    dispatch(establishTeams());

    return getAccessTokenSilently()
        .then((token) => {
            fetch(apiRoutes.get.teams(limit, offset), {
                method: 'GET',
                headers: {
                    organization_slug: window.localStorage.getItem(
                        'MP_ORGANIZATION'
                    ),
                    Authorization: `Token token="${token}"`,
                },
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.error) {
                        return dispatch(establishTeamsFailure(res.error));
                    }

                    return dispatch(establishTeamsSuccess(res.teams));
                })
                .catch((err) =>
                    dispatch(
                        establishTeamsFailure({
                            err,
                        })
                    )
                );
        })
        .catch(() => logout({ returnTo: window.location.origin }));
};

export const createTeamThunk = (
    getAccessTokenSilently,
    logout,
    teamData,
    isAdmin,
    creatorId = '',
    history
) => (dispatch) => {
    dispatch(createTeam());

    const fd = new FormData();

    fd.append('team[name]', teamData.name);

    if (isAdmin && creatorId) {
        fd.append('team[creator_id]', creatorId);
    }

    teamData.user_ids.forEach((id) => fd.append('team[user_ids][]', id));

    if (teamData.picture) {
        fd.append('team[picture]', teamData.picture);
    }

    const route = isAdmin
        ? apiRoutes.post.createTeamByAdmin
        : apiRoutes.post.createTeam;

    return getAccessTokenSilently()
        .then((token) => {
            fetch(route, {
                method: 'POST',
                headers: {
                    organization_slug: window.localStorage.getItem(
                        'MP_ORGANIZATION'
                    ),
                    Authorization: `Token token="${token}"`,
                },
                body: fd,
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.error) {
                        dispatch(
                            dispatchErrorAlertThunk(teamData.name, res.error)
                        );
                        return dispatch(createTeamFailure(res.error));
                    }

                    if (isAdmin) {
                        history.push(`${ROUTES.teamOverview}/${res.id}`);
                    } else {
                        document.location = ROUTES.team;
                    }

                    dispatch(
                        dispatchSuccessAlertThunk(
                            teamData.name,
                            'Team has been created'
                        )
                    );
                    dispatch(
                        establishTeamsThunk(getAccessTokenSilently, logout)
                    );
                    return dispatch(createTeamSuccess(res));
                })
                .catch((err) => {
                    dispatch(dispatchErrorAlertThunk(teamData.name));
                    dispatch(
                        createTeamFailure({
                            err,
                        })
                    );
                });
        })
        .catch(() => logout({ returnTo: window.location.origin }));
};

export const joinTeamThunk = (getAccessTokenSilently, logout, teamId) => (
    dispatch
) => {
    dispatch(joinTeam());

    return getAccessTokenSilently()
        .then((token) => {
            fetch(apiRoutes.post.joinTeam(teamId), {
                method: 'POST',
                headers: {
                    organization_slug: window.localStorage.getItem(
                        'MP_ORGANIZATION'
                    ),
                    Authorization: `Token token="${token}"`,
                },
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.error) {
                        dispatch(dispatchErrorAlertThunk(teamId, res.error));
                        return dispatch(joinTeamFailure(res.error));
                    }

                    dispatch(
                        dispatchSuccessAlertThunk(
                            teamId,
                            'You have joined the team'
                        )
                    );
                    dispatch(
                        establishTeamsThunk(getAccessTokenSilently, logout)
                    );
                    document.location = `${ROUTES.team}/overview/${teamId}`;
                    return dispatch(joinTeamSuccess());
                })
                .catch((err) => {
                    dispatch(dispatchErrorAlertThunk(teamId, err.error));
                    return dispatch(
                        joinTeamFailure({
                            err,
                        })
                    );
                });
        })
        .catch(() => logout({ returnTo: window.location.origin }));
};

export const updateTeamThunk = (
    getAccessTokenSilently,
    logout,
    teamData,
    isAdmin
) => (dispatch) => {
    dispatch(updateTeam());

    let url = apiRoutes.put.updateTeam(teamData.teamId);

    if (isAdmin) {
        url = apiRoutes.put.updateOrganizationTeam(teamData.teamId);
    }

    const fd = new FormData();
    teamData.user_ids.forEach((id) => fd.append('team[user_ids][]', id));

    return getAccessTokenSilently()
        .then((token) => {
            fetch(url, {
                method: 'PUT',
                headers: {
                    organization_slug: window.localStorage.getItem(
                        'MP_ORGANIZATION'
                    ),
                    Authorization: `Token token="${token}"`,
                },
                body: fd,
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.error) {
                        dispatch(
                            dispatchErrorAlertThunk(teamData.teamId, res.error)
                        );
                        return dispatch(updateTeamFailure(res.error));
                    }

                    dispatch(
                        dispatchSuccessAlertThunk(
                            teamData.teamId,
                            teamData.user_ids.length === 1
                                ? 'Selected user has been added to the team'
                                : 'Selected users have been added to the team'
                        )
                    );
                    dispatch(
                        establishTeamMembersThunk(
                            getAccessTokenSilently,
                            logout,
                            teamData.teamId
                        )
                    );
                    dispatch(
                        establishTeamsThunk(getAccessTokenSilently, logout)
                    );
                    return dispatch(updateTeamSuccess());
                })
                .catch((err) => {
                    dispatch(
                        dispatchErrorAlertThunk(teamData.teamId, err.error)
                    );
                    dispatch(
                        updateTeamFailure({
                            err,
                        })
                    );
                });
        })
        .catch(() => logout({ returnTo: window.location.origin }));
};

export const archiveTeamThunk = (getAccessTokenSilently, logout, teamId) => (
    dispatch
) => {
    dispatch(archiveTeam());

    return getAccessTokenSilently()
        .then((token) => {
            fetch(apiRoutes.delete.archiveTeam(teamId), {
                method: 'DELETE',
                headers: {
                    organization_slug: window.localStorage.getItem(
                        'MP_ORGANIZATION'
                    ),
                    Authorization: `Token token="${token}"`,
                },
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.error) {
                        dispatch(dispatchErrorAlertThunk(teamId, res.error));
                        return dispatch(archiveTeamFailure(res.error));
                    }

                    dispatch(
                        dispatchSuccessAlertThunk(
                            teamId,
                            'Team has been deleted'
                        )
                    );
                    dispatch(
                        establishTeamsThunk(getAccessTokenSilently, logout)
                    );
                    return dispatch(archiveTeamSuccess());
                })
                .catch((err) => {
                    dispatch(dispatchErrorAlertThunk(teamId, err.error));
                    dispatch(
                        archiveTeamFailure({
                            err,
                        })
                    );
                });
        })
        .catch(() => logout({ returnTo: window.location.origin }));
};
export const archiveOrganizationTeamThunk = (
    getAccessTokenSilently,
    logout,
    teamId,
    history
) => (dispatch) => {
    dispatch(archiveTeam());

    return getAccessTokenSilently()
        .then((token) => {
            fetch(apiRoutes.delete.archiveOrganizationTeam(teamId), {
                method: 'DELETE',
                headers: {
                    organization_slug: window.localStorage.getItem(
                        'MP_ORGANIZATION'
                    ),
                    Authorization: `Token token="${token}"`,
                    'Content-Type': 'application/json',
                },
            })
                .then((res) => {
                    if (res.error) {
                        dispatch(dispatchErrorAlertThunk(teamId, res.error));
                        return dispatch(archiveTeamFailure(res.error));
                    }

                    dispatch(
                        dispatchSuccessAlertThunk(
                            teamId,
                            'Team has been deleted'
                        )
                    );
                    dispatch(
                        establishTeamsThunk(getAccessTokenSilently, logout)
                    );

                    setTimeout(() => {
                        history.push(ROUTES.team);
                    }, 200);

                    return dispatch(archiveTeamSuccess());
                })
                .catch((err) => {
                    dispatch(dispatchErrorAlertThunk(teamId, err.error));
                    dispatch(
                        archiveTeamFailure({
                            err,
                        })
                    );
                });
        })
        .catch(() => logout({ returnTo: window.location.origin }));
};

export default establishTeamsThunk;
