import {
    ESTABLISH_ORGANIZATION_TEAMS,
    ESTABLISH_ORGANIZATION_TEAMS_SUCCESS,
    ESTABLISH_ORGANIZATION_TEAMS_FAILURE,
    LOAD_MORE_ORGANIZATION_TEAMS,
    MARK_ALL_ORGANIZATION_TEAMS_LOADED,
    RESET_ORGANIZATION_TEAMS,
} from 'redux/actions/organizationTeams';

export const initialState = {
    areOrganizationTeamsEstablished: false,
    isOrganizationTeamsError: false,
    areOrganizationTeamsFetching: false,
    organizationTeams: [],
    error: null,
    hasMoreOrganizationTeams: true,
};

const organizationTeams = (state = initialState, action) => {
    switch (action.type) {
        case ESTABLISH_ORGANIZATION_TEAMS:
            return {
                ...state,
                areOrganizationTeamsFetching: true,
                isOrganizationTeamsError: false,
            };

        case ESTABLISH_ORGANIZATION_TEAMS_SUCCESS:
            return {
                ...state,
                areOrganizationTeamsFetching: false,
                areOrganizationTeamsEstablished: true,
                organizationTeams: action.payload,
            };

        case ESTABLISH_ORGANIZATION_TEAMS_FAILURE:
            return {
                ...state,
                areOrganizationTeamsFetching: false,
                isOrganizationTeamsError: true,
                error: action.err,
            };

        case LOAD_MORE_ORGANIZATION_TEAMS:
            return {
                ...state,
                areOrganizationTeamsFetching: false,
                areOrganizationTeamsEstablished: true,
                organizationTeams: [
                    ...state.organizationTeams,
                    ...action.payload,
                ],
            };
        case MARK_ALL_ORGANIZATION_TEAMS_LOADED:
            return {
                ...state,
                hasMoreOrganizationTeams: false,
                areOrganizationTeamsFetching: false,
                areOrganizationTeamsEstablished: true,
            };

        case RESET_ORGANIZATION_TEAMS:
            return initialState;

        default:
            return state;
    }
};

export default organizationTeams;
