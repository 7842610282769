import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useMPA from 'hooks/useMPA';
import Spinner from 'components/Spinner';
import TeamMessageForm from 'components/TeamMessageForm';
import ConfirmActionModal from 'components/ConfirmActionModal';
import CommentMessage from './CommentMessage';
import NotificationMessage from './NotificationMessage';
import {
    TeamOverviewMessagesWrapper,
    Splash,
} from './TeamOverviewMessages.style';

const MESSAGES_LIMIT = 10;

const orderMessages = (msgs) =>
    msgs.sort((r1, r2) => {
        const t1 = new Date(r1.created_at).getTime();
        const t2 = new Date(r2.created_at).getTime();

        if (t1 < t2) {
            return -1;
        }
        if (t1 > t2) {
            return 1;
        }

        return 0;
    });

const TeamOverviewMessages = ({
    messages,
    fetchPreviousMessages,
    leaveMessageFeedback,
    handleSendMessage,
    deleteMessage,
    isAdmin,
}) => {
    const [messageToDeleteId, setMessageToDeleteId] = useState(null);
    const [confirmActionModalVisible, setConfirmActionModalVisible] = useState(
        false
    );
    const [scrollHeight, setScrollHeight] = useState(null);
    const [savedOffset, setSavedOffset] = useState(null);
    const [loadedMessages, setLoadedMessages] = useState(
        orderMessages(messages.items) || []
    );

    const { isCurrentUserMPA } = useMPA();

    useEffect(() => {
        if (messages) {
            setLoadedMessages(orderMessages(messages.items));
        }
    }, [messages]);

    useEffect(() => {
        const wrapper = document.getElementById('messagesWrapper');

        if (!wrapper) {
            return;
        }

        if (savedOffset !== null) {
            wrapper.scrollTop = savedOffset;
            return;
        }

        if (scrollHeight !== null) {
            wrapper.scrollTop = scrollHeight;
        }

        wrapper.scrollTop = wrapper.scrollHeight;
    }, [loadedMessages]);

    const toggleLikeMessage = (id) => {
        const wrapper = document.getElementById('messagesWrapper');

        if (wrapper) {
            wrapper.scrollTop = savedOffset;
        }

        const message = loadedMessages.find((msg) => msg.id === id);

        if (message && message.liked) {
            leaveMessageFeedback(id, false);
        }

        if (message && !message.liked) {
            leaveMessageFeedback(id, true);
        }
    };

    const loadOlder = () => {
        if (messages.areMessagesFetching) {
            return;
        }

        fetchPreviousMessages(MESSAGES_LIMIT, loadedMessages.length);
    };

    return (
      <>
        <TeamOverviewMessagesWrapper
                onScroll={(e) => {
                    if (
                        e.target.scrollTop % 5 === 0 ||
                        e.target.scrollTop === 0
                    ) {
                        setSavedOffset(e.target.scrollTop);
                    }

                    if (e.target.scrollTop === 0) {
                        setScrollHeight(e.target.scrollHeight);
                        loadOlder();
                    }
                }}
                id="messagesWrapper"
            >
          {loadedMessages.map((message) => {
                    if (message.type === 'comment') {
                        return (
                          <CommentMessage
                                key={message.id}
                                message={message}
                                deleteAllowed={isAdmin || isCurrentUserMPA}
                                handleDelete={() => {
                                    setMessageToDeleteId(message.id);
                                    setConfirmActionModalVisible(true);
                                }}
                                handleLike={() =>
                                    toggleLikeMessage(message.id, savedOffset)}
                            />
                        );
                    }

                    if (message.type === 'notification') {
                        return (
                          <NotificationMessage
                                key={message.id}
                                message={message}
                                handleLike={() => toggleLikeMessage(message.id)}
                            />
                        );
                    }

                    return <div key={message.id}>Invalid message</div>;
                })}
          {messages.areMessagesFetching && (
            <Splash>
              <Spinner />
            </Splash>
                )}
        </TeamOverviewMessagesWrapper>
        {confirmActionModalVisible && (
        <ConfirmActionModal
                    isVisible
                    confirmLabel="Delete message"
                    onConfirm={() => deleteMessage(messageToDeleteId)}
                    onToggle={() => setConfirmActionModalVisible(false)}
                    description=""
                />
            )}
        <TeamMessageForm sendMessage={handleSendMessage} />
      </>
    );
};

TeamOverviewMessages.propTypes = {
    messages: PropTypes.shape({
        areMessagesEstablished: PropTypes.bool,
        areMessagesFetching: PropTypes.bool,
        isMessagesError: PropTypes.bool,
        items: PropTypes.arrayOf(PropTypes.any),
    }).isRequired,
    currentUser: PropTypes.shape({
        id: PropTypes.string,
        userId: PropTypes.string,
    }).isRequired,
    fetchPreviousMessages: PropTypes.func.isRequired,
    leaveMessageFeedback: PropTypes.func.isRequired,
    handleSendMessage: PropTypes.func.isRequired,
    deleteMessage: PropTypes.func.isRequired,
    isAdmin: PropTypes.bool.isRequired,
};

export default TeamOverviewMessages;
