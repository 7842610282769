import React from 'react';
import { ReactComponent as CourseIcon } from 'assets/svg/course.svg';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import {
    StyledSingleCourse,
    StyledCourseTitleHolder,
    StyledCourseIconHolder,
    StyledCourseTitle,
    StyledNewBadge,
    StyledButtonHolder,
} from './SingleCourse.style';

const SingleCourse = ({ title, subtitle, buttonLabel, isNew, ...rest }) => (
  <StyledSingleCourse>
    <StyledCourseTitleHolder>
      <StyledCourseIconHolder>
        <CourseIcon />
      </StyledCourseIconHolder>
      <StyledCourseTitle>
        <strong>{title}</strong>
        <span>{subtitle}</span>
      </StyledCourseTitle>
    </StyledCourseTitleHolder>
    <StyledButtonHolder>
      <a
                href={rest.url}
                alt="Course url"
                rel="noopener noreferrer"
                target="_blank"
            >
        <Button secondary>{buttonLabel}</Button>
      </a>
    </StyledButtonHolder>
    {isNew && <StyledNewBadge>New!</StyledNewBadge>}
  </StyledSingleCourse>
);

SingleCourse.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    buttonLabel: PropTypes.string.isRequired,
    isNew: PropTypes.bool,
};

SingleCourse.defaultProps = {
    isNew: false,
};

export default SingleCourse;
