import styled, { css } from 'styled-components/macro';

const inputSettings = css`
    width: 327px;
    height: 48px;
    font-size: ${({ theme }) => theme.fontSize.base};
    color: ${({ theme }) => theme.color.textNavigation};

    @media (max-width: ${({ theme }) => `${theme.breakpoints.mobile}px`}) {
        width: auto;
    }
`;

export const StyledSearchHolder = styled.div`
    position: relative;
    margin-right: auto;
    ${inputSettings}

    svg {
        cursor: pointer;
        position: absolute;
        right: 24px;
        top: calc(50% - 8px);
    }
`;

export const StyledMobileSearchHolder = styled(StyledSearchHolder)`
    position: fixed;
    left: 100px;

    svg {
        right: 16px;
    }

    ${({ isSearchVisible }) =>
        isSearchVisible &&
        css`
            top: 0;
            right: 16px;
            left: 0;
            background-color: ${({ theme }) => theme.color.bgBase};
            height: 90px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            z-index: 99999;

            svg {
                right: 16px;
            }
        `}
`;

export const StyledSearch = styled.input`
    padding: 0 24px;
    background-color: ${({ theme }) => theme.color.bgSearch};
    border: none;
    border-radius: ${({ theme }) => theme.border.radiusX2};
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${inputSettings}

    &::placeholder {
        font-size: ${({ theme }) => theme.fontSize.base};
        color: ${({ theme }) => theme.color.textNavigation};
    }
`;

export const StyledMobileSearch = styled(StyledSearch)`
    display: block;
    width: ${({ isSearchVisible }) => (isSearchVisible ? 'auto' : '20px')};
`;

export const SearchResultsDropdown = styled.div`
    position: absolute;
    top: 50px;

    width: 327px;
    max-height: 452px;

    border: ${({ theme }) => theme.border.base};
    border-radius: ${({ theme }) => theme.border.radiusBase};
    background-color: ${({ theme }) => theme.color.white};

    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    padding: 16px;
`;
export const MobileSearchResultsDropdown = styled.div`
    position: absolute;
    top: 90px;

    width: 100%;
    max-width: 327px;
    max-height: 452px;

    border: ${({ theme }) => theme.border.base};
    border-radius: ${({ theme }) => theme.border.radiusBase};
    background-color: ${({ theme }) => theme.color.white};

    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    padding: 16px;
`;

export const SearchResultsSection = styled.div`
    width: 100%;
    height: 50%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;

    padding-top: 16px;

    strong {
        padding: 8px 16px;
        margin-bottom: 4px;
        font-weight: 400;
        color: ${({ theme }) => theme.color.textLight};
    }

    span {
        padding: 16px;
        width: 100%;
        border-radius: ${({ theme }) => theme.border.radiusBase};
        cursor: pointer;
        display: block;

        &:hover {
            background-color: ${({ theme }) => theme.color.bgSearch};
        }
    }
`;
