import styled from 'styled-components/macro';

export const Wrapper = styled.div`
    padding: 0 16px;
`;

export const Title = styled.div`
    padding-bottom: 22px;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    color: ${({ theme }) => theme.color.textLight};
`;

export const InputWrapper = styled.div`
    width: 40%;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    grid-gap: 16px;
`;

export const FormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 22px 28px;
    background-color: ${({ theme }) => theme.color.white};
    border: ${({ theme }) => theme.border.base};
    border-radius: ${({ theme }) => theme.border.radiusBase};
`;

export const ButtonsWrapper = styled.div`
    width: 40%;
    padding-top: 22px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 8px;
`;
