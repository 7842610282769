import React from 'react';
import PropTypes from 'prop-types';
import AccordionFAQ from 'components/Accordion/AccordionFAQ';
import SectionHeader from 'components/SectionHeader';
import { ReactComponent as SendIcon } from 'assets/svg/send-email.svg';
import { ReactComponent as BellIcon } from 'assets/svg/alarm-bell.svg';
import { ReactComponent as SettingsIcon } from 'assets/svg/settings.svg';
import theme from 'assets/theme/mainTheme';
import {
    FAQContainer,
    FAQItems,
    FaqItemInternalText,
    SectionSeparator,
    HeaderButtons,
} from './FAQ.style';

const FaqItem = ({ label, children }) => (
  <AccordionFAQ label={label}>
    <FaqItemInternalText>{children}</FaqItemInternalText>
  </AccordionFAQ>
);

const FAQ = () => (
  <FAQContainer>
    <SectionHeader
            title="FAQ"
            withMargin
            cta={() => (
              <HeaderButtons>
                {/* Temporary disable Tutorial until the new version is created */}
                {/* <Button
                            primary
                            onClick={() => {
                                clearItem();
                                history.push(ROUTES.onboarding);
                            }}
                        >
                            Tutorial
                        </Button> */}
                <a href="mailto:support@medpower.com?subject=learn.medpower.com%20Support">
                  Support 
                  {' '}
                  <SendIcon fill={theme.color.white} />
                </a>
              </HeaderButtons>
            )}
        />
    <FAQItems>
      <SectionSeparator>Smart Tips</SectionSeparator>
      <FaqItem label="What is a Smart Tip and why am I seeing them?">
        A Smart Tip is a short microlearning video or job aid that helps
        you to learn or improve a specific skill. Smart Tips can be
        assigned individually by a hospital administrator, or they can
        be assigned automatically when quality or performance measures
        are below your goals. You may also search the library of all the
        Smart Tips that are available.
      </FaqItem>

      <FaqItem label="How do I find a Smart Tip that I’ve already watched or closed?">
        You can use the search box at the top of the page, or you can
        visit the Smart Tips page and browse through your assigned or
        completed Smart Tips.
      </FaqItem>

      <FaqItem label="What’s the “Tip of the Week?”">
        The Tip of the Week is a featured Smart Tip that an
        Administrator or Department head thinks you will find helpful.
      </FaqItem>

      <FaqItem label="Why are Smart Tips different colors?">
        Blue Smart Tips are recommended to you based on individual goals
        and performance. White Smart Tips are recommended to you by
        department heads, administrators or your training team. Your
        Smart Tip of the Week recommendations are in Purple.
      </FaqItem>

      <FaqItem label="Can I set Smart Tip reminders?">
        Yes. You can set a reminder by clicking on the Smart Tip clock
        icon. You can always view, edit or cancel your upcoming
        reminders by visiting your user profile page, which is accessed
        by clicking on your name or avatar in the upper-right corner.
      </FaqItem>

      <FaqItem label="Can I control the notifications I receive?">
        Of course! Click on the notification&nbsp;
        <BellIcon fill={theme.color.primary} width={15} height={15} />
        &nbsp;icon to visit your notifications page and then click on
        the&nbsp;
        <SettingsIcon
                    fill={theme.color.primary}
                    width={15}
                    height={15}
                />
        &nbsp;gear icon in the upper-right to adjust your in-app
        notification settings. Of course, you also have the option to
        adjust push notification settings on your smartphone.
      </FaqItem>

      <SectionSeparator>Performance</SectionSeparator>
      <FaqItem label="What performance measures are being tracked? ">
        Your IT administrator can use MedPower to report on metrics like
        Electronic Clinical Quality Measures (eCQMs), CMS Star Ratings,
        EHR efficiency measures and more. Visit your personalized
        performance page to see what metrics are being used by your
        organization. If you don’t see any performance measures or you
        think something is missing, please contact your IT
        administrator.
      </FaqItem>

      <FaqItem label="What is the performance graph measuring?">
        The graph on your dashboard displays your overall performance,
        over time and against your peers (when data is available) for
        each measure or data source tracked. You can find a more
        detailed breakdown of each performance measure, including all
        the individual metrics, on your personalized performance page.
      </FaqItem>

      <FaqItem label="How do I know if I’m hitting my performance goals?">
        If your administrator has assigned you to a key performance
        measure, you will see all the individual metrics on your
        personalized performance page. Each metric includes your latest
        score along with your performance goal, indicated by a whilte
        line. A red progress bar and Smart Tip link indicates an
        opportunity for improvement. Simply click through to view a
        Smart Tip you can use to help boost your scores.
      </FaqItem>

      <SectionSeparator>Teams</SectionSeparator>
      <FaqItem label="How do I join a Team?">
        Simply visit your Teams page and click “Join” for any team you
        want to join, or click on “Create team” to start a team of your
        own and invite your colleagues to join!
      </FaqItem>

      <FaqItem label="Why should I join a Team?">
        Teams are a great way to share Smart Tips and advice with your
        colleagues. You can visit your team’s page to view suggested
        Smart Tips, post/read Team messages, and track your team’s
        overall performance.
      </FaqItem>

      <SectionSeparator>Stars and levels</SectionSeparator>
      <FaqItem label="How do I earn Stars?">
        You can earn stars by:
        <ul>
          <li>Watching a Smart Tip = 5 stars</li>
          <li>Adding a reminder = 2 stars</li>
          <li>Rating a Smart Tip = 5 stars</li>
          <li>Creating a team = 5 stars</li>
          <li>Adding message to team board = 1 star</li>
          <li>
            Bonus stars are awarded the first time you use each of
            the features above, so be sure to check them all out!
            {' '}
          </li>
        </ul>
      </FaqItem>

      <FaqItem label="How many Levels are there to achieve?">
        Current levels you can achieve include:
        <ul>
          <li>Level 1: &nbsp;0-75</li>
          <li>Level 2: &nbsp;76 - 200 </li>
          <li>Level 3: &nbsp;201 - 450 </li>
          <li>Level 4: &nbsp;451 - 1000 </li>
          <li>Level 5: &nbsp;1001+ </li>
        </ul>
      </FaqItem>

      <SectionSeparator>Other</SectionSeparator>
      <FaqItem label="What Courses appear in the app?">
        If connected to your MedPower Learning Management System, full
        online courses that you’re enrolled in will display at the
        bottom of your dashboard and on your Courses page. Simply click
        on the course to connect to your LMS and complete your
        assignments.
      </FaqItem>
      <FaqItem label="What Internet browsers are supported?">
        For the best user experience and to keep your session secure, we
        highly recommend using the latest version of
        {' '}
        <a href="https://www.google.com/chrome/">Google Chrome</a>
        {' '}
        or
        {' '}
        <a href="https://www.mozilla.org/en-US/firefox/">Firefox</a>
        . If you prefer, you can also use the latest version of Safari
        or Microsoft Edge. We do not currently provide support for
        Internet Explorer.
        <br />
        Please make sure your browser version is at or above the
        following:
        <ul>
          <li>Chrome 80 and above</li>
          <li>Firefox 79 and above</li>
          <li>Safari 14 and above</li>
          <li>Edge 80 and above</li>
        </ul>
        To make sure your browser is up-to-date, you can
        {' '}
        <a href="http://whatsmybrowser.com/">click here</a>
        . If you use
        an older version of your browser, it&apos;s likely that you will
        experience some technical troubles. If you have any questions,
        please reach out to our team by clicking on the “Support” button
        at the top of the page.
      </FaqItem>
    </FAQItems>
  </FAQContainer>
);

FaqItem.propTypes = {
    label: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
};

export default FAQ;
