import React from 'react';
import PropTypes from 'prop-types';
import { ProgressBarHolder, ProgressBarColor } from './ProgressBar.style';

const ProgressBar = ({ completed }) => (
  <ProgressBarHolder>
    <ProgressBarColor completed={completed} />
  </ProgressBarHolder>
);

ProgressBar.propTypes = {
    completed: PropTypes.bool,
};

ProgressBar.defaultProps = {
    completed: false,
};

export default ProgressBar;
