import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import useFetch from 'hooks/useFetch';

import MPAEditSuperAdminUserView from 'views/MPAEditSuperAdminUser';
import {
    dispatchErrorAlertThunk,
    dispatchSuccessAlertThunk,
} from 'redux/actions/thunks/systemAlerts';
import apiRoutes from 'config/apiRoutes';
import Spinner from 'components/Spinner';
import { useAuth0 } from '@auth0/auth0-react';

const formSchema = Yup.object().shape({
    firstName: Yup.string()
        .min(2, 'Too short')
        .max(50, 'Too long value')
        .required('Required'),
    lastName: Yup.string()
        .min(2, 'Too short')
        .max(50, 'Too long value')
        .required('Required'),
    emailAddress: Yup.string()
        .email('Invalid email format')
        .required('Required'),
});

const MPAEditSuperAdminUser = () => {
    const { getAccessTokenSilently, logout } = useAuth0();
    const dispatch = useDispatch();
    const request = useFetch(getAccessTokenSilently, logout);
    const { userId } = useParams();
    const [superAdminUser, setSuperAdminUser] = useState(undefined);

    const handleSubmitSuccess = (res) => {
        if (!res.error) {
            dispatch(
                dispatchSuccessAlertThunk(
                    'editSuperAdminUser',
                    'Super Admin User edited'
                )
            );
        }
    };

    const handleSubmitError = (err) => {
        dispatch(dispatchErrorAlertThunk(err, err.error));
    };

    const handleSubmit = (values) => {
        const fd = new FormData();
        if (values.firstName) fd.append('user[first_name]', values.firstName);
        if (values.lastName) fd.append('user[last_name]', values.lastName);
        if (values.emailAddress) fd.append('user[email]', values.emailAddress);

        request(
            apiRoutes.put.editSuperAdminUser(userId),
            'PUT',
            handleSubmitSuccess,
            handleSubmitError,
            {
                body: fd,
            }
        );
    };

    const handleSuperAdminUserDetailsSuccess = (res) => {
        setSuperAdminUser({
            firstName: res.first_name,
            lastName: res.last_name,
            emailAddress: res.email,
        });
    };

    const handleSuperAdminUserDetailsError = (err) => err;

    const fetchSuperAdminUserDetails = () => {
        request(
            apiRoutes.get.superAdminUser(userId),
            'GET',
            handleSuperAdminUserDetailsSuccess,
            handleSuperAdminUserDetailsError,
            {},
            { 'Content-Type': 'application/json' }
        );
    };

    useEffect(() => {
        fetchSuperAdminUserDetails();
    }, []);

    if (superAdminUser === undefined) {
        return <Spinner />;
    }

    return (
      <Formik
            initialValues={superAdminUser}
            validationSchema={formSchema}
            onSubmit={handleSubmit}
            enableReinitialize
        >
        {(formikProps) => <MPAEditSuperAdminUserView {...formikProps} />}
      </Formik>
    );
};

export default MPAEditSuperAdminUser;
