import {
    establishSmartTips,
    establishSmartTipsSuccess,
    establishSmartTipsEssentialSuccess,
    establishSmartTipsToTWSuccess,
    establishSmartTipsArchivedSuccess,
    establishSmartTipsArchivedEssentialSuccess,
    establishSmartTipsFailure,
    fetchRecentSmartTips,
    fetchRecentSmartTipsSuccess,
    fetchRecentSmartTipsFailure,
    resetSmartTips,
} from 'redux/actions/smartTips';
import parseSmartTips from 'redux/helpers/smartTips';
import apiRoutes from 'config/apiRoutes';

const establishEssentialSmartTipsThunk = (getAccessTokenSilently, logout) => (
    dispatch
) =>
    getAccessTokenSilently()
        .then((token) => {
            fetch(apiRoutes.get.smartTipsEssential, {
                method: 'GET',
                headers: {
                    organization_slug: window.localStorage.getItem(
                        'MP_ORGANIZATION'
                    ),
                    Authorization: `Token token="${token}"`,
                },
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.error) {
                        return dispatch(establishSmartTipsFailure(res.error));
                    }

                    return dispatch(
                        establishSmartTipsEssentialSuccess(
                            parseSmartTips(res.smart_tips, 'recommendation')
                        )
                    );
                })
                .catch(() => {});
        })
        .catch(() => logout({ returnTo: window.location.origin }));

const establishToTWThunk = (getAccessTokenSilently, logout) => (dispatch) =>
    getAccessTokenSilently()
        .then((token) => {
            fetch(apiRoutes.get.smartTipsToTW, {
                method: 'GET',
                headers: {
                    organization_slug: window.localStorage.getItem(
                        'MP_ORGANIZATION'
                    ),
                    Authorization: `Token token="${token}"`,
                },
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.error) {
                        return dispatch(establishSmartTipsFailure(res.error));
                    }

                    return dispatch(
                        establishSmartTipsToTWSuccess(
                            parseSmartTips(res.smart_tips, 'tip_of_the_week')
                        )
                    );
                })
                .catch(() => {});
        })
        .catch(() => logout({ returnTo: window.location.origin }));

const establishArchivedSmartTipsThunk = (getAccessTokenSilently, logout) => (
    dispatch
) =>
    getAccessTokenSilently()
        .then((token) => {
            fetch(apiRoutes.get.smartTipsArchived, {
                method: 'GET',
                headers: {
                    organization_slug: window.localStorage.getItem(
                        'MP_ORGANIZATION'
                    ),
                    Authorization: `Token token="${token}"`,
                },
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.error) {
                        return dispatch(establishSmartTipsFailure(res.error));
                    }

                    return dispatch(
                        establishSmartTipsArchivedSuccess(
                            parseSmartTips(res.smart_tips)
                        )
                    );
                })
                .catch(() => {});
        })
        .catch(() => logout({ returnTo: window.location.origin }));

const establishArchivedEssentialSmartTipsThunk = (
    getAccessTokenSilently,
    logout
) => (dispatch) =>
    getAccessTokenSilently()
        .then((token) => {
            fetch(apiRoutes.get.smartTipsArchivedEssential, {
                method: 'GET',
                headers: {
                    organization_slug: window.localStorage.getItem(
                        'MP_ORGANIZATION'
                    ),
                    Authorization: `Token token="${token}"`,
                },
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.error) {
                        return dispatch(establishSmartTipsFailure(res.error));
                    }

                    return dispatch(
                        establishSmartTipsArchivedEssentialSuccess(
                            parseSmartTips(res.smart_tips, 'recommendation')
                        )
                    );
                })
                .catch(() => {});
        })
        .catch(() => logout({ returnTo: window.location.origin }));

const establishSmartTipsThunk = (getAccessTokenSilently, logout) => (
    dispatch
) => {
    dispatch(establishSmartTips());

    dispatch(establishEssentialSmartTipsThunk(getAccessTokenSilently, logout));
    dispatch(establishToTWThunk(getAccessTokenSilently, logout));
    dispatch(establishArchivedSmartTipsThunk(getAccessTokenSilently, logout));
    dispatch(
        establishArchivedEssentialSmartTipsThunk(getAccessTokenSilently, logout)
    );

    return getAccessTokenSilently()
        .then((token) => {
            fetch(apiRoutes.get.smartTips(), {
                method: 'GET',
                headers: {
                    organization_slug: window.localStorage.getItem(
                        'MP_ORGANIZATION'
                    ),
                    Authorization: `Token token="${token}"`,
                },
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.error) {
                        return dispatch(establishSmartTipsFailure(res.error));
                    }

                    return dispatch(
                        establishSmartTipsSuccess(
                            parseSmartTips(res.smart_tips)
                        )
                    );
                })
                .catch((err) =>
                    dispatch(
                        establishSmartTipsFailure({
                            err,
                        })
                    )
                );
        })
        .catch(() => logout({ returnTo: window.location.origin }));
};

export const fetchRecentlyVisitedSmartTipsThunk = (
    getAccessTokenSilently,
    logout
) => (dispatch) => {
    dispatch(fetchRecentSmartTips());

    return getAccessTokenSilently()
        .then((token) => {
            fetch(apiRoutes.get.smartTipsRecentlyVisited, {
                method: 'GET',
                headers: {
                    organization_slug: window.localStorage.getItem(
                        'MP_ORGANIZATION'
                    ),
                    Authorization: `Token token="${token}"`,
                },
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.error) {
                        return dispatch(fetchRecentSmartTipsFailure(res.error));
                    }
                    return dispatch(
                        fetchRecentSmartTipsSuccess(res.smart_tips)
                    );
                })
                .catch((err) =>
                    dispatch(
                        fetchRecentSmartTipsFailure({
                            err,
                        })
                    )
                );
        })
        .catch(() => logout({ returnTo: window.location.origin }));
};

export const resetSmartTipsThunk = () => (dispatch) => {
    dispatch(resetSmartTips());
};

export default establishSmartTipsThunk;
