import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import { Link, useHistory } from 'react-router-dom';
import {
    StyledSectionHeader,
    StyledCounter,
    StyledSubtitle,
    GoBackHolder,
    CtaHolder,
    SectionTitle,
} from './SectionHeader.style';

const SectionHeader = ({
    goBack,
    title,
    subtitle,
    withMargin,
    withPadding,
    counter,
    cta,
    collapseOnMobile,
    customStyles,
    children,
    id,
}) => {
    const history = useHistory();

    return (
      <StyledSectionHeader
            withMargin={withMargin}
            withPadding={withPadding}
            withCta={cta}
            data-testid="sectionHeader"
            collapseOnMobile={collapseOnMobile}
            customStyles={customStyles}
            id={id}
        >
        {goBack && (
        <GoBackHolder>
          {goBack.url ? (
            <Link to={goBack.url}>{goBack.label()}</Link>
                    ) : (
                      <Button
                            transparent
                            borderless
                            onClick={() => history.goBack()}
                        >
                        {goBack.label()}
                      </Button>
                    )}
        </GoBackHolder>
            )}
        <SectionTitle>{title}</SectionTitle>
        <StyledSubtitle>{subtitle}</StyledSubtitle>
        {children}
        {counter !== undefined && <StyledCounter>{counter}</StyledCounter>}
        {cta && <CtaHolder>{cta()}</CtaHolder>}
      </StyledSectionHeader>
    );
};

SectionHeader.propTypes = {
    title: PropTypes.string.isRequired,
    id: PropTypes.string,
    subtitle: PropTypes.string,
    counter: PropTypes.number || undefined,
    customStyles: PropTypes.any || undefined,
    withMargin: PropTypes.bool,
    withPadding: PropTypes.bool,
    collapseOnMobile: PropTypes.bool,
    cta: PropTypes.func || undefined,
    goBack:
        PropTypes.shape({
            url: PropTypes.string,
            label: PropTypes.func,
        }) || undefined,
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

SectionHeader.defaultProps = {
    subtitle: '',
    counter: undefined,
    collapseOnMobile: false,
    withMargin: false,
    withPadding: false,
    cta: undefined,
    goBack: undefined,
    customStyles: undefined,
    children: undefined,
    id: '',
};

export default SectionHeader;
