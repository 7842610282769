export const ESTABLISH_TEAMS_RANKING = 'ESTABLISH_TEAMS_RANKING';
export const ESTABLISH_TEAMS_RANKING_SUCCESS =
    'ESTABLISH_TEAMS_RANKING_SUCCESS';
export const ESTABLISH_TEAMS_RANKING_FAILURE =
    'ESTABLISH_TEAMS_RANKING_FAILURE';

export const establishTeamsRanking = () => ({
    type: ESTABLISH_TEAMS_RANKING,
});

export const establishTeamsRankingSuccess = (payload) => ({
    type: ESTABLISH_TEAMS_RANKING_SUCCESS,
    payload,
});

export const establishTeamsRankingFailure = (err) => ({
    type: ESTABLISH_TEAMS_RANKING_FAILURE,
    err,
});
