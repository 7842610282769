import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Form } from 'formik';

import Input from 'components/Input';
import { Button } from './EditUserProfile.style';

const EditUserProfileForm = React.forwardRef(
    ({ setAvatarPreview, userOrganizationName, ...rest }, avatarRef) => {
        const {
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
        } = rest;
        const history = useHistory();

        return (
          <Form onSubmit={handleSubmit}>
            <Input
                    type="file"
                    ref={avatarRef}
                    name="avatar"
                    accept="image/png, image/jpeg"
                    style={{ display: 'none' }}
                    onBlur={handleBlur}
                    onChange={(e) => {
                        if (!e.target.files || !e.target.files[0]) return;
                        setAvatarPreview(
                            URL.createObjectURL(e.target.files[0])
                        );
                        setFieldValue('avatar', e.target.files[0]);
                    }}
                    error={errors.avatar}
                />
            <Input variant="group">
              <Input
                        name="firstName"
                        placeholder="First name"
                        label="First name"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.firstName}
                        error={
                            errors.firstName && touched.firstName
                                ? errors.firstName
                                : null
                        }
                    />
            </Input>
            <Input variant="group">
              <Input
                        name="lastName"
                        placeholder="Last name"
                        label="Last name"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.lastName}
                        error={
                            errors.lastName && touched.lastName
                                ? errors.lastName
                                : null
                        }
                    />
            </Input>
            <Input variant="group">
              <Input
                        name="email"
                        placeholder="E-mail address"
                        label="E-mail address"
                        type="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        error={
                            errors.email && touched.email ? errors.email : null
                        }
                    />
            </Input>
            <Input variant="group">
              <Button
                        primary
                        inline
                        inverted
                        type="submit"
                        disabled={isSubmitting}
                    >
                Save
              </Button>

              <Button
                        primary
                        inline
                        type="button"
                        onClick={() => history.goBack()}
                    >
                Cancel
              </Button>
            </Input>
          </Form>
        );
    }
);

EditUserProfileForm.propTypes = {
    setAvatarPreview: PropTypes.func.isRequired,
    userOrganizationName: PropTypes.string.isRequired,
};

export default EditUserProfileForm;
