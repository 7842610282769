import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRecentlyVisitedUsersThunk } from 'redux/actions/thunks/user';
import { fetchRecentlyVisitedSmartTipsThunk } from 'redux/actions/thunks/smartTips';
import { HA_ROUTES, ROUTES } from 'routing/constants';
import { useAuth0 } from '@auth0/auth0-react';

import theme from 'assets/theme/mainTheme';
import { ReactComponent as LightBulbIcon } from 'assets/svg/light-bulb-shine.svg';

import { SectionTitle } from 'components/SectionHeader/SectionHeader.style';
import UsersEmptyState from 'components/EmptyState/UsersEmptyState';
import RecentlyVisitedSTEmptyState from 'components/EmptyState/RecentlyVisitedSTEmptyState';

import {
    RecentItemsWrapper,
    RecentlyVisitedWrapper,
    RecentSmartTips,
    RecentlyVisitedItem,
    RecentlyVisitedImg,
    RecentlyVisitedTitle,
    IconHolder,
    UserGenericAvatar,
} from './HARecentItems.style';

const HARecentItems = () => {
    const { getAccessTokenSilently, logout } = useAuth0();
    const dispatch = useDispatch();
    const history = useHistory();
    const { recentSmartTips } = useSelector((state) => state.smartTips);
    const { recentUsers } = useSelector((state) => state.users);

    useEffect(() => {
        dispatch(
            fetchRecentlyVisitedSmartTipsThunk(getAccessTokenSilently, logout)
        );
        dispatch(
            fetchRecentlyVisitedUsersThunk(getAccessTokenSilently, logout)
        );
    }, []);

    return (
      <RecentItemsWrapper>
        <RecentlyVisitedWrapper>
          <SectionTitle withMargin>Recently Visited Users</SectionTitle>
          {recentUsers.length === 0 ? (
            <UsersEmptyState />
                ) : (
                    recentUsers.map((recentUser) => (
                      <RecentlyVisitedItem
                            key={recentUser.id}
                            onClick={() =>
                                history.push(
                                    `${HA_ROUTES.users}/${recentUser.id}`
                                )}
                        >
                        {recentUser.avatar_url ? (
                          <RecentlyVisitedImg
                                    src={recentUser.avatar_url}
                                    alt="User avatar"
                                />
                            ) : (
                              <UserGenericAvatar>
                                {recentUser.first_name &&
                                        recentUser.first_name[0]}
                                {recentUser.last_name &&
                                        recentUser.last_name[0]}
                              </UserGenericAvatar>
                            )}
                        <RecentlyVisitedTitle>
                          <strong>
                            {recentUser.first_name}
                            {' '}
                            {recentUser.last_name}
                          </strong>
                          <span>{recentUser.email}</span>
                        </RecentlyVisitedTitle>
                      </RecentlyVisitedItem>
                    ))
                )}
        </RecentlyVisitedWrapper>
        <RecentSmartTips>
          <SectionTitle withMargin>
            Recently Accessed Smart Tips
          </SectionTitle>
          {recentSmartTips.length === 0 ? (
            <RecentlyVisitedSTEmptyState />
                ) : (
                    recentSmartTips.map((recentSmartTip) => (
                      <RecentlyVisitedItem
                            key={recentSmartTip.id}
                            onClick={() =>
                                history.push(
                                    `${ROUTES.smartTips}/${recentSmartTip.id}`
                                )}
                        >
                        <IconHolder color={theme.color.bgPrimaryLight}>
                          <LightBulbIcon fill={theme.color.primary} />
                        </IconHolder>
                        <strong>{recentSmartTip.name}</strong>
                      </RecentlyVisitedItem>
                    ))
                )}
        </RecentSmartTips>
      </RecentItemsWrapper>
    );
};

export default HARecentItems;
