import styled, { css } from 'styled-components/macro';

export const ChartWrapper = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
`;

export const SourceSelector = styled.div`
    width: 100%;
    padding: 24px;
    border-bottom: 1px solid;
    border-color: ${({ theme }) => theme.color.borderColorBase};
    display: flex;
    flex-direction: row;
    align-items: center;

    p {
        color: ${({ theme }) => theme.color.textLight};
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        margin-right: 16px;
    }

    @media (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
        flex-direction: column;
    }
`;

export const Dropdown = styled.div`
    position: relative;
    cursor: pointer;
    width: 215px;
    height: 56px;
    padding: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: 1px solid;
    border-color: ${({ theme }) => theme.color.borderColorBase};
    border-radius: ${({ theme }) => theme.border.radiusSmall};
    ${({ disabled }) =>
        disabled &&
        css`
            opacity: 0.5;
            cursor: default;
        `}
`;

export const DropdownOptions = styled.div`
    position: absolute;
    z-index: 1000;
    top: 54px;
    left: 0;
    width: 215px;
    padding: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border: 1px solid;
    border-color: ${({ theme }) => theme.color.borderColorBase};
    border-radius: ${({ theme }) => theme.border.radiusSmall};
    background-color: ${({ theme }) => theme.color.white};
`;

export const DropdownOption = styled.div`
    width: 100%;
    padding: 8px;
    display: flex;
    margin: 5px 0;
    border-radius: ${({ theme }) => theme.border.radiusSmall};

    &:hover {
        background-color: ${({ theme }) => theme.color.bgPrimaryLight};
    }
`;

export const ChartOverlayWrapper = styled.div`
    ${({ isSingleResult }) =>
        isSingleResult &&
        css`
            padding: 54px 0px;
            margin-top: 15px;
            background-color: ${({ theme }) => theme.color.bgPrimarySuperLight};
            border-radius: ${({ theme }) => theme.border.radiusBase};
        `}
`;

export const ChartOverlay = styled.div`
    height: 57px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;

    ${({ row }) =>
        row &&
        css`
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
        `}

    ${({ isSingleResult }) =>
        isSingleResult &&
        css`
            justify-content: center;
        `}

    span {
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        margin-bottom: 10px;
        color: ${({ theme }) => theme.color.textLight};
    }

    strong {
        width: 90px;
        font-size: 32px;
        font-weight: 600;
        line-height: 39px;
    }
`;

export const Label = styled.div`
    padding: 10px;
    margin-right: 10px;
    border-radius: ${({ theme }) => theme.border.radiusBase};
    background-color: ${({ theme }) => theme.color.primary};

    color: ${({ theme }) => theme.color.white};
    font-size: 10px;
    font-weight: 600;
    line-height: 12px;

    ${({ gold }) =>
        gold &&
        css`
            background-color: ${({ theme }) => theme.color.gold};
        `}
`;

export const LegendWrapper = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 103px;
    padding: 24px;
`;

export const Info = styled.div`
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    color: ${({ theme }) => theme.color.textLight};
`;

export const Hint = styled.p`
    font-size: 11px;
    margin: 2px;
    color: ${({ theme }) => theme.color.textLight};
`;

export const Hospital = styled.div``;

export const DateWrapper = styled.div`
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    color: ${({ theme }) => theme.color.textLight};
    margin-left: 8px;

    @media (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
        margin-top: 16px;
    }
`;

export const SingleResultDateWrapper = styled.div`
    font-size: 10px;
    font-weight: 600;
    line-height: 12px;
    text-align: center;
    color: ${({ theme }) => theme.color.textLight};
    padding-top: 30px;
`;
