import thunk from 'redux-thunk';
// import logger from 'redux-logger';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';

import rootReducer from 'redux/reducers/rootReducer';

// const isDev = process.env.NODE_ENV === `development`;

const middlewares = [];
middlewares.push(thunk);

// if (isDev) middlewares.push(logger);

/* eslint-disable no-underscore-dangle */
const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(...middlewares))
);
/* eslint-enable */

export default store;
