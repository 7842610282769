import styled, { css } from 'styled-components/macro';

const uploadAreaCommon = css`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

export const UploadImageArea = styled.div`
    ${uploadAreaCommon};
    width: 100%;
    height: 210px;
    background-color: ${({ theme }) => theme.color.bgPrimarySuperLight};
    border-radius: ${({ theme }) => theme.border.radiusBase};
    position: relative;
    margin: 16px 0;
`;

export const DropzoneArea = styled.div`
    ${uploadAreaCommon};
    flex-direction: column;
    text-align: center;

    span {
        color: ${({ theme }) => theme.color.textLight};
        font-size: ${({ theme }) => theme.fontSize.small};
    }

    button {
        margin-top: 16px;
    }
`;

export const ProgressBarHolder = styled.div`
    width: 100%;
    height: 210px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    span {
        color: ${({ theme }) => theme.color.textLight};
        font-size: ${({ theme }) => theme.fontSize.small};
        display: block;
        margin-bottom: 16px;
    }
`;

export const FileToImportWrapper = styled.div`
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 200px;

    strong {
        margin-bottom: 8px;
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        color: ${({ theme }) => theme.color.textLight};
    }

    span {
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        color: ${({ theme }) => theme.color.textBase};
    }
`;

export const Row = styled.div`
    max-width: 380px;
    padding: 26px 28px;
    background-color: ${({ theme }) => theme.color.bgSearch};
    border-radius: ${({ theme }) => theme.border.radiusSmall};
    display: grid;
    grid-template-columns: 1fr 5fr 2fr;
    grid-gap: 8px;
    align-items: center;
`;

export const RowButton = styled.div`
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    color: ${({ theme }) => theme.color.primary};
    text-align: right;

    &:hover {
        cursor: pointer;
    }
`;
