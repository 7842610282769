import styled, { css } from 'styled-components/macro';

export const InputSection = styled.div`
    width: 100%;
    height: 85px;
    padding: 16px 32px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;

    ${({ theme }) => css`
        background-color: ${theme.color.white};
        border-bottom: ${theme.border.base};
    `};
`;

export const ModalWrapper = styled.div`
    height: 600px;
    overflow-y: scroll;

    @media (max-width: ${({ theme }) => `${theme.breakpoints.medium}px`}) {
        height: 500px;
    }

    @media (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
        height: 460px;
    }
`;

export const WarningSection = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: ${({ theme }) => theme.color.bgBase};
    border-bottom: ${({ theme }) => theme.border.base};
    padding: 24px;

    span {
        color: ${({ theme }) => theme.color.textLight};
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
    }

    strong {
        color: ${({ theme }) => theme.color.textLight};
        font-size: 14px;
        font-weight: 600;
        line-height: 22px;
    }

    svg {
        width: 35px;
        height: 35px;
        margin-right: 16px;
    }
`;

export const ProvidersSection = styled(InputSection)`
    height: 287px;
    align-items: flex-start;
    justify-content: space-between;
    flex-flow: row nowrap;
    border: none;
`;

export const SwitchStatusSection = styled(InputSection)`
    padding-right: 41%;
`;

export const InputHolder = styled.div`
    position: relative;
    width: 350px;
    margin-right: 64px;

    @media (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
        margin-right: 0;
        max-width: 200px;
    }
`;

export const Footer = styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-end;

    button {
        margin-left: 16px;
    }
`;

export const UnlinkSmartTipButton = styled.div`
    position: absolute;
    cursor: pointer;
    top: 24px;
    right: 16px;
`;
