import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Input from 'components/Input';
import Switch from 'components/Switch';
import { ReactComponent as CheckIcon } from 'assets/svg/check-circle.svg';
import { ReactComponent as BinIcon } from 'assets/svg/bin.svg';
import theme from 'assets/theme/mainTheme';
import { useAuth0 } from '@auth0/auth0-react';
import apiRoutes from 'config/apiRoutes';
import useFetch from 'hooks/useFetch';
import { useDispatch } from 'react-redux';
import {
    dispatchErrorAlertThunk,
    dispatchSuccessAlertThunk,
} from 'redux/actions/thunks/systemAlerts';
import {
    MetricContainerMPA,
    EnabledSection,
    InputWrapper,
    IconWrapper,
} from './SingleMetric.style';

const initialEditState = {
    name: false,
    description: false,
    goal: false,
};

const TYPES = {
    name: 'name',
    description: 'description',
    goal: 'goal',
    enabled: 'enabled',
};

const SingleMetricMPA = ({
    metricSourceId,
    metricId,
    metricName,
    metricDescription,
    metricGoal,
    metricEnabled,
    reloadMetrics,
    withoutBorder,
    handleDelete,
}) => {
    const { getAccessTokenSilently, logout } = useAuth0();
    const dispatch = useDispatch();
    const request = useFetch(getAccessTokenSilently, logout);
    const [name, setName] = useState(metricName);
    const [description, setDescription] = useState(metricDescription);
    const [goal, setGoal] = useState(metricGoal);
    const [enabled, setEnabled] = useState(metricEnabled);

    const [wasEdited, setWasEdited] = useState(initialEditState);

    const onUpdate = (value, type) => {
        const body = {
            name: undefined,
            description: undefined,
            goal: undefined,
            enabled: undefined,
        };

        switch (type) {
            case TYPES.name:
                body.name = value;
                break;
            case TYPES.description:
                body.description = value;
                break;
            case TYPES.goal:
                body.goal = value;
                break;
            case TYPES.enabled:
                body.enabled = value;
                break;
            default:
                break;
        }

        request(
            apiRoutes.put.mpaUpdateMetric(metricSourceId, metricId),
            'PUT',
            (res) => handleSuccess(res, type),
            (err) => handleFailure(err, type),
            {
                body: JSON.stringify({
                    metric: {
                        ...body,
                    },
                }),
            },
            { 'Content-Type': 'application/json' }
        );
    };

    const handleSuccess = (res, type) => {
        if (!res.error) {
            switch (type) {
                case TYPES.name:
                    setWasEdited({ ...wasEdited, name: true });
                    dispatch(
                        dispatchSuccessAlertThunk(
                            'nameEdited',
                            'Name of metric changed'
                        )
                    );
                    break;
                case TYPES.description:
                    setWasEdited({ ...wasEdited, description: true });
                    dispatch(
                        dispatchSuccessAlertThunk(
                            'descriptionEdited',
                            'Description of metric changed'
                        )
                    );
                    break;
                case TYPES.goal:
                    setWasEdited({ ...wasEdited, goal: true });
                    dispatch(
                        dispatchSuccessAlertThunk(
                            'goalEdited',
                            'Goal of metric changed'
                        )
                    );
                    break;
                case TYPES.enabled:
                    setWasEdited({ ...wasEdited, enabled: true });
                    setEnabled(!enabled);
                    dispatch(
                        dispatchSuccessAlertThunk(
                            'enabledEdited',
                            'Status of metric changed'
                        )
                    );
                    break;
                default:
            }
        }
    };

    const handleFailure = (err, type) => {
        switch (type) {
            case TYPES.name:
                setName(metricName);
                break;
            case TYPES.description:
                setDescription(metricDescription);
                break;
            case TYPES.goal:
                setGoal(metricGoal);
                break;
            case TYPES.enabled:
                setEnabled(metricEnabled);
                break;
            default:
                break;
        }
        dispatch(dispatchErrorAlertThunk(err, err.error));
        reloadMetrics();
    };

    return (
      <MetricContainerMPA withoutBorder={withoutBorder}>
        <InputWrapper>
          <Input
                    variant="base"
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    onFocus={() => {
                        if (wasEdited.name) {
                            setWasEdited({
                                ...wasEdited,
                                name: false,
                            });
                        }
                    }}
                    onBlur={() => {
                        if (name && name !== metricName && name !== '') {
                            onUpdate(name, TYPES.name);
                        }
                    }}
                />
          <CheckIcon
                    fill={
                        wasEdited.name ? theme.color.checkGreen : 'transparent'
                    }
                    style={{
                        marginBottom: -5,
                        marginLeft: 10,
                    }}
                />
        </InputWrapper>

        <InputWrapper>
          <Input
                    variant="base"
                    type="text"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    onFocus={() => {
                        if (wasEdited.description) {
                            setWasEdited({
                                ...wasEdited,
                                description: false,
                            });
                        }
                    }}
                    onBlur={() => {
                        if (description) {
                            onUpdate(description, TYPES.description);
                        }
                    }}
                />
          <CheckIcon
                    fill={
                        wasEdited.description
                            ? theme.color.checkGreen
                            : 'transparent'
                    }
                    style={{
                        marginBottom: -5,
                        marginLeft: 10,
                    }}
                />
        </InputWrapper>

        <InputWrapper>
          <Input
                    variant="base"
                    type="number"
                    value={goal}
                    onChange={({ target }) => {
                        if (target.value >= 0 && target.value <= 100) {
                            setGoal(target.value);
                        }
                    }}
                    onFocus={() => {
                        if (wasEdited.goal) {
                            setWasEdited({
                                ...wasEdited,
                                goal: false,
                            });
                        }
                    }}
                    onBlur={() => {
                        if (goal && goal !== metricGoal && goal !== '') {
                            onUpdate(goal, TYPES.goal);
                        }
                    }}
                />
          <CheckIcon
                    fill={
                        wasEdited.goal ? theme.color.checkGreen : 'transparent'
                    }
                    style={{
                        marginBottom: -5,
                        marginLeft: 10,
                    }}
                />
        </InputWrapper>
        <EnabledSection>
          <Switch
                    switchId={`${metricId}-switch`}
                    switchOn={() => {
                        onUpdate(true, TYPES.enabled);
                    }}
                    switchOff={() => {
                        onUpdate(false, TYPES.enabled);
                    }}
                    defaultStatus={enabled}
                    withConfirmationModal
                    externalStatus={enabled}
                    onConfirmModalDescription="Warning: Changing Metric Status triggers a score recalculation for all affected Organizations. It can take several minutes."
                    offConfirmModalDescription="Warning: Changing Metric Status triggers a score recalculation for all affected Organizations. It can take several minutes."
                />
        </EnabledSection>
        <IconWrapper className="bin" onClick={() => handleDelete(metricId)}>
          <BinIcon />
        </IconWrapper>
      </MetricContainerMPA>
    );
};

SingleMetricMPA.propTypes = {
    metricSourceId: PropTypes.string.isRequired,
    metricId: PropTypes.string.isRequired,
    metricName: PropTypes.string,
    metricDescription: PropTypes.string,
    metricGoal: PropTypes.number,
    metricEnabled: PropTypes.bool,
    reloadMetrics: PropTypes.func.isRequired,
    withoutBorder: PropTypes.bool,
    handleDelete: PropTypes.func.isRequired,
};

SingleMetricMPA.defaultProps = {
    metricName: '',
    metricDescription: '',
    metricGoal: 0,
    metricEnabled: false,
    withoutBorder: false,
};

export default SingleMetricMPA;
