import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Badge from 'components/Badge';
import ConfirmActionModal from 'components/ConfirmActionModal';
import { ReactComponent as CloseIcon } from 'assets/svg/close.svg';
import theme from 'assets/theme/mainTheme';
import useAdmin from 'hooks/useAdmin';
import { HA_ROUTES } from 'routing/constants';
import {
    StyledTeamMember,
    StyledAvatar,
    StyledAvatarPlaceholder,
    StyledLabel,
    LastColumn,
} from './TeamMember.style';

const TeamMember = ({
    userId,
    firstName,
    lastName,
    avatarUrl,
    backgroundColor,
    creator,
    email,
    onRemove,
    isRemovable,
}) => {
    const [
        isConfirmSubmitModalVisible,
        setIsConfirmSubmitModalVisible,
    ] = useState(false);
    const isAdmin = useAdmin();

    return (
      <StyledTeamMember
            data-testid={`${firstName}-${lastName}-test-member`}
            threeColumns
        >
        <ConfirmActionModal
                isVisible={isConfirmSubmitModalVisible}
                onToggle={() => {
                    setIsConfirmSubmitModalVisible(
                        !isConfirmSubmitModalVisible
                    );
                }}
                onConfirm={onRemove}
                description="Are you sure you want to remove this user from the team?"
            />
        {isAdmin ? (
          <>
            {' '}
            <Link to={`${HA_ROUTES.users}/${userId}`}>
              {avatarUrl ? (
                <StyledAvatar
                                src={avatarUrl}
                                alt={`${firstName} ${lastName}`}
                            />
                        ) : (
                          <StyledAvatarPlaceholder color={backgroundColor}>
                            {firstName && firstName[0]}
                            {lastName && lastName[0]}
                          </StyledAvatarPlaceholder>
                        )}
            </Link>
            <Link to={`${HA_ROUTES.users}/${userId}`}>
              <StyledLabel>
                <strong>
                  {firstName} 
                  {' '}
                  {lastName}
                </strong>
                <small>{email}</small>
              </StyledLabel>
            </Link>
          </>
            ) : (
              <>
                {' '}
                {avatarUrl ? (
                  <StyledAvatar
                            src={avatarUrl}
                            alt={`${firstName} ${lastName}`}
                        />
                    ) : (
                      <StyledAvatarPlaceholder color={backgroundColor}>
                        {firstName && firstName[0]}
                        {lastName && lastName[0]}
                      </StyledAvatarPlaceholder>
                    )}
                <StyledLabel>
                  <strong>
                    {firstName} 
                    {' '}
                    {lastName}
                  </strong>
                  <small>{email}</small>
                </StyledLabel>
              </>
            )}
        <LastColumn>
          {creator ? (
            <Badge secondary>Team Admin</Badge>
                ) : (
                    isRemovable && (
                    <CloseIcon
                            fill={theme.color.bgPrimaryLight}
                            onClick={() => {
                                setIsConfirmSubmitModalVisible(true);
                            }}
                        />
                    )
                )}
        </LastColumn>
      </StyledTeamMember>
    );
};

TeamMember.propTypes = {
    userId: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    avatarUrl: PropTypes.string || null,
    backgroundColor: PropTypes.string,
    creator: PropTypes.bool.isRequired,
    isRemovable: PropTypes.bool,
    email: PropTypes.string.isRequired,
    onRemove: PropTypes.func.isRequired,
};

TeamMember.defaultProps = {
    avatarUrl: null,
    backgroundColor: theme.color.bgPrimaryLight,
    isRemovable: false,
};

export default TeamMember;
