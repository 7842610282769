import Vimeo from '@u-wave/react-vimeo';
import styled, { css } from 'styled-components/macro';

export const StyledSmartTipView = styled.div`
    width: 100%;
    padding: 0 16px;
`;

export const StyledPlayer = styled(Vimeo)`
    width: 100%;
    height: auto;
    margin-bottom: 32px;

    ${({ theme }) => css`
        iframe {
            border: ${theme.border.base};
            border-radius: ${theme.border.radiusBase};
        }
    `}
`;

export const StyledSmartTipStatus = css`
    margin-left: 11px;
`;

export const Divider = styled.div`
    border-top: 1px solid;
    border-color: ${({ theme }) => theme.color.borderColorBase};
    margin: 32px 0;
`;

export const NoResultsWrapper = styled.div`
    margin-bottom: 32px;
    height: 55vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.color.bgGrey};
    border: ${({ theme }) => theme.border.base};
    border-radius: ${({ theme }) => theme.border.radiusBase};
`;
