import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, Link } from 'react-router-dom';
import { ReactComponent as RobotIcon } from 'assets/svg/no-results/robot.svg';
import { ReactComponent as BoxIcon } from 'assets/svg/no-results/box.svg';
import { ReactComponent as OopsIcon } from 'assets/svg/no-results/oops.svg';
import { ROUTES } from 'routing/constants';
import Button from 'components/Button';
import {
    NoResultsWrapper,
    FullHeightWrapper,
    ButtonsWrapper,
} from './NoResults.style';

const NoResults = ({
    type,
    title,
    message,
    fullHeight,
    reload,
    pageNotFound,
    linkedCourses,
}) => {
    const history = useHistory();
    const renderIcon = () => {
        switch (type) {
            case 'box':
                return <BoxIcon />;
            case 'oops':
                return <OopsIcon />;
            default:
                return <RobotIcon />;
        }
    };

    if (fullHeight) {
        return (
          <FullHeightWrapper>
            <NoResultsWrapper type={type}>
              {renderIcon()}
              <h2>{title}</h2>
              <p>
                {!linkedCourses ? (
                            message
                        ) : (
                          <>
                            New MedPower courses will appear here once you
                            are enrolled. If you want to find courses that
                            you previously completed, visit the
                            {' '}
                            <Link to="/courses"> Courses</Link>
                            {' '}
                            page.
                          </>
                        )}
              </p>
              {reload && (
                <Button
                            primaryDark
                            customStyles={{ marginTop: '8px' }}
                            onClick={() => window.location.reload()}
                        >
                  Reload
                </Button>
                    )}
              {pageNotFound && (
                <ButtonsWrapper>
                  <Button
                                primary
                                onClick={() => history.push(ROUTES.base)}
                            >
                    Go to Dashboard
                  </Button>
                  <Button
                                primary
                                onClick={() => window.location.reload()}
                            >
                    Try again
                  </Button>
                </ButtonsWrapper>
                    )}
            </NoResultsWrapper>
          </FullHeightWrapper>
        );
    }

    return (
      <NoResultsWrapper type={type}>
        {renderIcon()}
        <h2>{title}</h2>
        <p>
          {' '}
          {!linkedCourses ? (
                    message
                ) : (
                  <>
                    New MedPower courses will appear here once you are
                    enrolled. If you want to find courses that you
                    previously completed, visit the
                    {' '}
                    <Link to="/courses"> Courses</Link>
                    {' '}
                    page.
                  </>
                )}
        </p>
        {reload && (
        <Button
                    primaryDark
                    customStyles={{ marginTop: '8px' }}
                    onClick={() => window.location.reload()}
                >
          Reload
        </Button>
            )}
        {pageNotFound && (
        <ButtonsWrapper>
          <Button
                        primaryDark
                        onClick={() => history.push(ROUTES.base)}
                    >
            Go to Dashboard
          </Button>
          <Button
                        primaryDark
                        onClick={() => window.location.reload()}
                    >
            Try again
          </Button>
        </ButtonsWrapper>
            )}
      </NoResultsWrapper>
    );
};

NoResults.propTypes = {
    type: PropTypes.string,
    title: PropTypes.string,
    message: PropTypes.string,
    fullHeight: PropTypes.bool,
    reload: PropTypes.bool,
    pageNotFound: PropTypes.bool,
    linkedCourses: PropTypes.bool,
};

NoResults.defaultProps = {
    type: undefined,
    title: 'Oops! Nothing in here :(',
    message: '',
    fullHeight: false,
    reload: false,
    pageNotFound: false,
    linkedCourses: false,
};

export default NoResults;
