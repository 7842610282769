import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Input from 'components/Input';
import Button from 'components/Button';
import useAdmin from 'hooks/useAdmin';
import { ROUTES } from 'routing/constants';
import DropdownSelect from 'components/DropdownSelect';
import {
    CreateTeamFormWrapper,
    FormTitle,
    TeamPictureHolder,
    CreateTeamFormHolder,
    ButtonGroup,
    InputWrapper,
} from './CreateTeamForm.style';
import TeamPictureUpload from './TeamPictureUpload';

const CreateTeamForm = ({
    users,
    createTeam,
    loadMoreUsers,
    hasMoreUsers,
    filterUsers,
    resetUsersList,
    customCreator,
}) => {
    const isAdmin = useAdmin();
    const [teamPictureFile, setTeamPictureFile] = useState(null);
    const [teamNameValue, setTeamNameValue] = useState('');
    const [invitedUsers, setInvitedUsers] = useState([]);
    const [showCustomCreatorDropdown, setShowCustomCreatorDropdown] = useState(
        false
    );

    const history = useHistory();

    const resetState = () => {
        setTeamPictureFile(null);
        setTeamNameValue('');
        setInvitedUsers([]);
        filterUsers('');
    };

    const handleCreateTeam = () => {
        createTeam({
            user_ids: invitedUsers.map((user) => user.id),
            name: teamNameValue,
            picture: teamPictureFile,
        });
    };

    const handleUpload = (files) => {
        if (files.length) {
            setTeamPictureFile(files[0]);
        }
    };

    const getDisplayNameForCustomCreator = () => {
        if (customCreator && customCreator.selected) {
            const selectedCreator = invitedUsers.find(
                (user) => user.id === customCreator.selected
            );

            if (!selectedCreator) {
                return undefined;
            }

            return `${selectedCreator.first_name} ${selectedCreator.last_name}`;
        }

        return '';
    };

    useEffect(() => {
        if (invitedUsers.length === 0) {
            customCreator.onSelect('');
        }
    }, [invitedUsers]);

    return (
      <CreateTeamFormWrapper data-testid="CreateTeamForm">
        <TeamPictureHolder>
          <TeamPictureUpload handleUpload={handleUpload} />
        </TeamPictureHolder>
        <CreateTeamFormHolder>
          <FormTitle>Complete new team&apos;s details</FormTitle>
          <InputWrapper>
            <Input
                        variant="base"
                        type="text"
                        name="teamName"
                        onChange={(e) => setTeamNameValue(e.target.value)}
                        onBlur={() => {}}
                        placeholder="Team Name"
                        value={teamNameValue}
                    />
          </InputWrapper>

          <InputWrapper>
            <DropdownSelect
                        type="user"
                        items={users}
                        loadMore={loadMoreUsers}
                        hasMore={hasMoreUsers}
                        onSearch={filterUsers}
                        resetItemsList={resetUsersList}
                        handleSetSelectedItems={setInvitedUsers}
                        placeholder="Invite Users"
                        fullItem
                    />
          </InputWrapper>

          {isAdmin && (
            <InputWrapper>
              <Input
                            smallerDropdown
                            disabled={invitedUsers.length === 0}
                            dropdownTitle=""
                            variant="searchDropdown"
                            placeholder="Select a Team Admin"
                            onClick={() =>
                                setShowCustomCreatorDropdown(
                                    !showCustomCreatorDropdown
                                )}
                            value={getDisplayNameForCustomCreator()}
                            dropdownVisible={showCustomCreatorDropdown}
                            options={invitedUsers.map((user) => {
                                if (!user) {
                                    return { title: '', id: '' };
                                }

                                return {
                                    title: `${user.first_name} ${user.last_name}`,
                                    id: user.id,
                                };
                            })}
                            handleSelect={(value) => {
                                customCreator.onSelect(value);
                                setShowCustomCreatorDropdown(false);
                            }}
                        />
            </InputWrapper>
                )}

          <ButtonGroup>
            <Button
                        primaryDark
                        type="button"
                        onClick={() => handleCreateTeam()}
                        disabled={
                            !teamNameValue ||
                            teamNameValue.length === 0 ||
                            (isAdmin && !customCreator.selected)
                        }
                    >
              Finish
            </Button>
            <Button
                        primary
                        type="button"
                        onClick={() => {
                            resetState();
                            history.push(ROUTES.team);
                        }}
                    >
              Cancel
            </Button>
          </ButtonGroup>
        </CreateTeamFormHolder>
      </CreateTeamFormWrapper>
    );
};

CreateTeamForm.propTypes = {
    users: PropTypes.arrayOf(PropTypes.any).isRequired,
    createTeam: PropTypes.func.isRequired,
    loadMoreUsers: PropTypes.func.isRequired,
    hasMoreUsers: PropTypes.bool.isRequired,
    filterUsers: PropTypes.func.isRequired,
    resetUsersList: PropTypes.func.isRequired,
    customCreator: PropTypes.shape({
        selected: PropTypes.string,
        onSelect: PropTypes.func,
    }),
};

CreateTeamForm.defaultProps = {
    customCreator: {},
};

export default CreateTeamForm;
