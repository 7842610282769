import styled, { css } from 'styled-components/macro';

import BaseButton from 'components/Button';

import uploadIconSrc from 'assets/svg/cloud-upload.svg';

export const EditProfileWrapper = styled.div`
    position: relative;
    max-width: 1140px;
    display: flex;
    flex-wrap: wrap;

    margin: 16px;
    border-radius: ${({ theme }) => theme.border.radiusBase};
    border: ${({ theme }) => theme.border.base};
    background-color: ${({ theme }) => theme.color.white};
`;

export const StyledColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 267px;

    padding: 32px;

    & + & {
        border-left: ${({ theme }) => theme.border.base};
        justify-content: flex-start;
        align-items: flex-start;
        text-align: left;
        flex-grow: 1;
    }

    span {
        font-weight: 400;
        font-size: ${({ theme }) => theme.fontSize.small};
        color: ${({ theme }) => theme.color.textLight};
        margin-bottom: 24px;
    }
`;

export const AvatarContainer = styled.div`
    position: relative;

    &:hover {
        cursor: pointer;
    }
`;

export const ProfileImgHolder = styled.div`
    width: 134px;
    height: 134px;
    border-radius: 50%;
    margin: 8px;
    box-shadow: 0px 8px 40px rgba(0, 48, 135, 0.12);
    border: 6px solid white;
    background-color: #c4c4c4;
    overflow: hidden;

    img {
        width: 132px;
        height: 132px;
        margin: -6px;
        object-fit: cover;
    }
`;

export const UploadIcon = styled.div`
    position: absolute;
    bottom: 7px;
    right: 14px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #377fff;
    background-image: url(${uploadIconSrc});
    background-position: center;
    background-repeat: no-repeat;
`;

export const FormContainer = styled.div`
    width: 100%;
`;

// TODO: Move inverted to styles, update to match style guide
export const Button = styled(BaseButton)`
    width: 123px;

    ${({ inverted }) =>
        inverted &&
        css`
            color: white;
            background-color: #377fff;
        `};
`;
