import {
    GET_TENANT_ORGANIZATION,
    GET_TENANT_ORGANIZATION_SUCCESS,
    GET_TENANT_ORGANIZATION_FAILURE,
} from 'redux/actions/tenantOrganization';

export const initialState = {
    name: '',
    slug: '',
    isLmsEnabled: null,
    performanceCoaching: null,
    performanceCoachingRequestsCount: null,
    createdAt: '',
    updatedAt: '',
    isEstablished: false,
    isError: false,
};

const tenantOrganization = (state = initialState, action) => {
    switch (action.type) {
        case GET_TENANT_ORGANIZATION:
            return {
                ...state,
            };

        case GET_TENANT_ORGANIZATION_SUCCESS:
            return {
                ...state,
                ...action.payload,
                isEstablished: true,
                isError: false,
            };

        case GET_TENANT_ORGANIZATION_FAILURE:
            return {
                ...state,
                isEstablished: false,
                isError: true,
            };

        default:
            return state;
    }
};

export default tenantOrganization;
