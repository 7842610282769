import React from 'react';
import Button from 'components/Button';
import { Link } from 'react-router-dom';
import { ReactComponent as RobotIcon } from 'assets/svg/modal-robot.svg';
import { ROUTES } from 'routing/constants';
import { useSelector } from 'react-redux';
import {
    EmptySmartTipsSectionWrapper,
    EmptyPageTitle,
    EmptyPageSubtitle,
    EmptyButtonGroup,
} from './SmartTips.style';

const SmartTipsEmptyState = () => {
    const { isLMSEnabled } = useSelector((state) => state.currentUser);
    return (
      <EmptySmartTipsSectionWrapper data-testid="smarttips-wrapper-empty">
        <RobotIcon />
        <EmptyPageTitle>
          Congratulations! You finished everything!
        </EmptyPageTitle>
        <EmptyPageSubtitle>
          New Smart Tips will appear here in a while. In the meantime you
          can try our Courses section or see your Performance over last
          period.
        </EmptyPageSubtitle>
        <EmptyButtonGroup>
          {isLMSEnabled && (
            <Link to={ROUTES.courses}>
              <Button primary>Go to Courses</Button>
            </Link>
                )}

          <Link to={ROUTES.performance}>
            <Button primary>Go to Performance</Button>
          </Link>
        </EmptyButtonGroup>
      </EmptySmartTipsSectionWrapper>
    );
};

export default SmartTipsEmptyState;
