import React from 'react';
import Button from 'components/Button';
import { ReactComponent as Board } from 'assets/svg/no-results/board.svg';
import { useHistory } from 'react-router-dom';
import useAdmin from 'hooks/useAdmin';
import { ROUTES } from 'routing/constants';
import {
    GoToPerformanceWrapper,
    Grid,
    Section,
    Title,
    Info,
} from './GoToPerformance.style';

const GoToPerformance = () => {
    const history = useHistory();
    const isAdmin = useAdmin();

    return (
      <GoToPerformanceWrapper>
        <Grid>
          <Section>
            <Title>
              {isAdmin
                            ? 'Dig into the data!'
                            : 'Your personalized performance scorecard'}
            </Title>
            <Info>
              {isAdmin
                            ? `See a detailed breakdown of your users’ aggregated
                        performance along with corresponding goals.`
                            : 'See a detailed breakdown of your performance along with corresponding goals and recommended Smart Tips.'}
            </Info>
            <Button
                        primary
                        onClick={() => history.push(ROUTES.performance)}
                    >
              Go to Performance
            </Button>
          </Section>
          <Section>
            <Board />
          </Section>
        </Grid>
      </GoToPerformanceWrapper>
    );
};

export default GoToPerformance;
