import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import MetricUploadItem, {
    MPADataSourcesMeasuresUploadTableHeader,
} from 'components/MPADataSourcesMeasuresUploadItem/MPADataSourcesMeasuresUploadItem';
import Spinner from 'components/Spinner';
import InfiniteScroll from 'react-infinite-scroller';
import SectionHeader from 'components/SectionHeader';
import Button from 'components/Button';
import useOffset from 'hooks/useOffset';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import establishDataSourcesMeasuresUploadsThunk, {
    loadMoreDataSourcesMeasuresUploadsThunk,
    resetDataSourcesMeasuresUploadsThunk,
} from 'redux/actions/thunks/mpaDataSourcesMeasuresUploads';

const INFINITE_SCROLL_THRESHOLD = 20;

const ProcessedResults = ({ isImportModalVisible }) => {
    const [offset, incrementOffset, resetOffset] = useOffset(
        INFINITE_SCROLL_THRESHOLD
    );
    const [
        sortedDataSourcesMeasuresUploads,
        setSortedDataSourcesMeasuresUploads,
    ] = useState([]);

    const dispatch = useDispatch();
    const { getAccessTokenSilently, logout } = useAuth0();
    const {
        areDataSourcesMeasuresUploadsEstablished,
        areDataSourcesMeasuresUploadsFetching,
        hasMoreDataSourcesMeasuresUploads,
        dataSourcesMeasuresUploads,
    } = useSelector((state) => state.mpaDataSourcesMeasuresUploads);

    const reloadList = () => {
        dispatch(
            establishDataSourcesMeasuresUploadsThunk(
                getAccessTokenSilently,
                logout,
                INFINITE_SCROLL_THRESHOLD,
                0
            )
        );
        resetOffset(INFINITE_SCROLL_THRESHOLD);
    };

    useEffect(() => {
        dispatch(
            establishDataSourcesMeasuresUploadsThunk(
                getAccessTokenSilently,
                logout,
                INFINITE_SCROLL_THRESHOLD,
                0
            )
        );
        resetOffset(INFINITE_SCROLL_THRESHOLD);

        return dispatch(resetDataSourcesMeasuresUploadsThunk());
    }, []);

    useEffect(() => {
        if (!isImportModalVisible) {
            dispatch(
                establishDataSourcesMeasuresUploadsThunk(
                    getAccessTokenSilently,
                    logout,
                    INFINITE_SCROLL_THRESHOLD,
                    0
                )
            );
            resetOffset(INFINITE_SCROLL_THRESHOLD);
        }
    }, [isImportModalVisible]);

    useEffect(() => {
        setSortedDataSourcesMeasuresUploads(
            dataSourcesMeasuresUploads.sort(
                (a, b) =>
                    new Date(b.created_at).getTime() -
                    new Date(a.created_at).getTime()
            )
        );
    }, [dataSourcesMeasuresUploads]);

    if (!areDataSourcesMeasuresUploadsEstablished) {
        return <Spinner />;
    }

    return (
      <div>
        <SectionHeader title="Processed Files" withMargin>
          <Button primary small onClick={reloadList}>
            Refresh
          </Button>
          {areDataSourcesMeasuresUploadsFetching && <Spinner small />}
        </SectionHeader>
        <MPADataSourcesMeasuresUploadTableHeader firstColumnLabel="File name" />
        <InfiniteScroll
                loadMore={() => {
                    dispatch(
                        loadMoreDataSourcesMeasuresUploadsThunk(
                            getAccessTokenSilently,
                            logout,
                            INFINITE_SCROLL_THRESHOLD,
                            offset
                        )
                    );
                    incrementOffset();
                }}
                loader={<Spinner key="spinner" />}
                hasMore={
                    hasMoreDataSourcesMeasuresUploads &&
                    !areDataSourcesMeasuresUploadsFetching
                }
            >
          {sortedDataSourcesMeasuresUploads.map((item) => (
            <MetricUploadItem
                        key={item.id}
                        id={item.id}
                        createdAt={item.created_at}
                        fileName={item.file_name}
                        status={item.status}
                        fileUrl={item.file_url}
                        errorsFileUrl={item.error_file_url}
                    />
                ))}
        </InfiniteScroll>
      </div>
    );
};

ProcessedResults.propTypes = {
    isImportModalVisible: PropTypes.bool.isRequired,
};

export default ProcessedResults;
