import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { ReactComponent as Dot } from 'assets/svg/notifications/dot.svg';
import { ReactComponent as MemoWarning } from 'assets/svg/notifications/memo-warning.svg';

import useDate, { DATE_TYPES } from 'hooks/useDate';
import useCurrentUser from 'hooks/useCurrentUser';
import {
    getPathColor,
    getCircleColor,
    renderActions,
    renderIcon,
    renderDescription,
} from './helpers';
import {
    SingleNotificationHolder,
    CompactSingleNotificationHolder,
    IconHolder,
    MiddleSection,
    Description,
    DateHolder,
    ActionsHolder,
    CompactRow,
} from './SingleNotification.style';

const SingleNotification = ({
    name,
    compact,
    isRead,
    createdAt,
    context,
    markAsRead,
    critical,
}) => {
    const history = useHistory();
    const currentUser = useCurrentUser();

    const getTime = (creationDate) =>
        creationDate.toLocaleString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
        });

    const GetDate = () => {
        const creationDate = new Date(createdAt);
        const parsedDate = useDate(creationDate, DATE_TYPES.long);
        const days = Math.floor(
            (Date.now() - creationDate.getTime()) / 1000 / 86400
        );

        if (days === 0) {
            return `Today, ${getTime(creationDate)}`;
        }

        if (days === 1) {
            return `Yesterday, ${getTime(creationDate)}`;
        }

        return parsedDate;
    };

    const handleMarkAsRead = () => {
        if (
            !isRead &&
            markAsRead &&
            renderActions(name, context, history, compact, markAsRead) === null
        ) {
            markAsRead();
        }
    };

    if (compact) {
        return (
          <CompactSingleNotificationHolder onClick={() => markAsRead()}>
            <CompactRow>
              <IconHolder
                        type={name}
                        getPathColor={getPathColor}
                        getCircleColor={getCircleColor}
                        critical={critical}
                    >
                {critical ? (
                  <MemoWarning type="critical_memo" />
                        ) : (
                            renderIcon(name)
                        )}

                {!isRead && <Dot className="dot" />}
              </IconHolder>
              <MiddleSection>
                <Description isRead={isRead} critical={critical}>
                  {renderDescription(name, context, currentUser)}
                </Description>
                <DateHolder>{GetDate()}</DateHolder>
              </MiddleSection>
            </CompactRow>
            <ActionsHolder compact>
              {renderActions(
                        name,
                        context,
                        history,
                        compact,
                        handleMarkAsRead
                    )}
            </ActionsHolder>
          </CompactSingleNotificationHolder>
        );
    }

    return (
      <SingleNotificationHolder onClick={() => markAsRead()}>
        <IconHolder
                type={name}
                getPathColor={getPathColor}
                getCircleColor={getCircleColor}
                critical={critical}
            >
          {critical ? (
            <MemoWarning type="critical_memo" />
                ) : (
                    renderIcon(name)
                )}
          {!isRead && <Dot className="dot" />}
        </IconHolder>
        <MiddleSection>
          <Description isRead={isRead}>
            {renderDescription(name, context, currentUser)}
            {' '}
          </Description>
          <DateHolder>{GetDate()}</DateHolder>
        </MiddleSection>
        <ActionsHolder>
          {renderActions(name, context, history, compact, markAsRead)}
        </ActionsHolder>
      </SingleNotificationHolder>
    );
};

SingleNotification.propTypes = {
    name: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    isRead: PropTypes.bool,
    critical: PropTypes.bool,
    compact: PropTypes.bool,
    context: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({ id: PropTypes.string, message: PropTypes.string }),
    ]).isRequired,
    markAsRead: PropTypes.func.isRequired,
};

SingleNotification.defaultProps = {
    isRead: true,
    compact: false,
    critical: false,
};

export default SingleNotification;
