import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Accordion from 'components/Accordion';
import SingleMetric from 'containers/SingleMetric';
import Switch from 'components/Switch';
import { AccordionWrapper, TableHeader, LabelCta } from './MetricsTable.style';

const MetricsTable = ({
    metrics,
    label,
    metricSourceId,
    isMetricSourceEnabled,
    fetchChangeSourceStatus,
    reloadList,
    isAccordionOpen,
}) => {
    const [sortedMetrics, setSortedMetrics] = useState([]);

    useEffect(() => {
        setSortedMetrics(
            metrics.sort((m1, m2) => {
                if (m1.name > m2.name) {
                    return 1;
                }

                if (m1.name < m2.name) {
                    return -1;
                }

                return 0;
            })
        );
    }, [metrics]);

    return (
      <AccordionWrapper>
        <Accordion
                initiallyOpen={isAccordionOpen}
                expandOnlyOnArrowClick
                label={label}
                labelCta={() => (
                  <LabelCta>
                    <small style={{ marginRight: '16px' }}>
                      Global source status
                    </small>
                    <Switch
                            switchId={`${metricSourceId}-switch`}
                            switchOn={() => {
                                fetchChangeSourceStatus(true);
                            }}
                            switchOff={() => {
                                fetchChangeSourceStatus(false);
                            }}
                            defaultStatus={isMetricSourceEnabled}
                            withConfirmationModal
                            externalStatus={isMetricSourceEnabled}
                            onConfirmModalDescription="Warning: Changing Data Source Status triggers a score recalculation for all affected Organizations. It can take several minutes."
                            offConfirmModalDescription="Warning: Changing Data Source Status triggers a score recalculation for all affected Organizations. It can take several minutes."
                        />
                  </LabelCta>
                )}
            >
          <TableHeader>
            <span>Metrics name</span>
            <span>Goal</span>
            <span>Avg. result</span>
            <span>Metric status</span>
          </TableHeader>
          {sortedMetrics.map((metric, index) => (
            <SingleMetric
                        key={metric.id}
                        withoutBorder={index === metrics.length - 1}
                        metricSourceId={metricSourceId}
                        reloadList={reloadList}
                        {...metric}
                    />
                ))}
        </Accordion>
      </AccordionWrapper>
    );
};

MetricsTable.propTypes = {
    metrics: PropTypes.arrayOf(PropTypes.any).isRequired,
    label: PropTypes.string.isRequired,
    metricSourceId: PropTypes.string.isRequired,
    isMetricSourceEnabled: PropTypes.bool.isRequired,
    fetchChangeSourceStatus: PropTypes.func.isRequired,
    reloadList: PropTypes.func.isRequired,
    isAccordionOpen: PropTypes.bool.isRequired,
};

export default MetricsTable;
