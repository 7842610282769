import styled, { css } from 'styled-components/macro';

export const UserWrapper = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: 3fr 4fr;
    grid-gap: 16px;
    align-items: center;
`;

export const FullName = styled.div`
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    color: ${({ theme }) => theme.color.textBase};
`;

export const Result = styled.div`
    position: absolute;
    right: 15px;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.color.checkGreen};
    color: ${({ theme }) => theme.color.white};
    font-size: 12px;
    font-weight: 600;
    line-height: 22px;
    text-align: center;
    padding: 0 6px;

    ${({ isFail }) =>
        isFail &&
        css`
            background-color: ${({ theme }) => theme.color.red};
        `};
`;
