export const ESTABLISH_COURSES = 'ESTABLISH_COURSES';
export const ESTABLISH_COURSES_SUCCESS = 'ESTABLISH_COURSES_SUCCESS';
export const ESTABLISH_COURSES_FAILURE = 'ESTABLISH_COURSES_FAILURE';

export const establishCourses = () => ({
    type: ESTABLISH_COURSES,
});

export const establishCoursesSuccess = (payload) => ({
    type: ESTABLISH_COURSES_SUCCESS,
    payload,
});

export const establishCoursesFailure = (err) => ({
    type: ESTABLISH_COURSES_FAILURE,
    err,
});
