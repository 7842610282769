import styled, { css } from 'styled-components/macro';

export const TableHeaderHolder = styled.div`
    width: 100%;
    padding: 16px 32px;

    display: grid;
    grid-template-columns: 1fr 6fr 4fr 4fr 4fr 1fr;
    grid-gap: 16px;
    align-items: center;

    span {
        text-align: left;
        color: ${({ theme }) => theme.color.textLight};
    }
`;

export const SmartTipUploadItemHolder = styled.div`
    width: 100%;
    height: auto;
    padding: 20px 32px;

    display: grid;
    grid-template-columns: 1fr 6fr 4fr 4fr 4fr 1fr;
    grid-gap: 16px;
    align-items: center;
    margin-bottom: 16px;

    ${({ theme }) => css`
        background-color: ${theme.color.white};
        border: ${theme.border.base};
        border-radius: ${theme.border.radiusBase};
    `}
`;

export const LastUpdate = styled.p`
    color: ${({ theme }) => theme.color.textLight};
`;

export const SurveysEnabledHolder = styled.div`
    display: flex;
    flex-flow: row;
    align-items: center;

    p {
        color: ${(props) => props.color};
    }

    svg {
        margin-right: 11px;
        fill: ${(props) => props.color};
    }
`;

export const CommentHolder = styled.div`
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    display: flex;
    flex-flow: row;
    align-items: center;

    p {
        color: ${({ theme, disabled }) =>
            disabled ? theme.color.bgSearch : theme.color.textLight};
    }

    svg {
        margin-right: 9px;
        fill: ${({ theme, disabled }) =>
            disabled ? theme.color.bgSearch : theme.color.textLight};
    }
`;

export const Score = styled.p`
    color: ${({ theme }) => theme.color.textLight};
`;

export const StyledCheckbox = css``;
