import styled, { css } from 'styled-components/macro';

export const TileBackground = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1001;

    ${({ showBackground }) =>
        showBackground &&
        css`
            z-index: 1000;
            background: rgba(34, 41, 81, 0.6);
        `};

    ${({ isHidden }) =>
        isHidden &&
        css`
            display: none;
        `};
`;

export const Highlighter = styled.div`
    position: absolute;
    border-radius: ${({ theme }) => theme.border.radiusBase};
    background-color: ${({ theme }) => theme.color.white};
    display: flex;

    ${({ highlighterStyles }) =>
        highlighterStyles &&
        css`
            ${highlighterStyles};
        `};
`;

export const TilePositioner = styled.div`
    position: absolute;
    z-index: 1003;

    ${({ positioning }) =>
        positioning &&
        css`
            ${positioning};
        `};
`;

export const DirectionalArrow = styled.div`
    width: 20px;
    height: 20px;
    background-color: ${({ theme }) => theme.color.primary};

    position: absolute;
    z-index: 1003;

    opacity: 0;

    transform: rotate(45deg);

    ${({ topLeft }) =>
        topLeft &&
        css`
            opacity: 1;
            top: calc(100% - 75%);
            left: -10px;
        `};

    ${({ topRight }) =>
        topRight &&
        css`
            opacity: 1;
            top: calc(100% - 75%);
            right: -10px;
        `};

    ${({ bottom }) =>
        bottom &&
        css`
            opacity: 1;
            bottom: -10px;
            right: calc(100% - 50%);
        `};
`;

export const TileContainer = styled.div`
    width: 410px;
    height: auto;
    background-color: ${({ theme }) => theme.color.primary};
    border-radius: ${({ theme }) => theme.border.radiusBase};
    padding: 24px;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    position: relative;
    z-index: 1004;

    ${({ compact }) =>
        compact &&
        css`
            padding: 0 16px;
        `};
`;

export const TileContent = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    padding: 32px 16px;

    color: ${({ theme }) => theme.color.white};

    p {
        color: ${({ theme }) => theme.color.white};
        text-align: center;
    }

    ${({ compact }) =>
        compact &&
        css`
            align-items: flex-start;
            padding: 16px 8px;

            p {
                text-align: left;
            }
        `};
`;

export const TileButtons = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;

    button {
        margin-top: 8px;
        width: 362px;
        height: 47px;

        svg {
            margin-right: 8px;
        }
    }

    ${({ compact }) =>
        compact &&
        css`
            width: 100%;
            flex-direction: row-reverse;
            justify-content: space-between;
            margin-bottom: 24px;
            padding: 0 8px;

            button {
                margin-top: 0;
                width: 115px;
            }
        `};
`;

export const CloseIconHolder = styled.span`
    cursor: pointer;
    position: absolute;
    top: 16px;
    right: 16px;
`;
