import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Input from 'components/Input';
import Button from 'components/Button';
import Spinner from 'components/Spinner';
import InfiniteScroll from 'react-infinite-scroller';
import {
    Wrapper,
    FooterButtons,
    ListWrapper,
} from './DropdownSelectCheckbox.style';
import DropdownItem from './DropdownItem';

const DropdownSelect = ({
    type,
    items,
    loadMore,
    hasMore,
    onSearch,
    resetItemsList,
    onSubmit,
    onClose,
    preselectedItems,
    confirmButtonLabel,
    handleSetSelectedItems,
    placeholder,
    disabled,
    fullItem,
}) => {
    const [isDropdownVisible, setIsDropdownVisible] = useState(undefined);
    const [filter, setFilter] = useState('');
    const [selectedItems, setSelectedItems] = useState([]);
    const dropdownRef = useRef(null);

    const selectItem = (item) => {
        setSelectedItems([...selectedItems, item]);
    };

    const deselectItem = (id) =>
        setSelectedItems(
            selectedItems.filter((selectedItem) => selectedItem.id !== id)
        );

    const handleClose = () => {
        onClose();
        setIsDropdownVisible(false);
    };

    const handleClickOutside = (e) => {
        if (dropdownRef.current && dropdownRef.current.contains(e.target)) {
            return;
        }
        setIsDropdownVisible(false);
    };

    useEffect(() => {
        onSearch(filter);

        return () => {
            resetItemsList();
        };
    }, [filter]);

    useEffect(() => {
        if (handleSetSelectedItems && fullItem) {
            handleSetSelectedItems(selectedItems);
        } else if (handleSetSelectedItems) {
            handleSetSelectedItems(
                selectedItems.map((selectedItem) => selectedItem.id)
            );
        }
    }, [selectedItems]);

    useEffect(() => {
        if (isDropdownVisible) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isDropdownVisible]);

    useEffect(() => {
        setSelectedItems([...selectedItems, ...preselectedItems]);
    }, [preselectedItems]);

    return (
      <Wrapper disabled={disabled} withButtons>
        <Input
                placeholder={placeholder}
                defaultValue={filter}
                disabled={disabled}
                onChange={({ target }) => setFilter(target.value)}
            />

        <ListWrapper>
          <InfiniteScroll
                    initialLoad={false}
                    loadMore={loadMore}
                    loader={<Spinner key="spinner" small />}
                    hasMore={hasMore}
                    useWindow={false}
                >
            {items.map((item) => (
              <DropdownItem
                            key={item.id}
                            type={type}
                            data={item}
                            isSelected={selectedItems
                                .map((selectedItem) => selectedItem.id)
                                .includes(item.id)}
                            select={() => selectItem(item)}
                            deselect={() => deselectItem(item.id)}
                        />
                    ))}
          </InfiniteScroll>
        </ListWrapper>

        <FooterButtons>
          <Button primary disabled={disabled} onClick={handleClose}>
            Close
          </Button>
          <Button
                    primaryDark
                    disabled={disabled}
                    onClick={() => {
                        onSubmit(selectedItems.map((item) => item.id));
                        handleClose();
                    }}
                >
            {confirmButtonLabel}
          </Button>
        </FooterButtons>
      </Wrapper>
    );
};

DropdownSelect.propTypes = {
    type: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.any).isRequired,
    loadMore: PropTypes.func.isRequired,
    hasMore: PropTypes.bool.isRequired,
    onSearch: PropTypes.func.isRequired,
    resetItemsList: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    onClose: PropTypes.func,
    preselectedItems: PropTypes.arrayOf(PropTypes.any),
    confirmButtonLabel: PropTypes.string,
    handleSetSelectedItems: PropTypes.func,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    fullItem: PropTypes.bool,
};

DropdownSelect.defaultProps = {
    onSubmit: () => {},
    onClose: () => {},
    preselectedItems: [],
    confirmButtonLabel: 'Assign',
    handleSetSelectedItems: undefined,
    placeholder: 'Search',
    disabled: false,
    fullItem: false,
};

export default DropdownSelect;
