export const ROUTES = {
    base: '/',
    dashboard: '/dashboard',
    onboarding: '/onboarding',
    courses: '/courses',

    smartTips: '/smart-tips',

    user: '/user', // seems to be unnecessary
    userSettings: '/user/settings',
    editUserProfile: '/user/profile/edit',

    performance: '/performance',

    team: '/team',
    createTeam: '/team/create',
    teamOverview: '/team/overview',
    teamMembers: '/team/members',
    teamRanking: '/team/ranking',

    search: '/search',
    browseSmartTips: '/browse-smart-tips',
    notifications: '/notifications',
    faq: '/faq',
};

export const HA_ROUTES = {
    smartTips: '/ha-smart-tips-tab-1',
    createSmartTip: '/ha-smart-tips/create',
    editSmartTip: '/ha-smart-tips/edit',
    users: '/ha-users',
    createNewUser: '/ha-create-new-user',
    editUser: '/ha-edit-user',
    adminMemos: '/ha-admin-memos',
    dataSourcesMetrics: '/ha-data-sources-metrics',
    tipOfTheWeek: '/ha-smart-tips-tab-2',
    usersSatisfaction: '/ha-smart-tips-tab-3',
    userClassification: '/ha-user-classification',
};

export const MPA_ROUTES = {
    customers: '/mpa-customers',
    addCustomer: '/mpa-add-customer',
    editCustomer: '/mpa-edit-customer',
    dataSourcesMeasures: '/mpa-data-sources-measures',
    superAdminUsers: '/mpa-super-admin-users',
    addSuperAdminUser: '/mpa-add-super-admin-user',
    editSuperAdminUser: '/mpa-edit-super-admin-user',
    adminMemos: '/mpa-admin-memos',
    userSettings: '/mpa-user/settings',
    editUserProfile: '/mpa-user/profile/edit',
};
