import styled, { css } from 'styled-components/macro';

export const Wrapper = styled.div`
    position: relative;
    padding-bottom: 18px;
    margin-bottom: 18px;
    height: 260px;

    ${({ withButtons }) =>
        withButtons &&
        css`
            margin-bottom: 0px;
            padding-bottom: 0px;
            height: auto;
        `}

    ${({ disabled }) =>
        disabled &&
        css`
            height: auto;
        `}
`;

export const ListWrapper = styled.div`
    position: relative;
    width: 100%;
    max-height: 245px;
    overflow-y: auto;
    padding: 8px 16px;
`;

export const FooterButtons = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 8px;
    position: relative;
    border-top: 1px solid;
    border-color: ${({ theme }) => theme.color.borderColorBase};

    button {
        margin-left: 8px;
    }
`;

export const SpinnerWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`;
