import {
    establishMetricResults,
    establishMetricResultsSuccess,
    establishMetricResultsFailure,
    uploadMetricResults,
    uploadMetricResultsSuccess,
    uploadMetricResultsFailure,
    searchOrganizationUsers,
    searchOrganizationUsersSuccess,
    searchOrganizationUsersFailure,
    searchMetrics,
    searchMetricsSuccess,
    searchMetricsFailure,
    updateMetric,
    updateMetricSuccess,
    updateMetricFailure,
} from 'redux/actions/metrics';
import {
    dispatchSuccessAlertThunk,
    dispatchErrorAlertThunk,
} from 'redux/actions/thunks/systemAlerts';
import apiRoutes from 'config/apiRoutes';
import downloadBlob from 'redux/helpers/downloadBlob';
import establishMetricSourcesThunk from './metricSources';

export const updateMetricThunk = (
    getAccessTokenSilently,
    logout,
    metricSourceId,
    metricId,
    values,
    reloadList
) => (dispatch) => {
    dispatch(updateMetric());

    const formData = new FormData();

    formData.append('metric[goal]', values.goal);

    if (values.goal !== undefined)
        formData.append('metric[enabled]', values.enabled);

    if (values.smartTipId !== undefined)
        formData.append('metric[smart_tip_id]', values.smartTipId);

    if (values.disabledUserIds && values.disabledUserIds.length > 0) {
        values.disabledUserIds.forEach((userId) =>
            formData.append('metric[disabled_user_ids][]', userId)
        );
    } else {
        formData.append('metric[disabled_user_ids]', []);
    }

    return getAccessTokenSilently()
        .then((token) => {
            fetch(apiRoutes.put.updateMetric(metricSourceId, metricId), {
                method: 'PUT',
                headers: {
                    organization_slug: window.localStorage.getItem(
                        'MP_ORGANIZATION'
                    ),
                    Authorization: `Token token="${token}"`,
                },
                body: formData,
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.error) {
                        dispatch(dispatchErrorAlertThunk(metricId, res.error));
                        return dispatch(updateMetricFailure(res, res.error));
                    }

                    if (reloadList) reloadList();

                    dispatch(
                        dispatchSuccessAlertThunk(
                            metricId,
                            'Metric data has been updated'
                        )
                    );

                    return dispatch(
                        updateMetricSuccess({ res, metricSourceId })
                    );
                })
                .catch((err) => {
                    dispatch(dispatchErrorAlertThunk(metricId, err.error));
                    dispatch(
                        updateMetricFailure({
                            err,
                        })
                    );
                });
        })
        .catch(() => logout({ returnTo: window.location.origin }));
};

export const uploadMetricResultsThunk = (
    getAccessTokenSilently,
    logout,
    file
) => (dispatch) => {
    dispatch(uploadMetricResults());

    const formData = new FormData();
    formData.append('file', file);

    return getAccessTokenSilently()
        .then((token) => {
            fetch(apiRoutes.post.uploadMetricResults(), {
                method: 'POST',
                headers: {
                    organization_slug: window.localStorage.getItem(
                        'MP_ORGANIZATION'
                    ),
                    Authorization: `Token token="${token}"`,
                },
                body: formData,
            })
                .then((res) => {
                    if (res.error || +res.status >= 300) {
                        dispatch(
                            dispatchErrorAlertThunk('metricUpload', res.error)
                        );
                        return dispatch(uploadMetricResultsFailure(res.error));
                    }

                    dispatch(
                        dispatchSuccessAlertThunk(
                            'metricUpload',
                            'Uploaded metric results'
                        )
                    );
                    dispatch(
                        establishMetricSourcesThunk(
                            getAccessTokenSilently,
                            logout
                        )
                    );
                    return dispatch(uploadMetricResultsSuccess(res.status));
                })
                .catch((err) => {
                    dispatch(
                        dispatchErrorAlertThunk('metricUpload', err.error)
                    );
                    dispatch(
                        uploadMetricResultsFailure({
                            err,
                        })
                    );
                });
        })
        .catch(() => logout({ returnTo: window.location.origin }));
};

export const downloadTemplateThunk = (getAccessTokenSilently, logout) => () => {
    getAccessTokenSilently()
        .then((token) => {
            fetch(apiRoutes.get.downloadTemplate(), {
                method: 'GET',
                headers: {
                    organization_slug: window.localStorage.getItem(
                        'MP_ORGANIZATION'
                    ),
                    Authorization: `Token token="${token}"`,
                },
            })
                .then((res) => res.blob())
                .then((res) => downloadBlob(res, 'metrics-template.csv'));
        })
        .catch(() => logout({ returnTo: window.location.origin }));
};

export const searchOrganizationUsersThunk = (
    getAccessTokenSilently,
    logout,
    searchString,
    limit,
    offset
) => (dispatch) => {
    dispatch(searchOrganizationUsers());

    return getAccessTokenSilently()
        .then((token) => {
            fetch(
                apiRoutes.get.organizationUsersSearch(
                    searchString,
                    limit,
                    offset
                ),
                {
                    method: 'GET',
                    headers: {
                        organization_slug: window.localStorage.getItem(
                            'MP_ORGANIZATION'
                        ),
                        Authorization: `Token token="${token}"`,
                    },
                }
            )
                .then((res) => res.json())
                .then((res) => {
                    if (res.error) {
                        dispatch(
                            dispatchErrorAlertThunk('metricsSearch', res.error)
                        );
                        dispatch(searchOrganizationUsersFailure(res.error));
                    }

                    dispatch(searchOrganizationUsersSuccess(res.users));
                })
                .catch((err) => {
                    dispatch(
                        dispatchErrorAlertThunk('metricsSearch', err.error)
                    );
                    dispatch(searchOrganizationUsersFailure(err));
                });
        })
        .catch(() => logout({ returnTo: window.location.origin }));
};

export const searchMetricsThunk = (
    getAccessTokenSilently,
    logout,
    sourceId,
    searchString,
    limit,
    offset,
    providerIds = []
) => (dispatch) => {
    dispatch(searchMetrics());

    let body;
    if (providerIds.length > 0) {
        body = JSON.stringify({
            provider_ids: providerIds,
        });
    }

    return getAccessTokenSilently()
        .then((token) => {
            fetch(
                apiRoutes.post.searchMetrics(
                    sourceId,
                    searchString,
                    limit,
                    offset,
                    providerIds
                ),
                {
                    method: 'POST',
                    headers: {
                        organization_slug: window.localStorage.getItem(
                            'MP_ORGANIZATION'
                        ),
                        Authorization: `Token token="${token}"`,
                    },
                    body: {
                        ...body,
                    },
                }
            )
                .then((res) => res.json())
                .then((res) => {
                    if (res.error) {
                        dispatch(
                            dispatchErrorAlertThunk('metrichSearch', res.error)
                        );
                        dispatch(searchMetricsFailure(res.error));
                    }

                    dispatch(searchMetricsSuccess(res.metrics));
                })
                .catch((err) => {
                    dispatch(
                        dispatchErrorAlertThunk('metrichSearch', err.error)
                    );
                    dispatch(searchMetricsFailure(err));
                });
        })
        .catch(() => logout({ returnTo: window.location.origin }));
};

export const establishMetricResultsThunk = (
    getAccessTokenSilently,
    logout,
    metricId,
    providerIds
) => (dispatch) => {
    dispatch(establishMetricResults());

    return getAccessTokenSilently()
        .then((token) => {
            fetch(apiRoutes.post.organizationMetricResults(metricId), {
                method: 'POST',
                headers: {
                    organization_slug: window.localStorage.getItem(
                        'MP_ORGANIZATION'
                    ),
                    Authorization: `Token token="${token}"`,
                },
                body: JSON.stringify(providerIds),
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.error) {
                        dispatch(establishMetricResultsFailure(res.error));
                    }

                    dispatch(
                        establishMetricResultsSuccess({
                            metric: metricId,
                            results: res.metric_results,
                        })
                    );
                })
                .catch((err) => dispatch(establishMetricResultsFailure(err)));
        })
        .catch(() => logout({ returnTo: window.location.origin }));
};
