import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { css } from 'styled-components/macro';
import { useHistory } from 'react-router-dom';

import { ReactComponent as ArrowBackIcon } from 'assets/svg/arrow-back.svg';
import { MPA_ROUTES } from 'routing/constants';
import theme from 'assets/theme/mainTheme';
import Button from 'components/Button';
import Input from 'components/Input';
import Switch from 'components/Switch';
import Checkbox from 'components/Checkbox';
import SectionHeader from 'components/SectionHeader';
import TogglePerformanceCoachingModal from 'components/TogglePerformanceCoachingModal';

import {
    ComponentWrapper,
    EditCustomerWrapper,
    Title,
    SectionItem,
    InputGroupWrapper,
    InputWrapper,
    BottomSectionWrapper,
} from './MPAEditCustomer.style';

const MPAEditCustomer = ({
    countries,
    isDropdownVisible,
    setIsDropdownVisible,
    ...rest
}) => {
    const history = useHistory();
    const [perfomranceModalVisible, setPerformanceModalVisible] = useState(
        undefined
    ); // undefined or string<'enable' | 'disable'>
    const [useAuth0Connection, setUseAuth0Connection] = useState(false);
    const {
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
    } = rest;

    const isButtonDisabled = () => {
        let result = false;

        if (Object.values(errors).length !== 0) {
            result = true;
        }

        return result;
    };

    useEffect(() => {
        if (values.auth0OrganizationName) {
            setUseAuth0Connection(false);
        } else if (values.connectionName) {
            setUseAuth0Connection(true);
        }
    }, [values]);

    return (
      <ComponentWrapper>
        <SectionHeader
                goBack={{
                    url: MPA_ROUTES.customers,
                    label() {
                        return (
                          <>
                            <ArrowBackIcon stroke={theme.color.textLabel} />
                            <div style={{ color: theme.color.primary }}>
                              Back
                            </div>
                          </>
                        );
                    },
                }}
                title="Edit Organization Profile"
            />
        <EditCustomerWrapper>
          <SectionItem>
            <Title>Organization data</Title>
            <InputGroupWrapper gridRows="1fr 1fr 1fr">
              <Input
                            name="organizationName"
                            placeholder="Organization name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.organizationName || ''}
                            error={
                                errors.organizationName &&
                                touched.organizationName
                                    ? errors.organizationName
                                    : null
                            }
                        />
              <Input
                            name="LMSApiKey"
                            placeholder="LMS API Key"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.LMSApiKey || ''}
                            error={
                                errors.LMSApiKey && touched.LMSApiKey
                                    ? errors.LMSApiKey
                                    : null
                            }
                        />
              <Input
                            name="LMSAddress"
                            placeholder="LMS Address"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.LMSAddress || ''}
                            error={
                                errors.LMSAddress && touched.LMSAddress
                                    ? errors.LMSAddress
                                    : null
                            }
                        />
              <Input
                            name="lmsOrgId"
                            placeholder="LMS Organization ID"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.lmsOrgId || ''}
                            error={
                                errors.lmsOrgId && touched.lmsOrgId
                                    ? errors.lmsOrgId
                                    : null
                            }
                        />
            </InputGroupWrapper>
          </SectionItem>
          {/* {values.connectionName && (
                    <SectionItem borderTop>
                        <Title>Auth0 database/enterprise connection</Title>
                        <InputGroupWrapper>
                            <Input
                                name="connectionName"
                                placeholder="Auth0 database/enterprise name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.connectionName || ''}
                                error={
                                    errors.connectionName &&
                                    touched.connectionName
                                        ? errors.connectionName
                                        : null
                                }
                            />
                        </InputGroupWrapper>
                    </SectionItem>
                )}
                {values.auth0OrganizationName && (
                    <SectionItem borderTop>
                        <Title>Auth0 organization name</Title>
                        <InputGroupWrapper>
                            <Input
                                name="auth0OrganizationName"
                                placeholder="Auth0 organization name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.auth0OrganizationName || ''}
                                error={
                                    errors.auth0OrganizationName &&
                                    touched.auth0OrganizationName
                                        ? errors.auth0OrganizationName
                                        : null
                                }
                            />
                        </InputGroupWrapper>
                    </SectionItem>
                )} */}
          <SectionItem borderTop>
            <Title>Auth0 configuration</Title>
            <div
                        onClick={() => {
                            // if (useAuth0Connection) {
                            //     setFieldValue('connectionName', undefined);
                            // } else {
                            //     setFieldValue(
                            //         'auth0OrganizationName',
                            //         undefined
                            //     );
                            // }

                            setUseAuth0Connection(!useAuth0Connection);
                        }}
                        style={{
                            cursor: 'pointer',
                            marginBottom: '16px',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
              <Checkbox
                            name="use_auth0_connection"
                            isSelected={useAuth0Connection}
                            toggleSelected={() =>
                                setUseAuth0Connection(!useAuth0Connection)}
                            customStyles={css`
                                margin-right: 8px;
                            `}
                        />
              {' '}
              <span>
                Use a single Database or Enterprise connection
                instead of an Organization.
              </span>
            </div>

            {useAuth0Connection ? (
              <>
                <InputGroupWrapper>
                  <Input
                                    name="connectionName"
                                    placeholder="Enter name of existing Auth0 Database or Enterprise connection."
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.connectionName || ''}
                                    error={
                                        errors.connectionName &&
                                        touched.connectionName
                                            ? errors.connectionName
                                            : null
                                    }
                                />
                </InputGroupWrapper>
              </>
                    ) : (
                      <InputGroupWrapper>
                        <Input
                                name="auth0OrganizationName"
                                placeholder="If not using default, enter name of existing Auth0 Organization."
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.auth0OrganizationName || ''}
                                error={
                                    errors.auth0OrganizationName &&
                                    touched.auth0OrganizationName
                                        ? errors.auth0OrganizationName
                                        : null
                                }
                            />
                      </InputGroupWrapper>
                    )}
          </SectionItem>
          <SectionItem borderTop row>
            <Title
                        customStyles={css`
                            margin: 0;
                            margin-right: 64px;
                        `}
                    >
              Performance Coaching
            </Title>
            <Switch
                        name="performanceCoachingEnabled"
                        defaultStatus={values.performanceCoachingEnabled}
                        externalStatus={values.performanceCoachingEnabled}
                        switchOn={() => {
                            setFieldValue('performanceCoachingEnabled', true);
                        }}
                        switchOff={() => {
                            setFieldValue('performanceCoachingEnabled', false);
                        }}
                        switchId="performanceCoachingSwitch"
                        withConfirmationModal
                        onConfirmModalLabel="Enable"
                        onConfirmModalDescription={`
                            Performance Coaching enables users to view personalized performance data and assigns performance-related Smart Tips to each user based on their individual results to help them reach their goals.
                        `}
                        offConfirmModalLabel="Disable"
                        offConfirmModalDescription={`
                            Performance Coaching enables users to view personalized performance data and assigns performance-related Smart Tips to each user based on their individual results to help them reach their goals.
                        `}
                        onConfirmModalTitle="Enable Performance Coaching?"
                        offConfirmModalTitle="Disable Performance Coaching?"
                    />
          </SectionItem>
          <SectionItem borderTop borderBottom>
            <Title>Address</Title>
            <InputGroupWrapper gridRows="1fr 1fr 1fr 1fr">
              <Input
                            name="country"
                            onBlur={handleBlur}
                            value={values.country || ''}
                            dropdownTitle="Countries"
                            variant="searchDropdown"
                            placeholder="Country"
                            onClick={() =>
                                setIsDropdownVisible(!isDropdownVisible)}
                            dropdownVisible={isDropdownVisible}
                            options={countries}
                            handleSelect={(val) => {
                                setFieldValue(
                                    'country',
                                    countries.find(
                                        (country) => country.id === val
                                    ).title
                                );
                                setIsDropdownVisible(false);
                            }}
                            error={
                                errors.country && touched.country
                                    ? errors.country
                                    : null
                            }
                            readOnly
                        />

              <InputWrapper>
                <Input
                                name="zipCode"
                                placeholder="Zip Code"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.zipCode || ''}
                                error={
                                    errors.zipCode && touched.zipCode
                                        ? errors.zipCode
                                        : null
                                }
                            />
              </InputWrapper>

              <InputWrapper>
                <Input
                                name="city"
                                placeholder="City"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.city || ''}
                                error={
                                    errors.city && touched.city
                                        ? errors.city
                                        : null
                                }
                            />
                <Input
                                name="state"
                                placeholder="State"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.state || ''}
                                error={
                                    errors.state && touched.state
                                        ? errors.state
                                        : null
                                }
                            />
              </InputWrapper>

              <Input
                            name="street"
                            placeholder="Street"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.street || ''}
                            error={
                                errors.street && touched.street
                                    ? errors.street
                                    : null
                            }
                        />
            </InputGroupWrapper>
          </SectionItem>
          <SectionItem>
            <Title>Contact</Title>
            <InputGroupWrapper gridRows="1fr 1fr 1fr">
              <Input
                            name="fullName"
                            placeholder="Full Name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.fullName || ''}
                            error={
                                errors.fullName && touched.fullName
                                    ? errors.fullName
                                    : null
                            }
                        />
              <Input
                            name="mobile"
                            placeholder="Mobile"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.mobile || ''}
                            error={
                                errors.mobile && touched.mobile
                                    ? errors.mobile
                                    : null
                            }
                        />
              <Input
                            name="emailAddress"
                            placeholder="Email address"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.emailAddress || ''}
                            error={
                                errors.emailAddress && touched.emailAddress
                                    ? errors.emailAddress
                                    : null
                            }
                        />
            </InputGroupWrapper>
            <BottomSectionWrapper>
              <Button
                            primaryDark
                            type="submit"
                            onClick={handleSubmit}
                            disabled={isButtonDisabled()}
                        >
                Save
              </Button>
              <Button
                            primary
                            onClick={() => history.push(MPA_ROUTES.customers)}
                        >
                Cancel
              </Button>
            </BottomSectionWrapper>
          </SectionItem>
        </EditCustomerWrapper>

        {perfomranceModalVisible && (
        <TogglePerformanceCoachingModal
                    perfomranceModalVisible={perfomranceModalVisible}
                    setPerformanceModalVisible={setPerformanceModalVisible}
                    setFieldValue={setFieldValue}
                />
            )}
      </ComponentWrapper>
    );
};

MPAEditCustomer.propTypes = {
    avatar: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.objectOf(PropTypes.any),
    ]),
    handleUpload: PropTypes.func.isRequired,
    countries: PropTypes.arrayOf(PropTypes.any),
    isDropdownVisible: PropTypes.bool.isRequired,
    setIsDropdownVisible: PropTypes.func.isRequired,
};

MPAEditCustomer.defaultProps = { countries: [], avatar: undefined };

export default MPAEditCustomer;
