import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useOffset from 'hooks/useOffset';
import { archiveTeamThunk, joinTeamThunk } from 'redux/actions/thunks/team';
import { leaveTeamThunk } from 'redux/actions/thunks/teamMembers';
import TeamView from 'views/Team';
import useFetch from 'hooks/useFetch';
import apiRoutes from 'config/apiRoutes';
import { useAuth0 } from '@auth0/auth0-react';

const INFINITE_SCROLL_THRESHOLD = 20;

const Team = () => {
    const { getAccessTokenSilently, logout } = useAuth0();
    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.currentUser);
    const [offset, incrementOffset] = useOffset(INFINITE_SCROLL_THRESHOLD);
    const [hasMore, setHasMore] = useState(true);
    const [teamsEstablished, setTeamsEstablished] = useState(false);
    const [teamsFetching, setTeamsFetching] = useState(false);
    const [teams, setTeams] = useState([]);

    const request = useFetch(getAccessTokenSilently, logout);

    const joinTeam = (id) => {
        dispatch(joinTeamThunk(getAccessTokenSilently, logout, id));
    };

    const leaveTeam = (id) => {
        dispatch(leaveTeamThunk(getAccessTokenSilently, logout, id));
    };

    const archiveTeam = (id) => {
        dispatch(archiveTeamThunk(getAccessTokenSilently, logout, id));
    };

    const loadMore = () => {
        if (teamsFetching) {
            return;
        }

        setTeamsFetching(true);
        request(
            apiRoutes.get.teams(INFINITE_SCROLL_THRESHOLD, offset),
            'GET',
            (res) => {
                if (res.teams && res.teams.length < INFINITE_SCROLL_THRESHOLD) {
                    setHasMore(false);
                }
                setTeams([...teams, ...res.teams]);
                setTeamsFetching(false);
                setTeamsEstablished(true);
            },
            () => {},
            {},
            { 'Content-Type': 'application/json' }
        );

        incrementOffset();
    };

    useEffect(() => {
        setTeamsFetching(true);
        request(
            apiRoutes.get.teams(INFINITE_SCROLL_THRESHOLD, offset),
            'GET',
            (res) => {
                if (res.teams && res.teams.length < INFINITE_SCROLL_THRESHOLD) {
                    setHasMore(false);
                }
                setTeams(res.teams);
                setTeamsFetching(false);
                setTeamsEstablished(true);
            },
            () => {},
            {},
            { 'Content-Type': 'application/json' }
        );

        incrementOffset();
    }, []);

    return (
      <TeamView
            hasEstablished={teamsEstablished}
            isFetching={teamsFetching}
            teams={teams}
            loadFunc={loadMore}
            currentUser={currentUser}
            hasMore={hasMore}
            joinTeam={joinTeam}
            leaveTeam={leaveTeam}
            archiveTeam={archiveTeam}
        />
    );
};

export default Team;
