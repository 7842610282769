import React from 'react';
import NoResults from 'components/EmptyState/NoResults';

const PageNotFound = () => (
  <NoResults
        fullHeight
        pageNotFound
        title="Page not found"
        message="We are sorry, page you were looking for couldn’t be found. Please go back to the dashboard o try again."
    />
);

export default PageNotFound;
