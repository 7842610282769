import Input from 'components/Input';
import styled, { css } from 'styled-components/macro';

export const AdminWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    ${({ fullHeight }) =>
        fullHeight &&
        css`
            flex-direction: column;
            min-height: calc(100vh - 250px);
        `}
`;

export const AdminSection = styled.div`
    width: 100%;

    ${({ withPadding }) =>
        withPadding &&
        css`
            padding: 0 16px;
        `}

    ${({ withSpacing }) =>
        withSpacing &&
        css`
            margin: 16px 0;
        `}

    ${({ withOverflowScroll }) =>
        withOverflowScroll &&
        css`
            @media (max-width: ${({ theme }) =>
                    `${theme.breakpoints.small}px`}) {
                overflow-x: scroll;

                > div {
                    width: fit-content;
                    min-width: 700px;
                }
            }
        `}
`;

export const Filters = styled.div`
    padding: 16px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    p {
        color: ${({ theme }) => theme.color.textLight};
        margin-right: 16px;
        font-weight: 500;
    }

    input {
        color: ${({ theme }) => theme.color.textBase};
        width: auto;
        max-width: 210px;
    }
`;

export const StyledInput = styled(Input)`
    background-color: ${({ theme }) => theme.color.bgBase};

    &::placeholder {
        color: ${({ theme }) => theme.color.textLight};
    }
`;

export const CTA = styled.div`
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    p {
        margin-right: 20px;
        color: ${({ theme }) => theme.color.textLight};
        font-weight: 500;
    }
`;

export const ModalBody = styled.div`
    padding: 32px;
`;
