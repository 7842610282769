export const ESTABLISH_ORGANIZATION_ASSIGN_SMART_TIP_ASSIGNED_USERS =
    'ESTABLISH_ORGANIZATION_ASSIGN_SMART_TIP_ASSIGNED_USERS';
export const ESTABLISH_ORGANIZATION_ASSIGN_SMART_TIP_ASSIGNED_USERS_SUCCESS =
    'ESTABLISH_ORGANIZATION_ASSIGN_SMART_TIP_ASSIGNED_USERS_SUCCESS';
export const ESTABLISH_ORGANIZATION_ASSIGN_SMART_TIP_ASSIGNED_USERS_FAILURE =
    'ESTABLISH_ORGANIZATION_ASSIGN_SMART_TIP_ASSIGNED_USERS_FAILURE';
export const ESTABLISH_ORGANIZATION_ASSIGN_SMART_TIP_ASSIGNED_TEAMS =
    'ESTABLISH_ORGANIZATION_ASSIGN_SMART_TIP_ASSIGNED_TEAMS';
export const ESTABLISH_ORGANIZATION_ASSIGN_SMART_TIP_ASSIGNED_TEAMS_SUCCESS =
    'ESTABLISH_ORGANIZATION_ASSIGN_SMART_TIP_ASSIGNED_TEAMS_SUCCESS';
export const ESTABLISH_ORGANIZATION_ASSIGN_SMART_TIP_ASSIGNED_TEAMS_FAILURE =
    'ESTABLISH_ORGANIZATION_ASSIGN_SMART_TIP_ASSIGNED_TEAMS_FAILURE';
export const MARK_ALL_USERS_LOADED = 'MARK_ALL_USERS_LOADED';
export const LOAD_MORE_ESTABLISH_ORGANIZATION_ASSIGN_SMART_TIP_ASSIGNED_USERS_SUCCESS =
    'LOAD_MORE_ESTABLISH_ORGANIZATION_ASSIGN_SMART_TIP_ASSIGNED_USERS_SUCCESS';
export const RESET_USERS_AND_TEAMS_LISTS = 'RESET_USERS_AND_TEAMS_LISTS';
export const STOP_LOADING_ASSIGN_SMART_TIP_USER =
    'STOP_LOADING_ASSIGN_SMART_TIP_USER';
export const LOADING_ORGANIZATION_USERS = 'LOADING_ORGANIZATION_USERS';

export const establishOrganizationAssignSmartTipAssignedUsers = () => ({
    type: ESTABLISH_ORGANIZATION_ASSIGN_SMART_TIP_ASSIGNED_USERS,
});
export const establishOrganizationAssignSmartTipAssignedUsersSuccess = (
    payload
) => ({
    type: ESTABLISH_ORGANIZATION_ASSIGN_SMART_TIP_ASSIGNED_USERS_SUCCESS,
    payload,
});
export const establishOrganizationAssignSmartTipAssignedUsersFailure = (
    err
) => ({
    type: ESTABLISH_ORGANIZATION_ASSIGN_SMART_TIP_ASSIGNED_USERS_FAILURE,
    err,
});

export const establishOrganizationAssignSmartTipAssignedTeams = () => ({
    type: ESTABLISH_ORGANIZATION_ASSIGN_SMART_TIP_ASSIGNED_TEAMS,
});
export const establishOrganizationAssignSmartTipAssignedTeamsSuccess = (
    payload
) => ({
    type: ESTABLISH_ORGANIZATION_ASSIGN_SMART_TIP_ASSIGNED_TEAMS_SUCCESS,
    payload,
});
export const establishOrganizationAssignSmartTipAssignedTeamsFailure = (
    err
) => ({
    type: ESTABLISH_ORGANIZATION_ASSIGN_SMART_TIP_ASSIGNED_TEAMS_FAILURE,
    err,
});

export const loadMoreEstablishOrganizationAssignSmartTipAssignedUsersSuccess = (
    payload
) => ({
    type: LOAD_MORE_ESTABLISH_ORGANIZATION_ASSIGN_SMART_TIP_ASSIGNED_USERS_SUCCESS,
    payload,
});
export const stopLoadingAssignSmartTipUser = ({ payload }) => ({
    type: STOP_LOADING_ASSIGN_SMART_TIP_USER,
    payload,
});
export const resetUsersAndTeamsLists = () => ({
    type: RESET_USERS_AND_TEAMS_LISTS,
});
export const isLoading = (payload) => ({
    type: LOADING_ORGANIZATION_USERS,
    payload,
});

export const allUsersLoaded = () => ({
    type: MARK_ALL_USERS_LOADED,
});
