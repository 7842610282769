export const ESTABLISH_TEAM_MEMBERS = 'ESTABLISH_TEAM_MEMBERS';
export const ESTABLISH_TEAM_MEMBERS_SUCCESS = 'ESTABLISH_TEAM_MEMBERS_SUCCESS';
export const ESTABLISH_TEAM_MEMBERS_FAILURE = 'ESTABLISH_TEAM_MEMBERS_FAILURE';
export const RESET_TEAM_MEMBERS = 'RESET_TEAM_MEMBERS';
export const REMOVE_TEAM_MEMBER = 'REMOVE_TEAM_MEMBER';
export const REMOVE_TEAM_MEMBER_SUCCESS = 'REMOVE_TEAM_MEMBER_SUCCESS';
export const REMOVE_TEAM_MEMBER_FAILURE = 'REMOVE_TEAM_MEMBER_FAILURE';
export const LEAVE_TEAM = 'LEAVE_TEAM';
export const LEAVE_TEAM_SUCCESS = 'LEAVE_TEAM_SUCCESS';
export const LEAVE_TEAM_FAILURE = 'LEAVE_TEAM_FAILURE';

export const establishMembers = () => ({
    type: ESTABLISH_TEAM_MEMBERS,
});

export const establishMembersSuccess = (payload) => ({
    type: ESTABLISH_TEAM_MEMBERS_SUCCESS,
    payload,
});

export const establishMembersFailure = (err) => ({
    type: ESTABLISH_TEAM_MEMBERS_FAILURE,
    err,
});

export const resetMembers = () => ({
    type: RESET_TEAM_MEMBERS,
});

export const removeMember = () => ({
    type: REMOVE_TEAM_MEMBER,
});

export const removeMemberSuccess = () => ({
    type: REMOVE_TEAM_MEMBER_SUCCESS,
});

export const removeMemberFailure = (err) => ({
    type: REMOVE_TEAM_MEMBER_FAILURE,
    err,
});

export const leaveTeam = () => ({
    type: LEAVE_TEAM,
});

export const leaveTeamSuccess = () => ({
    type: LEAVE_TEAM_SUCCESS,
});

export const leaveTeamFailure = (err) => ({
    type: LEAVE_TEAM_FAILURE,
    err,
});
