import styled, { css } from 'styled-components/macro';

export const TeamOverviewWrapper = styled.div`
    display: block;
    position: relative;
    width: 100%;
    min-height: calc(100vh - 122px);
    padding: 0 16px;
    box-sizing: border-box;
`;

export const TeamOverviewNavigation = styled.div`
    display: flex;
    align-items: center;
`;

export const TeamOverviewNavigationItems = styled.div`
    display: flex;
    align-items: center;
    padding-right: 16px;
    margin-right: 32px;

    border-right: ${({ theme }) => theme.border.base};
`;

export const TeamOverviewItem = styled.span`
    padding: 10px 16px;
    cursor: pointer;

    border-radius: ${({ theme }) => theme.border.radiusBase};
    color: ${({ theme }) => theme.color.textLight};

    ${({ isActive }) =>
        isActive &&
        css`
            color: ${({ theme }) => theme.color.primary};
            background-color: ${({ theme }) => theme.color.bgPrimaryLight};
        `};
`;

export const TeamOverviewHolder = styled.div`
    position: relative;
    width: 100%;
    height: calc(100vh - 243px);
    display: grid;
    grid-gap: 16px;
    grid-template-columns: 1fr 3fr;

    @media (max-width: ${({ theme }) => `${theme.breakpoints.medium}px`}) {
        display: flex;
        flex-direction: column;
        height: auto;
    }
`;

export const TeamOverviewSidebar = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    height: inherit;

    ${({ theme }) => css`
        background-color: ${theme.color.white};
        border: ${theme.border.base};
        border-radius: ${theme.border.radiusBase};
    `}

    @media (max-width: ${({ theme }) => `${theme.breakpoints.s}px`}) {
        width: auto;
    }
`;

export const SidebarHeader = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 16px 0;

    ${({ theme }) => css`
        border-bottom: ${theme.border.base};
        border-top-right-radius: ${theme.border.radiusBase};
        border-top-left-radius: ${theme.border.radiusBase};
    `}
`;

export const SidebarHeaderItem = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 16px 24px;
`;

export const SidebarHeaderItemTitle = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    h3 {
        margin: 0 0 8px 0;
        padding: 0;
        font-weight: 500;
        line-height: 1;
        font-size: ${({ theme }) => theme.fontSize.normal};
    }

    span {
        line-height: 1;
        color: ${({ theme }) => theme.color.textLight};
    }
`;

export const RecentlyShared = styled.div`
    position: relative;
    height: calc(100vh - 420px);
    padding: 16px;
`;

export const RecentlySharedBody = styled.div`
    position: relative;
    height: calc(100vh - 490px);
    overflow: auto;
`;

export const RecentlySharedTitle = styled.span`
    color: ${({ theme }) => theme.color.textLight};
    line-height: 1;
    display: block;
    margin: 8px 8px 16px;
`;

export const RecentlySharedContent = styled.div`
    height: 360px;
    overflow-y: auto;
`;

export const RecentlySharedItem = styled.span`
    display: flex;
    align-items: center;
    padding: 16px;
    margin-bottom: 8px;

    background-color: ${({ theme }) => theme.color.bgPrimarySuperLight};
    border-radius: ${({ theme }) => theme.border.radiusBase};

    span {
        color: ${({ theme }) => theme.color.textLight};
    }
`;

export const RecentlySharedIcon = styled.span`
    width: 32px;
    height: 32px;
    border-radius: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    background-color: ${({ theme }) => theme.color.bgPrimaryLight};

    ${({ isActive }) =>
        isActive &&
        css`
            background-color: ${({ theme }) => theme.color.primary};
        `}
`;

export const RightColumn = styled.div`
    width: 100%;
    height: inherit;
    display: flex;
    flex-direction: column;

    @media (max-width: ${({ theme }) => `${theme.breakpoints.medium}px`}) {
        margin-top: 32px;
    }
`;
