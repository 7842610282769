import {
    ESTABLISH_TEAM_OVERVIEW,
    ESTABLISH_TEAM_OVERVIEW_SUCCESS,
    ESTABLISH_TEAM_OVERVIEW_FAILURE,
    ESTABLISH_TEAM_RECENTLY_SHARED,
    ESTABLISH_TEAM_RECENTLY_SHARED_SUCCESS,
    ESTABLISH_TEAM_RECENTLY_SHARED_FAILURE,
    ESTABLISH_TEAM_STATS,
    ESTABLISH_TEAM_STATS_SUCCESS,
    ESTABLISH_TEAM_STATS_FAILURE,
    FETCH_MESSAGES,
    FETCH_MESSAGES_SUCCESS,
    FETCH_MESSAGES_FAILURE,
    FETCH_PREVIOUS_MESSAGES,
    FETCH_PREVIOUS_MESSAGES_SUCCESS,
    FETCH_PREVIOUS_MESSAGES_FAILURE,
    DELETE_MESSAGE,
    DELETE_MESSAGE_SUCCESS,
    DELETE_MESSAGE_FAILURE,
    LIKE_MESSAGE,
    UNLIKE_MESSAGE,
    UPDATE_SINGLE_MESSAGE,
    ESTABLISH_TEAM_DETAILS,
    ESTABLISH_TEAM_DETAILS_SUCCESS,
    ESTABLISH_TEAM_DETAILS_FAILURE,
} from 'redux/actions/teamOverview';

export const initialState = {
    isTeamOverviewEstablished: false,
    isTeamOverviewFetching: false,
    isTeamOverviewError: false,
    error: null,
    details: {
        areTeamDetailsEstablished: false,
        areTeamDetailsFetching: false,
        error: null,
        items: {},
    },
    stats: {
        areStatsEstablished: false,
        areStatsFetching: false,
        isStatsError: false,
        items: {},
        error: null,
    },
    recentlyShared: {
        areRecentlySharedEstablished: false,
        areRecentlySharedFetching: false,
        isRecentlySharedError: false,
        items: [],
        error: null,
    },
    messages: {
        areMessagesEstablished: false,
        areMessagesFetching: false,
        isMessagesError: false,
        items: [],
        error: null,
    },
};

const teamOverview = (state = initialState, action) => {
    switch (action.type) {
        case ESTABLISH_TEAM_OVERVIEW:
            return {
                ...state,
                isTeamOverviewFetching: true,
                isTeamOverviewError: false,
            };

        case ESTABLISH_TEAM_OVERVIEW_SUCCESS:
            return {
                ...state,
                isTeamOverviewFetching: false,
                isTeamOverviewEstablished: true,
            };

        case ESTABLISH_TEAM_OVERVIEW_FAILURE:
            return {
                ...state,
                isTeamOverviewFetching: false,
                isTeamOverviewError: true,
                error: action.err,
            };

        case ESTABLISH_TEAM_RECENTLY_SHARED:
            return {
                ...state,
                recentlyShared: {
                    ...state.recentlyShared,
                    areRecentlySharedEstablished: false,
                    areRecentlySharedFetching: true,
                    isRecentlySharedError: false,
                },
            };

        case ESTABLISH_TEAM_RECENTLY_SHARED_SUCCESS:
            return {
                ...state,
                recentlyShared: {
                    ...state.recentlyShared,
                    areRecentlySharedEstablished: true,
                    areRecentlySharedFetching: false,
                    isRecentlySharedError: false,
                    items: action.payload,
                },
            };

        case ESTABLISH_TEAM_RECENTLY_SHARED_FAILURE:
            return {
                ...state,
                recentlyShared: {
                    ...state.recentlyShared,
                    areRecentlySharedEstablished: false,
                    areRecentlySharedFetching: false,
                    isRecentlySharedError: true,
                    error: action.err,
                },
            };

        case ESTABLISH_TEAM_STATS:
            return {
                ...state,
                stats: {
                    ...state.stats,
                    areStatsEstablished: false,
                    areStatsFetching: true,
                    isStatsError: false,
                },
            };

        case ESTABLISH_TEAM_STATS_SUCCESS:
            return {
                ...state,
                stats: {
                    ...state.stats,
                    areStatsEstablished: true,
                    areStatsFetching: false,
                    isStatsError: false,
                    items: action.payload,
                },
            };

        case ESTABLISH_TEAM_STATS_FAILURE:
            return {
                ...state,
                stats: {
                    ...state.stats,
                    areStatsEstablished: false,
                    areStatsFetching: false,
                    isStatsError: true,
                    error: action.err,
                },
            };

        case FETCH_MESSAGES:
            return {
                ...state,
                messages: {
                    ...state.messages,
                    areMessagesEstablished: false,
                    areMessagesFetching: true,
                    isMessagesError: false,
                },
            };

        case FETCH_MESSAGES_SUCCESS:
            return {
                ...state,
                messages: {
                    ...state.messages,
                    areMessagesEstablished: true,
                    areMessagesFetching: false,
                    isMessagesError: false,
                    items: action.payload,
                },
            };

        case FETCH_MESSAGES_FAILURE:
            return {
                ...state,
                messages: {
                    ...state.messages,
                    areMessagesEstablished: false,
                    areMessagesFetching: false,
                    isMessagesError: true,
                    error: action.err,
                },
            };

        case DELETE_MESSAGE:
            return {
                ...state,
                messages: {
                    ...state.messages,
                    areMessagesEstablished: false,
                    areMessagesFetching: true,
                },
            };

        case DELETE_MESSAGE_SUCCESS:
            return {
                ...state,
                messages: {
                    ...state.messages,
                    areMessagesEstablished: true,
                    areMessagesFetching: false,
                    items: state.messages.items.filter(
                        (msg) => msg.id !== action.payload
                    ),
                },
            };

        case DELETE_MESSAGE_FAILURE:
            return {
                ...state,
                messages: {
                    ...state.messages,
                    areMessagesEstablished: false,
                    areMessagesFetching: false,
                    isMessagesError: true,
                    error: action.err,
                },
            };

        case FETCH_PREVIOUS_MESSAGES:
            return {
                ...state,
                messages: {
                    ...state.messages,
                    areMessagesEstablished: false,
                    areMessagesFetching: true,
                    isMessagesError: false,
                },
            };

        case FETCH_PREVIOUS_MESSAGES_SUCCESS:
            return {
                ...state,
                messages: {
                    ...state.messages,
                    areMessagesEstablished: true,
                    areMessagesFetching: false,
                    isMessagesError: false,
                    items: [...state.messages.items, ...action.payload],
                },
            };

        case FETCH_PREVIOUS_MESSAGES_FAILURE:
            return {
                ...state,
                messages: {
                    ...state.messages,
                    areMessagesEstablished: false,
                    areMessagesFetching: false,
                    isMessagesError: true,
                },
            };

        case LIKE_MESSAGE:
            return {
                ...state,
                messages: {
                    ...state.messages,
                    items: state.messages.items.map((item) => {
                        if (item.id === action.payload) {
                            return {
                                ...item,
                                liked: true,
                            };
                        }

                        return item;
                    }),
                },
            };

        case UNLIKE_MESSAGE:
            return {
                ...state,
                messages: {
                    ...state.messages,
                    items: state.messages.items.map((item) => {
                        if (item.id === action.payload) {
                            return {
                                ...item,
                                liked: false,
                            };
                        }

                        return item;
                    }),
                },
            };

        case UPDATE_SINGLE_MESSAGE:
            return {
                ...state,
                messages: {
                    ...state.messages,
                    items: state.messages.items.map((item) => {
                        if (item.id === action.payload.id) {
                            return {
                                ...action.payload,
                            };
                        }

                        return item;
                    }),
                },
            };

        case ESTABLISH_TEAM_DETAILS:
            return {
                ...state,
                details: {
                    ...state.details,
                    areTeamDetailsEstablished: false,
                    areTeamDetailsFetching: true,
                },
            };

        case ESTABLISH_TEAM_DETAILS_SUCCESS:
            return {
                ...state,
                details: {
                    ...state.details,
                    areTeamDetailsEstablished: true,
                    areTeamDetailsFetching: false,
                    items: action.payload,
                },
            };

        case ESTABLISH_TEAM_DETAILS_FAILURE:
            return {
                ...state,
                details: {
                    ...state.details,
                    areTeamDetailsEstablished: false,
                    areTeamDetailsFetching: false,
                    error: action.err,
                },
            };

        default:
            return state;
    }
};

export default teamOverview;
