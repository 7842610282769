import styled, { css } from 'styled-components/macro';

export const MetricContainer = styled.div`
    width: 100%;
    padding: 16px 32px;
    display: grid;
    grid-gap: 16px;
    grid-template-columns: 4fr 2fr 1fr 2fr 1fr;
    align-items: center;

    ${({ theme }) => css`
        background-color: ${theme.color.white};
        border-bottom: ${theme.border.base};
    `};

    ${({ withoutBorder }) =>
        withoutBorder &&
        css`
            border: none !important;
        `}

    span {
        text-overflow: ellipsis;
        overflow: hidden;
    }

    &:last-child {
        border: none !important;
    }

    .bin {
        svg {
            display: none;
        }
    }

    &:hover {
        .bin {
            svg {
                display: flex;
            }
        }
    }

    @media (max-width: ${({ theme }) => `${theme.breakpoints.medium}px`}) {
        min-width: 500px;
        width: fit-content;
    }
`;

export const MetricContainerMPA = styled(MetricContainer)`
    grid-template-columns: 7fr 7fr 3fr 3fr 1fr;
`;

export const AvgLabel = styled.span`
    color: ${({ theme }) => theme.color.textLight};
    text-overflow: ellipsis;
    overflow: hidden;
`;

export const EnabledSection = styled.span`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
`;

export const EnabledIndicator = styled.div`
    display: block;
    width: 14px;
    height: 14px;
    border-radius: 50px;
    background-color: ${({ theme }) => theme.color.tertiary};
    margin-right: 10px;

    ${({ enabled }) =>
        enabled &&
        css`
            background-color: ${({ theme }) => theme.color.secondary};
        `}
`;

export const EnabledLabel = styled.div`
    color: ${({ theme }) => theme.color.tertiary};

    ${({ enabled }) =>
        enabled &&
        css`
            color: ${({ theme }) => theme.color.secondary};
        `}
`;

export const InputWrapper = styled.div`
    display: grid;
    grid-template-columns: 10fr 1fr;
    align-items: center;
`;

export const IconWrapper = styled.div`
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 49px;
    height: 49px;
    border-radius: ${({ theme }) => theme.border.radiusBase};
    cursor: pointer;

    &:hover {
        background-color: ${({ theme }) => theme.color.bgPrimaryLight};
    }
`;
