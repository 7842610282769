import React, { useState } from 'react';
import PropTypes from 'prop-types';
import downloadBlob from 'redux/helpers/downloadBlob';
import { ReactComponent as BinIcon } from 'assets/svg/bin.svg';
import { ReactComponent as ProcessedIcon } from 'assets/svg/upload-processed.svg';
import { ReactComponent as ProcessingIcon } from 'assets/svg/upload-processing.svg';
import { ReactComponent as ErrorIcon } from 'assets/svg/upload-error.svg';
import { ReactComponent as DownloadIcon } from 'assets/svg/download-thick-bottom.svg';
import ConfirmActionModal from 'components/ConfirmActionModal';
import useDate, { DATE_TYPES } from 'hooks/useDate';
import { useAuth0 } from '@auth0/auth0-react';
import {
    MetricUploadItemHolder,
    DateHolder,
    StatusHolder,
    BinIconHolder,
    TableHeaderHolder,
    DownloadIconPositioner,
} from './MetricUploadItem.style';

const getUploadStatus = (status) => {
    if (status === 'error') {
        return <ErrorIcon />;
    }

    if (status === 'processing') {
        return <ProcessingIcon />;
    }

    return <ProcessedIcon />;
};

export const MetricUploadsTableHeader = ({
    firstColumnLabel,
    secondColumnLabel,
    thirdColumnLabel,
}) => (
  <TableHeaderHolder>
    <span>{firstColumnLabel}</span>
    <span>{secondColumnLabel}</span>
    <span>{thirdColumnLabel}</span>
  </TableHeaderHolder>
);

MetricUploadsTableHeader.propTypes = {
    firstColumnLabel: PropTypes.string,
    secondColumnLabel: PropTypes.string,
    thirdColumnLabel: PropTypes.string,
};

MetricUploadsTableHeader.defaultProps = {
    firstColumnLabel: 'Filename',
    secondColumnLabel: 'Upload Date',
    thirdColumnLabel: 'Status',
};

const MetricUploadItem = ({
    id,
    createdAt,
    fileName,
    status,
    onDelete,
    errorsFileUrl,
}) => {
    const { getAccessTokenSilently, logout } = useAuth0();
    const [
        isConfirmLeaveModalVisible,
        setIsConfirmLeaveModalVisible,
    ] = useState(false);

    const creationDate = new Date(createdAt);

    const downloadFile = () => {
        getAccessTokenSilently()
            .then((token) => {
                fetch(errorsFileUrl, {
                    method: 'GET',
                    headers: {
                        organization_slug: window.localStorage.getItem(
                            'MP_ORGANIZATION'
                        ),
                        Authorization: `Token token="${token}"`,
                    },
                })
                    .then((res) => res.blob())
                    .then((res) => downloadBlob(res, `error-${fileName}`));
            })
            .catch(() => logout({ returnTo: window.location.origin }));
    };

    return (
      <MetricUploadItemHolder id={id}>
        <ConfirmActionModal
                isVisible={isConfirmLeaveModalVisible}
                onToggle={() => {
                    setIsConfirmLeaveModalVisible(!isConfirmLeaveModalVisible);
                }}
                onConfirm={onDelete}
                description="Are you sure you want to delete this record?"
                confirmLabel="Delete record"
            />
        <strong>{fileName}</strong>
        <DateHolder>{useDate(creationDate, DATE_TYPES.long)}</DateHolder>
        <StatusHolder>
          {getUploadStatus(status)}

          {status === 'error' && !errorsFileUrl ? (
            <>Faulty file error</>
                ) : (
                  <>{status.charAt(0).toUpperCase() + status.slice(1)}</>
                )}
        </StatusHolder>
        <BinIconHolder>
          {status === 'error' && errorsFileUrl && (
            <DownloadIconPositioner>
              <DownloadIcon onClick={() => downloadFile()} />
            </DownloadIconPositioner>
                )}
          <BinIcon
                    onClick={() =>
                        setIsConfirmLeaveModalVisible(
                            !isConfirmLeaveModalVisible
                        )}
                />
        </BinIconHolder>
      </MetricUploadItemHolder>
    );
};

MetricUploadItem.propTypes = {
    id: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    fileName: PropTypes.string.isRequired,
    errorsFileUrl: PropTypes.string,
    status: PropTypes.string.isRequired,
    onDelete: PropTypes.func.isRequired,
};

MetricUploadItem.defaultProps = {
    errorsFileUrl: undefined,
};

export default MetricUploadItem;
