import styled, { css } from 'styled-components/macro';

export const TeamOverviewMessagesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    position: relative;

    @media (max-width: ${({ theme }) => `${theme.breakpoints.l}px`}) {
        width: auto;
    }
`;

export const SingleMessage = styled.div`
    display: flex;
    margin-bottom: 16px;
    padding: 16px;
    background-color: ${({ theme }) => theme.color.white};
    border-radius: ${({ theme }) => theme.border.radiusBase};
    border: ${({ theme }) => theme.border.base};
    transition: box-shadow 0.2s ease;

    &:hover {
        .likeCounter {
            color: ${({ theme }) => theme.color.quaternary};
        }
    }
`;

export const SingleMessageAvatar = styled.div`
    margin: 8px;
    width: 40px;

    img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
    }
`;

export const SingleMessageContent = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 8px 0 16px;
    width: 100%;
`;

export const SingleMessageTitle = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
        font-size: ${({ theme }) => theme.fontSize.base};
    }

    span {
        color: ${({ theme }) => theme.color.textLight};
    }
`;

export const SingleMessageDescription = styled.div`
    width: 90%;
    margin: 8px 0;

    span {
        color: ${({ theme }) => theme.color.textNavigationCounter};
        line-height: 1.7;
    }
`;

export const HeartCounter = styled.div`
    display: flex;
    align-items: center;
    margin-right: auto;
    cursor: pointer;
    svg,
    span {
        user-select: none;
    }

    svg {
        margin-right: 8px;
    }

    span {
        font-weight: 500;
        color: ${({ theme }) => theme.color.textLight};

        ${({ isSelected }) =>
            isSelected &&
            css`
                color: ${({ theme }) => theme.color.quaternary};
            `};
    }
`;

export const SingleMessageNavigation = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 8px 0;
    height: 18px;

    svg {
        max-height: 18px;
        max-width: 18px;

        &:not(:first-child) {
            margin-left: 8px;
        }
    }
`;

export const SingleNotification = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    padding: 0 16px;
`;

export const SingleNotificationContent = styled.span`
    margin: 8px 16px;
    font-weight: 500;
    color: ${({ theme }) => theme.color.textLight};
`;

export const SingleNotificationDate = styled.span`
    margin: 8px;
    margin-left: auto;
    color: ${({ theme }) => theme.color.textLight};
`;

export const Splash = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
`;
