import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import apiRoutes from 'config/apiRoutes';
import useFetch from 'hooks/useFetch';
import CreateSmartTipView from 'views/CreateSmartTip';
import { ROUTES, HA_ROUTES } from 'routing/constants';
import { useAuth0 } from '@auth0/auth0-react';
import {
    dispatchErrorAlertThunk,
    dispatchSuccessAlertThunk,
} from 'redux/actions/thunks/systemAlerts';
import { useDispatch } from 'react-redux';

const statuses = [
    {
        id: 'published',
        title: 'Published',
    },
    {
        id: 'unpublished',
        title: 'Unpublished',
    },
    {
        id: 'review',
        title: 'Review',
    },
];

const EditSmartTip = () => {
    const { getAccessTokenSilently, logout } = useAuth0();
    const dispatch = useDispatch();
    const [isValid, setIsValid] = useState(false);
    const [status, setStatus] = useState(null); // published / unpublished
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [classificationTags, setClassificationTags] = useState({
        role_job: [],
        department: [],
    });
    const [vimeoId, setVimeoId] = useState('');
    const [assets, setAssets] = useState([]);
    const [asset, setAsset] = useState(null);
    const [assetUrl, setAssetUrl] = useState(null);
    const [searchRestricted, setSearchRestricted] = useState(null);

    const [nameValid, setNameValid] = useState(false);
    const [descriptionValid, setDescriptionValid] = useState(false);
    const [vimeoIdValid, setVimeoIdValid] = useState(false);
    const [assetValid, setAssetValid] = useState(false);
    const [applayAllTags, setApplayAllTags] = useState(false);
    const [classificationTagIds, setClassificationTagIds] = useState([]);

    const request = useFetch(getAccessTokenSilently, logout);
    const history = useHistory();
    const { smartTipId } = useParams();

    useEffect(() => {
        request(
            apiRoutes.get.organizationAssets,
            'GET',
            (res) => setAssets(res.assets),
            () => {}
        );

        request(
            apiRoutes.get.organizationSmartTipDetails(smartTipId),
            'GET',
            (res) => {
                setApplayAllTags(res.assigned_to_all);
                setName(res.name);
                setDescription(res.description || '');
                setStatus(statuses.find((stat) => stat.id === res.status));
                setAssetUrl(res.image_url);
                setClassificationTags(res.classification_tags);
                setVimeoId(res.source_id || '');
                setIsValid(true);
                setSearchRestricted(res.search_restricted);
            },
            () => {}
        );
    }, []);

    useEffect(() => {
        if (assets.length && assetUrl) {
            setAsset(assets.find((ast) => ast.url === assetUrl));
        }
    }, [assets, assetUrl]);

    useEffect(() => {
        if (description && description.length > 245) {
            return setDescriptionValid(false);
        }
        return setDescriptionValid(true);
    }, [description]);

    useEffect(() => {
        if (!name || name.length < 3 || name.length > 70) {
            return setNameValid(false);
        }
        return setNameValid(true);
    }, [name]);

    useEffect(() => {
        if (!vimeoId || vimeoId.toString().length < 6) {
            return setVimeoIdValid(false);
        }
        return setVimeoIdValid(true);
    }, [vimeoId]);

    useEffect(() => {
        if (!asset) {
            return setAssetValid(false);
        }
        return setAssetValid(true);
    }, [asset]);

    useEffect(() => {
        if (nameValid && vimeoIdValid && assetValid) {
            return setIsValid(true);
        }
        return setIsValid(false);
    }, [nameValid, vimeoIdValid, assetValid]);

    const updateField = (type, value) => {
        switch (type) {
            case 'status':
                setStatus(value);
                break;
            case 'name':
                setName(value);
                break;
            case 'description':
                setDescription(value);
                break;
            case 'vimeoId':
                setVimeoId(value);
                break;
            case 'asset':
                setAsset(value);
                break;
            default:
                break;
        }
    };

    const saveTags = () => {
        request(
            apiRoutes.post.assignClassificationTagsToSmartTip(smartTipId),
            'POST',
            (res) => {
                setClassificationTags(res.classification_tags);
            },
            (err) => {
                dispatch(
                    dispatchErrorAlertThunk(
                        'saveClassificationTreeError',
                        err.error
                    )
                );
            },
            {
                body: JSON.stringify({
                    classification_tag_ids: classificationTagIds,
                }),
            },
            { 'Content-Type': 'application/json' }
        );
    };

    const updateOrganizationSmartTip = () => {
        request(
            apiRoutes.put.updateOrganizationSmartTip(smartTipId),
            'PUT',
            (res) => {
                if (!res.error) {
                    dispatch(
                        dispatchSuccessAlertThunk(
                            'smartTipEdited',
                            'Smart Tip edited'
                        )
                    );
                    saveTags();
                    history.push(ROUTES.smartTips);
                }
            },
            (err) => {
                dispatch(dispatchErrorAlertThunk(err, err.error));
            },
            {
                body: JSON.stringify({
                    smart_tip: {
                        name,
                        description,
                        source_id: vimeoId,
                        status: status.id,
                        assigned_to_all: applayAllTags,
                        asset_id: asset.id ? asset.id : '',
                        search_restricted: searchRestricted,
                    },
                }),
            },
            {
                'Content-Type': 'application/json',
            }
        );
    };

    const handleCancel = () => history.push(ROUTES.smartTips);
    const handleSubmit = () => {
        updateOrganizationSmartTip();
    };

    const deleteSmartTip = () => {
        request(
            apiRoutes.delete.deleteSmartTip(smartTipId),
            'DELETE',
            () => {
                dispatch(
                    dispatchSuccessAlertThunk(
                        'smartTipDeleted',
                        'Smart Tip deleted.'
                    )
                );
                history.push(HA_ROUTES.smartTips);
            },
            () => {
                dispatch(
                    dispatchErrorAlertThunk(
                        'smartTipDeleteError',
                        'There was an error with deleting a Smart Tip.'
                    )
                );
            }
        );
    };

    return (
        <CreateSmartTipView
            isEdit
            updateField={updateField}
            setClassificationTagIds={setClassificationTagIds}
            handleCancel={handleCancel}
            handleSubmit={handleSubmit}
            smartTipId={smartTipId}
            formSettings={{
                status: status || '',
                name,
                description,
                vimeoId,
                asset,
                isValid,
            }}
            formValid={{
                name: nameValid,
                description: descriptionValid,
                vimeoId: vimeoIdValid,
                asset: assetValid,
            }}
            formValidationActions={{
                setNameValid,
                setVimeoIdValid,
                setAssetValid,
            }}
            statuses={statuses}
            assets={assets}
            deleteSmartTip={deleteSmartTip}
            classificationTags={classificationTags}
            setClassificationTags={setClassificationTags}
            searchRestricted={searchRestricted}
            setSearchRestricted={setSearchRestricted}
            applayAllTags={applayAllTags}
            setApplayAllTags={setApplayAllTags}
        />
    );
};

export default EditSmartTip;
