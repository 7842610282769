import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import theme from 'assets/theme/mainTheme';
import { MPA_ROUTES } from 'routing/constants';
import useFetch from 'hooks/useFetch';
import apiRoutes from 'config/apiRoutes';
import {
    dispatchErrorAlertThunk,
    dispatchSuccessAlertThunk,
} from 'redux/actions/thunks/systemAlerts';
import { useDispatch } from 'react-redux';

import { ReactComponent as EyeIcon } from 'assets/svg/eye-icon.svg';
import { ReactComponent as ThreeDotsIcon } from 'assets/svg/three-dots-icon.svg';
import ConfirmActionModal from 'components/ConfirmActionModal';
import { useAuth0 } from '@auth0/auth0-react';

import {
    SingleSuperAdminUserWrapper,
    Grid,
    Avatar,
    EmptyAvatar,
    Name,
    Email,
    ButtonsWrapper,
    IconWrapper,
    Divider,
    Dropdown,
    DropdownItem,
    DropdownWrapper,
} from './SingleSuperAdminUser.style';

const SingleSuperAdminUser = ({
    id,
    avatar,
    fullName,
    firstName,
    lastName,
    email,
    color,
    backgroundColor,
    reloadList,
}) => {
    const { getAccessTokenSilently, logout } = useAuth0();
    const history = useHistory();
    const request = useFetch(getAccessTokenSilently, logout);
    const dispatch = useDispatch();
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);

    const toggleDropdownVisible = () => {
        setIsDropdownVisible(!isDropdownVisible);
    };

    const [
        deleteConfirmationModalVisible,
        setDeleteConfirmationModalVisible,
    ] = useState(false);
    const dropdownRef = useRef(null);

    const toggleDeleteConfirmationModalVisible = () => {
        setDeleteConfirmationModalVisible(!deleteConfirmationModalVisible);
    };

    const handleMetricSourcesSuccess = (res) => {
        if (!res.error) {
            dispatch(
                dispatchSuccessAlertThunk(
                    'deleteSuperAdminUser',
                    'Super Admin User deleted'
                )
            );
            toggleDeleteConfirmationModalVisible();
            reloadList();
        }
    };

    const handleMetricSourcesError = (err) => {
        dispatch(dispatchErrorAlertThunk(err, err.error));
    };

    const handleDeleteUser = () => {
        request(
            apiRoutes.delete.deleteSuperAdminUser(id),
            'DELETE',
            handleMetricSourcesSuccess,
            handleMetricSourcesError,
            {},
            { 'Content-Type': 'application/json' }
        );
    };

    const redirectToUserDetails = () =>
        history.push(`${MPA_ROUTES.superAdminUsers}/${id}`);

    const handleClickOutside = (e) => {
        if (dropdownRef.current && dropdownRef.current.contains(e.target)) {
            return;
        }
        toggleDropdownVisible();
    };

    useEffect(() => {
        if (isDropdownVisible) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isDropdownVisible]);

    return (
      <SingleSuperAdminUserWrapper>
        <Grid>
          {avatar ? (
            <Avatar
                        src={avatar}
                        alt={fullName}
                        onClick={redirectToUserDetails}
                    />
                ) : (
                  <EmptyAvatar
                        onClick={redirectToUserDetails}
                        color={color}
                        backgroundColor={backgroundColor}
                    >
                    {`${firstName && firstName[0]}${
                            lastName && lastName[0]
                        }`}
                  </EmptyAvatar>
                )}

          <div>
            <Name onClick={redirectToUserDetails}>{fullName}</Name>
            <Email onClick={redirectToUserDetails}>{email}</Email>
          </div>
          <ButtonsWrapper>
            <IconWrapper onClick={redirectToUserDetails}>
              <EyeIcon fill={theme.color.textLight} />
            </IconWrapper>
            <Divider />
            <DropdownWrapper ref={dropdownRef}>
              <IconWrapper onClick={toggleDropdownVisible}>
                <ThreeDotsIcon fill={theme.color.textLight} />
              </IconWrapper>
              {isDropdownVisible && (
                <Dropdown>
                  <DropdownItem
                                    onClick={() =>
                                        history.push(
                                            `${MPA_ROUTES.editSuperAdminUser}/${id}`
                                        )}
                                >
                    Edit profile
                  </DropdownItem>
                  <DropdownItem
                                    secondary
                                    onClick={
                                        toggleDeleteConfirmationModalVisible
                                    }
                                >
                    Delete User
                  </DropdownItem>
                </Dropdown>
                        )}
            </DropdownWrapper>
          </ButtonsWrapper>
        </Grid>
        <ConfirmActionModal
                isVisible={deleteConfirmationModalVisible}
                onToggle={toggleDeleteConfirmationModalVisible}
                onConfirm={handleDeleteUser}
                description="Are you sure you want to delete User?"
                confirmLabel="Confirm"
                irreversible
            />
      </SingleSuperAdminUserWrapper>
    );
};

SingleSuperAdminUser.propTypes = {
    id: PropTypes.string.isRequired,
    avatar: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.objectOf(PropTypes.any),
    ]),
    fullName: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    color: PropTypes.string,
    backgroundColor: PropTypes.string,
    reloadList: PropTypes.func.isRequired,
};

SingleSuperAdminUser.defaultProps = {
    avatar: undefined,
    color: undefined,
    backgroundColor: undefined,
};

export default SingleSuperAdminUser;
