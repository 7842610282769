import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    uploadMetricResultsThunk,
    downloadTemplateThunk,
    searchMetricsThunk,
    searchOrganizationUsersThunk,
    establishMetricResultsThunk,
} from 'redux/actions/thunks/metrics';
import DataSourcesMetricsView from 'views/DataSourcesMeasures';

const DataSourcesMetrics = () => {
    const metricsState = useSelector((state) => state.metrics);
    const { getAccessTokenSilently, logout } = useAuth0();
    const { metricSources } = useSelector((state) => state.metricSources);
    const dispatch = useDispatch();

    return (
      <DataSourcesMetricsView
            metricsState={metricsState}
            metricSources={metricSources}
            uploadMetricResults={(file) =>
                dispatch(
                    uploadMetricResultsThunk(
                        getAccessTokenSilently,
                        logout,
                        file
                    )
                )}
            establishMetricResults={(metricId, providerIds) =>
                dispatch(
                    establishMetricResultsThunk(
                        getAccessTokenSilently,
                        logout,
                        metricId,
                        providerIds
                    )
                )}
            downloadTemplate={() =>
                dispatch(downloadTemplateThunk(getAccessTokenSilently, logout))}
            searchOrganizationUsers={(searchString, limit, offset) =>
                dispatch(
                    searchOrganizationUsersThunk(
                        getAccessTokenSilently,
                        logout,
                        searchString,
                        limit,
                        offset
                    )
                )}
            searchMetrics={(source, searchString, limit, offset, providerIds) =>
                dispatch(
                    searchMetricsThunk(
                        getAccessTokenSilently,
                        logout,
                        source,
                        searchString,
                        limit,
                        offset,
                        providerIds
                    )
                )}
        />
    );
};

export default DataSourcesMetrics;
