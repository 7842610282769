import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ArrowBackIcon } from 'assets/svg/arrow-back.svg';
import { ReactComponent as VimeoIcon } from 'assets/svg/vimeo.svg';
import SectionHeader from 'components/SectionHeader';
import theme from 'assets/theme/mainTheme';
import Button from 'components/Button';
import Input from 'components/Input';
import ConfirmActionModal from 'components/ConfirmActionModal';
import GalleryPicker from 'components/GalleryPicker';
import InputWithCounter from 'components/InputWithCounter';
import {
    CreateTipWrapper,
    DividedSection,
    Segment,
    WrapperCheckbox,
    SegmentTitle,
} from './CreateSmartTip.style';
import { ClassificationTagDropdown } from '../../components/ClassificationTagDropdown/ClassificationTagDropdown';
import apiRoutes from '../../config/apiRoutes';
import { Text } from '../CreateNewUserHA/CreateNewUserHA.style';
import Checkbox from '../../components/Checkbox';

const CreateSmartTipView = ({
    isEdit,
    updateField,
    handleCancel,
    handleSubmit,
    formSettings,
    formValid,
    statuses,
    assets,
    deleteSmartTip,
    classificationTags,
    smartTipId,
    setClassificationTags,
    classificationTagLocalMode,
    setClassificationTagIds,
    setSearchRestricted,
    searchRestricted,
    setApplayAllTags,
    applayAllTags,
}) => {
    const [statusDropdownVisible, setStatusDropdownVisible] = useState(false);

    const [nameFieldTouched, setNameFieldTouched] = useState(false);
    const [descriptionFieldTouched, setDescriptionFieldTouched] =
        useState(false);
    const [vimeoIdFieldTouched, setVimeoIdFieldTouched] = useState(false);
    const [assetFieldTouched, setAssetFieldTouched] = useState(false);
    const [isDeleteSmartTipModalVisible, setIsDeleteSmartTipModalVisible] =
        useState(false);

    const highlightFields = () => {
        setNameFieldTouched(true);
        setVimeoIdFieldTouched(true);
        setAssetFieldTouched(true);
    };

    useEffect(() => {
        setTimeout(() => {
            setAssetFieldTouched(true);
        }, 2000);
    }, [assets]);

    return (
        <>
            <SectionHeader
                title={isEdit ? 'Edit Smart Tip' : 'Create Smart Tip'}
                goBack={{
                    label() {
                        return (
                            <>
                                <ArrowBackIcon stroke={theme.color.textLabel} />{' '}
                                <span>Back</span>
                            </>
                        );
                    },
                }}
            />
            <CreateTipWrapper>
                <DividedSection horizontal>
                    <Segment borderPosition="right">
                        <SegmentTitle>Smart Tip status </SegmentTitle>
                        <Input
                            value={formSettings.status.title}
                            variant="dropdown"
                            options={statuses}
                            onClick={() =>
                                setStatusDropdownVisible(!statusDropdownVisible)
                            }
                            handleSelect={(id) => {
                                updateField(
                                    'status',
                                    statuses.find((status) => status.id === id)
                                );
                                setStatusDropdownVisible(false);
                            }}
                            dropdownTitle="Statuses"
                            dropdownVisible={statusDropdownVisible}
                            readOnly
                        />
                    </Segment>
                </DividedSection>
                <DividedSection>
                    <Segment borderPosition="bottom" column>
                        <SegmentTitle>Smart Tip details </SegmentTitle>
                        <InputWithCounter
                            placeholder="Name"
                            value={formSettings.name}
                            onChange={(e) =>
                                updateField('name', e.target.value)
                            }
                            invalid={nameFieldTouched && !formValid.name}
                            onTouch={() => setNameFieldTouched(true)}
                            characterLimit={70}
                            fixedWidth
                            withMargin
                        />
                        <InputWithCounter
                            placeholder="Description"
                            value={formSettings.description}
                            onChange={(e) =>
                                updateField('description', e.target.value)
                            }
                            invalid={
                                descriptionFieldTouched &&
                                !formValid.description
                            }
                            onTouch={() => setDescriptionFieldTouched(true)}
                            characterLimit={245}
                            fixedWidth
                        />
                    </Segment>
                    <Segment borderPosition="bottom">
                        <SegmentTitle>Vimeo ID</SegmentTitle>
                        <Input
                            variant="withIcon"
                            icon={() => (
                                <VimeoIcon fill={theme.color.textLight} />
                            )}
                            placeholder="Vimeo ID"
                            value={formSettings.vimeoId}
                            onChange={(e) =>
                                updateField('vimeoId', e.target.value)
                            }
                            invalid={vimeoIdFieldTouched && !formValid.vimeoId}
                            onBlur={() => setVimeoIdFieldTouched(true)}
                            isRequired
                        />
                    </Segment>
                    <Segment borderPosition="bottom">
                        <SegmentTitle>Graphic asset</SegmentTitle>
                        <GalleryPicker
                            selectedAsset={formSettings.asset}
                            setSelectedAsset={(asset) =>
                                updateField('asset', asset)
                            }
                            assets={assets}
                            invalid={assetFieldTouched && !formValid.asset}
                        />
                    </Segment>

                    <Segment borderPosition="bottom">
                        <SegmentTitle>Smart Tip Tags</SegmentTitle>
                        <SegmentTitle>
                            Add tags to help filter and find Tips to assign by
                            Department or Role.
                        </SegmentTitle>
                        <WrapperCheckbox mt="29px" mb="23px">
                            <Checkbox
                                isSelected={applayAllTags}
                                toggleSelected={() =>
                                    setApplayAllTags(!applayAllTags)
                                }
                            />
                            <Text>Apply all Departments and Roles</Text>
                        </WrapperCheckbox>
                        <div>
                            <ClassificationTagDropdown
                                localMode
                                setClassificationTagIds={
                                    isEdit && setClassificationTagIds
                                }
                                currentlySavedTags={classificationTags}
                                setClassificationTags={setClassificationTags}
                                disabled={applayAllTags}
                            />
                        </div>
                        <WrapperCheckbox mt="6px" mb="0">
                            <Checkbox
                                disabled={applayAllTags}
                                isSelected={searchRestricted}
                                toggleSelected={() =>
                                    setSearchRestricted(!searchRestricted)
                                }
                            />
                            <Text disabled={applayAllTags}>
                                Restrict Smart Tip to users with selected
                                Department(s)
                            </Text>
                        </WrapperCheckbox>
                    </Segment>

                    <Segment
                        onFocus={() => highlightFields()}
                        onMouseOver={() => highlightFields()}
                        style={{ display: 'flex' }}
                    >
                        <Button
                            primaryDark
                            onClick={() => handleSubmit()}
                            disabled={!formSettings.isValid}
                        >
                            Save
                        </Button>
                        <Button primary onClick={() => handleCancel()}>
                            Cancel
                        </Button>

                        <Button
                            quaternary
                            onClick={() =>
                                setIsDeleteSmartTipModalVisible(true)
                            }
                            style={{ marginLeft: 'auto' }}
                        >
                            Delete
                        </Button>
                    </Segment>
                    <ConfirmActionModal
                        isVisible={isDeleteSmartTipModalVisible}
                        onToggle={() => {
                            setIsDeleteSmartTipModalVisible(
                                !isDeleteSmartTipModalVisible
                            );
                        }}
                        onConfirm={() => {
                            deleteSmartTip();
                            setIsDeleteSmartTipModalVisible(false);
                        }}
                        description="Are you sure you want to delete this Smart Tip?"
                        confirmLabel="Delete"
                    />
                </DividedSection>
            </CreateTipWrapper>
        </>
    );
};

CreateSmartTipView.propTypes = {
    isEdit: PropTypes.bool,
    updateField: PropTypes.func.isRequired,
    handleCancel: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    setClassificationTagIds: PropTypes.func,
    formSettings: PropTypes.shape({
        status: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        isRecommendation: PropTypes.bool,
        name: PropTypes.string,
        description: PropTypes.string,
        vimeoId: PropTypes.string,
        asset: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        isValid: PropTypes.bool,
    }).isRequired,
    formValid: PropTypes.shape({
        name: PropTypes.bool,
        description: PropTypes.bool,
        vimeoId: PropTypes.bool,
        asset: PropTypes.bool,
    }).isRequired,
    formValidationActions: PropTypes.shape({
        setNameValid: PropTypes.func,
        setVimeoIdValid: PropTypes.func,
        setAssetValid: PropTypes.func,
    }).isRequired,
    statuses: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            title: PropTypes.string,
        })
    ).isRequired,
    assets: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            url: PropTypes.string,
        })
    ).isRequired,
    deleteSmartTip: PropTypes.func,
    // eslint-disable-next-line react/forbid-prop-types
    classificationTags: PropTypes.array.isRequired,
    setClassificationTags: PropTypes.func.isRequired,
    smartTipId: PropTypes.string,
    classificationTagLocalMode: PropTypes.bool,
    setSearchRestricted: PropTypes.func.isRequired,
    searchRestricted: PropTypes.bool.isRequired,
    setApplayAllTags: PropTypes.func.isRequired,
    applayAllTags: PropTypes.bool.isRequired,
};

CreateSmartTipView.defaultProps = {
    isEdit: false,
    deleteSmartTip: () => {},
    setClassificationTagIds: () => {},
    smartTipId: '',
    classificationTagLocalMode: false,
};

export default CreateSmartTipView;
