import {
    establishSuperAdminUsers,
    allSuperAdminUsersLoaded,
    loadMoreSuperAdminUsers,
    establishSuperAdminUsersSuccess,
    establishSuperAdminUsersFailure,
    resetSuperAdminUsers,
} from 'redux/actions/mpaSuperAdminUsers';
import apiRoutes from 'config/apiRoutes';
import paramsToString from 'redux/helpers/paramsToString';
import MPA_ORGANIZATION_SLUG from 'config/mpaAccessSlug';

export const establishSuperAdminUsersThunk = (
    getAccessTokenSilently,
    logout,
    limit = 20,
    offset = 0,
    params = {}
) => (dispatch) => {
    dispatch(establishSuperAdminUsers());

    const url =
        apiRoutes.get.superAdminUsers(limit, offset) + paramsToString(params);

    return getAccessTokenSilently()
        .then((token) => {
            fetch(url, {
                method: 'GET',
                headers: {
                    organization_slug: MPA_ORGANIZATION_SLUG,
                    Authorization: `Token token="${token}"`,
                    'Content-Type': 'application/json',
                },
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.error) {
                        return dispatch(
                            establishSuperAdminUsersFailure(res.error)
                        );
                    }

                    if (res.users.length < limit) {
                        dispatch(allSuperAdminUsersLoaded());
                    }

                    return dispatch(establishSuperAdminUsersSuccess(res));
                })
                .catch((err) =>
                    dispatch(
                        establishSuperAdminUsersFailure({
                            err,
                        })
                    )
                );
        })
        .catch(() => logout({ returnTo: window.location.origin }));
};

export const loadMoreSuperAdminUsersThunk = (
    getAccessTokenSilently,
    logout,
    limit,
    offset,
    params = {}
) => (dispatch) => {
    dispatch(establishSuperAdminUsers());

    const url =
        apiRoutes.get.superAdminUsers(limit, offset) + paramsToString(params);

    return getAccessTokenSilently()
        .then((token) => {
            fetch(url, {
                method: 'GET',
                headers: {
                    organization_slug: MPA_ORGANIZATION_SLUG,
                    Authorization: `Token token="${token}"`,
                    'Content-Type': 'application/json',
                },
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.error) {
                        return dispatch(
                            establishSuperAdminUsersFailure(res.error)
                        );
                    }

                    if (res.users.length === 0) {
                        return dispatch(allSuperAdminUsersLoaded());
                    }

                    return dispatch(loadMoreSuperAdminUsers(res));
                })
                .catch((err) =>
                    dispatch(
                        establishSuperAdminUsersFailure({
                            err,
                        })
                    )
                );
        })
        .catch(() => logout({ returnTo: window.location.origin }));
};

export const resetSuperAdminUsersThunk = () => (dispatch) => {
    dispatch(resetSuperAdminUsers());
};
