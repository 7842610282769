import { useSelector } from 'react-redux';

const useTeamName = (teamId) => {
    const { details } = useSelector((state) => state.teamOverview);
    let teamName = '';

    if (details && teamId === details.items.id) {
        teamName = details.items.name;
    }

    return teamName;
};

export default useTeamName;
