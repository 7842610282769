import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { ReactComponent as AssignIcon } from 'assets/svg/assign-icon.svg';

import useAdmin from 'hooks/useAdmin';
import AssignSmartTipModal from 'containers/AssignSmartTipModal';
import { HA_ROUTES } from 'routing/constants';
import { StyledButton } from 'components/SmartTip/SmartTip.style';
import StyledSmartTipCTA, { StyledIconButton } from './SmartTipCTA.style';

const SmartTipCTA = ({ status, smartTipId, isAssignedToAll, reload }) => {
    const isAdmin = useAdmin();
    const history = useHistory();
    const [isModalOpen, setIsModalOpen] = useState(false);

    if (!isAdmin) {
        return null;
    }

    return (
      <StyledSmartTipCTA>
        {status === 'published' && (
        <StyledIconButton primary onClick={() => setIsModalOpen(true)}>
          <AssignIcon />
          Assign
        </StyledIconButton>
            )}
        <StyledButton
                primaryDark
                onClick={() =>
                    history.push(`${HA_ROUTES.editSmartTip}/${smartTipId}`)}
            >
          Edit
        </StyledButton>
        {isModalOpen && (
        <AssignSmartTipModal
                    smartTipId={smartTipId}
                    toggleModal={() => setIsModalOpen(!isModalOpen)}
                    isAssignedToAll={isAssignedToAll}
                    resetSmartTips={reload}
                />
            )}
      </StyledSmartTipCTA>
    );
};

SmartTipCTA.propTypes = {
    status: PropTypes.string,
    smartTipId: PropTypes.string,
    isAssignedToAll: PropTypes.bool,
    reload: PropTypes.func,
};

SmartTipCTA.defaultProps = {
    status: '',
    smartTipId: '',
    isAssignedToAll: false,
    reload: () => {},
};

export default SmartTipCTA;
