import styled, { css } from 'styled-components/macro';

export const Container = styled.div`
    min-height: calc(100vh - 150px);
`;

export const CtaWrapper = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
`;

export const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 49px;
    height: 49px;
    border-radius: ${({ theme }) => theme.border.radiusBase};
    cursor: pointer;

    &:hover {
        background-color: ${({ theme }) => theme.color.bgPrimaryLight};
    }
`;

export const DropdownWrapper = styled.div`
    z-index: 1;
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-gap: 8px;
    padding: 16px;
    width: 160px;
    position: absolute;
    border-radius: ${({ theme }) => theme.border.radiusBase};
    background-color: ${({ theme }) => theme.color.white};
    box-shadow: ${({ theme }) => theme.boxShadow.base};
    top: 49px;
    right: 0px;
`;

export const DropdownItem = styled.div`
    padding: 16px;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    color: ${({ theme }) => theme.color.primary};
    border-radius: ${({ theme }) => theme.border.radiusSmall};
    z-index: 1;

    ${({ theme, secondary }) =>
        secondary &&
        css`
            color: ${theme.color.red};
        `}

    &:hover {
        cursor: pointer;
        background-color: ${({ theme }) => theme.color.bgPrimaryLight};

        ${({ theme, secondary }) =>
            secondary &&
            css`
                background-color: ${theme.color.pinkLight};
            `}
    }
`;

export const SuperAdminUserWrapper = styled.div`
    padding: 27px 32px;
    border: ${({ theme }) => theme.border.base};
    border-radius: ${({ theme }) => theme.border.radiusBase};
    margin-bottom: 24px;
`;

export const Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr 12fr;
    grid-gap: 32px;
    align-items: center;
`;

export const Avatar = styled.img`
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
`;

export const EmptyAvatar = styled.p`
    margin: 0px;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    text-align: center;
    vertical-align: middle;
    line-height: 80px;
    font-size: 20px;
    font-weight: 600;
    color: ${({ theme }) => theme.color.primary};
    background-color: ${({ theme }) => theme.color.bgPrimaryLight};

    ${({ color }) =>
        color &&
        css`
            color: ${color};
        `}

    ${({ backgroundColor }) =>
        backgroundColor &&
        css`
            background-color: ${backgroundColor};
        `}
`;

export const Name = styled.div`
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    color: ${({ theme }) => theme.color.textBase};
    padding-bottom: 8px;
`;

export const Email = styled.div`
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: ${({ theme }) => theme.color.textLight};
`;
