import React from 'react';
import PropTypes from 'prop-types';
import { Container, Title, Description } from './SmartTipDescription.style';

const SmartTipDescription = ({ description }) => (
  <>
    {description && (
    <Container>
      <Title>Description</Title>
      <Description>{description}</Description>
    </Container>
        )}
  </>
);

SmartTipDescription.propTypes = {
    description: PropTypes.string,
};

SmartTipDescription.defaultProps = {
    description: '',
};

export default SmartTipDescription;
