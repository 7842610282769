import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as LightBulbIcon } from 'assets/svg/light-bulb-shine.svg';
import { ReactComponent as FeatherIcon } from 'assets/svg/feather.svg';
import { ReactComponent as MultipleUsersIcon } from 'assets/svg/multiple-users.svg';
import theme from 'assets/theme/mainTheme';
import {
    StyledCTA,
    StyledLabel,
    StyledAvatarHolder,
} from './HospitalAdminCTA.style';

const HospitalAdminCTA = ({
    headerText,
    helperText,
    callbackFunc,
    icon,
    backgroundColor,
}) => (
  <StyledCTA onClick={callbackFunc} data-testid="ha-cta-container">
    <StyledAvatarHolder color={backgroundColor}>
      {icon === 'light-bulb' && (
        <LightBulbIcon fill={theme.color.white} />
            )}
      {icon === 'feather' && <FeatherIcon fill={theme.color.white} />}
      {icon === 'multiple-users' && (
        <MultipleUsersIcon fill={theme.color.white} />
            )}
    </StyledAvatarHolder>
    <StyledLabel>
      <strong>{headerText}</strong>
      <small>{helperText}</small>
    </StyledLabel>
  </StyledCTA>
);

HospitalAdminCTA.propTypes = {
    headerText: PropTypes.string.isRequired,
    helperText: PropTypes.string.isRequired,
    callbackFunc: PropTypes.func.isRequired,
    icon: PropTypes.string,
    backgroundColor: PropTypes.string,
};

HospitalAdminCTA.defaultProps = {
    icon: 'light-bulb',
    backgroundColor: theme.color.primary,
};

export default HospitalAdminCTA;
