import {
    ESTABLISH_COURSES,
    ESTABLISH_COURSES_SUCCESS,
    ESTABLISH_COURSES_FAILURE,
} from 'redux/actions/courses';

export const initialState = {
    areCoursesEstablished: false,
    isCoursesError: false,
    areCoursesFetching: false,
    error: null,
    courses: [],
};

const courses = (state = initialState, action) => {
    switch (action.type) {
        case ESTABLISH_COURSES:
            return {
                ...state,
                areCoursesFetching: true,
                isCoursesError: false,
            };

        case ESTABLISH_COURSES_SUCCESS:
            return {
                ...state,
                areCoursesFetching: false,
                areCoursesEstablished: true,
                courses: action.payload,
            };

        case ESTABLISH_COURSES_FAILURE:
            return {
                ...state,
                areCoursesFetching: false,
                isCoursesError: true,
                areCoursesEstablished: false,
                error: action.err,
            };

        default:
            return state;
    }
};

export default courses;
