import React, { useEffect } from 'react';
import useOffset from 'hooks/useOffset';
import { useSelector, useDispatch } from 'react-redux';
import establishAdminMemosThunk, {
    loadMoreAdminMemosThunk,
    resetAdminMemosThunk,
    revertAdminMemoThunk,
} from 'redux/actions/thunks/adminMemos';
import AdminMemosView from 'views/AdminMemos';
import { useAuth0 } from '@auth0/auth0-react';

const INFINITE_SCROLL_THRESHOLD = 20;

const AdminMemos = () => {
    const dispatch = useDispatch();
    const { getAccessTokenSilently, logout } = useAuth0();
    const [offset, incrementOffset, resetOffset] = useOffset(
        INFINITE_SCROLL_THRESHOLD
    );

    const {
        areAdminMemosFetching,
        hasMoreAdminMemos,
        adminMemos,
    } = useSelector((state) => state.adminMemos);

    const hasMore = hasMoreAdminMemos && !areAdminMemosFetching;

    const loadMore = () => {
        dispatch(
            loadMoreAdminMemosThunk(
                getAccessTokenSilently,
                logout,
                INFINITE_SCROLL_THRESHOLD,
                offset
            )
        );
        incrementOffset();
    };

    useEffect(() => {
        dispatch(
            establishAdminMemosThunk(
                getAccessTokenSilently,
                logout,
                INFINITE_SCROLL_THRESHOLD,
                0
            )
        );
        resetOffset(INFINITE_SCROLL_THRESHOLD);
        return dispatch(resetAdminMemosThunk());
    }, []);

    const revertAdminMemo = (adminMemoId) => {
        dispatch(
            revertAdminMemoThunk(
                getAccessTokenSilently,
                logout,
                adminMemoId,
                reloadAdminMemos
            )
        );
    };

    const reloadAdminMemos = () => {
        dispatch(resetAdminMemosThunk());
        dispatch(
            establishAdminMemosThunk(
                getAccessTokenSilently,
                logout,
                INFINITE_SCROLL_THRESHOLD,
                0
            )
        );
        resetOffset(INFINITE_SCROLL_THRESHOLD);
    };

    return (
      <AdminMemosView
            isFetching={areAdminMemosFetching}
            adminMemos={adminMemos}
            hasMore={hasMore}
            loadMore={loadMore}
            revertAdminMemo={revertAdminMemo}
            reloadAdminMemos={reloadAdminMemos}
        />
    );
};

export default AdminMemos;
