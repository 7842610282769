import React from 'react';
import PropTypes from 'prop-types';
import theme from 'assets/theme/mainTheme';
import EditReminderModal from 'components/EditReminderModal';
import { ReactComponent as EditIcon } from 'assets/svg/edit-reminder.svg';
import { ReactComponent as DeleteIcon } from 'assets/svg/delete-reminder.svg';
import { ReactComponent as BellIcon } from 'assets/svg/alarm-bell.svg';
import {
    SingleReminderHolder,
    BellIconHolder,
    TitleHolder,
    Title,
    DateTime,
    IconsHolder,
} from './SingleReminder.style';

const SingleReminder = ({
    topic,
    parsedDate,
    dateTimeObject,
    isEditReminderModalOpened,
    openModal,
    closeModal,
    reminderId,
    smartTips,
    editReminder,
    deleteReminder,
}) => (
  <SingleReminderHolder>
    <BellIconHolder>
      <BellIcon fill={theme.color.primary} />
    </BellIconHolder>
    <TitleHolder>
      <Title>{topic}</Title>
      <DateTime>{parsedDate}</DateTime>
    </TitleHolder>
    <IconsHolder>
      <EditIcon
                onClick={() => openModal()}
                fill={theme.color.textNavigation}
            />
      <DeleteIcon
                fill={theme.color.textNavigation}
                id="deleteReminderButton"
                onClick={() => deleteReminder(reminderId)}
            />
    </IconsHolder>
    {isEditReminderModalOpened === reminderId && (
    <EditReminderModal
                reminderId={reminderId}
                title={topic}
                parsedDate={parsedDate}
                dateTimeObject={dateTimeObject}
                smartTips={smartTips}
                editReminder={editReminder}
                closeModal={closeModal}
            />
        )}
  </SingleReminderHolder>
);

SingleReminder.propTypes = {
    reminderId: PropTypes.string.isRequired,
    isEditReminderModalOpened: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string,
    ]).isRequired,
    openModal: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    deleteReminder: PropTypes.func.isRequired,
    topic: PropTypes.string.isRequired,
    parsedDate: PropTypes.string.isRequired,
    dateTimeObject: PropTypes.objectOf(PropTypes.any).isRequired,
    smartTips: PropTypes.arrayOf(PropTypes.any).isRequired,
    editReminder: PropTypes.func.isRequired,
};

SingleReminder.defaultProps = {};

export default SingleReminder;
