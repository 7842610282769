import styled, { css } from 'styled-components/macro';

export const StyledUserHeader = styled.div`
    display: grid;
    grid-template-columns: 1fr 3fr 4fr 1fr;
    grid-gap: 16px;
    align-items: center;
    max-width: 100%;
    padding: 24px;
    border-radius: ${({ theme }) => theme.border.radiusBase};
    border: ${({ theme }) => theme.border.base};
    background-color: ${({ theme }) => theme.color.white};

    @media (max-width: 1400px) {
        grid-template-columns: 1fr 3fr 3fr 2fr;
    }

    @media (max-width: ${({ theme }) => `${theme.breakpoints.medium}px`}) {
        display: flex;
        flex-wrap: wrap;
    }
`;

export const StyledAvatar = styled.img`
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
`;

export const StyledAvatarPlaceholder = styled.div`
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    ${({ color }) =>
        color &&
        css`
            background-color: ${color};
        `}
`;

export const StyledLabel = styled.div`
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    width: 100%;
    overflow: hidden;

    strong {
        white-space: nowrap;
        font-size: ${({ theme }) => theme.fontSize.sectionHeading};
        text-overflow: ellipsis;
        overflow: hidden;
    }

    small {
        white-space: nowrap;
        padding: 8px 0;
        color: ${({ theme }) => theme.color.textLight};
        font-size: ${({ theme }) => theme.fontSize.medium};
        text-overflow: ellipsis;
        overflow: hidden;
    }

    ${({ pullRight }) =>
        pullRight &&
        css`
            align-items: flex-end;
            justify-content: center;
        `}
`;

export const StyledUserTeams = styled.span`
    display: flex !important;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;

    small {
        padding: 5px 5px 0px 0px;
        font-size: ${({ theme }) => theme.fontSize.small};
    }

    span {
        margin: 5px 5px 0px 0px;
    }
`;
