export const ESTABLISH_DATA_SOURCES_MEASURES_UPLOADS =
    'ESTABLISH_DATA_SOURCES_MEASURES_UPLOADS';
export const ESTABLISH_DATA_SOURCES_MEASURES_UPLOADS_SUCCESS =
    'ESTABLISH_DATA_SOURCES_MEASURES_UPLOADS_SUCCESS';
export const ESTABLISH_DATA_SOURCES_MEASURES_UPLOADS_FAILURE =
    'ESTABLISH_DATA_SOURCES_MEASURES_UPLOADS_FAILURE';

export const LOAD_MORE_DATA_SOURCES_MEASURES_UPLOADS =
    'LOAD_MORE_DATA_SOURCES_MEASURES_UPLOADS';
export const MARK_ALL_DATA_SOURCES_MEASURES_UPLOADS_LOADED =
    'MARK_ALL_DATA_SOURCES_MEASURES_UPLOADS_LOADED';
export const RESET_DATA_SOURCES_MEASURES_UPLOADS =
    'RESET_DATA_SOURCES_MEASURES_UPLOADS';

export const DESTROY_METRIC_UPLOAD = 'DESTROY_METRIC_UPLOAD';

export const establishDataSourcesMeasuresUploads = () => ({
    type: ESTABLISH_DATA_SOURCES_MEASURES_UPLOADS,
});

export const establishDataSourcesMeasuresUploadsSuccess = (payload) => ({
    type: ESTABLISH_DATA_SOURCES_MEASURES_UPLOADS_SUCCESS,
    payload,
});

export const establishDataSourcesMeasuresUploadsFailure = (err) => ({
    type: ESTABLISH_DATA_SOURCES_MEASURES_UPLOADS_FAILURE,
    err,
});

export const destroyMetricUpload = () => ({
    type: DESTROY_METRIC_UPLOAD,
});

export const loadMoreDataSourcesMeasuresUploads = (payload) => ({
    type: LOAD_MORE_DATA_SOURCES_MEASURES_UPLOADS,
    payload,
});

export const allDataSourcesMeasuresUploadsLoaded = () => ({
    type: MARK_ALL_DATA_SOURCES_MEASURES_UPLOADS_LOADED,
});

export const resetDataSourcesMeasuresUploads = () => ({
    type: RESET_DATA_SOURCES_MEASURES_UPLOADS,
});
