import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as FeedbackIcon1 } from 'assets/svg/survey/smiley-1.svg';
import { ReactComponent as FeedbackIcon2 } from 'assets/svg/survey/smiley-2.svg';
import { ReactComponent as FeedbackIcon3 } from 'assets/svg/survey/smiley-3.svg';
import { ReactComponent as FeedbackIcon4 } from 'assets/svg/survey/smiley-4.svg';
import { ReactComponent as FeedbackIcon5 } from 'assets/svg/survey/smiley-5.svg';
import theme from 'assets/theme/mainTheme';

import ReviewModal from 'components/ReviewModal';
import StyledFeedbackWrapper, {
    StyledFeedbackSelection,
    StyledFeedbackGroup,
    StyledFeedbackButton,
} from './SmartTipFeedback.style';

const SmartTipFeedback = ({ onSubmit, evaluation }) => {
    const [reviewInput, setReviewInput] = useState('');
    const [feedbackSubmitted, setFeedbackSubmitted] = useState(0); // numeric value corresponding to button numbers

    const [isModalVisible, setIsVisibleModal] = useState(false);
    const [feedbackExplainerText, setFeedbackExplainerText] = useState('');
    const [buttonNumber, setButtonNumber] = useState(0);

    const text = {
        1: 'Very Unsatisfied',
        2: 'Unsatisfied',
        3: 'Neutral',
        4: 'Satisfied',
        5: 'Very Satisfied',
    };

    useEffect(() => {
        setFeedbackExplainerText(text[buttonNumber] || '');
    }, [buttonNumber]);

    const onSubmitFeedback = () =>
        onSubmit({ value: feedbackSubmitted, comment: reviewInput });

    const handleSelection = () => {
        if (buttonNumber) {
            setFeedbackSubmitted(buttonNumber);

            if (buttonNumber > 0 && buttonNumber < 4) {
                setIsVisibleModal(true);
            } else {
                onSubmit({ value: buttonNumber, comment: '' });
            }
        }
    };

    const isSelected = (number) => evaluation === number && buttonNumber === 0;

    const onMouseEnter = (number) => {
        setButtonNumber(number);
    };

    const buttons = [
        {
            icon: <FeedbackIcon1 />,
            bgColor: theme.color.quaternary,
        },
        {
            icon: <FeedbackIcon2 />,
            bgColor: theme.color.quaternaryAlt,
        },
        {
            icon: <FeedbackIcon3 />,
            bgColor: theme.color.tertiaryAlt,
        },
        {
            icon: <FeedbackIcon4 />,
            bgColor: theme.color.paleGreen,
        },
        {
            icon: <FeedbackIcon5 />,
            bgColor: theme.color.primary,
        },
    ];

    return (
        <StyledFeedbackWrapper>
            Let us know what you think about this Smart Tip!
            {isModalVisible && (
                <ReviewModal
                    closeModal={() => setIsVisibleModal(false)}
                    cancelFeedback={() => {
                        setFeedbackSubmitted(0);
                    }}
                    onSubmit={onSubmitFeedback}
                    review={{
                        value: reviewInput,
                        setFunc: setReviewInput,
                    }}
                    noOverflow
                />
            )}
            <StyledFeedbackSelection buttonNumber={buttonNumber} isCompleted>
                <strong>{feedbackExplainerText}</strong>
                <StyledFeedbackGroup>
                    {buttons.map((button, index) => (
                        <StyledFeedbackButton
                            key={button.bgColor}
                            isCompleted
                            onMouseEnter={() => onMouseEnter(index + 1)}
                            onMouseLeave={() => setButtonNumber(0)}
                            onClick={() => handleSelection(index + 1)}
                            isSelected={isSelected(index + 1)}
                            bgColor={button.bgColor}
                        >
                            {button.icon}
                        </StyledFeedbackButton>
                    ))}
                </StyledFeedbackGroup>
            </StyledFeedbackSelection>
        </StyledFeedbackWrapper>
    );
};

SmartTipFeedback.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    evaluation: PropTypes.number,
};

SmartTipFeedback.defaultProps = {
    evaluation: 0,
};

export default SmartTipFeedback;
