import React, { useEffect } from 'react';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import 'react-datepicker/dist/react-datepicker.css';

const Calendar = ({
    selectedDate,
    selectDate,
    remindersByDate,
    setIsDaySelected,
}) => {
    useEffect(() => {
        const dayNumbers = remindersByDate.map((reminder) =>
            reminder.dateTimeObject.getDate()
        );

        const singleDays = Array.from(
            document.getElementsByClassName(`react-datepicker__day`)
        );

        dayNumbers.forEach((dayNumber) => {
            singleDays.forEach((singleDay) => {
                const arrayOfClasses = Array.from(singleDay.classList);
                const foundDay = arrayOfClasses.find(
                    (singleClass) =>
                        singleClass ===
                            `react-datepicker__day--0${dayNumber}` ||
                        singleClass === `react-datepicker__day--00${dayNumber}`
                );

                if (foundDay) {
                    Array.from(
                        document.getElementsByClassName(foundDay)
                    ).forEach((foundElement) => {
                        if (
                            !foundElement.classList.contains(
                                'react-datepicker__day--outside-month'
                            )
                        ) {
                            foundElement.classList.add(
                                'react-datepicker__day--with-reminders'
                            );
                        }
                    });
                }
                return arrayOfClasses;
            });
        });
    }, [remindersByDate]);

    const handleSetDate = (date) => {
        if (date.getMonth() === selectedDate.getMonth()) {
            selectDate(date);
        } else {
            selectDate(new Date(date.getFullYear(), date.getMonth(), 1));
        }

        setIsDaySelected(true);
    };

    return (
      <DatePicker
            selected={selectedDate}
            onChange={handleSetDate}
            inline
            className="inlineCalendar"
            adjustDateOnChange
        />
    );
};

Calendar.propTypes = {
    selectedDate: PropTypes.objectOf(Date).isRequired,
    selectDate: PropTypes.func.isRequired,
    setIsDaySelected: PropTypes.func.isRequired,
    remindersByDate: PropTypes.arrayOf(
        PropTypes.shape({
            dateTimeObject: PropTypes.instanceOf(Date),
        })
    ).isRequired,
};

export default Calendar;
