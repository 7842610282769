import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';

import UploadAvatarOnboardingComponent from 'components/UploadAvatarOnboarding';
import { updateUserProfileThunk } from 'redux/actions/thunks/profile';
import { useAuth0 } from '@auth0/auth0-react';

const UploadAvatarOnboarding = () => {
    const dispatch = useDispatch();
    const { getAccessTokenSilently, logout } = useAuth0();
    const { userAvatarUrl, isUserFetching } = useSelector(
        (state) => state.currentUser
    );
    const [avatarPreview, setAvatarPreview] = useState(userAvatarUrl);
    const [wasFormSubmitted, setWasFormSubmitted] = useState(false);

    const initialFormState = {
        avatar: null,
    };

    const handleSubmit = (values, { setSubmitting }) => {
        setSubmitting(false);
        setWasFormSubmitted(true);
        dispatch(
            updateUserProfileThunk(getAccessTokenSilently, logout, values)
        );
    };

    const formSchema = Yup.object().shape({
        avatar: Yup.mixed()
            .nullable()
            .test(
                'size',
                'Selected image is too large. Maximum avatar size: 5MB',
                (value) => {
                    if (!value) return true;
                    return value.size < 5 * 1024 * 1024;
                }
            )
            .test(
                'fileFormat',
                'Invalid avatar format. Available image formats: JPEG, PNG',
                (value) => {
                    if (!value) return true;
                    return ['image/pjpeg', 'image/jpeg', 'image/png'].includes(
                        value.type
                    );
                }
            ),
    });

    return (
      <Formik
            onSubmit={handleSubmit}
            initialValues={initialFormState}
            validationSchema={formSchema}
            enableReinitialize
            validateOnChange
        >
        {(formikProps) => (
          <UploadAvatarOnboardingComponent
                    avatarPreview={avatarPreview}
                    setAvatarPreview={setAvatarPreview}
                    userDataLoading={isUserFetching}
                    wasFormSubmitted={wasFormSubmitted}
                    resetFormSubmitted={() => setWasFormSubmitted(false)}
                    {...formikProps}
                />
            )}
      </Formik>
    );
};

export default UploadAvatarOnboarding;
