import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Button from 'components/Button';
import SectionHeader from 'components/SectionHeader';
import SmartTipHA from 'components/SmartTipHA';
import Input from 'components/Input';
import { HA_ROUTES } from 'routing/constants';
import InfiniteScroll from 'react-infinite-scroller';
import Spinner from 'components/Spinner';
import AdminHANavigation from 'components/AdminHANavigation';
import {
    AdminSection,
    SmartTipsWrapper,
    HeaderColumn,
    SectionHeaderCta,
    SmartTipsTableHeader,
} from './SmartTipsHA.style';
import { ClassificationTagDropdown } from '../../components/ClassificationTagDropdown/ClassificationTagDropdown';
import {
    FilterLabel,
    SeparatorLine,
    SmartFilterClearBtnContainer,
    SmartTipFiltersContainer,
} from '../../containers/BrowseAvailableSmartTipsForProvider/styled';
import { TAGS_INIT_VALS } from '../../containers/BrowseAvailableSmartTipsForProvider/helpers';

const filterOptions = [
    {
        id: 'all',
        title: 'All Smart Tips',
    },
    {
        id: 'published',
        title: 'Published',
    },
    {
        id: 'unpublished',
        title: 'Unpublished',
    },
    {
        id: 'review',
        title: 'Review',
    },
];

const SmartTipsHA = ({
    smartTips,
    handleFilter,
    search,
    handleSearch,
    filterParam,
    loadMore,
    hasMore,
    isFetching,
    resetSmartTips,
    tagsFilter,
    setTagsFilter,
}) => {
    const [filterDropdownVisible, setFilterDropdownVisible] = useState(false);
    const [
        selectedFilterDropdownVisible,
        setSelectedFilterDropdownVisible,
    ] = useState(filterOptions[0]);

    const handleFilterDropdownSelect = (id) => {
        setFilterDropdownVisible(false);
        setSelectedFilterDropdownVisible(
            filterOptions.find((option) => option.id === id)
        );
        handleFilter(id);
    };

    useEffect(() => {
        if (filterParam) {
            handleFilterDropdownSelect(filterParam);
        }
    }, [filterParam]);

    useEffect(() => {
        if (filterParam) {
            handleFilterDropdownSelect(filterParam);
        }
    }, [filterParam]);

    return (
      <>
        <SmartTipsWrapper>
          <SectionHeader
                    title="Smart Tips"
                    cta={() => (
                      <SectionHeaderCta>
                        <Link to={HA_ROUTES.createSmartTip}>
                          <Button primaryDark>Create new</Button>
                        </Link>
                      </SectionHeaderCta>
                    )}
                />
        </SmartTipsWrapper>
        <AdminSection withPadding>
          <AdminHANavigation variant="smart-tips" />
        </AdminSection>

        <SmartTipFiltersContainer
                style={{ marginTop: 20, marginBottom: 20 }}
            >
          <FilterLabel>Filters</FilterLabel>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Input
                        variant="dropdown"
                        dropdownTitle="Select option"
                        placeholder={selectedFilterDropdownVisible.title}
                        value={selectedFilterDropdownVisible.title}
                        dropdownVisible={filterDropdownVisible}
                        onClick={() =>
                            setFilterDropdownVisible(!filterDropdownVisible)}
                        handleSelect={handleFilterDropdownSelect}
                        options={filterOptions}
                        inputGroupStyle={{
                            flexBasis: 'auto',
                            width: 215,
                            margin: 0,
                            marginRight: 16,
                        }}
                    />

            <Input
                        variant="base"
                        value={search}
                        placeholder="Smart Tip Name"
                        onChange={handleSearch}
                        inputGroupStyle={{ flexBasis: 'auto', width: 215 }}
                    />
          </div>

          <ClassificationTagDropdown
                    currentlySavedTags={tagsFilter}
                    setClassificationTags={setTagsFilter}
                    layout="horizontal"
                    localMode
                />

          <SeparatorLine />

          <SmartFilterClearBtnContainer>
            <Button
                        primary
                        onClick={() => {
                            setSelectedFilterDropdownVisible(
                                filterOptions.find(
                                    (option) => option.id === 'all'
                                )
                            );
                            handleSearch({ target: { value: '' } });
                            setTagsFilter(TAGS_INIT_VALS);
                        }}
                    >
              Clear filters
            </Button>
          </SmartFilterClearBtnContainer>
        </SmartTipFiltersContainer>

        <SmartTipsWrapper>
          <SmartTipsTableHeader>
            <HeaderColumn>Name</HeaderColumn>
            <HeaderColumn>Last update</HeaderColumn>
            <HeaderColumn>Status</HeaderColumn>
            <HeaderColumn>Score</HeaderColumn>
            <HeaderColumn>Assigned to</HeaderColumn>
            <HeaderColumn />
          </SmartTipsTableHeader>
          {smartTips.length > 0 && (
            <InfiniteScroll
                        initialLoad={false}
                        loadMore={loadMore}
                        loader={isFetching ? <Spinner key="spinner" /> : <></>}
                        hasMore={hasMore}
                    >
              {smartTips.map((smartTip) => (
                <SmartTipHA
                                key={smartTip.id}
                                {...smartTip}
                                resetSmartTips={resetSmartTips}
                            />
                        ))}
            </InfiniteScroll>
                )}
          {isFetching && <Spinner />}
        </SmartTipsWrapper>
      </>
    );
};

SmartTipsHA.propTypes = {
    smartTips: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
        })
    ).isRequired,
    handleFilter: PropTypes.func.isRequired,
    search: PropTypes.string.isRequired,
    handleSearch: PropTypes.func.isRequired,
    filterParam: PropTypes.string,
    loadMore: PropTypes.func.isRequired,
    hasMore: PropTypes.bool.isRequired,
    isFetching: PropTypes.bool.isRequired,
    resetSmartTips: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    tagsFilter: PropTypes.array.isRequired,
    setTagsFilter: PropTypes.func.isRequired,
};

SmartTipsHA.defaultProps = {
    filterParam: undefined,
};

export default SmartTipsHA;
