import styled, { css } from 'styled-components/macro';

export const TeamMessageWrapper = styled.div`
    position: relative;
    padding-top: 16px;

    svg {
        min-height: 24px;
        min-width: 24px;
        align-self: center;
        margin-left: 16px;
        cursor: pointer;
    }

    @media (max-width: ${({ theme }) => `${theme.breakpoints.l}px`}) {
        width: auto;
    }

    @media (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
        .hiddenSmall {
            display: none;
        }
    }
`;

export const TeamMessageEmojiDropdown = styled.div`
    position: absolute;
    left: 360px;
    bottom: 72px;
`;

export const buttonCustom = css`
    min-width: 90px;
    margin-left: 16px;
    height: 54px;
`;

export default styled;
