import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as CheckboxActiveIcon } from 'assets/svg/checkbox-active.svg';
import { ReactComponent as CheckboxInactiveIcon } from 'assets/svg/checkbox-inactive.svg';
import { ReactComponent as DropdownIcon } from 'assets/svg/dropdown-down.svg';

import Spinner from 'components/Spinner';
import InfiniteScroll from 'react-infinite-scroller';
import theme from 'assets/theme/mainTheme';
import Button from 'components/Button';
import { css } from 'styled-components/macro';

import {
    InputBase,
    InputWithLabelHolder,
    InputGroup,
    InputWithIconHolder,
    InputError,
    InputLabel,
    InputContainer,
    InputDropdownBody,
    InputApiDropdownBody,
    InputDropdownTitle,
    InputDropdownOption,
    InputDropdownSelectBody,
    InputDropdownSelectOption,
    OptionsGroup,
    InputOuterLabel,
    CtaGroup,
} from './Input.style';

const Input = React.forwardRef(
    (
        {
            variant,
            children,
            icon,
            options,
            selectOptions,
            handleSelect,
            dropdownTitle,
            dropdownVisible,
            onChange,
            onBlur,
            handleDropdownSelectSubmit,
            handleDropdownSelectCancel,
            containerStyles,
            error,
            label,
            labelLeft,
            displaySelectButtons,
            loadMore,
            hasMore,
            isLoadingOptions,
            withImg,
            selectButtonLabel,
            cancelButtonLabel,
            invalid,
            customDropdownSelectBodyWidth,
            customOptionGroupHeight,
            smallerDropdown,
            inputGroupStyle,
            ...rest
        },
        ref
    ) => {
        const dropdownRef = useRef(null);

        const handleClickOutside = (e) => {
            if (dropdownRef.current && dropdownRef.current.contains(e.target)) {
                return;
            }
            if (rest.onClick) rest.onClick();
        };

        useEffect(() => {
            if (dropdownVisible) {
                document.addEventListener('mousedown', handleClickOutside);
            } else {
                document.removeEventListener('mousedown', handleClickOutside);
            }
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [dropdownVisible]);

        switch (variant) {
            case 'group':
                return <InputGroup {...rest}>{children}</InputGroup>;

            case 'base':
                return (
                  <InputContainer
                        customStyles={containerStyles}
                        style={inputGroupStyle}
                    >
                    <InputBase
                            onChange={onChange}
                            onBlur={onBlur}
                            ref={ref}
                            error={!!error}
                            label={label}
                            invalid={invalid}
                            {...rest}
                        />
                    {label && <InputLabel>{label}</InputLabel>}
                    {error && <InputError>{error}</InputError>}
                  </InputContainer>
                );

            case 'withIcon':
                return (
                  <InputWithIconHolder customStyles={containerStyles}>
                    <InputBase
                            {...rest}
                            onChange={onChange}
                            onBlur={onBlur}
                            invalid={invalid}
                        />
                    {icon !== null && icon()}
                  </InputWithIconHolder>
                );

            case 'withLabelLeft':
                return (
                  <InputWithLabelHolder customStyles={containerStyles}>
                    <InputOuterLabel>{labelLeft}</InputOuterLabel>
                    <InputBase
                            {...rest}
                            onChange={onChange}
                            invalid={invalid}
                        />
                  </InputWithLabelHolder>
                );

            case 'dropdown':
                return (
                  <InputGroup
                        dropdown
                        ref={dropdownRef}
                        style={inputGroupStyle}
                    >
                    <InputWithIconHolder
                            dropdown
                            customStyles={containerStyles}
                        >
                      <InputBase
                                {...rest}
                                onChange={onChange}
                                invalid={invalid}
                            />
                      <DropdownIcon
                                fill={theme.color.textBase}
                                onClick={rest.onClick || undefined}
                            />
                    </InputWithIconHolder>
                    <InputDropdownBody isVisible={dropdownVisible}>
                      {dropdownTitle && (
                        <InputDropdownTitle>
                          {dropdownTitle}
                        </InputDropdownTitle>
                            )}
                      {options.map((option) => (
                        <InputDropdownOption
                                    key={option.id}
                                    onClick={() => handleSelect(option.id)}
                                >
                          {option.title}
                        </InputDropdownOption>
                            ))}
                    </InputDropdownBody>
                  </InputGroup>
                );

            case 'searchDropdown':
                return (
                  <InputGroup dropdown ref={dropdownRef}>
                    <InputWithIconHolder
                            dropdown
                            customStyles={containerStyles}
                        >
                      <InputBase
                                {...rest}
                                onChange={onChange}
                                invalid={invalid}
                            />
                      <DropdownIcon
                                fill={theme.color.textBase}
                                onClick={rest.onClick || undefined}
                            />
                    </InputWithIconHolder>

                    <InputDropdownBody
                            isVisible={dropdownVisible}
                            smallerDropdown={smallerDropdown}
                        >
                      {dropdownTitle && (
                        <InputDropdownTitle>
                          {dropdownTitle}
                        </InputDropdownTitle>
                            )}
                      {withImg
                                ? options.map((option) => (
                                  <InputDropdownOption
                                          key={option.id}
                                          onClick={() =>
                                              handleSelect(option.id)}
                                          withImg
                                      >
                                    <img
                                              src={option.imgUrl}
                                              alt={option.title}
                                          />
                                    <div
                                              style={{
                                                  display: 'flex',
                                                  flexDirection: 'column',
                                              }}
                                          >
                                      <span>{option.title}</span>
                                      <small>{option.subtitle}</small>
                                    </div>
                                  </InputDropdownOption>
                                  ))
                                : options.map((option) => (
                                  <InputDropdownOption
                                          key={option.id}
                                          onClick={() =>
                                              handleSelect(option.id)}
                                      >
                                    {option.title}
                                  </InputDropdownOption>
                                  ))}
                    </InputDropdownBody>
                  </InputGroup>
                );

            case 'searchDropdownApi':
                return (
                  <InputGroup
                        dropdown
                        ref={dropdownRef}
                        style={inputGroupStyle}
                    >
                    <InputBase onChange={onChange} {...rest} />
                    <InputApiDropdownBody isVisible={dropdownVisible}>
                      {dropdownTitle && (
                        <InputDropdownTitle>
                          {dropdownTitle}
                        </InputDropdownTitle>
                            )}
                      <InfiniteScroll
                                loadMore={loadMore}
                                hasMore={hasMore && !isLoadingOptions}
                                useWindow={false}
                            >
                        {options.map((option) => (
                          <InputDropdownOption
                                        key={option.id}
                                        onClick={() => {
                                            handleSelect(option);
                                        }}
                                    >
                            {option.label()}
                          </InputDropdownOption>
                                ))}
                      </InfiniteScroll>
                      {isLoadingOptions && (
                        <div
                                    style={{
                                        alignSelf: 'center',
                                    }}
                                >
                          <Spinner />
                        </div>
                            )}
                    </InputApiDropdownBody>
                  </InputGroup>
                );

            case 'searchDropdownSelectApi':
                return (
                  <InputGroup dropdown noMargin ref={dropdownRef}>
                    <InputBase {...rest} onChange={onChange} />
                    <InputDropdownSelectBody isVisible={dropdownVisible}>
                      <OptionsGroup>
                        <InfiniteScroll
                                    loadMore={loadMore}
                                    hasMore={hasMore && !isLoadingOptions}
                                    useWindow={false}
                                >
                          {selectOptions.map((option) => (
                            <InputDropdownSelectOption
                                            key={option.id}
                                            onClick={() =>
                                                handleSelect(option.id)}
                                        >
                              {option.label()}
                              {option.noSelectAction &&
                                                option.noSelectAction()}
                              {option.selected ? (
                                <CheckboxActiveIcon
                                                    fill={theme.color.primary}
                                                />
                                            ) : (
                                              <CheckboxInactiveIcon />
                                            )}
                            </InputDropdownSelectOption>
                                    ))}
                        </InfiniteScroll>
                        {isLoadingOptions && (
                        <div
                                        style={{
                                            alignSelf: 'center',
                                        }}
                                    >
                          <Spinner />
                        </div>
                                )}
                      </OptionsGroup>
                      {displaySelectButtons && (
                        <CtaGroup>
                          <Button
                                        white
                                        onClick={() =>
                                            handleDropdownSelectCancel()}
                                    >
                            {cancelButtonLabel}
                          </Button>
                          <Button
                                        primary
                                        onClick={() =>
                                            handleDropdownSelectSubmit()}
                                    >
                            {selectButtonLabel}
                          </Button>
                        </CtaGroup>
                            )}
                    </InputDropdownSelectBody>
                  </InputGroup>
                );

            case 'searchDropdownSelect':
                return (
                  <InputGroup dropdown noMargin ref={dropdownRef}>
                    <InputBase {...rest} onChange={onChange} />
                    <InputDropdownSelectBody
                            isVisible={dropdownVisible}
                            width={customDropdownSelectBodyWidth}
                            verticalPadding={
                                customOptionGroupHeight !== undefined
                            }
                        >
                      <OptionsGroup height={customOptionGroupHeight}>
                        {selectOptions.map((option) => (
                          <InputDropdownSelectOption
                                        key={option.id}
                                        onClick={() => handleSelect(option.id)}
                                    >
                            {option.label()}
                            {option.selected ? (
                              <CheckboxActiveIcon
                                                fill={theme.color.primary}
                                            />
                                        ) : (
                                          <CheckboxInactiveIcon />
                                        )}
                          </InputDropdownSelectOption>
                                ))}
                      </OptionsGroup>
                      {displaySelectButtons && (
                        <CtaGroup>
                          <Button
                                        white
                                        onClick={() =>
                                            handleDropdownSelectCancel()}
                                    >
                            Cancel
                          </Button>
                          <Button
                                        primary
                                        onClick={() =>
                                            handleDropdownSelectSubmit()}
                                    >
                            Invite
                          </Button>
                        </CtaGroup>
                            )}
                    </InputDropdownSelectBody>
                  </InputGroup>
                );

            default:
                return <InputBase {...rest} />;
        }
    }
);

Input.propTypes = {
    dropdownTitle: PropTypes.string,
    selectButtonLabel: PropTypes.string,
    cancelButtonLabel: PropTypes.string,
    dropdownVisible: PropTypes.bool,
    variant: PropTypes.string,
    handleSelect: PropTypes.func,
    containerStyles: PropTypes.any || null,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    icon:
        PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.node),
            PropTypes.node,
        ]) || null,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            title: PropTypes.string,
        })
    ),
    selectOptions: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            label: PropTypes.func,
            selected: PropTypes.bool,
            noSelectAction: PropTypes.func || PropTypes.node || PropTypes.any,
        })
    ),
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    handleDropdownSelectSubmit: PropTypes.func,
    handleDropdownSelectCancel: PropTypes.func,
    error: PropTypes.string,
    label: PropTypes.string,
    labelLeft: PropTypes.string,
    displaySelectButtons: PropTypes.bool,
    hasMore: PropTypes.bool,
    invalid: PropTypes.bool,
    withImg: PropTypes.bool,
    isLoadingOptions: PropTypes.bool,
    loadMore: PropTypes.func,
    customDropdownSelectBodyWidth: PropTypes.string,
    customOptionGroupHeight: PropTypes.string,
    smallerDropdown: PropTypes.bool,
    name: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    inputGroupStyle: PropTypes.object,
};

Input.defaultProps = {
    name: '',
    variant: 'base',
    error: null,
    label: null,
    labelLeft: null,
    dropdownTitle: '',
    selectButtonLabel: 'Invite',
    cancelButtonLabel: 'Cancel',
    dropdownVisible: false,
    withImg: false,
    invalid: false,
    containerStyles: css``,
    children: <></>,
    icon: null,
    handleSelect: () => {},
    onChange: () => {},
    onBlur: () => {},
    handleDropdownSelectSubmit: () => {},
    handleDropdownSelectCancel: () => {},
    options: [],
    selectOptions: [],
    displaySelectButtons: true,
    hasMore: false,
    isLoadingOptions: false,
    loadMore: () => {},
    customDropdownSelectBodyWidth: undefined,
    customOptionGroupHeight: undefined,
    smallerDropdown: false,
    inputGroupStyle: {},
};

export default Input;
