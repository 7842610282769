import styled, { css } from 'styled-components/macro';

export const StyledNavbarResponsive = styled.nav`
    background-color: ${({ theme }) => theme.color.bgBase};
    height: 90px;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 998;
    padding: 16px 32px;
    transition: padding 0.2s ease;
    justify-content: flex-end;
    align-items: center;
    display: none;

    @media (max-width: ${({ theme }) => `${theme.breakpoints.mobile}px`}) {
        display: flex;
    }
`;

export const StyledUserData = styled.div`
    height: 29px;
    font-weight: 500;
    display: flex;
    padding-left: 12px;
    align-items: center;
    cursor: pointer;
    white-space: nowrap;
    border-left: none;

    img {
        margin-left: 12px;
        width: 29px;
        height: 29px;
        border-radius: 15px;
        object-fit: cover;
    }

    a {
        display: flex;
        align-items: center;
    }

    @media (max-width: ${({ theme }) => `${theme.breakpoints.s}px`}) {
        .currentUserName {
            display: none;
        }
    }
`;

export const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 49px;
    height: 49px;
    border-radius: ${({ theme }) => theme.border.radiusBase};
    cursor: pointer;

    &:hover {
        background-color: ${({ theme }) => theme.color.bgPrimaryLight};
    }

    ${({ faq }) =>
        faq &&
        css`
            margin-left: 8px;
        `}
`;
