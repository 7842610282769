import {
    establishOrganizationTeams,
    establishOrganizationTeamsFailure,
    establishOrganizationTeamsSuccess,
    loadMoreOrganizationTeams,
    allTeamsLoaded,
    resetOrganizationTeams,
} from 'redux/actions/organizationTeams';
import apiRoutes from 'config/apiRoutes';
import paramsToString from 'redux/helpers/paramsToString';

const establishOrganizationTeamsThunk = (
    getAccessTokenSilently,
    logout,
    limit,
    offset,
    params = {}
) => (dispatch) => {
    dispatch(establishOrganizationTeams());

    const url =
        apiRoutes.get.organizationTeams(limit, offset) + paramsToString(params);

    return getAccessTokenSilently()
        .then((token) => {
            fetch(url, {
                method: 'GET',
                headers: {
                    organization_slug: window.localStorage.getItem(
                        'MP_ORGANIZATION'
                    ),
                    Authorization: `Token token="${token}"`,
                },
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.error) {
                        return dispatch(
                            establishOrganizationTeamsFailure(res.error)
                        );
                    }

                    return dispatch(
                        establishOrganizationTeamsSuccess(res.teams)
                    );
                })
                .catch((err) =>
                    dispatch(
                        establishOrganizationTeamsFailure({
                            err,
                        })
                    )
                )
                .finally(() => !limit && !offset && dispatch(allTeamsLoaded()));
        })
        .catch(() => logout({ returnTo: window.location.origin }));
};

export const loadMoreOrganizationTeamsThunk = (
    getAccessTokenSilently,
    logout,
    limit,
    offset,
    params = {}
) => (dispatch) => {
    dispatch(establishOrganizationTeams());

    const url =
        apiRoutes.get.organizationTeams(limit, offset) + paramsToString(params);

    return getAccessTokenSilently()
        .then((token) => {
            fetch(url, {
                method: 'GET',
                headers: {
                    organization_slug: window.localStorage.getItem(
                        'MP_ORGANIZATION'
                    ),
                    Authorization: `Token token="${token}"`,
                },
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.error) {
                        return dispatch(
                            establishOrganizationTeamsFailure(res.error)
                        );
                    }

                    if (res.teams.length === 0) {
                        return dispatch(allTeamsLoaded());
                    }

                    return dispatch(loadMoreOrganizationTeams(res.teams));
                })
                .catch((err) =>
                    dispatch(
                        establishOrganizationTeamsFailure({
                            err,
                        })
                    )
                );
        })
        .catch(() => logout({ returnTo: window.location.origin }));
};

export const resetOrganizationTeamsThunk = () => (dispatch) => {
    dispatch(resetOrganizationTeams());
};

export default establishOrganizationTeamsThunk;
