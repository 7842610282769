import styled, { css } from 'styled-components/macro';

export const AccordionContainer = styled.div`
    width: 100%;
    ${({ isAccordionOpen }) =>
        !isAccordionOpen &&
        css`
            & > * {
                border: none !important;
            }
        `}

    ${({ theme }) => css`
        background-color: ${theme.color.white};
        border: ${theme.border.base};
        border-radius: ${theme.border.radiusBase};
    `}

    ${({ customStyles }) =>
        customStyles &&
        css`
            ${customStyles}
        `}
`;

export const AccordionLabel = styled.div`
    width: 100%;
    border-radius: ${({ theme }) => theme.border.radiusBase};
    background-color: ${({ theme }) => theme.color.white};

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 24px 24px;

    border: none;
    border-bottom: inherit;

    strong {
        font-size: ${({ theme }) => theme.fontSize.big};
    }

    ${({ expanded }) =>
        expanded &&
        css`
            border-radius: ${({ theme }) =>
                `${theme.border.radiusBase} ${theme.border.radiusBase} 0 0`};
        `}

    ${({ regularFont }) =>
        regularFont &&
        css`
            strong {
                font-size: ${({ theme }) => theme.fontSize.medium};
            }
        `}

    ${({ expandOnlyOnArrowClick }) =>
        !expandOnlyOnArrowClick &&
        css`
            cursor: pointer;
        `}
`;

export const AccordionLabelContent = styled.div`
    width: 90%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;

    ${({ threeColumns }) =>
        threeColumns &&
        css`
            width: 100%;
            display: grid;
            grid-template-columns: 2fr 6fr 4fr;
            grid-gap: 16px;
            align-items: center;
            justify-items: flex-start;
        `}
`;

export const AccordionStatusIconHolder = styled.div`
    width: 48px;
    height: 48px;
    background-color: ${({ theme }) => theme.color.bgPrimaryLight};
    border-radius: ${({ theme }) => theme.border.radiusBase};
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    ${({ circular }) =>
        circular &&
        css`
            width: 32px;
            height: 32px;
            border-radius: 50%;
        `}
`;

export const AccordionContent = styled.div`
    max-height: 0px;
    overflow: hidden;
    overflow-y: auto;
    transition: max-height 450ms ease;

    &.accordionContent {
        .accordionContent__item {
            padding: 16px 0;
            &:nth-child(even) {
                background-color: #fbfcfe;
            }
            &:nth-child(odd) {
                background-color: #fff;
            }
        }
    }

    :last-child {
        border-radius: ${({ theme }) => theme.border.radiusBase};
    }

    ${({ expanded }) =>
        expanded &&
        css`
            max-height: 700px;
        `}

    @media (max-width: ${({ theme }) => `${theme.breakpoints.medium}px`}) {
        overflow-x: scroll;
    }
`;

export const SecondLabel = styled.div`
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    color: ${({ theme }) => theme.color.textLight};
`;
