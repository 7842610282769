import styled from 'styled-components/macro';

export const StyledCongratsWrapper = styled.div`
    width: 100%;
    height: 100%;
    padding: 16px 0;
`;

export const StyledCongrats = styled.div`
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.color.bgPrimaryLight};
    border-radius: ${({ theme }) => theme.border.radiusBase};

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    strong,
    span,
    * {
        user-select: none;
    }

    strong {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        margin-top: 16px;
        margin-bottom: 16px;
    }

    span {
        font-size: 12px;
        line-height: 18px;
    }

    @media (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
        .hiddenSmall {
            display: none;
        }
    }
`;
