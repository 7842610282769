export const ESTABLISH_ORGANIZATION_SMART_TIP_DETAILS =
    'ESTABLISH_ORGANIZATION_SMART_TIP_DETAILS';
export const ESTABLISH_ORGANIZATION_SMART_TIP_DETAILS_SUCCESS =
    'ESTABLISH_ORGANIZATION_SMART_TIP_DETAILS_SUCCESS';
export const ESTABLISH_ORGANIZATION_SMART_TIP_DETAILS_FAILURE =
    'ESTABLISH_ORGANIZATION_SMART_TIP_DETAILS_FAILURE';

export const ESTABLISH_ORGANIZATION_SMART_TIP_ASSIGNED_USERS =
    'ESTABLISH_ORGANIZATION_SMART_TIP_ASSIGNED_USERS';
export const ESTABLISH_ORGANIZATION_SMART_TIP_ASSIGNED_USERS_SUCCESS =
    'ESTABLISH_ORGANIZATION_SMART_TIP_ASSIGNED_USERS_SUCCESS';
export const ESTABLISH_ORGANIZATION_SMART_TIP_ASSIGNED_USERS_FAILURE =
    'ESTABLISH_ORGANIZATION_SMART_TIP_ASSIGNED_USERS_FAILURE';

export const ESTABLISH_ORGANIZATION_SMART_TIP_ASSIGNED_TEAMS =
    'ESTABLISH_ORGANIZATION_SMART_TIP_ASSIGNED_TEAMS';
export const ESTABLISH_ORGANIZATION_SMART_TIP_ASSIGNED_TEAMS_SUCCESS =
    'ESTABLISH_ORGANIZATION_SMART_TIP_ASSIGNED_TEAMS_SUCCESS';
export const ESTABLISH_ORGANIZATION_SMART_TIP_ASSIGNED_TEAMS_FAILURE =
    'ESTABLISH_ORGANIZATION_SMART_TIP_ASSIGNED_TEAMS_FAILURE';

export const RESET_ORGANIZATION_SMART_TIP_DETAILS =
    'RESET_ORGANIZATION_SMART_TIP_DETAILS';

export const establishOrganizationSmartTipDetails = () => ({
    type: ESTABLISH_ORGANIZATION_SMART_TIP_DETAILS,
});
export const establishOrganizationSmartTipDetailsSuccess = (payload) => ({
    type: ESTABLISH_ORGANIZATION_SMART_TIP_DETAILS_SUCCESS,
    payload,
});
export const establishOrganizationSmartTipDetailsFailure = (err) => ({
    type: ESTABLISH_ORGANIZATION_SMART_TIP_DETAILS_FAILURE,
    err,
});

export const establishOrganizationSmartTipAssignedUsers = () => ({
    type: ESTABLISH_ORGANIZATION_SMART_TIP_ASSIGNED_USERS,
});
export const establishOrganizationSmartTipAssignedUsersSuccess = (payload) => ({
    type: ESTABLISH_ORGANIZATION_SMART_TIP_ASSIGNED_USERS_SUCCESS,
    payload,
});
export const establishOrganizationSmartTipAssignedUsersFailure = (err) => ({
    type: ESTABLISH_ORGANIZATION_SMART_TIP_ASSIGNED_USERS_FAILURE,
    err,
});

export const establishOrganizationSmartTipAssignedTeams = () => ({
    type: ESTABLISH_ORGANIZATION_SMART_TIP_ASSIGNED_TEAMS,
});
export const establishOrganizationSmartTipAssignedTeamsSuccess = (payload) => ({
    type: ESTABLISH_ORGANIZATION_SMART_TIP_ASSIGNED_TEAMS_SUCCESS,
    payload,
});
export const establishOrganizationSmartTipAssignedTeamsFailure = (err) => ({
    type: ESTABLISH_ORGANIZATION_SMART_TIP_ASSIGNED_TEAMS_FAILURE,
    err,
});

export const resetOrganizationSmartTipDetails = () => ({
    type: RESET_ORGANIZATION_SMART_TIP_DETAILS,
});
