import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import Input from 'components/Input';
import InfiniteScroll from 'react-infinite-scroller';
import Spinner from 'components/Spinner';
import SingleManageUserId from 'containers/SingleManageUserId';
import { ReactComponent as QuestionMark } from 'assets/svg/question-mark-circle.svg';

import {
    ModalBody,
    FilterWrapper,
    Label,
    InputWrapper,
    Filter,
    ListWrapper,
    AmountOfResults,
    Header,
    HeaderItem,
    TableWrapper,
    ListItem,
    Tooltip,
} from './ManageUsersIdsModal.style';

const ManageUsersIdsModal = ({
    users,
    totalCount,
    filter,
    setFilter,
    search,
    handleClick,
    loadMore,
    hasMore,
}) => (
  <ModalBody>
    <FilterWrapper>
      <Filter>
        <Label>User Name</Label>
        <InputWrapper>
          <Input
                        value={filter}
                        onChange={(e) => setFilter(e.target.value)}
                        placeholder="Fill User Name"
                        onKeyDown={handleClick}
                    />
          <Button primaryDark onClick={search}>
            Search
          </Button>
        </InputWrapper>
      </Filter>
    </FilterWrapper>
    <TableWrapper>
      <AmountOfResults>{`${totalCount} Users`}</AmountOfResults>
      <Header>
        <HeaderItem>User Name</HeaderItem>
        <HeaderItem>
          User ID or Mnemonic
          {' '}
          <Tooltip>
            <QuestionMark />
            <div className="tooltipText">
              Unique ID used to connect User to imported
              performance data.
            </div>
          </Tooltip>
        </HeaderItem>
      </Header>
      <ListWrapper>
        <InfiniteScroll
                    initialLoad={false}
                    loadMore={loadMore}
                    hasMore={hasMore}
                    useWindow={false}
                    loader={<Spinner key="spinner" />}
                >
          {users.map((user) => (
            <ListItem key={user.id}>
              <SingleManageUserId user={user} />
            </ListItem>
                    ))}
        </InfiniteScroll>
      </ListWrapper>
    </TableWrapper>
  </ModalBody>
);

ManageUsersIdsModal.propTypes = {
    users: PropTypes.arrayOf(PropTypes.any),
    totalCount: PropTypes.number,
    filter: PropTypes.string.isRequired,
    setFilter: PropTypes.func.isRequired,
    search: PropTypes.func.isRequired,
    handleClick: PropTypes.func.isRequired,
    loadMore: PropTypes.func.isRequired,
    hasMore: PropTypes.bool.isRequired,
};

ManageUsersIdsModal.defaultProps = {
    users: [],
    totalCount: 0,
};

export default ManageUsersIdsModal;
