import {
    establishMembers,
    establishMembersFailure,
    establishMembersSuccess,
    resetMembers,
    removeMember,
    removeMemberSuccess,
    removeMemberFailure,
    leaveTeam,
    leaveTeamSuccess,
    leaveTeamFailure,
} from 'redux/actions/teamMembers';
import {
    dispatchSuccessAlertThunk,
    dispatchErrorAlertThunk,
} from 'redux/actions/thunks/systemAlerts';
import apiRoutes from 'config/apiRoutes';
import { ROUTES } from 'routing/constants';

export const establishTeamMembersThunk = (
    getAccessTokenSilently,
    logout,
    teamId
) => (dispatch) => {
    dispatch(establishMembers());

    return getAccessTokenSilently()
        .then((token) => {
            fetch(apiRoutes.get.teamMembers(teamId), {
                method: 'GET',
                headers: {
                    organization_slug: window.localStorage.getItem(
                        'MP_ORGANIZATION'
                    ),
                    Authorization: `Token token="${token}"`,
                },
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.error) {
                        return dispatch(establishMembersFailure(res.error));
                    }

                    return dispatch(establishMembersSuccess(res.users));
                })
                .catch((err) =>
                    dispatch(
                        establishMembersFailure({
                            err,
                        })
                    )
                );
        })
        .catch(() => logout({ returnTo: window.location.origin }));
};

export const removeMemberThunk = (
    getAccessTokenSilently,
    logout,
    teamId,
    memberId,
    isAdmin = false
) => (dispatch) => {
    dispatch(removeMember());

    const route = isAdmin
        ? apiRoutes.delete.removeOrganizationMember(teamId, memberId)
        : apiRoutes.delete.removeTeamMember(teamId, memberId);

    return getAccessTokenSilently()
        .then((token) => {
            fetch(route, {
                method: 'DELETE',
                headers: {
                    organization_slug: window.localStorage.getItem(
                        'MP_ORGANIZATION'
                    ),
                    Authorization: `Token token="${token}"`,
                },
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.error) {
                        dispatch(dispatchErrorAlertThunk(teamId, res.error));
                        return dispatch(removeMemberFailure(res.error));
                    }

                    dispatch(
                        dispatchSuccessAlertThunk(
                            teamId,
                            'User has been removed from the team'
                        )
                    );
                    dispatch(
                        establishTeamMembersThunk(
                            getAccessTokenSilently,
                            logout,
                            teamId
                        )
                    );
                    return dispatch(removeMemberSuccess());
                })
                .catch((err) => {
                    dispatch(dispatchErrorAlertThunk(teamId, err.error));
                    dispatch(
                        removeMemberFailure({
                            err,
                        })
                    );
                });
        })
        .catch(() => logout({ returnTo: window.location.origin }));
};

export const leaveTeamThunk = (getAccessTokenSilently, logout, teamId) => (
    dispatch
) => {
    dispatch(leaveTeam());

    return getAccessTokenSilently()
        .then((token) => {
            fetch(apiRoutes.delete.leaveTeam(teamId), {
                method: 'DELETE',
                headers: {
                    organization_slug: window.localStorage.getItem(
                        'MP_ORGANIZATION'
                    ),
                    Authorization: `Token token="${token}"`,
                },
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.error) {
                        dispatch(dispatchErrorAlertThunk(teamId, res.error));
                        return dispatch(leaveTeamFailure(res.error));
                    }

                    dispatch(
                        dispatchSuccessAlertThunk(
                            teamId,
                            'You have left the team'
                        )
                    );
                    dispatch(
                        establishTeamMembersThunk(
                            getAccessTokenSilently,
                            logout,
                            teamId
                        )
                    );
                    document.location = ROUTES.team;
                    return dispatch(leaveTeamSuccess());
                })
                .catch((err) => {
                    dispatch(dispatchErrorAlertThunk(teamId, err.error));
                    dispatch(
                        leaveTeamFailure({
                            err,
                        })
                    );
                });
        })
        .catch(() => logout({ returnTo: window.location.origin }));
};

export const resetMembersThunk = () => (dispatch) => {
    dispatch(resetMembers());
};
