import {
    ESTABLISH_MPA_ADMIN_MEMOS,
    ESTABLISH_MPA_ADMIN_MEMOS_SUCCESS,
    ESTABLISH_MPA_ADMIN_MEMOS_FAILURE,
    LOAD_MORE_MPA_ADMIN_MEMOS,
    ALL_MPA_ADMIN_MEMOS_LOADED,
    RESET_MPA_ADMIN_MEMOS,
} from 'redux/actions/mpaAdminMemos';

export const initialState = {
    areMPAAdminMemosFetching: false,
    hasMoreMPAAdminMemos: true,
    adminMemos: [],
    isMPAAdminMemosError: false,
    error: null,
};

const adminMemos = (state = initialState, action) => {
    switch (action.type) {
        case ESTABLISH_MPA_ADMIN_MEMOS:
            return {
                ...state,
                areMPAAdminMemosFetching: true,
                isMPAAdminMemosError: false,
            };

        case ESTABLISH_MPA_ADMIN_MEMOS_SUCCESS:
            return {
                ...state,
                areMPAAdminMemosFetching: false,
                adminMemos: action.payload,
            };

        case ESTABLISH_MPA_ADMIN_MEMOS_FAILURE:
            return {
                ...state,
                areMPAAdminMemosFetching: false,
                isMPAAdminMemosError: true,
                error: action.err,
            };

        case LOAD_MORE_MPA_ADMIN_MEMOS:
            return {
                ...state,
                areMPAAdminMemosFetching: false,
                adminMemos: [...state.adminMemos, ...action.payload],
            };

        case ALL_MPA_ADMIN_MEMOS_LOADED:
            return {
                ...state,
                hasMoreMPAAdminMemos: false,
                areMPAAdminMemosFetching: false,
            };

        case RESET_MPA_ADMIN_MEMOS:
            return initialState;

        default:
            return state;
    }
};

export default adminMemos;
