import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as SuccessIcon } from 'assets/svg/check-circle.svg';
import { ReactComponent as ErrorIcon } from 'assets/svg/alert-triangle.svg';
import { ReactComponent as CloseIcon } from 'assets/svg/close.svg';
import theme from 'assets/theme/mainTheme';
import {
    AlertContainer,
    IconHolder,
    DismissIconHolder,
    MessageWrapper,
} from './SystemAlert.style';

const GENERIC_ALERT_SUCCESS_HEADER = 'Success';
const GENERIC_ALERT_ERROR_HEADER = 'Sorry, there was an error';
const GENERIC_ALERT_FATAL_ERROR_MESSAGE =
    'Something went wrong with your request. Please try again later'; // Displayed on occurrence of 403/500 or other request errors

const SystemAlert = ({ type, message, dismiss }) => {
    const [isDismissed, setToDismiss] = useState(false);
    const onDismiss = () => {
        setToDismiss(true);
        setTimeout(dismiss, 150);
    };

    useEffect(() => {
        const timer = setTimeout(onDismiss, 3000);

        return () => clearTimeout(timer);
    }, []);

    return (
      <AlertContainer isDismissed={isDismissed}>
        <IconHolder error={type === 'error'}>
          {type === 'success' ? (
            <SuccessIcon fill={theme.color.white} />
                ) : (
                  <ErrorIcon fill={theme.color.white} />
                )}
        </IconHolder>
        <MessageWrapper>
          <strong>
            {type === 'success'
                        ? GENERIC_ALERT_SUCCESS_HEADER
                        : GENERIC_ALERT_ERROR_HEADER}
          </strong>
          <small>
            {message === 'fatal'
                        ? GENERIC_ALERT_FATAL_ERROR_MESSAGE
                        : message}
          </small>
        </MessageWrapper>
        <DismissIconHolder>
          <CloseIcon fill={theme.color.textLight} onClick={onDismiss} />
        </DismissIconHolder>
      </AlertContainer>
    );
};

SystemAlert.propTypes = {
    type: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    dismiss: PropTypes.func.isRequired,
};

export default SystemAlert;
