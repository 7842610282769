import {
    ESTABLISH_METRIC_UPLOADS,
    ESTABLISH_METRIC_UPLOADS_SUCCESS,
    ESTABLISH_METRIC_UPLOADS_FAILURE,
    DESTROY_METRIC_UPLOAD,
    LOAD_MORE_METRIC_UPLOADS,
    MARK_ALL_METRIC_UPLOADS_LOADED,
    RESET_METRIC_UPLOADS,
} from 'redux/actions/metricUploads';

export const initialState = {
    areMetricUploadsEstablished: false,
    isMetricUploadsError: false,
    areMetricUploadsFetching: false,
    error: null,
    metricUploads: [],
    hasMoreMetricUploads: true,
};

const metricUploads = (state = initialState, action) => {
    switch (action.type) {
        case DESTROY_METRIC_UPLOAD:
        case ESTABLISH_METRIC_UPLOADS:
            return {
                ...state,
                areMetricUploadsFetching: true,
                isMetricUploadsError: false,
            };

        case ESTABLISH_METRIC_UPLOADS_SUCCESS:
            return {
                ...state,
                areMetricUploadsFetching: false,
                areMetricUploadsEstablished: true,
                metricUploads: action.payload,
            };

        case ESTABLISH_METRIC_UPLOADS_FAILURE:
            return {
                ...state,
                areMetricUploadsFetching: false,
                isMetricUploadsError: true,
                error: action.err,
            };

        case LOAD_MORE_METRIC_UPLOADS:
            return {
                ...state,
                areMetricUploadsFetching: false,
                areMetricUploadsEstablished: true,
                metricUploads: [...state.metricUploads, ...action.payload],
            };
        case MARK_ALL_METRIC_UPLOADS_LOADED:
            return {
                ...state,
                hasMoreMetricUploads: false,
                areMetricUploadsFetching: false,
                areMetricUploadsEstablished: true,
            };

        case RESET_METRIC_UPLOADS:
            return initialState;

        default:
            return state;
    }
};

export default metricUploads;
