import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useOffset from 'hooks/useOffset';
import establishMPAAdminMemosThunk, {
    loadMoreMPAAdminMemosThunk,
    resetMPAAdminMemosThunk,
    revertMPAAdminMemoThunk,
} from 'redux/actions/thunks/mpaAdminMemos';
import MPAAdminMemosView from 'views/MPAAdminMemos';
import { useAuth0 } from '@auth0/auth0-react';

const INFINITE_SCROLL_THRESHOLD = 20;

const MPAAdminMemos = () => {
    const dispatch = useDispatch();
    const { getAccessTokenSilently, logout } = useAuth0();
    const [offset, incrementOffset, resetOffset] = useOffset(
        INFINITE_SCROLL_THRESHOLD
    );

    const {
        areMPAAdminMemosFetching,
        hasMoreMPAAdminMemos,
        adminMemos,
    } = useSelector((state) => state.mpaAdminMemos);

    const hasMore = hasMoreMPAAdminMemos && !areMPAAdminMemosFetching;

    const loadMore = () => {
        dispatch(
            loadMoreMPAAdminMemosThunk(
                getAccessTokenSilently,
                logout,
                INFINITE_SCROLL_THRESHOLD,
                offset
            )
        );
        incrementOffset();
    };

    useEffect(() => {
        dispatch(
            establishMPAAdminMemosThunk(
                getAccessTokenSilently,
                logout,
                INFINITE_SCROLL_THRESHOLD,
                0
            )
        );
        resetOffset(INFINITE_SCROLL_THRESHOLD);
        return dispatch(resetMPAAdminMemosThunk());
    }, []);

    const revertMPAAdminMemo = (adminMemoId) => {
        dispatch(
            revertMPAAdminMemoThunk(
                getAccessTokenSilently,
                logout,
                adminMemoId,
                reloadMPAAdminMemos
            )
        );
    };

    const reloadMPAAdminMemos = () => {
        dispatch(resetMPAAdminMemosThunk());
        dispatch(
            establishMPAAdminMemosThunk(
                getAccessTokenSilently,
                logout,
                INFINITE_SCROLL_THRESHOLD,
                0
            )
        );
        resetOffset(INFINITE_SCROLL_THRESHOLD);
    };

    return (
      <MPAAdminMemosView
            isFetching={areMPAAdminMemosFetching}
            adminMemos={adminMemos}
            hasMore={hasMore}
            loadMore={loadMore}
            revertMPAAdminMemo={revertMPAAdminMemo}
            reloadMPAAdminMemos={reloadMPAAdminMemos}
        />
    );
};

export default MPAAdminMemos;
