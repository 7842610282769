import React from 'react';
import { css } from 'styled-components/macro';
import { StyledMenuitemTitle } from 'containers/SidebarContent/SidebarContent.style';
import { sidebarMenu } from 'containers/SidebarContent/SidebarContent.mock';
import SmartTip from 'components/SmartTip';
import UploadAvatarOnboarding from 'containers/UploadAvatarOnboarding';

export const ONBOARDING_START = 'ONBOARDING_START';
export const UPLOAD_PROFILE_PIC = 'UPLOAD_PROFILE_PIC';
export const SMART_TIPS_EXPLAINER = 'SMART_TIPS_EXPLAINER';
export const WHITE_ST_EXPLAINER = 'WHITE_ST_EXPLAINER';
export const BLUE_ST_EXPLAINER = 'BLUE_ST_EXPLAINER';
export const PURPLE_ST_EXPLAINER = 'PURPLE_ST_EXPLAINER';
export const PERFORMANCE_EXPLAINER = 'PERFORMANCE_EXPLAINER';
export const PERFORMANCE_CHART_EXPLAINER = 'PERFORMANCE_CHART_EXPLAINER';
export const PERFORMANCE_METRICS_EXPLAINER = 'PERFORMANCE_METRICS_EXPLAINER';
export const COURSES_EXPLAINER = 'COURSES_EXPLAINER';
export const ONBOARDING_FINISH = 'ONBOARDING_FINISH';

export const onboardingSteps = [
    {
        verboseId: UPLOAD_PROFILE_PIC,
        render() {
            return (
              <>
                <UploadAvatarOnboarding />
                <h2>Upload profile picture</h2>
                <p>Click on arrow above to upload your picture.</p>
              </>
            );
        },
        compact: false,
        positioning: undefined,
        showArrow: undefined,
    },
    {
        verboseId: SMART_TIPS_EXPLAINER,
        render() {
            return (
              <>
                <h2>Power up!</h2>
                <p>
                  Click here to explore all the Smart Tips that are
                  available to help boost your knowledge and performance.
                </p>
              </>
            );
        },
        compact: true,
        positioning: css`
            top: 124px;
            left: 335px;
        `,
        showArrow: 'topLeft',
        highlightedElement() {
            return (
              <>
                {sidebarMenu[1].icon()}
                <StyledMenuitemTitle>
                  {sidebarMenu[1].title}
                </StyledMenuitemTitle>
              </>
            );
        },
        highlightedElementStyles: css`
            align-items: center;
            justify-content: flex-start;
            padding: 14px;
            width: 290px;
            height: 50px;
            left: 16px;
            top: 170px;
        `,
    },
    {
        verboseId: WHITE_ST_EXPLAINER,
        render() {
            return (
              <>
                <h2>White Smart Tips</h2>
                <p>
                  Tips recommended by department heads, IT admins, and
                  team members.
                </p>
              </>
            );
        },
        compact: true,
        positioning: css`
            top: 260px;
            left: 339px;
        `,
        showArrow: 'topRight',
        highlightedElement() {
            return (
              <SmartTip
                    tipId="onboardingWhite"
                    title="Workflow update: medication documentation"
                    size="compact"
                    type="tip"
                    typeLabel="Smart Tip"
                    openModalById={() => {}}
                    clickable={false}
                />
            );
        },
        highlightedElementStyles: css`
            background-color: unset;
            left: 760px;
            top: 236px;
        `,
    },
    {
        verboseId: BLUE_ST_EXPLAINER,
        render() {
            return (
              <>
                <h2>Blue Smart Tips</h2>
                <p style={{ color: '#ffffff' }}>
                  Tips recommended based on your individual goals and
                  performance.
                </p>
              </>
            );
        },
        compact: true,
        positioning: css`
            top: 260px;
            left: 339px;
        `,
        showArrow: 'topRight',
        highlightedElement() {
            return (
              <SmartTip
                    tipId="onboardingBlue"
                    title="How to properly screen for future fall risk."
                    size="compact"
                    type="recommendation"
                    typeLabel="Performance Tip"
                    openModalById={() => {}}
                    clickable={false}
                    style={{ border: 'none' }}
                />
            );
        },
        highlightedElementStyles: css`
            background-color: unset;
            left: 760px;
            top: 236px;
            border: none !important;
        `,
    },
    {
        verboseId: PURPLE_ST_EXPLAINER,
        render() {
            return (
              <>
                <h2>Purple Smart Tips</h2>
                <p>
                  Recommended Tips of the Week that we think you will find
                  helpful.
                </p>
              </>
            );
        },
        compact: true,
        positioning: css`
            top: 260px;
            left: 339px;
        `,
        showArrow: 'topRight',
        highlightedElement() {
            return (
              <SmartTip
                    tipId="onboardingPurple"
                    title="Check out our new patient scheduling procedures"
                    size="compact"
                    type="tip_of_the_week"
                    typeLabel="Tip of the Week"
                    openModalById={() => {}}
                    clickable={false}
                    style={{ border: 'none' }}
                />
            );
        },
        highlightedElementStyles: css`
            background-color: unset;
            left: 760px;
            top: 236px;
        `,
    },
    {
        verboseId: PERFORMANCE_EXPLAINER,
        render() {
            return (
              <>
                <h2>Dig into the data!</h2>
                <p>
                  Click here to see personalized performance data and find
                  targeted online training.
                </p>
              </>
            );
        },
        compact: true,
        positioning: css`
            top: 195px;
            left: 335px;
        `,
        showArrow: 'topLeft',
        highlightedElement() {
            return (
              <>
                {sidebarMenu[2].icon()}
                <StyledMenuitemTitle>
                  {sidebarMenu[2].title}
                </StyledMenuitemTitle>
              </>
            );
        },
        highlightedElementStyles: css`
            align-items: center;
            justify-content: flex-start;
            padding: 14px;
            width: 290px;
            height: 50px;
            left: 16px;
            top: 235px;
        `,
    },
    {
        verboseId: PERFORMANCE_CHART_EXPLAINER,
        render() {
            return (
              <>
                <h2>What&apos;s trending?</h2>
                <p>
                  See how your overall performance is trending over time
                  and how well you are doing versus your peers.
                </p>
              </>
            );
        },
        compact: true,
        positioning: css`
            top: 233px;
            left: 50%;
        `,
        showArrow: 'topLeft',
    },
    {
        verboseId: PERFORMANCE_METRICS_EXPLAINER,
        render() {
            return (
              <>
                <h2>Personalized performance scorecard</h2>
                <p>
                  In this section you will see a detailed breakdown of
                  your results for important measures and metrics along
                  with corresponding goals and recommended Smart Tips.
                </p>
              </>
            );
        },
        compact: true,
        positioning: css`
            top: 390px;
            left: 45%;
        `,
        showArrow: 'bottom',
    },
    {
        verboseId: COURSES_EXPLAINER,
        render() {
            return (
              <>
                <h2>Dive in deeper</h2>
                <p>
                  Click here to browse through and take full online
                  courses you are enrolled in.
                </p>
              </>
            );
        },
        compact: true,
        positioning: css`
            top: 325px;
            left: 338px;
        `,
        showArrow: 'topLeft',
        highlightedElement() {
            return (
              <>
                {sidebarMenu[4].icon()}
                <StyledMenuitemTitle>
                  {sidebarMenu[4].title}
                </StyledMenuitemTitle>
              </>
            );
        },
        highlightedElementStyles: css`
            align-items: center;
            justify-content: flex-start;
            padding: 14px;
            width: 290px;
            height: 50px;
            left: 16px;
            top: 363px;
        `,
    },
];
