import React, { useState } from 'react';
import PropTypes from 'prop-types';
import EditMetricModal from 'containers/EditMetricModal';
import { ReactComponent as PencilIcon } from 'assets/svg/pencil-2.svg';
import {
    MetricContainer,
    AvgLabel,
    EnabledSection,
    EnabledIndicator,
    EnabledLabel,
    IconWrapper,
} from './SingleMetric.style';

const SingleMetric = ({
    id,
    name,
    enabled,
    goal,
    result,
    onUpdate,
    metricSourceId,
    withoutBorder,
}) => {
    const [isModalVisible, setModalVisible] = useState(false);

    const toggleModal = () => {
        setModalVisible(!isModalVisible);
    };

    return (
      <MetricContainer withoutBorder={withoutBorder}>
        {isModalVisible && (
        <EditMetricModal
                    toggleVisible={toggleModal}
                    name={name}
                    goal={goal}
                    enabled={enabled}
                    onUpdate={onUpdate}
                    metricId={id}
                    metricSourceId={metricSourceId}
                />
            )}
        <span>{name}</span>
        <AvgLabel>{Math.round(goal) || 'n/a'}</AvgLabel>
        <AvgLabel>{Math.round(result) || 'n/a'}</AvgLabel>
        <EnabledSection>
          <EnabledIndicator enabled={enabled} />
          <EnabledLabel enabled={enabled}>
            {enabled ? 'Enabled' : 'Disabled'}
          </EnabledLabel>
        </EnabledSection>
        <IconWrapper onClick={toggleModal}>
          <PencilIcon onClick={toggleModal} />
        </IconWrapper>
      </MetricContainer>
    );
};

SingleMetric.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    enabled: PropTypes.bool.isRequired,
    goal: PropTypes.number.isRequired,
    result: PropTypes.number,
    onUpdate: PropTypes.func.isRequired,
    metricSourceId: PropTypes.string.isRequired,
    withoutBorder: PropTypes.bool,
};

SingleMetric.defaultProps = {
    result: undefined,
    withoutBorder: false,
};

export default SingleMetric;
