import {
    ESTABLISH_SUPER_ADMIN_USERS,
    ESTABLISH_SUPER_ADMIN_USERS_SUCCESS,
    ESTABLISH_SUPER_ADMIN_USERS_FAILURE,
    LOAD_MORE_SUPER_ADMIN_USERS,
    MARK_ALL_SUPER_ADMIN_USERS_LOADED,
    RESET_SUPER_ADMIN_USERS,
} from 'redux/actions/mpaSuperAdminUsers';

export const initialState = {
    areSuperAdminUsersEstablished: false,
    isSuperAdminUsersError: false,
    areSuperAdminUsersFetching: false,
    superAdminUsers: [],
    error: null,
    hasMoreSuperAdminUsers: true,
    amountOfAllSuperAdminUsers: 0,
};

const superAdminUsers = (state = initialState, action) => {
    switch (action.type) {
        case ESTABLISH_SUPER_ADMIN_USERS:
            return {
                ...state,
                areSuperAdminUsersFetching: true,
                isSuperAdminUsersError: false,
                amountOfAllSuperAdminUsers: 0,
            };

        case ESTABLISH_SUPER_ADMIN_USERS_SUCCESS:
            return {
                ...state,
                areSuperAdminUsersFetching: false,
                areSuperAdminUsersEstablished: true,
                superAdminUsers: action.payload.users,
                amountOfAllSuperAdminUsers: action.payload.total_count,
            };

        case ESTABLISH_SUPER_ADMIN_USERS_FAILURE:
            return {
                ...state,
                areSuperAdminUsersFetching: false,
                isSuperAdminUsersError: true,
                error: action.err,
                amountOfAllSuperAdminUsers: 0,
            };

        case LOAD_MORE_SUPER_ADMIN_USERS:
            return {
                ...state,
                areSuperAdminUsersFetching: false,
                areSuperAdminUsersEstablished: true,
                superAdminUsers: [
                    ...state.superAdminUsers,
                    ...action.payload.users,
                ],
                amountOfAllSuperAdminUsers: action.payload.total_count,
            };

        case MARK_ALL_SUPER_ADMIN_USERS_LOADED:
            return {
                ...state,
                hasMoreSuperAdminUsers: false,
                areSuperAdminUsersFetching: false,
                areSuperAdminUsersEstablished: true,
            };

        case RESET_SUPER_ADMIN_USERS:
            return initialState;

        default:
            return state;
    }
};

export default superAdminUsers;
