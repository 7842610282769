import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as TeamIcon } from 'assets/svg/sidebarIcons/team.svg';
import { Link } from 'react-router-dom';
import { css } from 'styled-components/macro';
import useTeamCreator from 'hooks/useTeamCreator';
import useAdmin from 'hooks/useAdmin';
import useMPA from 'hooks/useMPA';
import ConfirmActionModal from 'components/ConfirmActionModal';
import AvatarPlaceholder from 'components/AvatarPlaceholder';
import { ROUTES } from 'routing/constants';
import {
    SingleTeamHolder,
    TitleHolder,
    IconHolder,
    Picture,
    Title,
    UsersHolder,
    UserAvatarsHolder,
    TeamButton,
} from './SingleTeam.style';

const generateUsersDescription = (users, usersCountProvided) => {
    if (!Array.isArray(users) || (users && !users.length)) {
        return '';
    }

    if (users.length === 1) {
        return `${users[0].first_name} in this team`;
    }

    if (users.length === 2) {
        return `${users[0].first_name} and ${users[1].first_name} in this team`;
    }

    return `${users[0].first_name}, ${users[1].first_name} and ${
        usersCountProvided - 2
    } others in this team`;
};

const SingleTeam = ({
    title,
    users,
    creatorId,
    pictureUrl,
    joinTeam,
    leaveTeam,
    archiveTeam,
    currentUser,
    teamId,
    color,
    bgColor,
    usersCountProvided,
}) => {
    const [
        isConfirmLeaveModalVisible,
        setIsConfirmLeaveModalVisible,
    ] = useState(false);

    const teamCreator = useTeamCreator();
    const isAdmin = useAdmin();
    const { isCurrentUserMPA } = useMPA();
    const isCreator = currentUser.id === creatorId;
    const isTeamMember = currentUser.userTeams.find(
        (team) => team.id === teamId
    );

    return (
        <SingleTeamHolder data-testid="SingleTeam">
            <ConfirmActionModal
                isVisible={isConfirmLeaveModalVisible}
                onToggle={() => {
                    setIsConfirmLeaveModalVisible(!isConfirmLeaveModalVisible);
                }}
                onConfirm={() => {
                    if (isCreator && teamCreator.usersCount === 1) {
                        archiveTeam();
                    } else if (!isCreator) {
                        leaveTeam();
                    }
                }}
                description="Are you sure you want to leave this team?"
                confirmLabel="Leave team"
            />
            {isTeamMember || isAdmin || isCurrentUserMPA ? (
                <Link to={`${ROUTES.teamOverview}/${teamId}`}>
                    <TitleHolder>
                        {pictureUrl ? (
                            <Picture src={pictureUrl} alt={title} />
                        ) : (
                            <IconHolder style={{ backgroundColor: bgColor }}>
                                <TeamIcon
                                    fill={color}
                                    style={{ maxWidth: '45%' }}
                                />
                            </IconHolder>
                        )}

                        <Title>
                            <h3 data-testid="teamTitle">{title} </h3>
                            <span>
                                {usersCountProvided}
                                &nbsp;
                                {usersCountProvided === 1 ? 'user' : 'users'}
                            </span>
                        </Title>
                    </TitleHolder>
                </Link>
            ) : (
                <TitleHolder>
                    {pictureUrl ? (
                        <Picture src={pictureUrl} alt={title} />
                    ) : (
                        <IconHolder style={{ backgroundColor: bgColor }}>
                            <TeamIcon
                                fill={color}
                                style={{ maxWidth: '45%' }}
                            />
                        </IconHolder>
                    )}

                    <Title>
                        <h3 data-testid="teamTitle">{title} </h3>
                        <span>
                            {usersCountProvided}{' '}
                            {usersCountProvided === 1 ? 'user' : 'users'}
                        </span>
                    </Title>
                </TitleHolder>
            )}

            <UsersHolder>
                <UserAvatarsHolder>
                    {users.map((user) =>
                        user.avatar_url ? (
                            <img
                                key={`img-${user.id}`}
                                src={user.avatar_url}
                                alt="test"
                                data-testid="userAvatar"
                            />
                        ) : (
                            <AvatarPlaceholder
                                key={`avatar-${user.id}`}
                                width="24px"
                                height="24px"
                                customStyles={css`
                                    margin-right: 8px;
                                `}
                            >
                                <span>
                                    {user.first_name &&
                                        user.first_name.slice(0, 1)}
                                    {user.last_name &&
                                        user.last_name.slice(0, 1)}
                                </span>
                            </AvatarPlaceholder>
                        )
                    )}
                </UserAvatarsHolder>
                <span>
                    {generateUsersDescription(users, usersCountProvided)}
                </span>
            </UsersHolder>
            {currentUser &&
                !isAdmin &&
                (isTeamMember ? (
                    <TeamButton
                        data-testid="leave-button"
                        data-text="You can't leave the team as its creator."
                        quaternary
                        disabled={isCreator}
                        onClick={() => {
                            setIsConfirmLeaveModalVisible(true);
                        }}
                    >
                        Leave team
                    </TeamButton>
                ) : (
                    <TeamButton primary onClick={() => joinTeam()}>
                        Join team
                    </TeamButton>
                ))}

            {isAdmin && (
                <Link to={`${ROUTES.teamOverview}/${teamId}`}>
                    <TeamButton primary>View</TeamButton>
                </Link>
            )}
        </SingleTeamHolder>
    );
};

SingleTeam.propTypes = {
    title: PropTypes.string,
    creatorId: PropTypes.string.isRequired,
    teamId: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    bgColor: PropTypes.string.isRequired,
    usersCountProvided: PropTypes.number.isRequired,
    pictureUrl: PropTypes.string || null,
    currentUser: PropTypes.shape({
        id: PropTypes.string,
        userTeams: PropTypes.arrayOf(PropTypes.any),
    }).isRequired,
    users: PropTypes.arrayOf(PropTypes.any),
    joinTeam: PropTypes.func.isRequired,
    leaveTeam: PropTypes.func.isRequired,
    archiveTeam: PropTypes.func.isRequired,
};

SingleTeam.defaultProps = {
    title: '(untitled)',
    pictureUrl: null,
    users: [],
};

export default SingleTeam;
