import {
    getUserProfile,
    getUserProfileSuccess,
    getUserProfileFailure,
    updateUserProfile,
    updateUserProfileSuccess,
    updateUserProfileFailure,
} from 'redux/actions/profile';
import apiRoutes from 'config/apiRoutes';
import { dispatchErrorAlertThunk } from './systemAlerts';

export const getUserProfileThunk = (getAccessTokenSilently, logout) => (
    dispatch
) => {
    dispatch(getUserProfile());

    getAccessTokenSilently()
        .then((token) => {
            fetch(apiRoutes.get.userProfile, {
                method: 'GET',
                headers: {
                    organization_slug: window.localStorage.getItem(
                        'MP_ORGANIZATION'
                    ),
                    Authorization: `Token token="${token}"`,
                },
            })
                .then((res) =>
                    res.status === 401
                        ? logout({ returnTo: window.location.origin })
                        : res
                )
                .then((res) => res.json())
                .then((res) => {
                    if (res.error) {
                        return dispatch(getUserProfileFailure(res.error));
                    }
                    return dispatch(
                        getUserProfileSuccess({
                            userId: res.lms_user_id,
                            userEmail: res.email,
                            userFirstName: res.first_name || '',
                            userLastName: res.last_name || '',
                            userName: `${res.first_name} ${res.last_name}`,
                            userAvatarUrl: res.avatar_url,
                            userLevel: res.level,
                            userScore: res.points,
                            pointsToNextLevel: res.points_to_next_level,
                            isScoreNotificationsEnabled:
                                res.score_notifications_enabled,
                            isMessageNotificationsEnabled:
                                res.message_notifications_enabled,
                            isEmailNotificationsEnabled:
                                res.email_notifications_enabled,
                        })
                    );
                })
                .catch((err) =>
                    dispatch(
                        getUserProfileFailure({
                            err,
                        })
                    )
                );
        })
        .catch(() => {
            logout({ returnTo: window.location.origin });
        });
};

export const updateUserProfileThunk = (
    getAccessTokenSilently,
    logout,
    values,
    onSuccess = () => {}
) => (dispatch) => {
    dispatch(updateUserProfile());

    const formData = new FormData();

    if (values.firstName) formData.append('user[first_name]', values.firstName);

    if (values.lastName) formData.append('user[last_name]', values.lastName);

    if (values.email) formData.append('user[email]', values.email);

    if (values.avatar) formData.append('user[avatar]', values.avatar);

    getAccessTokenSilently().then((token) => {
        fetch(apiRoutes.put.updateUserProfile, {
            method: 'PUT',
            headers: {
                organization_slug: window.localStorage.getItem(
                    'MP_ORGANIZATION'
                ),
                Authorization: `Token token="${token}"`,
            },
            body: formData,
        })
            .then((res) => res.json())
            .then((res) => {
                if (res.error) {
                    dispatch(dispatchErrorAlertThunk(res, res.error));
                    return dispatch(updateUserProfileFailure(res.error));
                }
                dispatch(
                    updateUserProfileSuccess({
                        userId: res.lms_user_id,
                        userEmail: res.email,
                        userFirstName: res.first_name,
                        userLastName: res.last_name,
                        userName: `${res.first_name} ${res.last_name}`,
                        userAvatarUrl: res.avatar_url,
                        userLevel: res.level,
                        userScore: res.points,
                    })
                );
                onSuccess();
                return () => {};
            })
            .catch((err) => {
                dispatch(dispatchErrorAlertThunk(err, err.error));
                dispatch(
                    updateUserProfileFailure({
                        err,
                    })
                );
            });
    });
};
