import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import SectionHeader from 'components/SectionHeader';
import Spinner from 'components/Spinner';
import { ReactComponent as ArrowBackIcon } from 'assets/svg/arrow-back.svg';
import { ReactComponent as HeartIcon } from 'assets/svg/heart.svg';
import { ReactComponent as SettingsIcon } from 'assets/svg/settings.svg';
import { MPA_ROUTES } from 'routing/constants';
import theme from 'assets/theme/mainTheme';
import ConfirmActionModal from 'components/ConfirmActionModal';
import {
    CustomerDetailsContainer,
    CustomerHeader,
    CustomerDataSection,
    CustomerDataBoxes,
    CustomerDataBox,
    CustomerDataBoxSection,
    StyledAvatar,
    StyledAvatarPlaceholder,
    CtaWrapper,
    IconWrapper,
    DropdownWrapper,
    DropdownItem,
} from './MPACustomerDetails.style';

const MPACustomerDetails = ({
    isDropdownVisible,
    toggleDropdownVisible,
    isConfirmationModalVisible,
    toggleConfirmationModalVisible,
    handleConfirm,
    dataProvided,
    error,
    name,
    avatarUrl,
    backgroundColor,
    apiUrl,
    apiKey,
    country,
    zipcode,
    city,
    state,
    street,
    contactFullname,
    contactMobile,
    contactEmail,
}) => {
    const dropdownRef = useRef(null);

    const handleClickOutside = (e) => {
        if (dropdownRef.current && dropdownRef.current.contains(e.target)) {
            return;
        }
        toggleDropdownVisible();
    };

    useEffect(() => {
        if (isDropdownVisible) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isDropdownVisible]);

    if (!dataProvided) {
        return (
          <CustomerDetailsContainer loading>
            {error ? (
                    'An unknown error occurred. Try again later.'
                ) : (
                  <Spinner />
                )}
          </CustomerDetailsContainer>
        );
    }

    return (
      <CustomerDetailsContainer>
        <SectionHeader
                title={name}
                withMargin
                goBack={{
                    url: MPA_ROUTES.customers,
                    label() {
                        return (
                          <>
                            <ArrowBackIcon stroke={theme.color.textLabel} />
                            <span>Back</span>
                          </>
                        );
                    },
                }}
                cta={() => (
                  <CtaWrapper ref={dropdownRef}>
                    <IconWrapper
                            onClick={toggleDropdownVisible}
                            withBackground={isDropdownVisible}
                        >
                      <SettingsIcon />
                    </IconWrapper>
                    {isDropdownVisible && (
                    <DropdownWrapper>
                      <DropdownItem
                                    secondary
                                    onClick={toggleConfirmationModalVisible}
                                >
                        Archive customer
                      </DropdownItem>
                    </DropdownWrapper>
                        )}
                  </CtaWrapper>
                )}
                collapseOnMobile
            />
        <CustomerHeader>
          {avatarUrl ? (
            <StyledAvatar src={avatarUrl} alt={name} />
                ) : (
                  <StyledAvatarPlaceholder
                        color={backgroundColor || theme.color.primaryLight}
                    >
                    <HeartIcon />
                  </StyledAvatarPlaceholder>
                )}
          <CustomerDataSection>
            <strong>{name}</strong>
            <small>{contactEmail}</small>
          </CustomerDataSection>
        </CustomerHeader>

        <CustomerDataBoxes>
          <CustomerDataBox>
            <CustomerDataBoxSection>
              <h1>Organization data</h1>
              <span>
                <strong>{name}</strong>
                <small>Hospital name</small>
              </span>
            </CustomerDataBoxSection>
            <CustomerDataBoxSection>
              <span>
                <strong>{apiKey}</strong>
                <small>API Key</small>
              </span>
              <span>
                <strong>
                  <a href={apiUrl}>
                    {apiUrl}
                    {' '}
                  </a>
                </strong>

                <small>LMS Address</small>
              </span>
            </CustomerDataBoxSection>
          </CustomerDataBox>

          <CustomerDataBox>
            <CustomerDataBoxSection>
              <h1>Address</h1>
              <span>
                <strong>
                  <p>{street || 'N/A'}</p>
                  <p>
                    {city} 
                    {' '}
                    {state} 
                    {' '}
                    {zipcode}
                  </p>
                  <p>{country}</p>
                </strong>
              </span>
            </CustomerDataBoxSection>
            <CustomerDataBoxSection>
              <h1>Contact Person</h1>
              <span>
                <strong>{contactFullname || 'N/A'}</strong>
                <strong>{contactMobile}</strong>

                <strong>
                  <a href={`mailto:${contactEmail}`}>
                    {contactEmail}
                  </a>
                </strong>
              </span>
            </CustomerDataBoxSection>
          </CustomerDataBox>
        </CustomerDataBoxes>
        <ConfirmActionModal
                isVisible={isConfirmationModalVisible}
                onToggle={toggleConfirmationModalVisible}
                onConfirm={handleConfirm}
                description="Are you sure you want to archive this customer?"
                confirmLabel="Confirm"
                irreversible
            />
      </CustomerDetailsContainer>
    );
};

MPACustomerDetails.propTypes = {
    isDropdownVisible: PropTypes.bool.isRequired,
    toggleDropdownVisible: PropTypes.func.isRequired,
    isConfirmationModalVisible: PropTypes.bool.isRequired,
    toggleConfirmationModalVisible: PropTypes.func.isRequired,
    handleConfirm: PropTypes.func.isRequired,
    dataProvided: PropTypes.bool.isRequired,
    error: PropTypes.string,
    name: PropTypes.string.isRequired,
    avatarUrl: PropTypes.string,
    backgroundColor: PropTypes.string,
    apiUrl: PropTypes.string,
    apiKey: PropTypes.string,
    country: PropTypes.string,
    zipcode: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    street: PropTypes.string,
    contactFullname: PropTypes.string,
    contactMobile: PropTypes.string,
    contactEmail: PropTypes.string,
};

MPACustomerDetails.defaultProps = {
    error: undefined,
    avatarUrl: undefined,
    backgroundColor: undefined,
    apiUrl: undefined,
    apiKey: undefined,
    country: undefined,
    zipcode: undefined,
    city: undefined,
    state: undefined,
    street: undefined,
    contactFullname: undefined,
    contactMobile: undefined,
    contactEmail: undefined,
};

export default MPACustomerDetails;
