import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import SectionHeader from 'components/SectionHeader';
import Spinner from 'components/Spinner';
import InfiniteScroll from 'react-infinite-scroller';
import AddAdminMemoModal from 'containers/AddAdminMemoModal';
import UniversalEmptyState from 'components/EmptyState/UniversalEmptyState';
import SingleAdminMemo from 'components/SingleAdminMemo';
import {
    AdminMemosWrapper,
    AdminMemosListWrapper,
    Divider,
} from './AdminMemos.style';

const AdminMemos = ({
    isFetching,
    adminMemos,
    loadMore,
    hasMore,
    revertAdminMemo,
    reloadAdminMemos,
}) => {
    const [isAdminMemoModalVisible, setIsAdminMemoModalVisible] = useState(
        false
    );
    let wasLast7Days = false;
    let wasLast30Days = false;
    let wasOlder = false;

    const getDaysAgo = (date) => {
        if (Date.now() - new Date(date).getTime() < 0) {
            return 0;
        }
        const days = Math.floor(
            (Date.now() - new Date(date).getTime()) / 1000 / 86400
        );
        return days;
    };

    const renderSingleAdminMemo = (adminMemo, id) => {
        if (id) {
            return (
              <SingleAdminMemo
                    key={id}
                    adminMemoId={adminMemo.id}
                    name={adminMemo.message}
                    type={adminMemo.memo_type}
                    sendDate={adminMemo.send_at}
                    discardedAt={adminMemo.discarded_at}
                    revertAdminMemo={revertAdminMemo}
                />
            );
        }

        return (
          <SingleAdminMemo
                key={adminMemo.id}
                adminMemoId={adminMemo.id}
                name={adminMemo.message}
                type={adminMemo.memo_type}
                sendDate={adminMemo.send_at}
                discardedAt={adminMemo.discarded_at}
                revertAdminMemo={revertAdminMemo}
            />
        );
    };

    return (
      <AdminMemosWrapper>
        <SectionHeader
                title="Admin Memos"
                withPadding
                cta={() => (
                  <Button
                        primaryDark
                        onClick={() => setIsAdminMemoModalVisible(true)}
                    >
                    Create new Memo
                  </Button>
                )}
            />
        {!adminMemos || adminMemos.length === 0 ? (
          <UniversalEmptyState description="There are no Admin Memos available yet. " />
            ) : (
              <InfiniteScroll
                    initialLoad={false}
                    loadMore={loadMore}
                    loader={<Spinner key="spinner" />}
                    hasMore={hasMore}
                >
                <AdminMemosListWrapper>
                  {adminMemos.map((adminMemo) => {
                            if (getDaysAgo(adminMemo.send_at) < 8) {
                                if (!wasLast7Days) {
                                    wasLast7Days = true;
                                    return (
                                      <div key={adminMemo.id}>
                                        <Divider>Last 7 days</Divider>
                                        {renderSingleAdminMemo(adminMemo)}
                                      </div>
                                    );
                                }
                            } else if (getDaysAgo(adminMemo.send_at) < 31) {
                                if (!wasLast30Days) {
                                    wasLast30Days = true;
                                    return (
                                      <div key={adminMemo.id}>
                                        <Divider>Last 30 days</Divider>
                                        {renderSingleAdminMemo(adminMemo)}
                                      </div>
                                    );
                                }
                            } else if (!wasOlder) {
                                wasOlder = true;
                                return (
                                  <div key={adminMemo.id}>
                                    <Divider>Older</Divider>
                                    {renderSingleAdminMemo(
                                            adminMemo,
                                            adminMemo.id
                                        )}
                                  </div>
                                );
                            }

                            return renderSingleAdminMemo(adminMemo);
                        })}
                </AdminMemosListWrapper>
              </InfiniteScroll>
            )}
        {isFetching && <Spinner />}
        {isAdminMemoModalVisible && (
        <AddAdminMemoModal
                    closeModal={() => {
                        setIsAdminMemoModalVisible(false);
                    }}
                    reloadAdminMemos={reloadAdminMemos}
                />
            )}
      </AdminMemosWrapper>
    );
};

AdminMemos.propTypes = {
    isFetching: PropTypes.bool.isRequired,
    adminMemos: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            message: PropTypes.string,
            memo_type: PropTypes.string,
            send_at: PropTypes.string,
            discarded_at: PropTypes.string,
        })
    ).isRequired,
    loadMore: PropTypes.func.isRequired,
    hasMore: PropTypes.bool.isRequired,
    revertAdminMemo: PropTypes.func.isRequired,
    reloadAdminMemos: PropTypes.func.isRequired,
};

export default AdminMemos;
