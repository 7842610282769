import React, { useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { ReactComponent as ArrowDown } from 'assets/svg/dropdown-down.svg';
import { ReactComponent as ArrowUp } from 'assets/svg/dropdown-up.svg';
import useMPA from 'hooks/useMPA';

import theme from 'assets/theme/mainTheme';
import Button from 'components/Button';
import MPAAddAdminMemoModal from 'containers/MPAAddAdminMemoModal';
import { MPA_ROUTES } from 'routing/constants';

import {
    Dropdown,
    DropdownItem,
    DropdownWrapper,
} from './SidebarMPAContent.style';
import {
    StyledSidebarMenuItem,
    StyledSidebarMenuHolder,
    StyledMenuitemTitle,
} from '../SidebarContent.style';
import sidebarMPAMenu from './SidebarMPAContent.mock';

const SidebarMPAContent = () => {
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [isAdminMemoVisible, setIsAdminMemoVisible] = useState(false);
    const history = useHistory();
    const { pathname } = useLocation();
    const { displayAsMPA } = useMPA();

    const toggleDropdownVisible = () => {
        setIsDropdownVisible(!isDropdownVisible);
    };

    return (
      <>
        <DropdownWrapper>
          <Button
                    primaryDark
                    svg
                    onClick={toggleDropdownVisible}
                    customStyles={{
                        width: '134px',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
            Add new
            {isDropdownVisible ? (
              <ArrowUp fill={theme.color.white} />
                    ) : (
                      <ArrowDown fill={theme.color.white} />
                    )}
          </Button>
          {isDropdownVisible && (
            <Dropdown>
              <DropdownItem
                            onClick={() => {
                                toggleDropdownVisible();
                                history.push(MPA_ROUTES.addCustomer);
                            }}
                        >
                Organization
              </DropdownItem>
              <DropdownItem
                            onClick={() => {
                                toggleDropdownVisible();
                                history.push(
                                    `${MPA_ROUTES.dataSourcesMeasures}#importDataSources`
                                );
                            }}
                        >
                Data Source
              </DropdownItem>
              <DropdownItem
                            onClick={() => {
                                toggleDropdownVisible();
                                setIsAdminMemoVisible(true);
                            }}
                        >
                Admin Memos
              </DropdownItem>
            </Dropdown>
                )}
        </DropdownWrapper>
        <StyledSidebarMenuHolder>
          {sidebarMPAMenu.map((menuItem) => (
            <Link to={menuItem.url} key={menuItem.url}>
              <StyledSidebarMenuItem
                            isMPA={displayAsMPA}
                            current={menuItem.highlight.find((item) =>
                                pathname.includes(item)
                            )}
                        >
                {menuItem.icon()}
                <StyledMenuitemTitle isMPA={displayAsMPA}>
                  {menuItem.title}
                </StyledMenuitemTitle>
              </StyledSidebarMenuItem>
            </Link>
                ))}
        </StyledSidebarMenuHolder>
        {isAdminMemoVisible && (
        <MPAAddAdminMemoModal
                    closeModal={() => {
                        setIsAdminMemoVisible(false);
                    }}
                />
            )}
      </>
    );
};

export default SidebarMPAContent;
