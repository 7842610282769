import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import PerformanceView from 'views/Performance';

const Performance = () => {
    const achievementsSection = useRef(null);
    const { userLevel, userScore, pointsToNextLevel } = useSelector(
        (state) => state.currentUser
    );

    const scrollToAchievementsSection = () => {
        if (achievementsSection.current) {
            achievementsSection.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const scrollToAchievements = new URLSearchParams(
        window.location.search
    ).get('scroll');

    const handleMetricLoaded = () => {
        if (scrollToAchievements) {
            scrollToAchievementsSection();
        }
    };

    return (
      <PerformanceView
            achievementsSection={achievementsSection}
            handleMetricLoaded={handleMetricLoaded}
            userLevel={userLevel}
            userScore={userScore}
            pointsToNextLevel={pointsToNextLevel}
        />
    );
};

export default Performance;
