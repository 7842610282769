import styled, { css } from 'styled-components/macro';

export const AlertContainer = styled.div`
    width: 432px;
    height: 96px;
    padding: 32px;
    margin-bottom: 16px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;

    border-radius: ${({ theme }) => theme.border.radiusBase};
    border: ${({ theme }) => theme.border.base};
    background-color: ${({ theme }) => theme.color.bgSearch};

    animation: 200ms ease-out 0s 1 slideIn;

    ${({ isDismissed }) =>
        isDismissed &&
        css`
            animation: 200ms ease-out 0s 1 slideOut;
        `}

    @keyframes slideIn {
        0% {
            transform: translateX(100%);
        }
        100% {
            transform: translateX(0);
        }
    }

    @keyframes slideOut {
        0% {
            transform: translateX(0);
        }
        100% {
            transform: translateX(100%);
        }
    }
`;

export const MessageWrapper = styled.span`
    height: 50px;
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
`;

export const IconHolder = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border-radius: 50%;

    background-color: ${({ theme }) => theme.color.secondary};

    ${({ error }) =>
        error &&
        css`
            background-color: ${({ theme }) => theme.color.quaternary};
        `}
`;

export const DismissIconHolder = styled.div`
    cursor: pointer;
`;
