import {
    ESTABLISH_METRIC_SOURCES_MPA,
    ESTABLISH_METRIC_SOURCES_MPA_SUCCESS,
    ESTABLISH_METRIC_SOURCES_MPA_FAILURE,
    UPDATE_METRIC_SOURCE_MPA,
    UPDATE_METRIC_SOURCE_MPA_SUCCESS,
    UPDATE_METRIC_SOURCE_MPA_FAILURE,
} from 'redux/actions/mpaMetricSources';

export const initialState = {
    areMetricSourcesEstablished: false,
    isMetricSourcesError: false,
    areMetricSourcesFetching: false,
    error: null,
    metricSources: [],
};

const mpaMetricSources = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_METRIC_SOURCE_MPA:
        case ESTABLISH_METRIC_SOURCES_MPA:
            return {
                ...state,
                areMetricSourcesFetching: true,
                isMetricSourcesError: false,
            };

        case ESTABLISH_METRIC_SOURCES_MPA_SUCCESS:
            return {
                ...state,
                areMetricSourcesFetching: false,
                areMetricSourcesEstablished: true,
                metricSources: action.payload,
            };

        case UPDATE_METRIC_SOURCE_MPA_FAILURE:
        case ESTABLISH_METRIC_SOURCES_MPA_FAILURE:
            return {
                ...state,
                areMetricSourcesFetching: false,
                isMetricSourcesError: true,
                error: action.err,
            };

        case UPDATE_METRIC_SOURCE_MPA_SUCCESS:
            return {
                ...state,
                areMetricSourcesFetching: false,
                areMetricSourcesEstablished: true,
                metricSources: [
                    ...state.metricSources.filter(
                        (metricSource) => metricSource.id !== action.payload.id
                    ),
                    action.payload,
                ],
            };

        default:
            return state;
    }
};

export default mpaMetricSources;
