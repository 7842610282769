import React from 'react';
import PropTypes from 'prop-types';
import {
    SmartTipHeader,
    SmartTipHeadersContainer,
    SmartTipListItemContainer,
    SmartTipSubHeader,
} from '../../containers/BrowseAvailableSmartTipsForProvider/styled';
import { ReactComponent as PlayIcon } from '../../assets/svg/playIcon.svg';
import Button from '../Button';

export const SmartTipListItem = ({
    name = 'Test Name',
    desc = 'Test Desc',
    onClick = undefined,
}) => (
  <SmartTipListItemContainer>
    <PlayIcon />
    <SmartTipHeadersContainer>
      <SmartTipHeader>{name}</SmartTipHeader>
      <SmartTipSubHeader>{desc}</SmartTipSubHeader>
    </SmartTipHeadersContainer>
    <Button primary white onClick={onClick}>
      View
    </Button>
  </SmartTipListItemContainer>
);

SmartTipListItem.propTypes = {
    name: PropTypes.string.isRequired,
    desc: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};
