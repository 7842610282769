import React from 'react';
import { ReactComponent as Clock } from 'assets/svg/no-results/clock.svg';
import { ReminderWrapper, Title, Info } from './ReminderEmptyState.style';

const ReminderEmptyState = () => (
  <ReminderWrapper>
    <Clock />
    <Title>Scheduled reminders will be displayed here</Title>
    <Info>
      You can add unlimited reminders to help you remember about
      completing Smart Tips
    </Info>
  </ReminderWrapper>
);

export default ReminderEmptyState;
