import styled, { css } from 'styled-components/macro';

export const ProgressBarHolder = styled.div`
    width: 275px;
    height: 32px;
    border-radius: ${({ theme }) => theme.border.radiusBase};
    background-color: ${({ theme }) => theme.color.bgSearch};
    display: flex;
    align-items: center;
    padding: 8px;
`;

export const ProgressBarColor = styled.div`
    @keyframes progressBarMove {
        0% {
            margin-left: 0;
        }

        50% {
            margin-left: calc(100% - 100px);
        }

        100% {
            margin-left: 0;
        }
    }

    width: 100px;
    height: 18px;
    border-radius: ${({ theme }) => theme.border.radiusBase};
    background-color: ${({ theme }) => theme.color.primary};
    animation: progressBarMove 2s infinite;
    transition: all 1s ease;

    ${({ completed }) =>
        completed &&
        css`
            width: 100%;
            background-color: ${({ theme }) => theme.color.secondary};
            animation: progressBarMove 2s 1;
        `}

    margin-left: 0;
    margin-right: auto;
`;
