export const ESTABLISH_METRIC_SOURCES_MPA = 'ESTABLISH_METRIC_SOURCES_MPA';
export const ESTABLISH_METRIC_SOURCES_MPA_SUCCESS =
    'ESTABLISH_METRIC_SOURCES_MPA_SUCCESS';
export const ESTABLISH_METRIC_SOURCES_MPA_FAILURE =
    'ESTABLISH_METRIC_SOURCES_MPA_FAILURE';
export const UPDATE_METRIC_SOURCE_MPA = 'UPDATE_METRIC_SOURCE_MPA';
export const UPDATE_METRIC_SOURCE_MPA_SUCCESS =
    'UPDATE_METRIC_SOURCE_MPA_SUCCESS';
export const UPDATE_METRIC_SOURCE_MPA_FAILURE =
    'UPDATE_METRIC_SOURCE_MPA_FAILURE';

export const establishMetricSourcesMpa = () => ({
    type: ESTABLISH_METRIC_SOURCES_MPA,
});

export const establishMetricSourcesMpaSuccess = (payload) => ({
    type: ESTABLISH_METRIC_SOURCES_MPA_SUCCESS,
    payload,
});

export const establishMetricSourcesMpaFailure = (err) => ({
    type: ESTABLISH_METRIC_SOURCES_MPA_FAILURE,
    err,
});

export const updateMetricSourceMpa = () => ({
    type: UPDATE_METRIC_SOURCE_MPA,
});

export const updateMetricSourceMpaSuccess = (payload) => ({
    type: UPDATE_METRIC_SOURCE_MPA_SUCCESS,
    payload,
});

export const updateMetricSourceMpaFailure = (err) => ({
    type: UPDATE_METRIC_SOURCE_MPA_FAILURE,
    err,
});
