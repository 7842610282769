import styled, { css } from 'styled-components/macro';

export const Wrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 8fr 1fr;
    align-items: center;
    justify-content: center;
`;

export const PaginationWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

export const Counter = styled.div`
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    color: ${({ theme }) => theme.color.textLight};
`;

export const PageNumber = styled.div`
    cursor: pointer;
    margin: 5px;
    width: 32px;
    height: 32px;
    border-radius: ${({ theme }) => theme.border.radiusSmall};
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.color.textLight};

    &:hover {
        color: ${({ theme }) => theme.color.primary};
        background-color: ${({ theme }) => theme.color.bgPrimaryLight};
    }

    ${({ selected }) =>
        selected &&
        css`
            color: ${({ theme }) => theme.color.white};
            background-color: ${({ theme }) => theme.color.textBase};
        `}
`;

export const ButtonWrapper = styled.div`
    cursor: pointer;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: ${({ theme }) => theme.border.base};
    border-radius: ${({ theme }) => theme.border.radiusSmall};

    svg {
        fill: ${({ theme }) => theme.color.textBase};
    }

    &:hover {
        background-color: ${({ theme }) => theme.color.bgPrimaryLight};
    }

    ${({ disabled }) =>
        disabled &&
        css`
            cursor: not-allowed;

            svg {
                fill: ${({ theme }) => theme.color.textLight};
            }

            &:hover {
                background-color: transparent;
            }
        `}
`;
