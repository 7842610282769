import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as RightArrowIcon } from 'assets/svg/arrow-right.svg';
import { ReactComponent as LeftArrowIcon } from 'assets/svg/arrow-left.svg';
import { ReactComponent as OkIcon } from 'assets/svg/ok-tick.svg';
import SmartTip from 'components/SmartTip';
import Congratulations from 'components/Congratulations';
import AddReminderModal from 'components/AddReminderModal';
import Spinner from 'components/Spinner';
import establishSmartTipsThunk, {
    resetSmartTipsThunk,
} from 'redux/actions/thunks/smartTips';
import { addReminderThunk } from 'redux/actions/thunks/reminders';
import { Link } from 'react-router-dom';
import Button from 'components/Button';
import theme from 'assets/theme/mainTheme';
import { ROUTES } from 'routing/constants';
import { useAuth0 } from '@auth0/auth0-react';
import apiRoutes from 'config/apiRoutes';
import { dispatchErrorAlertThunk } from 'redux/actions/thunks/systemAlerts';
import useFetch from 'hooks/useFetch';
import {
    StyledSmartTips,
    StyledButton,
    StyledTipsHolder,
    StyledTipsWrapper,
    StyledCongratulationsModal,
} from './SmartTips.style';

const SmartTips = () => {
    const { getAccessTokenSilently, logout } = useAuth0();
    const dispatch = useDispatch();
    const request = useFetch(getAccessTokenSilently, logout);
    const [numberOfSmartTips, setNumberOfSmartTips] = useState(0);
    const [firstVisibleTip, setFirstVisibleTip] = useState(0);
    const [tips, setTips] = useState([]);
    const [showCongrats, setShowCongrats] = useState(false);
    const [addReminderModalOpenedById, setAddReminderModalOpenedById] =
        useState(null); // reminderId || null

    const {
        areSmartTipsEstablished,
        areSmartTipsFetching,
        smartTipsToTW,
        smartTipsEssential,
        smartTips,
        smartTipsArchived,
        smartTipsEssentialArchived,
    } = useSelector((state) => state.smartTips);

    useEffect(() => {
        const availableWidth = window.innerWidth - 320 - 48;
        if (window.innerWidth > theme.breakpoints.web) {
            setNumberOfSmartTips(
                (availableWidth - availableWidth / 4 - 32) / (450 + 32)
            );
        } else {
            setNumberOfSmartTips(
                window.innerWidth > theme.breakpoints.mobile
                    ? (availableWidth - 32) / (450 + 32)
                    : 1
            );
        }
    }, [tips]);

    useEffect(() => {
        dispatch(establishSmartTipsThunk(getAccessTokenSilently, logout));

        return () => {
            dispatch(resetSmartTipsThunk());
        };
    }, []);

    useEffect(() => {
        setTips([
            ...smartTipsToTW.filter((st) => st.status === 'unviewed'),
            ...smartTipsEssential.filter((st) => st.status === 'unviewed'),
            ...smartTips.filter(
                (st) =>
                    st.status === 'unviewed' &&
                    !smartTipsEssential.find((eTip) => eTip.id === st.id)
            ),
        ]);
    }, [smartTipsToTW, smartTipsEssential, smartTips]);

    useEffect(() => {
        const tipsWrapper = document.getElementById('tipsWrapper');
        const tipsNodes = tipsWrapper
            ? Array.from(document.getElementById('tipsWrapper').childNodes)
            : [];

        if (tipsNodes.length) {
            if (firstVisibleTip === 0) {
                Array.from(
                    document.getElementById('tipsWrapper').childNodes
                )[0].style.marginLeft = `16px`;
            } else {
                const multiplier = firstVisibleTip - 1;

                if (numberOfSmartTips > 1) {
                    Array.from(
                        document.getElementById('tipsWrapper').childNodes
                    )[0].style.marginLeft = `${
                        -firstVisibleTip * 466 - multiplier * 16
                    }px`;
                } else {
                    Array.from(
                        document.getElementById('tipsWrapper').childNodes
                    )[0].style.marginLeft = `${
                        -firstVisibleTip * (window.innerWidth - 64) -
                        multiplier * 16
                    }px`;
                }
            }
        }
    }, [firstVisibleTip]);

    useEffect(() => {
        if (tips.length === 0) {
            setShowCongrats(true);
        } else {
            setShowCongrats(false);
        }
    }, [tips]);

    const handleModalClose = (e) => {
        if (e.target.id) {
            setAddReminderModalOpenedById(null);
        }
    };

    const handleChangeStatusSuccess = (res) => {
        if (!res.error) {
            setFirstVisibleTip(0);
            dispatch(establishSmartTipsThunk(getAccessTokenSilently, logout));
        }
    };

    const handleChangeStatusFailure = (err) => {
        dispatch(dispatchErrorAlertThunk(err, err.error));
    };

    const handleChangeStatus = (id, status) => {
        request(
            apiRoutes.put.updateSmartTip(id),
            'PUT',
            handleChangeStatusSuccess,
            handleChangeStatusFailure,
            {
                body: JSON.stringify({
                    smart_tip: {
                        status,
                    },
                }),
            },
            { 'Content-Type': 'application/json' },
            true
        );
    };

    if (!areSmartTipsEstablished || areSmartTipsFetching) {
        return <Spinner />;
    }

    if (showCongrats) {
        return (
            <StyledTipsWrapper>
                <Congratulations />
            </StyledTipsWrapper>
        );
    }

    return (
        <>
            <StyledTipsWrapper>
                <StyledSmartTips>
                    <StyledTipsHolder propWidth={tips.length} id="tipsWrapper">
                        {tips.map((tip) => (
                            <SmartTip
                                id={`${tip.id}-singleTip-${tip.type}`}
                                tipId={tip.id}
                                key={`${tip.id}-singleTip-${tip.type}`}
                                {...tip}
                                openModalById={setAddReminderModalOpenedById}
                                markAsOpened={handleChangeStatus}
                            />
                        ))}
                    </StyledTipsHolder>
                </StyledSmartTips>

                <StyledButton
                    type="button"
                    disabled={firstVisibleTip < 1}
                    onClick={() => {
                        setFirstVisibleTip(firstVisibleTip - 1);
                    }}
                >
                    <LeftArrowIcon />
                </StyledButton>
                <StyledButton
                    type="button"
                    isRightSide
                    disabled={
                        numberOfSmartTips === 1
                            ? firstVisibleTip >
                              tips.length - numberOfSmartTips - 1
                            : firstVisibleTip > tips.length - numberOfSmartTips
                    }
                    onClick={() => {
                        setFirstVisibleTip(firstVisibleTip + 1);
                    }}
                >
                    <RightArrowIcon />
                </StyledButton>
            </StyledTipsWrapper>

            {addReminderModalOpenedById && (
                <AddReminderModal
                    id="editReminderModal"
                    reminderId={addReminderModalOpenedById}
                    title="title"
                    smartTips={[
                        ...smartTipsToTW,
                        ...smartTipsEssential,
                        ...smartTips,
                        ...smartTipsEssentialArchived,
                        ...smartTipsArchived,
                    ]}
                    addReminder={(data) =>
                        dispatch(
                            addReminderThunk(
                                getAccessTokenSilently,
                                logout,
                                data,
                                false
                            )
                        )
                    }
                    closeModal={(e) => handleModalClose(e)}
                    congratulationsView={() => (
                        <StyledCongratulationsModal>
                            <OkIcon />
                            <h3>Congratulations!</h3>
                            <span>
                                Your mobile reminder has been set. You can
                                change its details in{' '}
                                <Link to={ROUTES.userSettings}>
                                    user profile
                                </Link>
                                .
                            </span>
                            <Button
                                primary
                                onClick={() => {
                                    setAddReminderModalOpenedById(null);
                                    dispatch(
                                        establishSmartTipsThunk(
                                            getAccessTokenSilently,
                                            logout
                                        )
                                    );
                                }}
                            >
                                Confirm
                            </Button>
                        </StyledCongratulationsModal>
                    )}
                />
            )}
        </>
    );
};

export default SmartTips;
