import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as CloseIcon } from 'assets/svg/close.svg';
import { ReactComponent as HeartIcon } from 'assets/svg/heart.svg';

import theme from 'assets/theme/mainTheme';
import { Item, EmptyAvatar, DropdownAvatar, Text } from './SelectedItem.style';

const SelectedItem = ({ type, data, deselectItem, disabled }) => {
    switch (type) {
        case 'user':
            return (
                <Item key={data.id}>
                    {data.avatar_url ? (
                        <DropdownAvatar>
                            <img src={data.avatar_url} alt={data.first_name} />
                        </DropdownAvatar>
                    ) : (
                        <EmptyAvatar
                            color={data.color}
                            backgroundColor={data.background_color}
                        >
                            {`${data.first_name && data.first_name[0]}${
                                data.last_name && data.last_name[0]
                            }`}
                        </EmptyAvatar>
                    )}
                    <Text>
                        {data.full_name
                            ? data.full_name
                            : `${data.first_name} ${data.last_name}`}
                    </Text>
                    {!disabled && (
                        <CloseIcon
                            className="closeIcon"
                            fill={theme.color.textBase}
                            onClick={() => deselectItem(data.id)}
                        />
                    )}
                </Item>
            );

        case 'team':
            return (
                <Item key={data.id}>
                    {data.picture_url ? (
                        <DropdownAvatar>
                            <img src={data.picture_url} alt={data.name} />
                        </DropdownAvatar>
                    ) : (
                        <EmptyAvatar backgroundColor={data.background_color}>
                            <HeartIcon fill={data.color} />
                        </EmptyAvatar>
                    )}
                    <Text>{data.name}</Text>

                    <CloseIcon
                        className="closeIcon"
                        fill={theme.color.textBase}
                        onClick={() => deselectItem(data.id)}
                    />
                </Item>
            );

        default:
            return <Item />;
    }
};

SelectedItem.propTypes = {
    type: PropTypes.string.isRequired,
    data: PropTypes.objectOf(PropTypes.any).isRequired,
    deselectItem: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};

SelectedItem.defaultProps = {
    disabled: false,
};

export default SelectedItem;
