import React from 'react';
import PropTypes from 'prop-types';

import theme from 'assets/theme/mainTheme';
import { MPA_ROUTES } from 'routing/constants';
import { useHistory } from 'react-router-dom';

import { ReactComponent as ArrowBackIcon } from 'assets/svg/arrow-back.svg';
import { ReactComponent as SettingsIcon } from 'assets/svg/settings.svg';

import SectionHeader from 'components/SectionHeader';
import ConfirmActionModal from 'components/ConfirmActionModal';

import {
    Container,
    CtaWrapper,
    DropdownWrapper,
    IconWrapper,
    DropdownItem,
    SuperAdminUserWrapper,
    Grid,
    Avatar,
    EmptyAvatar,
    Name,
    Email,
} from './MPASuperAdminUserDetails.style';

const MPASuperAdminUserDetailsView = ({
    id,
    fullName,
    firstName,
    lastName,
    email,
    avatar,
    color,
    backgroundColor,
    dropdownVisible,
    setSettingsVisible,
    deleteConfirmationModalVisible,
    toggleDeleteConfirmationModalVisible,
    handleDeleteUser,
}) => {
    const history = useHistory();

    return (
      <Container>
        <SectionHeader
                title={fullName}
                withPadding
                goBack={{
                    url: MPA_ROUTES.superAdminUsers,
                    label() {
                        return (
                          <>
                            <ArrowBackIcon stroke={theme.color.textLabel} />
                            {' '}
                            <span>Back</span>
                          </>
                        );
                    },
                }}
                cta={() => (
                  <CtaWrapper>
                    <IconWrapper
                            onClick={() => setSettingsVisible(!dropdownVisible)}
                        >
                      <SettingsIcon />
                    </IconWrapper>
                    {dropdownVisible && (
                    <DropdownWrapper>
                      <DropdownItem
                                    onClick={() =>
                                        history.push(
                                            `${MPA_ROUTES.editSuperAdminUser}/${id}`
                                        )}
                                >
                        Edit profile
                      </DropdownItem>
                      <DropdownItem
                                    secondary
                                    onClick={
                                        toggleDeleteConfirmationModalVisible
                                    }
                                >
                        Delete User
                      </DropdownItem>
                    </DropdownWrapper>
                        )}
                  </CtaWrapper>
                )}
            />
        <SuperAdminUserWrapper>
          <Grid>
            {avatar ? (
              <Avatar src={avatar} alt={fullName} />
                    ) : (
                      <EmptyAvatar
                            color={color}
                            backgroundColor={backgroundColor}
                        >
                        {`${firstName && firstName[0]}${
                                lastName && lastName[0]
                            }`}
                      </EmptyAvatar>
                    )}

            <div>
              <Name>{fullName}</Name>
              <Email>{email}</Email>
            </div>
          </Grid>
        </SuperAdminUserWrapper>
        <ConfirmActionModal
                isVisible={deleteConfirmationModalVisible}
                onToggle={toggleDeleteConfirmationModalVisible}
                onConfirm={handleDeleteUser}
                description="Are you sure you want to delete user?"
                confirmLabel="Confirm"
                irreversible
            />
      </Container>
    );
};

MPASuperAdminUserDetailsView.propTypes = {
    id: PropTypes.string.isRequired,
    fullName: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    avatar: PropTypes.string,
    color: PropTypes.string,
    backgroundColor: PropTypes.string,
    dropdownVisible: PropTypes.bool.isRequired,
    setSettingsVisible: PropTypes.func.isRequired,
    deleteConfirmationModalVisible: PropTypes.bool.isRequired,
    toggleDeleteConfirmationModalVisible: PropTypes.func.isRequired,
    handleDeleteUser: PropTypes.func.isRequired,
};

MPASuperAdminUserDetailsView.defaultProps = {
    avatar: undefined,
    color: undefined,
    backgroundColor: undefined,
};

export default MPASuperAdminUserDetailsView;
