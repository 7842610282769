export const ESTABLISH_ADMIN_MEMOS = 'ESTABLISH_ADMIN_MEMOS';
export const ESTABLISH_ADMIN_MEMOS_SUCCESS = 'ESTABLISH_ADMIN_MEMOS_SUCCESS';
export const ESTABLISH_ADMIN_MEMOS_FAILURE = 'ESTABLISH_ADMIN_MEMOS_FAILURE';
export const LOAD_MORE_ADMIN_MEMOS = 'LOAD_MORE_ADMIN_MEMOS';
export const ALL_ADMIN_MEMOS_LOADED = 'ALL_ADMIN_MEMOS_LOADED';
export const RESET_ADMIN_MEMOS = 'RESET_ADMIN_MEMOS';

export const establishAdminMemos = () => ({
    type: ESTABLISH_ADMIN_MEMOS,
});

export const establishAdminMemosSuccess = (payload) => ({
    type: ESTABLISH_ADMIN_MEMOS_SUCCESS,
    payload,
});

export const establishAdminMemosFailure = (err) => ({
    type: ESTABLISH_ADMIN_MEMOS_FAILURE,
    err,
});

export const loadMoreAdminMemos = (payload) => ({
    type: LOAD_MORE_ADMIN_MEMOS,
    payload,
});

export const allAdminMemosLoaded = () => ({
    type: ALL_ADMIN_MEMOS_LOADED,
});

export const resetAdminMemos = () => ({
    type: RESET_ADMIN_MEMOS,
});
