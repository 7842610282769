import styled, { css } from 'styled-components/macro';
import { ReactComponent as TeamIconBase } from 'assets/svg/team-icon.svg';

export const StyledTeamMember = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 6fr 4fr 4fr 4fr 4fr 1fr 1fr;
    grid-gap: 8px;
    padding: 24px;
    align-items: center;
    margin-bottom: 16px;
    border-radius: ${({ theme }) => theme.border.radiusBase};
    border: ${({ theme }) => theme.border.base};
    background-color: ${({ theme }) => theme.color.white};

    ${({ threeColumns }) =>
        threeColumns &&
        css`
            grid-template-columns: 1fr 5fr 4fr;
        `}

    @media (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: calc(100vw - 24px);
        overflow-x: hidden;

        ${({ usersHa }) =>
            usersHa &&
            css`
                flex-direction: row !important;
                width: 750px;
            `}
    }
`;

export const TeamsSection = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const UserRolesSection = styled.div``;

export const AvatarWrapper = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    ${({ inactive }) =>
        inactive &&
        css`
            cursor: initial;
        `}
`;

export const StyledAvatar = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
`;

export const StyledAvatarPlaceholder = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    ${({ color }) =>
        color &&
        css`
            background-color: ${color};
        `}
`;

export const StyledLabel = styled.div`
    cursor: pointer;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;

    small {
        margin-top: 8px;
        color: ${({ theme }) => theme.color.textLight};
    }

    ${({ inactive }) =>
        inactive &&
        css`
            cursor: initial;
        `}
`;

export const TeamIconWrapper = styled.div`
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    background-color: ${({ theme, color }) => color || theme.color.goldLight};
    color: ${({ theme }) => theme.color.textNavigationCounter};
`;

export const TeamIcon = styled(TeamIconBase)`
    path {
        fill: ${({ theme, color }) => color || theme.color.gold};
    }
`;

export const Tooltip = styled.div`
    position: relative;
    display: inline-block;
    margin-left: -5px;

    & .tooltipText {
        padding: 10px;
        visibility: hidden;
        width: max-content;
        background-color: ${({ theme }) => theme.color.textBase};
        color: ${({ theme }) => theme.color.white};
        text-align: center;
        border-radius: 6px;
        position: absolute;
        z-index: 1;
        bottom: 40px;
        left: 0px;
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
    }

    & .tooltipText::after {
        content: '';
        position: absolute;
        top: 100%;
        left: 17px;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: ${({ theme }) => theme.color.textBase} transparent
            transparent transparent;
    }

    &:hover .tooltipText {
        visibility: visible;
    }
`;

export const ThreeDotsWrapper = styled.div`
    position: relative;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: ${({ theme }) => theme.border.radiusBase};

    &:hover {
        background-color: ${({ theme }) => theme.color.bgPrimaryLight};
    }

    ${({ inactive }) =>
        inactive &&
        css`
            cursor: initial;
        `}
`;

export const Dropdown = styled.div`
    z-index: 1;
    display: flex;
    flex-direction: column;
    width: 180px;
    position: absolute;
    border-radius: ${({ theme }) => theme.border.radiusBase};
    background-color: ${({ theme }) => theme.color.white};
    box-shadow: ${({ theme }) => theme.boxShadow.base};
    top: 49px;
    right: 0px;
`;

export const Divider = styled.div`
    width: 100%;
    border-bottom: 1px solid;
    border-color: ${({ theme }) => theme.color.borderColorBase};
`;

export const DropdownItem = styled.div`
    margin: 16px;
    padding: 16px;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    color: ${({ theme }) => theme.color.primary};
    border-radius: ${({ theme }) => theme.border.radiusSmall};
    z-index: 1;

    ${({ theme, secondary }) =>
        secondary &&
        css`
            color: ${theme.color.red};
        `}

    &:hover {
        cursor: pointer;
        background-color: ${({ theme }) => theme.color.bgPrimaryLight};

        ${({ theme, secondary }) =>
            secondary &&
            css`
                background-color: ${theme.color.pinkLight};
            `}
    }
`;

export const LastColumn = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
        margin-top: 8px;
    }
`;

export const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 49px;
    height: 49px;
    border-radius: ${({ theme }) => theme.border.radiusBase};
    cursor: pointer;

    &:hover {
        background-color: ${({ theme }) => theme.color.bgPrimaryLight};
    }

    ${({ inactive }) =>
        inactive &&
        css`
            cursor: initial;
            &:hover {
                background-color: transparent;
            }
        `}
`;

export const UserTagLabel = styled.div`
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    color: ${({ theme }) => theme.color.textLight};
`;
