import React from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';
import { MPA_ROUTES } from 'routing/constants';
import SingleSuperAdminUser from 'containers/SingleSuperAdminUser';
import SectionHeader from 'components/SectionHeader';
import Button from 'components/Button';
import Spinner from 'components/Spinner';
import Pagination from 'components/Pagination';
import NoResults from 'components/EmptyState/NoResults';

import {
    SuperAdminUsersWrapper,
    FiltersWrapper,
    Input,
    Text,
    ListWrapper,
    SpinnerWrapper,
    PaginationWrapper,
} from './MPASuperAdminUsers.style';

const MPASuperAdminUsersView = ({
    pages,
    currentPage,
    setCurrentPage,
    nextPage,
    prevPage,
    isNextEnabled,
    isPrevEnabled,
    isFetching,
    superAdminUsers,
    filter,
    handleChangeFilter,
    reloadList,
}) => {
    const history = useHistory();

    return (
      <SuperAdminUsersWrapper>
        <SectionHeader
                title="Super Admin Users"
                withPadding
                cta={() => (
                  <Button
                        primaryDark
                        onClick={() =>
                            history.push(MPA_ROUTES.addSuperAdminUser)}
                    >
                    New Admin
                  </Button>
                )}
            />
        <FiltersWrapper>
          <Text>Filters</Text>
          <Input
                    placeholder="Name"
                    defaultValue={filter}
                    onChange={handleChangeFilter}
                />
        </FiltersWrapper>

        {isFetching && (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
            )}

        {superAdminUsers.length === 0 ? (
          <NoResults message="" />
            ) : (
              <>
                <ListWrapper>
                  {superAdminUsers.map((superAdminUser) => (
                    <SingleSuperAdminUser
                                key={superAdminUser.id}
                                id={superAdminUser.id}
                                avatar={superAdminUser.avatar_url}
                                fullName={superAdminUser.fullname}
                                firstName={superAdminUser.first_name}
                                lastName={superAdminUser.last_name}
                                email={superAdminUser.email}
                                color={superAdminUser.color}
                                backgroundColor={
                                    superAdminUser.background_color
                                }
                                reloadList={reloadList}
                            />
                        ))}
                </ListWrapper>

                <PaginationWrapper>
                  <Pagination
                            pages={pages}
                            currentPage={currentPage}
                            handleNextPage={nextPage}
                            handlePrevPage={prevPage}
                            setCurrentPage={setCurrentPage}
                            isPrevEnabled={isPrevEnabled}
                            isNextEnabled={isNextEnabled}
                        />
                </PaginationWrapper>
              </>
            )}
      </SuperAdminUsersWrapper>
    );
};

MPASuperAdminUsersView.propTypes = {
    pages: PropTypes.arrayOf(PropTypes.any),
    currentPage: PropTypes.number.isRequired,
    setCurrentPage: PropTypes.func.isRequired,
    nextPage: PropTypes.func.isRequired,
    prevPage: PropTypes.func.isRequired,
    isNextEnabled: PropTypes.func.isRequired,
    isPrevEnabled: PropTypes.func.isRequired,
    isFetching: PropTypes.bool.isRequired,
    superAdminUsers: PropTypes.arrayOf(PropTypes.any),
    filter: PropTypes.string,
    handleChangeFilter: PropTypes.func.isRequired,
    reloadList: PropTypes.func.isRequired,
};
MPASuperAdminUsersView.defaultProps = {
    pages: [],
    superAdminUsers: [],
    filter: '',
};

export default MPASuperAdminUsersView;
