import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { HA_ROUTES, ROUTES } from 'routing/constants';
import StyledButton from './AdminHANavigation.style';

const AdminHANavigation = ({ variant }) => {
    const history = useHistory();

    return (
      <>
        {variant === 'smart-tips' ? (
          <StyledButton
                    primary
                    onClick={() => history.push(ROUTES.smartTips)}
                >
            Smart Tips
          </StyledButton>
            ) : (
              <StyledButton
                    transparent
                    onClick={() => history.push(ROUTES.smartTips)}
                >
                Smart Tips
              </StyledButton>
            )}

        {variant === 'tip-of-the-week' ? (
          <StyledButton
                    primary
                    onClick={() => history.push(HA_ROUTES.tipOfTheWeek)}
                >
            Tip of the Week
          </StyledButton>
            ) : (
              <StyledButton
                    transparent
                    onClick={() => history.push(HA_ROUTES.tipOfTheWeek)}
                >
                Tip of the Week
              </StyledButton>
            )}

        {variant === 'users-satisfaction' ? (
          <StyledButton
                    primary
                    onClick={() => history.push(HA_ROUTES.usersSatisfaction)}
                >
            Users Satisfaction
          </StyledButton>
            ) : (
              <StyledButton
                    transparent
                    onClick={() => history.push(HA_ROUTES.usersSatisfaction)}
                >
                Users Satisfaction
              </StyledButton>
            )}
      </>
    );
};

AdminHANavigation.propTypes = {
    variant: PropTypes.string.isRequired,
};

export default AdminHANavigation;
