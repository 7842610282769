import styled from 'styled-components/macro';
import DatePicker, { CalendarContainer } from 'react-datepicker';

export const StyledDatePicker = styled(DatePicker)`
    width: 100%;
`;

export const StyledCalendarContainer = styled(CalendarContainer)`
    background-color: ${({ theme }) => theme.color.white};
    border: ${({ theme }) => theme.border.base};
    border-radius: ${({ theme }) => theme.border.radiusSmall};
`;
