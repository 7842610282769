import styled, { css } from 'styled-components/macro';

export const EmptyAvatar = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px;
    margin-right: 8px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    text-align: center;
    vertical-align: middle;
    line-height: 32px;
    font-size: 12px;
    font-weight: 600;
    color: ${({ theme }) => theme.color.primary};
    background-color: ${({ theme }) => theme.color.bgPrimaryLight};

    ${({ color }) =>
        color &&
        css`
            color: ${color};
        `}

    ${({ backgroundColor }) =>
        backgroundColor &&
        css`
            background-color: ${backgroundColor};
        `}
`;

export const DropdownAvatar = styled.div`
    img,
    svg {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        margin-right: 8px;
        object-fit: cover;
    }
`;

export const Item = styled.div`
    width: 100%;
    border-radius: ${({ theme }) => theme.border.radiusBase};
    padding: 8px;
    display: flex;
    align-items: center;

    .closeIcon {
        width: 18px;
        height: 18px;
        cursor: pointer;
        margin-left: auto !important;
        margin-right: 8px;
        display: none;
    }

    &:hover {
        background-color: ${({ theme }) => theme.color.bgPrimaryLight};

        .closeIcon {
            display: inline;
        }
    }

    &:last-child {
        margin-bottom: 16px;
    }
`;

export const Text = styled.div`
    max-width: 320px;
    font-size: 14px;
`;
