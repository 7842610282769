import React, { useEffect } from 'react';
import useOffset from 'hooks/useOffset';
import { useDispatch, useSelector } from 'react-redux';
import { resetOrganizationTeams } from 'redux/actions/organizationTeams';
import establishOrganizationTeamsThunk, {
    loadMoreOrganizationTeamsThunk,
} from 'redux/actions/thunks/organizationTeams';
import TeamView from 'views/Team';
import { useAuth0 } from '@auth0/auth0-react';

const INFINITE_SCROLL_THRESHOLD = 20;

const TeamHA = () => {
    const { getAccessTokenSilently, logout } = useAuth0();
    const dispatch = useDispatch();
    const [offset, incrementOffset] = useOffset(INFINITE_SCROLL_THRESHOLD);
    const currentUser = useSelector((state) => state.currentUser);
    const {
        areOrganizationTeamsEstablished,
        areOrganizationTeamsFetching,
        organizationTeams,
        hasMoreOrganizationTeams,
    } = useSelector((state) => state.organizationTeams);

    const loadMore = () => {
        dispatch(
            loadMoreOrganizationTeamsThunk(
                getAccessTokenSilently,
                logout,
                INFINITE_SCROLL_THRESHOLD,
                offset
            )
        );
        incrementOffset();
    };

    useEffect(() => {
        dispatch(
            establishOrganizationTeamsThunk(
                getAccessTokenSilently,
                logout,
                INFINITE_SCROLL_THRESHOLD,
                0
            )
        );
        incrementOffset();
    }, []);

    useEffect(() => () => dispatch(resetOrganizationTeams()), []);

    return (
      <TeamView
            hasEstablished={areOrganizationTeamsEstablished}
            isFetching={areOrganizationTeamsFetching}
            teams={organizationTeams}
            loadFunc={loadMore}
            currentUser={currentUser}
            hasMore={hasMoreOrganizationTeams}
        />
    );
};

export default TeamHA;
