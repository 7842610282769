import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { css } from 'styled-components/macro';
import Accordion from 'components/Accordion';
import Input from 'components/Input';
import { TableHeader } from 'components/MetricsTable/MetricsTable.style';
import { MetricContainer } from 'components/SingleMetric/SingleMetric.style';
import Badge from 'components/Badge';
import Spinner from 'components/Spinner';
import ResultsHeader from './ResultsHeader';
import {
    ResultsModalBodyWrapper,
    ResultsSidebar,
    ResultsBody,
    SidebarDataSourceHolder,
    // SidebarDetailsHolder,
    // SidebarDetailsRow,
    BodyHolder,
} from './ResultsModalBody.style';

const SingleProvider = ({ children }) => (
  <MetricContainer>{children}</MetricContainer>
);

SingleProvider.propTypes = {
    children: PropTypes.node || PropTypes.any,
};

SingleProvider.defaultProps = {
    children() {
        return <></>;
    },
};

const ResultsModalBody = ({
    areUsersFetched,
    areUsersFetching,
    isUsersError,
    searchOrganizationUsers,
    searchMetrics,
    foundUsers,
    foundMetrics,
    metricSources,
    areSearchMetricsFetching,
    establishMetricResults,
    foundMetricResults,
}) => {
    const [loadedResults, setLoadedResults] = useState({});
    const [metricsList, setMetricsList] = useState([]);
    const [selectedMetrics, setSelectedMetrics] = useState([]);
    const [selectedNames, setSelectedNames] = useState([]);
    const [sourcesOptions, setSourcesOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState({
        id: null,
        title: 'Select Data Source',
    });
    const [dropdownVisible, setDropdownVisible] = useState(false);

    useEffect(() => {
        setSourcesOptions(
            metricSources.map((source) => ({
                id: source.id,
                title: source.name,
            }))
        );
    }, [metricSources]);

    useEffect(() => {
        if (sourcesOptions.length > 0 && sourcesOptions[0].id !== null) {
            setSelectedOption(sourcesOptions[0]);

            searchMetrics(sourcesOptions[0].id, '', 10, 0);
        }
    }, [sourcesOptions]);

    useEffect(() => {
        if (foundMetricResults) {
            setLoadedResults(foundMetricResults);
        }
    }, [foundMetricResults]);

    useEffect(() => {
        setMetricsList([...foundMetrics]);
    }, [foundMetrics]);

    const handleSelectSource = (id) => {
        setSelectedOption(sourcesOptions.find((option) => option.id === id));
        setSelectedMetrics([]);
        setSelectedNames([]);
        searchMetrics(id, '', 10, 0);
        setDropdownVisible(false);
    };

    const filterMetrics = (passedSelectedMetrics) => {
        const filteredMetrics = passedSelectedMetrics.map((metricId) =>
            foundMetrics.find((metric) => metric.id === metricId)
        );
        setMetricsList([...filteredMetrics]);
    };

    return (
      <ResultsModalBodyWrapper>
        <ResultsSidebar>
          <SidebarDataSourceHolder>
            <span>Data Source</span>
            <Input
                        variant="dropdown"
                        dropdownVisible={dropdownVisible}
                        options={sourcesOptions}
                        value={selectedOption.title}
                        style={{ cursor: 'pointer', width: '252px' }}
                        handleSelect={handleSelectSource}
                        onClick={() => setDropdownVisible(!dropdownVisible)}
                        customStyles={css`
                            width: 100%;
                        `}
                        readOnly
                    />
          </SidebarDataSourceHolder>
          {/* <SidebarDetailsHolder>
                    <SidebarDetailsRow>
                        <small>Orga name/ID</small>
                        <span>
                            {window.localStorage
                                .getItem('MP_ORGANIZATION')
                                .toUpperCase()}
                        </span>
                    </SidebarDetailsRow>
                </SidebarDetailsHolder> */}
        </ResultsSidebar>
        <ResultsBody>
          <ResultsHeader
                    areUsersFetched={areUsersFetched}
                    areUsersFetching={areUsersFetching}
                    isUsersError={isUsersError}
                    searchOrganizationUsers={searchOrganizationUsers}
                    searchMetrics={searchMetrics}
                    foundUsers={foundUsers}
                    foundMetrics={foundMetrics}
                    selectedDataSource={selectedOption}
                    areSearchMetricsFetching={areSearchMetricsFetching}
                    filterMetrics={filterMetrics}
                    selectedMetrics={selectedMetrics}
                    setSelectedMetrics={setSelectedMetrics}
                    selectedNames={selectedNames}
                    setSelectedNames={setSelectedNames}
                />
          <BodyHolder>
            {metricsList.map((metric) => (
              <Accordion
                            expandOnlyOnArrowClick
                            key={metric.id}
                            label={metric.name}
                            customStyles={css`
                                width: 100%;
                                margin: 8px 0 16px;
                            `}
                            customOnClick={() => {
                                establishMetricResults(
                                    metric.id,
                                    foundUsers.map((user) => user.id)
                                );
                            }}
                        >
                <TableHeader>
                  <span>User Name</span>
                  <span>Goal</span>
                  <span>Result</span>
                  <span>Performance</span>
                </TableHeader>
                {loadedResults[metric.id] ? (
                                loadedResults[metric.id]
                                    .filter((result) => {
                                        if (selectedNames.length > 0) {
                                            return selectedNames.includes(
                                                result.user_id
                                            );
                                        }

                                        return result;
                                    })
                                    .map((result) => (
                                      <SingleProvider key={result.id}>
                                        <span>{result.user_name}</span>
                                        <span>{result.goal}</span>
                                        <span>{result.result}</span>
                                        <span>
                                          {result.efficient ? (
                                            <Badge secondary>
                                              Efficient
                                            </Badge>
                                                ) : (
                                                  <Badge quaternary>
                                                    Inefficient
                                                  </Badge>
                                                )}
                                        </span>
                                      </SingleProvider>
                                    ))
                            ) : (
                              <SingleProvider>
                                <Spinner />
                              </SingleProvider>
                            )}
                {loadedResults[metric.id] &&
                                loadedResults[metric.id].length === 0 && (
                                <SingleProvider>
                                  <span>
                                    No results for selected criteria.
                                  </span>
                                </SingleProvider>
                                )}
              </Accordion>
                    ))}
          </BodyHolder>
        </ResultsBody>
      </ResultsModalBodyWrapper>
    );
};

ResultsModalBody.propTypes = {
    areUsersFetched: PropTypes.bool.isRequired,
    areUsersFetching: PropTypes.bool.isRequired,
    isUsersError: PropTypes.bool.isRequired,
    areSearchMetricsFetching: PropTypes.bool.isRequired,
    establishMetricResults: PropTypes.func.isRequired,
    searchOrganizationUsers: PropTypes.func.isRequired,
    foundUsers: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
        })
    ).isRequired,
    searchMetrics: PropTypes.func.isRequired,
    foundMetrics: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
        })
    ).isRequired,
    foundMetricResults: PropTypes.shape({
        metric: PropTypes.string,
        results: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string,
                goal: PropTypes.string,
                result: PropTypes.string,
                efficient: PropTypes.bool,
            })
        ),
    }).isRequired,
    metricSources: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default ResultsModalBody;
