import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Sidebar from 'components/Sidebar';

const SidebarContainer = ({
    isMobile,
    isMobileExpanded,
    toggleMobileSidebar,
}) => (
  <Sidebar
        isMobile={isMobile}
        isMobileExpanded={isMobileExpanded}
        toggleMobileSidebar={toggleMobileSidebar}
    />
);

SidebarContainer.propTypes = {
    currentUser: PropTypes.shape({
        isUserEstablished: PropTypes.bool,
        isUserError: PropTypes.bool,
        isUserFetching: PropTypes.bool,
        userEmail: PropTypes.string,
        userName: PropTypes.string,
    }).isRequired,
    actions: PropTypes.shape({}).isRequired,
    isMobile: PropTypes.bool.isRequired,
    isMobileExpanded: PropTypes.bool.isRequired,
    toggleMobileSidebar: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    currentUser: state.currentUser,
});

const mapDispatchToProps = () => ({
    actions: {},
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarContainer);
