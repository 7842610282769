import {
    establishCustomers,
    allCustomersLoaded,
    loadMoreCustomers,
    establishCustomersSuccess,
    establishCustomersFailure,
    resetCustomers,
} from 'redux/actions/customers';
import apiRoutes from 'config/apiRoutes';
import MPA_ORGANIZATION_SLUG from 'config/mpaAccessSlug';

export const establishCustomersThunk = (
    getAccessTokenSilently,
    logout,
    limit = 20,
    offset = 0
) => (dispatch) => {
    dispatch(establishCustomers());

    const url = apiRoutes.get.customers(limit, offset);

    getAccessTokenSilently()
        .then((token) => {
            fetch(url, {
                method: 'GET',
                headers: {
                    organization_slug: MPA_ORGANIZATION_SLUG,
                    Authorization: `Token token="${token}"`,
                    'Content-Type': 'application/json',
                },
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.error) {
                        return dispatch(establishCustomersFailure(res.error));
                    }

                    if (res.organizations.length < limit) {
                        dispatch(allCustomersLoaded());
                    }

                    return dispatch(
                        establishCustomersSuccess(res.organizations)
                    );
                })
                .catch((err) =>
                    dispatch(
                        establishCustomersFailure({
                            err,
                        })
                    )
                );
        })
        .catch(() => logout({ returnTo: window.location.origin }));
};

export const loadMoreCustomersThunk = (
    getAccessTokenSilently,
    logout,
    limit,
    offset
) => (dispatch) => {
    dispatch(establishCustomers());

    const url = apiRoutes.get.customers(limit, offset);

    getAccessTokenSilently()
        .then((token) => {
            fetch(url, {
                method: 'GET',
                headers: {
                    organization_slug: MPA_ORGANIZATION_SLUG,
                    Authorization: `Token token="${token}"`,
                    'Content-Type': 'application/json',
                },
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.error) {
                        return dispatch(establishCustomersFailure(res.error));
                    }

                    if (res.organizations.length === 0) {
                        return dispatch(allCustomersLoaded());
                    }

                    return dispatch(loadMoreCustomers(res.organizations));
                })
                .catch((err) =>
                    dispatch(
                        establishCustomersFailure({
                            err,
                        })
                    )
                );
        })
        .catch(() => logout({ returnTo: window.location.origin }));
};

export const resetCustomersThunk = () => (dispatch) => {
    dispatch(resetCustomers());
};
