import styled from 'styled-components/macro';

export const Container = styled.div`
    display: flex;
    flex-flow: column;
`;

export const Title = styled.p`
    font-weight: 600;
    font-size: 18px;
    margin: 0;
`;

export const Row = styled.div`
    border: 1px solid;
    border-color: ${({ theme }) => theme.color.borderColorBase};
    border-radius: 16px;
    margin-top: 32px;
    display: grid;
    grid-template-columns: 3fr 3fr 3fr 1fr;

    @media (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
        display: block;
    }
`;

export const StyledItem = styled.div`
    padding: 24px 36px;
    height: 101px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: flex-start;
    border-right: 1px solid;
    border-color: ${({ theme }) => theme.color.borderColorBase};

    &:last-child {
        border-right: none;
    }
`;

export const ItemTitle = styled.p`
    font-weight: 500;
    margin: 0;
    margin-bottom: 11px;
    color: ${({ theme }) => theme.color.textLight};
`;

export const ItemValue = styled.p`
    margin: 0;
    font-weight: 600;
`;
