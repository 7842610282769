import {
    ESTABLISH_ORGANIZATION_USERS,
    ESTABLISH_ORGANIZATION_USERS_SUCCESS,
    ESTABLISH_ORGANIZATION_USERS_FAILURE,
    LOAD_MORE_ORGANIZATION_USERS,
    MARK_ALL_ORGANIZATION_USERS_LOADED,
    TOGGLE_USER_STATUS,
    TOGGLE_USER_STATUS_SUCCESS,
    TOGGLE_USER_STATUS_FAILURE,
    DELETE_ORGANIZATION_USER,
    DELETE_ORGANIZATION_USER_SUCCESS,
    DELETE_ORGANIZATION_USER_FAILURE,
    RESET_ORGANIZATION_USERS,
    LOADING_ORGANIZATION_USERS,
    CHOOSEN_FILTER_TAGS,
} from 'redux/actions/organizationUsers';

export const initialState = {
    areOrganizationUsersEstablished: false,
    isOrganizationUsersError: false,
    areOrganizationUsersFetching: false,
    isLoadingOrganizationUsers: true,
    organizationUsers: [],
    filterTags: [],
    error: null,
    hasMoreOrganizationUsers: true,
    amountOfAllUsers: 0,
};

const organizationUsers = (state = initialState, action) => {
    switch (action.type) {
        case ESTABLISH_ORGANIZATION_USERS:
            return {
                ...state,
                areOrganizationUsersFetching: true,
                isOrganizationUsersError: false,
                amountOfAllUsers: 0,
            };

        case ESTABLISH_ORGANIZATION_USERS_SUCCESS:
            return {
                ...state,
                isLoadingOrganizationUsers: false,
                areOrganizationUsersFetching: false,
                areOrganizationUsersEstablished: true,
                organizationUsers: action.payload.users,
                amountOfAllUsers: action.payload.total_count,
            };

        case ESTABLISH_ORGANIZATION_USERS_FAILURE:
            return {
                ...state,
                isLoadingOrganizationUsers: false,
                areOrganizationUsersFetching: false,
                isOrganizationUsersError: true,
                error: action.err,
                amountOfAllUsers: 0,
            };

        case LOAD_MORE_ORGANIZATION_USERS:
            return {
                ...state,
                isLoadingOrganizationUsers: true,
                areOrganizationUsersFetching: false,
                areOrganizationUsersEstablished: true,
                organizationUsers: [
                    ...state.organizationUsers,
                    ...action.payload.users,
                ],
                amountOfAllUsers: action.payload.total_count,
            };
        case MARK_ALL_ORGANIZATION_USERS_LOADED:
            return {
                ...state,
                hasMoreOrganizationUsers: false,
                areOrganizationUsersFetching: false,
                areOrganizationUsersEstablished: true,
            };

        case TOGGLE_USER_STATUS:
            return {
                ...state,
            };

        case TOGGLE_USER_STATUS_SUCCESS:
            return {
                ...state,
            };

        case TOGGLE_USER_STATUS_FAILURE:
            return {
                ...state,
                isUsersError: true,
                error: action.err,
            };

        case DELETE_ORGANIZATION_USER:
            return {
                ...state,
            };

        case DELETE_ORGANIZATION_USER_SUCCESS:
            return {
                ...state,
                organizationUsers: state.organizationUsers.filter(
                    (user) => user.id !== action.payload
                ),
            };

        case DELETE_ORGANIZATION_USER_FAILURE:
            return {
                ...state,
                isUsersError: true,
                error: action.err,
            };

        case RESET_ORGANIZATION_USERS:
            return initialState;

        case LOADING_ORGANIZATION_USERS:
            return {
                ...state,
                isLoadingOrganizationUsers: action.payload,
            };
        case CHOOSEN_FILTER_TAGS:
            return {
                ...state,
                filterTags: action.payload,
            };
        default:
            return state;
    }
};

export default organizationUsers;
