import React from 'react';
import PropTypes from 'prop-types';
import parseDate from './TeamOverviewMessages.common';
import {
    SingleNotification,
    SingleNotificationContent,
    SingleNotificationDate,
    SingleMessageAvatar,
} from './TeamOverviewMessages.style';

const NotificationMessage = ({ message }) => (
  <SingleNotification>
    <SingleMessageAvatar>
      <img src={message.user.avatar_url} alt="avatar" />
    </SingleMessageAvatar>
    <SingleNotificationContent>{message.message}</SingleNotificationContent>
    <SingleNotificationDate>
      {parseDate(message.created_at)}
    </SingleNotificationDate>
  </SingleNotification>
);

NotificationMessage.propTypes = {
    message: PropTypes.shape({
        id: PropTypes.string,
        message: PropTypes.string,
        type: PropTypes.string,
        likes_count: PropTypes.number,
        created_at: PropTypes.string,
        user: PropTypes.shape({
            id: PropTypes.string,
            first_name: PropTypes.string,
            last_name: PropTypes.string,
            color: PropTypes.string,
            background_color: PropTypes.string,
            email: PropTypes.string,
            avatar_url: PropTypes.string,
        }),
    }).isRequired,
};

export default NotificationMessage;
