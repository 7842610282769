import styled, { css } from 'styled-components/macro';
import { ReactComponent as BellIconBase } from 'assets/svg/alarm-bell.svg';
import { ReactComponent as SquareBase } from 'assets/svg/notifications/square.svg';

export const Square = styled(SquareBase)`
    position: absolute;
    top: -11px;
    right: 50px;
`;

export const NotificationDropdownHolder = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 46px;
    height: 46px;

    ${({ unseenCount }) =>
        unseenCount &&
        css`
            background: ${({ theme }) => theme.color.bgPrimaryLight};
            border-radius: ${({ theme }) => theme.border.radiusBase};
        `}
`;

export const NotificationDropdownWrapper = styled.div`
    position: absolute;
    top: 50px;
    right: -40px;
    width: 430px;
    background-color: ${({ theme }) => theme.color.white};
    border: ${({ theme }) => theme.border.base};
    border-radius: ${({ theme }) => theme.border.radiusSmall};

    @media (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
        width: calc(100vw - 32px);
    }
`;

export const NotificationListWrapper = styled.div`
    max-height: calc(80vh - 70px - 98px);
    overflow: hidden;
    overflow-y: auto;
`;

export const BellIcon = styled(BellIconBase)`
    width: '20px';
    cursor: pointer;
    fill: ${({ theme }) => theme.color.textNavigation};
`;

export const Counter = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -10px;
    right: -10px;
    width: 29px;
    height: 24px;
    background: ${({ theme }) => theme.color.bgQuaternary};
    border-radius: 20px;
`;

export const TopSection = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 70px;
    padding: 0px 10px 0px 30px;

    p {
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        color: ${({ theme }) => theme.color.textNavigationCounter};
    }
`;

export const BottomSection = styled.div`
    border-top: ${({ theme }) => theme.border.base};
    padding: 24px;
`;
