import {
    establishMetricUploads,
    establishMetricUploadsSuccess,
    establishMetricUploadsFailure,
    destroyMetricUpload,
    loadMoreMetricUploads,
    allMetricUploadsLoaded,
    resetMetricUploads,
} from 'redux/actions/metricUploads';
import {
    dispatchSuccessAlertThunk,
    dispatchErrorAlertThunk,
} from 'redux/actions/thunks/systemAlerts';
import apiRoutes from 'config/apiRoutes';

const establishMetricUploadsThunk = (
    getAccessTokenSilently,
    logout,
    limit,
    offset
) => (dispatch) => {
    dispatch(establishMetricUploads());

    return getAccessTokenSilently()
        .then((token) => {
            fetch(apiRoutes.get.metricUploads(limit, offset), {
                method: 'GET',
                headers: {
                    organization_slug: window.localStorage.getItem(
                        'MP_ORGANIZATION'
                    ),
                    Authorization: `Token token="${token}"`,
                },
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.error) {
                        return dispatch(
                            establishMetricUploadsFailure(res.error)
                        );
                    }

                    return dispatch(
                        establishMetricUploadsSuccess(
                            res.metric_results_uploads
                        )
                    );
                })
                .catch((err) =>
                    dispatch(
                        establishMetricUploadsFailure({
                            err,
                        })
                    )
                );
        })
        .catch(() => logout({ returnTo: window.location.origin }));
};

export const destroyMetricUploadThunk = (
    getAccessTokenSilently,
    logout,
    metricUploadId
) => (dispatch) => {
    dispatch(destroyMetricUpload());

    return getAccessTokenSilently()
        .then((token) => {
            fetch(apiRoutes.delete.destroyMetricUpload(metricUploadId), {
                method: 'DELETE',
                headers: {
                    organization_slug: window.localStorage.getItem(
                        'MP_ORGANIZATION'
                    ),
                    Authorization: `Token token="${token}"`,
                },
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.error) {
                        dispatch(
                            dispatchErrorAlertThunk(metricUploadId, res.error)
                        );
                        return dispatch(
                            establishMetricUploadsFailure(res.error)
                        );
                    }

                    dispatch(
                        dispatchSuccessAlertThunk(
                            metricUploadId,
                            'Record has been deleted'
                        )
                    );
                    return dispatch(
                        establishMetricUploadsThunk(
                            getAccessTokenSilently,
                            logout
                        )
                    );
                })
                .catch((err) => {
                    dispatch(
                        dispatchErrorAlertThunk(metricUploadId, err.error)
                    );
                    dispatch(
                        establishMetricUploadsFailure({
                            err,
                        })
                    );
                });
        })
        .catch(() => logout({ returnTo: window.location.origin }));
};

export const loadMoreMetricUploadsThunk = (
    getAccessTokenSilently,
    logout,
    limit,
    offset
) => (dispatch) => {
    dispatch(establishMetricUploads());

    const url = apiRoutes.get.metricUploads(limit, offset);

    return getAccessTokenSilently()
        .then((token) => {
            fetch(url, {
                method: 'GET',
                headers: {
                    organization_slug: window.localStorage.getItem(
                        'MP_ORGANIZATION'
                    ),
                    Authorization: `Token token="${token}"`,
                },
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.error) {
                        return dispatch(
                            establishMetricUploadsFailure(res.error)
                        );
                    }

                    if (res.metric_results_uploads.length === 0) {
                        return dispatch(allMetricUploadsLoaded());
                    }

                    return dispatch(
                        loadMoreMetricUploads(res.metric_results_uploads)
                    );
                })
                .catch((err) =>
                    dispatch(
                        establishMetricUploadsFailure({
                            err,
                        })
                    )
                );
        })
        .catch(() => logout({ returnTo: window.location.origin }));
};

export const resetMetricUploadsThunk = () => (dispatch) => {
    dispatch(resetMetricUploads());
};

export default establishMetricUploadsThunk;
