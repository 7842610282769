import {
    establishAdminMemos,
    establishAdminMemosSuccess,
    establishAdminMemosFailure,
    resetAdminMemos,
    allAdminMemosLoaded,
    loadMoreAdminMemos,
} from 'redux/actions/adminMemos';
import apiRoutes from 'config/apiRoutes';

const establishAdminMemosThunk = (
    getAccessTokenSilently,
    logout,
    limit,
    offset
) => (dispatch) => {
    dispatch(establishAdminMemos());

    return getAccessTokenSilently()
        .then((token) => {
            fetch(apiRoutes.get.adminMemos(limit, offset), {
                method: 'GET',
                headers: {
                    organization_slug: window.localStorage.getItem(
                        'MP_ORGANIZATION'
                    ),
                    Authorization: `Token token="${token}"`,
                },
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.error) {
                        return dispatch(establishAdminMemosFailure(res.error));
                    }

                    return dispatch(
                        establishAdminMemosSuccess(res.admin_memos)
                    );
                })
                .catch((err) => {
                    dispatch(
                        establishAdminMemosFailure({
                            err,
                        })
                    );
                })
                .finally(
                    () => !limit && !offset && dispatch(allAdminMemosLoaded())
                );
        })
        .catch(() => logout({ returnTo: window.location.origin }));
};

export const loadMoreAdminMemosThunk = (
    getAccessTokenSilently,
    logout,
    limit,
    offset
) => (dispatch) => {
    dispatch(establishAdminMemos());

    return getAccessTokenSilently()
        .then((token) => {
            fetch(apiRoutes.get.adminMemos(limit, offset), {
                method: 'GET',
                headers: {
                    organization_slug: window.localStorage.getItem(
                        'MP_ORGANIZATION'
                    ),
                    Authorization: `Token token="${token}"`,
                },
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.error) {
                        return dispatch(establishAdminMemosFailure(res.error));
                    }

                    if (res.admin_memos.length === 0) {
                        return dispatch(allAdminMemosLoaded());
                    }

                    return dispatch(loadMoreAdminMemos(res.admin_memos));
                })
                .catch((err) => {
                    dispatch(
                        establishAdminMemosFailure({
                            err,
                        })
                    );
                });
        })
        .catch(() => logout({ returnTo: window.location.origin }));
};

export const resetAdminMemosThunk = () => (dispatch) => {
    dispatch(resetAdminMemos());
};

export const revertAdminMemoThunk = (
    getAccessTokenSilently,
    logout,
    adminMemoId,
    reloadAdminMemos
) => (dispatch) => {
    dispatch(resetAdminMemosThunk());

    return getAccessTokenSilently()
        .then((token) => {
            fetch(apiRoutes.delete.revertAdminMemo(adminMemoId), {
                method: 'DELETE',
                headers: {
                    organization_slug: window.localStorage.getItem(
                        'MP_ORGANIZATION'
                    ),
                    Authorization: `Token token="${token}"`,
                },
            })
                .then(() => {
                    reloadAdminMemos();
                })
                .catch((err) =>
                    dispatch(
                        establishAdminMemosFailure({
                            err,
                        })
                    )
                );
        })
        .catch(() => logout({ returnTo: window.location.origin }));
};

export const createAdminMemoThunk = (
    getAccessTokenSilently,
    logout,
    adminMemo,
    reloadAdminMemos
) => (dispatch) =>
    getAccessTokenSilently()
        .then((token) => {
            fetch(apiRoutes.post.createAdminMemo(), {
                method: 'POST',
                headers: {
                    organization_slug: window.localStorage.getItem(
                        'MP_ORGANIZATION'
                    ),
                    Authorization: `Token token="${token}"`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(adminMemo),
            })
                .then(() => {
                    reloadAdminMemos();
                })
                .catch((err) =>
                    dispatch(
                        establishAdminMemosFailure({
                            err,
                        })
                    )
                );
        })
        .catch(() => logout({ returnTo: window.location.origin }));

export default establishAdminMemosThunk;
