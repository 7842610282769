import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as StarsIcon } from 'assets/svg/star.svg';
import { ReactComponent as UserIcon } from 'assets/svg/user.svg';
import theme from 'assets/theme/mainTheme';
import {
    SingleTeamHolder,
    TitleHolder,
    IconHolder,
    Title,
    StarsHolder,
} from './SingleTeam.style';

const SingleRankedTeam = ({
    title,
    order,
    usersCount,
    starsCount,
    isCurrentUserTeamMember,
}) => (
  <SingleTeamHolder data-testid="SingleRankedTeam">
    <TitleHolder>
      <IconHolder
                ranking
                gold={order === 1}
                silver={order === 2}
                bronze={order === 3}
            >
        {order}
      </IconHolder>
      <Title>
        <h3 data-testid="teamTitle">{title}</h3>
        <span>
          {usersCount} 
          {' '}
          {usersCount === 1 ? 'user' : 'users'}
        </span>
      </Title>
    </TitleHolder>
    <StarsHolder data-testid="starsHolder">
      <p>{starsCount}</p>
      <IconHolder starsHolder>
        <StarsIcon fill={theme.color.secondary} />
      </IconHolder>
    </StarsHolder>
    {isCurrentUserTeamMember && (
    <IconHolder
                data-testid="teamMembershipIndicator"
                currentTeamIndicator
            >
      <UserIcon />
    </IconHolder>
        )}
  </SingleTeamHolder>
);

SingleRankedTeam.propTypes = {
    title: PropTypes.string,
    order: PropTypes.number.isRequired,
    usersCount: PropTypes.number.isRequired,
    starsCount: PropTypes.number.isRequired,
    isCurrentUserTeamMember: PropTypes.bool.isRequired,
};

SingleRankedTeam.defaultProps = {
    title: '(untitled)',
};

export default SingleRankedTeam;
