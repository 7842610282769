import {
    establishCurrentUser,
    establishCurrentUserSuccess,
    establishCurrentUserFailure,
    establishUsers,
    establishUsersSuccess,
    establishUsersFailure,
    fetchRecentUsers,
    fetchRecentUsersSuccess,
    fetchRecentUsersFailure,
    loadMoreUsers,
    allUsersLoaded,
    resetUsers,
} from 'redux/actions/user';
import paramsToString from 'redux/helpers/paramsToString';
import apiRoutes from 'config/apiRoutes';

export const establishCurrentUserThunk = (getAccessTokenSilently, logout) => (
    dispatch
) => {
    dispatch(establishCurrentUser());
    const organization = window.localStorage.getItem('MP_ORGANIZATION');

    return getAccessTokenSilently()
        .then((token) => {
            fetch(apiRoutes.get.userProfile, {
                method: 'GET',
                headers: {
                    organization_slug: organization,
                    Authorization: `Token token="${token}"`,
                },
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.error) {
                        logout({ returnTo: window.location.origin });
                        return dispatch(establishCurrentUserFailure(res.error));
                    }

                    const isAdmin =
                        res.role === 'hospital_admin' ||
                        res.role === 'medpower_admin';
                    const isMPA = res.role === 'medpower_admin';

                    return dispatch(
                        establishCurrentUserSuccess({
                            organizationName: organization,
                            id: res.id,
                            isAdmin,
                            isMPA,
                            userId: res.lms_user_id,
                            userEmail: res.email,
                            userFirstName: res.first_name,
                            userLastName: res.last_name,
                            userName: `${res.first_name} ${res.last_name}`,
                            userAvatarUrl: res.avatar_url,
                            userLevel: res.level,
                            userScore: res.points,
                            pointsToNextLevel: res.points_to_next_level,
                            userTeams: res.teams,
                            isLMSEnabled: res.organization.is_lms_enabled,
                            isScoreNotificationsEnabled:
                                res.score_notifications_enabled,
                            isMessageNotificationsEnabled:
                                res.message_notifications_enabled,
                            isEmailNotificationsEnabled:
                                res.email_notifications_enabled,
                            lmsCoursesListLink:
                                res.organization &&
                                res.organization.lms_courses_list_url,
                        })
                    );
                })
                .catch((err) => {
                    dispatch(
                        establishCurrentUserFailure({
                            err,
                        })
                    );
                    logout({ returnTo: window.location.origin });
                });
        })
        .catch(() => logout({ returnTo: window.location.origin }));
};

export const establishUsersThunk = (
    getAccessTokenSilently,
    logout,
    limit,
    offset,
    params = {}
) => (dispatch) => {
    dispatch(establishUsers());

    const url = apiRoutes.get.users(limit, offset) + paramsToString(params);

    return getAccessTokenSilently()
        .then((token) => {
            fetch(url, {
                method: 'GET',
                headers: {
                    organization_slug: window.localStorage.getItem(
                        'MP_ORGANIZATION'
                    ),
                    Authorization: `Token token="${token}"`,
                },
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.error) {
                        return dispatch(establishUsersFailure(res.error));
                    }

                    return dispatch(establishUsersSuccess(res.users));
                })
                .catch((err) =>
                    dispatch(
                        establishUsersFailure({
                            err,
                        })
                    )
                )
                .finally(() => !limit && !offset && dispatch(allUsersLoaded()));
        })
        .catch(() => logout({ returnTo: window.location.origin }));
};

export const loadMoreUsersThunk = (
    getAccessTokenSilently,
    logout,
    limit,
    offset,
    params = {}
) => (dispatch) => {
    dispatch(establishUsers());

    const url = apiRoutes.get.users(limit, offset) + paramsToString(params);

    return getAccessTokenSilently()
        .then((token) => {
            fetch(url, {
                method: 'GET',
                headers: {
                    organization_slug: window.localStorage.getItem(
                        'MP_ORGANIZATION'
                    ),
                    Authorization: `Token token="${token}"`,
                },
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.error) {
                        return dispatch(establishUsersFailure(res.error));
                    }

                    if (res.users.length === 0) {
                        return dispatch(allUsersLoaded());
                    }

                    return dispatch(loadMoreUsers(res.users));
                })
                .catch((err) =>
                    dispatch(
                        establishUsersFailure({
                            err,
                        })
                    )
                );
        })
        .catch(() => logout({ returnTo: window.location.origin }));
};

export const fetchRecentlyVisitedUsersThunk = (
    getAccessTokenSilently,
    logout
) => (dispatch) => {
    dispatch(fetchRecentUsers());

    return getAccessTokenSilently()
        .then((token) => {
            fetch(apiRoutes.get.usersRecentlyVisited, {
                method: 'GET',
                headers: {
                    organization_slug: window.localStorage.getItem(
                        'MP_ORGANIZATION'
                    ),
                    Authorization: `Token token="${token}"`,
                },
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.error) {
                        return dispatch(fetchRecentUsersFailure(res.error));
                    }

                    return dispatch(fetchRecentUsersSuccess(res.users));
                })
                .catch((err) =>
                    dispatch(
                        fetchRecentUsersFailure({
                            err,
                        })
                    )
                );
        })
        .catch(() => logout({ returnTo: window.location.origin }));
};

export const resetUsersThunk = () => (dispatch) => {
    dispatch(resetUsers());
};
