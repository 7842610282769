import React from 'react';
import { useHistory } from 'react-router-dom';
import SectionHeader from 'components/SectionHeader';
import HospitalAdminCTA from 'components/HospitalAdminCTA';
import PerformanceDashboard from 'containers/PerformanceDashboard/PerformanceDashboard';
import theme from 'assets/theme/mainTheme';
import HARecentItems from 'containers/PerformanceDashboard/HARecentItems';
import { HA_ROUTES, ROUTES } from 'routing/constants';
import {
    StyledDashboardWrapper,
    HACTAWrapper,
    DashboardItemWrapper,
} from './DashboardMain.style';

const DashboardMainHA = () => {
    const history = useHistory();

    return (
      <StyledDashboardWrapper oneColumn>
        <DashboardItemWrapper>
          <SectionHeader title="Dashboard" />
          <HACTAWrapper>
            <HospitalAdminCTA
                        headerText="Create new Smart Tip"
                        helperText="Add and assign new Smart Tip"
                        callbackFunc={() =>
                            history.push(HA_ROUTES.createSmartTip)}
                        icon="light-bulb"
                    />
            <HospitalAdminCTA
                        headerText="Review Smart Tips"
                        helperText="See and review all Smart Tips"
                        callbackFunc={() =>
                            history.push(
                                `${ROUTES.smartTips}?filter=unpublished`
                            )}
                        icon="feather"
                        backgroundColor={theme.color.paleGreen}
                    />
            <HospitalAdminCTA
                        headerText="Manage Users"
                        helperText="Manage Users in your organization"
                        callbackFunc={() => history.push(HA_ROUTES.users)}
                        icon="multiple-users"
                        backgroundColor={theme.color.gold}
                    />
          </HACTAWrapper>
          <SectionHeader title="Average Performance" />
          <PerformanceDashboard twoThird goToPerformance />
          <HARecentItems />
        </DashboardItemWrapper>
      </StyledDashboardWrapper>
    );
};

export default DashboardMainHA;
