import React, { useEffect, useState } from 'react';
import useOffset from 'hooks/useOffset';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
    establishTeamMembersThunk,
    resetMembersThunk,
    removeMemberThunk,
    leaveTeamThunk,
} from 'redux/actions/thunks/teamMembers';
import TeamMembersView from 'views/TeamMembersView';
import useAdmin from 'hooks/useAdmin';
import { useAuth0 } from '@auth0/auth0-react';

const INFINITE_SCROLL_THRESHOLD = 20;

const TeamMembers = () => {
    const { getAccessTokenSilently, logout } = useAuth0();
    const dispatch = useDispatch();
    const members = useSelector((state) => state.teamMembers);
    const { teamId } = useParams();
    const [displayedTeamMembers, setDisplayedTeamMembers] = useState([]);
    const [offset, incrementOffset, resetOffset] = useOffset(
        INFINITE_SCROLL_THRESHOLD
    );
    const isAdmin = useAdmin();

    const loadMoreTeamMembers = () => {
        if (displayedTeamMembers.length + offset > members.teamMembers.length) {
            setDisplayedTeamMembers([
                ...displayedTeamMembers,
                ...members.teamMembers.slice(
                    offset,
                    members.teamMembers.length
                ),
            ]);

            resetOffset(members.teamMembers.length);
            return;
        }

        setDisplayedTeamMembers([
            ...displayedTeamMembers,
            ...members.teamMembers.slice(
                offset,
                offset + INFINITE_SCROLL_THRESHOLD
            ),
        ]);
        incrementOffset();
    };

    const removeUserFromTeam = (memberId) => {
        dispatch(
            removeMemberThunk(
                getAccessTokenSilently,
                logout,
                teamId,
                memberId,
                isAdmin
            )
        );
    };

    const leaveTeam = () =>
        dispatch(leaveTeamThunk(getAccessTokenSilently, logout, teamId));

    useEffect(() => {
        setDisplayedTeamMembers(
            members.teamMembers.slice(0, INFINITE_SCROLL_THRESHOLD)
        );
        resetOffset(INFINITE_SCROLL_THRESHOLD);
    }, [members.teamMembers]);

    useEffect(() => {
        if (
            !members.areTeamMembersEstablished &&
            !members.areTeamMembersFetching
        ) {
            dispatch(
                establishTeamMembersThunk(
                    getAccessTokenSilently,
                    logout,
                    teamId
                )
            );
        }
        // Global state Team Members cleanup
        return () => {
            dispatch(resetMembersThunk());
        };
    }, []);

    return (
        <TeamMembersView
            isFetching={members.areTeamMembersFetching}
            membersCount={members.teamMembers.length}
            teamMembers={displayedTeamMembers}
            loadMore={loadMoreTeamMembers}
            hasMore={displayedTeamMembers.length !== members.teamMembers.length}
            teamId={teamId}
            removeUserFromTeam={removeUserFromTeam}
            leaveTeam={leaveTeam}
        />
    );
};

export default TeamMembers;
