import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import useMPA from 'hooks/useMPA';
import { ROUTES } from 'routing/constants';
import { ReactComponent as Hamburger } from 'assets/svg/hamburger.svg';
import SidebarContent from 'containers/SidebarContent';
import AuthButton from 'components/AuthButton';

import {
    StyledSidebarHeader,
    StyledSidebarHolder,
    StyledSidebarLogo,
    StyledSidebarHamburger,
    StyledSidebarLogotype,
    StyledMobileSidebarHolder,
    StyledMobileSidebarMenuHolder,
} from './Sidebar.style';

const Sidebar = ({ isMobile, isMobileExpanded, toggleMobileSidebar }) => {
    const history = useHistory();
    const { displayAsMPA } = useMPA();

    const handleClickSidebarLogo = () => {
        if (isMobile) {
            toggleMobileSidebar();
        }
    };

    if (isMobile) {
        return (
          <StyledMobileSidebarHolder
                isMobileExpanded={isMobileExpanded}
                isMPA={displayAsMPA}
            >
            <StyledSidebarHeader>
              <StyledSidebarHamburger onClick={handleClickSidebarLogo}>
                <Hamburger onClick={handleClickSidebarLogo} />
              </StyledSidebarHamburger>
              {isMobileExpanded && (
                <>
                  <StyledSidebarLogotype isMPA={displayAsMPA}>
                    <StyledSidebarLogo
                                    onClick={() =>
                                        history.push(ROUTES.dashboard)}
                                />

                    <AuthButton style={{ marginLeft: '24px' }} />
                  </StyledSidebarLogotype>
                </>
                    )}
            </StyledSidebarHeader>

            <StyledMobileSidebarMenuHolder
                    isMobileExpanded={isMobileExpanded}
                >
              <SidebarContent
                        isMobile
                        toggleMobileSidebar={toggleMobileSidebar}
                    />
            </StyledMobileSidebarMenuHolder>
          </StyledMobileSidebarHolder>
        );
    }

    return (
      <StyledSidebarHolder isMPA={displayAsMPA}>
        <StyledSidebarHeader onClick={() => history.push(ROUTES.base)}>
          <StyledSidebarLogo />
          <StyledSidebarLogotype isMPA={displayAsMPA}>
            MedPower
          </StyledSidebarLogotype>
        </StyledSidebarHeader>
        <SidebarContent />
      </StyledSidebarHolder>
    );
};

Sidebar.propTypes = {
    isMobile: PropTypes.bool.isRequired,
    isMobileExpanded: PropTypes.bool,
    toggleMobileSidebar: PropTypes.func.isRequired,
};

Sidebar.defaultProps = {
    isMobileExpanded: false,
};

export default Sidebar;
