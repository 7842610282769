import React from 'react';
import PropTypes from 'prop-types';
import SectionHeader from 'components/SectionHeader';
import SplashScreen from 'views/SplashScreen';
import RemindersCalendar from 'components/RemindersCalendar';
import SingleReminder from 'components/SingleReminder';
import Button from 'components/Button';
import AddReminderModal from 'components/AddReminderModal';
import ProfileBar from 'components/ProfileBar';
import ReminderEmptyState from 'components/EmptyState/ReminderEmptyState';
import Spinner from 'components/Spinner';
import useDate from 'hooks/useDate';
import {
    StyledSettingsWrapper,
    StyledUpcomingRemindersHolder,
    StyledCalendarHolder,
    ClearRemindersHolder,
    Grid,
} from './UserSettings.style';

const UserSettings = ({
    selectedDate,
    userName,
    userEmail,
    userAvatarUrl,
    isUserFetching,
    displayedReminders,
    allReminders,
    areRemindersFetching,
    smartTips,
    isDaySelected,
    setIsDaySelected,
    isEditReminderModalOpened,
    setIsEditReminderModalOpened,
    isNewReminderModalOpened,
    setIsNewReminderModalOpened,
    closeModal,
    addReminder,
    editReminder,
    resetReminders,
    deleteReminder,
    setSelectedDate,
    userFirstName,
    userLastName,
    userTeams,
}) => {
    const parsedDate = useDate(selectedDate);

    if (isUserFetching) {
        return <SplashScreen />;
    }

    return (
      <>
        <SectionHeader title="User Profile" withMargin />
        <StyledSettingsWrapper>
          <ProfileBar
                    userName={userName}
                    userFirstName={userFirstName}
                    userLastName={userLastName}
                    userEmail={userEmail}
                    userTeams={userTeams}
                    userAvatarUrl={userAvatarUrl}
                />
        </StyledSettingsWrapper>
        <SectionHeader title="Upcoming Reminders" withMargin />
        {areRemindersFetching ? (
          <Spinner />
            ) : (
              <StyledSettingsWrapper>
                {isDaySelected && (
                <ClearRemindersHolder>
                  <span>{`Reminders for ${parsedDate}`}</span>
                  <Button primary xs onClick={() => resetReminders()}>
                    All reminders
                  </Button>
                </ClearRemindersHolder>
                    )}

                <Grid>
                  <StyledUpcomingRemindersHolder>
                    {displayedReminders.length === 0 && (
                    <ReminderEmptyState />
                            )}

                    {isDaySelected &&
                                displayedReminders.length > 0 &&
                                displayedReminders.map((reminder) => (
                                  <SingleReminder
                                        reminderId={reminder.id}
                                        key={reminder.id}
                                        {...reminder}
                                        smartTips={smartTips}
                                        editReminder={editReminder}
                                        isEditReminderModalOpened={
                                            isEditReminderModalOpened
                                        }
                                        deleteReminder={deleteReminder}
                                        openModal={() => {
                                            setIsEditReminderModalOpened(
                                                reminder.id
                                            );
                                        }}
                                        closeModal={() => {
                                            setIsEditReminderModalOpened(false);
                                        }}
                                    />
                                ))}

                    {!isDaySelected &&
                                displayedReminders.length > 0 &&
                                displayedReminders.map((reminder) => (
                                  <SingleReminder
                                        reminderId={reminder.id}
                                        key={reminder.id}
                                        {...reminder}
                                        smartTips={smartTips}
                                        editReminder={editReminder}
                                        isEditReminderModalOpened={
                                            isEditReminderModalOpened
                                        }
                                        deleteReminder={deleteReminder}
                                        openModal={() => {
                                            setIsEditReminderModalOpened(
                                                reminder.id
                                            );
                                        }}
                                        closeModal={() => {
                                            setIsEditReminderModalOpened(false);
                                        }}
                                    />
                                ))}
                  </StyledUpcomingRemindersHolder>
                  <StyledCalendarHolder>
                    <RemindersCalendar
                                selectedDate={selectedDate}
                                setSelectedDate={setSelectedDate}
                                remindersByDate={allReminders}
                                setIsDaySelected={setIsDaySelected}
                                setNewReminderModalOpened={() =>
                                    setIsNewReminderModalOpened(true)}
                            />
                  </StyledCalendarHolder>
                </Grid>
              </StyledSettingsWrapper>
            )}
        {isNewReminderModalOpened && (
        <AddReminderModal
                    smartTips={smartTips}
                    addReminder={addReminder}
                    closeModal={closeModal}
                />
            )}
      </>
    );
};

UserSettings.propTypes = {
    selectedDate: PropTypes.objectOf(Date),
    userName: PropTypes.string.isRequired,
    userFirstName: PropTypes.string.isRequired,
    userLastName: PropTypes.string.isRequired,
    userEmail: PropTypes.string.isRequired,
    userAvatarUrl: PropTypes.string.isRequired,
    isUserFetching: PropTypes.bool.isRequired,
    userTeams: PropTypes.arrayOf(PropTypes.any).isRequired,
    displayedReminders: PropTypes.arrayOf(PropTypes.any).isRequired,
    allReminders: PropTypes.arrayOf(PropTypes.any).isRequired,
    areRemindersFetching: PropTypes.bool.isRequired,
    smartTips: PropTypes.arrayOf(PropTypes.any).isRequired,
    isDaySelected: PropTypes.bool.isRequired,
    setIsDaySelected: PropTypes.func.isRequired,
    isEditReminderModalOpened: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string,
    ]).isRequired,
    setIsEditReminderModalOpened: PropTypes.func.isRequired,
    isNewReminderModalOpened: PropTypes.bool.isRequired,
    setIsNewReminderModalOpened: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    resetReminders: PropTypes.func.isRequired,
    addReminder: PropTypes.func.isRequired,
    editReminder: PropTypes.func.isRequired,
    deleteReminder: PropTypes.func.isRequired,
    setSelectedDate: PropTypes.func.isRequired,
};

UserSettings.defaultProps = {
    selectedDate: undefined,
};

export default UserSettings;
