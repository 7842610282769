export const ESTABLISH_METRIC_DETAILS = 'ESTABLISH_METRIC_DETAILS';
export const ESTABLISH_METRIC_DETAILS_SUCCESS =
    'ESTABLISH_METRIC_DETAILS_SUCCESS';
export const ESTABLISH_METRIC_DETAILS_FAILURE =
    'ESTABLISH_METRIC_DETAILS_FAILURE';

export const RESET_METRIC_DETAILS = 'RESET_METRIC_DETAILS';

export const establishMetricDetails = () => ({
    type: ESTABLISH_METRIC_DETAILS,
});

export const establishMetricDetailsSuccess = (payload) => ({
    type: ESTABLISH_METRIC_DETAILS_SUCCESS,
    payload,
});

export const establishMetricDetailsFailure = (err) => ({
    type: ESTABLISH_METRIC_DETAILS_FAILURE,
    err,
});

export const resetMetricDetails = () => ({
    type: RESET_METRIC_DETAILS,
});
