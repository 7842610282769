import React, { useState, useEffect } from 'react';
import apiRoutes from 'config/apiRoutes';
import useFetch from 'hooks/useFetch';
import HAEditUserView from 'views/HAEditUser';
import { useDispatch } from 'react-redux';
import {
    dispatchSuccessAlertThunk,
    dispatchErrorAlertThunk,
} from 'redux/actions/thunks/systemAlerts';
import { useHistory, useParams } from 'react-router-dom';
import { HA_ROUTES } from 'routing/constants';
import Spinner from 'components/Spinner';
import { useAuth0 } from '@auth0/auth0-react';

const HAEditUser = () => {
    const { getAccessTokenSilently, logout } = useAuth0();
    const { userId } = useParams();
    const request = useFetch(getAccessTokenSilently, logout);
    const dispatch = useDispatch();
    const history = useHistory();

    const [loading, setLoading] = useState(true);
    const [firstName, setFirstName] = useState(undefined);
    const [lastName, setLastName] = useState(undefined);
    const [emailAddress, setEmailAddress] = useState(undefined);
    const [role, setRole] = useState(undefined);
    const [mnemonic, setMnemonic] = useState('');
    const [classificationTags, setClassificationTags] = useState({
        role_job: [],
        department: [],
    });
    const [classificationTagIds, setClassificationTagIds] = useState([]);
    const isFirstNameValid = () => {
        if (firstName !== undefined) {
            return /^.{3,20}$/.test(firstName);
        }

        return true;
    };

    const isLastNameValid = () => {
        if (lastName !== undefined) {
            return /^.{3,30}$/.test(lastName);
        }

        return true;
    };

    const isEmailAddressValid = () => {
        if (emailAddress !== undefined) {
            return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
                emailAddress
            );
        }

        return true;
    };

    const isMnemonicValid = () => {
        if (mnemonic !== '') {
            return /^.{3,35}$/.test(mnemonic);
        }

        return true;
    };

    const isButtonDisabled =
        firstName === undefined ||
        !isFirstNameValid() ||
        lastName === undefined ||
        !isLastNameValid() ||
        emailAddress === undefined ||
        !isEmailAddressValid() ||
        !isMnemonicValid() ||
        role === undefined;

    const saveTags = () => {
        request(
            apiRoutes.post.assignClassificationTagsToUser(userId),
            'POST',
            (res) => {
                setClassificationTags(res.classification_tags);
            },
            (err) => {
                dispatch(
                    dispatchErrorAlertThunk(
                        'saveClassificationTreeError',
                        err.error
                    )
                );
            },
            {
                body: JSON.stringify({
                    classification_tag_ids: classificationTagIds,
                }),
            },
            { 'Content-Type': 'application/json' }
        );
    };

    const editUser = () => {
        request(
            apiRoutes.put.updateUser(userId),
            'PUT',
            (res) => handleSuccess(res),
            (err) => handleFailure(err),
            {
                body: JSON.stringify({
                    user: {
                        email: emailAddress,
                        first_name: firstName,
                        last_name: lastName,
                        role,
                        default_performance_id: mnemonic,
                    },
                }),
            },
            { 'Content-Type': 'application/json' }
        );
        saveTags();
    };

    const handleSuccess = (res) => {
        if (!res.error) {
            dispatch(
                dispatchSuccessAlertThunk(
                    'editUser',
                    'User successfully edited'
                )
            );
            history.push(HA_ROUTES.users);
        }
    };

    const handleFailure = (err) => {
        setLoading(false);
        dispatch(dispatchErrorAlertThunk(err, err.error));
    };

    const handleGetSuccess = (res) => {
        setFirstName(res.first_name);
        setLastName(res.last_name);
        setEmailAddress(res.email);
        setClassificationTags(res.classification_tags);
        setRole(res.admin ? 'hospital_admin' : 'provider');
        setMnemonic(res.default_performance_id);
        setLoading(false);
    };

    useEffect(() => {
        if (userId) {
            setLoading(true);
            request(
                apiRoutes.get.organizationUserDetails(userId),
                'GET',
                (res) => handleGetSuccess(res),
                (err) => handleFailure(err),
                {},
                { 'Content-Type': 'application/json' }
            );
        }
    }, [userId]);

    if (loading) {
        return <Spinner />;
    }

    return (
        <HAEditUserView
            firstName={firstName}
            setFirstName={setFirstName}
            isFirstNameValid={isFirstNameValid}
            lastName={lastName}
            setLastName={setLastName}
            isLastNameValid={isLastNameValid}
            emailAddress={emailAddress}
            setEmailAddress={setEmailAddress}
            isEmailAddressValid={isEmailAddressValid}
            role={role}
            mnemonic={mnemonic}
            setMnemonic={setMnemonic}
            isMnemonicValid={isMnemonicValid}
            editUser={editUser}
            isButtonDisabled={isButtonDisabled}
            userId={userId}
            setClassificationTags={setClassificationTags}
            classificationTags={classificationTags}
            setClassificationTagIds={setClassificationTagIds}
        />
    );
};

export default HAEditUser;
