import styled from 'styled-components/macro';

export const EmptyStateWrapper = styled.div`
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: ${({ theme }) => theme.border.base};
    border-radius: ${({ theme }) => theme.border.radiusBase};
    background-color: ${({ theme }) => theme.color.bgGrey};
    margin-bottom: 16px;
`;

export const Title = styled.div`
    padding-top: 8px;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    color: ${({ theme }) => theme.color.textBase};
`;

export const Text = styled.div`
    padding-top: 8px;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    color: ${({ theme }) => theme.color.textLight};
`;
