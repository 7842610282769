import {
    ESTABLISH_METRIC_SOURCES,
    ESTABLISH_METRIC_SOURCES_SUCCESS,
    ESTABLISH_METRIC_SOURCES_FAILURE,
    UPDATE_METRIC_SOURCE,
    UPDATE_METRIC_SOURCE_SUCCESS,
    UPDATE_METRIC_SOURCE_FAILURE,
} from 'redux/actions/metricSources';

export const initialState = {
    areMetricSourcesEstablished: false,
    isMetricSourcesError: false,
    areMetricSourcesFetching: false,
    error: null,
    metricSources: [],
};

const metricSources = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_METRIC_SOURCE:
        case ESTABLISH_METRIC_SOURCES:
            return {
                ...state,
                areMetricSourcesFetching: true,
                isMetricSourcesError: false,
            };

        case ESTABLISH_METRIC_SOURCES_SUCCESS:
            return {
                ...state,
                areMetricSourcesFetching: false,
                areMetricSourcesEstablished: true,
                metricSources: action.payload,
            };

        case UPDATE_METRIC_SOURCE_FAILURE:
        case ESTABLISH_METRIC_SOURCES_FAILURE:
            return {
                ...state,
                areMetricSourcesFetching: false,
                isMetricSourcesError: true,
                error: action.err,
            };

        case UPDATE_METRIC_SOURCE_SUCCESS:
            return {
                ...state,
                areMetricSourcesFetching: false,
                areMetricSourcesEstablished: true,
                metricSources: [
                    ...state.metricSources.filter(
                        (metricSource) => metricSource.id !== action.payload.id
                    ),
                    action.payload,
                ],
            };

        default:
            return state;
    }
};

export default metricSources;
