import styled from 'styled-components/macro';

export const DropdownButtonHolder = styled.div`
    position: relative;
`;
export const DropdownArea = styled.div`
    position: absolute;
    min-width: 200px;
    top: 100%;
    right: 0;
    padding: 16px;
    background-color: ${({ theme }) => theme.color.white};
    border-radius: ${({ theme }) => theme.border.radiusBase};
    box-shadow: ${({ theme }) => theme.boxShadow.base};
    z-index: 100;
`;
