import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as RecentUsersIcon } from 'assets/svg/no-results/recent-users.svg';

import { EmptyStateWrapper, Title, Text } from './UsersEmptyState.style';

const UsersEmptyState = ({ description }) => (
  <EmptyStateWrapper>
    <RecentUsersIcon />
    <Title>Nothing in here</Title>
    <Text>{description}</Text>
  </EmptyStateWrapper>
);

UsersEmptyState.propTypes = {
    description: PropTypes.string,
};

UsersEmptyState.defaultProps = {
    description: 'You have not visited any users yet.',
};

export default UsersEmptyState;
