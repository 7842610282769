import {
    establishRecentlyShared,
    establishRecentlySharedSuccess,
    establishRecentlySharedFailure,
    establishStats,
    establishStatsSuccess,
    establishStatsFailure,
    establishOverview,
    establishOverviewFailure,
    fetchPreviousMessages,
    fetchPreviousMessagesSuccess,
    fetchPreviousMessagesFailure,
    fetchMessages,
    fetchMessagesSuccess,
    fetchMessagesFailure,
    deleteMessage,
    deleteMessageSuccess,
    deleteMessageFailure,
    updateSingleMessage,
    likeMessage,
    unlikeMessage,
    establishTeamDetails,
    establishTeamDetailsSuccess,
    establishTeamDetailsFailure,
} from 'redux/actions/teamOverview';
import apiRoutes from 'config/apiRoutes';

export const establishTeamDetailsThunk = (
    getAccessTokenSilently,
    logout,
    teamId
) => (dispatch) => {
    dispatch(establishTeamDetails());

    return getAccessTokenSilently()
        .then((token) => {
            fetch(apiRoutes.get.teamDetails(teamId), {
                method: 'GET',
                headers: {
                    organization_slug: window.localStorage.getItem(
                        'MP_ORGANIZATION'
                    ),
                    Authorization: `Token token="${token}"`,
                },
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.error) {
                        dispatch(establishOverviewFailure(res.error));
                        return dispatch(establishTeamDetailsFailure(res.error));
                    }
                    return dispatch(establishTeamDetailsSuccess(res));
                })
                .catch((err) => {
                    dispatch(establishOverviewFailure(err));
                    dispatch(
                        establishTeamDetailsFailure({
                            err,
                        })
                    );
                });
        })
        .catch(() => logout({ returnTo: window.location.origin }));
};

export const establishTeamStatsThunk = (
    getAccessTokenSilently,
    logout,
    teamId
) => (dispatch) => {
    dispatch(establishStats());

    return getAccessTokenSilently()
        .then((token) => {
            fetch(apiRoutes.get.teamStats(teamId), {
                method: 'GET',
                headers: {
                    organization_slug: window.localStorage.getItem(
                        'MP_ORGANIZATION'
                    ),
                    Authorization: `Token token="${token}"`,
                },
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.error) {
                        dispatch(establishOverviewFailure(res.error));
                        return dispatch(establishStatsFailure(res.error));
                    }
                    return dispatch(establishStatsSuccess(res));
                })
                .catch((err) => {
                    dispatch(establishOverviewFailure(err));
                    dispatch(
                        establishStatsFailure({
                            err,
                        })
                    );
                });
        })
        .catch(() => logout({ returnTo: window.location.origin }));
};

export const establishTeamRecentlySharedThunk = (
    getAccessTokenSilently,
    logout,
    teamId
) => (dispatch) => {
    dispatch(establishRecentlyShared());

    return getAccessTokenSilently()
        .then((token) => {
            fetch(apiRoutes.get.teamRecentlyShared(teamId), {
                method: 'GET',
                headers: {
                    organization_slug: window.localStorage.getItem(
                        'MP_ORGANIZATION'
                    ),
                    Authorization: `Token token="${token}"`,
                },
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.error) {
                        dispatch(establishOverviewFailure(res.error));
                        return dispatch(
                            establishRecentlySharedFailure(res.error)
                        );
                    }
                    return dispatch(establishRecentlySharedSuccess(res.shared));
                })
                .catch((err) => {
                    dispatch(establishOverviewFailure(err));
                    dispatch(
                        establishRecentlySharedFailure({
                            err,
                        })
                    );
                });
        })
        .catch(() => logout({ returnTo: window.location.origin }));
};

export const fetchTeamMessagesThunk = (
    getAccessTokenSilently,
    logout,
    teamId,
    isAdmin = false
) => (dispatch) => {
    dispatch(fetchMessages());

    const route = isAdmin
        ? apiRoutes.get.organizationTeamMessages(teamId, 8, 0)
        : apiRoutes.get.teamMessages(teamId, 8);

    return getAccessTokenSilently()
        .then((token) => {
            fetch(route, {
                method: 'GET',
                headers: {
                    organization_slug: window.localStorage.getItem(
                        'MP_ORGANIZATION'
                    ),
                    Authorization: `Token token="${token}"`,
                },
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.error) {
                        dispatch(establishOverviewFailure(res.error));
                        return dispatch(fetchMessagesFailure(res.error));
                    }
                    return dispatch(fetchMessagesSuccess(res.messages));
                })
                .catch((err) => {
                    dispatch(establishOverviewFailure(err));
                    dispatch(
                        fetchMessagesFailure({
                            err,
                        })
                    );
                });
        })
        .catch(() => logout({ returnTo: window.location.origin }));
};

export const fetchTeamMessagesWithParamsThunk = (
    getAccessTokenSilently,
    logout,
    teamId,
    limit = 8,
    offset = 0,
    isAdmin = false
) => (dispatch) => {
    dispatch(fetchPreviousMessages());

    const route = isAdmin
        ? apiRoutes.get.organizationTeamMessages(teamId, limit, offset)
        : apiRoutes.get.teamMessages(teamId, limit, offset);

    return getAccessTokenSilently()
        .then((token) => {
            fetch(route, {
                method: 'GET',
                headers: {
                    organization_slug: window.localStorage.getItem(
                        'MP_ORGANIZATION'
                    ),
                    Authorization: `Token token="${token}"`,
                },
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.error) {
                        return dispatch(
                            fetchPreviousMessagesFailure(res.error)
                        );
                    }
                    return dispatch(fetchPreviousMessagesSuccess(res.messages));
                })
                .catch((err) => {
                    dispatch(
                        fetchPreviousMessagesFailure({
                            err,
                        })
                    );
                });
        })
        .catch(() => logout({ returnTo: window.location.origin }));
};
export const messageFeedbackThunk = (
    getAccessTokenSilently,
    logout,
    teamId,
    id,
    like
) => (dispatch) => {
    if (like) {
        dispatch(likeMessage(id));
    } else {
        dispatch(unlikeMessage(id));
    }

    const body = JSON.stringify({
        feedback: {
            like,
        },
    });

    return getAccessTokenSilently()
        .then((token) => {
            fetch(apiRoutes.put.messageFeedback(teamId, id), {
                method: 'PUT',
                headers: {
                    organization_slug: window.localStorage.getItem(
                        'MP_ORGANIZATION'
                    ),
                    Authorization: `Token token="${token}"`,
                    'Content-Type': 'application/json',
                },
                body,
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.error) {
                        return dispatch(fetchMessagesFailure(res.error));
                    }

                    return dispatch(updateSingleMessage(res));
                })
                .catch((err) => {
                    dispatch(fetchMessagesFailure(err));
                });
        })
        .catch(() => logout({ returnTo: window.location.origin }));
};

export const sendMessageThunk = (
    getAccessTokenSilently,
    logout,
    teamId,
    message
) => (dispatch) => {
    const body = JSON.stringify({
        message: {
            content: message,
        },
    });

    return getAccessTokenSilently()
        .then((token) => {
            fetch(apiRoutes.post.createMessage(teamId), {
                method: 'POST',
                headers: {
                    organization_slug: window.localStorage.getItem(
                        'MP_ORGANIZATION'
                    ),
                    Authorization: `Token token="${token}"`,
                    'Content-Type': 'application/json',
                },
                body,
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.error) {
                        return dispatch(fetchMessagesFailure(res.error));
                    }

                    return dispatch(
                        fetchTeamMessagesThunk(
                            getAccessTokenSilently,
                            logout,
                            teamId
                        )
                    );
                })
                .catch((err) => {
                    dispatch(fetchMessagesFailure(err));
                });
        })
        .catch(() => logout({ returnTo: window.location.origin }));
};

export const deleteMessageThunk = (
    getAccessTokenSilently,
    logout,
    teamId,
    messageId,
    isAdmin = false
) => (dispatch) => {
    dispatch(deleteMessage());

    const route = isAdmin
        ? apiRoutes.delete.deleteOrganizationMessage(teamId, messageId)
        : apiRoutes.delete.deleteMessage(teamId, messageId);

    return getAccessTokenSilently()
        .then((token) => {
            fetch(route, {
                method: 'DELETE',
                headers: {
                    organization_slug: window.localStorage.getItem(
                        'MP_ORGANIZATION'
                    ),
                    Authorization: `Token token="${token}"`,
                    'Content-Type': 'application/json',
                },
            })
                .then((res) => res.json())
                .then((res) => {
                    if (!res.error) {
                        return dispatch(deleteMessageSuccess(messageId));
                    }

                    return dispatch(deleteMessageFailure(res?.error));
                })
                .catch((err) => dispatch(deleteMessageFailure(err)));
        })
        .catch(() => logout({ returnTo: window.location.origin }));
};

export const establishOverviewThunk = (
    getAccessTokenSilently,
    logout,
    teamId,
    isAdmin
) => (dispatch) => {
    dispatch(establishOverview());
    dispatch(establishTeamDetailsThunk(getAccessTokenSilently, logout, teamId));
    dispatch(establishTeamStatsThunk(getAccessTokenSilently, logout, teamId));
    dispatch(
        establishTeamRecentlySharedThunk(getAccessTokenSilently, logout, teamId)
    );
    dispatch(
        fetchTeamMessagesThunk(getAccessTokenSilently, logout, teamId, isAdmin)
    );
};
