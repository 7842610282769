import styled, { css } from 'styled-components/macro';

const BACK_BTN_SIZE = '40px';
const BACK_BTN_RAD = '16px';
const BACK_BTN_PAD = '15px, 24px, 15px, 24px';

export const BackToMPAContainer = styled.div`
    display: flex;
    align-items: center;
    margin: 5px 15px;
`;
export const BackToMPArrowWrapper = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${BACK_BTN_SIZE};
    height: ${BACK_BTN_SIZE};
    padding: ${BACK_BTN_PAD};
    border-radius: ${BACK_BTN_RAD};
    background-color: ${({ theme }) => theme.color.bgPrimaryLight};
    color: ${({ theme }) => theme.color.primary};
    cursor: pointer;
    margin-left: 7px;
    margin-right: 22px;

    svg {
        stroke: ${({ theme }) => theme.color.primary};
    }
`;

export const CurrentOrgIndicatorTxt = styled.span`
    color: ${({ theme }) => theme.color.mpaItemText};
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
`;

export const StyledSidebarMenuHolder = styled.ul`
    position: relative;
    width: 100%;
    padding: 0;
    margin: 10px 0;
    list-style: none;
    display: flex;
    flex-direction: column;
`;

export const StyledSidebarMenuItem = styled.li`
    margin: 5px 15px;
    padding: 15px;
    border-radius: ${({ theme }) => theme.border.radiusBase};
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;

    background-color: transparent;

    transition-duration: 200ms;

    span {
        color: ${({ isMPA, theme }) =>
            isMPA
                ? theme.color.mpaItemText
                : theme.color.textNavigationCounter};
    }

    svg {
        fill: ${({ isMPA, theme }) =>
            isMPA
                ? theme.color.mpaItemText
                : theme.color.textNavigationCounter};
    }

    ${({ current }) =>
        !current &&
        css`
            &:hover {
                svg {
                    fill: ${({ isMPA, theme }) =>
                        isMPA ? theme.color.white : theme.color.textBase};
                }

                span {
                    color: ${({ isMPA, theme }) =>
                        isMPA ? theme.color.white : theme.color.textBase};
                }
            }
        `}

    ${({ current }) =>
        current &&
        css`
            background-color: ${({ isMPA, theme }) =>
                isMPA ? theme.color.mpaActiveItem : theme.color.bgPrimaryLight};

            svg {
                fill: ${({ isMPA, theme }) =>
                    isMPA ? theme.color.bgPrimaryLight : theme.color.primary};
            }

            span {
                color: ${({ isMPA, theme }) =>
                    isMPA ? theme.color.bgPrimaryLight : theme.color.primary};
            }
        `}
`;

export const StyledDownloadLabel = styled.strong`
    position: relative;
    opacity: 1;
    transition: all 0.2s ease;
    white-space: nowrap;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: ${({ theme }) => theme.color.textLight};
    margin-bottom: 10px;
`;

export const StyledMenuitemTitle = styled.span`
    display: block;
    margin-left: 46px;
    opacity: 1;
    transition: all 0.2s ease;
    position: absolute;
`;

export const StyledSidebarDownloadApp = styled.div`
    margin: auto 30px 30px;
    width: 258px;
    border-top: 1px solid;
    border-color: ${({ theme }) => theme.color.bgSearch};
    padding: 25px 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
`;

export const IconsWrapper = styled.div`
    padding: 12px 0;
    display: flex;
    flex-direction: row;
`;

export const IconWrapper = styled.a`
    width: 64px;
    height: 64px;
    border: ${({ theme }) => theme.border.base};
    border-radius: ${({ theme }) => theme.border.radiusBase};
    background-color: ${({ theme }) => theme.color.white};
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
        background-color: ${({ theme }) => theme.color.lightHoverBg};
    }
`;
