import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import DropdownSelect from 'components/DropdownSelect/DropdownSelect';
import {
    establishSuperAdminUsersThunk,
    loadMoreSuperAdminUsersThunk,
    resetSuperAdminUsersThunk,
} from 'redux/actions/thunks/mpaSuperAdminUsers';
import { useAuth0 } from '@auth0/auth0-react';
import useOffset from 'hooks/useOffset';

const INFINITE_SCROLL_THRESHOLD = 20;

const SelectUsersDropdown = ({ onUpdate }) => {
    const dispatch = useDispatch();
    const { getAccessTokenSilently, logout } = useAuth0();
    const [searchFilter, setSearchFilter] = useState('');
    const [offset, incrementOffset, resetOffset] = useOffset(
        INFINITE_SCROLL_THRESHOLD
    );

    const { superAdminUsers, hasMoreSuperAdminUsers } = useSelector(
        (state) => state.mpaSuperAdminUsers
    );

    useEffect(() => {
        resetOffset();
        dispatch(
            establishSuperAdminUsersThunk(
                getAccessTokenSilently,
                logout,
                INFINITE_SCROLL_THRESHOLD,
                0,
                {
                    search: searchFilter,
                    role: 'hospital_admin',
                }
            )
        );
        incrementOffset();
    }, [searchFilter]);

    return (
      <DropdownSelect
            type="user"
            items={superAdminUsers}
            loadMore={() => {
                dispatch(
                    loadMoreSuperAdminUsersThunk(
                        getAccessTokenSilently,
                        logout,
                        INFINITE_SCROLL_THRESHOLD,
                        offset,
                        {
                            search: searchFilter,
                            role: 'hospital_admin',
                        }
                    )
                );
                incrementOffset();
            }}
            hasMore={hasMoreSuperAdminUsers}
            onSearch={setSearchFilter}
            resetItemsList={() => {
                resetOffset();
                dispatch(resetSuperAdminUsersThunk());
            }}
            searchPlaceholder="Assign to admins"
            handleSetSelectedItems={onUpdate}
        />
    );
};

SelectUsersDropdown.propTypes = {
    onUpdate: PropTypes.func.isRequired,
};

export default SelectUsersDropdown;
