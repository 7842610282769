import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import useOffset from 'hooks/useOffset';
import establishOrganizationTeamsThunk, {
    loadMoreOrganizationTeamsThunk,
    resetOrganizationTeamsThunk,
} from 'redux/actions/thunks/organizationTeams';
import DropdownSelect from 'components/DropdownSelect/DropdownSelect';
import { useAuth0 } from '@auth0/auth0-react';

const INFINITE_SCROLL_THRESHOLD = 20;

const SelectTeamsDropdown = ({ onUpdate }) => {
    const { getAccessTokenSilently, logout } = useAuth0();
    const dispatch = useDispatch();
    const [searchFilter, setSearchFilter] = useState('');
    const [offset, incrementOffset, resetOffset] = useOffset(
        INFINITE_SCROLL_THRESHOLD
    );

    const { organizationTeams, hasMoreOrganizationTeams } = useSelector(
        (state) => state.organizationTeams
    );

    useEffect(() => {
        resetOffset();
        dispatch(
            establishOrganizationTeamsThunk(
                getAccessTokenSilently,
                logout,
                INFINITE_SCROLL_THRESHOLD,
                0,
                {
                    search: searchFilter || '',
                }
            )
        );
        incrementOffset();
    }, [searchFilter]);

    return (
      <DropdownSelect
            type="team"
            items={organizationTeams}
            loadMore={() => {
                dispatch(
                    loadMoreOrganizationTeamsThunk(
                        getAccessTokenSilently,
                        logout,
                        INFINITE_SCROLL_THRESHOLD,
                        offset,
                        {
                            search: searchFilter || '',
                        }
                    )
                );
                incrementOffset();
            }}
            hasMore={hasMoreOrganizationTeams}
            onSearch={setSearchFilter}
            resetItemsList={() => {
                resetOffset();
                dispatch(resetOrganizationTeamsThunk());
            }}
            handleSetSelectedItems={onUpdate}
            placeholder="Assign to teams"
        />
    );
};

SelectTeamsDropdown.propTypes = {
    onUpdate: PropTypes.func.isRequired,
};

export default SelectTeamsDropdown;
