export const ESTABLISH_NOTIFICATIONS = 'ESTABLISH_NOTIFICATIONS';
export const ESTABLISH_NOTIFICATIONS_SUCCESS =
    'ESTABLISH_NOTIFICATIONS_SUCCESS';
export const ESTABLISH_NOTIFICATIONS_FAILURE =
    'ESTABLISH_NOTIFICATIONS_FAILURE';
export const LOAD_MORE_NOTIFICATIONS = 'LOAD_MORE_NOTIFICATIONS';
export const ALL_NOTIFICATIONS_LOADED = 'ALL_NOTIFICATIONS_LOADED';
export const RESET_NOTIFICATIONS = 'RESET_NOTIFICATIONS';
export const MARK_NOTIFICATION_AS_READ = 'MARK_NOTIFICATION_AS_READ';
export const SET_UNSEEN_NOTIFICATIONS_COUNT = 'SET_UNSEEN_COUNT';
export const DECREASE_UNSEEN_NOTIFICATIONS_COUNT =
    'DECREASE_UNSEEN_NOTIFICATIONS_COUNT';

export const establishNotifications = () => ({
    type: ESTABLISH_NOTIFICATIONS,
});

export const establishNotificationsSuccess = (payload) => ({
    type: ESTABLISH_NOTIFICATIONS_SUCCESS,
    payload,
});

export const establishNotificationsFailure = (err) => ({
    type: ESTABLISH_NOTIFICATIONS_FAILURE,
    err,
});

export const loadMoreNotifications = (payload) => ({
    type: LOAD_MORE_NOTIFICATIONS,
    payload,
});

export const allNotificationsLoaded = () => ({
    type: ALL_NOTIFICATIONS_LOADED,
});

export const resetNotifications = () => ({
    type: RESET_NOTIFICATIONS,
});

export const markNotificationAsRead = (payload) => ({
    type: MARK_NOTIFICATION_AS_READ,
    payload,
});

export const setUnseenNotificationsCount = (payload) => ({
    type: SET_UNSEEN_NOTIFICATIONS_COUNT,
    payload,
});

export const decreaseUnseenNotificationsCount = () => ({
    type: DECREASE_UNSEEN_NOTIFICATIONS_COUNT,
});
