import {
    ESTABLISH_USER_DETAILS,
    ESTABLISH_USER_DETAILS_SUCCESS,
    ESTABLISH_USER_DETAILS_FAILURE,
} from 'redux/actions/organizationUserDetails';

export const initialState = {
    areUserDetailsEstablished: false,
    isUserDetailsError: false,
    areUserDetailsFetching: true,
    organizationUserDetails: {},
    error: null,
};

const organizationUserDetails = (state = initialState, action) => {
    switch (action.type) {
        case ESTABLISH_USER_DETAILS:
            return {
                ...state,
                areUserDetailsFetching: true,
                isUserDetailsError: false,
            };

        case ESTABLISH_USER_DETAILS_SUCCESS:
            return {
                ...state,
                areUserDetailsFetching: false,
                areUserDetailsEstablished: true,
                organizationUserDetails: action.payload,
            };

        case ESTABLISH_USER_DETAILS_FAILURE:
            return {
                ...state,
                areUserDetailsFetching: false,
                isUserDetailsError: true,
                error: action.err,
            };

        default:
            return state;
    }
};

export default organizationUserDetails;
