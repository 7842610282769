import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Auth0ProviderWithHistory from 'auth/auth0ProviderWithHistory';
import GlobalStyle from 'assets/theme/GlobalStyle';
import theme from 'assets/theme/mainTheme';
import store from 'redux/store/store';
import Dashboard from 'containers/Dashboard';
import AuthView from 'views/AuthView';
import { ThemeProvider } from 'styled-components/macro';

const Root = () => (
    <Router>
        <Switch>
            <Route
                path="/login"
                component={() => (
                    <Provider store={store}>
                        <GlobalStyle />
                        <ThemeProvider theme={theme}>
                            <AuthView />
                        </ThemeProvider>
                    </Provider>
                )}
            />
            <Route
                path="*"
                component={() => (
                    <Auth0ProviderWithHistory>
                        <Provider store={store}>
                            <GlobalStyle />
                            <ThemeProvider theme={theme}>
                                <Dashboard />
                            </ThemeProvider>
                        </Provider>
                    </Auth0ProviderWithHistory>
                )}
            />
        </Switch>
    </Router>
);

export default Root;
