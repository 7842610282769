import styled, { css } from 'styled-components/macro';

export const Title = styled.div`
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    color: ${({ theme }) => theme.color.textLight};
`;

export const StyledFiltersContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 12px;
    padding-bottom: 24px;

    @media (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;

        input {
            margin-bottom: 8px;
        }
    }
`;

export const Clear = styled.div`
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: ${({ theme }) => theme.color.textLight};
`;

export const StyledFilterSet = styled.div`
    width: 180px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 12px;

    small {
        color: ${({ theme }) => theme.color.textLight};
        font-size: ${({ theme }) => theme.fontSize.medium};
    }
`;

export const Role = styled.div`
    display: flex;
    align-items: center;
    width: 172px;
    height: 54px;
    padding: 16px;
    border: ${({ theme }) => theme.border.base};
    border-radius: ${({ theme }) => theme.border.radiusSmall};
    background-color: ${({ theme }) => theme.color.white};
    cursor: pointer;

    svg {
        margin-left: auto;
    }
`;

export const RoleDropdownWrapper = styled.div`
    position: relative;
`;

export const Dropdown = styled.div`
    z-index: 1;
    display: flex;
    flex-direction: column;
    width: 172px;
    position: absolute;
    border-radius: ${({ theme }) => theme.border.radiusBase};
    background-color: ${({ theme }) => theme.color.white};
    box-shadow: ${({ theme }) => theme.boxShadow.base};
    top: 54px;
    left: 0px;
`;

export const DropdownItem = styled.div`
    margin: 0 16px 16px 16px;
    padding: 16px;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    color: ${({ theme }) => theme.color.primary};
    border-radius: ${({ theme }) => theme.border.radiusSmall};
    z-index: 1;

    &:first-child {
        margin: 16px;
    }

    ${({ isSelected }) =>
        isSelected &&
        css`
            color: ${({ theme }) => theme.color.white};
            background-color: ${({ theme }) => theme.color.bgPrimary};
        `}

    &:hover {
        cursor: pointer;
        color: ${({ theme }) => theme.color.primary};
        background-color: ${({ theme }) => theme.color.bgPrimaryLight};
    }
`;
