import {
    ESTABLISH_ORGANIZATION_ASSIGN_SMART_TIP_ASSIGNED_USERS,
    LOAD_MORE_ESTABLISH_ORGANIZATION_ASSIGN_SMART_TIP_ASSIGNED_USERS_SUCCESS,
    ESTABLISH_ORGANIZATION_ASSIGN_SMART_TIP_ASSIGNED_USERS_SUCCESS,
    ESTABLISH_ORGANIZATION_ASSIGN_SMART_TIP_ASSIGNED_USERS_FAILURE,
    ESTABLISH_ORGANIZATION_ASSIGN_SMART_TIP_ASSIGNED_TEAMS,
    ESTABLISH_ORGANIZATION_ASSIGN_SMART_TIP_ASSIGNED_TEAMS_SUCCESS,
    ESTABLISH_ORGANIZATION_ASSIGN_SMART_TIP_ASSIGNED_TEAMS_FAILURE,
    RESET_USERS_AND_TEAMS_LISTS,
    MARK_ALL_USERS_LOADED,
    LOADING_ORGANIZATION_USERS,
} from 'redux/actions/organizationAssignSmartTip';

export const initialState = {
    assignedUsers: [],
    areOrganizationAssignedUsersEstablished: false,
    areOrganizationAssignedUsersFetching: true,
    assignedUsersError: null,
    assignedTeams: [],
    areOrganizationAssignedTeamsEstablished: false,
    areOrganizationAssignedTeamsFetching: true,
    assignedTeamsError: null,
    allUsersLoaded: true,
    isLoadingUser: true,
};

const organizationAssignSmartTip = (state = initialState, action) => {
    switch (action.type) {
        case ESTABLISH_ORGANIZATION_ASSIGN_SMART_TIP_ASSIGNED_USERS:
            return {
                ...state,
                areOrganizationAssignedUsersFetching: false,
                assignedUsersError: null,
            };

        case ESTABLISH_ORGANIZATION_ASSIGN_SMART_TIP_ASSIGNED_TEAMS:
            return {
                ...state,
                areOrganizationAssignedTeamsFetching: true,
                assignedTeamsError: null,
            };
        case LOAD_MORE_ESTABLISH_ORGANIZATION_ASSIGN_SMART_TIP_ASSIGNED_USERS_SUCCESS:
            return {
                ...state,
                areOrganizationAssignedUsersFetching: false,
                assignedUsers: [...state.assignedUsers, ...action.payload],
            };
        case ESTABLISH_ORGANIZATION_ASSIGN_SMART_TIP_ASSIGNED_USERS_SUCCESS:
            return {
                ...state,
                areOrganizationAssignedUsersFetching: false,
                assignedUsers: action.payload,
            };

        case ESTABLISH_ORGANIZATION_ASSIGN_SMART_TIP_ASSIGNED_TEAMS_SUCCESS:
            return {
                ...state,
                areOrganizationAssignedTeamsFetching: false,
                assignedTeams: action.payload,
            };

        case ESTABLISH_ORGANIZATION_ASSIGN_SMART_TIP_ASSIGNED_USERS_FAILURE:
            return {
                ...state,
                areOrganizationAssignedUsersFetching: false,
                assignedUsersError: action.err,
            };

        case ESTABLISH_ORGANIZATION_ASSIGN_SMART_TIP_ASSIGNED_TEAMS_FAILURE:
            return {
                ...state,
                areOrganizationAssignedTeamsFetching: false,
                assignedTeamsError: action.err,
            };
        case RESET_USERS_AND_TEAMS_LISTS:
            return { ...initialState };
        case LOADING_ORGANIZATION_USERS:
            return {
                ...state,
                isLoadingUser: action.payload,
            };
        case MARK_ALL_USERS_LOADED:
            return {
                ...state,
                allUsersLoaded: false,
            };

        default:
            return state;
    }
};

export default organizationAssignSmartTip;
