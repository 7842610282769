import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as HeartIcon } from 'assets/svg/heart.svg';

import Checkbox from 'components/Checkbox';
import {
    DropdownOption,
    DropdownOptionTexts,
    EmptyAvatar,
    DropdownAvatar,
} from './DropdownItem.style';

const DropdownItem = ({ type, data, isSelected, select, deselect }) => {
    switch (type) {
        case 'user':
            return (
              <DropdownOption>
                {data.avatar_url ? (
                  <DropdownAvatar>
                    <img src={data.avatar_url} alt={data.first_name} />
                  </DropdownAvatar>
                    ) : (
                      <EmptyAvatar
                            color={data.color}
                            backgroundColor={data.background_color}
                        >
                        {`${data.first_name && data.first_name[0]}${
                                data.last_name && data.last_name[0]
                            }`}
                      </EmptyAvatar>
                    )}

                <DropdownOptionTexts>
                  <span>
                    {data.first_name} 
                    {' '}
                    {data.last_name}
                  </span>
                  {data.email && <small>{data.email}</small>}
                </DropdownOptionTexts>

                <Checkbox
                        isSelected={isSelected}
                        toggleSelected={isSelected ? deselect : select}
                        customStyles={{
                            marginLeft: 'auto',
                            marginRight: '8px',
                        }}
                    />
              </DropdownOption>
            );

        case 'team':
            return (
              <DropdownOption>
                {data.picture_url ? (
                  <DropdownAvatar>
                    <img src={data.picture_url} alt={data.name} />
                  </DropdownAvatar>
                    ) : (
                      <EmptyAvatar backgroundColor={data.background_color}>
                        <HeartIcon fill={data.color} />
                      </EmptyAvatar>
                    )}

                <DropdownOptionTexts>
                  <span>{data.name}</span>
                  {data.recent_users && (
                    <small>
                      {data.recent_users.length === 1
                                    ? `${data.recent_users.length} user`
                                    : `${data.recent_users.length} users`}
                    </small>
                        )}
                </DropdownOptionTexts>

                <Checkbox
                        isSelected={isSelected}
                        toggleSelected={isSelected ? deselect : select}
                        customStyles={{
                            marginLeft: 'auto',
                            marginRight: '8px',
                        }}
                    />
              </DropdownOption>
            );

        default:
            return <DropdownOption />;
    }
};

DropdownItem.propTypes = {
    type: PropTypes.string.isRequired,
    data: PropTypes.objectOf(PropTypes.any).isRequired,
    isSelected: PropTypes.bool.isRequired,
    select: PropTypes.func.isRequired,
    deselect: PropTypes.func.isRequired,
};

export default DropdownItem;
