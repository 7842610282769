import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import ImportModal from 'components/ImportModal/ImportModal';
import {
    resetUsersUploadsThunk,
    uploadUsersThunk,
} from 'redux/actions/thunks/usersUploads';
import { useAuth0 } from '@auth0/auth0-react';
import { UPLOAD_USER_STRATEGIES } from '../UsersHA/constants';

const STRATEGY_TO_MODAL_TITLE = {
    [UPLOAD_USER_STRATEGIES.UPDATE_EXISTING_ONES]: 'Bulk Update Existing Users',
    [UPLOAD_USER_STRATEGIES.IMPORT_NEW_ONES]: 'Import Users via CSV',
};

const AdminUsersUploadComponent = ({ setIsVisible, strategy }) => {
    const { getAccessTokenSilently, logout } = useAuth0();
    const dispatch = useDispatch();
    const [isUploading, setIsUploading] = useState(false);
    const [isReadyToClose, setIsReadyToClose] = useState(false);

    const { isUsersFileUploading, isUsersFileUploaded } = useSelector(
        (state) => state.usersUploads
    );

    const handleUsersUpload = (file) => {
        if (file) {
            dispatch(
                uploadUsersThunk(getAccessTokenSilently, logout, file, strategy)
            );
        }
    };

    useEffect(() => {
        setIsUploading(isUsersFileUploading);
        setIsReadyToClose(isUsersFileUploaded);
    }, [isUsersFileUploading, isUsersFileUploaded]);

    return (
      <ImportModal
            headerText={STRATEGY_TO_MODAL_TITLE[strategy]}
            handleUpload={handleUsersUpload}
            setIsVisible={setIsVisible}
            type="users"
            isUploading={isUploading}
            setIsUploading={setIsUploading}
            isReadyToClose={isReadyToClose}
            setIsReadyToClose={setIsReadyToClose}
            strategy={strategy}
            reset={() => dispatch(resetUsersUploadsThunk())}
        />
    );
};

AdminUsersUploadComponent.propTypes = {
    setIsVisible: PropTypes.func.isRequired,
    strategy: PropTypes.string.isRequired,
};

export default AdminUsersUploadComponent;
