import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import UploadDropzone from 'components/UploadDropzone';
import Button from 'components/Button';
import ProgressBar from 'components/ProgressBar';
import Modal from 'components/Modal';
import { ReactComponent as FileIcon } from 'assets/svg/file-icon.svg';
import theme from 'assets/theme/mainTheme';
import {
    UploadImageArea,
    ProgressBarHolder,
    DropzoneArea,
    FileToImportWrapper,
    Row,
    RowButton,
} from './ImportModal.style';

const ImportModal = ({
    headerText,
    handleUpload,
    setIsVisible,
    type,
    isUploading,
    setIsUploading,
    isReadyToClose,
    setIsReadyToClose,
    strategy,
    uploadedMetricReset,
    reset,
}) => {
    const [progressBar, setProgressBar] = useState(false);
    const [fileToImport, setFileToImport] = useState(undefined);

    const handleUploadFile = () => {
        handleUpload(fileToImport);
        setFileToImport(undefined);
    };

    const handleModalClose = () => {
        setIsUploading(false);
        setIsReadyToClose(false);
        setIsVisible(false);
        uploadedMetricReset();
        reset();
    };

    const handleFileToImport = (files) => {
        if (files.length > 0) {
            setFileToImport(files[0]);
        }
    };

    const renderDescription = () => {
        switch (type) {
            case 'users':
                switch (strategy) {
                    case 'update_existing_ones':
                        return (
                          <span>
                            Choose CSV file to upload.
                            <br />
                            Complete all template fields, except password.
                          </span>
                        );
                    case 'import_new_ones':
                        return (
                          <span>
                            Choose CSV file to upload.
                            <br />
                            Unique email address is required for each user.
                          </span>
                        );
                    default:
                        return <span>Choose CSV file to upload.</span>;
                }
            case 'metrics':
                return <span>Choose CSV file to upload</span>;
            default:
                return <span>Choose CSV file to upload</span>;
        }
    };

    useEffect(() => {
        if (isUploading) {
            setProgressBar(true);
        } else {
            setProgressBar(false);
        }
    }, [isUploading]);

    const renderContent = () => {
        if (progressBar)
            return (
              <ProgressBarHolder>
                <span>Uploading. Please wait.</span>
                <ProgressBar />
              </ProgressBarHolder>
            );

        if (isReadyToClose)
            return (
              <ProgressBarHolder>
                <span>
                  File is being processed. You can close this window.
                </span>
                <ProgressBar completed />
              </ProgressBarHolder>
            );

        if (fileToImport)
            return (
              <FileToImportWrapper>
                <strong>Selected file</strong>
                <Row>
                  <FileIcon fill={theme.color.primary} />
                  <span>{fileToImport.name}</span>
                  <RowButton onClick={() => setFileToImport(undefined)}>
                    Change file
                  </RowButton>
                </Row>
              </FileToImportWrapper>
            );

        return (
          <UploadImageArea data-testid="adminUsersUpload">
            <DropzoneArea>
              {renderDescription()}
              <Button primaryDark>Browse files</Button>
            </DropzoneArea>

            <UploadDropzone
                    handleUpload={handleFileToImport}
                    accept="text/csv"
                />
          </UploadImageArea>
        );
    };

    return (
      <Modal
            closeModal={handleModalClose}
            headerText={headerText}
            modalFooter={() => (
              <>
                <Button primary onClick={handleModalClose}>
                  Close
                </Button>
                <Button
                        primaryDark
                        onClick={handleUploadFile}
                        disabled={!fileToImport || isReadyToClose}
                        customStyles={{ marginLeft: '8px' }}
                    >
                  Proceed
                </Button>
              </>
            )}
            isCloseIcon
            closeByIconClick={handleModalClose}
        >
        {renderContent()}
      </Modal>
    );
};

ImportModal.propTypes = {
    headerText: PropTypes.string.isRequired,
    handleUpload: PropTypes.func.isRequired,
    setIsVisible: PropTypes.func.isRequired,
    uploadedMetricReset: PropTypes.func,
    type: PropTypes.string,
    isUploading: PropTypes.bool.isRequired,
    setIsUploading: PropTypes.func.isRequired,
    isReadyToClose: PropTypes.bool.isRequired,
    setIsReadyToClose: PropTypes.func.isRequired,
    strategy: PropTypes.string.isRequired,
    reset: PropTypes.func.isRequired,
};

ImportModal.defaultProps = {
    type: undefined,
    uploadedMetricReset: () => {},
};

export default ImportModal;
