import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import establishNotificationsThunk, {
    getUnseenNotificationsCountThunk,
    markAllNotificationsAsReadThunk,
    markNotificationAsReadThunk,
    resetNotificationsThunk,
} from 'redux/actions/thunks/notifications';
import NotificationsDropdownView from 'views/NotificationsDropdown';
import { MPA_ROUTES, ROUTES } from 'routing/constants';
import useMPA from 'hooks/useMPA';
import { useAuth0 } from '@auth0/auth0-react';

const INFINITE_SCROLL_THRESHOLD = 20;

const NotificationsDropdown = () => {
    const dispatch = useDispatch();
    const { getAccessTokenSilently, logout } = useAuth0();
    const history = useHistory();
    const [isVisible, setIsVisible] = useState(false);
    const { displayAsMPA } = useMPA();

    const { unseenCount, notifications } = useSelector(
        (state) => state.notifications
    );

    const toggleVisible = () => {
        setIsVisible(!isVisible);
    };

    const seeAllNotifications = () => {
        toggleVisible();
        if (displayAsMPA) {
            history.push(MPA_ROUTES.adminMemos);
        } else {
            history.push(ROUTES.notifications);
        }
    };

    const markAllNotificationsAsRead = () => {
        toggleVisible();
        dispatch(
            markAllNotificationsAsReadThunk(getAccessTokenSilently, logout)
        );
    };

    const markAsRead = (notificationId) => {
        dispatch(
            markNotificationAsReadThunk(
                getAccessTokenSilently,
                logout,
                notificationId
            )
        );
    };

    useEffect(() => {
        dispatch(
            establishNotificationsThunk(
                getAccessTokenSilently,
                logout,
                INFINITE_SCROLL_THRESHOLD,
                0
            )
        );
        return dispatch(resetNotificationsThunk());
    }, [isVisible]);

    useEffect(() => {
        dispatch(
            getUnseenNotificationsCountThunk(getAccessTokenSilently, logout)
        );
        const interval = setInterval(() => {
            dispatch(
                getUnseenNotificationsCountThunk(getAccessTokenSilently, logout)
            );
        }, 60 * 1000); // 60 * 1000 = 60 sec

        return () => clearInterval(interval);
    }, []);

    return (
      <NotificationsDropdownView
            unseenCount={unseenCount}
            notifications={notifications}
            isVisible={isVisible}
            toggleVisible={toggleVisible}
            markAsRead={markAsRead}
            seeAllNotifications={seeAllNotifications}
            markAllNotificationsAsRead={markAllNotificationsAsRead}
        />
    );
};

export default NotificationsDropdown;
