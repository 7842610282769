import DATE_TYPES from 'hooks/useDateTypes';
import useDate from 'hooks/useDate';

export const getHoursFromTime = (hour) => {
    if (hour.includes('am') || hour.includes('AM')) {
        if (hour.slice(0, hour.indexOf(':')) === '12') {
            return 0;
        }
        return hour.slice(0, hour.indexOf(':'));
    }

    if (hour.includes('pm') || hour.includes('PM')) {
        if (hour.slice(0, hour.indexOf(':')) === '12') {
            return parseInt(hour.slice(0, hour.indexOf(':')), 10);
        }

        return parseInt(hour.slice(0, hour.indexOf(':')), 10) + 12;
    }

    return '12';
};

export const notifyTimes = [
    {
        id: 1,
        label: 'On date',
    },
    {
        id: 15,
        label: '15 mins.',
    },
    {
        id: 30,
        label: '30 mins.',
    },
    {
        id: 60,
        label: '1 hour',
    },
    {
        id: 180,
        label: '3 hours',
    },
    {
        id: 1440,
        label: '1 day',
    },
];

export const validateTime = (timeString) => {
    const hourRegex = /^(10|11|12|[1-9])$/g; // One/two first digits (0-9 or 10-12)
    const hourWithColonRegex = /^(10|11|12|[1-9]):$/g; // One/two first digits (0-9 or 10-12) with colon
    const hourWithColonAndMinuteDigitRegex = /^(10|11|12|[1-9]):[0-5]$/g; // One/two first digits (0-9 or 10-12) with colon and first digit of minutes (0-5)
    const fullTimeWithoutLetterRegex = /^(10|11|12|[1-9]):[0-5][0-9]( |)$/g; // Full time regex (XX:XX) without am/AM/pm/PM
    const fullTimeWithFirstLetterRegex = /^(10|11|12|[1-9]):[0-5][0-9]( |)(a|A|p|P)$/g; // Full time regex (XX:XX) with first letter a/A
    const fullTimeRegex = /^(10|11|12|[1-9]):[0-5][0-9]( |)(a|A|p|P)(m|M)$/g; // Full time regex (XX:XX) with am/AM/pm/PM

    if (
        timeString === '' ||
        timeString.match(hourRegex) ||
        timeString.match(hourWithColonRegex) ||
        timeString.match(hourWithColonAndMinuteDigitRegex) ||
        timeString.match(fullTimeWithoutLetterRegex) ||
        timeString.match(hourWithColonAndMinuteDigitRegex) ||
        timeString.match(fullTimeWithFirstLetterRegex) ||
        timeString.match(fullTimeWithFirstLetterRegex) ||
        timeString.match(fullTimeRegex)
    ) {
        return true;
    }

    return false;
};

const getDaysToDate = (date) => {
    const today = new Date();
    const todayDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate()
    ).getTime();
    const currentDate = new Date(date).getTime();

    return Math.floor((currentDate - todayDate) / 1000 / 86400); // / 1000 ms / 86400 s = 24 h
};

const ParseDate = (date) => {
    const parsedDate = useDate(date, DATE_TYPES.long);
    switch (getDaysToDate(date)) {
        case 0:
            return `Today, ${date.toLocaleString('en-US', {
                hour: '2-digit',
                minute: '2-digit',
            })}`;
        case 1:
            return `Tomorrow, ${date.toLocaleString('en-US', {
                hour: '2-digit',
                minute: '2-digit',
            })}`;
        default:
            return parsedDate;
    }
};

const parseReminders = (appliedReminders) =>
    appliedReminders.map((reminder) => {
        const reminderDate = new Date(reminder.action_datetime);

        return {
            ...reminder,
            dateTimeObject: reminderDate,
            parsedDate: ParseDate(reminderDate),
        };
    });

export default parseReminders;
