import styled from 'styled-components/macro';

export const AdminMemosWrapper = styled.div``;

export const AdminMemosListWrapper = styled.div`
    padding: 0 16px;
`;

export const Divider = styled.div`
    margin-top: 24px;
    margin-bottom: 16px;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    color: ${({ theme }) => theme.color.textLight};
`;
