import styled from 'styled-components/macro';

export const StyledDataSection = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: ${({ theme }) => `${theme.breakpoints.mobile}px`}) {
        width: 100%;
    }
`;

export const Title = styled.div`
    color: ${({ theme }) => theme.color.textLight};
    font-size: ${({ theme }) => theme.fontSize.medium};
`;

export const Grid = styled.div`
    display: flex;
    height: 135px;
    margin-top: 10px;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
`;

export const GridItem = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    strong {
        font-size: ${({ theme }) => theme.fontSize.huge};
    }

    small {
        color: ${({ theme }) => theme.color.textLight};
        font-size: 10px;
        font-weight: 600;
        line-height: 12px;
        border-radius: ${({ theme }) => theme.border.radiusBase};
        background-color: ${({ theme }) => theme.color.bgSearch};
        padding: 7px 8px;
        margin-top: 4px;
    }
`;

export const Divider = styled.div`
    width: 1px;
    height: 60px;
    border-right: 1px solid;
    border-color: ${({ theme }) => theme.color.bgSearch};
    margin-top: 5px;
`;

export const StyledDataBox = styled.div`
    height: 47%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding: 24px;
    border-radius: ${({ theme }) => theme.border.radiusBase};
    border: ${({ theme }) => theme.border.base};
    background-color: ${({ theme }) => theme.color.white};
`;
