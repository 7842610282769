import {
    establishTeamsRanking,
    establishTeamsRankingSuccess,
    establishTeamsRankingFailure,
} from 'redux/actions/teamRanking';
import apiRoutes from 'config/apiRoutes';
import parseTeams from 'redux/helpers/team';

const establishTeamsRankingThunk = (
    getAccessTokenSilently,
    logout,
    isAdmin = false
) => (dispatch) => {
    dispatch(establishTeamsRanking());

    const route = isAdmin
        ? apiRoutes.get.organizationTeamRanking
        : apiRoutes.get.teamRanking;

    return getAccessTokenSilently()
        .then((token) => {
            fetch(route, {
                method: 'GET',
                headers: {
                    organization_slug: window.localStorage.getItem(
                        'MP_ORGANIZATION'
                    ),
                    Authorization: `Token token="${token}"`,
                },
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.error) {
                        return dispatch(
                            establishTeamsRankingFailure(res.error)
                        );
                    }

                    return dispatch(
                        establishTeamsRankingSuccess(parseTeams(res.teams))
                    );
                })
                .catch((err) =>
                    dispatch(
                        establishTeamsRankingFailure({
                            err,
                        })
                    )
                );
        })
        .catch(() => logout({ returnTo: window.location.origin }));
};

export default establishTeamsRankingThunk;
