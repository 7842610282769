import styled, { css } from 'styled-components/macro';

export const StyledSectionHeader = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 16px;

    @media (max-width: ${({ theme }) => `${theme.breakpoints.medium}px`}) {
        flex-flow: wrap;
        align-items: flex-start;
    }

    @media (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
        flex-direction: ${({ collapseOnMobile }) =>
            collapseOnMobile ? 'column' : 'initial'};
    }

    ${({ withMargin }) =>
        withMargin &&
        css`
            margin-left: 16px;
            margin-right: 16px;
        `}

    ${({ withPadding }) =>
        withPadding &&
        css`
            padding-left: 16px;
            padding-right: 16px;
        `}

    ${({ withCta }) =>
        withCta &&
        css`
            margin-bottom: 16px;
        `}

    a {
        text-decoration: none;
    }

    @media (max-width: ${({ theme }) => `${theme.breakpoints.xl}px`}) {
        min-width: 0;
        max-width: 100%;
    }

    ${({ customStyles }) =>
        customStyles &&
        css`
            ${customStyles}
        `}
`;

export const SectionTitle = styled.h2`
    font-size: ${({ theme }) => theme.fontSize.pageHeading};
    color: ${({ theme }) => theme.color.textNavigation};
    padding-right: 16px;
    font-weight: 600;

    margin-bottom: ${({ withMargin }) => withMargin && '32px'};
`;

export const StyledSubtitle = styled.span`
    color: ${({ theme }) => theme.color.textNavigationLight};
`;

export const StyledCounter = styled.span`
    border-radius: ${({ theme }) => theme.border.radiusBase};
    background-color: ${({ theme }) => theme.color.bgPrimaryLight};
    color: ${({ theme }) => theme.color.textNavigationCounter};
    width: 38px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const GoBackHolder = styled.div`
    padding-right: 24px;
    margin-right: 24px;
    border-right: ${({ theme }) => theme.border.base};
    color: ${({ theme }) => theme.color.primary};

    a,
    button {
        display: flex;
        align-items: center;
    }

    svg {
        margin-right: 8px;
    }

    span {
        color: ${({ theme }) => theme.color.primary};
        font-size: ${({ theme }) => theme.fontSize.small};
        line-height: 1;
    }
`;

export const CtaHolder = styled.div`
    margin-left: auto;

    button {
        display: flex;
        align-items: center;
    }
`;
