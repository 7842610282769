import React from 'react';
import { ReactComponent as Bell } from 'assets/svg/notifications/bell.svg';
import { ReactComponent as GamificationArrow } from 'assets/svg/notifications/gamification-arrow.svg';
import { ReactComponent as GamificationStar } from 'assets/svg/notifications/gamification-star.svg';
import { ReactComponent as MemoInfo } from 'assets/svg/notifications/memo-info.svg';
// import { ReactComponent as MemoWarning } from 'assets/svg/notifications/memo-warning.svg';
import { ReactComponent as PerformanceHighest } from 'assets/svg/notifications/performance-highest.svg';
// import { ReactComponent as PerformanceLowest } from 'assets/svg/notifications/performance-lowest.svg';
import { ReactComponent as PerformanceSmartTip } from 'assets/svg/notifications/performance-smart-tip.svg';
import { ReactComponent as TeamChat } from 'assets/svg/notifications/team-chat.svg';
import { ROUTES } from 'routing/constants';
import { StyledButton } from './SingleNotification.style';

export const names = {
    new_level_achieved: 'new_level_achieved',
    leading_in_team: 'leading_in_team',
    points_awarded: 'points_awarded',
    new_smart_tip_of_the_week_chosen: 'new_smart_tip_of_the_week_chosen',
    team_message_destroyed: 'team_message_destroyed',
    team_message_created: 'team_message_created',
    admin_memo_created: 'admin_memo_created',
    smart_tip_assigned_to_user: 'smart_tip_assigned_to_user',
    smart_tip_assigned_to_team: 'smart_tip_assigned_to_team',

    metric_results_uploaded: 'metric_results_uploaded',
    performance_tip_assigned_to_user: 'performance_tip_assigned_to_user',
    reminder_sent: 'reminder_sent',
};

// const pointsCauses = {
//     logged_in: 'logging in',
//     performance_viewed: 'visiting your performance view',
//     smart_tip_watched: 'watching smart tip',
//     smart_tip_rated: 'rating smart tip',
//     reminder_added: 'adding a new reminder',
//     comment_added: 'adding a new comment',
//     team_created: 'creating a new team',
// };

export const renderDescription = (name, context, currentUser) => {
    switch (name) {
        case names.new_level_achieved:
            return `${currentUser.userFirstName}, you are now at level ${context.level}!`;
        case names.leading_in_team:
            return `Congrats! You’ve completed the most Smart Tips on the ${context.name} team.`;
        case names.points_awarded:
            return `${currentUser.userFirstName}, you've earned ${context.points} stars!`;
        // Uncomment to display causes for gained start
        // if (pointsCauses[context.action_name] === undefined) {
        // return `${currentUser.userFirstName}, you earned ${context.points} stars!`;
        // }
        // return `${currentUser.userFirstName}, you earned ${
        // context.points
        // } stars for ${pointsCauses[context.action_name]}!`;
        case names.new_smart_tip_of_the_week_chosen:
            return `Your Smart Tip of the Week: ${context.smart_tip_name}`;
        case names.team_message_destroyed:
            return `${context.admin_full_name} deleted your message from the ${context.team_name} message board.`;
        case names.team_message_created:
            return `${context.user_full_name} just posted on the ${context.team_name} message board.`;
        case names.admin_memo_created:
            return context.message;
        case names.smart_tip_assigned_to_user:
            return `${context.admin_full_name} recommends this Smart Tip for you: “${context.smart_tip_name}”`;
        case names.smart_tip_assigned_to_team:
            return `New Smart Tip assigned to the ${context.team_name} team: “${context.smart_tip_name}”`;

        case names.metric_results_uploaded:
            return `${context.data_source_name} performance updated. See your results.`;
        case names.performance_tip_assigned_to_user:
            return `New Performance Tip recommendation: “${context.smart_tip_name}”`;
        case names.reminder_sent:
            return `Reminder: ${context.smart_tip_name}`;

        default:
            return null;
    }
};

export const renderIcon = (name) => {
    switch (name) {
        case names.new_level_achieved:
            return <GamificationArrow />;
        case names.leading_in_team:
        case names.metric_results_uploaded:
            return <PerformanceHighest />;
        case names.points_awarded:
            return <GamificationStar />;
        case names.new_smart_tip_of_the_week_chosen:
        case names.performance_tip_assigned_to_user:
        case names.smart_tip_assigned_to_user:
        case names.smart_tip_assigned_to_team:
        case names.reminder_sent:
            return <PerformanceSmartTip />;
        case names.admin_memo_created:
            return <MemoInfo />;
        case names.team_message_destroyed:
        case names.team_message_created:
            return <TeamChat />;
        default:
            return <Bell />;
    }
};

export const renderActions = (name, context, history, compact, markAsRead) => {
    switch (name) {
        case names.leading_in_team:
            return (
              <StyledButton
                    primary
                    compact={compact}
                    onClick={() => {
                        markAsRead();
                        history.push(`${ROUTES.teamOverview}/${context.id}`);
                    }}
                >
                Go to Team
              </StyledButton>
            );

        case names.new_smart_tip_of_the_week_chosen:
        case names.smart_tip_assigned_to_user:
        case names.smart_tip_assigned_to_team:
        case names.performance_tip_assigned_to_user:
        case names.reminder_sent:
            return (
              <StyledButton
                    primary
                    compact={compact}
                    onClick={() => {
                        markAsRead();
                        history.push(`${ROUTES.smartTips}/${context.id}`);
                    }}
                >
                Go to Smart Tip
              </StyledButton>
            );

        case names.team_message_created:
            return (
              <StyledButton
                    primary
                    compact={compact}
                    onClick={() => {
                        markAsRead();
                        history.push(`${ROUTES.teamOverview}/${context.id}`);
                    }}
                >
                Go to Team Chat
              </StyledButton>
            );

        case names.new_level_achieved:
        case names.points_awarded:
            return (
              <StyledButton
                    primary
                    compact={compact}
                    onClick={() => {
                        markAsRead();
                        history.push(`${ROUTES.performance}?scroll=true`);
                    }}
                >
                Go to Achievements
              </StyledButton>
            );

        case names.metric_results_uploaded:
            return (
              <StyledButton
                    primary
                    compact={compact}
                    onClick={() => {
                        markAsRead();
                        history.push(ROUTES.performance);
                    }}
                >
                Go to Performance
              </StyledButton>
            );
        case names.admin_memo_created:
        case names.team_message_destroyed:
        default:
            return null;
    }
};

export const getCircleColor = (type, theme) => {
    switch (type) {
        case names.new_level_achieved:
        case names.performance_tip_assigned_to_user:
        case names.new_smart_tip_of_the_week_chosen:
        case names.points_awarded:
        case names.smart_tip_assigned_to_user:
        case names.smart_tip_assigned_to_team:
        case names.leading_in_team:
        case names.metric_results_uploaded:
        case names.reminder_sent:
            return theme.color.primary;
        case names.admin_memo_created:
            return theme.color.textLight;
        case names.team_message_destroyed:
        case names.team_message_created:
            return theme.color.gold;
        default:
            return theme.color.violet;
    }
};

export const getPathColor = (type, theme) => {
    switch (type) {
        case names.new_level_achieved:
        case names.performance_tip_assigned_to_user:
        case names.new_smart_tip_of_the_week_chosen:
        case names.points_awarded:
        case names.smart_tip_assigned_to_user:
        case names.smart_tip_assigned_to_team:
        case names.leading_in_team:
        case names.metric_results_uploaded:
        case names.reminder_sent:
            return theme.color.bgPrimaryLight;
        case names.admin_memo_created:
            return theme.color.bgSearch;
        case names.team_message_destroyed:
        case names.team_message_created:
            return theme.color.goldLight;
        default:
            return theme.color.violetLight;
    }
};
