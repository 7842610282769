import styled from 'styled-components/macro';

const StyledTeamRankingView = styled.div`
    width: 100%;
    display: flex;
    flex-flow: row wrap;

    @media (max-width: ${({ theme }) => `${theme.breakpoints.xl}px`}) {
        width: auto;
    }
`;

export default StyledTeamRankingView;
