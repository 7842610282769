import styled from 'styled-components/macro';

export const DropdownWrapper = styled.div`
    margin: 16px 32px;
    width: 140px;
    position: relative;
`;

export const Dropdown = styled.div`
    padding: 8px;
    position: absolute;
    top: 49px;
    left: 0;
    width: 150px;
    display: grid;
    grid-template-rows: 1fr;
    background-color: ${({ theme }) => theme.color.primary};
    z-index: 1;
    border-radius: ${({ theme }) => theme.border.radiusBase};
`;

export const DropdownItem = styled.div`
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    padding: 16px;
    color: ${({ theme }) => theme.color.white};
    border-radius: ${({ theme }) => theme.border.radiusSmall};

    &:hover {
        background-color: ${({ theme }) => theme.color.darkBlue};
    }
`;
