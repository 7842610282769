import styled from 'styled-components/macro';

const DataSourcesMeasuresCTAHolder = styled.div`
    display: flex;

    button {
        margin-left: 16px;
    }

    @media (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
        flex-wrap: wrap;
        justify-content: flex-end;

        > * {
            margin: 8px 0;

            button {
                width: 195px;
            }
        }
    }
`;

export default DataSourcesMeasuresCTAHolder;
