import {
    ESTABLISH_CUSTOMERS,
    ESTABLISH_CUSTOMERS_SUCCESS,
    ESTABLISH_CUSTOMERS_FAILURE,
    LOAD_MORE_CUSTOMERS,
    MARK_ALL_CUSTOMERS_LOADED,
    RESET_CUSTOMERS,
} from 'redux/actions/customers';

export const initialState = {
    areCustomersEstablished: false,
    isCustomersError: false,
    areCustomersFetching: false,
    customers: [],
    error: null,
    hasMoreCustomers: true,
};

const customers = (state = initialState, action) => {
    switch (action.type) {
        case ESTABLISH_CUSTOMERS:
            return {
                ...state,
                areCustomersFetching: true,
                isCustomersError: false,
            };

        case ESTABLISH_CUSTOMERS_SUCCESS:
            return {
                ...state,
                areCustomersFetching: false,
                areCustomersEstablished: true,
                customers: action.payload,
            };

        case ESTABLISH_CUSTOMERS_FAILURE:
            return {
                ...state,
                areCustomersFetching: false,
                isCustomersError: true,
                error: action.err,
            };

        case LOAD_MORE_CUSTOMERS:
            return {
                ...state,
                areCustomersFetching: false,
                areCustomersEstablished: true,
                customers: [...state.customers, ...action.payload],
            };

        case MARK_ALL_CUSTOMERS_LOADED:
            return {
                ...state,
                hasMoreCustomers: false,
                areCustomersFetching: false,
                areCustomersEstablished: true,
            };

        case RESET_CUSTOMERS:
            return initialState;

        default:
            return state;
    }
};

export default customers;
