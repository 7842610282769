import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as EmptyStateIcon } from 'assets/svg/universal-empty-state.svg';

import { EmptyStateWrapper, Title, Text } from './UniversalEmptyState.style';

const UniversalEmptyState = ({ description }) => (
  <EmptyStateWrapper>
    <EmptyStateIcon />
    <Title>Nothing in here</Title>
    <Text>{description}</Text>
  </EmptyStateWrapper>
);

UniversalEmptyState.propTypes = {
    description: PropTypes.string,
};

UniversalEmptyState.defaultProps = {
    description: 'You have not visited any users yet.',
};

export default UniversalEmptyState;
