import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { ReactComponent as SvgLogo } from 'assets/svg/logo.svg';

const Logo = styled(SvgLogo)`
    width: ${(props) => (props.size ? `${props.size}px` : '24px')};
    height: ${(props) => (props.size ? `${props.size}px` : '24px')};
`;

Logo.propTypes = {
    size: PropTypes.number,
};

export default Logo;
