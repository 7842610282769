export const KEYBOARD_KEYS = {
    enter: 13,
    arrowLeft: 37,
    arrowUp: 38,
    arrowRight: 39,
    arrowDown: 40,
};

export const ROLES_TO_SELECT = {
    provider: 'User',
    hospital_admin: 'Organization Admin',
};
