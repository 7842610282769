import styled, { css } from 'styled-components/macro';

export const CreateTipWrapper = styled.div`
    width: 100%;
    padding: 16px;
`;

export const DividedSection = styled.div`
    width: 100%;
    height: 100%;
    border: ${({ theme }) => theme.border.base};
    border-radius: ${({ theme }) => theme.border.radiusBase};
    background-color: ${({ theme }) => theme.color.white};
    margin: 16px 0;

    display: flex;
    flex-direction: column;

    ${({ horizontal }) =>
        horizontal &&
        css`
            flex-direction: row;
        `};
`;

export const Segment = styled.div`
    padding: 32px;
    width: 100%;

    ${({ theme, borderPosition }) => css`
        border-${borderPosition}: ${theme.border.base};
    `};

    ${({ column }) =>
        column &&
        css`
            display: flex;
            flex-direction: column;
        `};

    input {
        max-width: 380px;
    }

    button {
        margin-right: 16px;
    }
`;

export const WrapperCheckbox = styled.div`
    ${({ mt, mb }) => `
        display: flex;
        align-items: center;
        margin-top: ${mt};
        margin-bottom: ${mb};
        cursor: pointer;
    `}
`;

export const SegmentTitle = styled.span`
    color: ${({ theme }) => theme.color.textLight};
    display: flex;
    margin-bottom: 16px;
`;
