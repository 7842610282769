export const ESTABLISH_ORGANIZATION_SMART_TIP_EVALUATION_LIST =
    'ESTABLISH_ORGANIZATION_SMART_TIP_EVALUATION_LIST';
export const ESTABLISH_ORGANIZATION_SMART_TIP_EVALUATION_LIST_SUCCESS =
    'ESTABLISH_ORGANIZATION_SMART_TIP_EVALUATION_LIST_SUCCESS';
export const ESTABLISH_ORGANIZATION_SMART_TIP_EVALUATION_LIST_FAILURE =
    'ESTABLISH_ORGANIZATION_SMART_TIP_EVALUATION_LIST_FAILURE';

export const establishSmartTipEvaluationList = () => ({
    type: ESTABLISH_ORGANIZATION_SMART_TIP_EVALUATION_LIST,
});

export const establishSmartTipEvaluationListSuccess = (payload) => ({
    type: ESTABLISH_ORGANIZATION_SMART_TIP_EVALUATION_LIST_SUCCESS,
    payload,
});

export const establishSmartTipEvaluationListFailure = (err) => ({
    type: ESTABLISH_ORGANIZATION_SMART_TIP_EVALUATION_LIST_FAILURE,
    err,
});
