import React from 'react';
import PropTypes from 'prop-types';
import {
    IndicatorsContainer,
    Indicator,
} from './OnboardingStatusIndicator.style';

const OnboardingStatusIndicator = ({ totalSteps, currentStep }) => (
  <IndicatorsContainer>
    {[...Array(totalSteps).keys()].map((n) => (
      <Indicator key={n} current={n === currentStep} />
        ))}
  </IndicatorsContainer>
);

OnboardingStatusIndicator.propTypes = {
    totalSteps: PropTypes.number.isRequired,
    currentStep: PropTypes.number.isRequired,
};

export default OnboardingStatusIndicator;
