import React, { useEffect, useState } from 'react';
import useOffset from 'hooks/useOffset';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import establishTeamsRankingThunk from 'redux/actions/thunks/teamRanking';
import TeamRankingView from 'views/TeamRankingView';
import useAdmin from 'hooks/useAdmin';
import { useAuth0 } from '@auth0/auth0-react';

const INFINITE_SCROLL_THRESHOLD = 20;

const TeamRanking = () => {
    const { getAccessTokenSilently, logout } = useAuth0();
    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.currentUser);
    const teamRanking = useSelector((state) => state.teamRanking);
    const { teamId } = useParams();
    const [displayedTeams, setDisplayedTeams] = useState([]);
    const [offset, incrementOffset, resetOffset] = useOffset(
        INFINITE_SCROLL_THRESHOLD
    );

    const isAdmin = useAdmin();

    const loadMoreTeams = () => {
        if (displayedTeams.length + offset > teamRanking.teams.length) {
            setDisplayedTeams([
                ...displayedTeams,
                ...teamRanking.teams.slice(offset, teamRanking.teams.length),
            ]);

            resetOffset(teamRanking.teams.length);
            return;
        }

        setDisplayedTeams([
            ...displayedTeams,
            ...teamRanking.teams.slice(
                offset,
                offset + INFINITE_SCROLL_THRESHOLD
            ),
        ]);
        incrementOffset();
    };

    useEffect(() => {
        if (
            !teamRanking.isTeamsRankingEstablished &&
            !teamRanking.isTeamsRankingFetching
        ) {
            dispatch(
                establishTeamsRankingThunk(
                    getAccessTokenSilently,
                    logout,
                    isAdmin
                )
            );
        }
    }, []);

    useEffect(() => {
        setDisplayedTeams(
            teamRanking.teams.slice(0, INFINITE_SCROLL_THRESHOLD)
        );
        resetOffset(INFINITE_SCROLL_THRESHOLD);
    }, [teamRanking.teams]);

    return (
      <TeamRankingView
            teamId={teamId}
            currentUser={currentUser}
            isFetching={teamRanking.isTeamsRankingFetching}
            teams={displayedTeams}
            loadFunc={loadMoreTeams}
            hasMore={
                teamRanking.teams
                    ? displayedTeams.length !== teamRanking.teams.length
                    : false
            }
        />
    );
};

TeamRanking.propTypes = {
    currentUser: PropTypes.shape({
        id: PropTypes.string,
        userTeams: PropTypes.arrayOf(PropTypes.any),
    }).isRequired,
    teamRanking: PropTypes.shape({
        isTeamsRankingEstablished: PropTypes.bool,
        isTeamsRankingError: PropTypes.bool,
        isTeamsRankingFetching: PropTypes.bool,
        teams: PropTypes.arrayOf(PropTypes.any),
    }).isRequired,
    actions: PropTypes.shape({
        establishRanking: PropTypes.func,
    }).isRequired,
};

export default TeamRanking;
