import React from 'react';
import { ReactComponent as DashboardIcon } from 'assets/svg/sidebarIcons/dashboard.svg';
import { ReactComponent as CoursesIcon } from 'assets/svg/sidebarIcons/courses.svg';
import { ReactComponent as SmartTipsIcon } from 'assets/svg/sidebarIcons/smartTips.svg';
import { ReactComponent as PerformanceIcon } from 'assets/svg/sidebarIcons/performance.svg';
import { ReactComponent as TeamIcon } from 'assets/svg/sidebarIcons/team.svg';

import { ReactComponent as AdminIcon } from 'assets/svg/admin-icon.svg';
import { ReactComponent as AdminUsersIcon } from 'assets/svg/admin-users-icon.svg';
import { ReactComponent as AdminMemosIcon } from 'assets/svg/sidebarIcons/adminMemos.svg';
import { ReactComponent as UserClassIcon } from 'assets/svg/userClassificationIcon.svg';

import theme from 'assets/theme/mainTheme';
import { HA_ROUTES, ROUTES } from 'routing/constants';

export const sidebarMenu = [
    {
        id: 0,
        title: 'Dashboard',
        url: ROUTES.dashboard,
        highlight: [ROUTES.dashboard],
        icon(isIconActive) {
            return (
              <DashboardIcon
                    fill={
                        isIconActive
                            ? theme.color.primary
                            : theme.color.textNavigation
                    }
                />
            );
        },
    },
    {
        id: 1,
        title: 'Smart Tips',
        url: ROUTES.smartTips,
        highlight: [
            ROUTES.smartTips,
            HA_ROUTES.smartTips,
            HA_ROUTES.tipOfTheWeek,
            HA_ROUTES.usersSatisfaction,
            HA_ROUTES.editSmartTip,
        ],
        icon(isIconActive) {
            return (
              <SmartTipsIcon
                    fill={
                        isIconActive
                            ? theme.color.primary
                            : theme.color.textNavigation
                    }
                />
            );
        },
    },
    {
        id: 2,
        title: 'Courses',
        url: ROUTES.courses,
        highlight: [ROUTES.courses],
        icon(isIconActive) {
            return (
              <CoursesIcon
                    fill={
                        isIconActive
                            ? theme.color.primary
                            : theme.color.textNavigation
                    }
                />
            );
        },
    },
    {
        id: 3,
        title: 'Performance',
        url: ROUTES.performance,
        highlight: [ROUTES.performance],
        icon(isIconActive) {
            return (
              <PerformanceIcon
                    fill={
                        isIconActive
                            ? theme.color.primary
                            : theme.color.textNavigation
                    }
                />
            );
        },
    },
    {
        id: 4,
        title: 'Teams',
        url: ROUTES.team,
        highlight: [
            ROUTES.team,
            ROUTES.createTeam,
            ROUTES.teamOverview,
            ROUTES.teamMembers,
            ROUTES.teamRanking,
        ],
        icon(isIconActive) {
            return (
              <TeamIcon
                    fill={
                        isIconActive
                            ? theme.color.primary
                            : theme.color.textNavigation
                    }
                />
            );
        },
    },
];

export const sidebarMenuWithoutLMS = [
    {
        id: 0,
        title: 'Dashboard',
        url: ROUTES.dashboard,
        highlight: [ROUTES.dashboard],
        icon(isIconActive) {
            return (
              <DashboardIcon
                    fill={
                        isIconActive
                            ? theme.color.primary
                            : theme.color.textNavigation
                    }
                />
            );
        },
    },
    {
        id: 1,
        title: 'Smart Tips',
        url: ROUTES.smartTips,
        highlight: [
            ROUTES.smartTips,
            HA_ROUTES.smartTips,
            HA_ROUTES.tipOfTheWeek,
            HA_ROUTES.usersSatisfaction,
            HA_ROUTES.editSmartTip,
        ],
        icon(isIconActive) {
            return (
              <SmartTipsIcon
                    fill={
                        isIconActive
                            ? theme.color.primary
                            : theme.color.textNavigation
                    }
                />
            );
        },
    },
    {
        id: 2,
        title: 'Performance',
        url: ROUTES.performance,
        highlight: [ROUTES.performance],
        icon(isIconActive) {
            return (
              <PerformanceIcon
                    fill={
                        isIconActive
                            ? theme.color.primary
                            : theme.color.textNavigation
                    }
                />
            );
        },
    },
    {
        id: 3,
        title: 'Teams',
        url: ROUTES.team,
        highlight: [
            ROUTES.team,
            ROUTES.createTeam,
            ROUTES.teamOverview,
            ROUTES.teamMembers,
            ROUTES.teamRanking,
        ],
        icon(isIconActive) {
            return (
              <TeamIcon
                    fill={
                        isIconActive
                            ? theme.color.primary
                            : theme.color.textNavigation
                    }
                />
            );
        },
    },
];

export const sidebarAdminMenu = [
    {
        id: 5,
        title: 'Data Sources / Metrics',
        url: HA_ROUTES.dataSourcesMetrics,
        highlight: [HA_ROUTES.dataSourcesMetrics],
        icon(isIconActive) {
            return (
              <AdminIcon
                    fill={
                        isIconActive
                            ? theme.color.primary
                            : theme.color.textNavigation
                    }
                />
            );
        },
    },
    {
        id: 6,
        title: 'Users',
        url: HA_ROUTES.users,
        highlight: [HA_ROUTES.users, HA_ROUTES.editUser],
        icon(isIconActive) {
            return (
              <AdminUsersIcon
                    fill={
                        isIconActive
                            ? theme.color.primary
                            : theme.color.textNavigation
                    }
                />
            );
        },
    },
    {
        id: 7,
        title: 'Admin Memos',
        url: HA_ROUTES.adminMemos,
        highlight: [HA_ROUTES.adminMemos],
        icon(isIconActive) {
            return (
              <AdminMemosIcon
                    fill={
                        isIconActive
                            ? theme.color.primary
                            : theme.color.textNavigation
                    }
                />
            );
        },
    },
    {
        id: 8,
        title: 'Tags',
        url: HA_ROUTES.userClassification,
        highlight: [HA_ROUTES.userClassification],
        icon(isIconActive) {
            return (
              <UserClassIcon
                    fill={
                        isIconActive
                            ? theme.color.primary
                            : theme.color.textNavigation
                    }
                />
            );
        },
    },
];
