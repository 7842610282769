import styled, { css } from 'styled-components/macro';

export const CustomerDetailsContainer = styled.div`
    width: 100%;
    display: flex;
    flex-flow: row wrap;

    @media (max-width: ${({ theme }) => `${theme.breakpoints.xl}px`}) {
        width: auto;
    }

    ${({ loading }) =>
        loading &&
        css`
            height: 250px;
            justify-content: center;
            align-items: center;
        `}
`;

export const CustomerHeader = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 141px;
    padding: 24px;

    border-radius: ${({ theme }) => theme.border.radiusBase};
    border: ${({ theme }) => theme.border.base};
    background-color: ${({ theme }) => theme.color.white};
`;

export const StyledAvatar = styled.img`
    margin-right: 32px;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
`;

export const CustomerDataSection = styled.div`
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-evenly;

    strong {
        font-size: ${({ theme }) => theme.fontSize.sectionHeading};
    }

    small {
        font-size: ${({ theme }) => theme.fontSize.medium};
        color: ${({ theme }) => theme.color.textLight};
    }
`;

export const CustomerDataBoxes = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
`;

export const CustomerDataBox = styled.div`
    height: 336px;
    width: 49%;
    padding: 16px 24px;
    border-radius: ${({ theme }) => theme.border.radiusBase};
    border: ${({ theme }) => theme.border.base};
    background-color: ${({ theme }) => theme.color.white};
    display: flex;
    flex-flow: column nowrap;

    div:nth-child(1) {
        border-bottom: ${({ theme }) => theme.border.base};
    }
`;

export const CustomerDataBoxSection = styled.div`
    height: 50%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-evenly;

    h1,
    small {
        font-size: ${({ theme }) => theme.fontSize.medium};
        color: ${({ theme }) => theme.color.textLight};
        font-weight: 400;
    }

    h1 {
        margin-top: 0;
    }

    span {
        display: flex;
        flex-flow: column nowrap;

        strong {
            margin-bottom: 4px;

            p {
                margin: 0;
            }

            a {
                color: ${({ theme }) => theme.color.primary};
            }
        }
    }
`;

export const StyledAvatarPlaceholder = styled.div`
    margin-right: 32px;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    ${({ color }) =>
        color &&
        css`
            background-color: ${color};
        `}
`;

export const CtaWrapper = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
`;

export const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 49px;
    height: 49px;
    border-radius: ${({ theme }) => theme.border.radiusBase};
    cursor: pointer;

    &:hover {
        background-color: ${({ theme }) => theme.color.bgPrimaryLight};
    }

    ${({ withBackground }) =>
        withBackground &&
        css`
            background-color: ${({ theme }) => theme.color.bgPrimaryLight};
        `}
`;

export const DropdownWrapper = styled.div`
    z-index: 1;
    display: grid;
    grid-template-rows: 1fr;
    grid-gap: 8px;
    padding: 16px;
    width: 190px;
    position: absolute;
    border-radius: ${({ theme }) => theme.border.radiusBase};
    background-color: ${({ theme }) => theme.color.white};
    box-shadow: ${({ theme }) => theme.boxShadow.base};
    top: 49px;
    right: 0px;
`;

export const DropdownItem = styled.div`
    padding: 16px;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    color: ${({ theme }) => theme.color.primary};
    border-radius: ${({ theme }) => theme.border.radiusSmall};
    z-index: 1;

    ${({ theme, secondary }) =>
        secondary &&
        css`
            color: ${theme.color.red};
        `}

    &:hover {
        cursor: pointer;
        background-color: ${({ theme }) => theme.color.bgPrimaryLight};

        ${({ theme, secondary }) =>
            secondary &&
            css`
                background-color: ${theme.color.pinkLight};
            `}
    }
`;
