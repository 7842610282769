import { useReducer } from 'react';

const OFFSET_RESET = 'OFFSET_RESET';
const OFFSET_INCREMENT = 'OFFSET_INCREMENT';

const offsetReducer = (state, action) => {
    if (action.type === OFFSET_INCREMENT) {
        return state + action.payload;
    }

    if (action.type === OFFSET_RESET) {
        return action.payload;
    }

    return state;
};

const useOffset = (scrollThreshold) => {
    const [offset, dispatch] = useReducer(offsetReducer, 0);

    const incrementOffset = () =>
        dispatch({
            type: OFFSET_INCREMENT,
            payload: scrollThreshold,
        });

    const resetOffset = (resetTo = 0) =>
        dispatch({ type: OFFSET_RESET, payload: resetTo });

    return [offset, incrementOffset, resetOffset];
};

export default useOffset;
