import styled, { css } from 'styled-components/macro';

export const GalleryHolder = styled.div`
    width: 100%;
    max-width: 380px;
    border: ${({ theme }) => theme.border.base};
    border-radius: ${({ theme }) => theme.border.radiusBase};

    ${({ invalid, theme }) =>
        invalid &&
        css`
            border: 1px solid ${theme.color.quaternary};
        `};
`;

export const PreviewHolder = styled.div`
    padding: 16px;
`;

export const PreviewArea = styled.div`
    height: 170px;
    background-color: ${({ theme }) => theme.color.bgBase};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    img {
        height: 120px;
        width: auto;
    }
`;

export const SettingsArea = styled.div`
    padding: 16px;
    width: 100%;
    border-top: ${({ theme }) => theme.border.base};
`;

export const ModalGallery = styled.div`
    max-width: 448px;
    height: 380px;
    width: 100%;
    background-color: ${({ theme }) => theme.color.bgBase};
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 8px;
`;

export const ModalGalleryItem = styled.div`
    height: 122px;
    width: 122px;
    border: ${({ theme }) => theme.border.base};
    border-radius: ${({ theme }) => theme.border.radiusBase};
    background-color: ${({ theme }) => theme.color.white};
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 8px;
    cursor: pointer;

    img {
        height: 80px;
        width: auto;
    }
`;
