import styled, { css } from 'styled-components/macro';

export const AdminWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    ${({ fullHeight }) =>
        fullHeight &&
        css`
            flex-direction: column;
            min-height: calc(100vh - 250px);
        `}
`;

export const AdminSection = styled.div`
    width: 100%;

    ${({ withPadding }) =>
        withPadding &&
        css`
            padding: 0 16px;
        `}

    ${({ withSpacing }) =>
        withSpacing &&
        css`
            margin: 16px 0;
        `}

        ${({ withOverflowScroll }) =>
        withOverflowScroll &&
        css`
            @media (max-width: ${({ theme }) =>
                    `${theme.breakpoints.small}px`}) {
                overflow-x: scroll;
                padding-right: 0;
                margin-right: 0;

                > div {
                    width: fit-content;
                    min-width: 700px;
                }
            }
        `}
`;
