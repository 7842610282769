import React from 'react';
import PropTypes from 'prop-types';
import theme from 'assets/theme/mainTheme';
import CircleChartStyles from './CircleChart.style';

const CircleChart = ({ scoringPercent = 62 }) => (
  <CircleChartStyles>
    <svg
            className="circle-chart"
            viewBox="0 0 110 110"
            width="110"
            height="110"
            xmlns="http://www.w3.org/2000/svg"
        >
      <circle
                className="circle-chart__background"
                stroke="#efefef"
                strokeWidth="6"
                fill="none"
                cx="55"
                cy="55"
                r="48"
            />
      <circle
                className="circle-chart__circle"
                stroke={theme.color.primary}
                strokeWidth="10"
                strokeDasharray={`${
                    (scoringPercent / 100) * (2 * Math.PI * 50)
                }, ${2 * Math.PI * 50}`}
                strokeLinecap="round"
                fill="none"
                cx="55"
                cy="55"
                r="48"
            />
      <g className="circle-chart__info">
        <text
                    className="circle-chart__percent"
                    x="55"
                    y="55"
                    alignmentBaseline="central"
                    textAnchor="middle"
                    fontSize="21"
                >
          {`${scoringPercent || 0}%`}
        </text>
      </g>
    </svg>
  </CircleChartStyles>
);

CircleChart.propTypes = {
    scoringPercent: PropTypes.number.isRequired,
};

export default CircleChart;
