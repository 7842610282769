import styled, { css } from 'styled-components/macro';

export const SwitchHolder = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 32px 0;
`;

export const SwitchBox = styled.div`
    width: 18px;
    height: 18px;
    border-radius: 9px;
    border: 2px solid ${({ theme }) => theme.color.textLight};
    margin-right: 16px;
`;

export const SwitchCase = styled.div`
    width: calc(50% - 16px);
    height: 48px;
    border-radius: 10px;
    border: ${({ theme }) => theme.border.base};
    background-color: ${({ theme }) => theme.color.white};
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    padding: 16px;
    cursor: pointer;

    ${({ selected, theme }) =>
        selected &&
        css`
            ${SwitchBox} {
                border: 4px solid ${theme.color.primary};
            }
        `}
`;
