export const ESTABLISH_ORGANIZATION_USERS = 'ESTABLISH_ORGANIZATION_USERS';
export const ESTABLISH_ORGANIZATION_USERS_SUCCESS =
    'ESTABLISH_ORGANIZATION_USERS_SUCCESS';
export const ESTABLISH_ORGANIZATION_USERS_FAILURE =
    'ESTABLISH_ORGANIZATION_USERS_FAILURE';
export const LOAD_MORE_ORGANIZATION_USERS = 'LOAD_MORE_ORGANIZATION_USERS';
export const MARK_ALL_ORGANIZATION_USERS_LOADED =
    'MARK_ALL_ORGANIZATION_USERS_LOADED';

export const TOGGLE_USER_STATUS = 'TOGGLE_USER_STATUS';
export const TOGGLE_USER_STATUS_SUCCESS = 'TOGGLE_USER_STATUS_SUCCESS';
export const TOGGLE_USER_STATUS_FAILURE = 'TOGGLE_USER_STATUS_FAILURE';

export const DELETE_ORGANIZATION_USER = 'DELETE_ORGANIZATION_USER';
export const DELETE_ORGANIZATION_USER_SUCCESS =
    'DELETE_ORGANIZATION_USER_SUCCESS';
export const DELETE_ORGANIZATION_USER_FAILURE =
    'DELETE_ORGANIZATION_USER_FAILURE';
export const LOADING_ORGANIZATION_USERS = 'LOADING_ORGANIZATION_USERS';
export const RESET_ORGANIZATION_USERS = 'RESET_ORGANIZATION_USERS';
export const CHOOSEN_FILTER_TAGS = 'CHOOSEN_FILTER_TAGS';

export const establishOrganizationUsers = () => ({
    type: ESTABLISH_ORGANIZATION_USERS,
});

export const establishOrganizationUsersSuccess = (payload) => ({
    type: ESTABLISH_ORGANIZATION_USERS_SUCCESS,
    payload,
});

export const establishOrganizationUsersFailure = (err) => ({
    type: ESTABLISH_ORGANIZATION_USERS_FAILURE,
    err,
});

export const loadMoreOrganizationUsers = (payload) => ({
    type: LOAD_MORE_ORGANIZATION_USERS,
    payload,
});

export const allUsersLoaded = () => ({
    type: MARK_ALL_ORGANIZATION_USERS_LOADED,
});

export const toggleUserStatus = () => ({
    type: TOGGLE_USER_STATUS,
});

export const toggleUserStatusSuccess = (payload) => ({
    type: TOGGLE_USER_STATUS_SUCCESS,
    payload,
});

export const toggleUserStatusFailure = (err) => ({
    type: TOGGLE_USER_STATUS_FAILURE,
    err,
});

export const deleteOrganizationUser = () => ({
    type: DELETE_ORGANIZATION_USER,
});

export const deleteOrganizationUserSuccess = (payload) => ({
    type: DELETE_ORGANIZATION_USER_SUCCESS,
    payload,
});

export const deleteOrganizationUserFailure = (err) => ({
    type: DELETE_ORGANIZATION_USER_FAILURE,
    err,
});

export const resetOrganizationUsers = () => ({
    type: RESET_ORGANIZATION_USERS,
});

export const isLoadingOrganizationUsers = (payload) => ({
    type: LOADING_ORGANIZATION_USERS,
    payload,
});

export const choosenFilterTags = (payload) => ({
    type: CHOOSEN_FILTER_TAGS,
    payload,
});
