import React from 'react';
import PropTypes from 'prop-types';
import SectionHeader from 'components/SectionHeader';
import StyledSpinner from 'components/Spinner';
import SingleCourse from 'components/SingleCourse';
import NoResults from 'components/EmptyState/NoResults';
import StyledCoursesSectionWrapper from './Courses.style';

const CoursesView = ({ isFetching, courses }) => (
  <>
    <SectionHeader
            title="Enrolled Courses"
            counter={
                courses.filter((course) => course.status === 'enrolled').length
            }
            withMargin
        />
    <StyledCoursesSectionWrapper>
      {isFetching && <StyledSpinner />}
      {courses.length === 0 ? (
        <NoResults
                    title="Currently you don't have any active courses."
                    message="New MedPower courses will appear here once you are enrolled."
                />
            ) : (
                courses
                    .filter((course) => course.status === 'enrolled')
                    .map((course) => (
                      <SingleCourse key={course.id} {...course} />
                    ))
            )}
    </StyledCoursesSectionWrapper>
    <SectionHeader
            title="Completed Courses"
            counter={
                courses.filter((course) => course.status === 'completed').length
            }
            withMargin
        />
    <StyledCoursesSectionWrapper>
      {isFetching && <StyledSpinner />}
      {courses.filter((course) => course.status === 'completed')
                .length === 0 ? (
                  <NoResults
                    title="There aren't any finished courses"
                    message="Finished MedPower courses will appear here once you complete them."
                    style={{ maxHeight: '290px' }}
                />
            ) : (
                courses
                    .filter((course) => course.status === 'completed')
                    .map((course) => (
                      <SingleCourse key={course.id} {...course} />
                    ))
            )}
    </StyledCoursesSectionWrapper>
  </>
);

CoursesView.propTypes = {
    isFetching: PropTypes.bool,
    courses: PropTypes.arrayOf(PropTypes.any).isRequired,
};

CoursesView.defaultProps = {
    isFetching: false,
};

export default CoursesView;
