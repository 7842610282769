import { useSelector } from 'react-redux';

const useTeamCreator = (teamId = '') => {
    const currentUser = useSelector((state) => state.currentUser);
    const currentTeamMembers = useSelector(
        (state) => state.teamMembers.teamMembers
    );
    const currentTeams = useSelector((state) => state.team.teams);

    if (!teamId) {
        if (!currentUser || !currentTeamMembers) {
            return { isTeamCreator: false, userId: null, usersCount: 0 };
        }

        const isUserTeamMember = currentTeamMembers.find(
            (member) => currentUser.id === member.id
        );

        if (isUserTeamMember) {
            return {
                isTeamCreator: isUserTeamMember.creator,
                userId: currentUser.id,
                usersCount: currentTeamMembers.length,
            };
        }

        return { isTeamCreator: false, userId: null, usersCount: 0 };
    }

    if (!currentUser || !currentTeams) {
        return { isTeamCreator: false, userId: null, usersCount: 0 };
    }

    const searchedTeam = currentTeams.find((team) => teamId === team.id);

    if (searchedTeam) {
        return {
            isTeamCreator: searchedTeam.creator_id === currentUser.id,
            userId: currentUser.id,
            usersCount: searchedTeam.users_count,
        };
    }

    return {
        isTeamCreator: false,
        userId: null,
        usersCount: 0,
    };
};

export default useTeamCreator;
