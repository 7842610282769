import {
    dispatchSuccessAlert,
    dispatchErrorAlert,
} from 'redux/actions/systemAlerts';

export const dispatchSuccessAlertThunk = (id, message) => (dispatch) => {
    dispatch(
        dispatchSuccessAlert({
            id,
            message,
        })
    );
};

export const dispatchErrorAlertThunk = (id, message = 'fatal') => (
    dispatch
) => {
    dispatch(
        dispatchErrorAlert({
            id,
            message,
        })
    );
};
