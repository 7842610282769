import styled from 'styled-components/macro';

export const StyledDashboardWrapper = styled.div`
    width: 100%;
    min-height: 100vh;
    position: relative;
    padding-left: ${({ isMobile }) => (isMobile ? '0px' : '320px')};
    transition: padding 0.2s ease;
`;

export const StyledContentHolder = styled.main`
    width: 100%;
    padding: 96px 24px 24px;
    overflow: hidden;

    @media (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
        padding: 96px 8px 8px;
    }
`;
