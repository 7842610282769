import React from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { ReactComponent as ArrowBackIcon } from 'assets/svg/arrow-back.svg';
import { ReactComponent as GooglePlayIcon } from 'assets/svg/download-mobile/google-play.svg';
import { ReactComponent as AppStoreIcon } from 'assets/svg/download-mobile/app-store.svg';
import useAdmin from 'hooks/useAdmin';
import useMPA from 'hooks/useMPA';

import { ROUTES } from 'routing/constants';
import theme from 'assets/theme/mainTheme';

import { useSelector } from 'react-redux';
import SidebarMPAContent from './SidebarMPAContent';
import {
    StyledSidebarMenuItem,
    StyledSidebarMenuHolder,
    StyledSidebarDownloadApp,
    StyledDownloadLabel,
    IconsWrapper,
    StyledMenuitemTitle,
    IconWrapper,
    BackToMPAContainer,
    BackToMPArrowWrapper,
    CurrentOrgIndicatorTxt,
} from './SidebarContent.style';
import {
    sidebarAdminMenu,
    sidebarMenu,
    sidebarMenuWithoutLMS,
} from './SidebarContent.mock';

const SidebarContent = ({ toggleMobileSidebar }) => {
    const history = useHistory();
    const isAdmin = useAdmin();
    const { pathname } = useLocation();
    const { isLMSEnabled, lmsCoursesListLink } = useSelector(
        (state) => state.currentUser
    );
    const { isCurrentUserMPA, displayAsMPA } = useMPA();

    if (displayAsMPA) {
        return <SidebarMPAContent />;
    }

    return (
      <>
        <BackToMPAContainer>
          {isCurrentUserMPA && (
            <BackToMPArrowWrapper
                        onClick={() => {
                            history.push(ROUTES.base);
                            toggleMobileSidebar();
                        }}
                    >
              <ArrowBackIcon stroke={theme.color.textLabel} />
            </BackToMPArrowWrapper>
                )}
          <CurrentOrgIndicatorTxt>
            {window.localStorage.getItem('MP_ORGANIZATION')}
          </CurrentOrgIndicatorTxt>
        </BackToMPAContainer>

        {isLMSEnabled ? (
          <StyledSidebarMenuHolder>
            {sidebarMenu.map((menuItem) => {
                        if (isAdmin && menuItem.title === 'Courses') {
                            return (
                              <a
                                    href={lmsCoursesListLink}
                                    key={menuItem.url}
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                <StyledSidebarMenuItem
                                        current={menuItem.highlight.find(
                                            (item) => pathname.includes(item)
                                        )}
                                    >
                                  {menuItem.icon()}
                                  <StyledMenuitemTitle>
                                    {menuItem.title}
                                  </StyledMenuitemTitle>
                                </StyledSidebarMenuItem>
                              </a>
                            );
                        }

                        return (
                          <Link
                                to={menuItem.url}
                                key={menuItem.url}
                                onClick={() => toggleMobileSidebar()}
                            >
                            <StyledSidebarMenuItem
                                    current={menuItem.highlight.find((item) =>
                                        pathname.includes(item)
                                    )}
                                >
                              {menuItem.icon()}
                              <StyledMenuitemTitle>
                                {menuItem.title}
                              </StyledMenuitemTitle>
                            </StyledSidebarMenuItem>
                          </Link>
                        );
                    })}
          </StyledSidebarMenuHolder>
            ) : (
              <StyledSidebarMenuHolder>
                {sidebarMenuWithoutLMS.map((menuItem) => (
                  <Link
                            to={menuItem.url}
                            key={menuItem.url}
                            onClick={() => toggleMobileSidebar()}
                        >
                    <StyledSidebarMenuItem
                                current={menuItem.highlight.find((item) =>
                                    pathname.includes(item)
                                )}
                            >
                      {menuItem.icon()}
                      <StyledMenuitemTitle>
                        {menuItem.title}
                      </StyledMenuitemTitle>
                    </StyledSidebarMenuItem>
                  </Link>
                    ))}
              </StyledSidebarMenuHolder>
            )}

        {isAdmin && (
        <StyledSidebarMenuHolder>
          {sidebarAdminMenu.map((menuItem) => (
            <Link
                            to={menuItem.url}
                            key={menuItem.url}
                            onClick={() => toggleMobileSidebar()}
                        >
              <StyledSidebarMenuItem
                                current={menuItem.highlight.find((item) =>
                                    pathname.includes(item)
                                )}
                            >
                {menuItem.icon()}
                <StyledMenuitemTitle>
                  {menuItem.title}
                </StyledMenuitemTitle>
              </StyledSidebarMenuItem>
            </Link>
                    ))}
        </StyledSidebarMenuHolder>
            )}

        <StyledSidebarDownloadApp>
          <StyledDownloadLabel>
            Download our Mobile App
          </StyledDownloadLabel>
          <IconsWrapper>
            <IconWrapper
                        href="https://apps.apple.com/us/app/medpower/id1566307498"
                        target="_blank"
                    >
              <AppStoreIcon />
            </IconWrapper>
            <IconWrapper
                        href="https://play.google.com/store/apps/details?id=org.medpower.medpower.app"
                        target="_blank"
                    >
              <GooglePlayIcon />
            </IconWrapper>
          </IconsWrapper>
        </StyledSidebarDownloadApp>
      </>
    );
};

SidebarContent.propTypes = {
    toggleMobileSidebar: PropTypes.func,
};

SidebarContent.defaultProps = {
    toggleMobileSidebar: () => {},
};

export default SidebarContent;
