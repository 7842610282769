import styled, { css } from 'styled-components/macro';

const MetricModalHolder = styled.div`
    width: 325px;
    height: 190px;
    margin: 0 32px 32px 0;
    padding: 24px;
    background-color: ${({ theme }) => theme.color.white};
    border-radius: ${({ theme }) => theme.border.radiusBase};
    border: ${({ theme }) => theme.border.base};
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    strong {
        margin-bottom: 16px;
    }

    span {
        color: ${({ theme }) => theme.color.textLight};
    }

    ${({ withChart }) =>
        withChart &&
        css`
            flex-direction: row;
            align-items: flex-start;
        `}

    @media (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
        width: 100%;
        min-width: 288px;
        margin-right: 0;
    }
`;

export const TitleHolder = styled.div`
    display: flex;
    flex-direction: column;

    strong,
    span {
        margin-bottom: 16px;
        padding-right: 5px;
    }
`;

export const Stats = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: auto;
`;

export const Scoring = styled.div`
    color: ${({ theme }) => theme.color.textBase};
    font-size: 18px;
    margin-right: 16px;
`;

export const Indicator = styled.div`
    width: 100%;
    height: 32px;
    /* padding: 8px; */
    border-radius: ${({ theme }) => theme.border.radiusBase};
    background-color: ${({ theme }) => theme.color.bgSearch};
    position: relative;
`;

export const IndicatorProgress = styled.div`
    height: 16px;
    padding: 8px;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.color.primary};
    position: relative;
    top: 50%;
    left: 8px;
    transform: translateY(-50%);
    z-index: 2;

    span {
        position: absolute;
        left: calc(100% + 8px);
        top: 50%;
        transform: translateY(-50%);
        line-height: 1;
        font-weight: bold;
        color: ${({ theme }) => theme.color.textBase};
        font-size: ${({ theme }) => theme.fontSize.small};
        margin-left: 8px;
    }

    ${({ scoring }) => css`
        width: ${scoring}%;

        ${scoring > 40 &&
        css`
            span {
                left: calc(100% - 32px);
                color: ${({ theme }) => theme.color.white};
            }
        `}
    `};

    ${({ efficient, theme }) => css`
        background-color: ${efficient
            ? theme.color.primary
            : theme.color.quaternary};
    `};
`;

export const IndicatorGoal = styled.div`
    height: 32px;
    width: 3px;
    background-color: ${({ theme }) => theme.color.white};
    opacity: 1;
    position: absolute;
    margin-left: 8px;
    top: 0;
    z-index: 1;

    &::before {
        content: 'ˆ';
        font-size: 14px;
        position: absolute;
        top: 101%;
        transform: translateX(-25.5%);
    }
    ${({ goal }) =>
        css`
            left: ${goal}%;
        `};
`;

export default MetricModalHolder;
