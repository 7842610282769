import styled from 'styled-components/macro';
import Button from 'components/Button';

export const RemindersCalendarHolder = styled.div`
    width: 100%;
    min-width: 400px;
    background-color: ${({ theme }) => theme.color.white};
    border: ${({ theme }) => theme.border.base};
    border-radius: ${({ theme }) => theme.border.radiusBase};

    @media (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
        min-width: 240px;
    }
`;

export const CalendarHeader = styled.div`
    border-top-right-radius: ${({ theme }) => theme.border.radiusBase};
    border-top-left-radius: ${({ theme }) => theme.border.radiusBase};
    border-bottom: ${({ theme }) => theme.border.base};
    height: 95px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px;
`;

export const StyledButton = styled(Button)`
    width: 100%;
`;

export const CalendarBody = styled.div`
    padding: 24px;

    * {
        font-family: 'Inter', sans-serif !important;
    }

    .react-datepicker {
        width: 100%;
        border: none;
    }

    .react-datepicker__month-container {
        float: none;
    }

    .react-datepicker__header {
        text-align: center;
        background-color: transparent;
        border-bottom: none;
        padding-top: 8px;
        position: relative;

        .react-datepicker__current-month {
            color: ${({ theme }) => theme.color.textBase};
            font-size: ${({ theme }) => theme.fontSize.base};
            font-weight: 600;
            height: 32px;
            user-select: none;
        }
    }

    .react-datepicker__navigation {
        border: 0.25rem solid transparent;

        &--previous {
            border-right-color: ${({ theme }) => theme.color.textLight};

            &:hover {
                border-right-color: ${({ theme }) => theme.color.textBase};
            }
        }

        &--next {
            border-left-color: ${({ theme }) => theme.color.textLight};

            &:hover {
                border-left-color: ${({ theme }) => theme.color.textBase};
            }
        }
    }

    .react-datepicker__month {
        margin: 0;
    }

    .react-datepicker__day-names {
        .react-datepicker__day-name {
            font-weight: 600;
            font-size: ${({ theme }) => theme.fontSize.smaller};
            color: ${({ theme }) => theme.color.textLight};
            text-transform: uppercase;
        }
    }
    .react-datepicker__day-names,
    .react-datepicker__week {
        display: flex;
        justify-content: space-between;
        margin: 8px 0;
    }

    .react-datepicker__day {
        text-align: center;
        color: ${({ theme }) => theme.color.textLight};
        cursor: pointer;
        border-radius: 50%;
        opacity: 1;
        transition: all 0.2s ease;

        &--outside-month {
            opacity: 0.5;
        }

        &:hover {
            background-color: ${({ theme }) => theme.color.bgPrimaryLight};
            color: ${({ theme }) => theme.color.primary};
        }

        &:focus,
        &:active {
            outline: none;
        }
    }

    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--selected,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--selected,
    .react-datepicker__year-text--in-selecting-range,
    .react-datepicker__year-text--in-range,
    .react-datepicker__day--keyboard-selected,
    .react-datepicker__month-text--keyboard-selected,
    .react-datepicker__quarter-text--keyboard-selected,
    .react-datepicker__year-text--keyboard-selected {
        border-radius: 50%;
        background-color: ${({ theme }) => theme.color.primary};
        color: ${({ theme }) => theme.color.white};
        outline: none;
    }

    .react-datepicker__day--with-reminders {
        position: relative;

        &::before {
            position: absolute;
            top: -12px;
            right: -4px;
            content: '•';
            color: ${({ theme }) => theme.color.secondary};
            font-size: ${({ theme }) => theme.fontSize.sectionHeading};
            font-size: 20px;
        }
    }
`;
