import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import MetricModal from 'components/MetricModal';
import apiRoutes from 'config/apiRoutes';
import useFetch from 'hooks/useFetch';
import Spinner from 'components/Spinner';
import useAdmin from 'hooks/useAdmin';
import { useAuth0 } from '@auth0/auth0-react';
import MetricModalHolder from './MetricDetailsModal.style';

const HOSPITAL_TEXT =
    'Average overall score for all Users, based on latest metrics.';
const PROVIDER_TEXT =
    'Average overall score based on latest individual metrics ';

const MetricDetailsModal = ({ sourceId, isUserView, userId }) => {
    const { getAccessTokenSilently, logout } = useAuth0();
    const isAdmin = useAdmin();
    const request = useFetch(getAccessTokenSilently, logout);
    const [loading, setLoading] = useState(true);
    const [metricDetails, setMetricDetails] = useState(undefined);

    const handleMetricDetailsSuccess = (res) => {
        setMetricDetails(res);
        setLoading(false);
    };

    const handleMetricDetailsError = (err) => {
        setLoading(false);
        return err;
    };

    const fetchMetricDetails = () => {
        let reqUrl;
        if (isUserView) {
            reqUrl = apiRoutes.get.organizationMetricSourceIdForSingleUser(
                userId,
                sourceId
            );
        } else {
            reqUrl = isAdmin
                ? apiRoutes.get.organizationMetricSourceId(sourceId)
                : apiRoutes.get.metricSourceId(sourceId);
        }

        request(
            reqUrl,
            'GET',
            handleMetricDetailsSuccess,
            handleMetricDetailsError,
            {},
            { 'Content-Type': 'application/json' }
        );
    };

    const renderHospitalText = () =>
        isUserView ? PROVIDER_TEXT : HOSPITAL_TEXT;

    useEffect(() => {
        setLoading(true);
        fetchMetricDetails();
    }, []);

    if ((loading, !metricDetails)) {
        return (
          <MetricModalHolder>
            <Spinner />
          </MetricModalHolder>
        );
    }

    return (
      <MetricModal
            withChart
            title={metricDetails.name}
            subtitle={isAdmin ? renderHospitalText() : PROVIDER_TEXT}
            scoring={metricDetails.overall_score}
        />
    );
};

MetricDetailsModal.propTypes = {
    isUserView: PropTypes.bool,
    sourceId: PropTypes.string.isRequired,
    userId: PropTypes.oneOfType([PropTypes.string, undefined]),
};

MetricDetailsModal.defaultProps = {
    isUserView: false,
    userId: undefined,
};

export default MetricDetailsModal;
