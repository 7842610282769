import React, { useState, useEffect } from 'react';
import useOffset from 'hooks/useOffset';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
    establishUsersThunk,
    loadMoreUsersThunk,
    resetUsersThunk,
} from 'redux/actions/thunks/user';
import { updateTeamThunk } from 'redux/actions/thunks/team';
import AddTeamMembersComponent from 'components/AddTeamMembers';
import { useAuth0 } from '@auth0/auth0-react';
import useAdmin from 'hooks/useAdmin';

const INFINITE_SCROLL_THRESHOLD = 20;

const excludeTeamMembersFromUsersList = (users, members) => {
    const membersIds = members ? members.map((member) => member.id) : [];

    return users.filter((user) => !membersIds.includes(user.id));
};

const AddTeamMembers = ({ teamMembers, teamId }) => {
    const { getAccessTokenSilently, logout } = useAuth0();
    const dispatch = useDispatch();
    const isAdmin = useAdmin();
    const users = useSelector((state) => state.users);
    const [offset, incrementOffset, resetOffset] = useOffset(
        INFINITE_SCROLL_THRESHOLD
    );
    const [nameFilter, setNameFilter] = useState('');

    const fetchUsers = () => {
        resetOffset(INFINITE_SCROLL_THRESHOLD);
        if (nameFilter.length >= 3) {
            dispatch(
                establishUsersThunk(
                    getAccessTokenSilently,
                    logout,
                    INFINITE_SCROLL_THRESHOLD,
                    0,
                    {
                        search: nameFilter.toLowerCase(),
                    }
                )
            );
        } else {
            dispatch(
                establishUsersThunk(
                    getAccessTokenSilently,
                    logout,
                    INFINITE_SCROLL_THRESHOLD,
                    0
                )
            );
        }
        incrementOffset();
    };

    useEffect(() => {
        dispatch(resetUsersThunk());
        fetchUsers();
    }, [nameFilter]);

    const addMembers = (membersToAdd) => {
        dispatch(
            updateTeamThunk(
                getAccessTokenSilently,
                logout,
                {
                    teamId,
                    user_ids: membersToAdd,
                },
                isAdmin
            )
        );
    };

    return (
      <AddTeamMembersComponent
            fetchUsers={fetchUsers}
            users={excludeTeamMembersFromUsersList(users.users, teamMembers)}
            updateTeam={addMembers}
            hasMoreUsers={users.hasMoreUsers && !users.areUsersFetching}
            filterUsers={setNameFilter}
            resetUsersList={() => {
                dispatch(resetUsersThunk());
                resetOffset();
            }}
            loadMoreUsers={() => {
                dispatch(
                    loadMoreUsersThunk(
                        getAccessTokenSilently,
                        logout,
                        INFINITE_SCROLL_THRESHOLD,
                        offset,
                        {
                            search: nameFilter.length >= 3 ? nameFilter : '',
                        }
                    )
                );
                incrementOffset();
            }}
        />
    );
};

AddTeamMembers.propTypes = {
    teamMembers: PropTypes.arrayOf(PropTypes.any).isRequired,
    teamId: PropTypes.string.isRequired,
};

export default AddTeamMembers;
