import styled, { css } from 'styled-components/macro';

export const StyledCTA = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: auto;
    padding: 24px;

    cursor: pointer;

    border-radius: ${({ theme }) => theme.border.radiusBase};
    border: ${({ theme }) => theme.border.base};
    background-color: ${({ theme }) => theme.color.white};
`;

export const StyledLabel = styled.div`
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    height: auto;
    width: 100%;

    small {
        color: ${({ theme }) => theme.color.textLight};
    }
`;

export const StyledAvatarHolder = styled.div`
    margin-right: 32px;
    min-width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    ${({ color }) =>
        color &&
        css`
            background-color: ${color};
        `}
`;
