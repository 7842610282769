import styled from 'styled-components/macro';

const CreateTeamWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 125px);
`;

export default CreateTeamWrapper;
