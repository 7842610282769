export const ESTABLISH_TEAM = 'ESTABLISH_TEAM';
export const ESTABLISH_TEAM_SUCCESS = 'ESTABLISH_TEAM_SUCCESS';
export const ESTABLISH_TEAM_FAILURE = 'ESTABLISH_TEAM_FAILURE';

export const CREATE_TEAM = 'CREATE_TEAM';
export const CREATE_TEAM_SUCCESS = 'CREATE_TEAM_SUCCESS';
export const CREATE_TEAM_FAILURE = 'CREATE_TEAM_FAILURE';

export const JOIN_TEAM = 'JOIN_TEAM';
export const JOIN_TEAM_SUCCESS = 'JOIN_TEAM_SUCCESS';
export const JOIN_TEAM_FAILURE = 'JOIN_TEAM_FAILURE';

export const UPDATE_TEAM = 'UPDATE_TEAM';
export const UPDATE_TEAM_SUCCESS = 'UPDATE_TEAM_SUCCESS';
export const UPDATE_TEAM_FAILURE = 'UPDATE_TEAM_FAILURE';

export const ARCHIVE_TEAM = 'ARCHIVE_TEAM';
export const ARCHIVE_TEAM_SUCCESS = 'ARCHIVE_TEAM_SUCCESS';
export const ARCHIVE_TEAM_FAILURE = 'ARCHIVE_TEAM_FAILURE';

export const RESET_TEAMS = 'RESET_TEAMS';

export const establishTeams = () => ({
    type: ESTABLISH_TEAM,
});

export const establishTeamsSuccess = (payload) => ({
    type: ESTABLISH_TEAM_SUCCESS,
    payload,
});

export const establishTeamsFailure = (err) => ({
    type: ESTABLISH_TEAM_FAILURE,
    err,
});

export const createTeam = () => ({
    type: CREATE_TEAM,
});

export const createTeamSuccess = (payload) => ({
    type: CREATE_TEAM_SUCCESS,
    payload,
});

export const createTeamFailure = (err) => ({
    type: CREATE_TEAM_FAILURE,
    err,
});

export const joinTeam = () => ({
    type: JOIN_TEAM,
});

export const joinTeamSuccess = (payload) => ({
    type: JOIN_TEAM_SUCCESS,
    payload,
});

export const joinTeamFailure = (err) => ({
    type: JOIN_TEAM_FAILURE,
    err,
});

export const updateTeam = () => ({
    type: UPDATE_TEAM,
});

export const updateTeamSuccess = (payload) => ({
    type: UPDATE_TEAM_SUCCESS,
    payload,
});

export const updateTeamFailure = (err) => ({
    type: UPDATE_TEAM_FAILURE,
    err,
});

export const archiveTeam = () => ({
    type: UPDATE_TEAM,
});

export const archiveTeamSuccess = (payload) => ({
    type: UPDATE_TEAM_SUCCESS,
    payload,
});

export const archiveTeamFailure = (err) => ({
    type: UPDATE_TEAM_FAILURE,
    err,
});

export const resetTeams = () => ({
    type: RESET_TEAMS,
});
