import React from 'react';
import PropTypes from 'prop-types';
import Badge from 'components/Badge';
import useMPA from 'hooks/useMPA';
import { MPA_ROUTES, ROUTES } from 'routing/constants';
import {
    ProfileHolder,
    ProfileImgHolder,
    ProfileDetails,
    TeamsHolder,
    TeamsTitle,
    BadgeHolder,
    StyledLink,
    ProfileImgEmpty,
    EditButton,
} from './ProfileBar.style';

const ProfileBar = ({
    userAvatarUrl,
    userName,
    userEmail,
    userTeams,
    userFirstName,
    userLastName,
}) => {
    const { isCurrentUserMPA } = useMPA();

    return (
      <ProfileHolder>
        {userAvatarUrl ? (
          <ProfileImgHolder src={userAvatarUrl} alt={userName} />
            ) : (
              <ProfileImgEmpty>
                {userFirstName && userLastName ? (
                  <>
                    {userFirstName[0]}
                    {userLastName[0]}
                  </>
                    ) : (
                        userName && userName[0]
                    )}
              </ProfileImgEmpty>
            )}

        <ProfileDetails>
          <h3>
            {userName}
            {' '}
          </h3>
          <span>{userEmail}</span>
        </ProfileDetails>
        <TeamsHolder>
          <TeamsTitle>In teams:</TeamsTitle>
          <BadgeHolder>
            {userTeams.map(
                        (team, index) =>
                            index < 3 && (
                            <Badge
                                    key={team.id}
                                    style={{
                                        backgroundColor: team.background_color,
                                        color: team.color,
                                    }}
                                >
                              {team.name}
                            </Badge>
                            )
                    )}
          </BadgeHolder>
          {userTeams.length > 3 && (
            <TeamsTitle style={{ marginTop: '8px' }}>
              and 
              {' '}
              {userTeams.length - 3}
              {' '}
              more.
            </TeamsTitle>
                )}
        </TeamsHolder>
        <StyledLink
                to={
                    isCurrentUserMPA
                        ? MPA_ROUTES.editUserProfile
                        : ROUTES.editUserProfile
                }
            >
          <EditButton primary>Edit profile</EditButton>
        </StyledLink>
      </ProfileHolder>
    );
};

ProfileBar.propTypes = {
    userName: PropTypes.string.isRequired,
    userFirstName: PropTypes.string.isRequired,
    userLastName: PropTypes.string.isRequired,
    userEmail: PropTypes.string.isRequired,
    userAvatarUrl: PropTypes.string.isRequired,
    userTeams: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default ProfileBar;
