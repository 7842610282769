import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import useOffset from 'hooks/useOffset';
import establishMetricUploadsThunk, {
    destroyMetricUploadThunk,
    loadMoreMetricUploadsThunk,
    resetMetricUploadsThunk,
} from 'redux/actions/thunks/metricUploads';
import MetricUploadItem, {
    MetricUploadsTableHeader,
} from 'components/MetricUploadItem/MetricUploadItem';
import Spinner from 'components/Spinner';
import InfiniteScroll from 'react-infinite-scroller';
import SectionHeader from 'components/SectionHeader';
import Button from 'components/Button';
import { useAuth0 } from '@auth0/auth0-react';
import { AdminSection } from 'views/DataSourcesMeasures/DataSourcesMeasures.style';

const INFINITE_SCROLL_THRESHOLD = 20;

const ProcessedResults = ({ isImportModalVisible }) => {
    const [offset, incrementOffset, resetOffset] = useOffset(
        INFINITE_SCROLL_THRESHOLD
    );
    const [sortedMetricUploads, setSortedMetricUploads] = useState([]);

    const dispatch = useDispatch();
    const { getAccessTokenSilently, logout } = useAuth0();
    const {
        areMetricUploadsEstablished,
        areMetricUploadsFetching,
        hasMoreMetricUploads,
        metricUploads,
    } = useSelector((state) => state.metricUploads);

    const reloadList = () => {
        dispatch(
            establishMetricUploadsThunk(
                getAccessTokenSilently,
                logout,
                INFINITE_SCROLL_THRESHOLD,
                0
            )
        );
        resetOffset(INFINITE_SCROLL_THRESHOLD);
    };

    useEffect(() => {
        dispatch(
            establishMetricUploadsThunk(
                getAccessTokenSilently,
                logout,
                INFINITE_SCROLL_THRESHOLD,
                0
            )
        );
        resetOffset(INFINITE_SCROLL_THRESHOLD);

        return dispatch(resetMetricUploadsThunk());
    }, []);

    useEffect(() => {
        if (!isImportModalVisible) {
            dispatch(
                establishMetricUploadsThunk(
                    getAccessTokenSilently,
                    logout,
                    INFINITE_SCROLL_THRESHOLD,
                    0
                )
            );
            resetOffset(INFINITE_SCROLL_THRESHOLD);
        }
    }, [isImportModalVisible]);

    useEffect(() => {
        setSortedMetricUploads(
            metricUploads.sort(
                (a, b) =>
                    new Date(b.created_at).getTime() -
                    new Date(a.created_at).getTime()
            )
        );
    }, [metricUploads]);

    if (!areMetricUploadsEstablished) {
        return <Spinner />;
    }

    return (
      <>
        <div>
          <SectionHeader title="Processed Files">
            <Button primary small onClick={reloadList}>
              Refresh
            </Button>
            {areMetricUploadsFetching && <Spinner small />}
          </SectionHeader>
        </div>
        <AdminSection withOverflowScroll>
          <MetricUploadsTableHeader />
          <InfiniteScroll
                    loadMore={() => {
                        dispatch(
                            loadMoreMetricUploadsThunk(
                                getAccessTokenSilently,
                                logout,
                                INFINITE_SCROLL_THRESHOLD,
                                offset
                            )
                        );
                        incrementOffset();
                    }}
                    loader={<Spinner key="spinner" />}
                    hasMore={hasMoreMetricUploads && !areMetricUploadsFetching}
                >
            {sortedMetricUploads.map((item) => (
              <MetricUploadItem
                            key={item.id}
                            id={item.id}
                            createdAt={item.created_at}
                            fileName={item.file_name}
                            status={item.status}
                            errorsFileUrl={item.error_file_url || undefined}
                            onDelete={() =>
                                dispatch(
                                    destroyMetricUploadThunk(
                                        getAccessTokenSilently,
                                        logout,
                                        item.id
                                    )
                                )}
                        />
                    ))}
            {areMetricUploadsFetching && <Spinner />}
          </InfiniteScroll>
        </AdminSection>
      </>
    );
};

ProcessedResults.propTypes = {
    isImportModalVisible: PropTypes.bool.isRequired,
};

export default ProcessedResults;
