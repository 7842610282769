import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useFetch from 'hooks/useFetch';
import apiRoutes from 'config/apiRoutes';
import SectionHeader from 'components/SectionHeader';
import AdminHANavigation from 'components/AdminHANavigation';
import SmartTipUploadItem, {
    SmartTipUploadsTableHeader,
} from 'components/SmartTipUploadItem/SmartTipUploadItem';
import InfiniteScroll from 'react-infinite-scroller';
import Spinner from 'components/Spinner';
import Button from 'components/Button';
import Modal from 'components/Modal';
import SwitchButtons from 'components/SwitchButtons';
import SurveyFeedbackModal from 'containers/SurveyFeedbackModal';
import { useAuth0 } from '@auth0/auth0-react';
import {
    CTA,
    AdminSection,
    AdminWrapper,
    Filters,
    StyledInput,
    ModalBody,
} from './UsersSatisfaction.style';

const UsersSatisfaction = ({
    areOrganizationSmartTipsEstablished,
    searchValue,
    handleSearchInput,
    loadMore,
    hasMore,
    organizationSmartTips,
    areOrganizationSmartTipsFetching,
    selectedSmartTips,
    handleSelectSmartTip,
    handleSelectAllSmartTips,
    isSurveyFeedbackModalVisible,
    selectedSmartTip,
    setSelectedSmartTip,
    reload,
}) => {
    const { getAccessTokenSilently, logout } = useAuth0();
    const [statusModalVisible, setStatusModalVisible] = useState(false);
    const [statusConfirmed, setStatusConfirmed] = useState(true);

    const request = useFetch(getAccessTokenSilently, logout);

    const handleChangeStatusConfirmation = (type) => {
        // type is 'confirm' or 'reject'
        if (type === 'confirm') {
            setStatusConfirmed(true);
        }

        if (type === 'reject') {
            setStatusConfirmed(false);
        }
    };

    const handleChangeStatus = () => {
        const body = JSON.stringify({
            smart_tips_ids: selectedSmartTips,
            surveys_enabled: statusConfirmed,
        });

        request(
            apiRoutes.post.setSurveyStatuses,
            'POST',
            () => {
                setStatusModalVisible(false);
                reload();
            },
            () => {},
            {
                body,
            },
            { 'Content-Type': 'application/json' }
        );
    };

    return (
        <>
            <AdminWrapper data-testid="AdminHAWrapper">
                <SectionHeader
                    title="Users Satisfaction"
                    withPadding
                    cta={() => (
                        <CTA>
                            <p>{`${selectedSmartTips.length} selected`}</p>
                            <Button
                                disabled={selectedSmartTips.length < 1}
                                x
                                primaryDark
                                onClick={() => setStatusModalVisible(true)}
                            >
                                Change Survey Status
                            </Button>
                        </CTA>
                    )}
                />
                <AdminSection withPadding>
                    <AdminHANavigation variant="users-satisfaction" />
                </AdminSection>
                <AdminSection withPadding>
                    {!areOrganizationSmartTipsEstablished ? (
                        <Spinner />
                    ) : (
                        <>
                            <Filters>
                                <p>Filters</p>
                                <StyledInput
                                    variant="base"
                                    value={searchValue}
                                    placeholder="Smart Tip Name"
                                    onChange={({ target: { value } }) =>
                                        handleSearchInput(value)
                                    }
                                />
                            </Filters>
                            <AdminSection withOverflowScroll>
                                <SmartTipUploadsTableHeader
                                    areAllSelected={
                                        organizationSmartTips.length ===
                                        selectedSmartTips.length
                                    }
                                    handleSelectAllSmartTips={
                                        handleSelectAllSmartTips
                                    }
                                />
                                <InfiniteScroll
                                    loadMore={loadMore}
                                    loader={
                                        areOrganizationSmartTipsFetching ? (
                                            <Spinner key="spinner" />
                                        ) : (
                                            <></>
                                        )
                                    }
                                    hasMore={hasMore}
                                >
                                    {organizationSmartTips.map((item) => (
                                        <SmartTipUploadItem
                                            key={item.id}
                                            smartTip={item}
                                            isSelected={selectedSmartTips.includes(
                                                item.id
                                            )}
                                            handleSelectSmartTip={
                                                handleSelectSmartTip
                                            }
                                            setSelectedSmartTip={
                                                setSelectedSmartTip
                                            }
                                        />
                                    ))}
                                    {areOrganizationSmartTipsFetching && (
                                        <Spinner />
                                    )}
                                </InfiniteScroll>
                            </AdminSection>
                        </>
                    )}
                </AdminSection>
            </AdminWrapper>

            {isSurveyFeedbackModalVisible && (
                <SurveyFeedbackModal
                    selectedSmartTip={selectedSmartTip}
                    closeModal={() => setSelectedSmartTip(undefined)}
                />
            )}

            {statusModalVisible && (
                <Modal
                    absolute
                    fixed
                    headerText={`Survey Status `}
                    closeModal={() => setStatusModalVisible(false)}
                    isCloseIcon
                    closeByIconClick={() => setStatusModalVisible(false)}
                    modalFooter={() => (
                        <>
                            <Button
                                primary
                                onClick={() => setStatusModalVisible(false)}
                                inline
                            >
                                Cancel
                            </Button>
                            <Button
                                primaryDark
                                onClick={() => handleChangeStatus()}
                                inline
                            >
                                Confirm
                            </Button>
                        </>
                    )}
                >
                    <ModalBody>
                        <span>
                            Set Satisfaction Survey Status for selected Smart
                            Tips
                        </span>
                        <SwitchButtons
                            isConfirmed={statusConfirmed}
                            handleUpdate={handleChangeStatusConfirmation}
                        />
                    </ModalBody>
                </Modal>
            )}
        </>
    );
};

UsersSatisfaction.propTypes = {
    areOrganizationSmartTipsEstablished: PropTypes.bool.isRequired,
    searchValue: PropTypes.string.isRequired,
    handleSearchInput: PropTypes.func.isRequired,
    loadMore: PropTypes.func.isRequired,
    hasMore: PropTypes.bool.isRequired,
    organizationSmartTips: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
            last_evaluated_at: PropTypes.number,
            surveys_enabled: PropTypes.bool,
            score: PropTypes.string,
            comments_count: PropTypes.number,
        })
    ).isRequired,
    areOrganizationSmartTipsFetching: PropTypes.bool.isRequired,
    selectedSmartTips: PropTypes.arrayOf(PropTypes.string).isRequired,
    handleSelectSmartTip: PropTypes.func.isRequired,
    handleSelectAllSmartTips: PropTypes.func.isRequired,
    isSurveyFeedbackModalVisible: PropTypes.bool.isRequired,
    selectedSmartTip: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        full_name: PropTypes.string,
        avatar_url: PropTypes.string,
        value: PropTypes.number,
        created_at: PropTypes.string,
    }),
    setSelectedSmartTip: PropTypes.func.isRequired,
    reload: PropTypes.func.isRequired,
};

UsersSatisfaction.defaultProps = {
    selectedSmartTip: undefined,
};

export default UsersSatisfaction;
