import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import theme from 'assets/theme/mainTheme';
import { ReactComponent as CloseIcon } from 'assets/svg/close.svg';
import { ReactComponent as HeartIcon } from 'assets/svg/heart-empty.svg';
import { ReactComponent as HeartIconFull } from 'assets/svg/heart-filled.svg';
import { css } from 'styled-components/macro';
import AvatarPlaceholder from 'components/AvatarPlaceholder';
import parseDate from './TeamOverviewMessages.common';
import {
    SingleMessage,
    SingleMessageAvatar,
    SingleMessageContent,
    SingleMessageTitle,
    SingleMessageDescription,
    SingleMessageNavigation,
    HeartCounter,
} from './TeamOverviewMessages.style';

const CommentMessage = ({
    message,
    handleLike,
    handleDelete,
    deleteAllowed,
}) => {
    const [isHovered, setIsHovered] = useState(false);
    const [isDeleteAllowed, setIsDeleteAllowed] = useState(false);

    useEffect(() => {
        setIsDeleteAllowed(deleteAllowed);
    }, [deleteAllowed]);

    const handleHover = (type) => {
        switch (type) {
            case 'over':
                if (!isHovered) {
                    setIsHovered(true);
                }
                break;
            case 'out':
                setIsHovered(false);
                break;
            default:
                break;
        }
    };

    return (
      <SingleMessage
            onMouseOver={() => handleHover('over')}
            onFocus={() => handleHover('over')}
            onMouseOut={() => handleHover('out')}
            onBlur={() => handleHover('out')}
        >
        <SingleMessageAvatar>
          {message.user.avatar_url ? (
            <AvatarPlaceholder
                        image={message.user.avatar_url}
                        width="40px"
                        height="40px"
                    />
                ) : (
                  <AvatarPlaceholder
                        width="40px"
                        height="40px"
                        customStyles={css`
                            margin-right: 8px;
                        `}
                    >
                    <span>
                      {message.user.first_name &&
                                message.user.first_name.slice(0, 1)}
                      {message.user.last_name &&
                                message.user.last_name.slice(0, 1)}
                    </span>
                  </AvatarPlaceholder>
                )}
        </SingleMessageAvatar>

        <SingleMessageContent>
          <SingleMessageTitle>
            <h3>{`${message.user.first_name} ${message.user.last_name}`}</h3>
            <span>{parseDate(message.created_at)}</span>
          </SingleMessageTitle>
          <SingleMessageDescription>
            <span>{message.message}</span>
          </SingleMessageDescription>
          <SingleMessageNavigation>
            <HeartCounter
                        onClick={() => handleLike()}
                        isSelected={message.liked}
                    >
              {message.liked ? (
                <HeartIconFull fill={theme.color.quaternary} />
                        ) : (
                          <HeartIcon
                                fill={
                                    isHovered
                                        ? theme.color.quaternary
                                        : theme.color.textLight
                                }
                            />
                        )}

              <span className="likeCounter">
                {message.likes_count}
              </span>
            </HeartCounter>
            {isDeleteAllowed && (
            <CloseIcon
                            fill={theme.color.textNavigation}
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleDelete()}
                        />
                    )}
          </SingleMessageNavigation>
        </SingleMessageContent>
      </SingleMessage>
    );
};

CommentMessage.propTypes = {
    handleLike: PropTypes.func.isRequired,
    handleDelete: PropTypes.func.isRequired,
    deleteAllowed: PropTypes.bool.isRequired,
    message: PropTypes.shape({
        id: PropTypes.string,
        message: PropTypes.string,
        type: PropTypes.string,
        likes_count: PropTypes.number,
        liked: PropTypes.bool,
        created_at: PropTypes.string,
        user: PropTypes.shape({
            id: PropTypes.string,
            first_name: PropTypes.string,
            last_name: PropTypes.string,
            color: PropTypes.string,
            background_color: PropTypes.string,
            email: PropTypes.string,
            avatar_url: PropTypes.string,
        }),
    }).isRequired,
};

export default CommentMessage;
