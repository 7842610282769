export const DATE_TYPES = {
    long: 'long',
};

const useDate = (date, type) => {
    switch (type) {
        case DATE_TYPES.long:
            return date.toLocaleString('en-US', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
            });
        default:
            return date.toLocaleString('en-US', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
            });
    }
};

export default useDate;
