import {
    ESTABLISH_ORGANIZATION_USERS_MODAL,
    ESTABLISH_ORGANIZATION_USERS_MODAL_SUCCESS,
    ESTABLISH_ORGANIZATION_USERS_MODAL_FAILURE,
    LOAD_MORE_ORGANIZATION_USERS_MODAL,
    MARK_ALL_ORGANIZATION_USERS_MODAL_LOADED,
    RESET_ORGANIZATION_USERS_MODAL,
} from 'redux/actions/organizationUsersModal';

export const initialState = {
    areOrganizationUsersEstablished: false,
    isOrganizationUsersError: false,
    areOrganizationUsersFetching: false,
    organizationUsers: [],
    error: null,
    hasMoreOrganizationUsers: true,
    amountOfAllUsers: 0,
};

const organizationUsersModal = (state = initialState, action) => {
    switch (action.type) {
        case ESTABLISH_ORGANIZATION_USERS_MODAL:
            return {
                ...state,
                areOrganizationUsersFetching: true,
                isOrganizationUsersError: false,
            };

        case ESTABLISH_ORGANIZATION_USERS_MODAL_SUCCESS:
            return {
                ...state,
                areOrganizationUsersFetching: false,
                areOrganizationUsersEstablished: true,
                organizationUsers: action.payload.users,
                amountOfAllUsers: action.payload.total_count,
            };

        case ESTABLISH_ORGANIZATION_USERS_MODAL_FAILURE:
            return {
                ...state,
                areOrganizationUsersFetching: false,
                isOrganizationUsersError: true,
                error: action.err,
                amountOfAllUsers: 0,
            };

        case LOAD_MORE_ORGANIZATION_USERS_MODAL:
            return {
                ...state,
                areOrganizationUsersFetching: false,
                areOrganizationUsersEstablished: true,
                organizationUsers: [
                    ...state.organizationUsers,
                    ...action.payload.users,
                ],
                amountOfAllUsers: action.payload.total_count,
            };
        case MARK_ALL_ORGANIZATION_USERS_MODAL_LOADED:
            return {
                ...state,
                hasMoreOrganizationUsers: false,
                areOrganizationUsersFetching: false,
                areOrganizationUsersEstablished: true,
            };

        case RESET_ORGANIZATION_USERS_MODAL:
            return initialState;

        default:
            return state;
    }
};

export default organizationUsersModal;
