import styled, { css } from 'styled-components/macro';
import Button from 'components/Button';
import { ReactComponent as MemoInfo } from 'assets/svg/notifications/memo-info.svg';
import { ReactComponent as MemoWarning } from 'assets/svg/notifications/memo-warning.svg';

export const StyledMemoInfo = styled(MemoInfo)`
    width: 16px;
    height: 16px;

    circle {
        fill: ${({ theme }) => theme.color.textLight};
    }

    path {
        fill: ${({ theme }) => theme.color.bgSearch};
    }
`;

export const StyledMemoWarning = styled(MemoWarning)`
    width: 16px;
    height: 16px;

    circle {
        fill: ${({ theme }) => theme.color.quaternary};
    }

    path {
        fill: ${({ theme }) => theme.color.goldLight};
    }
`;

export const ModalCustomBodyStyle = styled.div`
    display: flex;
    flex-flow: column;

    ${({ overflowY }) =>
        overflowY &&
        css`
            overflow-y: scroll;
            height: 450px;
        `}
`;

export const TwoColumnGrid = styled.div`
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
`;

export const InputSection = styled.div`
    width: 100%;
    height: 100%;
    padding: 24px;
    display: grid;
    grid-template-columns: 1fr 4fr;
    grid-gap: 16px;
    background-color: ${({ theme, shadow }) =>
        shadow ? theme.color.bgBase : theme.color.white};

    ${({ borderTop }) =>
        borderTop &&
        css`
            border-top: ${({ theme }) => theme.border.base};
        `}

    ${({ borderBottom }) =>
        borderBottom &&
        css`
            border-bottom: ${({ theme }) => theme.border.base};
        `}


    ${({ noPaddingTop }) =>
        noPaddingTop &&
        css`
            padding-top: 0px;
        `}

    ${({ noPaddingBottom }) =>
        noPaddingBottom &&
        css`
            padding-bottom: 0px;
        `}
`;

export const StyledButton = styled(Button)`
    margin-left: 16px;
`;

export const Title = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    color: ${({ theme }) => theme.color.textLight};

    ${({ noCentered }) =>
        noCentered &&
        css`
            padding-top: 16px;
            align-items: flex-start;
        `}

    ${({ columnFlex }) =>
        columnFlex &&
        css`
            flex-direction: column;
        `}
`;

export const Message = styled.textarea`
    background-color: ${({ theme }) => theme.color.white};
    border: ${({ theme }) => theme.border.base};
    border-radius: ${({ theme }) => theme.border.radiusSmall};
    padding: 16px;
    width: 100%;
    height: 107px;
    resize: none;

    color: ${({ theme }) => theme.border.textBase};
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
`;
