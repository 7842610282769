import React, { useEffect } from 'react';
import MPACustomersView from 'views/MPACustomers';
import useOffset from 'hooks/useOffset';
import { useSelector, useDispatch } from 'react-redux';
import {
    establishCustomersThunk,
    loadMoreCustomersThunk,
    resetCustomersThunk,
} from 'redux/actions/thunks/customers';
import { useAuth0 } from '@auth0/auth0-react';

const INFINITE_SCROLL_THRESHOLD = 20;

const MPACustomers = () => {
    const dispatch = useDispatch();
    const { getAccessTokenSilently, logout } = useAuth0();
    const [offset, incrementOffset, resetOffset] = useOffset(
        INFINITE_SCROLL_THRESHOLD
    );

    const { customers, areCustomersFetching, hasMoreCustomers } = useSelector(
        (state) => state.customers
    );

    const hasMore = hasMoreCustomers && !areCustomersFetching;

    const loadMore = () => {
        dispatch(
            loadMoreCustomersThunk(
                getAccessTokenSilently,
                logout,
                INFINITE_SCROLL_THRESHOLD,
                offset
            )
        );
        incrementOffset();
    };

    useEffect(() => {
        dispatch(
            establishCustomersThunk(
                getAccessTokenSilently,
                logout,
                INFINITE_SCROLL_THRESHOLD,
                0
            )
        );
        resetOffset(INFINITE_SCROLL_THRESHOLD);

        return dispatch(resetCustomersThunk());
    }, []);

    return (
      <MPACustomersView
            isFetching={areCustomersFetching}
            customers={customers.filter(
                (customer) => customer.enabled === true
            )}
            hasMore={hasMore}
            loadMore={loadMore}
        />
    );
};

export default MPACustomers;
