import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
    establishOverviewThunk,
    fetchTeamMessagesWithParamsThunk,
    sendMessageThunk,
    messageFeedbackThunk,
    deleteMessageThunk,
} from 'redux/actions/thunks/teamOverview';
import TeamOverviewView from 'views/TeamOverview';
import useAdmin from 'hooks/useAdmin';
import { useAuth0 } from '@auth0/auth0-react';

const TeamOverview = ({
    isFetching,
    match: {
        params: { teamId },
    },
}) => {
    const { getAccessTokenSilently, logout } = useAuth0();
    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.currentUser);
    const overview = useSelector((state) => state.teamOverview);
    const isAdmin = useAdmin();

    const fetchPreviousMessages = (limit, offset) => {
        dispatch(
            fetchTeamMessagesWithParamsThunk(
                getAccessTokenSilently,
                logout,
                teamId,
                limit,
                offset,
                isAdmin
            )
        );
    };

    const deleteMessage = (messageId) => {
        dispatch(
            deleteMessageThunk(
                getAccessTokenSilently,
                logout,
                teamId,
                messageId,
                isAdmin
            )
        );
    };

    const leaveMessageFeedback = (messageId, liked) => {
        dispatch(
            messageFeedbackThunk(
                getAccessTokenSilently,
                logout,
                teamId,
                messageId,
                liked
            )
        );
    };

    const handleSendMessage = (messageContent) => {
        dispatch(
            sendMessageThunk(
                getAccessTokenSilently,
                logout,
                teamId,
                messageContent
            )
        );
    };

    useEffect(() => {
        dispatch(
            establishOverviewThunk(
                getAccessTokenSilently,
                logout,
                teamId,
                isAdmin
            )
        );
    }, []);

    return (
      <TeamOverviewView
            isFetching={isFetching}
            overview={overview}
            teamId={teamId}
            currentUser={currentUser}
            fetchPreviousMessages={fetchPreviousMessages}
            deleteMessage={deleteMessage}
            leaveMessageFeedback={leaveMessageFeedback}
            handleSendMessage={handleSendMessage}
        />
    );
};

TeamOverview.propTypes = {
    isFetching: PropTypes.bool,
    match: PropTypes.shape({
        params: PropTypes.shape({
            teamId: PropTypes.string,
        }),
    }).isRequired,
};
TeamOverview.defaultProps = {
    isFetching: false,
};

export default TeamOverview;
