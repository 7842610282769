import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Auth0Provider } from '@auth0/auth0-react';
import { ROUTES } from 'routing/constants';

const Auth0ProviderWithHistory = ({ children }) => {
    const domain = process.env.REACT_APP_AUTH0_DOMAIN;
    const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
    const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
    const history = useHistory();

    if (window.localStorage.getItem('MP_AUTH0_ORGANIZATION')) {
        return (
          <Auth0Provider
                domain={domain}
                clientId={clientId}
                redirectUri={window.location.origin}
                responseType="token id_token"
                onRedirectCallback={() => history.push(ROUTES.base)}
                organization={window.localStorage.getItem(
                    'MP_AUTH0_ORGANIZATION'
                )}
                scope="openid profile email"
                audience={audience}
            >
            {children}
          </Auth0Provider>
        );
    }

    if (window.localStorage.getItem('MP_AUTH0_CONNECTION')) {
        return (
          <Auth0Provider
                domain={domain}
                clientId={clientId}
                redirectUri={window.location.origin}
                responseType="token id_token"
                onRedirectCallback={() => history.push(ROUTES.base)}
                connection={window.localStorage.getItem('MP_AUTH0_CONNECTION')}
                scope="openid profile email"
                audience={audience}
            >
            {children}
          </Auth0Provider>
        );
    }

    return (
      <Auth0Provider
            domain={domain}
            clientId={clientId}
            redirectUri={window.location.origin}
            responseType="token id_token"
            onRedirectCallback={() => history.push(ROUTES.base)}
            connection={window.localStorage.getItem('MP_ORGANIZATION')}
            scope="openid profile email"
            audience={audience}
        >
        {children}
      </Auth0Provider>
    );
};

Auth0ProviderWithHistory.propTypes = {
    children: PropTypes.oneOfType([PropTypes.any]).isRequired,
};

export default Auth0ProviderWithHistory;
