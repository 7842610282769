export const ESTABLISH_USER_STATS = 'ESTABLISH_USER_DETAILS';
export const ESTABLISH_USER_STATS_SUCCESS = 'ESTABLISH_USER_STATS_SUCCESS';
export const ESTABLISH_USER_STATS_FAILURE = 'ESTABLISH_USER_DETAILS_FAILURE';

export const establishUserStats = () => ({
    type: ESTABLISH_USER_STATS,
});

export const establishUserStatsSuccess = (payload) => ({
    type: ESTABLISH_USER_STATS_SUCCESS,
    payload,
});

export const establishUserStatsFailure = (err) => ({
    type: ESTABLISH_USER_STATS_FAILURE,
    err,
});
