import {
    establishMetricSourcesMpa,
    establishMetricSourcesMpaSuccess,
    establishMetricSourcesMpaFailure,
} from 'redux/actions/mpaMetricSources';
import apiRoutes from 'config/apiRoutes';
import MPA_ORGANIZATION_SLUG from 'config/mpaAccessSlug';

const establishMetricSourcesMpaThunk = (getAccessTokenSilently, logout) => (
    dispatch
) => {
    dispatch(establishMetricSourcesMpa());

    return getAccessTokenSilently()
        .then((token) => {
            fetch(apiRoutes.get.mpaMetricSources, {
                method: 'GET',
                headers: {
                    organization_slug: MPA_ORGANIZATION_SLUG,
                    Authorization: `Token token="${token}"`,
                },
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.error) {
                        return dispatch(
                            establishMetricSourcesMpaFailure(res.error)
                        );
                    }

                    return dispatch(
                        establishMetricSourcesMpaSuccess(res.metric_sources)
                    );
                })
                .catch((err) =>
                    dispatch(
                        establishMetricSourcesMpaFailure({
                            err,
                        })
                    )
                );
        })
        .catch(() => logout({ returnTo: window.location.origin }));
};

export default establishMetricSourcesMpaThunk;
