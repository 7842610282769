export const ESTABLISH_CUSTOMERS = 'ESTABLISH_CUSTOMERS';
export const ESTABLISH_CUSTOMERS_SUCCESS = 'ESTABLISH_CUSTOMERS_SUCCESS';
export const ESTABLISH_CUSTOMERS_FAILURE = 'ESTABLISH_CUSTOMERS_FAILURE';
export const LOAD_MORE_CUSTOMERS = 'LOAD_MORE_CUSTOMERS';
export const MARK_ALL_CUSTOMERS_LOADED = 'MARK_ALL_CUSTOMERS_LOADED';
export const RESET_CUSTOMERS = 'RESET_CUSTOMERS';

export const establishCustomers = () => ({
    type: ESTABLISH_CUSTOMERS,
});

export const loadMoreCustomers = (payload) => ({
    type: LOAD_MORE_CUSTOMERS,
    payload,
});

export const allCustomersLoaded = () => ({
    type: MARK_ALL_CUSTOMERS_LOADED,
});

export const establishCustomersSuccess = (payload) => ({
    type: ESTABLISH_CUSTOMERS_SUCCESS,
    payload,
});

export const establishCustomersFailure = (err) => ({
    type: ESTABLISH_CUSTOMERS_FAILURE,
    err,
});

export const resetCustomers = () => ({
    type: RESET_CUSTOMERS,
});
