import styled, { css } from 'styled-components/macro';
import Button from 'components/Button';
import { ReactComponent as CloseIcon } from 'assets/svg/close-modal.svg';
import { ReactComponent as RobotIcon } from 'assets/svg/modal-robot.svg';

export const StyledModalBackground = styled.div`
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 1001;
    background-color: ${({ theme }) => theme.color.bgModal};
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const StyledModal = styled.div`
    width: 490px;
    min-height: 385px;
    border-radius: ${({ theme }) => theme.border.radiusBase};
    background-color: ${({ theme }) => theme.color.white};
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 80px 8px 16px;

    h3 {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        margin: 16px 0;
    }

    p {
        max-width: 355px;
        margin: 8px 0;
        font-weight: 500;
        line-height: 18px;
        font-size: ${({ theme }) => theme.fontSize.small};
        color: ${({ theme }) => theme.color.textLight};
    }

    ${(autoMargin) =>
        autoMargin &&
        css`
            p {
                margin-bottom: auto;
            }
        `}

    @media (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
        width: 100%;
    }
`;

export const StyledCloseIcon = styled(CloseIcon)`
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
`;

export const StyledRobotIcon = styled(RobotIcon)`
    position: absolute;
    bottom: calc(100% - 66px);
    cursor: pointer;
`;

export const SurveyOptions = styled.div`
    width: 355px;
    display: flex;
    justify-content: space-between;
    height: 58px;
    position: relative;
    text-align: center;
    margin-top: 40px;
`;

export const SurveyText = styled.strong`
    color: ${({ theme }) => theme.color.secondary};
    display: block;
    width: 100%;
    text-align: center;
    position: absolute;
    top: calc(100% + 16px);
    ${(props) =>
        props.isNeutral &&
        css`
            color: ${({ theme }) => theme.color.tertiary};
        `}
    ${(props) =>
        props.isNegative &&
        css`
            color: ${({ theme }) => theme.color.quaternary};
        `}
`;

export const SurveyOption = styled.div`
    background-color: ${({ theme }) => theme.color.bgPrimaryLight};
    cursor: pointer;
    width: 58px;
    height: 58px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    ${(props) =>
        props.bg === 'positive' &&
        css`
            background-color: ${({ theme }) => theme.color.secondary};
        `}
    ${(props) =>
        props.bg === 'neutral' &&
        css`
            background-color: ${({ theme }) => theme.color.tertiary};
        `}
    ${(props) =>
        props.bg === 'negative' &&
        css`
            background-color: ${({ theme }) => theme.color.quaternary};
        `}
`;

export const StyledTextfield = styled.textarea`
    background-color: ${({ theme }) => theme.color.white};
    border: ${({ theme }) => theme.border.base};
    border-radius: ${({ theme }) => theme.border.radiusBase};
    height: 120px;
    width: 420px;
    max-width: 420px;
    min-width: 240px;
    min-height: 60px;
    margin: 16px;
    padding: 16px 8px;

    @media (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
        width: 90vw;
    }
`;

export const StyledSurveyButton = styled(Button)`
    width: 420px;
    margin: 8px 8px 16px;
`;
