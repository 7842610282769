import {
    ESTABLISH_USERS,
    ESTABLISH_USERS_SUCCESS,
    ESTABLISH_USERS_FAILURE,
    FETCH_RECENT_USERS,
    FETCH_RECENT_USERS_SUCCESS,
    FETCH_RECENT_USERS_FAILURE,
    LOAD_MORE_USERS,
    MARK_ALL_USERS_LOADED,
    RESET_USERS,
} from 'redux/actions/user';

export const initialState = {
    areUsersEstablished: false,
    isUsersError: false,
    areUsersFetching: false,
    users: [],
    areRecentUsersEstablished: false,
    isRecentUsersError: false,
    areRecentUsersFetching: false,
    recentUsers: [],
    error: null,
    hasMoreUsers: true,
};

const users = (state = initialState, action) => {
    switch (action.type) {
        case ESTABLISH_USERS:
            return {
                ...state,
                areUsersFetching: true,
                isUsersError: false,
            };

        case ESTABLISH_USERS_SUCCESS:
            return {
                ...state,
                areUsersFetching: false,
                areUsersEstablished: true,
                users: action.payload,
            };

        case ESTABLISH_USERS_FAILURE:
            return {
                ...state,
                areUsersFetching: false,
                isUsersError: true,
                error: action.err,
            };

        case FETCH_RECENT_USERS:
            return {
                ...state,
                areRecentUsersFetching: true,
                isRecentUsersError: false,
            };

        case FETCH_RECENT_USERS_SUCCESS:
            return {
                ...state,
                areRecentUsersFetching: false,
                areRecentUsersEstablished: true,
                recentUsers: action.payload,
            };

        case FETCH_RECENT_USERS_FAILURE:
            return {
                ...state,
                areRecentUsersFetching: false,
                isRecentUsersError: true,
                error: action.err,
            };

        case LOAD_MORE_USERS:
            return {
                ...state,
                areUsersFetching: false,
                areUsersEstablished: true,
                users: [...state.users, ...action.payload],
            };

        case MARK_ALL_USERS_LOADED:
            return {
                ...state,
                hasMoreUsers: false,
                areUsersFetching: false,
                areUsersEstablished: true,
            };

        case RESET_USERS:
            return initialState;

        default:
            return state;
    }
};

export default users;
