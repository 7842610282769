import React from 'react';
import PropTypes from 'prop-types';
import DropdownSelect from 'components/DropdownSelect/DropdownSelect';
import { InputHolder, ProvidersSection } from './EditMetricModal.style';

const DisableForUsersInput = ({
    list,
    setSubmitted,
    defaultValue,
    loadMore,
    hasMore,
    filterFunc,
    resetList,
}) => (
  <ProvidersSection>
    <p>Turn off for Users</p>
    <InputHolder>
      <DropdownSelect
                type="user"
                items={list}
                loadMore={loadMore}
                hasMore={hasMore}
                onSearch={filterFunc}
                resetItemsList={resetList}
                preselectedItems={defaultValue}
                handleSetSelectedItems={setSubmitted}
                placeholder="User Name"
            />
    </InputHolder>
  </ProvidersSection>
);

DisableForUsersInput.propTypes = {
    list: PropTypes.arrayOf(PropTypes.any).isRequired,
    setSubmitted: PropTypes.func.isRequired,
    defaultValue: PropTypes.arrayOf(PropTypes.any).isRequired,
    loadMore: PropTypes.func.isRequired,
    hasMore: PropTypes.bool.isRequired,
    filterFunc: PropTypes.func.isRequired,
    resetList: PropTypes.func.isRequired,
};

export default DisableForUsersInput;
