import styled, { css } from 'styled-components/macro';

const Badge = styled.span`
    height: 24px;
    padding: 5px 7px;
    border-radius: 16px;
    white-space: nowrap;
    width: fit-content;

    ${({ theme }) => css`
        font-size: ${theme.fontSize.small};
    `};

    ${({ primary }) =>
        primary &&
        css`
            ${({ theme }) => css`
                background-color: ${theme.color.bgPrimaryLight};
                color: ${theme.color.primary}!important;
            `};
        `}

    ${({ secondary }) =>
        secondary &&
        css`
            ${({ theme }) => css`
                background-color: ${theme.color.bgSecondaryLight};
                color: ${theme.color.secondary}!important;
            `};
        `}

    ${({ tertiary }) =>
        tertiary &&
        css`
            ${({ theme }) => css`
                background-color: ${theme.color.bgTertiaryLight};
                color: ${theme.color.tertiary}!important;
            `};
        `}

    ${({ quaternary }) =>
        quaternary &&
        css`
            ${({ theme }) => css`
                background-color: ${theme.color.bgQuaternaryLight};
                color: ${theme.color.quaternary}!important;
            `};
        `}

    ${({ greyedOut }) =>
        greyedOut &&
        css`
            ${({ theme }) => css`
                background-color: ${theme.color.bgSearch};
                color: ${theme.color.textLight}!important;
            `};
        `}

    ${({ withMargin }) =>
        withMargin &&
        css`
            margin-right: 8px;
        `}

    ${({ customStyles }) =>
        css`
            ${customStyles}
        `}
`;

export default Badge;
