import styled, { css } from 'styled-components/macro';

export const IndicatorsContainer = styled.div`
    width: 100%;
    height: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: row nowrap;
    margin-top: 16px;
`;

export const Indicator = styled.div`
    width: 8px;
    height: 8px;
    border-radius: 50px;
    background-color: ${({ theme }) => theme.color.black};
    margin-left: 2px;
    margin-right: 2px;
    opacity: 0.2;

    ${({ current }) =>
        current &&
        css`
            background-color: ${({ theme }) => theme.color.white};
            opacity: 1;
        `};
`;
