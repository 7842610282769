import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import EmptyStateStats from 'components/EmptyState/StatsEmptyState/StatsEmptyState';
import { HA_ROUTES, ROUTES } from 'routing/constants';

import {
    StyledDataSection,
    StyledDataBox,
    Title,
    Grid,
    GridItem,
    Divider,
} from './Stats.style';

const Stats = ({ userStats, isAdmin }) => {
    if (!userStats) {
        return <EmptyStateStats />;
    }

    return (
      <StyledDataSection>
        <StyledDataBox>
          <Link to={isAdmin ? HA_ROUTES.smartTips : ROUTES.smartTips}>
            <Title>
              {isAdmin
                            ? 'Completed Smart Tips - all Users'
                            : 'Completed Smart Tips'}
            </Title>
            <Grid>
              <GridItem>
                <strong>
                  {
                                    userStats.smart_tips_completed_in_current_month
                                }
                </strong>
                <small>This month</small>
              </GridItem>

              <Divider />

              <GridItem>
                <strong>
                  {
                                    userStats.smart_tips_completed_in_previous_month
                                }
                </strong>
                <small>Last month</small>
              </GridItem>
            </Grid>
          </Link>
        </StyledDataBox>
        <StyledDataBox>
          <Link to={isAdmin ? HA_ROUTES.smartTips : ROUTES.smartTips}>
            <Title>
              {isAdmin
                            ? 'Active Smart Tips - all Users'
                            : 'Active Smart Tips'}
            </Title>
            <Grid>
              <GridItem>
                <strong>
                  {
                                    userStats.smart_tips_uncompleted_in_current_month
                                }
                </strong>
                <small>Current</small>
              </GridItem>
            </Grid>
          </Link>
        </StyledDataBox>
      </StyledDataSection>
    );
};

Stats.propTypes = {
    userStats: PropTypes.shape({
        smart_tips_completed_in_current_month: PropTypes.number,
        smart_tips_uncompleted_in_current_month: PropTypes.number,
        smart_tips_completed_in_previous_month: PropTypes.number,
        smart_tips_uncompleted_in_previous_month: PropTypes.number,
    }),
    isAdmin: PropTypes.bool.isRequired,
};

Stats.defaultProps = {
    userStats: undefined,
};

export default Stats;
