import styled, { css } from 'styled-components/macro';
import Button from 'components/Button';

export const ModalContentWrapper = styled.div`
    padding: 16px;
    display: flex;
    flex-direction: column;
`;

export const StyledButton = styled(Button)`
    margin-left: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    ${({ width }) => css`
        width: ${width};
    `}

    ${({ disabled }) =>
        disabled &&
        css`
            opacity: 0.5;
            cursor: inherit;
        `}
`;

export const customModalStyles = css`
    right: -360px;
    top: 50%;
    transform: translateX(16px);
    transform: translateY(-50%);
    max-width: 420px;

    &::before {
        content: '♦';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: calc(100% - 12px);
        color: ${({ theme }) => theme.color.white};
        font-size: 24px;
        z-index: 1005;

        @media (max-width: ${({ theme }) => `${theme.breakpoints.web}px`}) {
            transform: translateY(-50%);
            left: calc(100% - 12px);
        }
    }

    @media (max-width: ${({ theme }) => `${theme.breakpoints.web}px`}) {
        right: 110px;
    }

    @media (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
        position: fixed;
        width: calc(100vw - 16px);
        left: 8px;
        right: 8px;
    }
`;

export const ModalCustomBodyStyle = styled.div`
    padding: 15px 23px;
    display: flex;
    flex-flow: column;
`;
