import React, { useEffect, useState } from 'react';
import apiRoutes from 'config/apiRoutes';
import useFetch from 'hooks/useFetch';
import CreateNewUserHAView from 'views/CreateNewUserHA';
import { useDispatch, useSelector } from 'react-redux';
import {
    dispatchSuccessAlertThunk,
    dispatchErrorAlertThunk,
} from 'redux/actions/thunks/systemAlerts';
import { useHistory } from 'react-router-dom';
import { HA_ROUTES } from 'routing/constants';
import { useAuth0 } from '@auth0/auth0-react';

export const idize = (l) => l.id;

const CreateNewUserHA = () => {
    const { getAccessTokenSilently, logout } = useAuth0();
    const request = useFetch(getAccessTokenSilently, logout);
    const dispatch = useDispatch();
    const history = useHistory();

    const [firstName, setFirstName] = useState(undefined);
    const [lastName, setLastName] = useState(undefined);
    const [emailAddress, setEmailAddress] = useState(undefined);
    const [password, setPassword] = useState(undefined);
    const [classificationTags, setClassificationTags] = useState({
        role_job: [],
        department: [],
    });
    const [role, setRole] = useState(undefined);
    const [isRoleDropdownVisible, setIsRoleDropdownVisible] = useState(false);
    const [mnemonic, setMnemonic] = useState('');
    const [isLMSSelected, setIsLMSSelected] = useState(false);

    const { isLMSEnabled } = useSelector((state) => state.currentUser);

    useEffect(() => {
        if (isLMSEnabled) {
            setIsLMSSelected(true);
        }
    }, [isLMSEnabled]);

    const isFirstNameValid = () => {
        if (firstName !== undefined) {
            return /^.{3,20}$/.test(firstName);
        }

        return true;
    };

    const isLastNameValid = () => {
        if (lastName !== undefined) {
            return /^.{3,30}$/.test(lastName);
        }

        return true;
    };

    const isEmailAddressValid = () => {
        if (emailAddress !== undefined) {
            return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
                emailAddress
            );
        }

        return true;
    };

    const isMnemonicValid = () => {
        if (mnemonic !== '') {
            return /^.{3,35}$/.test(mnemonic);
        }

        return true;
    };

    const isButtonDisabled =
        firstName === undefined ||
        !isFirstNameValid() ||
        lastName === undefined ||
        !isLastNameValid() ||
        emailAddress === undefined ||
        !isEmailAddressValid() ||
        !isMnemonicValid() ||
        role === undefined;

    const createUser = () => {
        request(
            apiRoutes.post.createUser(),
            'POST',
            (res) => handleSuccess(res),
            (err) => handleFailure(err),
            {
                body: JSON.stringify({
                    user: {
                        email: emailAddress,
                        first_name: firstName,
                        last_name: lastName,
                        password,
                        role,
                        create_lms_user: isLMSSelected,
                        default_performance_id: mnemonic,
                        classification_tag_ids: [
                            ...classificationTags.role_job.map(idize),
                            ...classificationTags.department.map(idize),
                        ],
                    },
                }),
            },
            { 'Content-Type': 'application/json' }
        );
    };

    const handleSuccess = (res) => {
        if (!res.error) {
            dispatch(dispatchSuccessAlertThunk('createUser', 'User created'));
            history.push(HA_ROUTES.users);
        }
    };

    const handleFailure = (err) => {
        dispatch(dispatchErrorAlertThunk(err, err.error));
    };

    return (
        <CreateNewUserHAView
            firstName={firstName}
            setFirstName={setFirstName}
            isFirstNameValid={isFirstNameValid}
            lastName={lastName}
            setLastName={setLastName}
            password={password}
            setPassword={setPassword}
            isLastNameValid={isLastNameValid}
            emailAddress={emailAddress}
            setEmailAddress={setEmailAddress}
            isEmailAddressValid={isEmailAddressValid}
            role={role}
            setRole={setRole}
            setIsRoleDropdownVisible={setIsRoleDropdownVisible}
            isRoleDropdownVisible={isRoleDropdownVisible}
            mnemonic={mnemonic}
            setMnemonic={setMnemonic}
            isMnemonicValid={isMnemonicValid}
            isLMSSelected={isLMSSelected}
            setIsLMSSelected={setIsLMSSelected}
            createUser={createUser}
            isButtonDisabled={isButtonDisabled}
            isLMSEnabled={isLMSEnabled}
            setClassificationTags={setClassificationTags}
            classificationTags={classificationTags}
        />
    );
};

export default CreateNewUserHA;
