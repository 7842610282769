import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import { ReactComponent as CloseModalIcon } from 'assets/svg/close-modal.svg';
import { ReactComponent as ArrowLeft } from 'assets/svg/arrow-left.svg';
import { css } from 'styled-components/macro';
import theme from 'assets/theme/mainTheme';
import {
    TileBackground,
    Highlighter,
    TilePositioner,
    TileContainer,
    TileContent,
    TileButtons,
    CloseIconHolder,
    DirectionalArrow,
} from './OnboardingTile.style';

const OnboardingTile = ({
    children,
    onNext,
    onPrevious,
    onClose,
    positioning,
    buttonNextLabel,
    buttonPreviousLabel,
    compact,
    showArrow,
    highlightedElement,
    highlightedElementStyles,
    isCurrentStep,
}) => (
  <TileBackground isHidden={!isCurrentStep}>
    {highlightedElement && (
    <Highlighter highlighterStyles={highlightedElementStyles}>
      {highlightedElement()}
    </Highlighter>
        )}
    <TilePositioner positioning={positioning}>
      <TileContainer compact={compact}>
        <DirectionalArrow
                    topLeft={showArrow === 'topLeft'}
                    topRight={showArrow === 'topRight'}
                    bottom={showArrow === 'bottom'}
                />
        <CloseIconHolder>
          <CloseModalIcon onClick={onClose} />
        </CloseIconHolder>
        <TileContent compact={compact}>{children}</TileContent>
        <TileButtons compact={compact}>
          <Button onClick={onNext} primary>
            {buttonNextLabel}
          </Button>
          <Button
                        onClick={onPrevious}
                        transparent
                        borderless
                        customStyles={css`
                            color: ${theme.color.white};
                        `}
                    >
            {compact && <ArrowLeft fill={theme.color.white} />}
            {buttonPreviousLabel}
          </Button>
        </TileButtons>
      </TileContainer>
    </TilePositioner>
  </TileBackground>
);

OnboardingTile.propTypes = {
    children: PropTypes.node.isRequired,
    onNext: PropTypes.func.isRequired,
    onPrevious: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    positioning: PropTypes.oneOfType([PropTypes.func, PropTypes.any]),
    buttonNextLabel: PropTypes.string,
    buttonPreviousLabel: PropTypes.string,
    compact: PropTypes.bool,
    showArrow: PropTypes.oneOf(['topLeft', 'topRight', 'bottom', undefined]),
    highlightedElement: PropTypes.func,
    highlightedElementStyles: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.any,
    ]),
    isCurrentStep: PropTypes.bool.isRequired,
};

OnboardingTile.defaultProps = {
    positioning: undefined,
    buttonNextLabel: 'Next step',
    buttonPreviousLabel: 'Back',
    compact: false,
    showArrow: undefined,
    highlightedElement: undefined,
    highlightedElementStyles: undefined,
};

export default OnboardingTile;
