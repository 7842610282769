import styled from 'styled-components/macro';

export const SmartTipListItemContainer = styled.div`
    border-radius: 16px;
    display: flex;
    align-items: center;
    padding: 24px;
    border: 1px solid #ebf0fa;
    background: white;
`;

export const SmartTipHeadersContainer = styled.div`
    flex: 1;
    margin-left: 24px;
`;

export const SmartTipHeader = styled.p`
    margin: 0px;
    color: #718fbf;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 10px;
`;

export const SmartTipSubHeader = styled.p`
    margin: 0px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #343c6a;
`;

export const SmartTipFiltersContainer = styled.section`
    padding: 24px;
    background: #ffffff;
    border-radius: 16px;
    border: 1px solid #ebf0fa;
    margin-bottom: 24px;
`;

export const SmartFilterClearBtnContainer = styled.section`
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
`;

export const FilterLabel = styled.h4`
    font-weight: 600;
    font-size: 16px;
    color: #343c6a;
`;
export const SmartTipsItemsContainer = styled.div`
    display: grid;
    row-gap: 15px;
`;

export const SeparatorLine = styled.div`
    height: 1px;
    background: #ebf0fa;
    border: 0;
`;
