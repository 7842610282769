import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { removeAlert } from 'redux/actions/systemAlerts';
import SystemAlert from 'components/SystemAlert';
import AlertsDisplay from './SystemAlerts.style';

const SystemAlerts = () => {
    const dispatch = useDispatch();
    const { list } = useSelector((state) => state.systemAlerts);

    if (list.length === 0) {
        return null;
    }

    return (
      <AlertsDisplay>
        {list.map((alert) => (
          <SystemAlert
                    key={alert.id}
                    {...alert}
                    dismiss={() => dispatch(removeAlert({ id: alert.id }))}
                />
            ))}
      </AlertsDisplay>
    );
};

export default SystemAlerts;
