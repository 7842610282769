import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Badge from 'components/Badge';
import theme from 'assets/theme/mainTheme';
import { ReactComponent as EyeIcon } from 'assets/svg/eye-icon.svg';
import { ReactComponent as ThreeDotsIcon } from 'assets/svg/three-dots-icon.svg';
import { useHistory } from 'react-router-dom';
import ConfirmActionModal from 'components/ConfirmActionModal';
import { HA_ROUTES } from 'routing/constants';
import { useDispatch } from 'react-redux';
import { deleteOrganizationUserThunk } from 'redux/actions/thunks/organizationUsers';
import { useAuth0 } from '@auth0/auth0-react';
import {
    StyledTeamMember,
    StyledAvatar,
    StyledAvatarPlaceholder,
    StyledLabel,
    TeamsSection,
    UserRolesSection,
    TeamIcon,
    Tooltip,
    TeamIconWrapper,
    AvatarWrapper,
    ThreeDotsWrapper,
    Dropdown,
    IconWrapper,
    DropdownItem,
    Divider,
    UserTagLabel,
} from './TeamMember.style';

const TeamMemberHA = ({
    id,
    firstName,
    lastName,
    avatarUrl,
    backgroundColor,
    admin,
    email,
    teams,
    resetUsersList,
    departmentTags,
    roleJobTags,
}) => {
    const { getAccessTokenSilently, logout } = useAuth0();
    const dropdownRef = useRef();
    const dispatch = useDispatch();
    const history = useHistory();
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [isDeletePopupVisible, setIsDeletePopupVisible] = useState(false);

    const moreTeamNames = teams.slice(5, teams.length);

    const togglePopupVisible = () => {
        setIsDeletePopupVisible(!isDeletePopupVisible);
    };

    const redirectToUserDetails = () => history.push(`ha-users/${id}`);

    const deleteOrganizationUser = () =>
        dispatch(
            deleteOrganizationUserThunk(
                getAccessTokenSilently,
                logout,
                id,
                resetUsersList
            )
        );

    const handleClickOutside = (e) => {
        if (dropdownRef.current && dropdownRef.current.contains(e.target)) {
            return;
        }
        setIsDropdownVisible(false);
    };

    useEffect(() => {
        if (isDropdownVisible) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isDropdownVisible]);

    return (
        <StyledTeamMember
            data-testid={`${firstName}-${lastName}-test-member`}
            usersHa
        >
            {avatarUrl ? (
                <AvatarWrapper
                    inactive={admin}
                    onClick={!admin ? redirectToUserDetails : () => {}}
                >
                    <StyledAvatar
                        src={avatarUrl}
                        alt={`${firstName} ${lastName}`}
                    />
                </AvatarWrapper>
            ) : (
                <AvatarWrapper
                    inactive={admin}
                    onClick={!admin ? redirectToUserDetails : () => {}}
                >
                    <StyledAvatarPlaceholder color={backgroundColor}>
                        {firstName && firstName[0]}
                        {lastName && lastName[0]}
                    </StyledAvatarPlaceholder>
                </AvatarWrapper>
            )}
            <StyledLabel
                inactive={admin}
                onClick={!admin ? redirectToUserDetails : () => {}}
            >
                <strong>
                    {firstName} {lastName}
                </strong>
                <small>{email}</small>
            </StyledLabel>
            <TeamsSection>
                {teams.map((team, index) => {
                    if (index < 5) {
                        return (
                            <Tooltip key={team.id} isNotFirst={index !== 0}>
                                <TeamIconWrapper color={team.background_color}>
                                    <TeamIcon color={team.color} />
                                </TeamIconWrapper>
                                {team.creator ? (
                                    <div className="tooltipText">
                                        {`${team.name} (${team.creator})`}
                                    </div>
                                ) : (
                                    <div className="tooltipText">
                                        {team.name}
                                    </div>
                                )}
                            </Tooltip>
                        );
                    }

                    if (index === 5) {
                        return (
                            <Tooltip key={team.id} isNotFirst={index !== 0}>
                                <TeamIconWrapper color={theme.color.bgSearch}>
                                    {`+${teams.length - 5}`}
                                </TeamIconWrapper>
                                <div className="tooltipText">
                                    {moreTeamNames.map((item) =>
                                        item.creator ? (
                                            <div key={item.id}>
                                                {`${item.name} (${team.creator})`}
                                            </div>
                                        ) : (
                                            <div key={item.id}>{item.name}</div>
                                        )
                                    )}
                                </div>
                            </Tooltip>
                        );
                    }

                    return null;
                })}
            </TeamsSection>
            <UserRolesSection>
                {admin && (
                    <Badge withMargin quaternary>
                        Admin
                    </Badge>
                )}
            </UserRolesSection>

            <div>
                {departmentTags.map((gt) => (
                    <UserTagLabel>{gt.name}</UserTagLabel>
                ))}
            </div>

            <div>
                {roleJobTags.map((gt) => (
                    <UserTagLabel>{gt.name}</UserTagLabel>
                ))}
            </div>

            {admin ? (
                <IconWrapper inactive />
            ) : (
                <IconWrapper onClick={redirectToUserDetails}>
                    <EyeIcon fill={theme.color.textLight} />
                </IconWrapper>
            )}

            <ThreeDotsWrapper
                ref={dropdownRef}
                onClick={() => setIsDropdownVisible(!isDropdownVisible)}
                onBlurCapture={() => setIsDropdownVisible(false)}
            >
                <ThreeDotsIcon fill={theme.color.textLight} />
                {isDropdownVisible && (
                    <Dropdown>
                        <DropdownItem
                            onClick={() =>
                                history.push(`${HA_ROUTES.editUser}/${id}`)
                            }
                        >
                            Edit User
                        </DropdownItem>
                        <Divider />
                        <DropdownItem secondary onClick={togglePopupVisible}>
                            Delete User
                        </DropdownItem>
                    </Dropdown>
                )}
            </ThreeDotsWrapper>
            <ConfirmActionModal
                isVisible={isDeletePopupVisible}
                onToggle={togglePopupVisible}
                onConfirm={deleteOrganizationUser}
                description="Are you sure you want to delete this account?"
                confirmLabel="Confirm"
                irreversible
            />
        </StyledTeamMember>
    );
};

TeamMemberHA.propTypes = {
    id: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    avatarUrl: PropTypes.string || null,
    backgroundColor: PropTypes.string,
    admin: PropTypes.bool,
    email: PropTypes.string,
    teams: PropTypes.arrayOf(PropTypes.any),
    resetUsersList: PropTypes.func.isRequired,
    roleJobTags: PropTypes.arrayOf(PropTypes.any).isRequired,
    departmentTags: PropTypes.arrayOf(PropTypes.any).isRequired,
};

TeamMemberHA.defaultProps = {
    avatarUrl: null,
    backgroundColor: theme.color.bgPrimaryLight,
    admin: false,
    email: '',
    teams: [],
};

export default TeamMemberHA;
