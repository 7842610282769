import React, { useState } from 'react';
import apiRoutes from 'config/apiRoutes';
import useFetch from 'hooks/useFetch';
import MPAAddNewCustomerView from 'views/MPAAddNewCustomer';
import { Formik } from 'formik';
import * as Yup from 'yup';
import countryList from 'react-select-country-list';
import {
    dispatchSuccessAlertThunk,
    dispatchErrorAlertThunk,
} from 'redux/actions/thunks/systemAlerts';
import { useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { MPA_ROUTES } from 'routing/constants';

const formSchema = Yup.object().shape({
    organizationName: Yup.string()
        .min(2, 'Too short')
        .max(50, 'Too long value')
        .required('Required'),
    LMSApiKey: Yup.string()
        .min(2, 'Too short')
        .max(100, 'Too long value')
        .nullable(),
    LMSAddress: Yup.string()
        .min(2, 'Too short')
        .max(100, 'Too long value')
        .nullable(),
    use_auth0_connection: Yup.boolean(),
    connectionName: Yup.string()
        .min(
            1,
            'Auth0 Connection with this name does not exist. Please try again.'
        )
        .max(100, 'Too long value'),
    auth0OrganizationName: Yup.string()
        .min(2, 'Too short')
        .max(100, 'Too long value')
        .nullable(),
    country: Yup.string().nullable(),
    zipCode: Yup.number()
        .typeError('Invalid zip code')
        .min(501, 'Invalid zip code')
        .max(99950, 'Invalid zip code')
        .nullable(),
    city: Yup.string().min(2, 'Too short').max(50, 'Too long value').nullable(),
    state: Yup.string()
        .min(2, 'Too short')
        .max(50, 'Too long value')
        .nullable(),
    street: Yup.string()
        .min(2, 'Too short')
        .max(50, 'Too long value')
        .nullable(),
    fullName: Yup.string()
        .min(2, 'Too short')
        .max(50, 'Too long value')
        .nullable(),
    mobile: Yup.string()
        .min(2, 'Too short')
        .max(50, 'Too long value')
        .nullable(),
    emailAddress: Yup.string().email('Invalid email format').nullable(),
});

const initialData = {
    use_auth0_connection: false,
    organizationName: undefined,
    connectionName: undefined,
    auth0OrganizationName: undefined,
    LMSApiKey: undefined,
    LMSAddress: undefined,
    country: undefined,
    zipCode: undefined,
    city: undefined,
    state: undefined,
    street: undefined,
    fullName: undefined,
    mobile: undefined,
    emailAddress: undefined,
    lmsOrgId: null,
};

const MPAAddNewCustomer = () => {
    const { getAccessTokenSilently, logout } = useAuth0();
    const dispatch = useDispatch();
    const request = useFetch(getAccessTokenSilently, logout);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);

    const handleSubmitSuccess = (res) => {
        if (!res.error) {
            dispatch(
                dispatchSuccessAlertThunk(
                    'createOrganization',
                    'Organization created'
                )
            );
            document.location = MPA_ROUTES.customers;
        }
    };

    const handleSubmitError = (err) => {
        dispatch(dispatchErrorAlertThunk(err, err.error));
    };

    const handleSubmit = (values) => {
        const fd = new FormData();
        if (values.LMSAddress)
            fd.append('organization[api_url]', values.LMSAddress);
        if (values.connectionName)
            fd.append(
                'organization[auth0_connection_name]',
                values.connectionName
            );
        if (values.auth0OrganizationName || values.auth0OrganizationName === '')
            fd.append(
                'organization[auth0_organization_name]',
                values.auth0OrganizationName
            );
        if (values.LMSApiKey)
            fd.append('organization[api_key]', values.LMSApiKey);
        if (values.organizationName)
            fd.append('organization[name]', values.organizationName);
        if (values.city) fd.append('organization[city]', values.city);
        if (values.zipCode) fd.append('organization[zipcode]', values.zipCode);
        if (values.state) fd.append('organization[state]', values.state);
        if (values.country) fd.append('organization[country]', values.country);
        if (values.street) fd.append('organization[street]', values.street);
        if (values.fullName)
            fd.append('organization[contact_fullname]', values.fullName);
        if (values.mobile)
            fd.append('organization[contact_mobile]', values.mobile);
        if (values.emailAddress)
            fd.append('organization[contact_email]', values.emailAddress);
        if (values.lmsOrgId)
            fd.append('organization[lms_org_id]', values.lmsOrgId);

        request(
            apiRoutes.post.createCustomer(),
            'POST',
            handleSubmitSuccess,
            handleSubmitError,
            {
                body: fd,
            }
        );
    };

    const countries = countryList()
        .getData()
        .map((country) => ({
            id: `id_${country.value}`,
            title: country.label,
        }));

    return (
      <Formik
            initialValues={initialData}
            validationSchema={formSchema}
            onSubmit={handleSubmit}
            enableReinitialize
        >
        {(formikProps) => (
          <MPAAddNewCustomerView
                    countries={countries}
                    isDropdownVisible={isDropdownVisible}
                    setIsDropdownVisible={setIsDropdownVisible}
                    {...formikProps}
                />
            )}
      </Formik>
    );
};

export default MPAAddNewCustomer;
