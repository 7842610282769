export const ESTABLISH_METRIC_SOURCES = 'ESTABLISH_METRIC_SOURCES';
export const ESTABLISH_METRIC_SOURCES_SUCCESS =
    'ESTABLISH_METRIC_SOURCES_SUCCESS';
export const ESTABLISH_METRIC_SOURCES_FAILURE =
    'ESTABLISH_METRIC_SOURCES_FAILURE';
export const UPDATE_METRIC_SOURCE = 'UPDATE_METRIC_SOURCE';
export const UPDATE_METRIC_SOURCE_SUCCESS = 'UPDATE_METRIC_SOURCE_SUCCESS';
export const UPDATE_METRIC_SOURCE_FAILURE = 'UPDATE_METRIC_SOURCE_FAILURE';

export const establishMetricSources = () => ({
    type: ESTABLISH_METRIC_SOURCES,
});

export const establishMetricSourcesSuccess = (payload) => ({
    type: ESTABLISH_METRIC_SOURCES_SUCCESS,
    payload,
});

export const establishMetricSourcesFailure = (err) => ({
    type: ESTABLISH_METRIC_SOURCES_FAILURE,
    err,
});

export const updateMetricSource = () => ({
    type: UPDATE_METRIC_SOURCE,
});

export const updateMetricSourceSuccess = (payload) => ({
    type: UPDATE_METRIC_SOURCE_SUCCESS,
    payload,
});

export const updateMetricSourceFailure = (err) => ({
    type: UPDATE_METRIC_SOURCE_FAILURE,
    err,
});
