import {
    establishUsersUploads,
    establishUsersUploadsSuccess,
    establishUsersUploadsFailure,
    loadMoreUsersUploads,
    allUsersUploadsLoaded,
    resetUsersUploads,
    uploadUsers,
    uploadUsersFailure,
    uploadUsersSuccess,
} from 'redux/actions/usersUploads';
import apiRoutes from 'config/apiRoutes';
import {
    dispatchErrorAlertThunk,
    dispatchSuccessAlertThunk,
} from './systemAlerts';

export const establishUsersUploadsThunk = (
    getAccessTokenSilently,
    logout,
    limit,
    offset
) => (dispatch) => {
    dispatch(establishUsersUploads());

    return getAccessTokenSilently()
        .then((token) => {
            fetch(apiRoutes.get.usersUploads(limit, offset), {
                method: 'GET',
                headers: {
                    organization_slug: window.localStorage.getItem(
                        'MP_ORGANIZATION'
                    ),
                    Authorization: `Token token="${token}"`,
                },
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.error) {
                        return dispatch(
                            establishUsersUploadsFailure(res.error)
                        );
                    }

                    if (res.user_uploads.length === 0) {
                        return dispatch(allUsersUploadsLoaded());
                    }

                    return dispatch(
                        establishUsersUploadsSuccess(res.user_uploads)
                    );
                })
                .catch((err) =>
                    dispatch(
                        establishUsersUploadsFailure({
                            err,
                        })
                    )
                );
        })
        .catch(() => logout({ returnTo: window.location.origin }));
};

export const loadMoreUsersUploadsThunk = (
    getAccessTokenSilently,
    logout,
    limit,
    offset
) => (dispatch) => {
    dispatch(establishUsersUploads());

    const url = apiRoutes.get.usersUploads(limit, offset);

    return getAccessTokenSilently()
        .then((token) => {
            fetch(url, {
                method: 'GET',
                headers: {
                    organization_slug: window.localStorage.getItem(
                        'MP_ORGANIZATION'
                    ),
                    Authorization: `Token token="${token}"`,
                },
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.error) {
                        return dispatch(
                            establishUsersUploadsFailure(res.error)
                        );
                    }

                    if (res.user_uploads.length === 0) {
                        return dispatch(allUsersUploadsLoaded());
                    }

                    return dispatch(loadMoreUsersUploads(res.user_uploads));
                })
                .catch((err) =>
                    dispatch(
                        establishUsersUploadsFailure({
                            err,
                        })
                    )
                );
        })
        .catch(() => logout({ returnTo: window.location.origin }));
};

export const resetUsersUploadsThunk = () => (dispatch) => {
    dispatch(resetUsersUploads());
};

export const uploadUsersThunk = (
    getAccessTokenSilently,
    logout,
    file,
    strategy
) => (dispatch) => {
    dispatch(uploadUsers());

    const formData = new FormData();
    formData.append('file', file);
    formData.append('strategy', strategy);

    return getAccessTokenSilently()
        .then((token) => {
            fetch(apiRoutes.post.uploadUsers(), {
                method: 'POST',
                headers: {
                    organization_slug: window.localStorage.getItem(
                        'MP_ORGANIZATION'
                    ),
                    Authorization: `Token token="${token}"`,
                },
                body: formData,
            })
                .then((res) => {
                    if (res.error || +res.status >= 300) {
                        dispatch(
                            dispatchErrorAlertThunk('usersUpload', res.error)
                        );
                        return dispatch(uploadUsersFailure(res.error));
                    }

                    dispatch(
                        dispatchSuccessAlertThunk(
                            'usersUpload',
                            'Uploaded users'
                        )
                    );
                    dispatch(
                        establishUsersUploadsThunk(
                            getAccessTokenSilently,
                            logout,
                            20,
                            0
                        )
                    );
                    return dispatch(uploadUsersSuccess(res.status));
                })
                .catch((err) => {
                    dispatch(dispatchErrorAlertThunk('usersUpload', err.error));
                    dispatch(
                        uploadUsersFailure({
                            err,
                        })
                    );
                });
        })
        .catch(() => logout({ returnTo: window.location.origin }));
};
