import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UserSettings from 'views/UserSettings';
import {
    fetchRemindersThunk,
    addReminderThunk,
    editReminderThunk,
    deleteReminderThunk,
} from 'redux/actions/thunks/reminders';
import { setSelectedDate, clearSelectedDate } from 'redux/actions/remindersAll';
import { useAuth0 } from '@auth0/auth0-react';
import establishSmartTipsThunk, {
    resetSmartTipsThunk,
} from 'redux/actions/thunks/smartTips';

const UserSettingsContainer = () => {
    const { getAccessTokenSilently, logout } = useAuth0();
    const dispatch = useDispatch();
    const { reminders, areRemindersFetching, selectedDate } = useSelector(
        (state) => state.reminders
    );
    const {
        smartTipsToTW,
        smartTipsEssential,
        smartTips,
        smartTipsArchived,
        smartTipsEssentialArchived,
    } = useSelector((state) => state.smartTips);
    const {
        userName,
        userFirstName,
        userLastName,
        userEmail,
        userAvatarUrl,
        userTeams,
        isUserFetching,
    } = useSelector((state) => state.currentUser);

    const [isDaySelected, setIsDaySelected] = useState(false);
    const [displayedReminders, setDisplayedReminders] = useState([]);
    const [isNewReminderModalOpened, setIsNewReminderModalOpened] = useState(
        false
    );
    const [isEditReminderModalOpened, setIsEditReminderModalOpened] = useState(
        false
    );

    const handleSetSelectedDate = (data) => {
        dispatch(setSelectedDate(data));
    };

    const closeModal = (e) => {
        if (
            e.target.id === 'globalModalBg' ||
            e.target.id === 'closeReminderModalButton'
        ) {
            return setIsNewReminderModalOpened(false);
        }

        return null;
    };

    const filterRemindersByMonth = () =>
        reminders.filter(
            (reminder) =>
                reminder.dateTimeObject.getMonth() ===
                    selectedDate.getMonth() &&
                reminder.dateTimeObject.getFullYear() ===
                    selectedDate.getFullYear()
        );

    const isReminderIncluded = (reminderDate) =>
        reminderDate.getFullYear() === selectedDate.getFullYear() &&
        reminderDate.getMonth() === selectedDate.getMonth() &&
        reminderDate.getDate() === selectedDate.getDate();

    const addReminder = (data) => {
        dispatch(addReminderThunk(getAccessTokenSilently, logout, data, false));
        setIsNewReminderModalOpened(false);
    };

    const editReminder = (id, data) => {
        dispatch(editReminderThunk(getAccessTokenSilently, logout, id, data));
        setIsEditReminderModalOpened(false);
    };

    const resetReminders = () => {
        setIsDaySelected(false);
        dispatch(clearSelectedDate());
        dispatch(fetchRemindersThunk(getAccessTokenSilently, logout));
    };

    const deleteReminder = (id) =>
        dispatch(deleteReminderThunk(getAccessTokenSilently, logout, id));

    useEffect(() => {
        dispatch(fetchRemindersThunk(getAccessTokenSilently, logout));
    }, []);

    useEffect(() => {
        setDisplayedReminders(reminders);
    }, [reminders]);

    useEffect(() => {
        if (selectedDate) {
            setDisplayedReminders(
                reminders.filter((reminder) =>
                    isReminderIncluded(reminder.dateTimeObject)
                )
            );
        }
    }, [isDaySelected, selectedDate]);

    useEffect(() => {
        dispatch(establishSmartTipsThunk(getAccessTokenSilently, logout));

        return () => {
            dispatch(resetSmartTipsThunk());
        };
    }, []);

    return (
      <UserSettings
            selectedDate={selectedDate}
            userName={userName}
            userFirstName={userFirstName}
            userLastName={userLastName}
            userEmail={userEmail}
            userTeams={userTeams}
            userAvatarUrl={userAvatarUrl}
            isUserFetching={isUserFetching}
            displayedReminders={displayedReminders}
            allReminders={filterRemindersByMonth()}
            areRemindersFetching={areRemindersFetching}
            smartTips={[
                ...smartTipsToTW,
                ...smartTipsEssential,
                ...smartTips,
                ...smartTipsEssentialArchived,
                ...smartTipsArchived,
            ]}
            isDaySelected={isDaySelected}
            setIsDaySelected={setIsDaySelected}
            isEditReminderModalOpened={isEditReminderModalOpened}
            setIsEditReminderModalOpened={setIsEditReminderModalOpened}
            isNewReminderModalOpened={isNewReminderModalOpened}
            setIsNewReminderModalOpened={setIsNewReminderModalOpened}
            closeModal={closeModal}
            addReminder={addReminder}
            editReminder={editReminder}
            resetReminders={resetReminders}
            deleteReminder={deleteReminder}
            setSelectedDate={handleSetSelectedDate}
        />
    );
};

export default UserSettingsContainer;
