import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Spinner from 'components/Spinner';
import SectionHeader from 'components/SectionHeader';
import SmartTipCTA from 'components/SmartTipCTA';
import SmartTipFeedback from 'components/SmartTipFeedback';
import SmartTipDescription from 'components/SmartTipDescription';
import { ReactComponent as ArrowBackIcon } from 'assets/svg/arrow-back.svg';
import theme from 'assets/theme/mainTheme';
import NoResults from 'components/EmptyState/NoResults';
import {
    StyledSmartTipView,
    StyledPlayer,
    Divider,
    NoResultsWrapper,
} from './SmartTipsDetailsView.style';

const SmartTipDetailsView = ({
    name,
    source,
    description,
    isFetching,
    onUpdate,
    onSubmitFeedback,
    handleVideoError,
    isVideoError,
    evaluation,
    serveysEnabled,
}) => {
    const [isCompleted, setIsCompleted] = useState(false);

    if (isFetching) {
        return <Spinner />;
    }

    return (
        <StyledSmartTipView>
            <SectionHeader
                title={name}
                goBack={{
                    label() {
                        return (
                            <>
                                <ArrowBackIcon stroke={theme.color.textLabel} />{' '}
                                <span>Back</span>
                            </>
                        );
                    },
                }}
                cta={() => <SmartTipCTA />}
            />
            {source && source.id && !isVideoError ? (
                <StyledPlayer
                    responsive
                    video={source.id}
                    showPortrait={false}
                    showTitle={false}
                    showByline={false}
                    onEnd={() => {
                        setIsCompleted(true);
                        onUpdate({
                            status: 'archived',
                        });
                    }}
                    onError={handleVideoError}
                />
            ) : (
                <NoResultsWrapper>
                    <NoResults
                        reload
                        type="oops"
                        message="There is something wrong with video player. Please come back later or contact the Administrator."
                    />
                </NoResultsWrapper>
            )}
            <SmartTipDescription description={description} />

            {description && serveysEnabled && <Divider />}

            {serveysEnabled && (
                <SmartTipFeedback
                    isCompleted={isCompleted}
                    onSubmit={onSubmitFeedback}
                    evaluation={evaluation}
                />
            )}
        </StyledSmartTipView>
    );
};

SmartTipDetailsView.propTypes = {
    onUpdate: PropTypes.func.isRequired,
    onSubmitFeedback: PropTypes.func.isRequired,
    isFetching: PropTypes.bool.isRequired,
    name: PropTypes.string,
    description: PropTypes.string,
    source: PropTypes.objectOf(PropTypes.any),
    handleVideoError: PropTypes.func.isRequired,
    isVideoError: PropTypes.bool.isRequired,
    evaluation: PropTypes.number,
    serveysEnabled: PropTypes.bool,
};

SmartTipDetailsView.defaultProps = {
    name: '',
    description: undefined,
    source: undefined,
    evaluation: undefined,
    serveysEnabled: true,
};

export default SmartTipDetailsView;
