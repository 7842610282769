import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import SectionHeader from 'components/SectionHeader';
import UsersFilters from 'containers/UsersFilters';
import TeamMember from 'components/TeamMember/TeamMemberHA';
import Spinner from 'components/Spinner';
import { ReactComponent as DropdownArrow } from 'assets/svg/dropdown-down.svg';
import Button from 'components/Button';
import ManageUserIdsModal from 'containers/ManageUserIdsModal';
import CSVUsersImport from 'containers/CSVUsersImport/CSVUsersImport';
import AdminUsersUploadComponent from 'containers/AdminUsersUploadModal/AdminUsersUploadModal';
import Pagination from 'components/Pagination';
import UsersEmptyState from 'components/EmptyState/UsersEmptyState';
import theme from 'assets/theme/mainTheme';
import { HA_ROUTES } from 'routing/constants';
import Hr from 'components/Hr';
import {
    TeamMemberContainer,
    StyledUsersContainer,
    HeaderCTA,
    Text,
    NewUserWrapper,
    NewUserDropdown,
    SpinnerWrapper,
    UsersWrapper,
    AdminSection,
} from './UsersHA.style';
import { UPLOAD_USER_STRATEGIES } from '../../containers/UsersHA/constants';

const UsersHA = ({
    currentPage,
    setCurrentPage,
    pages,
    users,
    hasLoaded,
    isFetching,
    nextPage,
    prevPage,
    isNextEnabled,
    isPrevEnabled,
    filters,
    isAddNewUserDropdownVisible,
    setIsAddNewUserDropdownVisible,
    downloadTemplate,
    isManageIdsModalVisible,
    toggleManageIdsModalVisible,
    importStrategyUpload,
    setImportStrategyUpload,
    resetUsersList,
    downloadUsersResultsCSV,
}) => {
    const history = useHistory();

    if (!hasLoaded) {
        return <Spinner data-testid="spinner" />;
    }

    return (
        <StyledUsersContainer>
            <SectionHeader
                title="Users"
                collapseOnMobile
                cta={() => (
                    <HeaderCTA>
                        <NewUserWrapper>
                            <Button
                                primary
                                borderless
                                onClick={downloadTemplate}
                            >
                                Download CSV template
                            </Button>
                        </NewUserWrapper>
                        <NewUserWrapper>
                            <Button
                                primaryDark
                                svg
                                onClick={() =>
                                    setIsAddNewUserDropdownVisible(
                                        !isAddNewUserDropdownVisible
                                    )
                                }
                            >
                                Users
                                <DropdownArrow
                                    fill={theme.color.bgPrimaryLight}
                                />
                            </Button>
                            {isAddNewUserDropdownVisible && (
                                <NewUserDropdown>
                                    <Button
                                        transparent
                                        borderless
                                        onClick={() =>
                                            history.push(
                                                HA_ROUTES.createNewUser
                                            )
                                        }
                                        customStyles={{
                                            color: theme.color.primary,
                                        }}
                                    >
                                        Add using form
                                    </Button>
                                    <Button
                                        transparent
                                        borderless
                                        customStyles={{
                                            color: theme.color.primary,
                                        }}
                                        onClick={() =>
                                            setImportStrategyUpload(
                                                UPLOAD_USER_STRATEGIES.IMPORT_NEW_ONES
                                            )
                                        }
                                    >
                                        Create using CSV
                                    </Button>
                                    <Button
                                        transparent
                                        borderless
                                        customStyles={{
                                            color: theme.color.primary,
                                        }}
                                        onClick={() =>
                                            setImportStrategyUpload(
                                                UPLOAD_USER_STRATEGIES.UPDATE_EXISTING_ONES
                                            )
                                        }
                                    >
                                        Update using CSV
                                    </Button>
                                    <Button
                                        primary
                                        transparent
                                        borderless
                                        onClick={downloadUsersResultsCSV}
                                    >
                                        Export to CSV
                                    </Button>
                                    <Hr />
                                    <Button
                                        transparent
                                        borderless
                                        customStyles={{
                                            color: theme.color.primary,
                                        }}
                                        onClick={toggleManageIdsModalVisible}
                                    >
                                        Manage IDs
                                    </Button>
                                </NewUserDropdown>
                            )}
                        </NewUserWrapper>
                    </HeaderCTA>
                )}
            />

            <UsersFilters {...filters} />

            <UsersWrapper>
                {' '}
                <TeamMemberContainer>
                    <Text />
                    <Text>Name</Text>
                    <Text>Team</Text>
                    <Text>User Type</Text>
                    <Text>Department</Text>
                    <Text>Role/Job</Text>
                </TeamMemberContainer>
                {isFetching ? (
                    <SpinnerWrapper>
                        <Spinner />
                    </SpinnerWrapper>
                ) : (
                    users.map((member) => (
                        <TeamMember
                            id={member.id}
                            key={member.id}
                            firstName={member.first_name}
                            lastName={member.last_name}
                            avatarUrl={member.avatar_url}
                            email={member.email}
                            admin={member.admin}
                            teams={member.teams}
                            roleJobTags={member.classification_tags.role_job}
                            departmentTags={
                                member.classification_tags.department
                            }
                            resetUsersList={resetUsersList}
                        />
                    ))
                )}
                {users.length === 0 && (
                    <UsersEmptyState description="Users not found." />
                )}
                <Pagination
                    pages={pages}
                    currentPage={currentPage}
                    handleNextPage={nextPage}
                    handlePrevPage={prevPage}
                    setCurrentPage={setCurrentPage}
                    isPrevEnabled={isPrevEnabled}
                    isNextEnabled={isNextEnabled}
                />
            </UsersWrapper>

            <AdminSection>
                <CSVUsersImport
                    isImportModalVisible={importStrategyUpload !== null}
                />
            </AdminSection>

            {isManageIdsModalVisible && (
                <ManageUserIdsModal
                    toggleVisibility={toggleManageIdsModalVisible}
                />
            )}

            {importStrategyUpload !== null && (
                <AdminUsersUploadComponent
                    strategy={importStrategyUpload}
                    setIsVisible={() => {
                        setImportStrategyUpload(null);
                    }}
                />
            )}
        </StyledUsersContainer>
    );
};

UsersHA.propTypes = {
    currentPage: PropTypes.number.isRequired,
    setCurrentPage: PropTypes.func.isRequired,
    pages: PropTypes.arrayOf(PropTypes.any).isRequired,
    isAddNewUserDropdownVisible: PropTypes.bool.isRequired,
    setIsAddNewUserDropdownVisible: PropTypes.func.isRequired,
    users: PropTypes.arrayOf(PropTypes.any).isRequired,
    hasLoaded: PropTypes.bool.isRequired,
    isFetching: PropTypes.bool.isRequired,
    nextPage: PropTypes.func.isRequired,
    prevPage: PropTypes.func.isRequired,
    isNextEnabled: PropTypes.func.isRequired,
    isPrevEnabled: PropTypes.func.isRequired,
    filters: PropTypes.shape({
        role: {
            value: PropTypes.string,
            setFilter: PropTypes.func,
        },
        team: {
            value: PropTypes.string,
            setFilter: PropTypes.func,
        },
        name: {
            value: PropTypes.string,
            setFilter: PropTypes.func,
        },
    }).isRequired,
    downloadTemplate: PropTypes.func.isRequired,
    downloadUsersResultsCSV: PropTypes.func.isRequired,
    isManageIdsModalVisible: PropTypes.bool.isRequired,
    toggleManageIdsModalVisible: PropTypes.func.isRequired,
    resetUsersList: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    importStrategyUpload: PropTypes.any.isRequired,
    setImportStrategyUpload: PropTypes.func.isRequired,
    isResourcesDropdownVisible: PropTypes.bool.isRequired,
    setResourcesDropdownVisible: PropTypes.func.isRequired,
};

export default UsersHA;
