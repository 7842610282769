import styled from 'styled-components/macro';

export const AssignModalBodyHolder = styled.div`
    height: 220px;
    padding: 16px;
`;

export const DropdownOption = styled.div`
    display: flex;
    align-items: center;

    img {
        margin-right: 16px;
        width: 32px;
        height: 32px;
        border-radius: 50%;
    }
`;

export const DropdownOptionTexts = styled.div`
    display: flex;
    flex-direction: column;

    small {
        color: ${({ theme }) => theme.color.textLight};
    }
`;

export const SelectedTipHolder = styled.div`
    margin-top: 24px;
    height: 125px;
    overflow-y: auto;
`;

export const SelectedTip = styled.div`
    display: flex;
    align-items: center;
    height: 40px;
    width: 100%;
    border-radius: ${({ theme }) => theme.border.radiusBase};
    padding-left: 8px;
    padding-right: 8px;

    .removeIcon {
        margin-left: auto;
        display: none;
        cursor: pointer;
    }

    span {
        margin: 8px;
    }

    svg {
        margin: 8px;
    }

    &:hover {
        background-color: ${({ theme }) => theme.color.bgPrimaryLight};

        .removeIcon {
            display: inline;
        }
    }
`;
