import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as CloseIcon } from 'assets/svg/close-modal.svg';
import {
    ModalHeader,
    ModalHolder,
    ModalBody,
    ModalFooter,
    ModalWithBgWrapper,
    ModalBg,
} from './Modal.style';

const Modal = ({
    closeModal,
    children,
    headerText,
    absolute,
    fixed,
    noBg,
    isCloseIcon,
    closeByIconClick,
    customStyles,
    modalFooter,
    noPadding,
    bodyOverflow,
    noOverflow,
    ...rest
}) => {
    if (noBg) {
        return (
          <ModalHolder customStyles={customStyles} fixed absolute {...rest}>
            <ModalHeader>
              <span>{headerText}</span>
              {isCloseIcon && (
                <CloseIcon onClick={() => closeByIconClick()} />
                    )}
            </ModalHeader>
            <ModalBody noPadding noOverflow={noOverflow}>
              {children}
            </ModalBody>
            {modalFooter && <ModalFooter>{modalFooter()}</ModalFooter>}
          </ModalHolder>
        );
    }

    return (
      <ModalWithBgWrapper>
        <ModalBg onClick={(e) => closeModal(e)} id="globalModalBg" />
        <ModalHolder id="modalHolder" customStyles={customStyles} {...rest}>
          {headerText && (
            <ModalHeader>
              <span>{headerText}</span>
              {' '}
              {isCloseIcon && (
              <CloseIcon onClick={() => closeByIconClick()} />
                        )}
            </ModalHeader>
                )}
          <ModalBody noPadding bodyOverflow noOverflow={noOverflow}>
            {children}
          </ModalBody>
          {modalFooter && <ModalFooter>{modalFooter()}</ModalFooter>}
        </ModalHolder>
      </ModalWithBgWrapper>
    );
};

Modal.propTypes = {
    headerText: PropTypes.string,
    closeModal: PropTypes.func.isRequired,
    closeByIconClick: PropTypes.func,
    absolute: PropTypes.bool,
    fixed: PropTypes.bool,
    noBg: PropTypes.bool,
    bodyOverflow: PropTypes.bool,
    isCloseIcon: PropTypes.bool,
    noPadding: PropTypes.bool,
    noOverflow: PropTypes.bool,
    customStyles: PropTypes.any || null,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    modalFooter: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
        PropTypes.string,
        PropTypes.any,
    ]),
};

Modal.defaultProps = {
    headerText: '',
    children: <></>,
    modalFooter: undefined,
    customStyles: null,
    absolute: false,
    fixed: false,
    noBg: false,
    isCloseIcon: false,
    noPadding: false,
    noOverflow: false,
    bodyOverflow: false,
    closeByIconClick: () => {},
};

export default Modal;
