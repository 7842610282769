import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import DropdownSelectCheckbox from 'components/DropdownSelectCheckbox';
import { ReactComponent as DropdownIcon } from 'assets/svg/dropdown-down.svg';
import theme from 'assets/theme/mainTheme';
import {
    DropdownSelectWrapper,
    StyledInputWrapper,
} from './AddTeamMembers.style';

const AddTeamMembers = ({
    fetchUsers,
    users,
    updateTeam,
    hasMoreUsers,
    filterUsers,
    resetUsersList,
    loadMoreUsers,
}) => {
    const [isVisible, setIsVisible] = useState(false);
    const dropdownRef = useRef(null);

    const toggleVisibility = () => setIsVisible(!isVisible);

    useEffect(() => {
        if (isVisible) {
            fetchUsers();
        }
    }, [isVisible]);

    const handleClickOutside = (e) => {
        if (dropdownRef.current && dropdownRef.current.contains(e.target)) {
            return;
        }
        toggleVisibility();
    };

    useEffect(() => {
        if (isVisible) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isVisible]);

    return (
      <DropdownSelectWrapper ref={dropdownRef}>
        <Button primaryDark onClick={toggleVisibility}>
          Add User 
          {' '}
          <DropdownIcon fill={theme.color.white} />
        </Button>
        {isVisible && (
        <StyledInputWrapper>
          <DropdownSelectCheckbox
                        type="user"
                        items={users}
                        loadMore={loadMoreUsers}
                        hasMore={hasMoreUsers}
                        onSearch={filterUsers}
                        resetItemsList={resetUsersList}
                        onSubmit={updateTeam}
                        onClose={() => setIsVisible(false)}
                        confirmButtonLabel="Add"
                        placeholder="Invite Users"
                    />
        </StyledInputWrapper>
            )}
      </DropdownSelectWrapper>
    );
};

AddTeamMembers.propTypes = {
    fetchUsers: PropTypes.func.isRequired,
    users: PropTypes.arrayOf(PropTypes.any).isRequired,
    updateTeam: PropTypes.func.isRequired,
    hasMoreUsers: PropTypes.bool.isRequired,
    filterUsers: PropTypes.func.isRequired,
    resetUsersList: PropTypes.func.isRequired,
    loadMoreUsers: PropTypes.func.isRequired,
};

export default AddTeamMembers;
