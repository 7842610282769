import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import UserSettings from 'containers/UserSettings';
import EditUserProfile from 'containers/EditUserProfile';
import DashboardMain from 'containers/DashboardMain';
import Onboarding from 'containers/Onboarding';
import Courses from 'containers/Courses';
import Performance from 'containers/Performance';
import TeamOverview from 'containers/TeamOverview';
import TeamRanking from 'containers/TeamRanking';
import SmartTips from 'containers/SmartTipsPage';
import SmartTipDetails from 'containers/SmartTipDetails';
import Team from 'containers/Team';
import CreateTeamForm from 'containers/CreateTeamForm';
import TeamMembers from 'containers/TeamMembers';

import DashboardMainHA from 'views/DashboardMain/DashboardMainHA';
import SmartTipsHA from 'containers/SmartTipsHA';
import TeamHA from 'containers/Team/TeamHA';
import UsersHA from 'containers/UsersHA';
import CreateSmartTip from 'containers/CreateSmartTip';
import EditSmartTip from 'containers/EditSmartTip';
import DataSourcesMetrics from 'containers/DataSourcesMetrics';
import TipOfTheWeek from 'containers/TipOfTheWeek';
import UsersSatisfaction from 'containers/UsersSatisfaction';
import AdminMemos from 'containers/AdminMemos';
import Notifications from 'containers/Notifications';
import CreateNewUserHA from 'containers/CreateNewUserHA';
import MPACustomers from 'containers/MPACustomers';
import MPAEditCustomer from 'containers/MPAEditCustomer';
import MPAAdminMemos from 'containers/MPAAdminMemos';
import MPAAddNewCustomer from 'containers/MPAAddNewCustomer';
import MPASuperAdminUsers from 'containers/MPASuperAdminUsers';
import MPACustomerDetails from 'containers/MPACustomerDetails';
import MPAAddSuperAdminUser from 'containers/MPAAddSuperAdminUser';
import UserDetailsHA from 'containers/UserDetailsHA';
import MPASuperAdminUserDetails from 'containers/MPASuperAdminUserDetails';
import MPAEditSuperAdminUser from 'containers/MPAEditSuperAdminUser';
import MPADataSourcesMeasures from 'containers/MPADataSourcesMeasures';
import PageNotFound from 'containers/PageNotFound';
import HAEditUser from 'containers/HAEditUser';

import FAQ from 'views/FAQ';

import useAdmin from 'hooks/useAdmin';
import useMPA from 'hooks/useMPA';
import ProtectedRoute from './ProtectedRoute';
import { HA_ROUTES, MPA_ROUTES, ROUTES } from './constants';
import { UserClassifications } from '../containers/UserClassifications';
import { BrowseAvailableSmartTipsForProvider } from '../containers/BrowseAvailableSmartTipsForProvider/BrowseAvailableSmartTipsForProvider';

const Routes = () => {
    const isAdmin = useAdmin();
    const { isCurrentUserMPA } = useMPA();

    return (
      <Switch>
        <ProtectedRoute
                exact
                path={ROUTES.base}
                component={
                    isAdmin && isCurrentUserMPA
                        ? () => <Redirect to={MPA_ROUTES.customers} />
                        : () => <Redirect to={ROUTES.dashboard} />
                }
            />
        <ProtectedRoute
                exact
                path={ROUTES.dashboard}
                component={isAdmin ? DashboardMainHA : DashboardMain}
            />
        <ProtectedRoute
                exact
                path={ROUTES.onboarding}
                component={isAdmin ? Onboarding : Onboarding}
            />
        <ProtectedRoute
                exact
                path={ROUTES.courses}
                component={
                    isAdmin ? () => <Redirect to={ROUTES.dashboard} /> : Courses
                }
            />

        <ProtectedRoute
                exact
                path={`${ROUTES.smartTips}/:smartTipId`}
                component={isAdmin ? SmartTipDetails : SmartTipDetails}
            />

        <ProtectedRoute
                exact
                path={ROUTES.smartTips}
                component={
                    isAdmin
                        ? () => <Redirect to={HA_ROUTES.smartTips} />
                        : SmartTips
                }
            />

        <ProtectedRoute
                exact
                path={ROUTES.user}
                component={() => <Redirect to={ROUTES.userSettings} />}
            />
        <ProtectedRoute
                exact
                path={ROUTES.userSettings}
                component={UserSettings}
            />
        <ProtectedRoute
                exact
                path={ROUTES.editUserProfile}
                component={isAdmin ? EditUserProfile : EditUserProfile}
            />

        <ProtectedRoute
                exact
                path={ROUTES.performance}
                component={isAdmin ? Performance : Performance}
            />

        <ProtectedRoute
                exact
                path={ROUTES.team}
                component={isAdmin ? TeamHA : Team}
            />
        <ProtectedRoute
                exact
                path={ROUTES.createTeam}
                component={isAdmin ? CreateTeamForm : CreateTeamForm}
            />
        <ProtectedRoute
                exact
                path={`${ROUTES.teamOverview}/:teamId`}
                component={isAdmin ? TeamOverview : TeamOverview}
            />
        <ProtectedRoute
                exact
                path={`${ROUTES.teamMembers}/:teamId`}
                component={isAdmin ? TeamMembers : TeamMembers}
            />
        <ProtectedRoute
                exact
                path={`${ROUTES.teamRanking}/:teamId`}
                component={isAdmin ? TeamRanking : TeamRanking}
            />

        <ProtectedRoute
                exact
                path={ROUTES.faq}
                component={isAdmin ? FAQ : FAQ}
            />
        <ProtectedRoute exact path="/faq" component={FAQ} />

        <ProtectedRoute
                exact
                path={ROUTES.notifications}
                component={isAdmin ? Notifications : Notifications}
            />

        {/* HOSPITAL ADMIN ROUTING */}
        <ProtectedRoute
                exact
                path={HA_ROUTES.smartTips}
                component={SmartTipsHA}
            />

        <ProtectedRoute
                exact
                path={HA_ROUTES.createSmartTip}
                adminOnly
                component={CreateSmartTip}
            />

        <ProtectedRoute
                exact
                path={`${HA_ROUTES.editSmartTip}/:smartTipId`}
                adminOnly
                component={EditSmartTip}
            />

        <ProtectedRoute
                exact
                path={`${HA_ROUTES.users}/:userId`}
                adminOnly
                component={UserDetailsHA}
            />

        <ProtectedRoute
                exact
                path={HA_ROUTES.users}
                adminOnly
                component={UsersHA}
            />

        <ProtectedRoute
                exact
                path={`${HA_ROUTES.users}/:userId`}
                adminOnly
                component={UserDetailsHA}
            />

        <ProtectedRoute
                exact
                path={HA_ROUTES.createNewUser}
                adminOnly
                component={CreateNewUserHA}
            />

        <ProtectedRoute
                exact
                path={`${HA_ROUTES.editUser}/:userId`}
                adminOnly
                component={HAEditUser}
            />

        <ProtectedRoute
                exact
                path={HA_ROUTES.adminMemos}
                adminOnly
                component={AdminMemos}
            />

        <ProtectedRoute
                exact
                path={HA_ROUTES.dataSourcesMetrics}
                adminOnly
                component={DataSourcesMetrics}
            />

        <ProtectedRoute
                exact
                path={HA_ROUTES.tipOfTheWeek}
                adminOnly
                component={TipOfTheWeek}
            />

        <ProtectedRoute
                exact
                path={HA_ROUTES.usersSatisfaction}
                adminOnly
                component={UsersSatisfaction}
            />

        {/* MED POWER ADMIN ROUTING */}
        <ProtectedRoute
                exact
                path={MPA_ROUTES.customers}
                mpaOnly
                component={MPACustomers}
            />

        <ProtectedRoute
                exact
                path={`${MPA_ROUTES.customers}/:customerId`}
                mpaOnly
                component={MPACustomerDetails}
            />

        <ProtectedRoute
                exact
                path={MPA_ROUTES.addCustomer}
                mpaOnly
                component={MPAAddNewCustomer}
            />

        <ProtectedRoute
                exact
                path={HA_ROUTES.userClassification}
                // mpaOnly
                adminOnly
                component={UserClassifications}
            />

        <ProtectedRoute
                exact
                path="/ha-create-new-user"
                adminOnly
                component={CreateNewUserHA}
            />

        <ProtectedRoute
                exact
                path={`${MPA_ROUTES.editCustomer}/:customerId`}
                mpaOnly
                component={MPAEditCustomer}
            />

        <ProtectedRoute
                exact
                path={MPA_ROUTES.dataSourcesMeasures}
                mpaOnly
                component={() => <MPADataSourcesMeasures />}
            />

        <ProtectedRoute
                exact
                path={MPA_ROUTES.superAdminUsers}
                mpaOnly
                component={MPASuperAdminUsers}
            />

        <ProtectedRoute
                exact
                path={`${MPA_ROUTES.superAdminUsers}/:userId`}
                mpaOnly
                component={MPASuperAdminUserDetails}
            />

        <ProtectedRoute
                exact
                path={MPA_ROUTES.addSuperAdminUser}
                mpaOnly
                component={MPAAddSuperAdminUser}
            />

        <ProtectedRoute
                exact
                path={`${MPA_ROUTES.editSuperAdminUser}/:userId`}
                mpaOnly
                component={MPAEditSuperAdminUser}
            />

        <ProtectedRoute
                exact
                path={MPA_ROUTES.adminMemos}
                mpaOnly
                component={MPAAdminMemos}
            />

        <ProtectedRoute
                exact
                path={MPA_ROUTES.userSettings}
                mpaOnly
                component={UserSettings}
            />

        <ProtectedRoute
                exact
                path={MPA_ROUTES.editUserProfile}
                mpaOnly
                component={EditUserProfile}
            />

        <ProtectedRoute
                exact
                path={ROUTES.browseSmartTips}
                component={BrowseAvailableSmartTipsForProvider}
            />

        {/* NOT FOUND */}
        <ProtectedRoute path="*" component={PageNotFound} />
      </Switch>
    );
};

export default Routes;
