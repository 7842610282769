import {
    getTenantOrganization,
    getTenantOrganizationSuccess,
    getTenantOrganizationFailure,
} from 'redux/actions/tenantOrganization';
import apiRoutes from 'config/apiRoutes';

const getTenantOrganizationThunk = (getAccessTokenSilently, logout) => (
    dispatch
) => {
    dispatch(getTenantOrganization());

    getAccessTokenSilently()
        .then((token) => {
            fetch(apiRoutes.get.organizationInfo, {
                method: 'GET',
                headers: {
                    organization_slug: window.localStorage.getItem(
                        'MP_ORGANIZATION'
                    ),
                    Authorization: `Token token="${token}"`,
                },
            })
                .then((res) =>
                    res.status === 401
                        ? logout({ returnTo: window.location.origin })
                        : res
                )
                .then((res) => res.json())
                .then((res) => {
                    if (res.error) {
                        return () => {};
                    }
                    return dispatch(
                        getTenantOrganizationSuccess({
                            name: res.name,
                            slug: res.slug,
                            isLmsEnabled: res.is_lms_enabled,
                            performanceCoaching: res.performance_coaching,
                            performanceCoachingRequestsCount:
                                res.performance_coaching_requests_count,
                            createdAt: res.created_at,
                            updatedAt: res.updated_at,
                        })
                    );
                })
                .catch((err) =>
                    dispatch(
                        getTenantOrganizationFailure({
                            err,
                        })
                    )
                );
        })
        .catch(() => {
            logout({ returnTo: window.location.origin });
        });
};

export default getTenantOrganizationThunk;
