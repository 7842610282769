import styled, { css } from 'styled-components/macro';

export const FormWrapper = styled.div`
    background-color: ${({ theme }) => theme.color.white};
    border: ${({ theme }) => theme.border.base};
    border-radius: ${({ theme }) => theme.border.radiusBase};
`;

export const Section = styled.div`
    padding: 28px;

    ${({ grid }) =>
        grid &&
        css`
            max-width: 40%;
            display: grid;
            grid-gap: 16px;

            @media (max-width: ${({ theme }) =>
                    `${theme.breakpoints.small}px`}) {
                max-width: 100%;
            }
        `}

    ${({ borderTop }) =>
        borderTop &&
        css`
            border-top: 1px solid;
            border-color: ${({ theme }) => theme.color.borderColorBase};
        `}
`;

export const Title = styled.div`
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: ${({ theme }) => theme.color.textLight};
    display: flex;
    align-items: center;

    svg {
        margin-right: 16px;
    }
`;

export const InputWrapper = styled.div`
    max-width: 50%;
`;

export const CheckboxWrapper = styled.div`
    height: 54px;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const Text = styled.div`
    margin-left: 16px;
    font-weight: 600;

    ${({ disabled }) =>
        disabled &&
        css`
            opacity: 0.5;
        `}
`;

export const Role = styled.div`
    display: flex;
    align-items: center;
    width: 172px;
    height: 54px;
    padding: 16px;
    border: ${({ theme }) => theme.border.base};
    border-radius: ${({ theme }) => theme.border.radiusSmall};
    cursor: pointer;

    > svg {
        position: absolute;
        right: 21px;
    }
`;
export const RoleDropdownWrapper = styled.div`
    position: relative;
`;

export const Dropdown = styled.div`
    z-index: 1;
    display: flex;
    flex-direction: column;
    width: 172px;
    position: absolute;
    border-radius: ${({ theme }) => theme.border.radiusBase};
    background-color: ${({ theme }) => theme.color.white};
    box-shadow: ${({ theme }) => theme.boxShadow.base};
    top: 54px;
    left: 0px;
`;

export const DropdownItem = styled.div`
    margin: 0 16px 16px 16px;
    padding: 16px;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    color: ${({ theme }) => theme.color.primary};
    border-radius: ${({ theme }) => theme.border.radiusSmall};
    z-index: 1;
    white-space: nowrap;

    &:first-child {
        margin: 16px;
    }

    ${({ isSelected }) =>
        isSelected &&
        css`
            color: ${({ theme }) => theme.color.white};
            background-color: ${({ theme }) => theme.color.bgPrimary};
        `}

    &:hover {
        cursor: pointer;
        color: ${({ theme }) => theme.color.primary};
        background-color: ${({ theme }) => theme.color.bgPrimaryLight};
    }
`;
