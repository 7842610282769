import styled from 'styled-components/macro';

export const StyledUsersContainer = styled.div`
    width: 100%;
    padding: 0 16px;
`;

export const TeamMemberContainer = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 6fr 4fr 4fr 4fr 4fr 1fr 1fr;
    grid-gap: 8px;
    padding: 16px 24px;
`;

export const Text = styled.div`
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    color: ${({ theme }) => theme.color.textLight};
`;

export const HeaderCTA = styled.div`
    display: flex;

    @media (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
        flex-wrap: wrap;
        justify-content: flex-end;
        width: calc(100vw - 48px);
        > * {
            margin: 8px 0;

            button {
                width: 195px;
            }
        }
    }
`;

export const UsersWrapper = styled.div`
    @media (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
        overflow-x: auto;

        > * {
            width: fit-content;
            min-width: 750px;
        }
    }
`;

export const NewUserWrapper = styled.div`
    position: relative;
    &:first-child {
        margin-right: 16px;
    }
`;

export const NewUserDropdown = styled.div`
    z-index: 1;
    position: absolute;
    top: 49px;
    right: 0px;
    width: 200px;
    min-height: 140px;
    box-shadow: ${({ theme }) => theme.boxShadow.base};
    background-color: ${({ theme }) => theme.color.white};
    border-radius: ${({ theme }) => theme.border.radiusBase};
    border: ${({ theme }) => theme.border.base};
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
`;

export const SpinnerWrapper = styled.div`
    width: 100%;
    height: 784px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const AdminSection = styled.div`
    width: 100%;
`;
