import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { css } from 'styled-components/macro';
import Input from 'components/Input';
import Modal from 'components/Modal';
import TimePicker from 'components/TimePicker';
import DatePicker from 'components/DatePicker';
import reactDatepickerWrapper from 'assets/styles/datepickerWrapper.style';
import { getHoursFromTime, validateTime } from 'redux/helpers/reminders';
// import { useAuth0 } from '@auth0/auth0-react';
// import { useDispatch } from 'react-redux';
// import establishSmartTipsThunk from 'redux/actions/thunks/smartTips';
import StyledButton, { ModalCustomBodyStyle } from './AddReminderModal.style';

const AddReminderModal = ({
    closeModal,
    addReminder,
    smartTips,
    reminderId,
    congratulationsView,
    ...rest
}) => {
    // const { getAccessTokenSilently, logout } = useAuth0();
    // const dispatch = useDispatch();
    const getValueIfIdProvided = (id, tips) => {
        if (id) {
            const foundTip = tips.find((tip) => tip.id === id);

            if (foundTip) {
                return foundTip.title;
            }
        }

        return '';
    };
    const [showCongratulation, setShowCongratulations] = useState(false);
    const [relatableId, setRelatableId] = useState(reminderId || null);
    const [searchDropdownValue, setSearchDropdownValue] = useState(
        getValueIfIdProvided(reminderId, smartTips)
    );
    const [searchDropdownVisible, setSearchDropdownVisible] = useState(false);

    const [actionDatetime, setActionDatetime] = useState(null);
    const [actionTime, setActionTime] = useState('12:30 PM');
    const [actionDate, setActionDate] = useState(new Date());
    const [relatableType, setRelatableType] = useState('smart_tip');
    const [newReminderBody, setNewReminderBody] = useState(null);

    useEffect(() => {
        if (searchDropdownValue.length > 0 && !searchDropdownVisible) {
            setSearchDropdownVisible(true);
        } else if (!searchDropdownValue.length && searchDropdownVisible) {
            setSearchDropdownVisible(false);
        }
    }, [searchDropdownValue]);

    useEffect(() => {
        if (relatableId && searchDropdownValue === '') {
            const foundTip = smartTips.find((tip) => tip.id === relatableId);

            if (foundTip) {
                setSearchDropdownValue(foundTip.title);
                setSearchDropdownVisible(false);
            }
        }
        if (relatableId) {
            setSearchDropdownVisible(false);
        }
    }, [relatableId]);

    useEffect(() => {
        const dateTime = actionDate;

        dateTime.setHours(getHoursFromTime(actionTime));
        dateTime.setMinutes(
            actionTime.slice(
                actionTime.indexOf(':') + 1,
                actionTime.indexOf(':') + 3
            )
        );

        setActionDatetime(dateTime);
    }, [actionTime, actionDate]);

    useEffect(() => {
        if (relatableId && relatableType && actionDatetime) {
            setNewReminderBody(createNewReminderBody());
        }
    }, [relatableId, relatableType, actionDatetime]);

    // const refreshSmartTips = () => {
    //     dispatch(establishSmartTipsThunk(getAccessTokenSilently, logout));
    // };

    const handleSmartTipSelect = (id) => {
        const foundTip = smartTips.find((tip) => id.includes(tip.id));

        if (foundTip) {
            setSearchDropdownValue(foundTip.title);
        }
        setRelatableId(id);
        setRelatableType('smart_tip');
        setSearchDropdownVisible(false);
    };

    const filterDropdownOptions = () =>
        smartTips
            .filter((tip) =>
                tip.title
                    .toLowerCase()
                    .includes(searchDropdownValue.toLowerCase())
            )
            .map((tip) => ({ ...tip, id: `${tip.id}` }));

    const createNewReminderBody = () => ({
        reminder: {
            relatable_id: relatableId,
            relatable_type: 'smart_tip',
            action_datetime: actionDatetime,
        },
    });

    const handleTime = (val) => {
        if (validateTime(val)) {
            setActionTime(val);
        }
    };

    if (showCongratulation && congratulationsView !== null) {
        return (
          <Modal
                closeModal={closeModal}
                headerText="Add new reminder"
                {...rest}
            >
            {congratulationsView()}
          </Modal>
        );
    }

    return (
      <Modal closeModal={closeModal} headerText="Add new reminder" {...rest}>
        <ModalCustomBodyStyle>
          <Input
                    value={searchDropdownValue}
                    onChange={({ target }) =>
                        setSearchDropdownValue(target.value)}
                    variant="searchDropdown"
                    placeholder="Search Smart Tips"
                    dropdownTitle="Smart Tips"
                    dropdownVisible={searchDropdownVisible}
                    handleSelect={handleSmartTipSelect}
                    onFocus={() => setSearchDropdownVisible(true)}
                    onBlur={() => setSearchDropdownVisible(false)}
                    options={filterDropdownOptions()}
                    containerStyles={css`
                        width: 100%;
                    `}
                />
          <Input variant="group">
            <TimePicker time={actionTime} setTime={handleTime} />
          </Input>
          <Input
                    variant="group"
                    noflex
                    customStyles={reactDatepickerWrapper}
                >
            <DatePicker date={actionDate} setNewDate={setActionDate} />
          </Input>
          <Input variant="group" pullright>
            <StyledButton
                        primary
                        onClick={closeModal}
                        id="closeReminderModalButton"
                    >
              Cancel
            </StyledButton>
            <StyledButton
                        disabled={newReminderBody === null}
                        primaryDark
                        onClick={() => {
                            if (newReminderBody) {
                                addReminder(newReminderBody);
                                if (congratulationsView !== null) {
                                    setShowCongratulations(true);
                                }
                            }
                        }}
                    >
              Save
            </StyledButton>
          </Input>
        </ModalCustomBodyStyle>
      </Modal>
    );
};

AddReminderModal.propTypes = {
    closeModal: PropTypes.func.isRequired,
    addReminder: PropTypes.func.isRequired,
    congratulationsView: PropTypes.func,
    reminderId: PropTypes.string,
    smartTips: PropTypes.arrayOf(PropTypes.any).isRequired,
};

AddReminderModal.defaultProps = {
    reminderId: null,
    congratulationsView: null,
};

export default AddReminderModal;
