import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SmartTipsView from 'views/SmartTips/SmartTips';
import establishSmartTipsThunk, {
    resetSmartTipsThunk,
} from 'redux/actions/thunks/smartTips';
import { useAuth0 } from '@auth0/auth0-react';
import { addReminderThunk } from 'redux/actions/thunks/reminders';

const SmartTipsPageContainer = () => {
    const { getAccessTokenSilently, logout } = useAuth0();
    const dispatch = useDispatch();
    const {
        areSmartTipsEstablished,
        areSmartTipsFetching,
        smartTipsToTW,
        smartTipsEssential,
        smartTips,
        smartTipsArchived,
        smartTipsEssentialArchived,
    } = useSelector((state) => state.smartTips);

    const [
        addReminderModalOpenedById,
        setAddReminderModalOpenedById,
    ] = useState(null); // reminderId || null

    const handleModalClose = (e) => {
        if (e.target.id) {
            setAddReminderModalOpenedById(null);
        }
    };

    const addReminder = (data) => {
        dispatch(addReminderThunk(getAccessTokenSilently, logout, data, false));
    };

    useEffect(() => {
        dispatch(establishSmartTipsThunk(getAccessTokenSilently, logout));

        return () => {
            dispatch(resetSmartTipsThunk());
        };
    }, []);

    return (
      <SmartTipsView
            isFetching={!areSmartTipsEstablished && areSmartTipsFetching}
            smartTips={smartTips.filter(
                (tip) => !smartTipsEssential.find((eTip) => eTip.id === tip)
            )}
            smartTipsEssential={smartTipsEssential}
            smartTipsToTW={smartTipsToTW}
            smartTipsArchived={smartTipsArchived}
            smartTipsEssentialArchived={smartTipsEssentialArchived}
            addReminderModalOpenedById={addReminderModalOpenedById}
            setAddReminderModalOpenedById={setAddReminderModalOpenedById}
            handleModalClose={handleModalClose}
            addReminder={addReminder}
        />
    );
};

export default SmartTipsPageContainer;
