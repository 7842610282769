import styled, { css } from 'styled-components/macro';

export const FormWrapper = styled.div`
    background-color: ${({ theme }) => theme.color.white};
    border: ${({ theme }) => theme.border.base};
    border-radius: ${({ theme }) => theme.border.radiusBase};
`;

export const Section = styled.div`
    padding: 28px;

    ${({ grid }) =>
        grid &&
        css`
            max-width: 40%;
            display: grid;
            grid-gap: 16px;
        `}

    ${({ borderTop }) =>
        borderTop &&
        css`
            border-top: 1px solid;
            border-color: ${({ theme }) => theme.color.borderColorBase};
        `}
`;

export const Title = styled.div`
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    color: ${({ theme }) => theme.color.textLight};

    display: flex;
    align-items: center;

    svg {
        margin-right: 16px;
    }
`;

export const InputWrapper = styled.div`
    max-width: 50%;
`;

export const Role = styled.div`
    display: flex;
    align-items: center;
    width: 172px;
    height: 54px;
    padding: 16px;
    border: ${({ theme }) => theme.border.base};
    border-radius: ${({ theme }) => theme.border.radiusSmall};
`;
