import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/Modal';
import {
    StyledModal,
    StyledRobotIcon,
    StyledCloseIcon,
    StyledTextfield,
    StyledSurveyButton,
} from './ReviewModal.style';

const ReviewModal = ({
    closeModal,
    cancelFeedback,
    onSubmit,
    review,
    noOverflow,
}) => {
    const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);

    return (
      <Modal closeModal={closeModal} bodyOverflow noOverflow={noOverflow}>
        <StyledModal autoMargin={feedbackSubmitted}>
          <StyledRobotIcon />
          <h3>
            {feedbackSubmitted
                        ? 'Feedback submitted'
                        : 'Please tell us more!'}
          </h3>
          <p>
            {feedbackSubmitted
                        ? 'Thank you for sharing your opinion!'
                        : 'Please let us know where we came up short this time around.'}
          </p>
          {feedbackSubmitted ? (
            <StyledSurveyButton primary onClick={() => closeModal()}>
              Close
            </StyledSurveyButton>
                ) : (
                  <>
                    <StyledTextfield
                            placeholder="Type your review here (optional)"
                            onChange={({ target }) =>
                                review.setFunc(target.value)}
                        />
                    <StyledSurveyButton
                            primary
                            onClick={() => {
                                onSubmit();
                                setFeedbackSubmitted(true);
                            }}
                        >
                      {review.value ? 'Submit review' : 'Skip'}
                    </StyledSurveyButton>
                  </>
                )}

          <StyledCloseIcon
                    onClick={() => {
                        cancelFeedback();
                        closeModal();
                    }}
                />
        </StyledModal>
      </Modal>
    );
};

ReviewModal.propTypes = {
    closeModal: PropTypes.func.isRequired,
    cancelFeedback: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    noOverflow: PropTypes.bool,
    review: PropTypes.shape({
        value: PropTypes.string,
        setFunc: PropTypes.func,
    }).isRequired,
};

ReviewModal.defaultProps = {
    noOverflow: false,
};

export default ReviewModal;
