import {
    ESTABLISH_TEAMS_RANKING,
    ESTABLISH_TEAMS_RANKING_SUCCESS,
    ESTABLISH_TEAMS_RANKING_FAILURE,
} from 'redux/actions/teamRanking';

export const initialState = {
    isTeamsRankingEstablished: false,
    isTeamsRankingError: false,
    isTeamsRankingFetching: false,
    teams: [],
    error: null,
};

const teamRanking = (state = initialState, action) => {
    switch (action.type) {
        case ESTABLISH_TEAMS_RANKING:
            return {
                ...state,
                isTeamsRankingFetching: true,
                isTeamsRankingError: false,
            };

        case ESTABLISH_TEAMS_RANKING_SUCCESS:
            return {
                ...state,
                isTeamsRankingFetching: false,
                isTeamsRankingEstablished: true,
                teams: action.payload,
            };

        case ESTABLISH_TEAMS_RANKING_FAILURE:
            return {
                ...state,
                isTeamsRankingFetching: false,
                isTeamsRankingError: true,
                error: action.err,
            };

        default:
            return state;
    }
};

export default teamRanking;
