import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Input from 'components/Input';
import { BodyHeader } from './ResultsModalBody.style';

import {
    generateNameDropdownOptions,
    generateMetricsDropdownOptions,
} from './ResultsModalBody.helper';

const ResultsHeader = ({
    areUsersFetching,
    areSearchMetricsFetching,
    searchOrganizationUsers,
    searchMetrics,
    foundUsers,
    foundMetrics,
    selectedDataSource,
    filterMetrics,
    selectedMetrics,
    setSelectedMetrics,
    selectedNames,
    setSelectedNames,
}) => {
    const [metricDropdownVisible, setMetricDropdownVisible] = useState(false);
    const [metricDropdownValue, setMetricDropdownValue] = useState('');
    const [metricDropdownPlaceholder, setMetricDropdownPlaceholder] = useState(
        'Metric name'
    );
    const [metricDropdownOptions, setMetricDropdownOptions] = useState([]);

    const [nameDropdownPlaceholder, setNameDropdownPlaceholder] = useState(
        'Name'
    );
    const [nameDropdownVisible, setNameDropdownVisible] = useState(false);
    const [nameDropdownValue, setNameDropdownValue] = useState('');
    const [nameDropdownOptions, setNameDropdownOptions] = useState([]);

    useEffect(() => {
        setMetricDropdownPlaceholder('Metric name');
        setNameDropdownPlaceholder('Name');
    }, [selectedDataSource]);

    useEffect(() => {
        setNameDropdownOptions(
            generateNameDropdownOptions(foundUsers, selectedNames)
        );
    }, [foundUsers]);

    useEffect(() => {
        setMetricDropdownOptions(
            generateMetricsDropdownOptions(foundMetrics, selectedMetrics)
        );
    }, [foundMetrics]);

    const handleSelectName = (id) => {
        if (selectedNames.includes(id)) {
            setSelectedNames(
                selectedNames.filter((selectedId) => selectedId !== id)
            );
        } else {
            setSelectedNames([...selectedNames, id]);
        }

        setNameDropdownOptions(
            nameDropdownOptions.map((name) => {
                if (name.id === id) {
                    return {
                        ...name,
                        selected: !name.selected,
                    };
                }

                return name;
            })
        );
    };

    const handleSelectMetric = (id) => {
        if (selectedMetrics.includes(id)) {
            setSelectedMetrics(
                selectedMetrics.filter((selectedId) => selectedId !== id)
            );
        } else {
            setSelectedMetrics([...selectedMetrics, id]);
        }

        setMetricDropdownOptions(
            metricDropdownOptions.map((metric) => {
                if (metric.id === id) {
                    return {
                        ...metric,
                        selected: !metric.selected,
                    };
                }

                return metric;
            })
        );
    };

    return (
      <BodyHeader>
        <div className="dropdownHolder">
          <small>Metric name</small>
          <Input
                    variant="searchDropdownSelectApi"
                    placeholder={metricDropdownPlaceholder}
                    onClick={() => {
                        if (!metricDropdownVisible) {
                            searchMetrics(
                                selectedDataSource.id,
                                '',
                                10,
                                0,
                                selectedNames
                            );
                        }
                        setMetricDropdownVisible(!metricDropdownVisible);
                    }}
                    onChange={(e) => {
                        setMetricDropdownValue(e.target.value);
                        searchMetrics(
                            selectedDataSource.id,
                            e.target.value,
                            10,
                            0,
                            selectedNames
                        );
                    }}
                    value={metricDropdownValue}
                    dropdownVisible={metricDropdownVisible}
                    selectOptions={metricDropdownOptions}
                    handleSelect={(id) => handleSelectMetric(id)}
                    handleDropdownSelectSubmit={() => {
                        setMetricDropdownVisible(false);
                        setMetricDropdownValue('');
                        setMetricDropdownPlaceholder(
                            `Selected ${selectedMetrics.length} metrics`
                        );
                        filterMetrics(selectedMetrics);
                    }}
                    handleDropdownSelectCancel={() => {
                        setMetricDropdownVisible(false);
                        setMetricDropdownValue('');
                        setSelectedMetrics([]);
                        setMetricDropdownPlaceholder('Metric name');
                    }}
                    isLoadingOptions={areSearchMetricsFetching}
                    selectButtonLabel="Select"
                    cancelButtonLabel="Clear all"
                />
        </div>
        <div className="dropdownHolder">
          <small>Provider name</small>
          <Input
                    variant="searchDropdownSelectApi"
                    placeholder={nameDropdownPlaceholder}
                    onClick={() => {
                        if (!nameDropdownVisible) {
                            searchOrganizationUsers('', 10, 0);
                        }
                        setNameDropdownVisible(!nameDropdownVisible);
                    }}
                    onChange={(e) => {
                        searchOrganizationUsers(e.target.value, 10, 0);
                        setNameDropdownValue(e.target.value);
                    }}
                    value={nameDropdownValue}
                    dropdownVisible={nameDropdownVisible}
                    selectOptions={nameDropdownOptions}
                    handleSelect={(id) => handleSelectName(id)}
                    handleDropdownSelectSubmit={() => {
                        setNameDropdownVisible(false);
                        setNameDropdownValue('');
                        setNameDropdownPlaceholder(
                            `Selected ${selectedNames.length} Users`
                        );
                    }}
                    handleDropdownSelectCancel={() => {
                        setNameDropdownVisible(false);
                        setNameDropdownValue('');
                        setSelectedNames([]);
                        setNameDropdownPlaceholder('User Name');
                    }}
                    isLoadingOptions={areUsersFetching}
                    selectButtonLabel="Select"
                    cancelButtonLabel="Clear all"
                />
        </div>
      </BodyHeader>
    );
};

ResultsHeader.propTypes = {
    areUsersFetching: PropTypes.bool.isRequired,
    areSearchMetricsFetching: PropTypes.bool.isRequired,
    searchOrganizationUsers: PropTypes.func.isRequired,
    searchMetrics: PropTypes.func.isRequired,
    filterMetrics: PropTypes.func.isRequired,
    foundUsers: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
        })
    ).isRequired,
    foundMetrics: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
        })
    ).isRequired,
    selectedDataSource: PropTypes.shape({ id: PropTypes.string || null })
        .isRequired,

    selectedMetrics: PropTypes.arrayOf(PropTypes.any).isRequired,
    setSelectedMetrics: PropTypes.func.isRequired,
    selectedNames: PropTypes.arrayOf(PropTypes.any).isRequired,
    setSelectedNames: PropTypes.func.isRequired,
};

export default ResultsHeader;
