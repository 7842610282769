export const apiUrl = process.env.REACT_APP_API_URL;
const queryString = require('qs');

const apiRoutes = {
    get: {
        courses: (limit, offset) =>
            limit && offset !== undefined
                ? `${apiUrl}/courses?limit=${limit}&offset=${offset}`
                : `${apiUrl}/courses`,
        reminders: `${apiUrl}/reminders`,
        smartTips: (limit, offset) =>
            limit && offset !== undefined
                ? `${apiUrl}/smart_tips/?exclude_status=archived&limit=${limit}&offset=${offset}`
                : `${apiUrl}/smart_tips/?exclude_status=archived`,
        allSmartTips: (limit, offset) =>
            limit && offset !== undefined
                ? `${apiUrl}/smart_tips/?limit=${limit}&offset=${offset}`
                : `${apiUrl}/smart_tips`,
        browseSmartTips: (params) =>
            `${apiUrl}/smart_tips?${queryString.stringify(params, {
                arrayFormat: 'brackets',
            })}`,
        smartTipsEssential: `${apiUrl}/smart_tips/?exclude_status=archived&type=essential_tip`,
        smartTipsToTW: `${apiUrl}/smart_tips/?type=tip_of_the_week`,
        smartTipsArchived: `${apiUrl}/smart_tips/?status=archived`,
        smartTipsArchivedEssential: `${apiUrl}/smart_tips/?status=archived&type=essential_tip`,
        smartTipDetails: (smartTipId) => `${apiUrl}/smart_tips/${smartTipId}`,
        establishOrganizationSmartTipDetails: (smartTipId) =>
            `${apiUrl}/organization/smart_tips/${smartTipId}`,
        smartTipsRecentlyVisited: `${apiUrl}/organization/smart_tips/recently_visited`,
        teams: (limit, offset) =>
            limit && offset !== undefined
                ? `${apiUrl}/teams?limit=${limit}&offset=${offset}`
                : `${apiUrl}/teams`,
        teamDetails: (teamId) => `${apiUrl}/teams/${teamId}`,
        teamMembers: (teamId) => `${apiUrl}/teams/${teamId}/members`,
        teamStats: (teamId) => `${apiUrl}/teams/${teamId}/stats`,
        teamRecentlyShared: (teamId) =>
            `${apiUrl}/teams/${teamId}/recent_shared`,
        teamMessages: (teamId, limit, offset) => {
            if (teamId && limit && offset) {
                return `${apiUrl}/teams/${teamId}/messages?limit=${limit}&offset=${offset}`;
            }

            if (offset && !limit) {
                return `${apiUrl}/teams/${teamId}/messages?offset=${offset}`;
            }
            if (!offset && limit) {
                return `${apiUrl}/teams/${teamId}/messages?limit=${limit}`;
            }

            return `${apiUrl}/teams/${teamId}/messages`;
        },

        userProfile: `${apiUrl}/profile`,
        userProfileStats: `${apiUrl}/profile/stats`,
        organizationInfo: `${apiUrl}/tenant/organization`,
        users: (limit, offset) =>
            limit && offset !== undefined
                ? `${apiUrl}/users?limit=${limit}&offset=${offset}`
                : `${apiUrl}/users`,
        usersRecentlyVisited: `${apiUrl}/organization/users/recently_visited`,
        teamRanking: `${apiUrl}/teams/ranking`,
        providerMetricSources: `${apiUrl}/metric_sources`,
        organizationMetricSourcesForSingleUser: `${apiUrl}/metric_sources?enabled=1`,
        metricSourceId: (sourceId) => `${apiUrl}/metric_sources/${sourceId}`,
        organizationMetricSourceId: (sourceId) =>
            `${apiUrl}/organization/metric_sources/${sourceId}`,
        organizationMetricSourceIdForSingleUser: (userId, sourceId) =>
            `${apiUrl}/organization/users/${userId}/metric_sources/${sourceId}`,
        metricsBySourceId: (sourceId) =>
            `${apiUrl}/metric_sources/${sourceId}/metrics`,
        organizationAssets: `${apiUrl}/organization/assets`,
        organizationUsers: (limit, offset, teamId) =>
            `${apiUrl}/organization/users?limit=${limit}&offset=${offset}&teamid=${teamId}`,
        organizationUsersCSVExport: (params = {}) =>
            `${apiUrl}/organization/users.csv?${params}`,
        organizationUsersSearch: (searchString, limit, offset) =>
            `${apiUrl}/organization/users?limit=${limit}&offset=${offset}&search=${searchString}`,
        organizationUserDetails: (userId) =>
            `${apiUrl}/organization/users/${userId}`,
        organizationUserStats: (userId) =>
            `${apiUrl}/organization/users/${userId}/stats`,
        organizationUserSmartTipsList: (
            userId,
            searchString,
            limit = undefined,
            offset = undefined
        ) =>
            `${apiUrl}/organization/users/${userId}/smart_tips?search=${searchString}${
                limit !== undefined ? `&limit=${limit}` : ''
            }${offset !== undefined ? `&offset=${offset}` : ''}`,
        organizationSmartTips: (limit, offset) =>
            limit && offset !== undefined
                ? `${apiUrl}/organization/smart_tips?limit=${limit}&offset=${offset}`
                : `${apiUrl}/organization/smart_tips`,
        organizationSmartTipDetails: (id) =>
            `${apiUrl}/organization/smart_tips/${id}`,
        organizationTeams: (limit, offset) =>
            limit && offset !== undefined
                ? `${apiUrl}/organization/teams?limit=${limit}&offset=${offset}`
                : `${apiUrl}/organization/teams`,
        organizationTeamsSearch: (search, limit, offset) =>
            limit && offset !== undefined
                ? `${apiUrl}/organization/teams?limit=${limit}&offset=${offset}&search=${search}`
                : `${apiUrl}/organization/teams?search=${search}`,
        organizationStats: `${apiUrl}/organization/statistics`,
        organizationTeamMessages: (
            teamId,
            limit = 5,
            offset = 0
        ) => `${apiUrl}/organization/teams/${teamId}/messages?limit=${limit}&offset=${offset}
                    `,
        organizationTeamRanking: `${apiUrl}/organization/teams/ranking`,
        organizationMetricSources: `${apiUrl}/organization/metric_sources`,
        organizationMetricsBySourceId: (metricSourceId) =>
            `${apiUrl}/organization/metric_sources/${metricSourceId}/metrics`,
        organizationMetricsBySourceIdForSingleUser: (userId, metricSourceId) =>
            `${apiUrl}/organization/users/${userId}/metric_sources/${metricSourceId}/metrics`,
        mpaMetricSources: `${apiUrl}/admin/metric_sources`,
        mpaMetricsBySourceId: (metricSourceId) =>
            `${apiUrl}/admin/metric_sources/${metricSourceId}/metrics`,
        metricDetails: (metricSourceId, metricId) =>
            `${apiUrl}/organization/metric_sources/${metricSourceId}/metrics/${metricId}`,
        downloadTemplateAdmin: `${apiUrl}/admin/metric_sources/download_template`,
        downloadTemplate: () =>
            `${apiUrl}/organization/metric_results_uploads/download_template`,
        downloadUsersTemplate: () =>
            `${apiUrl}/organization/users/download_template`,
        metricUploads: (limit, offset) =>
            limit && offset !== undefined
                ? `${apiUrl}/organization/metric_results_uploads?limit=${limit}&offset=${offset}`
                : `${apiUrl}/organization/metric_results_uploads`,
        usersUploads: (limit, offset) =>
            limit && offset !== undefined
                ? `${apiUrl}/organization/user_uploads?limit=${limit}&offset=${offset}`
                : `${apiUrl}/organization/user_uploads`,
        usersAssignedToSmartTip: (smartTipId, limit, offset) =>
            `${apiUrl}/organization/smart_tips/${smartTipId}/users?limit=${limit}&offset=${offset}`,
        teamsAssignedToSmartTip: (smartTipId) =>
            `${apiUrl}/organization/smart_tips/${smartTipId}/teams?limit=100`,
        organizationSmartTipEvaluationList: (smartTipId) =>
            `${apiUrl}/organization/smart_tips/${smartTipId}/evaluations`,
        notifications: (limit, offset) =>
            limit && offset !== undefined
                ? `${apiUrl}/notifications?limit=${limit}&offset=${offset}`
                : `${apiUrl}/notifications`,
        tenant: `${apiUrl}/tenant`,
        tenantOrganization: `${apiUrl}/tenant/organization`,
        tenantSearch: (searchString) =>
            `${apiUrl}/tenants?search=${searchString}`,
        adminMemos: (limit, offset) =>
            limit && offset !== undefined
                ? `${apiUrl}/organization/admin_memos?limit=${limit}&offset=${offset}`
                : `${apiUrl}/organization/admin_memos`,
        mpaAdminMemos: (limit, offset) =>
            limit && offset !== undefined
                ? `${apiUrl}/admin/admin_memos?limit=${limit}&offset=${offset}`
                : `${apiUrl}/admin/admin_memos`,
        getUnseenNotificationCount: () =>
            `${apiUrl}/notifications/unseen_count`,
        metricSourcesOverallScores: (metricSourceId) =>
            `${apiUrl}/metric_sources/${metricSourceId}/overall_scores`,
        organizationMetricSourcesOverallScores: (metricSourceId) =>
            `${apiUrl}/organization/metric_sources/${metricSourceId}/overall_scores`,
        organizationUserMetricSourcesOverallScores: (metricSourceId, userId) =>
            `${apiUrl}/organization/metric_sources/${metricSourceId}/overall_scores/user_scores/${userId}`,
        customers: (limit, offset) =>
            limit && offset !== undefined
                ? `${apiUrl}/admin/organizations?limit=${limit}&offset=${offset}`
                : `${apiUrl}/admin/organizations`,
        customerDetails: (customerId) =>
            `${apiUrl}/admin/organizations/${customerId}`,
        superAdminUsers: (limit, offset) =>
            limit && offset !== undefined
                ? `${apiUrl}/admin/users?limit=${limit}&offset=${offset}`
                : `${apiUrl}/admin/users`,
        superAdminUser: (userId) => `${apiUrl}/admin/users/${userId}`,
        dataSourcesMeasuresUploads: (limit, offset) =>
            limit && offset !== undefined
                ? `${apiUrl}/admin/metric_sources_uploads?limit=${limit}&offset=${offset}`
                : `${apiUrl}/admin/metric_sources_uploads`,
        performanceCoachingRequest: `${apiUrl}/performance_coaching_request`,
        classificationTags: `${apiUrl}/organization/classification_tags`,
    },
    post: {
        login: `${apiUrl}/login`,
        performanceCoachingRequest: `${apiUrl}/performance_coaching_request`,
        createReminder: `${apiUrl}/reminders`,
        createTeam: `${apiUrl}/teams`,
        createTeamByAdmin: `${apiUrl}/organization/teams`,
        createOrganizationSmartTip: `${apiUrl}/organization/smart_tips`,
        joinTeam: (teamId) => `${apiUrl}/teams/${teamId}/join`,
        createMessage: (teamId) => `${apiUrl}/teams/${teamId}/messages`,
        assignSmartTipToOrganizationUser: (userId, smartTipId) =>
            `${apiUrl}/organization/users/${userId}/smart_tips/${smartTipId}/assignment`,
        uploadMetricResults: () =>
            `${apiUrl}/organization/metric_results_uploads`,
        setSurveyStatuses: `${apiUrl}/organization/smart_tips/set_survey_statuses`,
        organizationMetricResults: (metricId) =>
            `${apiUrl}/organization/metrics/${metricId}/metric_results`,
        searchMetrics: (
            sourceId,
            searchString = '',
            limit = 20,
            offset = 0
        ) => `${apiUrl}/organization/metric_sources/${sourceId}/metrics/with_results_only?limit=${limit}&offset=${offset}&search=${searchString}
        `,
        mpaMetricSourcesUpload: `${apiUrl}/admin/metric_sources_uploads`,
        sendSmartTipFeedback: (smartTipId) =>
            `${apiUrl}/smart_tips/${smartTipId}/evaluations`,
        markAllNotificationsAsRead: () =>
            `${apiUrl}/notifications/mark_all_as_read`,
        createAdminMemo: () => `${apiUrl}/organization/admin_memos`,
        uploadUsers: () => `${apiUrl}/organization/user_uploads`,
        createUser: () => `${apiUrl}/organization/users`,
        createCustomer: () => `${apiUrl}/admin/organizations`,
        createSuperAdminUser: () => `${apiUrl}/admin/users`,
        createMPAAdminMemo: () => `${apiUrl}/admin/admin_memos`,
        assignClassificationTagsToUser: (userId) =>
            `${apiUrl}/organization/users/${userId}/assign_classification_tags`,
        assignClassificationTagsToSmartTip: (smartTipId) =>
            `${apiUrl}/organization/smart_tips/${smartTipId}/assign_classification_tags`,
    },
    put: {
        updateUserProfile: `${apiUrl}/profile`,
        updateTeam: (teamId) => `${apiUrl}/teams/${teamId}`,
        updateOrganizationTeam: (teamId) =>
            `${apiUrl}/organization/teams/${teamId}`,
        messageFeedback: (teamId, msgId) =>
            `${apiUrl}/teams/${teamId}/messages/${msgId}/feedback`,
        toggleUserStatus: (userId) =>
            `${apiUrl}/organization/users/${userId}/toggle_status`,
        updateMetricSource: (metricSourceId) =>
            `${apiUrl}/organization/metric_sources/${metricSourceId}`,
        updateMetric: (metricSourceId, metricId) =>
            `${apiUrl}/organization/metric_sources/${metricSourceId}/metrics/${metricId}`,
        updateSmartTip: (smartTipId) => `${apiUrl}/smart_tips/${smartTipId}/`,
        updateOrganizationSmartTip: (smartTipId) =>
            `${apiUrl}/organization/smart_tips/${smartTipId}/`,
        markNotificationAsRead: (notificationId) =>
            `${apiUrl}/notifications/${notificationId}/mark_as_read`,
        toggleNotificationsOptions: () => `${apiUrl}/notifications/toggle`,
        updateUser: (userId) => `${apiUrl}/organization/users/${userId}`,
        editCustomerDetails: (customerId) =>
            `${apiUrl}/admin/organizations/${customerId}`,
        editSuperAdminUser: (userId) => `${apiUrl}/admin/users/${userId}`,
        editReminder: (reminderId) => `${apiUrl}/reminders/${reminderId}`,
        mpaUpdateMetric: (metricSourceId, metricId) =>
            `${apiUrl}/admin/metric_sources/${metricSourceId}/metrics/${metricId}`,
        mpaUpdateMetricSource: (metricSourceId) =>
            `${apiUrl}/admin/metric_sources/${metricSourceId}`,
        retryUsersUpload: (userUploadId) =>
            `${apiUrl}/organization/user_uploads/${userUploadId}/retry`,
        updateClassificationTags: `${apiUrl}/organization/classification_tags`,
    },
    delete: {
        deleteReminder: (id) => `${apiUrl}/reminders/${id}`,
        leaveTeam: (teamId) => `${apiUrl}/teams/${teamId}/leave`,
        removeTeamMember: (teamId, memberId) =>
            `${apiUrl}/teams/${teamId}/members/${memberId}`,
        deleteMessage: (teamId, messageId) =>
            `${apiUrl}/teams/${teamId}/messages/${messageId}`,
        archiveTeam: (teamId) => `${apiUrl}/teams/${teamId}`,
        archiveOrganizationTeam: (teamId) =>
            `${apiUrl}/organization/teams/${teamId}`,
        deleteOrganizationUser: (userId) =>
            `${apiUrl}/organization/users/${userId}`,
        deleteOrganizationMessage: (teamId, messageId) =>
            `${apiUrl}/organization/teams/${teamId}/messages/${messageId}`,
        removeOrganizationMember: (teamId, memberId) =>
            `${apiUrl}/organization/teams/${teamId}/members/${memberId}`,
        unassignSmartTipFromOrganizationUser: (userId, smartTipId) =>
            `${apiUrl}/organization/users/${userId}/smart_tips/${smartTipId}/assignment`,
        destroyMetricUpload: (metricUploadId) =>
            `${apiUrl}/organization/metric_results_uploads/${metricUploadId}`,
        revertAdminMemo: (adminMemoId) =>
            `${apiUrl}/organization/admin_memos/${adminMemoId}`,
        revertMPAAdminMemo: (adminMemoId) =>
            `${apiUrl}/admin/admin_memos/${adminMemoId}`,
        deleteSuperAdminUser: (userId) => `${apiUrl}/admin/users/${userId}`,
        deleteSmartTip: (smartTipId) =>
            `${apiUrl}/organization/smart_tips/${smartTipId}/`,
        mpaDeleteMetric: (metricSourceId, metricId) =>
            `${apiUrl}/admin/metric_sources/${metricSourceId}/metrics/${metricId}`,
    },
};

export default apiRoutes;
