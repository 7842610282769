import {
    ESTABLISH_CURRENT_USER,
    ESTABLISH_CURRENT_USER_SUCCESS,
    ESTABLISH_CURRENT_USER_FAILURE,
} from 'redux/actions/user';
import {
    GET_USER_PROFILE,
    GET_USER_PROFILE_SUCCESS,
    GET_USER_PROFILE_FAILURE,
    UPDATE_USER_PROFILE,
    UPDATE_USER_PROFILE_SUCCESS,
    UPDATE_USER_PROFILE_FAILURE,
} from 'redux/actions/profile';

export const initialState = {
    isUserEstablished: false,
    isUserError: false,
    isUserFetching: false,
    id: null,
    isAdmin: false,
    isMPA: false,
    userId: null,
    userEmail: '',
    userName: '',
    userAvatarUrl: '',
    userLevel: null,
    userScore: null,
    userTeams: [],
    isLMSEnabled: false,
    error: null,
    isScoreNotificationsEnabled: false,
    isMessageNotificationsEnabled: false,
    isEmailNotificationsEnabled: false,
};

const currentUser = (state = initialState, action) => {
    switch (action.type) {
        case ESTABLISH_CURRENT_USER:
        case GET_USER_PROFILE:
        case UPDATE_USER_PROFILE:
            return {
                ...state,
                isUserFetching: true,
                isUserError: false,
            };

        case ESTABLISH_CURRENT_USER_SUCCESS:
            return {
                ...state,
                isUserFetching: false,
                isUserEstablished: true,
                isUserError: false,
                userOrganizationName: action.payload.organizationName,
                id: action.payload.id,
                isAdmin: action.payload.isAdmin,
                isMPA: action.payload.isMPA,
                userId: action.payload.userId,
                userEmail: action.payload.userEmail,
                userName: action.payload.userName,
                userFirstName: action.payload.userFirstName,
                userLastName: action.payload.userLastName,
                userAvatarUrl: action.payload.userAvatarUrl,
                userLevel: action.payload.userLevel,
                userScore: action.payload.userScore,
                pointsToNextLevel: action.payload.pointsToNextLevel,
                userTeams: action.payload.userTeams,
                isLMSEnabled: action.payload.isLMSEnabled,
                isScoreNotificationsEnabled:
                    action.payload.isScoreNotificationsEnabled,
                isMessageNotificationsEnabled:
                    action.payload.isMessageNotificationsEnabled,
                isEmailNotificationsEnabled:
                    action.payload.isEmailNotificationsEnabled,
                lmsCoursesListLink: action.payload.lmsCoursesListLink,
            };

        case GET_USER_PROFILE_SUCCESS:
        case UPDATE_USER_PROFILE_SUCCESS:
            return {
                ...state,
                isUserFetching: false,
                isUserEstablished: true,
                isUserError: false,
                id: action.payload.id,
                userId: action.payload.userId,
                userEmail: action.payload.userEmail,
                userName: action.payload.userName,
                userFirstName: action.payload.userFirstName,
                userLastName: action.payload.userLastName,
                userAvatarUrl: action.payload.userAvatarUrl,
                userLevel: action.payload.userLevel,
                userScore: action.payload.userScore,
                pointsToNextLevel: action.payload.pointsToNextLevel,
                isScoreNotificationsEnabled:
                    action.payload.isScoreNotificationsEnabled,
                isMessageNotificationsEnabled:
                    action.payload.isMessageNotificationsEnabled,
                isEmailNotificationsEnabled:
                    action.payload.isEmailNotificationsEnabled,
            };

        case ESTABLISH_CURRENT_USER_FAILURE:
        case GET_USER_PROFILE_FAILURE:
        case UPDATE_USER_PROFILE_FAILURE:
            return {
                ...state,
                isUserFetching: false,
                error: action.err,
            };

        default:
            return state;
    }
};

export default currentUser;
