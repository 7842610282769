import {
    establishOrganizationUsersModal,
    establishOrganizationUsersModalFailure,
    establishOrganizationUsersModalSuccess,
    loadMoreOrganizationUsersModal,
    allUsersModalLoaded,
} from 'redux/actions/organizationUsersModal';
import apiRoutes from 'config/apiRoutes';
import paramsToString from 'redux/helpers/paramsToString';

export const establishOrganizationUsersModalThunk =
    (getAccessTokenSilently, logout, limit = 20, offset = 0, params = {}) =>
    (dispatch) => {
        dispatch(establishOrganizationUsersModal());

        const url =
            apiRoutes.get.organizationUsers(limit, offset) +
            paramsToString(params);

        return getAccessTokenSilently()
            .then((token) => {
                fetch(url, {
                    method: 'GET',
                    headers: {
                        organization_slug:
                            window.localStorage.getItem('MP_ORGANIZATION'),
                        Authorization: `Token token="${token}"`,
                    },
                })
                    .then((res) => res.json())
                    .then((res) => {
                        if (res.error) {
                            return dispatch(
                                establishOrganizationUsersModalFailure(
                                    res.error
                                )
                            );
                        }

                        return dispatch(
                            establishOrganizationUsersModalSuccess(res)
                        );
                    })
                    .catch((err) =>
                        dispatch(
                            establishOrganizationUsersModalFailure({
                                err,
                            })
                        )
                    );
            })
            .catch(() => logout({ returnTo: window.location.origin }));
    };

export const loadMoreOrganizationUsersModalThunk =
    (getAccessTokenSilently, logout, limit, offset, params = {}) =>
    (dispatch) => {
        dispatch(establishOrganizationUsersModal());

        const url =
            apiRoutes.get.organizationUsers(limit, offset) +
            paramsToString(params);

        return getAccessTokenSilently()
            .then((token) => {
                fetch(url, {
                    method: 'GET',
                    headers: {
                        organization_slug:
                            window.localStorage.getItem('MP_ORGANIZATION'),
                        Authorization: `Token token="${token}"`,
                    },
                })
                    .then((res) => res.json())
                    .then((res) => {
                        if (res.error) {
                            return dispatch(
                                establishOrganizationUsersModalFailure(
                                    res.error
                                )
                            );
                        }

                        if (res.users.length === 0) {
                            return dispatch(allUsersModalLoaded());
                        }

                        return dispatch(loadMoreOrganizationUsersModal(res));
                    })
                    .catch((err) =>
                        dispatch(
                            establishOrganizationUsersModalFailure({
                                err,
                            })
                        )
                    );
            })
            .catch(() => logout({ returnTo: window.location.origin }));
    };
