import styled, { css } from 'styled-components/macro';

export const DropdownSelectWrapper = styled.div`
    position: relative;

    @media (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
        position: initial;
    }
`;

export const StyledInputWrapper = styled.div`
    position: absolute;
    width: 345px;
    right: 0px;
    top: 0px;
    margin-top: 49px;

    ${({ theme }) => css`
        box-shadow: ${theme.boxShadow.base};
        background-color: ${theme.color.white};
        border: ${theme.border.base};
        border-radius: ${theme.border.radiusBase};
    `}

    input {
        width: 313px;
        height: 50px;
        margin: 16px;
        margin-bottom: 20px;

        ${({ theme }) => css`
            background-color: ${theme.color.white};
            border: ${theme.border.base};
            border-radius: ${theme.border.radiusBase};
        `}
    }

    div {
        box-shadow: none;

        button {
            justify-content: center;
            width: 86px;
            margin-left: 16px;
        }
    }

    @media (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
        width: calc(100vw - 16px);
        margin-top: 0;
        top: 90px;
        right: 8px;
        left: 8px;

        input {
            width: calc(100vw - 48px);
        }
    }
`;
