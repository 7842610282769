import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'styled-components/macro';
import { useSelector } from 'react-redux';
import RelativePerformanceChart from 'containers/RelativePerformance';
import Button from 'components/Button';
import { ReactComponent as Checkmark } from './checkmark.svg';
import GoToPerformance from './GoToPerformance/GoToPerformance';
import Stats from './Stats/Stats';

import {
    StyledChartWrapper,
    StyledPerformanceDashboard,
    Placeholder,
    PlaceholderText,
    StyledIcon,
} from './PerformanceDashboard.style';

const btnStyles = css`
    color: ${({ theme }) => theme.color.tertiary};
    display: flex;
    align-items: center;

    svg {
        margin-right: 8px;
    }
`;

const PerformanceDashboard = ({
    twoThird,
    goToPerformance,
    userStats,
    metricSourceOverallScores,
    getMetricSourcesOverallScores,
    isAdmin,
    handleSendNotification,
    performanceCoachingRequestSent,
    btnLoadingState,
}) => {
    const { performanceCoaching, isEstablished } = useSelector(
        (state) => state.tenantOrganization
    );

    return (
      <StyledPerformanceDashboard twoThird={twoThird}>
        {!isAdmin && isEstablished && !performanceCoaching ? (
          <Placeholder>
            <PlaceholderText>
              <h3>Performance Coaching is not enabled.</h3>
              <p>
                Performance Coaching displays individual scores for
                key quality and performance metrics and links you to
                targeted microlearning Smart Tips that you can use
                to improve your skills and reach your goals.
              </p>
              <p>
                If you’re interested in Performance Coaching, let
                your organization admin know.
              </p>
              {!performanceCoachingRequestSent ? (
                <Button
                                tertiaryDark
                                onClick={handleSendNotification}
                            >
                  {btnLoadingState
                                    ? 'Sending...'
                                    : 'Send notification'}
                </Button>
                        ) : (
                          <Button primaryDark customStyles={btnStyles}>
                            <Checkmark />
                            {' '}
                            Notification sent
                          </Button>
                        )}
            </PlaceholderText>
            <StyledIcon className="rightIcon" />
          </Placeholder>
            ) : (
              <StyledChartWrapper>
                <RelativePerformanceChart
                        data={metricSourceOverallScores}
                        getData={getMetricSourcesOverallScores}
                    />
              </StyledChartWrapper>
            )}
        {goToPerformance ? (
          <GoToPerformance />
            ) : (
              <Stats userStats={userStats} isAdmin={isAdmin} />
            )}
      </StyledPerformanceDashboard>
    );
};

PerformanceDashboard.propTypes = {
    twoThird: PropTypes.bool.isRequired,
    goToPerformance: PropTypes.bool.isRequired,
    btnLoadingState: PropTypes.bool.isRequired,
    performanceCoachingRequestSent: PropTypes.bool.isRequired,
    handleSendNotification: PropTypes.func.isRequired,
    userStats: PropTypes.shape({
        smart_tips_completed_in_current_month: PropTypes.number,
        smart_tips_uncompleted_in_current_month: PropTypes.number,
        smart_tips_completed_in_previous_month: PropTypes.number,
        smart_tips_uncompleted_in_previous_month: PropTypes.number,
    }),
    metricSourceOverallScores: PropTypes.arrayOf(PropTypes.any).isRequired,
    getMetricSourcesOverallScores: PropTypes.func.isRequired,
    isAdmin: PropTypes.bool,
};

PerformanceDashboard.defaultProps = {
    userStats: undefined,
    isAdmin: false,
};

export default PerformanceDashboard;
