import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Spinner from 'components/Spinner';
import Button from 'components/Button';
import SectionHeader from 'components/SectionHeader';
import UserDetailsHeader from 'components/UserDetailsHeader';
import StatisticBox from 'components/StatisticBox';
import ConfirmActionModal from 'components/ConfirmActionModal';
import PerformanceDashboard from 'containers/PerformanceDashboard';
import Modal from 'components/Modal';
import { ReactComponent as ArrowBackIcon } from 'assets/svg/arrow-back.svg';
import { ReactComponent as SettingsIcon } from 'assets/svg/settings.svg';
import { CtaGroup } from 'components/Input/Input.style';
import PerformanceMetrics from 'containers/PerformanceMetrics/PerformanceMetrics';
import { HA_ROUTES } from 'routing/constants';
import theme from 'assets/theme/mainTheme';
import AssignModalBody from './AssignModalBody';
import {
    StyledUserDetailsWrapper,
    StyledUserStatistics,
    StyledUserDetailsSections,
    CtaWrapper,
    Divider,
    DropdownWrapper,
    Dropdown,
    DropdownItem,
} from './UserDetailsHA.style';

const UserDetailsHA = ({
    isFetching,
    userDetails,
    userStats,
    isSettingsVisible,
    setIsSettingsVisible,
    isDeleteConfirmationModalVisible,
    setIsDeleteConfirmationModalVisible,
    isAssignModalVisible,
    setIsAssignModalVisible,
    areUserSmartTipsFetching,
    handleDeleteUser,
    handleSearch,
    handleAssignment,
    handleSmartTipSelect,
    redirectToUserEdit,
    smartTipsSearchList,
    setSmartTipsSearchList,
    removeSmartTip,
    selectedSmartTips,
}) => {
    const dropdownRef = useRef();

    const handleClickOutside = (e) => {
        if (dropdownRef.current && dropdownRef.current.contains(e.target)) {
            return;
        }
        setIsSettingsVisible(false);
    };

    useEffect(() => {
        if (isSettingsVisible) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isSettingsVisible]);

    if (isFetching) {
        return <Spinner data-testid="spinner" />;
    }

    return (
        <StyledUserDetailsWrapper>
            <SectionHeader
                title={`${userDetails.first_name} ${userDetails.last_name}`}
                withPadding
                goBack={{
                    url: HA_ROUTES.users,
                    label() {
                        return (
                            <>
                                <ArrowBackIcon stroke={theme.color.textLabel} />{' '}
                                <span>Back</span>
                            </>
                        );
                    },
                }}
                cta={() => (
                    <CtaWrapper ref={dropdownRef}>
                        <SettingsIcon
                            onClick={() =>
                                setIsSettingsVisible(!isSettingsVisible)
                            }
                        />
                        {isSettingsVisible && (
                            <DropdownWrapper>
                                <Dropdown
                                    onClick={() => setIsSettingsVisible(false)}
                                >
                                    <DropdownItem onClick={redirectToUserEdit}>
                                        Edit profile
                                    </DropdownItem>
                                    <Divider />
                                    <DropdownItem
                                        secondary
                                        onClick={() =>
                                            setIsDeleteConfirmationModalVisible(
                                                true
                                            )
                                        }
                                    >
                                        Delete User
                                    </DropdownItem>
                                </Dropdown>
                            </DropdownWrapper>
                        )}
                    </CtaWrapper>
                )}
                collapseOnMobile
            />
            <StyledUserDetailsSections>
                <UserDetailsHeader
                    firstName={userDetails.first_name}
                    lastName={userDetails.last_name}
                    email={userDetails.email}
                    avatarUrl={userDetails.avatar_url}
                    backgroundColor={userDetails.background_color}
                    teams={userDetails.teams}
                    setIsAssignModalVisible={setIsAssignModalVisible}
                />
            </StyledUserDetailsSections>
            <SectionHeader title="Achievementsttt" withMargin />
            <StyledUserDetailsSections>
                <StyledUserStatistics>
                    <StatisticBox
                        metric={userStats.stars_earned || '0'}
                        label="Stars earned in total"
                        type="stars-earned"
                    />
                    <StatisticBox
                        metric={`${userStats.level || 0} level`}
                        label="Your current level"
                        type="level"
                    />
                    <StatisticBox
                        metric={userStats.stars_to_next_level || '0'}
                        label="Stars to next level"
                        type="stars-to-next-level"
                    />
                </StyledUserStatistics>
            </StyledUserDetailsSections>
            <StyledUserDetailsSections marginTop>
                <PerformanceDashboard userId={userDetails.id} />
            </StyledUserDetailsSections>
            <ConfirmActionModal
                isVisible={isDeleteConfirmationModalVisible}
                onToggle={() => {
                    setIsDeleteConfirmationModalVisible(false);
                }}
                onConfirm={handleDeleteUser}
                description="Are you sure you want to delete this account?"
                confirmLabel="Confirm"
                irreversible
            />
            {isAssignModalVisible && (
                <Modal
                    noOverflow
                    headerText="Assign a Smart Tip"
                    closeModal={(e) => {
                        if (e.target.id === 'globalModalBg') {
                            setIsAssignModalVisible(false);
                        }
                    }}
                >
                    <AssignModalBody
                        assignType="a Smart Tip"
                        handleSearch={(val) => handleSearch(val)}
                        areUserSmartTipsFetching={areUserSmartTipsFetching}
                        smartTipsSearchList={smartTipsSearchList}
                        handleSmartTipSelect={handleSmartTipSelect}
                        setSmartTipsSearchList={setSmartTipsSearchList}
                        removeSmartTip={removeSmartTip}
                        selectedSmartTips={selectedSmartTips}
                    />
                    <CtaGroup style={{ marginTop: 'auto' }}>
                        <Button
                            white
                            onClick={() => setIsAssignModalVisible(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            primary
                            onClick={() => {
                                handleAssignment();
                                setIsAssignModalVisible(false);
                            }}
                        >
                            Assign
                        </Button>
                    </CtaGroup>
                </Modal>
            )}
            <PerformanceMetrics
                handleMetricLoaded={() => {}}
                userId={userDetails.id}
            />
        </StyledUserDetailsWrapper>
    );
};

UserDetailsHA.propTypes = {
    isFetching: PropTypes.bool,
    userDetails: PropTypes.objectOf(PropTypes.any).isRequired,
    userStats: PropTypes.objectOf(PropTypes.any).isRequired,
    isSettingsVisible: PropTypes.bool.isRequired,
    setIsSettingsVisible: PropTypes.func.isRequired,
    isDeleteConfirmationModalVisible: PropTypes.bool.isRequired,
    setIsDeleteConfirmationModalVisible: PropTypes.func.isRequired,
    isAssignModalVisible: PropTypes.bool.isRequired,
    setIsAssignModalVisible: PropTypes.func.isRequired,
    areUserSmartTipsFetching: PropTypes.bool.isRequired,
    handleDeleteUser: PropTypes.func.isRequired,
    handleSearch: PropTypes.func.isRequired,
    handleAssignment: PropTypes.func.isRequired,
    handleSmartTipSelect: PropTypes.func.isRequired,
    redirectToUserEdit: PropTypes.func.isRequired,
    smartTipsSearchList: PropTypes.arrayOf(PropTypes.any).isRequired,
    setSmartTipsSearchList: PropTypes.func.isRequired,
    removeSmartTip: PropTypes.func.isRequired,
    selectedSmartTips: PropTypes.arrayOf(PropTypes.any).isRequired,
};

UserDetailsHA.defaultProps = {
    isFetching: true,
};

export default UserDetailsHA;
