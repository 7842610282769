export const ESTABLISH_ORGANIZATION_USERS_MODAL =
    'ESTABLISH_ORGANIZATION_USERS_MODAL';
export const ESTABLISH_ORGANIZATION_USERS_MODAL_SUCCESS =
    'ESTABLISH_ORGANIZATION_USERS_MODAL_SUCCESS';
export const ESTABLISH_ORGANIZATION_USERS_MODAL_FAILURE =
    'ESTABLISH_ORGANIZATION_USERS_MODAL_FAILURE';
export const LOAD_MORE_ORGANIZATION_USERS_MODAL =
    'LOAD_MORE_ORGANIZATION_USERS_MODAL';
export const MARK_ALL_ORGANIZATION_USERS_MODAL_LOADED =
    'MARK_ALL_ORGANIZATION_USERS_MODAL_LOADED';
export const RESET_ORGANIZATION_USERS_MODAL = 'RESET_ORGANIZATION_USERS_MODAL';

export const establishOrganizationUsersModal = () => ({
    type: ESTABLISH_ORGANIZATION_USERS_MODAL,
});

export const establishOrganizationUsersModalSuccess = (payload) => ({
    type: ESTABLISH_ORGANIZATION_USERS_MODAL_SUCCESS,
    payload,
});

export const establishOrganizationUsersModalFailure = (err) => ({
    type: ESTABLISH_ORGANIZATION_USERS_MODAL_FAILURE,
    err,
});

export const loadMoreOrganizationUsersModal = (payload) => ({
    type: LOAD_MORE_ORGANIZATION_USERS_MODAL,
    payload,
});

export const allUsersModalLoaded = () => ({
    type: MARK_ALL_ORGANIZATION_USERS_MODAL_LOADED,
});

export const resetOrganizationUsersModal = () => ({
    type: RESET_ORGANIZATION_USERS_MODAL,
});
