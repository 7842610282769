import apiRoutes from 'config/apiRoutes';
import {
    fetchReminders,
    fetchRemindersSuccess,
    fetchRemindersFailure,
    deleteReminder,
    deleteReminderSuccess,
    deleteReminderFailure,
    addReminder,
    addReminderSuccess,
    editReminder,
    editReminderSuccess,
} from 'redux/actions/remindersAll';

export const deleteReminderThunk = (getAccessTokenSilently, logout, id) => (
    dispatch
) => {
    dispatch(deleteReminder(id));

    return getAccessTokenSilently()
        .then((token) => {
            fetch(apiRoutes.delete.deleteReminder(id), {
                method: 'DELETE',
                headers: {
                    organization_slug: window.localStorage.getItem(
                        'MP_ORGANIZATION'
                    ),
                    Authorization: `Token token="${token}"`,
                },
            })
                .then((res) => JSON.parse(res))
                .then((res) => dispatch(deleteReminderSuccess(res)))
                .catch((err) => dispatch(deleteReminderFailure(err)));
        })
        .catch(() => logout({ returnTo: window.location.origin }));
};

export const addReminderThunk = (
    getAccessTokenSilently,
    logout,
    newReminder,
    noreload
) => (dispatch) => {
    dispatch(addReminder());

    return getAccessTokenSilently()
        .then((token) => {
            fetch(apiRoutes.post.createReminder, {
                method: 'POST',
                headers: {
                    organization_slug: window.localStorage.getItem(
                        'MP_ORGANIZATION'
                    ),
                    Authorization: `Token token="${token}"`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newReminder),
            })
                .then((res) => res.json())
                .then((res) => {
                    dispatch(addReminderSuccess(res));
                    if (!noreload) {
                        dispatch(
                            fetchRemindersThunk(getAccessTokenSilently, logout)
                        );
                    }
                })
                .catch(() => {
                    if (!noreload) {
                        dispatch(
                            fetchRemindersThunk(getAccessTokenSilently, logout)
                        );
                    }
                });
        })
        .catch(() => logout({ returnTo: window.location.origin }));
};

export const editReminderThunk = (
    getAccessTokenSilently,
    logout,
    id,
    reminder
) => (dispatch) => {
    dispatch(editReminder());

    return getAccessTokenSilently()
        .then((token) => {
            fetch(apiRoutes.put.editReminder(id), {
                method: 'PUT',
                headers: {
                    organization_slug: window.localStorage.getItem(
                        'MP_ORGANIZATION'
                    ),
                    Authorization: `Token token="${token}"`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(reminder),
            })
                .then((res) => res.json())
                .then((res) => {
                    dispatch(editReminderSuccess(res));
                    dispatch(
                        fetchRemindersThunk(getAccessTokenSilently, logout)
                    );
                })
                .catch(() => {
                    dispatch(
                        fetchRemindersThunk(getAccessTokenSilently, logout)
                    );
                });
        })
        .catch(() => logout({ returnTo: window.location.origin }));
};

export const fetchRemindersThunk = (getAccessTokenSilently, logout) => (
    dispatch
) => {
    dispatch(fetchReminders());

    return getAccessTokenSilently()
        .then((token) => {
            fetch(apiRoutes.get.reminders, {
                method: 'GET',
                headers: {
                    organization_slug: window.localStorage.getItem(
                        'MP_ORGANIZATION'
                    ),
                    Authorization: `Token token="${token}"`,
                },
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.error) {
                        return dispatch(fetchRemindersFailure(res.error));
                    }

                    return dispatch(fetchRemindersSuccess(res.reminders));
                })
                .catch((err) =>
                    dispatch(
                        fetchRemindersFailure({
                            err,
                        })
                    )
                );
        })
        .catch(() => logout({ returnTo: window.location.origin }));
};
