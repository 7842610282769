import React from 'react';
import PropTypes from 'prop-types';
import { FileInputArea, FileInputAreaLabel } from './UploadDropzone.style';

const UploadDropzone = ({ handleUpload, setUploadedFile, ...rest }) => (
  <>
    <FileInputAreaLabel htmlFor="uploadInput">
      <FileInputArea
                type="file"
                name="uploadInput"
                id="uploadInput"
                onChange={(e) => {
                    handleUpload(e.target.files);
                    if (setUploadedFile !== undefined) {
                        setUploadedFile(e.target.files[0]);
                    }
                }}
                {...rest}
            />
    </FileInputAreaLabel>
  </>
);

UploadDropzone.propTypes = {
    handleUpload: PropTypes.func.isRequired,
    setUploadedFile: PropTypes.func || undefined,
};

UploadDropzone.defaultProps = {
    setUploadedFile: undefined,
};

export default UploadDropzone;
