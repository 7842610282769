import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Input from 'components/Input';
import Button from 'components/Button';
import Picker from 'emoji-picker-react';
import { ReactComponent as EmojiDropdownIcon } from 'assets/svg/emoji-open.svg';
import { KEYBOARD_KEYS } from 'constants.js';
import {
    TeamMessageWrapper,
    TeamMessageEmojiDropdown,
    buttonCustom,
} from './TeamMessageForm.style';

const TeamMessageForm = ({ sendMessage }) => {
    const [messageValue, setMessageValue] = useState('');
    const [formDisabled, setFormDisabled] = useState(true);
    const [isEmojiDropdownOpen, setIsEmojiDropdownOpen] = useState(false);

    useEffect(() => {
        if (messageValue.length >= 2 && formDisabled) {
            setFormDisabled(false);
        }

        if (messageValue.length < 2) {
            setFormDisabled(true);
        }
    }, [messageValue]);

    useEffect(() => {
        setMessageValue('');
        setIsEmojiDropdownOpen(false);
    }, [sendMessage]);

    const handleClick = (e) => {
        if (e.keyCode === KEYBOARD_KEYS.enter) {
            sendMessage(messageValue);
        }
    };

    const onEmojiClick = (event, emojiObject) => {
        setMessageValue(messageValue.concat(emojiObject.emoji));
    };

    return (
      <TeamMessageWrapper>
        <Input variant="group" noMargin>
          <Input
                    variant="base"
                    placeholder="Your message"
                    value={messageValue}
                    onChange={({ target }) => setMessageValue(target.value)}
                    onKeyDown={handleClick}
                />
          <EmojiDropdownIcon
                    className="hiddenSmall"
                    onClick={() => setIsEmojiDropdownOpen(!isEmojiDropdownOpen)}
                />
          {isEmojiDropdownOpen && (
            <TeamMessageEmojiDropdown>
              <Picker onEmojiClick={onEmojiClick} />
            </TeamMessageEmojiDropdown>
                )}
          <Button
                    customStyles={buttonCustom}
                    primaryDark
                    onClick={() => {
                        sendMessage(messageValue);
                    }}
                    disabled={formDisabled}
                >
            Send
          </Button>
        </Input>
      </TeamMessageWrapper>
    );
};

TeamMessageForm.propTypes = {
    sendMessage: PropTypes.func.isRequired,
};

export default TeamMessageForm;
