export const ESTABLISH_ORGANIZATION_SMART_TIPS =
    'ESTABLISH_ORGANIZATION_SMART_TIPS';
export const ESTABLISH_ORGANIZATION_SMART_TIPS_SUCCESS =
    'ESTABLISH_ORGANIZATION_SMART_TIPS_SUCCESS';
export const ESTABLISH_ORGANIZATION_SMART_TIPS_SEARCH_SUCCESS =
    'ESTABLISH_ORGANIZATION_SMART_TIPS_SEARCH_SUCCESS';
export const ESTABLISH_ORGANIZATION_SMART_TIPS_FAILURE =
    'ESTABLISH_ORGANIZATION_SMART_TIPS_FAILURE';
export const LOAD_MORE_ORGANIZATION_SMART_TIPS =
    'LOAD_MORE_ORGANIZATION_SMART_TIPS';
export const MARK_ALL_ORGANIZATION_SMART_TIPS_LOADED =
    'MARK_ALL_ORGANIZATION_SMART_TIPS_LOADED';

export const UPDATE_ORGANIZATION_SMART_TIP = 'UPDATE_ORGANIZATION_SMART_TIP';
export const UPDATE_ORGANIZATION_SMART_TIP_SUCCESS =
    'UPDATE_ORGANIZATION_SMART_TIP_SUCCESS';
export const UPDATE_ORGANIZATION_SMART_TIP_FAILURE =
    'UPDATE_ORGANIZATION_SMART_TIP_FAILURE';

export const RESET_ORGANIZATION_SMART_TIPS = 'RESET_ORGANIZATION_SMART_TIPS';

export const SEARCH_USER_SMART_TIPS = 'SEARCH_USER_SMART_TIPS';
export const SEARCH_USER_SMART_TIPS_SUCCESS = 'SEARCH_USER_SMART_TIPS_SUCCESS';
export const SEARCH_USER_SMART_TIPS_FAILURE = 'SEARCH_USER_SMART_TIPS_FAILURE';

export const ASSIGN_ORGANIZATION_SMART_TIP = 'ASSIGN_ORGANIZATION_SMART_TIP';
export const ASSIGN_ORGANIZATION_SMART_TIP_SUCCESS =
    'ASSIGN_ORGANIZATION_SMART_TIP_SUCCESS';
export const ASSIGN_ORGANIZATION_SMART_TIP_FAILURE =
    'ASSIGN_ORGANIZATION_SMART_TIP_FAILURE';

export const establishOrganizationSmartTips = () => ({
    type: ESTABLISH_ORGANIZATION_SMART_TIPS,
});

export const establishOrganizationSmartTipsSuccess = (payload) => ({
    type: ESTABLISH_ORGANIZATION_SMART_TIPS_SUCCESS,
    payload,
});

export const establishOrganizationSmartTipsFailure = (err) => ({
    type: ESTABLISH_ORGANIZATION_SMART_TIPS_FAILURE,
    err,
});

export const updateOrganizationSmartTip = () => ({
    type: UPDATE_ORGANIZATION_SMART_TIP,
});

export const updateOrganizationSmartTipSuccess = (payload) => ({
    type: UPDATE_ORGANIZATION_SMART_TIP_SUCCESS,
    payload,
});

export const updateOrganizationSmartTipSearchSuccess = (payload) => ({
    type: ESTABLISH_ORGANIZATION_SMART_TIPS_SEARCH_SUCCESS,
    payload,
});

export const updateOrganizationSmartTipFailure = (err) => ({
    type: UPDATE_ORGANIZATION_SMART_TIP_FAILURE,
    err,
});

export const loadMoreOrganizationSmartTips = (payload) => ({
    type: LOAD_MORE_ORGANIZATION_SMART_TIPS,
    payload,
});

export const allSmartTipsLoaded = () => ({
    type: MARK_ALL_ORGANIZATION_SMART_TIPS_LOADED,
});

export const resetOrganizationSmartTips = () => ({
    type: RESET_ORGANIZATION_SMART_TIPS,
});

export const searchUserSmartTips = () => ({
    type: SEARCH_USER_SMART_TIPS,
});

export const searchUserSmartTipsSuccess = (payload) => ({
    type: SEARCH_USER_SMART_TIPS_SUCCESS,
    payload,
});

export const searchUserSmartTipsFailure = (err) => ({
    type: SEARCH_USER_SMART_TIPS_FAILURE,
    err,
});

export const assignOrganizationSmartTip = () => ({
    type: ASSIGN_ORGANIZATION_SMART_TIP,
});

export const assignOrganizationSmartTipSuccess = (payload) => ({
    type: ASSIGN_ORGANIZATION_SMART_TIP_SUCCESS,
    payload,
});

export const assignOrganizationSmartTipFailure = (err) => ({
    type: ASSIGN_ORGANIZATION_SMART_TIP_FAILURE,
    err,
});
