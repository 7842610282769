export const TAGS_INIT_VALS = {
    role_job: [],
    department: [],
};

export const DEFAULT_LIMIT = 10;

export const generatePages = (totalCount) => {
    const newPages = [];
    for (
        let index = 0;
        index < Math.ceil(totalCount / DEFAULT_LIMIT);
        index += 1
    ) {
        newPages.push({
            index,
            pageNumber: index + 1,
        });
    }

    return newPages;
};
