import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import establishUserDetailsThunk from 'redux/actions/thunks/organizationUserDetails';
import establishUserStatsThunk from 'redux/actions/thunks/organizationUserStats';
import UserDetailsView from 'views/UserDetailsHA';
import { deleteOrganizationUserThunk } from 'redux/actions/thunks/organizationUsers';
import { HA_ROUTES } from 'routing/constants';
import {
    assignOrganizationSmartTipThunk,
    searchOrganizationSmartTipsThunk,
} from 'redux/actions/thunks/organizationSmartTips';
import { useAuth0 } from '@auth0/auth0-react';
import sleep from 'sleep-promise';

const UserDetailsHA = () => {
    const { getAccessTokenSilently, logout } = useAuth0();
    const { userId } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const [isAssignModalVisible, setIsAssignModalVisible] = useState(false);
    const [isSettingsVisible, setIsSettingsVisible] = useState(false);
    const [selectedSmartTips, setSelectedSmartTips] = useState([]);
    const [smartTipsSearchList, setSmartTipsSearchList] = useState([]);
    const [
        isDeleteConfirmationModalVisible,
        setIsDeleteConfirmationModalVisible,
    ] = useState(false);

    const { areUserDetailsFetching, organizationUserDetails } = useSelector(
        (state) => state.organizationUserDetails
    );

    const { areUserStatsFetching, organizationUserStats } = useSelector(
        (state) => state.organizationUserStats
    );

    const {
        areUserSmartTipsFetching,
        userSmartTipsSearchList,
        isUserSmartTipsAssignmentPending,
        areUserSmartTipsEstablished,
    } = useSelector((state) => state.organizationSmartTips);

    useEffect(() => {
        dispatch(
            establishUserDetailsThunk(getAccessTokenSilently, logout, userId)
        );
        dispatch(
            establishUserStatsThunk(getAccessTokenSilently, logout, userId)
        );
    }, []);

    useEffect(() => {
        setSmartTipsSearchList(
            userSmartTipsSearchList.map((st) => ({
                ...st,
                selected:
                    selectedSmartTips.find((tip) => tip.id === st.id) !==
                    undefined,
            }))
        );
    }, [userSmartTipsSearchList]);

    const handleAssignment = async () => {
        selectedSmartTips.forEach(async (singleTip, index) => {
            const sleepPromise = sleep(1000 + index * 1000);
            await sleepPromise;
            assignOrganizationSmartTip(singleTip.id);
        });
        clearModal();
    };

    const clearModal = () => {
        setSelectedSmartTips([]);
        setSmartTipsSearchList([]);
    };

    const handleDeleteUser = () => {
        deleteOrganizationUser(organizationUserDetails.id);
        history.push(HA_ROUTES.users);
    };

    const handleSearch = (val) => {
        if ((val && val.length >= 3) || userSmartTipsSearchList.length > 0) {
            searchOrganizationSmartTips(val, organizationUserDetails.id);
        }
    };

    const handleSmartTipSelect = (id) => {
        setSmartTipsSearchList(
            smartTipsSearchList.map((st) => {
                if (st.id === id) {
                    if (!st.selected) {
                        setSelectedSmartTips([...selectedSmartTips, st]);
                    } else if (selectedSmartTips.find((tip) => tip.id === id)) {
                        setSelectedSmartTips(
                            selectedSmartTips.filter((tip) => tip.id !== id)
                        );
                    }

                    return {
                        ...st,
                        selected: !st.selected,
                    };
                }

                return {
                    ...st,
                };
            })
        );
    };

    const removeSmartTip = (id) =>
        setSelectedSmartTips(selectedSmartTips.filter((st) => st.id !== id));

    const deleteOrganizationUser = (uId) =>
        dispatch(
            deleteOrganizationUserThunk(getAccessTokenSilently, logout, uId)
        );

    const searchOrganizationSmartTips = (searchValue, uId) =>
        dispatch(
            searchOrganizationSmartTipsThunk(
                getAccessTokenSilently,
                logout,
                searchValue,
                uId
            )
        );

    const assignOrganizationSmartTip = (smartTipId) =>
        dispatch(
            assignOrganizationSmartTipThunk(
                getAccessTokenSilently,
                logout,
                userId,
                smartTipId
            )
        );

    const redirectToUserEdit = () =>
        history.push(`${HA_ROUTES.editUser}/${organizationUserDetails.id}`);

    useEffect(() => {
        clearModal();
    }, [isAssignModalVisible]);

    return (
        <UserDetailsView
            isFetching={areUserDetailsFetching || areUserStatsFetching}
            userDetails={organizationUserDetails}
            userStats={organizationUserStats}
            isSettingsVisible={isSettingsVisible}
            setIsSettingsVisible={setIsSettingsVisible}
            isDeleteConfirmationModalVisible={isDeleteConfirmationModalVisible}
            setIsDeleteConfirmationModalVisible={
                setIsDeleteConfirmationModalVisible
            }
            isAssignModalVisible={isAssignModalVisible}
            setIsAssignModalVisible={setIsAssignModalVisible}
            areUserSmartTipsFetching={areUserSmartTipsFetching}
            handleDeleteUser={handleDeleteUser}
            handleSearch={handleSearch}
            handleAssignment={handleAssignment}
            handleSmartTipSelect={handleSmartTipSelect}
            redirectToUserEdit={redirectToUserEdit}
            smartTipsSearchList={smartTipsSearchList}
            setSmartTipsSearchList={setSmartTipsSearchList}
            removeSmartTip={removeSmartTip}
            selectedSmartTips={selectedSmartTips}
        />
    );
};

export default UserDetailsHA;
