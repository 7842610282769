import styled, { css } from 'styled-components/macro';

export const ModalHolder = styled.div`
    min-width: 420px;
    max-height: 80vh;
    display: flex;
    flex-direction: column;

    ${({ theme }) => css`
        background-color: ${theme.color.white};
        border-radius: ${theme.border.radiusBase};
        box-shadow: ${theme.boxShadow.base};
    `};

    z-index: 1002;

    ${({ absolute }) =>
        absolute &&
        css`
            position: absolute;
        `};

    @media (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
        min-width: 300px;
        max-height: calc(100vh - 24px);
    }

    ${({ customStyles }) =>
        customStyles &&
        css`
            ${customStyles};
        `};
`;

export const ModalHeader = styled.div`
    ${({ theme }) => css`
        border-top-right-radius: ${theme.border.radiusBase};
        border-top-left-radius: ${theme.border.radiusBase};
        border-bottom: ${theme.border.base};
    `};

    min-height: 64px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 24px;

    span {
        font-weight: 500;
    }

    svg {
        cursor: pointer;
    }
`;

export const ModalFooter = styled.div`
    ${({ theme }) => css`
        border-bottom-right-radius: ${theme.border.radiusBase};
        border-bottom-left-radius: ${theme.border.radiusBase};
        border-top: ${theme.border.base};
    `};

    height: 94px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 8px 24px;
    margin-top: auto;
`;

export const ModalBody = styled.div`
    padding: 8px 24px;
    display: flex;
    flex-direction: column;
    max-height: 70vh;

    ${({ noPadding }) =>
        noPadding &&
        css`
            padding: 0;
        `}

    ${({ bodyOverflow }) =>
        bodyOverflow &&
        css`
            overflow-y: auto;
        `}

    ${({ noOverflow }) =>
        noOverflow &&
        css`
            @media (min-width: ${({ theme }) =>
                    `${theme.breakpoints.small}px`}) {
                overflow-y: initial;
            }
        `}
`;

export const ModalWithBgWrapper = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1001;
`;

export const ModalBg = styled.div`
    width: 100%;
    height: 100%;
    z-index: 1001;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.6);
`;
