import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ArrowDown } from 'assets/svg/dropdown-down.svg';
import { ReactComponent as ArrowUp } from 'assets/svg/dropdown-up.svg';
import theme from 'assets/theme/mainTheme';
import { css } from 'styled-components/macro';
import {
    AccordionContainer,
    AccordionLabel,
    AccordionLabelContent,
    AccordionStatusIconHolder,
    AccordionContent,
} from './Accordion.style';

const AccordionFAQ = ({ label, children }) => {
    const [isAccordionOpen, toggleAccordion] = useState(false);

    return (
      <AccordionContainer
            customStyles={css`
                margin-bottom: 16px;
            `}
        >
        <AccordionLabel
                type="button"
                expanded={isAccordionOpen}
                className="accordionButton"
                onClick={() => toggleAccordion(!isAccordionOpen)}
                regularFont
            >
          <AccordionLabelContent className="accordionLabelContent">
            <strong>{label}</strong>
          </AccordionLabelContent>
          <AccordionStatusIconHolder
                    className="accordionIconHolder"
                    circular
                >
            {isAccordionOpen ? (
              <ArrowUp fill={theme.color.primary} />
                    ) : (
                      <ArrowDown fill={theme.color.primary} />
                    )}
          </AccordionStatusIconHolder>
        </AccordionLabel>
        <AccordionContent expanded={isAccordionOpen}>
          {children}
        </AccordionContent>
      </AccordionContainer>
    );
};

AccordionFAQ.propTypes = {
    label: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
};

export default AccordionFAQ;
