import styled, { css } from 'styled-components/macro';

export const RecentItemsWrapper = styled.div`
    padding: 16px 0;
    width: 100%;
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 30px;

    @media (max-width: ${({ theme }) => `${theme.breakpoints.medium}px`}) {
        grid-template-columns: 1fr;
    }
`;

export const RecentlyVisitedWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    @media (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
        max-width: calc(100vw - 48px);
    }
`;

export const RecentSmartTips = styled.div`
    width: 100%;
`;

export const RecentlyVisitedItem = styled.div`
    width: 100%;
    height: 90px;
    margin-bottom: 16px;
    padding: 0 16px;
    border-radius: ${({ theme }) => theme.border.radiusBase};
    border: ${({ theme }) => theme.border.base};
    display: flex;
    align-items: center;
    cursor: pointer;

    @media (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
        overflow-x: hidden;
    }
`;

export const RecentlyVisitedImg = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin: 16px 16px 16px 8px;
    object-fit: cover;
`;

export const UserGenericAvatar = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin: 16px 16px 16px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.color.bgPrimaryLight};
`;

export const RecentlyVisitedTitle = styled.div`
    display: flex;
    flex-direction: column;

    strong,
    span {
        line-height: 1.5;
    }

    span {
        color: ${({ theme }) => theme.color.textLight};
        font-size: ${({ theme }) => theme.fontSize.small};
    }
`;

export const IconHolder = styled.div`
    margin-right: 32px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    ${({ color }) =>
        color &&
        css`
            background-color: ${color};
        `}
`;
