import styled, { css } from 'styled-components/macro';
import { ReactComponent as CloseIcon } from 'assets/svg/close.svg';

const PerformanceSectionWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    ${({ withPadding }) =>
        withPadding &&
        css`
            padding: 0 16px;
        `}
`;

export const StatisticsWrapper = styled(PerformanceSectionWrapper)`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px;

    @media (max-width: ${({ theme }) => `${theme.breakpoints.mobile}px`}) {
        grid-template-columns: 1fr;
    }
`;

export const ModalLayout = styled.div`
    background: #ebf0fa;
    border-radius: 16px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 24px;
    position: relative;
    margin-bottom: 32px;

    @media (max-width: 928px) {
        flex-direction: column;
    }
`;

export const ModalIconHolder = styled.div`
    display: flex;
    border-radius: 24px;
    width: 48px;
    height: 48px;
    background-color: #fb4c79;
    align-items: center;
    justify-content: center;

    svg {
        width: 16px;
        height: 16px;
    }

    @media (max-width: 928px) {
        flex-direction: column;
    }
    border-bottom: 24px;
`;

export const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 24px;

    @media (max-width: 928px) {
        padding-left: 0;
    }
`;

export const ModalParagraph = styled.p`
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #718fbf;
    margin: 8px 0 16px;
    padding-right: 48px;
`;
export const StyledH3 = styled.h3`
    margin-top: 0;

    @media (max-width: 928px) {
        margin-top: 24px;
    }
`;

export const ModalAnchor = styled.a`
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #fb4c79;
    margin: 8px 16px 8px 0;
`;

export const StyledCloseIcon = styled(CloseIcon)`
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
    z-index: 2;
`;

export const StyledCounter = styled.strong`
    background: #cfd9ec;
    border-radius: 52px;
    padding: 8px;
    margin-right: 4px;
`;

export default PerformanceSectionWrapper;
