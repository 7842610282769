import React, { useReducer, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import useLocalStorage from 'hooks/useLocalStorage';
import OnboardingTile from 'components/OnboardingTile';
import DashboardOnboardingMock from 'views/DashboardMain/DashboardOnboardingMock';
import { TileBackground } from 'components/OnboardingTile/OnboardingTile.style';
import OnboardingStatusIndicator from 'components/OnboardingStatusIndicator';
import { ReactComponent as MedpowerLogo } from 'assets/svg/medpower-logo-onboarding.svg';
import { ReactComponent as OnboardingFinished } from 'assets/svg/onboarding-finish.svg';
import { ROUTES } from 'routing/constants';
import {
    onboardingSteps,
    ONBOARDING_FINISH,
    ONBOARDING_START,
    PERFORMANCE_CHART_EXPLAINER,
    PERFORMANCE_METRICS_EXPLAINER,
    UPLOAD_PROFILE_PIC,
} from './OnboardingProcessSteps';

const NEXT_STEP = 'increment';
const PREV_STEP = 'decrement';
const SET_VALUE = 'set_value';

const onboardingReducer = (state, action) => {
    if (action.type === NEXT_STEP) {
        return state + 1;
    }

    if (action.type === PREV_STEP) {
        return state - 1;
    }

    if (action.type === SET_VALUE) {
        return action.payload;
    }

    return state;
};

const Onboarding = () => {
    const [step, dispatch] = useReducer(onboardingReducer, 0);
    const history = useHistory();
    const { getItem, setItem, clearItem } = useLocalStorage('onboardingStatus');

    const indicatorSteps = [
        ONBOARDING_START,
        UPLOAD_PROFILE_PIC,
        '',
        ONBOARDING_FINISH,
    ];

    const initialStep = {
        verboseId: ONBOARDING_START,
        render() {
            return (
              <>
                <MedpowerLogo />
                <h2>Welcome!</h2>
                <p>
                  We&apos;ve put together a brief walk-through,
                  highlighting key features you&apos;ll want to check out.
                  If you don&apos;t have time, you can always restart this
                  tutorial from the FAQ page.
                </p>
              </>
            );
        },
        compact: false,
        positioning: undefined,
        showArrow: undefined,
        highlightedElement: () => {},
        highlightedElementStyles: () => {},
        buttonNextLabel: "Let's start!",
        buttonPreviousLabel: 'Skip',
    };

    const finalStep = {
        verboseId: ONBOARDING_FINISH,
        render() {
            return (
              <>
                <OnboardingFinished />
                <h2>That&apos;s all!</h2>
                <p>
                  Now it&apos;s time to check out the Smart Tips and
                  performance metrics that have been curated just for you.
                </p>
              </>
            );
        },
        compact: false,
        positioning: undefined,
        showArrow: undefined,
        highlightedElement: () => {},
        highlightedElementStyles: () => {},
        buttonNextLabel: 'Finish',
        buttonPreviousLabel: 'Back',
    };

    const onboardingProcess = [initialStep, ...onboardingSteps, finalStep];

    const goToNext = () => {
        if (step + 1 < onboardingProcess.length) {
            return dispatch({ type: NEXT_STEP });
        }

        clearItem();
        return history.push(ROUTES.dashboard);
    };

    const goToPrevious = () => {
        if (step - 1 >= 0) {
            return dispatch({ type: PREV_STEP });
        }

        clearItem();
        return history.push(ROUTES.dashboard);
    };

    useEffect(() => {
        const getStatus = async () => {
            const savedOnboardingStatus = await getItem();

            if (savedOnboardingStatus !== null) {
                dispatch({
                    type: SET_VALUE,
                    payload: parseInt(savedOnboardingStatus, 10),
                });
            } else {
                setItem(0);
            }
        };

        getStatus();
    }, []);

    useEffect(() => {
        setItem(step);
    }, [step]);

    return (
      <div
            style={{
                position: 'fixed',
                zIndex: 999,
                width: 'calc(100% - 365px)',
            }}
        >
        <DashboardOnboardingMock
                displayPerformance={[
                    PERFORMANCE_METRICS_EXPLAINER,
                    PERFORMANCE_CHART_EXPLAINER,
                ].includes(onboardingProcess[step].verboseId)}
                highlightChart={step === 7}
                highlightMetrics={step === 8}
            />
        <TileBackground showBackground />
        {onboardingProcess.map((item, index) => (
          <OnboardingTile
                    key={item.verboseId}
                    isCurrentStep={step === index}
                    onNext={goToNext}
                    onPrevious={goToPrevious}
                    onClose={() => history.push(ROUTES.dashboard)}
                    {...item}
                >
            {item.render()}
            <OnboardingStatusIndicator
                        totalSteps={4}
                        currentStep={
                            item.compact
                                ? 2
                                : indicatorSteps.indexOf(item.verboseId)
                        }
                    />
          </OnboardingTile>
            ))}
      </div>
    );
};

export default Onboarding;
