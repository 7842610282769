import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useFetch from 'hooks/useFetch';
import Input from 'components/Input';
import apiRoutes from 'config/apiRoutes';
import { useAuth0 } from '@auth0/auth0-react';
import { UserWrapper, FullName, Result } from './SingleManageUserId.style';

const SingleManageUserId = ({ user }) => {
    const { getAccessTokenSilently, logout } = useAuth0();
    const request = useFetch(getAccessTokenSilently, logout);
    const [isFailure, setIsFailure] = useState(undefined);
    const [isIdTaken, setIsIdTaken] = useState(undefined);
    const [mnemonic, setMnemonic] = useState(
        user.default_performance_id ? user.default_performance_id : undefined
    );

    const handleRequestSuccess = (res) => {
        if (res.error) {
            setIsIdTaken(true);
        } else {
            setIsIdTaken(false);
            setIsFailure(false);
        }
    };

    const handleRequestFailure = () => {
        setIsFailure(true);
    };

    const updateUser = () => {
        request(
            apiRoutes.put.updateUser(user.id),
            'PUT',
            handleRequestSuccess,
            handleRequestFailure,
            {
                body: JSON.stringify({
                    user: { default_performance_id: mnemonic },
                }),
            },
            { 'Content-Type': 'application/json' }
        );
    };

    const renderResult = () => {
        if (isFailure === undefined) {
            return null;
        }

        if (isIdTaken) {
            return (
              <Result isFail={isFailure}>
                User ID has already been taken
              </Result>
            );
        }

        if (isFailure) {
            return <Result isFail={isFailure}>FAILED</Result>;
        }

        return <Result>SAVED</Result>;
    };

    return (
      <UserWrapper>
        <FullName>{user.full_name}</FullName>
        <Input
                onBlur={updateUser}
                value={mnemonic}
                onChange={(e) => setMnemonic(e.target.value)}
            />
        {renderResult()}
      </UserWrapper>
    );
};

SingleManageUserId.propTypes = {
    user: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default SingleManageUserId;
