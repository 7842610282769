import {
    establishOrganizationSmartTipDetails,
    establishOrganizationSmartTipDetailsSuccess,
    establishOrganizationSmartTipDetailsFailure,
    establishOrganizationSmartTipAssignedUsers,
    establishOrganizationSmartTipAssignedUsersSuccess,
    establishOrganizationSmartTipAssignedUsersFailure,
    establishOrganizationSmartTipAssignedTeams,
    establishOrganizationSmartTipAssignedTeamsSuccess,
    establishOrganizationSmartTipAssignedTeamsFailure,
    resetOrganizationSmartTipDetails,
} from 'redux/actions/organizationSmartTipDetails';
import apiRoutes from 'config/apiRoutes';

const establishOrganizationSmartTipDetailsThunk = (
    getAccessTokenSilently,
    logout,
    smartTipId
) => (dispatch) => {
    dispatch(establishOrganizationSmartTipDetails());

    return getAccessTokenSilently()
        .then((token) => {
            fetch(
                apiRoutes.get.establishOrganizationSmartTipDetails(smartTipId),
                {
                    method: 'GET',
                    headers: {
                        organization_slug: window.localStorage.getItem(
                            'MP_ORGANIZATION'
                        ),
                        Authorization: `Token token="${token}"`,
                    },
                }
            )
                .then((res) => res.json())
                .then((res) => {
                    if (res.error) {
                        return dispatch(
                            establishOrganizationSmartTipDetailsFailure(
                                res.error
                            )
                        );
                    }

                    return dispatch(
                        establishOrganizationSmartTipDetailsSuccess(res)
                    );
                })
                .catch((err) =>
                    dispatch(
                        establishOrganizationSmartTipDetailsFailure({
                            err,
                        })
                    )
                );
        })
        .catch(() => logout({ returnTo: window.location.origin }));
};

export const establishOrganizationSmartTipAssignedUsersThunk = (
    getAccessTokenSilently,
    logout,
    smartTipId
) => (dispatch) => {
    dispatch(establishOrganizationSmartTipAssignedUsers());

    return getAccessTokenSilently()
        .then((token) => {
            fetch(apiRoutes.get.usersAssignedToSmartTip(smartTipId), {
                method: 'GET',
                headers: {
                    organization_slug: window.localStorage.getItem(
                        'MP_ORGANIZATION'
                    ),
                    Authorization: `Token token="${token}"`,
                },
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.error) {
                        return dispatch(
                            establishOrganizationSmartTipAssignedUsersFailure(
                                res.error
                            )
                        );
                    }

                    return dispatch(
                        establishOrganizationSmartTipAssignedUsersSuccess(
                            res.users
                        )
                    );
                })
                .catch((err) =>
                    dispatch(
                        establishOrganizationSmartTipAssignedUsersFailure({
                            err,
                        })
                    )
                );
        })
        .catch(() => logout({ returnTo: window.location.origin }));
};

export const establishOrganizationSmartTipAssignedTeamsThunk = (
    getAccessTokenSilently,
    logout,
    smartTipId
) => (dispatch) => {
    dispatch(establishOrganizationSmartTipAssignedTeams());

    return getAccessTokenSilently()
        .then((token) => {
            fetch(apiRoutes.get.teamsAssignedToSmartTip(smartTipId), {
                method: 'GET',
                headers: {
                    organization_slug: window.localStorage.getItem(
                        'MP_ORGANIZATION'
                    ),
                    Authorization: `Token token="${token}"`,
                },
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.error) {
                        return dispatch(
                            establishOrganizationSmartTipAssignedTeamsFailure(
                                res.error
                            )
                        );
                    }

                    return dispatch(
                        establishOrganizationSmartTipAssignedTeamsSuccess(
                            res.teams
                        )
                    );
                })
                .catch((err) =>
                    dispatch(
                        establishOrganizationSmartTipAssignedTeamsFailure({
                            err,
                        })
                    )
                );
        })
        .catch(() => logout({ returnTo: window.location.origin }));
};

export const resetOrganizationSmartTipDetailsThunk = () => (dispatch) => {
    dispatch(resetOrganizationSmartTipDetails());
};

export default establishOrganizationSmartTipDetailsThunk;
