import styled from 'styled-components/macro';

export const GoToPerformanceWrapper = styled.div`
    border: ${({ theme }) => theme.border.base};
    border-radius: ${({ theme }) => theme.border.radiusBase};
    background-color: ${({ theme }) => theme.color.white};
    padding: 50px;
    height: 100%;

    @media (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
        width: calc(100vw - 48px);
        overflow: hidden;
    }
`;

export const Grid = styled.div`
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 5fr 1fr;
    grid-gap: 8px;
`;

export const Section = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const Title = styled.div`
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    color: ${({ theme }) => theme.color.textBase};
`;

export const Info = styled.div`
    padding: 24px 0;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    color: ${({ theme }) => theme.color.textLight};
`;
