import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ConfirmActionModal from 'components/ConfirmActionModal';
import {
    SwitchDisplay,
    SwitchLabel,
    SwitchInnerLabel,
    SwitchTextLabel,
} from './Switch.style';

const Switch = ({
    switchId,
    switchOn,
    switchOff,
    defaultStatus,
    partiallyEnabled,
    onLabel,
    offLabel,
    withConfirmationModal,
    externalStatus,
    onConfirmModalDescription,
    offConfirmModalDescription,
    onConfirmModalLabel,
    offConfirmModalLabel,
    onConfirmModalTitle,
    offConfirmModalTitle,
}) => {
    const [isSwitchOn, toggleSwitch] = useState(defaultStatus);
    const [changeStatusTo, setChangeStatusTo] = useState(undefined);

    useEffect(() => {
        if (withConfirmationModal) {
            toggleSwitch(externalStatus);
        }
    }, [externalStatus]);

    return (
      <div>
        <input
                style={{ display: 'none' }}
                type="checkbox"
                id={switchId}
                checked={isSwitchOn}
                onChange={() => {
                    if (!withConfirmationModal) {
                        if (isSwitchOn) {
                            switchOff();
                        } else {
                            switchOn();
                        }
                        toggleSwitch(!isSwitchOn);
                    } else {
                        setChangeStatusTo(!isSwitchOn);
                    }
                }}
            />
        <SwitchDisplay>
          <SwitchLabel htmlFor={switchId}>
            <SwitchInnerLabel
                        switchedOn={isSwitchOn}
                        partiallyEnabled={isSwitchOn && partiallyEnabled}
                    />
          </SwitchLabel>
          <SwitchTextLabel
                    switchedOn={isSwitchOn}
                    partiallyEnabled={isSwitchOn && partiallyEnabled}
                >
            {isSwitchOn && (onLabel || 'Enabled')}
            {!isSwitchOn && (offLabel || 'Disabled')}
          </SwitchTextLabel>
        </SwitchDisplay>
        {withConfirmationModal && (
        <ConfirmActionModal
                    isVisible={changeStatusTo !== undefined}
                    onToggle={() => {
                        setChangeStatusTo(undefined);
                    }}
                    onConfirm={changeStatusTo ? switchOn : switchOff}
                    description={
                        changeStatusTo
                            ? onConfirmModalDescription
                            : offConfirmModalDescription
                    }
                    confirmLabel={
                        changeStatusTo
                            ? onConfirmModalLabel
                            : offConfirmModalLabel
                    }
                    title={
                        changeStatusTo
                            ? onConfirmModalTitle
                            : offConfirmModalTitle
                    }
                    large
                />
            )}
      </div>
    );
};

Switch.propTypes = {
    switchId: PropTypes.string.isRequired,
    switchOn: PropTypes.func.isRequired,
    switchOff: PropTypes.func.isRequired,
    defaultStatus: PropTypes.bool,
    partiallyEnabled: PropTypes.bool,
    onLabel: PropTypes.string,
    offLabel: PropTypes.string,
    withConfirmationModal: PropTypes.bool,
    externalStatus: PropTypes.bool,
    onConfirmModalDescription: PropTypes.string,
    offConfirmModalDescription: PropTypes.string,
    onConfirmModalLabel: PropTypes.string,
    offConfirmModalLabel: PropTypes.string,
    onConfirmModalTitle: PropTypes.string,
    offConfirmModalTitle: PropTypes.string,
};

Switch.defaultProps = {
    defaultStatus: false,
    partiallyEnabled: false,
    onLabel: undefined,
    offLabel: undefined,
    withConfirmationModal: undefined,
    externalStatus: false,
    onConfirmModalDescription: 'Are you sure you want to disable?',
    offConfirmModalDescription: 'Are you sure you want to enable?',
    onConfirmModalLabel: 'Enable',
    offConfirmModalLabel: 'Disable',
    onConfirmModalTitle: 'Are you sure?',
    offConfirmModalTitle: 'Are you sure?',
};

export default Switch;
