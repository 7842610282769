import styled, { css } from 'styled-components/macro';

export const StyledTeamMembersView = styled.div`
    width: 100%;
    min-height: calc(100vh - 150px);
`;

export const TeamMemberWrapper = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;

    @media (max-width: ${({ theme }) => `${theme.breakpoints.medium}px`}) {
        display: block;
    }
`;

export const StyledTeamMembersHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 98px;
    margin-bottom: 24px;
    padding: 28px;

    button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: auto;
        width: 128px;
    }

    ${({ theme }) => css`
        background-color: ${theme.color.white};
        border: ${theme.border.base};
        border-radius: ${theme.border.radiusBase};

        span {
            color: ${theme.color.textLight};
        }
    `}
`;
