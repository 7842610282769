export const ESTABLISH_SMART_TIP_DETAILS = 'ESTABLISH_SMART_TIP_DETAILS';
export const ESTABLISH_SMART_TIP_DETAILS_SUCCESS =
    'ESTABLISH_SMART_TIP_DETAILS_SUCCESS';
export const ESTABLISH_SMART_TIP_DETAILS_FAILURE =
    'ESTABLISH_SMART_TIP_DETAILS_FAILURE';
export const ASSIGN_TO_ALL_USERS = 'ASSIGN_TO_ALL_USERS';

export const UPDATE_SMART_TIP_DETAILS = 'UPDATE_SMART_TIP_DETAILS';
export const UPDATE_SMART_TIP_DETAILS_SUCCESS =
    'UPDATE_SMART_TIP_DETAILS_SUCCESS';
export const UPDATE_SMART_TIP_DETAILS_FAILURE =
    'UPDATE_SMART_TIP_DETAILS_FAILURE';

export const SEND_SMART_TIP_FEEDBACK = 'SEND_SMART_TIP_FEEDBACK';
export const SEND_SMART_TIP_FEEDBACK_SUCCESS =
    'SEND_SMART_TIP_FEEDBACK_SUCCESS';
export const SEND_SMART_TIP_FEEDBACK_FAILURE =
    'SEND_SMART_TIP_FEEDBACK_FAILURE';

export const establishSmartTipDetails = () => ({
    type: ESTABLISH_SMART_TIP_DETAILS,
});

export const establishSmartTipDetailsSuccess = (payload) => ({
    type: ESTABLISH_SMART_TIP_DETAILS_SUCCESS,
    payload,
});

export const establishSmartTipDetailsFailure = (err) => ({
    type: ESTABLISH_SMART_TIP_DETAILS_FAILURE,
    err,
});

export const updateSmartTipDetails = () => ({
    type: UPDATE_SMART_TIP_DETAILS,
});

export const updateSmartTipDetailsSuccess = () => ({
    type: UPDATE_SMART_TIP_DETAILS_SUCCESS,
});

export const updateSmartTipDetailsFailure = (err) => ({
    type: UPDATE_SMART_TIP_DETAILS_FAILURE,
    err,
});

export const sendSmartTipFeedback = () => ({
    type: SEND_SMART_TIP_FEEDBACK,
});

export const sendSmartTipFeedbackSuccess = (payload) => ({
    type: SEND_SMART_TIP_FEEDBACK_SUCCESS,
    payload,
});

export const sendSmartTipFeedbackFailure = (err) => ({
    type: SEND_SMART_TIP_FEEDBACK_FAILURE,
    err,
});

export const assignToAllUsersAction = (payload) => ({
    type: ASSIGN_TO_ALL_USERS,
    payload,
});
