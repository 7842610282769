export const ESTABLISH_METRIC_RESULTS = 'ESTABLISH_METRIC_RESULTS';
export const ESTABLISH_METRIC_RESULTS_SUCCESS =
    'ESTABLISH_METRIC_RESULTS_SUCCESS';
export const ESTABLISH_METRIC_RESULTS_FAILURE =
    'ESTABLISH_METRIC_RESULTS_FAILURE';

export const UPLOADED_METRIC_RESET = 'UPLOADED_METRIC_RESET';
export const UPLOAD_METRIC_RESULTS = 'UPLOAD_METRIC_RESULTS';
export const UPLOAD_METRIC_RESULTS_SUCCESS = 'UPLOAD_METRIC_RESULTS_SUCCESS';
export const UPLOAD_METRIC_RESULTS_FAILURE = 'UPLOAD_METRIC_RESULTS_FAILURE';

export const SEARCH_ORGANIZATION_USERS = 'SEARCH_ORGANIZATION_USERS';
export const SEARCH_ORGANIZATION_USERS_SUCCESS =
    'SEARCH_ORGANIZATION_USERS_SUCCESS';
export const SEARCH_ORGANIZATION_USERS_FAILURE =
    'SEARCH_ORGANIZATION_USERS_FAILURE';

export const SEARCH_METRICS = 'SEARCH_METRICS';
export const SEARCH_METRICS_SUCCESS = 'SEARCH_METRICS_SUCCESS';
export const SEARCH_METRICS_FAILURE = 'SEARCH_METRICS_FAILURE';

export const UPDATE_METRIC = 'UPDATE_METRIC';
export const UPDATE_METRIC_SUCCESS = 'UPDATE_METRIC_SUCCESS';
export const UPDATE_METRIC_FAILURE = 'UPDATE_METRIC_FAILURE';

export const establishMetricResults = () => ({
    type: ESTABLISH_METRIC_RESULTS,
});

export const establishMetricResultsSuccess = (payload) => ({
    type: ESTABLISH_METRIC_RESULTS_SUCCESS,
    payload,
});

export const establishMetricResultsFailure = (err) => ({
    type: ESTABLISH_METRIC_RESULTS_FAILURE,
    err,
});

export const updateMetric = () => ({
    type: UPDATE_METRIC,
});

export const updateMetricSuccess = (payload) => ({
    type: UPDATE_METRIC_SUCCESS,
    payload,
});

export const updateMetricFailure = (err) => ({
    type: UPDATE_METRIC_FAILURE,
    err,
});

export const uploadMetricResults = () => ({
    type: UPLOAD_METRIC_RESULTS,
});

export const uploadedMetricReset = () => ({
    type: UPLOADED_METRIC_RESET,
});

export const uploadMetricResultsSuccess = (payload) => ({
    type: UPLOAD_METRIC_RESULTS_SUCCESS,
    payload,
});

export const uploadMetricResultsFailure = (err) => ({
    type: UPLOAD_METRIC_RESULTS_FAILURE,
    err,
});

export const searchOrganizationUsers = () => ({
    type: SEARCH_ORGANIZATION_USERS,
});

export const searchOrganizationUsersSuccess = (payload) => ({
    type: SEARCH_ORGANIZATION_USERS_SUCCESS,
    payload,
});

export const searchOrganizationUsersFailure = (err) => ({
    type: SEARCH_ORGANIZATION_USERS_FAILURE,
    err,
});

export const searchMetrics = () => ({
    type: SEARCH_METRICS,
});

export const searchMetricsSuccess = (payload) => ({
    type: SEARCH_METRICS_SUCCESS,
    payload,
});

export const searchMetricsFailure = (err) => ({
    type: SEARCH_METRICS_FAILURE,
    err,
});
