export const DISPATCH_SUCCESS_ALERT = 'DISPATCH_SUCCESS_ALERT';
export const DISPATCH_ERROR_ALERT = 'DISPATCH_ERROR_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

export const dispatchSuccessAlert = (payload) => ({
    type: DISPATCH_SUCCESS_ALERT,
    payload: {
        type: 'success',
        ...payload,
    },
});

export const dispatchErrorAlert = (payload) => ({
    type: DISPATCH_ERROR_ALERT,
    payload: {
        type: 'error',
        ...payload,
    },
});

export const removeAlert = (payload) => ({
    type: REMOVE_ALERT,
    payload,
});
