import styled, { css } from 'styled-components/macro';

export const InputWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
`;

export const ComponentWrapper = styled.div`
    padding: 0 16px;
`;

export const EditCustomerWrapper = styled.div`
    border: ${({ theme }) => theme.border.base};
    border-radius: ${({ theme }) => theme.border.radiusBase};
    display: flex;
    flex-direction: column;
`;

export const SectionItem = styled.div`
    padding: 32px;
    display: flex;
    flex-direction: column;

    ${({ borderTop }) =>
        borderTop &&
        css`
            border-top: 1px solid;
            border-color: ${({ theme }) => theme.color.borderColorBase};
        `}

    ${({ borderBottom }) =>
        borderBottom &&
        css`
            border-bottom: 1px solid;
            border-color: ${({ theme }) => theme.color.borderColorBase};
        `}

    ${({ row }) =>
        row &&
        css`
            flex-direction: row;
            align-items: center;
        `}
`;

export const Title = styled.div`
    margin-bottom: 24px;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    color: ${({ theme }) => theme.color.textLight};

    ${({ customStyles }) =>
        customStyles &&
        css`
            ${customStyles}
        `}
`;

export const InputGroupWrapper = styled.div`
    width: 50%;

    ${({ gridRows }) =>
        gridRows &&
        css`
            display: grid;
            grid-gap: 24px;
            grid-template-rows: ${gridRows};
        `}
`;

export const BottomSectionWrapper = styled.div`
    width: 40%;
    padding-top: 32px;
    display: grid;
    grid-gap: 16px;
    grid-template-columns: 1fr 1fr;
`;

export const DatabaseInfo = styled.div`
    margin-top: 16px;
    display: flex;

    opacity: 0.8;

    span {
        color: #718fbf;
    }

    svg {
        margin-right: 8px;
    }
`;
