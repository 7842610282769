import styled, { css } from 'styled-components/macro';

export const AccordionWrapper = styled.div`
    margin-top: 20px;
`;

export const TableHeader = styled.div`
    width: 100%;
    padding: 24px 32px;
    display: grid;
    grid-gap: 16px;
    grid-template-columns: 4fr 2fr 1fr 2fr 1fr;
    align-items: center;

    ${({ theme }) => css`
        background-color: ${theme.color.bgPrimarySuperLight};
        border-bottom: ${theme.border.base};

        span {
            color: ${theme.color.textLight};
        }
    `}

    @media (max-width: ${({ theme }) => `${theme.breakpoints.medium}px`}) {
        min-width: 500px;
        width: fit-content;
    }
`;

export const TableHeaderMPA = styled(TableHeader)`
    grid-template-columns: 7fr 7fr 3fr 3fr 1fr;
`;

export const LabelCta = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    small {
        color: ${({ theme }) => theme.color.textLight};
        margin-right: 32px;
    }
`;

export const CustomersListWrapper = styled.div`
    max-height: 500px;
    overflow-y: auto;
`;

export const SingleCustomerWrapper = styled.div`
    width: 100%;
    padding: 8px 24px;
    display: grid;
    grid-template-columns: 1fr 9fr;
    grid-gap: 16px;
    align-items: center;

    span {
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
    }
`;

export const Avatar = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
`;

export const EmptyAvatar = styled.p`
    margin: 0px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    text-align: center;
    vertical-align: middle;
    line-height: 40px;
    font-size: 12px;
    font-weight: 600;
    color: ${({ theme }) => theme.color.primary};
    background-color: ${({ theme }) => theme.color.bgPrimaryLight};

    ${({ color }) =>
        color &&
        css`
            color: ${color};
        `}

    ${({ backgroundColor }) =>
        backgroundColor &&
        css`
            background-color: ${backgroundColor};
        `}
`;
